import { Component, Input, OnInit } from "@angular/core";
import {
  ACOS,
  AD_CONVERSIONS,
  AD_SALES,
  AdStatsData,
  AdStatsEx,
  AdStatsWithStrategyHistory,
  AuthService,
  CLICK_THROUGH_RATE,
  CLICKS,
  CONVERSION_RATE,
  COST,
  CPC,
  Currency,
  DataSet,
  DataSetEventAnnotation,
  IMPRESSIONS,
  indexStrategyByDate,
  marketplaceToCurrencyRate,
  mergeAdStatsWithStrategyHistory,
  mergeSeveralDates,
  Metric,
  MetricsSelectorLocalStorageKey,
  ROAS,
  StatsApiClientService,
  Strategy,
  StrategyEx,
  StrategyType,
  UserSelectionService,
} from "@front/m19-services";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { ActivityService } from "@m19-board/activities/activity.service";
import { StrategyStats } from "@m19-board/models/Metric";

import { ChartData } from "@m19-board/shared/chart-renderer/chart-renderer.component";
import { BsModalRef } from "ngx-bootstrap/modal";
import { combineLatest, map, Observable } from "rxjs";
import { DAILY_BUDGET, MIN_DAILY_SPEND, MONTHLY_BUDGET, TARGET_ACOS } from "../../../models/MetricsDef";

@UntilDestroy()
@Component({
  selector: "app-strategy-stats",
  template: ` <div class="modal-header bg-primary">
      <h4 class="modal-title">Strategy Stats - {{ strategy.name }}</h4>
      <h4 class="text-secondary pointer" (click)="close()">&times;</h4>
    </div>
    <div class="modal-body" style="height: 90vh">
      <mat-tab-group
        animationDuration="0ms"
        [selectedIndex]="selectedTab"
        (selectedIndexChange)="selectedTab = $event; tabChanged = true"
      >
        <mat-tab label="Strategy">
          <app-chart-renderer
            title=""
            [dataset]="dataSet"
            [metrics]="METRIC_COLUMNS"
            [localStorageKey]="localStorageKey"
            [selectMetricCallback]="selectMetricCallback"
            [chartData$]="chartData$"
            [withEventAnnotations]="true"
            [annotations$]="annotations$"
          ></app-chart-renderer>
        </mat-tab>
        <mat-tab label="Targeting" class="py-2">
          @if (tabChanged) {
            <app-targeting-asin-stats
              [isReadOnly]="isReadOnly"
              [strategy]="strategy"
              [withTOSROSwitch]="withTOSROSwitch"
            ></app-targeting-asin-stats>
          }
        </mat-tab>
      </mat-tab-group>
    </div>`,
})
export class StrategyStatsComponent implements OnInit {
  @Input()
  strategy: StrategyEx;
  @Input()
  isReadOnly = false;

  readonly METRIC_COLUMNS: Metric<StrategyStats>[] = [
    AD_SALES,
    AD_CONVERSIONS,
    COST,
    ACOS,
    CLICKS,
    IMPRESSIONS,
    CLICK_THROUGH_RATE,
    CONVERSION_RATE,
    CPC,
    ROAS,
  ];
  readonly localStorageKey = MetricsSelectorLocalStorageKey.strategy;
  readonly dataSet: DataSet<AdStatsEx>;
  withTOSROSwitch = false;
  chartData$: Observable<ChartData<AdStatsEx>>;
  annotations$: Observable<DataSetEventAnnotation[]>;
  selectedTab = 0;
  // this boolean is to lazy load the Targeting asin stats component (loaded only once tab is changed)
  tabChanged = false;
  additionalCostMetrics: Metric<AdStatsEx>[] = [MIN_DAILY_SPEND, DAILY_BUDGET, MONTHLY_BUDGET];

  constructor(
    private authService: AuthService,
    private statsService: StatsApiClientService,
    private activityService: ActivityService,
    private userSelectionService: UserSelectionService,
    private bsModalRef: BsModalRef,
  ) {
    this.dataSet = new DataSet<AdStatsWithStrategyHistory>(3, [AD_SALES, COST], mergeAdStatsWithStrategyHistory);
    this.dataSet.metricsOnSameScale = [
      [AD_SALES, COST],
      [ACOS, TARGET_ACOS],
      [COST, MIN_DAILY_SPEND, DAILY_BUDGET, MONTHLY_BUDGET],
    ];
  }

  ngOnInit(): void {
    this.withTOSROSwitch = this.strategy.strategyType == StrategyType.PRODUCT;
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.dataSet.locale = user.locale;
    });
    this.userSelectionService.selectedCurrency$.pipe(untilDestroyed(this)).subscribe((currency) => {
      this.dataSet.currency = currency;
    });
    this.chartData$ = combineLatest<[AdStatsData, AdStatsData, Strategy[], Currency]>([
      this.statsService.dailyPlacementStats$,
      this.statsService.previousPeriodDailyPlacementStats$,
      this.statsService.strategyConfigHistory$,
      this.userSelectionService.selectedCurrency$,
    ]).pipe(
      map(([dailyPlacementStats, previousPeriodDailyPlacementStats, strategyConfigHistory, currency]) => {
        const stats = dailyPlacementStats.data.filter((d) => d.strategyId == this.strategy.strategyId);
        const previousStats = previousPeriodDailyPlacementStats.data.filter(
          (d) => d.strategyId == this.strategy.strategyId,
        );
        const acosTargetHistory: {
          date: string;
          acosTarget: number;
          minDailySpend: number;
          dailyBudget: number;
          monthlyBudget: number;
        }[] = [];
        // display ACOS target history when displaying one strategy graph
        for (const [date, s] of indexStrategyByDate(
          strategyConfigHistory.filter((x) => x.strategyId == this.strategy.strategyId),
        ).entries()) {
          const rate = marketplaceToCurrencyRate(this.strategy.marketplace, currency);
          const data = {
            date,
            acosTarget: s.acosTarget,
            minDailySpend: rate * s.minDailySpend,
            dailyBudget: rate * s.dailyBudget,
            monthlyBudget: rate * s.monthlyBudget,
            computedDailyBudget: rate * s.computedDailyBudget,
          };
          this.additionalCostMetrics = [];
          if (!isNaN(data.dailyBudget)) this.additionalCostMetrics.push(DAILY_BUDGET);
          if (data.minDailySpend !== 0) this.additionalCostMetrics.push(MIN_DAILY_SPEND);
          if (!isNaN(data.monthlyBudget)) this.additionalCostMetrics.push(MONTHLY_BUDGET);
          acosTargetHistory.push(data);
        }
        const totalData = stats.reduce((prev, curr) => mergeSeveralDates(prev, curr), {});
        const totalPreviousData = previousStats?.reduce((curr, prev) => mergeSeveralDates(curr, prev), {});
        return {
          data: [...acosTargetHistory, ...stats],
          previousData: previousStats,
          totalData,
          totalPreviousData,
        };
      }),
    );
    this.annotations$ = this.activityService.getStrategyActivityEventAnnotation(
      this.strategy.accountId,
      this.strategy.marketplace,
      this.strategy,
    );
  }

  selectMetricCallback = (metrics) => {
    const res = [...metrics];
    if (metrics.includes(ACOS)) {
      res.push(TARGET_ACOS);
    }
    if (metrics.includes(COST)) {
      res.push(...this.additionalCostMetrics);
    }
    return res;
  };

  close() {
    this.bsModalRef.hide();
  }
}
