import { Component, Input } from "@angular/core";
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { UntilDestroy } from "@ngneat/until-destroy";
import {
  AudienceTargeting,
  ConfigService,
  Marketplace,
  MatchType,
  StrategyEx,
  StrategyTacticEx,
  SupportedAudienceMatchType,
  TacticType,
} from "@front/m19-services";
import { ProductSegmentModalComponent } from "@m19-board/segments/product-segment-modal.component";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ModalRemoveTacticFromStrategyComponent } from "@m19-board/strategies/tactic/tactic.component";

/* Modal component to delete the Audience from the Strategy */
@Component({
  templateUrl: "./remove-audience-popup.component.html",
})
export class ModalRemoveAudienceFromStrategyComponent {
  accountId: string;
  strategyId: number;
  audience: AudienceTargeting;
  isSdTargeting: false;

  constructor(
    public configurationService: ConfigService,
    public bsModalRef: BsModalRef,
  ) {}

  delete() {
    this.configurationService.removeRemarketingAudienceToSdStrategy(
      this.accountId,
      this.strategyId,
      this.audience.audienceTargetId,
    );
    this.bsModalRef.hide();
  }
}

@Component({
  templateUrl: "./remove-ia-powered-targeting-popup.component.html",
})
export class ModalRemoveAIPoweredTargetingFromStrategyComponent {
  accountId: string;
  strategyId: number;
  marketplace: Marketplace;

  constructor(
    public configurationService: ConfigService,
    public bsModalRef: BsModalRef,
  ) {}

  delete() {
    this.configurationService.updateStrategyAutoAlgoExploration(
      this.accountId,
      this.marketplace,
      this.strategyId,
      false,
    );
    this.bsModalRef.hide();
  }
}

@UntilDestroy()
@Component({
  selector: "app-sd-targeting",
  templateUrl: "./sd-targeting.component.html",
  styleUrls: ["./sd-targeting.component.scss"],
})
export class SdTargetingComponent {
  readonly faPencil = faPencilAlt;
  readonly faTrash = faTrashAlt;
  readonly TacticType = TacticType;
  readonly supportedAudienceMatchType = SupportedAudienceMatchType;

  @Input() tactic: StrategyTacticEx;

  @Input() audience: AudienceTargeting;

  @Input() strategy: StrategyEx;

  @Input() isReadOnly = false;

  @Input() defaultTactic = false;

  @Input() isLastTargeting: boolean;

  constructor(
    public configurationService: ConfigService,
    private modalService: BsModalService,
  ) {}

  nbProductTargeting(): number {
    return this?.tactic?.segment?.items.filter((x) => x.matchType == MatchType.asinSameAs).length ?? 0;
  }

  editSegment() {
    const modalOptions: ModalOptions = {
      initialState: {
        segment: this.tactic?.segment,
        isReadOnly: this.isReadOnly,
      },
      class: "modal-xl",
    };
    this.modalService.show(ProductSegmentModalComponent, modalOptions);
  }

  // AI-powered targeting
  disableAutoAlgoExploration() {
    const modalOptions: ModalOptions = {
      initialState: {
        accountId: this.strategy.accountId,
        strategyId: this.strategy.strategyId,
        marketplace: this.strategy.marketplace,
      },
      class: "modal-danger",
    };
    this.modalService.show(ModalRemoveAIPoweredTargetingFromStrategyComponent, modalOptions);
  }

  removeTacticFromStrategy() {
    const modalOptions: ModalOptions = {
      initialState: {
        accountId: this.strategy.accountId,
        strategyId: this.strategy.strategyId,
        tactic: this.tactic,
        isSdTargeting: true,
      },
      class: "modal-danger",
    };
    this.modalService.show(ModalRemoveTacticFromStrategyComponent, modalOptions);
  }

  removeAudienceFromStrategy() {
    const modalOptions: ModalOptions = {
      initialState: {
        accountId: this.strategy.accountId,
        strategyId: this.strategy.strategyId,
        audience: this.audience,
      },
      class: "modal-danger",
    };
    this.modalService.show(ModalRemoveAudienceFromStrategyComponent, modalOptions);
  }
}
