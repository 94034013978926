import { Component, inject, signal } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UntilDestroy } from "@ngneat/until-destroy";
import { AuthService, Currency, UserBase } from "@front/m19-services";

import { Angulartics2 } from "angulartics2";
import { LayoutSelectorService } from "@m19-board/layout-selector.service";
import { environment } from "../../../environments/environment";
import { RegisterFormValue } from "@front/m19-ui";
import { HttpErrorResponse } from "@angular/common/http";

@UntilDestroy()
@Component({
  selector: "app-register",
  templateUrl: "register.component.html",
  styleUrls: ["register.component.scss"],
})
export class RegisterComponent {
  private authService = inject(AuthService);
  private router = inject(Router);
  private angulartics2 = inject(Angulartics2);
  private layoutSelector = inject(LayoutSelectorService);
  private route = inject(ActivatedRoute);

  isTest = !environment.production;

  termsAndConditionLink = this.layoutSelector.getTermsAndConditionLink();
  agency = this.layoutSelector.getAgencyName();

  loading = signal(false);
  error = signal<string | null>(null);

  register(formValue: RegisterFormValue): void {
    if (!formValue) return;
    this.loading.set(true);
    const user = this.toUser(formValue);

    this.authService.registerV2(user).subscribe({
      next: () => {
        this.angulartics2.eventTrack.next({
          action: "register",
          properties: { category: "register", label: "success_" + user.email },
        });
        this.goToHomeOrRedirect();
      },
      error: (err: HttpErrorResponse) => {
        this.error.set(err.message);
        this.loading.set(false);
      },
    });
  }

  private toUser(formValue: RegisterFormValue): UserBase {
    const fullName: string = formValue.firstName.trim() + " " + formValue.lastName.trim();
    return {
      defaultCurrency: Currency.USD, // TODO select according to user accounts and browser language
      userName: fullName,
      email: formValue.email,
      newPassword: formValue.password,
      registered: true, // Already checked by the form
    };
  }

  goToHomeOrRedirect(): void {
    const redirectUrl = this.route.snapshot.queryParams["redirect"] ?? "dashboard";
    this.router.navigateByUrl(redirectUrl);
  }
}
