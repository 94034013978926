import { formatNumber } from "@angular/common";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import {
  AccountMarketplace,
  AccountSelectionService,
  ACOS,
  AD_CONVERSIONS,
  AD_SALES,
  AdStatsEx,
  AuthService,
  BrandAnalyticsService,
  CLICK_THROUGH_RATE,
  CLICKS,
  CONVERSION_RATE,
  convertToCurrency,
  COST,
  CPC,
  Currency,
  DataSet,
  getAmazonSearchUrl,
  getBasicGridOptions,
  groupBy,
  KeywordTopOfSearchRankings,
  MatchType,
  mergeSeveralDates,
  Metric,
  ROAS,
  SearchTermRank,
  SIDE_BAR_NO_PIVOT,
  StatsApi,
  StrategyApi,
  StrategyEx,
  UpdateStrategyTopOfSearchRankingsActionEnum,
  User,
  UserSelectionService,
  Utils,
} from "@front/m19-services";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { AgGridAngular } from "@ag-grid-community/angular";
import {
  ColDef,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  ModelUpdatedEvent,
  ValueFormatterParams,
  ValueGetterParams,
} from "@ag-grid-community/core";

import { IBadgeComponent } from "@front/m19-ui";
import { AsinLinkComponent } from "@m19-board/product-view/asin-link.component";
import { ProductViewComponent } from "@m19-board/product-view/product-view.component";
import { LinkComponent } from "@m19-board/shared/link/link.component";
import { keywordRankingAvailableFor } from "@m19-board/tracking/KeywordRankingAvailability";
import { ICON_CHART_LINE, ICON_LIST } from "@m19-board/utils/iconsLabels";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, combineLatest, map, Observable, of, shareReplay, switchMap } from "rxjs";
import { actionColumnProperties, ImageColumn } from "../../../grid-config/grid-columns";
import { getMetricsColDef, STATUS_BAR } from "../../../grid-config/grid-config";
import {
  ActionButton,
  ActionButtonsComponent,
} from "../../../insights/overview/action-buttons/action-buttons.component";
import { ASINS_WITH_TOP_OF_SEARCH_RANKINGS_FIRED } from "../../../models/MetricsDef";
import { ProductThumbnailComponent } from "../../../product-view/product-thumbnail.component";
import { ChartRendererComponent } from "../../../shared/chart-renderer/chart-renderer.component";
import { GridViewerComponent } from "../../../shared/grid-viewer/grid-viewer.component";
import {
  SlideToggleParams,
  SlideToggleRendererComponent,
} from "../../../shared/slide-toggle-renderer/slide-toggle-renderer.component";

@UntilDestroy()
@Component({
  selector: "app-targeting-asin-stats",
  templateUrl: "./targeting-asin-stats.component.html",
})
export class TargetingAsinStatsComponent implements OnInit {
  @Input()
  strategy: StrategyEx;

  @Input() isReadOnly: boolean;

  @Input() withTOSROSwitch = true;

  private readonly metrics: Metric<AdStatsEx>[] = [
    AD_SALES,
    AD_CONVERSIONS,
    CPC,
    COST,
    CLICKS,
    CLICK_THROUGH_RATE,
    CONVERSION_RATE,
    ACOS,
    ROAS,
  ];

  private readonly metricsWithTopOfSearch: Metric<AdStatsEx>[] = this.metrics.concat(
    ASINS_WITH_TOP_OF_SEARCH_RANKINGS_FIRED,
  );

  readonly sfrByKeyword: Map<string, number> = new Map();
  topOfSearchRankingsOn: Set<string> = new Set<string>();
  private selectedAccountMarketplace: AccountMarketplace = undefined;

  selectedMainMetrics$: BehaviorSubject<Metric<AdStatsEx>[]>;
  asinDataSet: DataSet<AdStatsEx>;
  kwDataSet: DataSet<AdStatsEx>;

  locale: string;

  currency: Currency = Currency.EUR;

  kwStats: AdStatsEx[];

  supportedMarketplace: boolean;

  private readonly SFR_FALLBACK = 300000;
  private readonly GRID_KEY = "spKeywordsGrid";
  private readonly ASIN_DETAILS_GRID_KEY = "spKeywordsDetailsGrid";

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  // <keyword::matchType, asinDetails>
  private asinDetailsByKeywordMatch: Map<string, AdStatsEx[]>;
  private selectedChartKwRow$ = new BehaviorSubject<AdStatsEx>(undefined);
  private gridData: AdStatsEx[];

  private colDefs: ColDef[] = [
    {
      field: "keywordText",
      headerName: "Targeting",
      pinned: "left",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cellRendererSelector: (params) => {
        if (params.node.isRowPinned()) return undefined;
        const data = params.data;
        if (data) {
          if (data.matchType == MatchType.asinSameAs) {
            return {
              component: ProductViewComponent,
              params: { asin: params.value, marketplace: this.selectedAccountMarketplace.marketplace },
            };
          } else {
            const link = getAmazonSearchUrl(params.value, this.selectedAccountMarketplace.marketplace);
            return {
              component: LinkComponent,
              params: {
                href: link,
                target: "_blank",
                content: params.value,
              },
            };
          }
        }
        return undefined;
      },
    },
    {
      field: "matchType",
      headerName: "Match type",
      floatingFilter: true,
      filter: "agSetColumnFilter",
      cellStyle: { textAlign: "center" },
      cellRendererSelector: (params) => {
        if (params.node.isRowPinned()) return null;
        return {
          component: IBadgeComponent,
          params: { label: params.value, size: "xs", variant: "soft" },
        };
      },
      pinned: "left",
    },
    {
      headerName: "SFR",
      headerTooltip: "Search Frequency Rank",
      colId: "SFR",
      valueGetter: (params: ValueGetterParams<AdStatsEx>) =>
        params.data?.matchType == MatchType.asinSameAs
          ? undefined
          : (this.sfrByKeyword.get(params.data?.keywordText) ?? this.SFR_FALLBACK),
      valueFormatter: (params: ValueFormatterParams<AdStatsEx>) => this.formatSFR(params.value, this.SFR_FALLBACK),
      filter: "agNumberColumnFilter",
      type: "numericColumn",
      pinned: "left",
    },
    ...getMetricsColDef(this.metrics).map((def: ColDef) => ({
      ...def,
      cellRendererParams: (params: ICellRendererParams<AdStatsEx>) => {
        return {
          ...def.cellRendererParams(params),
          previousData: undefined,
          currency: this.currency,
          locale: this.locale,
        };
      },
    })),
    {
      headerName: "TOSRO",
      colId: "TOSRO",
      headerTooltip: "Top of Search Rankings Optimizer",
      pinned: "right",
      suppressSizeToFit: true,
      floatingFilter: true,
      valueGetter: (params) => (this.topOfSearchRankingsOn.has(params.data.keywordText) ? "Enabled" : "Disabled"),
      cellRenderer: SlideToggleRendererComponent,
      cellRendererParams: (params: ICellRendererParams<AdStatsEx>) =>
        ({
          isHidden: params.data.matchType !== MatchType.exact || this.strategy.defaultStrategy,
          isChecked: this.topOfSearchRankingsOn.has(params.data.keywordText),
          isDisabled: !this.supportedMarketplace || this.isReadOnly,

          tooltip: this.isReadOnly
            ? "Not enough permissions"
            : !this.supportedMarketplace
              ? "Not supported for this marketplace"
              : "Activated keywords are tracked and included in keyword tracker hourly quota",

          onChange: (event) => {
            this.switchOptimizerStatus(params.data.keywordText, event);
          },
        }) as SlideToggleParams,
      cellStyle: { "text-align": "center" },
    },
    {
      ...actionColumnProperties<AdStatsEx, string>(),
      cellRendererSelector: (params: ICellRendererParams<AdStatsEx>) => {
        if (params.node.isRowPinned()) return;
        const actionBtns: ActionButton[] = [
          {
            icon: ICON_CHART_LINE,
            tooltip: "Display keyword chart",
            disabled: params.data.cost <= 0,
            onClick: () => {
              this.selectedChartKwRow$.next(params.data);
            },
          },
          {
            icon: ICON_LIST,
            tooltip: "Open ASIN details",
            disabled: params.data.cost <= 0,
            onClick: () => {
              const asinData: AdStatsEx[] = this.asinDetailsByKeywordMatch.get(this.keywordKey(params.data));
              this.openAsinDetailsGridModal(asinData);
            },
          },
        ];

        return { component: ActionButtonsComponent, params: { actionButtons: actionBtns } };
      },
    },
  ];

  private defaultColDef: ColDef = {
    resizable: true,
    filter: true,
    sortable: true,
  };

  gridOptions: GridOptions;

  private getGridOptions(): GridOptions {
    const key = this.GRID_KEY + (this.withTOSROSwitch ? "" : "_noTOSRO");
    const commonOptions = getBasicGridOptions(key, true);
    return {
      ...commonOptions,
      columnDefs: this.withTOSROSwitch ? this.colDefs : this.colDefs.filter((c) => c.colId != "TOSRO"),
      defaultColDef: this.defaultColDef,
      context: { parentComponent: this },
      sideBar: SIDE_BAR_NO_PIVOT,
      // default ad sales desc sorting
      onGridReady: (event: GridReadyEvent<any>) => {
        // data is fetch on init but grid is not rendered because of ngIf
        // thus, we set data on grid ready meaning that grid has been initialized
        event.api.setRowData(this.gridData);
      },
      onModelUpdated: (event: ModelUpdatedEvent<any>) => {
        commonOptions.onModelUpdated(event);
      },
    };
  }

  private asinDetailsGridOptions = {
    ...getBasicGridOptions(this.ASIN_DETAILS_GRID_KEY, true),
    defaultColDef: this.defaultColDef,
    context: { parentComponent: this },
    columnDefs: [
      {
        ...ImageColumn,
        cellRendererSelector: (params: ICellRendererParams<AdStatsEx>) => {
          if (params.node.isRowPinned()) return;
          return {
            component: ProductThumbnailComponent,
            params: {
              asin: params.data.asin,
              marketplace: this.strategy.marketplace,
              smallImg: true,
            },
          };
        },
      },
      {
        colId: "asin",
        field: "asin",
        floatingFilter: true,
        filter: "agTextColumnFilter",
        headerName: "ASIN",
        width: 170,
        pinned: "left",
        enableRowGroup: true,
        cellRendererSelector: (params: ICellRendererParams<AdStatsEx>) => {
          return params.node.isRowPinned()
            ? null
            : {
                component: AsinLinkComponent,
                params: {
                  asin: params.data.asin,
                  marketplace: this.strategy.marketplace,
                },
              };
        },
      },
      ...getMetricsColDef(this.metrics).map((def: ColDef) => ({
        ...def,
        cellRendererParams: (params: ICellRendererParams<AdStatsEx>) => {
          return {
            ...def.cellRendererParams(params),
            previousData: undefined,
            currency: this.currency,
            locale: this.locale,
          };
        },
      })),
      {
        ...actionColumnProperties<AdStatsEx, string>(),
        cellRendererSelector: (params: ICellRendererParams<AdStatsEx>) => {
          if (params.node.isRowPinned()) return;
          const button: ActionButton = {
            icon: ICON_CHART_LINE,
            tooltip: "Display ASIN chart",
            onClick: (_params) => {
              this.selectedChartKwRow$.next(params.node.data);
            },
          };
          return {
            component: ActionButtonsComponent,
            params: {
              actionButtons: [button],
            },
          };
        },
      },
    ] as ColDef[],
  };

  constructor(
    private authService: AuthService,
    private userSelectionService: UserSelectionService,
    private statsService: StatsApi,
    private brandAnalyticsService: BrandAnalyticsService,
    private strategyService: StrategyApi,
    private toasterService: ToastrService,
    private accountSelectionService: AccountSelectionService,
    private modalService: BsModalService,
  ) {}

  ngOnInit(): void {
    this.gridOptions = this.getGridOptions();
    this.selectedMainMetrics$ = new BehaviorSubject<Metric<AdStatsEx>[]>([AD_SALES, COST]);
    this.asinDataSet = new DataSet<AdStatsEx>(6, this.selectedMainMetrics$.value, mergeSeveralDates);
    this.kwDataSet = new DataSet<AdStatsEx>(3, this.selectedMainMetrics$.value, mergeSeveralDates);

    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user: User) => {
      this.locale = user.locale;
      this.asinDataSet.locale = user.locale;
      this.kwDataSet.locale = user.locale;
    });

    this.sfrByKeyword.clear();
    this.topOfSearchRankingsOn.clear();

    const dateRange$: Observable<string[]> = this.userSelectionService.dateRange$;
    this.supportedMarketplace = keywordRankingAvailableFor(this.strategy.marketplace);
    if (this.strategy.topOfSearchRankings) {
      for (const k of this.strategy.topOfSearchRankings) {
        this.topOfSearchRankingsOn.add(k.keyword);
      }
    }

    const stats$: Observable<AdStatsEx[]> = dateRange$.pipe(
      switchMap((range: string[]) => {
        return this.statsService.getDailyTargetingAsinStats({
          accountId: this.strategy.accountId,
          marketplace: this.strategy.marketplace,
          minDate: range[0],
          maxDate: range[1],
          strategyId: this.strategy.strategyId,
        });
      }),
      shareReplay(1),
    );

    const selectedCurrency$: Observable<Currency> = this.userSelectionService.selectedCurrency$;
    const sfrList$: Observable<SearchTermRank[]> = stats$.pipe(
      switchMap<AdStatsEx[], Observable<Array<SearchTermRank>>>((x: AdStatsEx[]) =>
        x.length > 0
          ? this.brandAnalyticsService.getLastSearchFrequencyRank(
              x[0].marketplace,
              x.flatMap((a) => a.keywordText).concat(this.strategy.topOfSearchRankings.map((x) => x.keyword)),
            )
          : of([]),
      ),
    );

    // new data fetching
    combineLatest([stats$, sfrList$, selectedCurrency$])
      .pipe(untilDestroyed(this))
      .subscribe(([stats, sfrList, currency]: [AdStatsEx[], SearchTermRank[], Currency]) => {
        this.currency = currency;
        const byDate = convertToCurrency(stats, currency);

        // group keywords by title + match type
        const byKeywords: Map<string, AdStatsEx> = groupBy<string>(byDate, (x) => this.keywordKey(x));
        const keywordsStats: AdStatsEx[] = Array.from(byKeywords.values());

        // ensure than top of search keywords are always displayed (even if no stats)
        const exactKwText = new Set(
          keywordsStats.filter((x) => x.matchType === MatchType.exact).map((x) => x.keywordText),
        );
        this.strategy.topOfSearchRankings.forEach((x) => {
          if (!exactKwText.has(x.keyword)) {
            keywordsStats.push({
              keywordText: x.keyword,
              cost: 0,
              matchType: MatchType.exact,
              currency: this.currency,
              marketplace: this.strategy.marketplace,
            });
            exactKwText.add(x.keyword);
          }
        });

        this.kwStats = keywordsStats;

        this.asinDetailsByKeywordMatch = new Map();
        const asinDetailsMap: Map<string, AdStatsEx[]> = new Map();
        for (const d of byDate) {
          Utils.insertInArrayMap(asinDetailsMap, this.keywordKey(d), d);
        }

        // there is still multiple object with same asin for a keyword::matchType because of date range
        for (const [key, value] of asinDetailsMap) {
          // aggregate by asin, now we have on object by asin
          const aggData: AdStatsEx[] = Array.from(groupBy<string>(value, (d: AdStatsEx) => d.asin).values());
          this.asinDetailsByKeywordMatch.set(key, aggData);
        }

        // sfr values
        for (const sfr of sfrList) this.sfrByKeyword.set(sfr.searchTerm, sfr.searchFrequencyRank);

        // set asin to null to know if we toggle graph for keyword or asin
        this.gridData = keywordsStats.map((s: AdStatsEx) => ({ ...s, asin: null }));
        this.agGrid?.api.setRowData(this.gridData);
      });

    const historyStats$: Observable<KeywordTopOfSearchRankings[]> = this.userSelectionService.dateRange$.pipe(
      switchMap((dateRange: string[]) => {
        return this.statsService.getStrategyTopOfSearchRankingsHistory({
          accountId: this.strategy.accountId,
          marketplace: this.strategy.marketplace,
          minDate: dateRange[0],
          maxDate: dateRange[1],
          strategyId: this.strategy.strategyId,
        });
      }),
    );

    this.selectedChartKwRow$.pipe(untilDestroyed(this)).subscribe((rowData) => {
      if (!rowData) {
        return;
      }
      const chartData$ = combineLatest<[AdStatsEx[], KeywordTopOfSearchRankings[], Currency]>([
        stats$,
        historyStats$,
        this.userSelectionService.selectedCurrency$,
      ]).pipe(
        map(([data, history, currency]) => {
          // is the user selected row from keyword grid or asin details grid
          const isAsinRow = rowData.asin !== null;

          this.kwDataSet.currency = currency;

          const kwStats = data.filter(
            (x) =>
              x.matchType === rowData.matchType &&
              x.keywordText === rowData.keywordText &&
              (isAsinRow ? x.asin === rowData.asin : true),
          );

          const kwStatsConv = convertToCurrency(kwStats, currency);
          // only available for MatchType.exact
          if (rowData.matchType === MatchType.exact) {
            history
              .filter((x) => x.keywordText === rowData.keywordText && (isAsinRow ? x.asin === rowData.asin : true))
              .filter((x) => x.factor && x.factor < 1)
              .forEach((h) => {
                const stats: AdStatsEx = {};
                stats.date = h.date;
                stats.keywordText = h.keywordText;
                stats.asin = h.asin;
                stats.matchType = MatchType.exact;
                stats.nbAsinsWithRuleFired = 1;
                stats.marketplace = h.marketplace;
                stats.currency = currency;
                kwStatsConv.push(stats);
              });
          }
          return {
            data: kwStatsConv,
            totalData: kwStatsConv.reduce((total, curr) => mergeSeveralDates(total, curr), {}),
          };
        }),
      );

      const modalOptions: ModalOptions = {
        initialState: {
          title: "Keyword Chart - " + rowData.keywordText,
          dataset: this.kwDataSet,
          chartData$,
          metrics:
            this.topOfSearchRankingsOn.has(rowData.keywordText) && rowData.matchType === MatchType.exact
              ? this.metricsWithTopOfSearch
              : this.metrics,
        },
        class: "modal-xl",
      };
      this.modalService.show(ChartRendererComponent, modalOptions);
    });
    this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(untilDestroyed(this)).subscribe((x) => {
      this.selectedAccountMarketplace = x;
    });
  }

  openAsinDetailsGridModal(asinData: AdStatsEx[]) {
    const modalOptions: ModalOptions = {
      initialState: {
        gridData: asinData,
        gridOptions: this.asinDetailsGridOptions,
        title: "Asin details - " + asinData[0].keywordText,
      },
      class: "modal-xl",
    };

    this.modalService.show(GridViewerComponent, modalOptions);
  }

  private formatSFR(value: number, fallback: number) {
    if (!value) return "-";
    return value < this.SFR_FALLBACK
      ? formatNumber(value, this.locale, "1.0-1")
      : ">" + formatNumber(fallback, this.locale, "1.0-1");
  }

  switchOptimizerStatus(keywordText: string, event: MatSlideToggleChange): void {
    const enable = event.checked;
    if (enable && this.topOfSearchRankingsOn.size > 50) {
      this.toasterService.error(
        "Error switching keyword state: limit of 50 keywords per strategy is reached",
        "Top Of Search Rankings Optimizer",
      );
      event.source.toggle();
      return;
    }

    const requestBody = new Array<string>();
    requestBody.push(keywordText);
    this.strategyService
      .updateStrategyTopOfSearchRankings({
        accountId: this.strategy.accountId,
        marketplace: this.strategy.marketplace,
        strategyId: this.strategy.strategyId,
        organizationId: this.selectedAccountMarketplace.resourceOrganizationId,
        action: enable
          ? UpdateStrategyTopOfSearchRankingsActionEnum.ADD
          : UpdateStrategyTopOfSearchRankingsActionEnum.DELETE,
        requestBody: requestBody,
      })
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          if (enable) {
            requestBody.forEach((k) => this.topOfSearchRankingsOn.add(k));
            requestBody.forEach((k) => this.strategy.topOfSearchRankings.push({ keyword: k }));

            this.toasterService.success("Keyword ON", "Top Of Search Rankings Optimizer Activated");
          } else {
            requestBody.forEach((k) => this.topOfSearchRankingsOn.delete(k));
            requestBody.forEach((k) =>
              this.strategy.topOfSearchRankings.forEach((item, index) => {
                if (item.keyword == k) this.strategy.topOfSearchRankings.splice(index, 1);
              }),
            );
            this.toasterService.success("Keyword OFF", "Top Of Search Rankings Optimizer Deactivated");
          }
        },
        (error) => {
          event.source.toggle();
          this.toasterService.error(
            `Error switching keyword state: ${error?.response ? error.response.message : "Unknown error"}`,
            "Top Of Search Rankings Optimizer Update Error",
          );
        },
      );
  }

  private keywordKey(adStats: AdStatsEx): string {
    return adStats.keywordText + "::" + adStats.matchType;
  }

  protected readonly STATUS_BAR = STATUS_BAR;
}
