import { StrategyEx, TacticType, MatchType } from "@front/m19-services";

const maxNumberOfTargets = 500;
const maxNumberOfNegativeKeywords = 250;
const maxNumberOfNegativeTargets = 500;

enum TargetingObjectType {
  Keywords = "Keywords",
  Asins = "Asins",
  Undefined = "",
}

function toTargetingObjectType(matchType: MatchType) {
  switch (matchType) {
    case MatchType.exact:
    case MatchType.phrase:
      return TargetingObjectType.Keywords;
    case MatchType.asinSameAs:
      return TargetingObjectType.Asins;
    default:
      return TargetingObjectType.Undefined;
  }
}

class TargetingCountInfo {
  count: number;
  tacticType: TacticType;
  objectType: TargetingObjectType;
}

export class TargetingLimit {
  private sumByObjectType: Array<TargetingCountInfo>;

  constructor(strategy: StrategyEx) {
    this.sumByObjectType = [];
    if (strategy) {
      strategy.countItems().forEach((matchTypeCounters: Map<MatchType, number>, tacticType: TacticType) => {
        const agg = new Map<TargetingObjectType, number>();
        matchTypeCounters.forEach((value: number, matchType: MatchType) => {
          const type = toTargetingObjectType(matchType);
          if (type != TargetingObjectType.Undefined) {
            agg.set(type, value + (agg.has(type) ? agg.get(type) : 0));
          }
        });
        agg.forEach((value: number, key: TargetingObjectType) => {
          this.sumByObjectType.push({ count: value, tacticType: tacticType, objectType: key });
        });
      });
    }
  }

  private limit(x: TargetingCountInfo) {
    if (x.tacticType == TacticType.BLACKLIST) {
      switch (x.objectType) {
        case TargetingObjectType.Keywords:
          return maxNumberOfNegativeKeywords;
        default:
          return maxNumberOfNegativeTargets;
      }
    }
    return maxNumberOfTargets;
  }

  private exceeded(): Array<TargetingCountInfo> {
    return this.sumByObjectType.filter((x) => x.count > this.limit(x));
  }

  public tooManyTargets(): boolean {
    return this.exceeded().length > 0;
  }

  public formatErrorMessage(): string {
    if (!this.tooManyTargets()) {
      return "";
    }
    const e = this.exceeded()[0];
    const type = e.tacticType == TacticType.BLACKLIST ? "blacklisted " : "";

    return (
      `Too many ${type}${e.objectType} in your tactics ` +
      `(${e.count} > limit=${this.limit(e)}). Extra ${e.objectType} will not be taken into account.`
    );
  }
}
