import { Component, Input, Pipe, PipeTransform } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  AlgoMode,
  ConfigService,
  Intensity,
  ProductGroupEx,
  Strategy,
  StrategyEx,
  StrategyTactic,
  StrategyTacticEx,
} from "@front/m19-services";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

@Pipe({ name: "tacticIntensityPipe" })
export class TacticIntensityPipe implements PipeTransform {
  transform(tactic: StrategyTactic): number {
    switch (tactic.intensity) {
      case Intensity.LOW:
        return 1;
      case Intensity.MEDIUM_LOW:
        return 2;
      case Intensity.NEUTRAL:
        return 3;
      case Intensity.MEDIUM_HIGH:
        return 4;
      case Intensity.HIGH:
        return 5;
    }
  }
}

@UntilDestroy()
@Component({
  selector: "app-strategy-tactics-card-details",
  templateUrl: "./strategy-tactics-card-details.component.html",
  styleUrls: ["./strategy-tactics-card-details.component.scss"],
})
export class StrategyTacticsCardDetailsComponent {
  _strategy: StrategyEx;
  strategyTactic: StrategyTacticEx;
  asinsNumber = 0;

  @Input()
  set strategy(strategy: Strategy) {
    this._strategy = strategy as StrategyEx;
    this.strategyTactic = strategy.tactics.length ? this._strategy.tactics[0] : undefined;
    if (strategy) {
      this.configurationService
        .getStrategyAsins(this._strategy)
        .pipe(untilDestroyed(this))
        .subscribe((asins) => {
          this.asinsNumber = asins.length;
        });
    }
  }

  readonly AlgoModeTypes = AlgoMode;
  readonly MaxAsins = ProductGroupEx.MaxProductGroupItems;
  readonly faWaring = faExclamationTriangle;

  constructor(private configurationService: ConfigService) {}
}
