<app-stats-overlay [checkOrganicDataAccess]="true" *transloco="let t">
  @if (lowInventoryAsins.length > 0) {
    <div class="inventory-warning alert alert-warning" role="alert">
      <div>
        <b>{{ t("inventory.low_inventory_warning_title", { count: lowInventoryAsins.length }) }}</b>
        @if (lowInventoryAsinsPaused.length === 0) {
          <span> {{ t("inventory.low_inventory_warning", { count: lowInventoryAsins.length }) }}</span>
        }
        @if (lowInventoryAsinsPaused.length > 0) {
          <span> {{ t("inventory.low_inventory_paused", { count: lowInventoryAsinsPaused.length }) }}</span>
        }
      </div>
      <div>
        <button
          class="btn"
          [ngClass]="filterLowInventory ? 'btn-warning' : 'btn-outline-warning'"
          matTooltip="{{
            filterLowInventory
              ? t('inventory.filter_low_inventory_tooltip_remove')
              : t('inventory.filter_low_inventory_tooltip_only')
          }}"
          (click)="toggleLowInventoryFilter()"
        >
          <fa-icon [icon]="faFilter" size="lg"></fa-icon>
        </button>
      </div>
    </div>
  }

  <div class="form-group row">
    <app-strategy-product-group-hybrid-dropdown
      class="col-sm-3"
      [strategies]="strategies"
      [productGroups]="productGroups"
      (selectedStrategyOrProductGroup)="setStrategyOrProductGroup($event)"
    ></app-strategy-product-group-hybrid-dropdown>
  </div>
  <app-inventory-table
    [currency]="currency"
    [locale]="locale"
    [data]="filteredInventoryStats"
    [marketplace]="marketplace"
    [accountId]="accountId"
    [loading]="loading"
    [inventoryRules]="inventoryRules"
  ></app-inventory-table>
</app-stats-overlay>
