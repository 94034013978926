import { NgTemplateOutlet } from '@angular/common';
import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';

export type IBadgeParams = {
  label?: string;
  color?: 'main' | 'red' | 'orange' | 'gray' | 'black' | 'green' | 'purple';
  variant?: 'solid' | 'outline' | 'soft' | 'subtle';
  size?: 'sm' | 'xs';
  tooltipValue?: string;
  icon?: string;
  rounded?: boolean;
};

@Component({
  selector: 'IBadge',
  standalone: true,
  imports: [MatTooltip, NgTemplateOutlet],
  template: `
    <span class="inline-flex items-center font-medium" [class]="cssConfig" [matTooltip]="tooltipValue">
      @if (icon) {
        <span [class]="icon + ' mr-1 text-sm'"></span>
      }
      @if (labelSlot) {
        <ng-container [ngTemplateOutlet]="labelSlot"></ng-container>
      } @else {
        <slot>{{ label }}</slot>
      }
    </span>
  `,
})
export class IBadgeComponent implements ICellRendererAngularComp, OnInit {
  @Input() label: string | undefined;

  @Input() set color(c: IBadgeParams['color']) {
    this._color = c;
    this.cssConfig = this.getClass()
      .concat(' ', this.getSizeConfig())
      .concat(' ', this.rounded ? 'rounded-full' : 'rounded-md');
  }

  _color: IBadgeParams['color'] = 'main';

  @Input() variant: IBadgeParams['variant'] = 'solid';
  @Input() size: IBadgeParams['size'] = 'sm';
  @Input() tooltipValue: string | undefined;
  @Input() icon: string | undefined;
  @Input() rounded: boolean = false;

  @ContentChild('labelSlot') labelSlot: TemplateRef<any> | undefined;

  cssConfig: string | undefined;

  agInit(params: ICellRendererParams & IBadgeParams): void {
    if (params.label) this.label = params.label;
    if (params.color) this._color = params.color;
    if (params.variant) this.variant = params.variant;
    if (params.size) this.size = params.size;
    if (params.tooltipValue) this.tooltipValue = params.tooltipValue;
    if (params.icon) this.icon = params.icon;
    if (params.rounded) this.rounded = params.rounded;
  }

  ngOnInit(): void {
    this.cssConfig = this.getClass()
      .concat(' ', this.getSizeConfig())
      .concat(' ', this.rounded ? 'rounded-full' : 'rounded-md');
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }

  getSizeConfig(): string {
    if (this.size === 'xs') {
      return 'text-xs px-1.5 py-0.5';
    }
    if (this.size === 'sm') {
      return `text-xs px-2 py-1`;
    }
    return '';
  }

  getClass(): string {
    if (this._color === 'gray') {
      return `ring-1 ring-inset ring-gray-300 text-gray-700 bg-gray-50`;
    } else if (this._color === 'black') {
      return 'text-white bg-gray-900';
    }
    if (this.variant === 'solid') {
      return `bg-${this._color}-500  text-white `;
    } else if (this.variant === 'outline') {
      return `text-${this._color}-500 ring-1 ring-inset ring-${this._color}-500 `;
    } else if (this.variant === 'soft') {
      return `bg-${this._color}-50  text-${this._color}-500`;
    } else if (this.variant === 'subtle') {
      return `bg-${this._color}-100 text-${this._color}-500 ring-1 ring-inset ring-${this._color}-500  ring-opacity-25`;
    }
    return '';
  }
}
