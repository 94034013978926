<ng-container *transloco="let t">
  <div class="tactic card-perso collapse-card border-shadow left-child right-child card">
    <div class="tactic card-perso-header collapse-card-header top-child card-header">
      <h6 class="inline-block">{{ _tactic.segment.name }}</h6>
      @if (_tactic.tacticType === "BLACKLIST") {
        <IBadge
          color="gray"
          [label]="t('common.blacklist') + ' ' + getSegmentTypeLabel()"
          [tooltipValue]="t('tactic.excludes_from_your_strategy', [getSegmentTypeDesc()])"
          class="ml-2"
          size="xs"
        />
      }
      @if (_tactic.tacticType !== "BLACKLIST") {
        <IBadge color="gray" [label]="getSegmentTypeLabel()" size="xs" class="ml-2" />
      }
      @if (segmentType === SegmentType.KeywordSegment && !showKW) {
        <span class="ml-2">
          <fa-icon
            [icon]="faWarning"
            class="warning"
            matTooltip="{{ t('tactic.keywords_targeting_are_not_supported_on_sd_strategies') }}"
          ></fa-icon>
          <span class="pl-1">t('tactic.keywords_targeting_are_not_supported_on_sd_strategies')</span>
        </span>
      }
      <div class="float-right flex items-center">
        @if (nbKeywords() && showKW) {
          <span class="counter"> #{{ t("common.keywords") }}: {{ nbKeywords() }} </span>
        }
        @if (nbProductTargeting()) {
          <span class="counter"> #{{ t("common.products") }}: {{ nbProductTargeting() }} </span>
        }
        @if (segmentType === SegmentType.ProductSegment && !showProducts) {
          <span class="ml-2">
            <fa-icon
              [icon]="faWarning"
              class="warning"
              matTooltip="{{ t('tactic.product_targeting_has_been_disabled_for_this_strategy') }}"
            ></fa-icon>
            <span class="pl-1">{{ t("tactic.product_targeting_has_been_disabled_for_this_strategy") }}</span>
          </span>
        }
        @if (!(!_strategy.autoAlgoExplorationEnabled && lastProductKeyword())) {
          <IButton
            [disabled]="isReadOnly"
            testid="btn-delete-tactic"
            (onClick)="!isReadOnly && removeTacticFromStrategy(); $event.stopPropagation()"
            color="red"
            variant="ghost"
            [icon]="ICON_TRASH"
          />
        }
        @if (!_strategy.autoAlgoExplorationEnabled && lastProductKeyword()) {
          <span
            class="icon-delete-append-disabled line-height-1"
            matTooltip="{{ t('tactic.disabled_auto_keyword') }}"
            (click)="$event.stopPropagation()"
          >
            <fa-icon [icon]="faTrash" class="font-xl"></fa-icon>
          </span>
        }
        <IButton (onClick)="editSegment()" color="gray" variant="ghost" [square]="true" [icon]="ICON_EDIT" />
      </div>
      <br />
      @if (_tactic.tacticType !== "BLACKLIST") {
        <div class="edit-param mb-2 ml-2 mt-4" (click)="$event.stopPropagation()">
          @if (_strategy.algoMode !== AlgoModeTypes.PRODUCT_LAUNCH) {
            <h6 class="mb-0 inline-block pt-1 align-top">{{ t("tactic.intensity") }}</h6>
          }
          @if (_strategy.algoMode !== AlgoModeTypes.PRODUCT_LAUNCH) {
            <div class="intensity custom-width-18 ml-4 inline-block align-top">
              <input
                type="range"
                #intensity
                value="{{ getIntensity() }}"
                (mouseup)="setIntensity(intensity.value)"
                [disabled]="isReadOnly"
                min="1"
                max="5"
                step="1"
                class="slider"
              />
              <div class="labels">
                <span class="label green">
                  <span class="indication" (click)="setIntensity(1)">{{ t("common.low") }}</span>
                </span>
                <span class="label"></span>
                <span class="label gray">
                  <span class="indication" (click)="setIntensity(3)">{{ t("common.neutral") }}</span>
                </span>
                <span class="label"></span>
                <span class="label red">
                  <span class="indication" (click)="setIntensity(5)">{{ t("common.high") }}</span>
                </span>
              </div>
            </div>
          }
          @if (boostTopSearchEnabled && nbKeywords() > 0) {
            <div class="flex items-center justify-end">
              <div class="top-of-search-label mr-4">{{ t("tactic.only_top_of_search_placement") }}</div>
              <app-switch-input
                [disabled]="isReadOnly"
                [checked]="_tactic.boostPlacementTop"
                (onInput)="switchBoostPlacementTop()"
                switchId="tactic_{{ _tactic.segmentId }}"
              ></app-switch-input>
            </div>
          }
        </div>
      }
    </div>
  </div>
</ng-container>
