import { Component, EventEmitter, Input, OnInit, Output, inject } from "@angular/core";
import { AccountMarketplace, AlgoMode, AuthService, CurrencyService } from "@front/m19-services";
import { AlgoModeConfig } from "@m19-board/strategies/strategies/algo-mode-selection/algo-mode-selection.component";
import { FormStepEvent, SbFormStep } from "../sb-strategy-form.component";

export interface SbAlgo {
  algoMode: AlgoMode;
  acosTarget: number;
  dailyBudget: number;
  monthlyBudget: number;
  suggestedBid: number;
}

@Component({
  selector: "app-sb-form-algo",
  templateUrl: "./sb-form-algo.component.html",
})
export class SbFormAlgoComponent implements OnInit {
  private readonly currencyService = inject(CurrencyService);
  private readonly authService = inject(AuthService);

  @Input() set am(a: AccountMarketplace) {
    if (a) {
      this._am = a;
      this.currencyCode = this.currencyService.getCurrencyCode(a.marketplace);
    }
  }

  _am: AccountMarketplace;

  @Input() set sbAlgo(i: SbAlgo) {
    this.algoConfig = i;
    if (i) this.isValid = true;
  }

  algoConfig: AlgoModeConfig;
  isValid = false;

  @Input() reviewMode = false;

  @Output() onFormSubmit = new EventEmitter<FormStepEvent<SbAlgo>>();
  @Output() onFormPrevious = new EventEmitter<void>();

  currencyCode: string;
  locale: string;

  ngOnInit(): void {
    this.authService.loggedUser$.subscribe((user) => {
      this.locale = user.locale;
    });
  }

  submitForm(goNext = false) {
    if (this.isValid && !this.reviewMode) {
      this.onFormSubmit.emit({ formData: this.algoConfig as SbAlgo, step: SbFormStep.ALGO, goNext });
    }
  }

  newAlgoConfig(config: Partial<AlgoModeConfig>) {
    this.isValid = config.isValid;
    if (config.isValid) this.algoConfig = config as AlgoModeConfig;
    this.submitForm();
  }

  readonly AlgoMode = AlgoMode;
}
