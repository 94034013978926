import { Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { CampaignType, SegmentEx, StrategyEx } from "@front/m19-services";
import { BsModalRef } from "ngx-bootstrap/modal";
import { StrategyCache } from "libs/m19-services/src/lib/m19-services/strategy.cache";

@UntilDestroy()
@Component({
  selector: "app-segment-detail-strategies-modal",
  templateUrl: "./segment-detail-strategies-modal.component.html",
})
export class SegmentDetailStrategiesModalComponent implements OnInit {
  @Input()
  segment: SegmentEx;

  linkedStrategies: StrategyEx[] = [];

  readonly CampaignType = CampaignType;

  constructor(
    public bsModalRef: BsModalRef,
    private strategyCache: StrategyCache,
  ) {}

  ngOnInit(): void {
    this.strategyCache.strategiesBySegmentId$
      .pipe(untilDestroyed(this))
      .subscribe((x) => (this.linkedStrategies = x.get(this.segment.segmentId) ?? []));
  }
}
