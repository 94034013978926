import { Component } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AccountSelectionService, AccountType } from "@front/m19-services";

@UntilDestroy()
@Component({
  template: `@if (accountType === AccountType.VENDOR) {
      <app-sales-advertising [accountType]="AccountType.VENDOR"></app-sales-advertising>
    } @else {
      <app-sales-advertising [accountType]="AccountType.SELLER"></app-sales-advertising>
    }`,
})
export class SalesAdvertisingPageComponent {
  readonly AccountType = AccountType;
  accountType?: string;

  constructor(private accountSelectionService: AccountSelectionService) {
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(untilDestroyed(this))
      .subscribe((accountMarketplace) => {
        this.accountType = accountMarketplace.accountType;
      });
  }
}
