<div
  class="relative"
  (mouseenter)="submenu = true"
  (mouseleave)="submenu = false"
  [attr.data-testid]="'card-' + _strategy.strategyName"
  *transloco="let t"
>
  @if (submenu || submenuhover) {
    <div class="submenu btn-group" (mouseenter)="submenuhover = true" (mouseleave)="submenuhover = false">
      <button
        type="button"
        class="submenu-btn btn btn-secondary"
        [disabled]="isReadOnly"
        (click)="changeStatus()"
        matTooltipPosition="above"
        matTooltip="{{
          _strategy.state === 'ENABLED'
            ? t('sp-strategy-group-card.pause_strategy')
            : t('sp-strategy-group-card.activate_strategy')
        }}"
      >
        <fa-icon [icon]="_strategy.state === 'ENABLED' ? faPause : faPlay"></fa-icon>
      </button>
      @if (canDelete) {
        <button
          data-testid="btn-delete"
          [disabled]="isReadOnly"
          type="button"
          class="submenu-btn btn btn-secondary"
          (click)="deleteStrategy()"
          matTooltipPosition="above"
          matTooltip="{{ t('strategy-card.delete_strategy') }}"
        >
          <fa-icon [icon]="faTrash"></fa-icon>
        </button>
      }
    </div>
  }

  <div
    class="strategy card-perso left-child right-child border-shadow card mb-0 align-top"
    [routerLink]="strategyPagePrefix + strategyPage + '/' + _strategy.strategyId"
    queryParamsHandling="merge"
  >
    <div class="strategy-status card-perso-header top-child card-header pb-0 pr-2">
      <div
        class="pb-0 pr-0 pt-0"
        matTooltipPosition="above"
        [matTooltip]="
          _strategy.state === 'ENABLED' ? t('subscription-card.status_active') : t('creative-display.status_inactive')
        "
      >
        @if (_strategy.state === "ENABLED") {
          <fa-icon [icon]="faPlayCircle" class="activated"></fa-icon>
        }
        @if (_strategy.state !== "ENABLED") {
          <fa-icon [icon]="faPauseCircle" class="desactivated"></fa-icon>
        }
      </div>
      <app-target-limit-warning [targetLimit]="targetLimit" class="error pb-0 pr-0 pt-0"></app-target-limit-warning>
      @if (!_strategy.autoAlgoExplorationEnabled && _strategy.campaignType !== CampaignType.SD) {
        <app-disable-auto-warning
          class="sc-warning ml-1 pb-0 pr-0 pt-0"
          [campaignType]="_strategy.campaignType"
        ></app-disable-auto-warning>
      }
      <app-strategy-warning [strategy]="_strategy"></app-strategy-warning>
      <h5 class="card-name mb-0 ml-2 mr-2" [matTooltip]="_strategy.getName()" matTooltipPosition="below">
        {{ _strategy.getName() }}
      </h5>
      <div class="fbl-status">
        @if (algoMode === AlgoMode.ACOS_TARGET) {
          <app-fbl-mode [strategy]="_strategy" [size]="FblModeSize.small"></app-fbl-mode>
        }
      </div>
      @if (_strategy.primeDayBoost && (boostActivated$ | async)) {
        <span
          [class]="ICON_BOOST"
          class="text-main ml-3 text-xl"
          matTooltip="{{ t('strategy-card.boost_has_been_activated_on_this_strategy') }}"
        ></span>
      }
    </div>
    <div class="card-perso-body bottom-child card-body">
      <app-algo-target-renderer
        [algoTarget]="_strategy"
        [locale]="locale"
        [currency]="currency"
        [readonly]="isReadOnly"
        [addLabel]="true"
      ></app-algo-target-renderer>
      <ng-content></ng-content>
    </div>
  </div>
</div>
