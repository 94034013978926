import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import {
  AccountMarketplace,
  AccountSelectionService,
  ACOS,
  AD_CONVERSIONS,
  AD_SALES,
  addAdStats,
  AdStatsData,
  AdStatsEx,
  AuthService,
  CampaignType,
  CLICK_THROUGH_RATE,
  CLICKS,
  CONVERSION_RATE,
  COST,
  CPC,
  DataSet,
  DataSetEventAnnotation,
  DateAggregation,
  groupBy,
  IMPRESSIONS,
  mergeSeveralDates,
  Metric,
  MetricsSelectorLocalStorageKey,
  MetricType,
  ROAS,
  StatsApiClientService,
  StrategyEx,
  UserSelectionService,
} from "@front/m19-services";
import { Option } from "@front/m19-ui";
import { ActivityEventType, ActivityService } from "@m19-board/activities/activity.service";
import { getCsvFileName } from "@m19-board/grid-config/grid-config";
import { DonutDataSet } from "@m19-board/models/DonutDataSet";
import { SwitchButtonType } from "@m19-board/shared/switch-button/switch-button.component";
import { ICON_CHART_LINE, ICON_CLOSE, ICON_EXPORT } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Chart, ChartOptions, LegendItem } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { StrategyCache } from "libs/m19-services/src/lib/m19-services/strategy.cache";
import { BaseChartDirective } from "ng2-charts";
import { BehaviorSubject, combineLatest, Observable, of } from "rxjs";
import { filter, map, share, switchMap } from "rxjs/operators";
import { PALETTE, StrategyStats, toStrategyStats } from "../../models/Metric";
import { OverviewGridComponent } from "./overview-grid.component";

export const CAMPAIGN_TYPE_NAME = {
  [CampaignType.SP]: "Sponsored Products",
  [CampaignType.SB]: "Sponsored Brands",
  [CampaignType.SD]: "Sponsored Display",
  [CampaignType.SDR]: "Sponsored Display Remarketing",
};

export const CAMPAIGN_TYPE_COLOR = {
  [CampaignType.SP]: PALETTE[0],
  [CampaignType.SB]: PALETTE[1],
  [CampaignType.SD]: PALETTE[3],
  [CampaignType.SDR]: PALETTE[4],
};

export const CAMPAIGN_TYPE_BADGE_COLOR = {
  [CampaignType.SP]: "black",
  [CampaignType.SB]: "green",
  [CampaignType.SD]: "orange",
};

@UntilDestroy()
@Component({
  selector: "app-overview",
  templateUrl: "./overview.component.html",
  styleUrls: ["./overview.component.scss"],
})
export class InsightOverviewComponent implements OnInit {
  readonly RATIO = MetricType.RATIO;
  readonly STRATEGY_GRID_KEY = "overviewGrid";

  readonly defaultGenerateLabels: (chart: Chart) => LegendItem[] =
    Chart.overrides.polarArea.plugins.legend.labels.generateLabels;
  readonly localStorageKey = MetricsSelectorLocalStorageKey.overview;
  readonly chartPlugins = [ChartDataLabels];
  readonly CampaignTypes = [CampaignType.SP, CampaignType.SB, CampaignType.SD, CampaignType.SDR];
  readonly SwitchButtonType = SwitchButtonType;
  readonly chartDisplayModeOptions: ("lineChart" | "donuts")[] = ["donuts", "lineChart"];
  readonly ICON_CLOSE = ICON_CLOSE;
  readonly ICON_EXPORT = ICON_EXPORT;
  readonly ICON_CHART_LINE = ICON_CHART_LINE;
  chartDisplayMode: "lineChart" | "donuts";
  dateAggregation$: BehaviorSubject<DateAggregation> = new BehaviorSubject<DateAggregation>(DateAggregation.daily);
  displayEventAnnotation$ = new BehaviorSubject(false);
  disableEventAnnotation = false;
  allEventAnnotationTypes: Option<ActivityEventType>[] = this.activityService.allActivityEventTypesOptions;
  eventAnnotationTypes$: Observable<Option<ActivityEventType>[]> = this.activityService.selectedActivityTypesOptions$;
  allUsers$: Observable<Option<string>[]> = this.activityService.allUsersOptions$;
  selectedUsers$: Observable<Option<string>[]> = this.activityService.selectedUsersOptions$;
  allStrategies$: Observable<Option<StrategyEx>[]> = this.activityService.allStrategiesOptions$;
  selectedStrategies$: Observable<Option<StrategyEx>[]> = this.activityService.selectedStrategiesOptions$;

  @ViewChild("strategyChart")
  strategyChart: BaseChartDirective;

  @ViewChild(OverviewGridComponent) overviewGrid: OverviewGridComponent;

  accountMarketplace: AccountMarketplace;

  selectedMetric$: BehaviorSubject<Metric<StrategyStats>[]>;
  selectedMetrics: Metric<StrategyStats>[] = [];
  mainMetric: Metric<StrategyStats>;
  globalData: AdStatsEx;
  previousPeriodGlobalData: AdStatsEx = {};

  chartDisplayed = true;

  /** CampaignType pieChart data **/
  campaignTypeDonutDataSet = new DonutDataSet<AdStatsEx>(
    addAdStats,
    (x) => ({
      // merge SD and SDR in the donut chart
      key: x.campaignType === CampaignType.SDR ? CampaignType.SD : x.campaignType,
      label: CAMPAIGN_TYPE_NAME[x.campaignType],
      color: CAMPAIGN_TYPE_COLOR[x.campaignType],
    }),
    (donutChartOptions: ChartOptions) => {
      donutChartOptions.plugins.legend.position = "bottom";
    },
  );

  /** Strategy pieChart data **/
  strategyDonutDataSet = new DonutDataSet<StrategyStats>(
    this.addStrategyStats,
    (x) => ({
      key: x.strategyName,
      label: x.strategyName,
    }),
    (donutChartOptions: ChartOptions) => {
      donutChartOptions.plugins.legend.position = "bottom";
    },
    8,
    "Other Strategies",
  );

  // line chart configuration
  globalDataset: DataSet<AdStatsEx>;

  readonly CHART_METRICS: Metric<StrategyStats>[] = [
    AD_SALES,
    AD_CONVERSIONS,
    COST,
    ACOS,
    CLICKS,
    IMPRESSIONS,
    CLICK_THROUGH_RATE,
    CONVERSION_RATE,
    CPC,
    ROAS,
  ];

  constructor(
    private authService: AuthService,
    private statsService: StatsApiClientService,
    private strategyCache: StrategyCache,
    private userSelectionService: UserSelectionService,
    private accountSelectionService: AccountSelectionService,
    private activityService: ActivityService,
  ) {
    this.selectedMetric$ = new BehaviorSubject<Metric<StrategyStats>[]>([AD_SALES, COST]);
    this.globalDataset = new DataSet<AdStatsEx>(4, this.selectedMetric$.getValue(), mergeSeveralDates);
    this.chartDisplayMode = this.userSelectionService.getUserOverviewChartTypePreference();
  }

  ngOnInit(): void {
    this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(untilDestroyed(this)).subscribe((am) => {
      this.accountMarketplace = am;
    });
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.campaignTypeDonutDataSet.locale = user.locale;
      this.strategyDonutDataSet.locale = user.locale;
      this.globalDataset.locale = user.locale;
    });
    this.userSelectionService.selectedCurrency$.pipe(untilDestroyed(this)).subscribe((currency) => {
      this.campaignTypeDonutDataSet.currency = currency;
      this.strategyDonutDataSet.currency = currency;
      this.globalDataset.currency = currency;
    });

    const mainMetric$ = this.selectedMetric$.pipe(map((m) => (m.length === 1 ? m[0] : m[1])));
    mainMetric$.pipe(untilDestroyed(this)).subscribe((x) => {
      this.mainMetric = x;
    });

    this.chartDisplayed = this.userSelectionService.getUserChartDisplayedPreference(this.localStorageKey);
    const campaignTypeStats$ = this.statsService.dailyPlacementStats$.pipe(
      map((data: AdStatsData) => groupBy<CampaignType>(data.data, (x) => x.campaignType, this.CampaignTypes)),
    );
    const previousPeriodCampaignTypeStats$: Observable<Map<CampaignType, AdStatsEx>> =
      this.statsService.previousPeriodDailyPlacementStats$.pipe(
        map((data: AdStatsData) => groupBy<CampaignType>(data.data, (x) => x.campaignType, this.CampaignTypes)),
      );
    campaignTypeStats$.pipe(untilDestroyed(this)).subscribe((data) => {
      this.globalData = {};
      for (const d of data.values()) mergeSeveralDates(this.globalData, d);
    });
    previousPeriodCampaignTypeStats$.pipe(untilDestroyed(this)).subscribe((data) => {
      this.previousPeriodGlobalData = {};

      for (const d of data.values()) {
        if (Object.keys(d).length != 0) mergeSeveralDates(this.previousPeriodGlobalData, d);
      }
    });
    const strategyStats$ = this.statsService.dailyPlacementStats$.pipe(
      map((data) => ({
        ...data,
        data: Array.from(groupBy<number>(data.data, (x) => x.strategyId).values()).map((x) => {
          x.placement = undefined;
          return x;
        }),
      })),
    );

    const sortedStrategyStats$ = combineLatest([strategyStats$, this.strategyCache.strategyIndex$]).pipe(
      filter(([data, strategyIndex]) => {
        if (strategyIndex.size == 0) return true;
        for (const strat of strategyIndex.values()) {
          if (
            strat.accountId === data.accountMarketplace.accountId &&
            strat.marketplace === data.accountMarketplace.marketplace
          )
            return true;
        }
        return false;
      }),
      map(([data, strategyIndex]) => data.data.map((x) => toStrategyStats(x, strategyIndex))),
      share(),
    );

    combineLatest([this.statsService.dailyPlacementStats$, mainMetric$])
      .pipe(untilDestroyed(this))
      .subscribe(([data, metric]) => this.buildCampainTypeDataSet(data.data, metric));

    combineLatest([
      this.statsService.dailyPlacementStats$,
      this.selectedMetric$,
      this.dateAggregation$,
      this.displayEventAnnotation$.pipe(
        switchMap((displayEventAnnotation) =>
          displayEventAnnotation ? this.activityService.activityEventsAnnotations$ : of([]),
        ),
      ),
      this.statsService.previousPeriodDailyPlacementStats$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([data, metrics, aggregation, activityEventsAnnotations, previousData]) =>
        this.buildLineChartDataSet(data, metrics, aggregation, previousData, activityEventsAnnotations),
      );

    combineLatest([sortedStrategyStats$, mainMetric$])
      .pipe(untilDestroyed(this))
      .subscribe(([data, metric]) => this.buildStrategyPieChartDataSet(data, metric));

    this.dateAggregation$.pipe(untilDestroyed(this)).subscribe((aggregation) => {
      this.disableEventAnnotation = aggregation !== DateAggregation.daily;
    });
  }

  buildCampainTypeDataSet(data: AdStatsEx[], metric: Metric<StrategyStats>): void {
    this.campaignTypeDonutDataSet.buildDataSet(data, metric);
  }

  buildStrategyPieChartDataSet(data: StrategyStats[], metric: Metric<StrategyStats>): void {
    const sorted = [...data].sort((a, b) => metric.compare(a, b));

    this.strategyDonutDataSet.buildDataSet(sorted, metric);
  }

  buildLineChartDataSet(
    data: AdStatsData,
    metrics: Metric<StrategyStats>[],
    aggregation: DateAggregation,
    previousData: AdStatsData,
    annotations: DataSetEventAnnotation[],
  ): void {
    this.globalDataset.buildDataSet(
      data.data,
      metrics,
      aggregation,
      {
        minDate: data.dateRange[0],
        maxDate: data.dateRange[1],
      },
      previousData?.dateRange?.length > 1
        ? {
            data: previousData.data,
            period: previousData.dateRange,
          }
        : undefined,
      annotations,
    );
  }

  addStrategyStats(stats1: StrategyStats, stats2: StrategyStats): StrategyStats {
    return {
      ...stats1,
      ...addAdStats(stats1, stats2),
    };
  }

  exportGridCsv(): void {
    const fileName = getCsvFileName(
      "strategy_stats",
      this.accountMarketplace.accountGroupName,
      this.accountMarketplace.marketplace,
      this.userSelectionService.getDateRangeStr(),
    );
    this.overviewGrid.exportGridCsv(fileName);
  }

  restoreDefaultColumns(): void {
    this.overviewGrid.restoreDefaultColumns();
  }

  selectMetrics(metrics: Metric<StrategyStats>[]): void {
    this.selectedMetric$.next(metrics);
  }

  toggleChartDisplay(displayed: boolean): void {
    this.chartDisplayed = displayed;
    this.userSelectionService.setUserChartDisplayedPreference(this.localStorageKey, displayed);
  }

  toggleChartDisplayMode(chartDisplayMode: "lineChart" | "donuts"): void {
    this.chartDisplayMode = chartDisplayMode;
    this.userSelectionService.setUserOverviewChartTypePreference(chartDisplayMode);
  }

  selectAggregation(aggregation: DateAggregation): void {
    this.dateAggregation$.next(aggregation);
  }

  toggleEventAnnotation(change: MatSlideToggleChange): void {
    this.displayEventAnnotation$.next(change.checked);
  }
}
