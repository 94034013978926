import { ReplaySubject } from 'rxjs';
import { SbCreativeEx, SegmentEx } from './models';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StrategyCacheReloaded {
  public readonly segmentIndexReloaded: ReplaySubject<Map<number, SegmentEx>> = new ReplaySubject(1);
  public readonly sbCreativeIndexReloaded: ReplaySubject<Map<number, SbCreativeEx>> = new ReplaySubject(1);
}
