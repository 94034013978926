import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Marketplace, MatchType, SegmentEx, StrategyAsin, Targeting } from "@front/m19-services";
import { StrategyAsinSelectionMode } from "@m19-board/strategies/strategy-asins/asins-selection.component";
import { SegmentService } from "libs/m19-services/src/lib/m19-services/segmentService";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { of, switchMap } from "rxjs";

@Component({
  selector: "app-product-segment-modal",
  templateUrl: "./product-segment-modal.component.html",
})
export class ProductSegmentModalComponent implements OnInit {
  @Input()
  set segment(value: SegmentEx) {
    this.existingSegment = value;
    this.name = value.name;
    this.asinsList = value.items
      .filter((a) => a.matchType == MatchType.asinSameAs)
      .map((a) => ({
        asin: a.targetingValue,
      }));
    this.marketplace = value.marketplace;
    this.accountId = value.accountId;
  }

  @Input()
  accountId: string;
  @Input()
  marketplace: Marketplace;
  @Input()
  initSegmentItems: Targeting[] = [];
  @Input() isReadOnly = false;

  @Output()
  segmentCreated = new EventEmitter<SegmentEx>();

  @Output()
  segmentEditionCanceled = new EventEmitter<void>();

  readonly productSelectionModes = [
    { selectionMode: StrategyAsinSelectionMode.FromCatalog, label: "Catalog" },
    { selectionMode: StrategyAsinSelectionMode.Bulk, label: "ASIN list" },
    { selectionMode: StrategyAsinSelectionMode.FromProductGroups, label: "Product Group" },
  ];

  existingSegment: SegmentEx;
  name: string;
  asinsList: StrategyAsin[] = [];

  constructor(
    public bsModalRef: BsModalRef,
    private segmentService: SegmentService,
    private toastrService: ToastrService,
  ) {}

  ngOnInit(): void {
    if (this.initSegmentItems.length) {
      this.asinsList = this.initSegmentItems.map((i) => ({
        asin: i.targetingValue,
      }));
    }
  }

  addAsins(asins: StrategyAsin[]) {
    this.asinsList = Array.from(new Set(this.asinsList.concat(asins).values()).values());
  }

  deleteAsins(asins: StrategyAsin[]) {
    this.asinsList = this.asinsList.filter((a) => !asins.map((a2) => a2.asin).includes(a.asin));
  }

  saveSegment() {
    if (this.existingSegment) {
      this.updateSegment();
    } else {
      this.createSegment();
    }
  }

  close() {
    this.segmentEditionCanceled.emit();
    this.bsModalRef.hide();
  }

  private updateSegment() {
    const subscription = (
      this.name !== this.existingSegment.name
        ? this.segmentService.updateSegmentName(this.existingSegment.segmentId, this.name)
        : of(this.existingSegment)
    )
      .pipe(
        switchMap((segment) => {
          const asinsToDelete = [];
          for (const asin of this.existingSegment.getProductTargetingItems()) {
            if (this.asinsList.findIndex((s) => s.asin == asin.targetingValue) < 0) {
              asinsToDelete.push(asin);
            }
          }
          if (asinsToDelete.length == 0) {
            return of(segment);
          }
          return this.segmentService.deleteItemsFromSegment(this.existingSegment.segmentId, asinsToDelete);
        }),
        switchMap((segment) => {
          const asinsToAdd = [];
          for (const asin of this.asinsList) {
            if (this.existingSegment.getProductTargetingItems().findIndex((s) => s.targetingValue == asin.asin) < 0) {
              asinsToAdd.push(asin.asin);
            }
          }
          if (asinsToAdd.length == 0) {
            return of(segment);
          }
          return this.segmentService.addAsinsToSegment(this.existingSegment.segmentId, asinsToAdd);
        }),
      )
      .subscribe({
        next: (s) => {
          this.toastrService.success(`Segment ${s.name} updated`, "Segment updated");
          this.bsModalRef.hide();
        },
        error: (err) => {
          this.toastrService.error("Error updating segment: " + err, "Segment update error");
          subscription.unsubscribe();
        },
      });
  }

  private createSegment() {
    const subscription = this.segmentService
      .createSegment(this.accountId, this.name, this.marketplace)
      .pipe(
        switchMap((segment: SegmentEx) => {
          const asinsToAdd = this.asinsList.map((a) => a.asin);
          return this.segmentService.addAsinsToSegment(segment.segmentId, asinsToAdd);
        }),
      )
      .subscribe({
        next: (s) => {
          this.toastrService.success(`Segment ${s.name} created`, "Segment created");
          this.segmentCreated.emit(s);
          this.bsModalRef.hide();
        },
        error: (err) => {
          this.toastrService.error("Error creating segment: " + err, "Segment creation error");
          subscription.unsubscribe();
        },
      });
  }

  isInvalid() {
    return !this.name || this.asinsList.length == 0;
  }

  invalidTooltip() {
    if (!this.name) {
      return "Enter a name for this product segment";
    }
    if (this.asinsList.length == 0) {
      return "Set ASINs for this product segment";
    }
    return "";
  }
}
