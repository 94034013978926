import { Component } from "@angular/core";
import { EntityIdType } from "@front/m19-services";

@Component({
  selector: "app-activities-component",
  template: "<app-activity-component [activityFilter]='ActivityFilter'></app-activity-component>",
})
export class ActivitiesComponent {
  ActivityFilter = [
    {
      primaryType: EntityIdType.strategyId,
    },
    {
      primaryType: EntityIdType.creativeId,
    },
    {
      primaryType: EntityIdType.segmentId,
    },
    {
      primaryType: EntityIdType.strategyGroupId,
    },
    {
      primaryType: EntityIdType.audienceTargetId,
    },
    {
      primaryType: EntityIdType.profileId,
    },
  ];
}
