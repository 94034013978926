import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { Marketplace, Product, SearchTermAsinRank } from '../api-client';
import { Utils } from './utils';
import { ProductEx } from '../models';

export enum RankOption {
  ORGANIC = 'common.orgqnic',
  SPONSORED = 'common.sponsored',
  GLOBAL = 'sov-pie-chart.global',
  BOTH = 'common.organic_sponsored',
}

export const RANK_OPTIONS_ARRAY = [RankOption.ORGANIC, RankOption.SPONSORED, RankOption.GLOBAL, RankOption.BOTH];
export const RANK_OPTIONS_DESC = ['Organic Rank', 'Sponsored Rank', 'Global Rank', 'Organic/Sponsored Rank'];

function getAmazonUrl(marketplace: Marketplace) {
  return 'https://www.amazon.' + Utils.getTopDomainFromMarketplace(marketplace);
}

export function getAmazonProductUrl(product: ProductEx) {
  return getAmazonUrl(product.marketplace!) + '/dp/' + product.asin;
}

export function getAmazonProductUrlForMarketplace(asin: string, marketplace: Marketplace) {
  return getAmazonUrl(marketplace) + '/dp/' + asin;
}

export function getAmazonSearchUrl(search: string, marketplace: Marketplace) {
  return getAmazonUrl(marketplace) + '/s?k=' + search;
}

@Pipe({ name: 'amazonAsinUrlPipe' })
export class AmazonAsinUrlPipe implements PipeTransform {
  transform(asin: string, marketplace: Marketplace): string {
    return asin ? getAmazonProductUrlForMarketplace(asin, marketplace) : '';
  }
}

@Pipe({ name: 'amazonProductUrlPipe' })
export class AmazonProductUrlPipe implements PipeTransform {
  transform(product: ProductEx | undefined | null): string {
    return product ? getAmazonProductUrl(product) : '';
  }
}

@Pipe({ name: 'asinAndTitle' })
export class AsinAndTitlePipe implements PipeTransform {
  transform(product: ProductEx): string {
    if (product?.asin == undefined) return '';
    let ret = product.asin;
    if (product.title) {
      ret = ret + ' - ' + product.title;
    }
    return ret;
  }
}

@Pipe({ name: 'amazonProductImageUrl' })
export class AmazonProductImageUrlPipe implements PipeTransform {
  transform(product: Product | undefined | null, size = 75): string {
    const imageId = product?.imageIds?.[0];
    if (imageId) return `https://m.media-amazon.com/images/I/${imageId}._SL${size}_.jpg`;
    else {
      switch (product?.marketplace ?? Marketplace.US) {
        case 'FR':
          return 'https://images-na.ssl-images-amazon.com/images/G/08/x-site/icons/no-img-sm._CB1275507936_.gif';
        case 'ES':
          return 'https://images-na.ssl-images-amazon.com/images/G/30/x-site/icons/no-img-sm._CB1303168197_.gif';
        case 'DE':
          return 'https://images-na.ssl-images-amazon.com/images/G/03/x-site/icons/no-img-sm._CB1275617821_.gif';
          break;
        case 'IT':
          return 'https://images-na.ssl-images-amazon.com/images/G/29/x-site/icons/no-img-sm._CB1547651849_._SL75_.gif';
        default:
          return 'https://images-na.ssl-images-amazon.com/images/I/01RmK+J4pJL._SL75_.gif';
      }
    }
  }
}

@Pipe({ name: 'amazonSearchUrlPipe' })
export class AmazonSearchUrlPipe implements PipeTransform {
  transform(search: string, marketplace?: Marketplace): string {
    if (!marketplace) {
      return '';
    }
    return getAmazonSearchUrl(search, marketplace);
  }
}

@Pipe({ name: 'arraySortPipe' })
export class ArraySortPipe implements PipeTransform {
  transform<T>(array: T[], sortFunction: () => number): T[] {
    return array.sort(sortFunction);
  }
}

@Pipe({
  name: 'map',
})
export class MapPipe implements PipeTransform {
  transform<T, U>(input: T[], fn: (T: T) => U): U[] {
    return input.map(fn);
  }
}

@Pipe({ name: 'maxAllowedBidPipe' })
export class MaxAllowedBidPipe implements PipeTransform {
  transform(dailyBudget: number): number {
    return Math.floor((dailyBudget / 5) * 100 + Number.EPSILON) / 100;
  }
}

export function getRankFromOption(r: SearchTermAsinRank, option: RankOption): number | undefined {
  if (!r) return undefined;
  switch (option) {
    case RankOption.ORGANIC:
      return r.organic;
    case RankOption.SPONSORED:
      return r.sp;
    case RankOption.GLOBAL:
      return r.global;
  }
  return undefined;
}

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {
  transform(value: string, limitArg?: number, trailArg?: string): string {
    const limit = limitArg ?? 20;
    const trail = trailArg ?? '…';
    return value.length > limit ? value.substring(0, limit) + trail : value;
  }
}

@Pipe({ name: 'searchTextFilter' })
export class SearchTextFilterPipe implements PipeTransform {
  transform<T>(array: T[], value: (value: T) => string, filter: string): T[] {
    if (!filter) return array;
    const regex = new RegExp(filter, 'i');
    if (typeof value === 'function') return array.filter((v) => value(v).search(regex) > -1);
    return array.filter((v) => String(v).search(regex) > -1);
  }
}

@NgModule({
  imports: [],
  exports: [
    AmazonAsinUrlPipe,
    AmazonProductImageUrlPipe,
    AmazonProductUrlPipe,
    AmazonSearchUrlPipe,
    ArraySortPipe,
    AsinAndTitlePipe,
    MapPipe,
    TruncatePipe,
    SearchTextFilterPipe,
  ],
  declarations: [
    AmazonAsinUrlPipe,
    AmazonProductImageUrlPipe,
    AmazonProductUrlPipe,
    AmazonSearchUrlPipe,
    ArraySortPipe,
    AsinAndTitlePipe,
    MapPipe,
    TruncatePipe,
    SearchTextFilterPipe,
  ],
})
export class UtilityPipesModule {}
