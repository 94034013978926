import { StrategyTactic, SegmentEx } from '..';
import { Intensity, TacticType } from '../api-client';

export class StrategyTacticEx implements StrategyTactic {
  segmentId: number;
  segment: SegmentEx;
  tacticType: TacticType;
  intensity: Intensity;
  boostPlacementTop: boolean;

  constructor(strategyTactic: StrategyTactic, tactic: SegmentEx) {
    this.segmentId = strategyTactic.segmentId!;
    this.tacticType = strategyTactic.tacticType!;
    this.intensity = strategyTactic.intensity!;
    this.boostPlacementTop = strategyTactic.boostPlacementTop!;
    this.segment = tactic;
  }

  public toStrategyTactic(): StrategyTactic {
    return {
      segmentId: this.segmentId,
      tacticType: this.tacticType,
      intensity: this.intensity,
      boostPlacementTop: this.boostPlacementTop,
    };
  }
}

export const IntensityDesc: { [key in Intensity]: string } = {
  LOW: 'Low',
  MEDIUM_LOW: 'Medium Low',
  NEUTRAL: 'Neutral',
  MEDIUM_HIGH: 'Medium High',
  HIGH: 'High',
};
