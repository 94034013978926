<ng-container *transloco="let t">
  @if (!accountMarketplace?.hasAccessToAdvertising) {
    <div class="alert alert-warning mt-4">
      {{ t("strategy-list.issue_connecting") }}
    </div>
  }
  @if (!loading && !accountMarketplace?.activated) {
    <div class="alert alert-warning mt-4">
      {{ t("billing-account-selection.unable_to_automate") }}
      <a href="https://advertising.amazon.com/" target="_blank">{{ t("common.amazon_advertising_console") }}</a
      >.
    </div>
  }
  <app-sb-creative-warning></app-sb-creative-warning>
  @switch (getSBStrategyLimitWarning()) {
    @case ("REACHED") {
      <div class="alert alert-warning mt-4">
        {{ t("strategy-list.maximum_reached_150_active_sponsored") }}
      </div>
    }
    @case ("SOON") {
      <div class="alert alert-warning mt-4">
        {{ t("strategy-list.reached_maximum_num", [liveStrategies().length]) }}
      </div>
    }
  }
  <div class="position-absolute flex">
    <input
      [class.disabled]="loading || !bidderOn || !accountMarketplace?.activated"
      type="text"
      class="form-control simple-input position-sticky"
      placeholder="{{ t('strategy-list.search_by_strategy_name_or_asin') }}"
      [(ngModel)]="userSearch"
      (input)="search()"
    />
    <div class="ml-4" style="width: 25rem">
      <ISelect
        [options]="strategyStatusOptions"
        [selected]="strategyFilter"
        (selectedChange)="strategyFilter = $event; search()"
      />
    </div>
  </div>
  <div class="flex justify-center"></div>
  <div class="flex items-center justify-end">
    <IButton
      class="mr-3"
      [icon]="ICON_ADD"
      label="{{ t('sd-strategy-creation.create_strategy') }}"
      [disabled]="isReadOnly"
      (onClick)="createStrategy()"
      testid="create-strategy-button"
    />
    @if (campaignType === "SP") {
      <IButton
        class="mr-3"
        [icon]="ICON_WRENCH"
        color="white"
        [to]="strategyGroupMigrationUrl"
        queryParamsHandling="merge"
        [disabled]="isReadOnly"
        tooltipValue="{{ t('strategy-list.open_migration_page') }}"
      />
    }

    <app-switch-button
      class="mr-4"
      [type]="SwitchButtonType.ICON"
      [options]="[ViewTypeEnum.CARD, ViewTypeEnum.LIST]"
      [buttonIcons]="['icon-[mdi--view-grid]', 'icon-[mdi--format-list-bulleted]']"
      [selected]="viewType"
      [buttonTooltips]="[t('strategy-asins.display_as_cards'), t('strategy-asins.display_as_list')]"
      [testids]="['card-view', 'list-view']"
      (buttonClicked)="displayBy($event)"
    >
    </app-switch-button>

    <div [class.disabled]="!bidderOn || !accountMarketplace?.activated || campaignType === 'SB'" class="flex gap-2">
      <IButton
        [disabled]="isReadOnly"
        tooltipValue="{{ t('product-group.upload_strategy_data') }}"
        [icon]="ICON_IMPORT"
        (onClick)="uploadStrategyData()"
        color="white"
        label="{{ t('common.import') }}"
      />
      <app-export-button
        (export)="exportStrategyData()"
        tooltipValue="{{ t('strategy-list.export_strategy_list_as_csv') }}"
      ></app-export-button>
    </div>
  </div>

  @if (accountMarketplace?.activated) {
    <div [class.disabled]="!bidderOn">
      @if (viewType === ViewTypeEnum.CARD) {
        <div class="flex flex-row flex-wrap gap-3 p-3">
          @for (
            strategy of strategiesAndGroups?.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);
            track strategy.strategyGroupId + "_" + strategy["strategyId"]
          ) {
            @if (isStrategy(strategy)) {
              @if (strategy.campaignType === "SP") {
                <app-sp-card
                  [strategy]="strategy"
                  [isReadOnly]="isReadOnly"
                  [accountMarketplace]="accountMarketplace"
                ></app-sp-card>
              } @else if (strategy.campaignType === "SD") {
                <app-sd-card
                  [strategy]="strategy"
                  [isReadOnly]="isReadOnly"
                  [accountMarketplace]="accountMarketplace"
                ></app-sd-card>
              } @else if (strategy.campaignType === "SB") {
                <app-sb-card [strategy]="strategy" [isReadOnly]="isReadOnly"></app-sb-card>
              }
            } @else {
              <app-sp-strategy-group-card
                [strategyGroup]="strategy"
                [readonly]="isReadOnly"
              ></app-sp-strategy-group-card>
            }
          }
        </div>
        <mat-paginator
          [pageSize]="10"
          [pageSizeOptions]="[10, 25, 50, 100]"
          [pageIndex]="pageIndex"
          [length]="strategiesAndGroups?.length"
          (page)="changePage($event)"
          showFirstLastButtons
          #strategyCardPaginator
        ></mat-paginator>
      } @else {
        <app-strategy-table
          [campaignType]="campaignType"
          [strategies]="strategies"
          [strategyGroups]="strategyGroups"
          [filter]="userSearch"
          [strategyFilter]="strategyFilter.value"
          [marketplace]="accountMarketplace.marketplace"
          [asinsByStrategy]="asinsByStrategy"
          (strategyCreationClick)="createStrategy()"
          class="w-100 p-3"
        ></app-strategy-table>
      }
    </div>
  }

  <app-spinner [display]="loading" [main]="true"></app-spinner>
</ng-container>
