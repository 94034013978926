import { DOCUMENT } from "@angular/common";
import { AfterViewInit, Component, Inject } from "@angular/core";
import { Meta } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
import { Angulartics2GoogleGlobalSiteTag } from "angulartics2";
import { environment } from "../environments/environment";
import { BoardType, LayoutSelectorService } from "./layout-selector.service";
import { AppUpdateService } from "./update-service/update-service";
import { AuthService, User } from "@front/m19-services";
import { I18nService } from "./services/i18n.service";
import { LicenseManager } from "@ag-grid-enterprise/core";

declare global {
  interface Window {
    clarity: (method: string, ...args: any[]) => void;
  }
}

@Component({
  selector: "body",
  template: "<router-outlet></router-outlet>",
})
export class AppComponent implements AfterViewInit {
  private theme = "m19-theme";
  private user: User;
  private readonly sessionId = "session-" + crypto.randomUUID();

  constructor(
    private layoutSelectorService: LayoutSelectorService,
    @Inject(DOCUMENT) private document: Document,
    private meta: Meta,
    private angulartics2GoogleGlobalSiteTag: Angulartics2GoogleGlobalSiteTag,
    updateService: AppUpdateService,
    private router: Router,
    private authService: AuthService,
    private i18nService: I18nService,
  ) {
    // add custom agency style
    const body = this.document.getElementsByTagName("body")[0];

    this.theme = this.layoutSelectorService.getBoardTheme();
    body.classList.add(this.theme);

    // AG grid license key
    LicenseManager.setLicenseKey(
      "Using_this_AG_Grid_Enterprise_key_( AG-041404 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( m19 SAS )_is_granted_a_( Single Application )_Developer_License_for_the_application_( m19 )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( m19 )_need_to_be_licensed___( m19 )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 23 April 2024 )____[v2]_MTcxMzgyNjgwMDAwMA==ee2f5410d26dca8e280c06973d3c5589",
    );
    angulartics2GoogleGlobalSiteTag.startTracking();
    updateService.initUpdateService();
    this.trackRouteEvents();
    this.authService.loggedUser$.subscribe((user) => {
      this.user = user;
    });

    // MS clarity identification
    // see: https://learn.microsoft.com/en-us/clarity/setup-and-installation/identify-api
    if (environment.production) {
      this.authService.loggedUser$.subscribe((user) => {
        window.clarity("identify", user.email);
      });
    }
    this.i18nService.initLanguage();
  }

  private trackRouteEvents() {
    // track page views manually as we are in a single page application
    // see: https://developers.google.com/analytics/devguides/collection/gtagjs/pages?hl=fr#manual_pageviews
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.angulartics2GoogleGlobalSiteTag.eventTrack("page_view", {
          gstCustom: {
            page_title: this.document.title,
            page_path: event.urlAfterRedirects,
            page_location: this.document.location.href,
          },
        });
        // MS clarity identification - trigger also on page change
        // see: https://learn.microsoft.com/en-us/clarity/setup-and-installation/identify-api
        if (environment.production && this.user) {
          const pageId = this.document.location.pathname.replace(/\//g, "_");
          window.clarity("identify", this.user.email, this.sessionId, pageId, this.user.userName);
        }
      }
    });
  }

  ngAfterViewInit() {
    // Hubspot integration is really specific. Don't put it in the index.html
    // Hubspot requires to be loaded after page rendering
    // otherwise, hubspot damages the page.
    // also, do not load hubspot beacon on whitelabel
    if (this.layoutSelectorService.getBoardType() == BoardType.M19 && environment.production) {
      this.loadHubspotBeacon();
    }

    this.layoutSelectorService.setDocumentMetadata(this.document, this.meta);

    // page load monitoring
    // https://developers.google.com/analytics/devguides/collection/gtagjs/user-timings
    if (window.performance) {
      const timeSincePageLoad = Math.round(performance.now());
      this.angulartics2GoogleGlobalSiteTag.eventTrack("timing_complete", {
        gstCustom: {
          name: "init_load",
          value: timeSincePageLoad,
          event_category: "Performance",
          event_label: "App Init Load",
        },
      });
    }
  }

  loadHubspotBeacon() {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://js.hs-scripts.com/8861742.js";
    document.getElementsByTagName("head")[0].appendChild(script);
  }
}
