import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { faRocket } from "@fortawesome/free-solid-svg-icons";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  AccountGroup,
  OrganizationAccountGroupService,
  AccountSelectionService,
  AuthService,
  BillingService,
  OrganizationAccountGroups,
  Plan,
} from "@front/m19-services";
import { BoardType, LayoutSelectorService, Link } from "@m19-board/layout-selector.service";
import { combineLatest } from "rxjs";
import { filter } from "rxjs/operators";
import { TranslocoService } from "@jsverse/transloco";
@UntilDestroy()
@Component({
  selector: "app-registration-button",
  templateUrl: "./registration-button.component.html",
})
export class RegistrationButtonComponent implements OnInit {
  message = "";
  display = false;
  upgradeWhitelabel = false;
  whitelabelUpgradeLink: Link = undefined;

  readonly faRocket = faRocket;

  constructor(
    private router: Router,
    private accountGroupService: OrganizationAccountGroupService,
    private accountSelectionService: AccountSelectionService,
    private billingService: BillingService,
    private layoutSelector: LayoutSelectorService,
    private authenticationService: AuthService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.authenticationService.loggedUser$.pipe(untilDestroyed(this)).subscribe((u) => {
      this.whitelabelUpgradeLink = this.layoutSelector.getUpgrade(u);
    });

    combineLatest([
      this.accountGroupService.allOrganizationAccountGroups$,
      this.accountSelectionService.accountGroupSelection$,
      this.billingService.organizationOnwer$,
    ])
      .pipe(
        untilDestroyed(this),
        filter(
          ([allOrgs, accountGroup, orgOnwer]: [OrganizationAccountGroups[], AccountGroup, OrganizationAccountGroups]) =>
            allOrgs.length > 0 && !!accountGroup && !!orgOnwer,
        ),
      )
      .subscribe(
        ([allOrgs, accountGroup, orgOnwer]: [OrganizationAccountGroups[], AccountGroup, OrganizationAccountGroups]) => {
          const isAlreadyBillingOwner = !!orgOnwer.getBillingPlan();
          const selectedOrgId = accountGroup.organizationId;
          const selectedOrg = allOrgs.find((x) => x.id == selectedOrgId);

          const displayUgrade =
            selectedOrg.hasActiveSubscription() &&
            isAlreadyBillingOwner &&
            selectedOrg.getBillingPlan()?.plan == Plan.STARTER &&
            this.layoutSelector.getBoardType() === BoardType.M19;
          const displayFreemium =
            !selectedOrg.hasSubscription() &&
            selectedOrg.hasASellerAccount() &&
            !selectedOrg.organization.starterPlanBlacklisted;
          const displayNotFreemium =
            !selectedOrg.hasSubscription() &&
            (selectedOrg.allVendorAccounts() || selectedOrg.organization.starterPlanBlacklisted);
          if (
            this.layoutSelector.getBoardType() === BoardType.WHITELABEL_SELFSERVICE &&
            selectedOrg.hasActiveSubscription() &&
            selectedOrg.getBillingPlan()?.plan == Plan.STARTER &&
            this.whitelabelUpgradeLink &&
            !orgOnwer.isParentPpcBoard
          ) {
            this.upgradeWhitelabel = true;
          }

          if (displayUgrade) this.message = this.translocoService.translate("common.upgrade");
          if (displayFreemium)
            this.message = this.translocoService.translate("registration-button.start_ads_automation_for_free");
          if (displayNotFreemium)
            this.message = this.translocoService.translate("registration-button.start_ads_automation");
          this.display = displayUgrade || displayFreemium || displayNotFreemium;
        },
      );
  }

  upgradeWhitelableLink() {
    window.open(this.whitelabelUpgradeLink.url, this.whitelabelUpgradeLink.target);
  }

  billingRegistration(): void {
    switch (this.layoutSelector.getBoardType()) {
      case BoardType.M19:
        this.router.navigate(["billing-registration"]);
        break;
      case BoardType.WHITELABEL_SELFSERVICE:
        this.router.navigate(["freemium"]);
        break;
      default:
      // not possible
    }
  }
}
