import { formatCurrency, formatPercent } from "@angular/common";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { faPauseCircle, faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import {
  AccountMarketplace,
  AccountSelectionService,
  ACOS,
  AD_CONVERSIONS,
  AD_SALES,
  AdStatsData,
  AdStatsEx,
  AdStatsWithStrategyHistory,
  AlgoMode,
  AuthService,
  CampaignType,
  CLICK_THROUGH_RATE,
  CLICKS,
  CONVERSION_RATE,
  COST,
  CPC,
  Currencies,
  Currency,
  DataSet,
  getBasicGridOptions,
  groupBy,
  IMPRESSIONS,
  indexStrategyByDate,
  Marketplaces,
  marketplaceToCurrencyRate,
  mergeAdStatsWithStrategyHistory,
  mergeSeveralDates,
  Metric,
  MetricsSelectorLocalStorageKey,
  ROAS,
  SegmentEx,
  SIDE_BAR_NO_PIVOT,
  StatsApiClientService,
  Strategy,
  StrategyEx,
  StrategyGroupEx,
  StrategyType,
  StrategyTypeStr,
  UserSelectionService,
  Utils,
} from "@front/m19-services";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { ActivityService } from "@m19-board/activities/activity.service";
import { exportGridCsv, getMetricsColDef, GRAPH_ICON, STATUS_BAR } from "@m19-board/grid-config/grid-config";
import { NON_M19_STRATEGIES, StrategyStats } from "@m19-board/models/Metric";
import { AgGridAngular } from "@ag-grid-community/angular";
import {
  ColDef,
  ColGroupDef,
  CsvExportParams,
  GetContextMenuItemsParams,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  IRowNode,
  ITooltipParams,
  MenuItemDef,
  ModelUpdatedEvent,
  ShouldRowBeSkippedParams,
  ValueFormatterParams,
  ValueGetterParams,
} from "@ag-grid-community/core";

import { IBadgeComponent } from "@front/m19-ui";
import { CampaignTypeBadgeComponent } from "@m19-board/shared/campaign-type-badge/campaign-type-badge.component";
import { ChartRendererComponent } from "@m19-board/shared/chart-renderer/chart-renderer.component";
import { CustomIconComponent } from "@m19-board/shared/custom-icon/custom-icon.component";
import { LinkComponent } from "@m19-board/shared/link/link.component";
import { FblModeComponent, FblModeSize } from "@m19-board/strategies/fbl-mode/fbl-mode.component";
import { StrategyLinkComponent } from "@m19-board/strategies/strategy-link/strategy-link.component";
import { ICON_CHART_LINE, ICON_LIST } from "@m19-board/utils/iconsLabels";
import { SegmentService } from "libs/m19-services/src/lib/m19-services/segmentService";
import { StrategyCache } from "libs/m19-services/src/lib/m19-services/strategy.cache";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { combineLatest, map, Observable } from "rxjs";
import { actionColumnProperties, CurrencyColumn } from "../../grid-config/grid-columns";
import { DAILY_BUDGET, MIN_DAILY_SPEND, MONTHLY_BUDGET, TARGET_ACOS } from "../../models/MetricsDef";
import { ActionButton, ActionButtonsComponent } from "./action-buttons/action-buttons.component";
import { AlgoTargetRendererComponent } from "./algo-target-renderer/algo-target-renderer.component";
import { StrategyDetailStatsModalComponent } from "./strategy-detail-stats-modal/strategy-detail-stats-modal.component";

export interface GlobalStrategyStats extends StrategyStats {
  algoMode: string;
}

@UntilDestroy()
@Component({
  selector: "app-overview-grid",
  template: ` <div class="ag-theme-quartz" style="height: 42rem">
    <ag-grid-angular class="h-[90%]" [gridOptions]="strategyGridOptions" [statusBar]="STATUS_BAR" />
  </div>`,
})
export class OverviewGridComponent implements OnInit {
  private readonly TACTIC_GRID_KEY = "tacticGrid";

  readonly METRIC_COLUMNS: Metric<StrategyStats>[] = [
    AD_SALES,
    AD_CONVERSIONS,
    COST,
    ACOS,
    CLICKS,
    IMPRESSIONS,
    CLICK_THROUGH_RATE,
    CONVERSION_RATE,
    CPC,
    ROAS,
  ];

  @Input()
  gridKey: string = "overviewGrid";
  @Input()
  hiddenColumns: string[] = [];
  @Input()
  dataFilter: (GlobalStrategyStats) => boolean = () => true;
  @Input()
  noStrategyLinks = false;

  locale = "fr";
  currency = Currency.EUR;
  isReadOnly = false;
  accountMarketplace: AccountMarketplace;

  strategyIndex: Map<number, StrategyEx> = new Map();
  segmentIndex: Map<number, SegmentEx> = new Map();
  strategyGroupIndex: Map<number, StrategyGroupEx> = new Map();

  singleStrategyDataSet: DataSet<AdStatsEx>;

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  private gridData$: Observable<StrategyStats[]>;

  previousDataMap: Map<number, AdStatsEx> = new Map<number, AdStatsEx>(); // <strategyId, aggPreviousData>
  previousDataArrayMap: Map<number, AdStatsEx[]> = new Map<number, AdStatsEx[]>(); // <strategyId, previousData[]>
  strategyConfigHistory: Map<number, Map<string, Strategy>>;

  globalStats: AdStatsEx[];

  // Map used on parent row to get strategy info
  strategyNameOrSub: Map<number, string> = new Map();

  // Col defs common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    useValueFormatterForExport: true,
  };

  readonly gridComponents = {
    notOperatedStrategyLink: (params) => {
      return NON_M19_STRATEGIES;
    },
    deletedStrategyLink: (params) => {
      return "Deleted strategy";
    },
  };

  readonly STATUS_BAR = STATUS_BAR;

  public strategyColumnDefs: (ColDef<GlobalStrategyStats> | ColGroupDef<GlobalStrategyStats>)[] = [
    {
      ...actionColumnProperties<GlobalStrategyStats, string>(),
      cellRendererSelector: (params) => {
        const isTotalRow = params.node.isRowPinned();

        let btns: ActionButton[] = [
          {
            icon: ICON_CHART_LINE,
            tooltip: `Display ${isTotalRow ? "total" : ""} strategy graph`,
            onClick: (params: ICellRendererParams) => {
              this.displayStrategyGraph(params, isTotalRow);
            },
          },
        ];

        if (!isTotalRow && params.data.strategyId > 0) {
          btns = [
            ...btns,
            {
              icon: ICON_LIST,
              tooltip: `Open ${
                params.data.campaignType === CampaignType.SB
                  ? "tactic, placement and creative"
                  : params.data.campaignType == CampaignType.SD
                    ? "tactic, audience and placement"
                    : "tactic and placement"
              } stats details`,
              onClick: (params: ICellRendererParams) => {
                this.displayStrategyPlacementGrid(params.data.strategyId);
              },
            },
          ];
        }

        return {
          component: ActionButtonsComponent,
          params: {
            actionButtons: btns,
          },
        };
      },
    },
    {
      field: "state",
      enablePivot: true,
      pinned: "left",
      headerName: "State",
      filter: "agSetColumnFilter",
      suppressAutoSize: true,
      floatingFilter: true,
      cellRendererSelector: (params) => {
        if (!params.value) return undefined;

        return {
          component: CustomIconComponent,
          params: {
            faIcon: params.value === "ENABLED" ? faPlayCircle : faPauseCircle,
            tooltip: (params.value === "ENABLED" ? "Active" : "Paused") + " strategy",
            tooltipPos: "left",
          },
        };
      },
      cellClassRules: {
        desactivated: (params) => params.value === "PAUSED",
        activated: (params) => params.value === "ENABLED",
      },
      cellStyle: { textAlign: "center" },
    },
    {
      field: "campaignType",
      colId: "campaignType",
      pinned: "left",
      filter: "agSetColumnFilter",
      floatingFilter: true,
      headerName: "Ad Type",
      cellRendererSelector: (params) => {
        if (!params.value) return undefined;

        return {
          component: CampaignTypeBadgeComponent,
          params: {
            campaignType: params.value,
          },
        };
      },
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "Strategy",
      colId: "strategyCol",
      field: "strategyName",
      pinned: "left",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      enablePivot: false,
      cellRendererSelector: (params) => {
        if (params.node.isRowPinned()) return undefined;
        if (!params.data.strategyId) return { component: "notOperatedStrategyLink" };
        else if (!this.strategyIndex.get(params.data.strategyId)) return { component: "deletedStrategyLink" };
        return {
          component: StrategyLinkComponent,
          params: { strategyId: params.data.strategyId, withCampaignType: false, disableLink: this.noStrategyLinks },
        };
      },
    },
    {
      headerName: "Type",
      colId: "strategyType",
      pinned: "left",
      filter: "agSetColumnFilter",
      floatingFilter: true,
      valueGetter: (params: ValueGetterParams<GlobalStrategyStats>) => {
        return params.data.strategy?.strategyType;
      },
      valueFormatter: (params: ValueFormatterParams<GlobalStrategyStats, StrategyType>) => {
        return params.value == StrategyType.LEGACY ? "" : StrategyTypeStr[params.value];
      },
      filterValueGetter: (params: ValueGetterParams<GlobalStrategyStats>) => {
        return StrategyTypeStr[params.data.strategy?.strategyType] ?? "-";
      },
      cellStyle: { textAlign: "center" },
      cellRendererSelector: (params) => {
        if (params.valueFormatted) {
          return {
            component: IBadgeComponent,
            params: { label: params.valueFormatted, color: "gray", size: "xs" },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: "Strategy Group",
      colId: "strategyGroup",
      pinned: "left",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      valueGetter: (params: ValueGetterParams<GlobalStrategyStats>) => {
        if (params.data.strategy?.strategyGroupId) {
          return this.strategyGroupIndex.get(params.data.strategy.strategyGroupId)?.strategyGroupName;
        }
        return undefined;
      },
      cellRendererSelector: (params) => {
        if (!params.value) {
          return undefined;
        }
        return {
          component: LinkComponent,
          params: {
            routerLink: "/strategies/strategy-group/sponsored-product/" + params.data.strategy.strategyGroupId,
            target: "_blank",
            content: params.value,
            queryParamsHandling: "merge",
          },
        };
      },
    },
    {
      headerName: "Mode",
      floatingFilter: true,
      pinned: "left",
      colId: "algoMode",
      sortable: false,
      filterValueGetter: (params) => {
        const strategy = this.strategyIndex.get(params.data.strategyId);

        return FblModeComponent.fblMode[strategy?.constraint]?.name;
      },
      cellRendererSelector: (params) => {
        const strat = this.strategyIndex.get(params.data.strategyId);
        const constraint = FblModeComponent.fblMode[strat?.constraint];

        if (strat && constraint === FblModeComponent.fblMode[4]) {
          if (
            (strat.campaignType === CampaignType.SP || strat.campaignType === CampaignType.SD) &&
            strat.asins.length === 0
          )
            return undefined;
          if (strat.campaignType === CampaignType.SB && strat.sbCreatives.length === 0) return undefined;
        }
        if (strat && strat.campaignType)
          return { component: FblModeComponent, params: { strategy: strat, size: FblModeSize.small } };
        return undefined;
      },
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "Algorithm",
      floatingFilter: true,
      field: "algorithm",
      pinned: "left",
      tooltipValueGetter: (params: ITooltipParams<GlobalStrategyStats>) => StrategyEx.getAlgoModeStr(params.value),
      valueFormatter: (params: ValueFormatterParams<GlobalStrategyStats>) =>
        StrategyEx.getAlgoModeStrShort(params.value),
      filterValueGetter: (params: ValueGetterParams<GlobalStrategyStats>) =>
        params.data?.algorithm ? StrategyEx.getAlgoModeStrShort(params.data?.algorithm as AlgoMode) : "",
    },
    {
      headerName: "Target",
      filter: false,
      sortable: false,
      colId: "targetAcos",
      pinned: "left",
      type: "numericColumn",
      cellRenderer: AlgoTargetRendererComponent,
      autoHeight: true,
      cellRendererParams: (params: ICellRendererParams<GlobalStrategyStats>) => {
        return {
          locale: this.locale,
          currency: this.accountMarketplace?.marketplace
            ? Marketplaces[this.accountMarketplace.marketplace].currency
            : Currency.USD,
          readonly: this.isReadOnly,
          minBid: this.accountMarketplace.minBid,
          averageDailyBudget: params.data.strategy?.dailyBudget,
        };
      },
    },

    {
      headerName: "ACOS Target",
      colId: "acosTarget",
      field: "acosTarget",
      // hide this column
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      hide: true,
      valueFormatter: (params: ValueFormatterParams<GlobalStrategyStats, number>) => {
        return params.value ? formatPercent(params.value, this.locale, "1.0-0") : "-";
      },
    },
    {
      headerName: "Suggested Bid",
      colId: "suggestedBid",
      field: "suggestedBid",
      // hide this column
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      hide: true,
      valueFormatter: (params: ValueFormatterParams<GlobalStrategyStats, number>) => {
        return params.value
          ? formatCurrency(params.value, this.locale, Currencies[this.currency].currencySymbol, this.currency, "1.0-0")
          : "-";
      },
    },
    {
      headerName: "Daily Budget",
      colId: "dailyBudget",
      field: "dailyBudget",
      // hide this column
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      hide: true,
      valueFormatter: (params: ValueFormatterParams<GlobalStrategyStats, number>) => {
        return params.value
          ? formatCurrency(params.value, this.locale, Currencies[this.currency].currencySymbol, this.currency, "1.0-0")
          : "-";
      },
    },
    {
      headerName: "Monthly Budget",
      colId: "monthlyBudget",
      field: "monthlyBudget",
      // hide this column
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      hide: true,
      valueFormatter: (params: ValueFormatterParams<GlobalStrategyStats, number>) => {
        return params.value
          ? formatCurrency(params.value, this.locale, Currencies[this.currency].currencySymbol, this.currency, "1.0-0")
          : "-";
      },
    },
    ...getMetricsColDef<GlobalStrategyStats, GlobalStrategyStats>(this.METRIC_COLUMNS).map((def) => ({
      ...def,
      cellRendererParams: (params) => {
        return {
          ...(def as ColDef<GlobalStrategyStats>).cellRendererParams(params),
          previousData: params.node.isRowPinned() ? undefined : this.previousDataMap.get(params.data.strategyId),
          currency: this.currency,
          locale: this.locale,
        };
      },
    })),
    CurrencyColumn,
  ];

  private commonOptions = getBasicGridOptions(this.gridKey, true);
  public strategyGridOptions: GridOptions = {
    ...this.commonOptions,
    context: { componentParent: this },
    columnDefs: this.strategyColumnDefs.filter((c) => !this.hiddenColumns.includes(c["colId"])),
    defaultColDef: this.defaultColDef,
    components: this.gridComponents,
    readOnlyEdit: true,
    sideBar: SIDE_BAR_NO_PIVOT,
    getContextMenuItems: (params) => this.getContextMenuItems(params),
    onModelUpdated: (event: ModelUpdatedEvent<any>) => {
      this.commonOptions.onModelUpdated(event);
    },
    getRowClass: (params) => {
      if (params.node.isRowPinned()) {
        return "grid-row-footer";
      }
    },
    onGridReady: (event: GridReadyEvent<any>) => {
      this.gridData$.subscribe((data: GlobalStrategyStats[]) => {
        event.api.setGridOption("rowData", data);
      });
    },
  };

  constructor(
    private authService: AuthService,
    private statsService: StatsApiClientService,
    private strategyCache: StrategyCache,
    private segmentService: SegmentService,
    private userSelectionService: UserSelectionService,
    private modalService: BsModalService,
    private accountSelectionService: AccountSelectionService,
    private router: Router,
    private activityService: ActivityService,
  ) {}

  ngOnInit(): void {
    this.strategyCache.strategyGroupIndex$.pipe(untilDestroyed(this)).subscribe((strategyGroupIndex) => {
      this.strategyGroupIndex = strategyGroupIndex;
    });
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(untilDestroyed(this))
      .subscribe((am: AccountMarketplace) => {
        this.accountMarketplace = am;
      });
    this.accountSelectionService.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((b) => (this.isReadOnly = b));
    this.singleStrategyDataSet = new DataSet<AdStatsWithStrategyHistory>(
      3,
      [AD_SALES, COST],
      mergeAdStatsWithStrategyHistory,
    );
    this.singleStrategyDataSet.metricsOnSameScale = [
      [AD_SALES, COST],
      [ACOS, TARGET_ACOS],
      [COST, MIN_DAILY_SPEND, DAILY_BUDGET, MONTHLY_BUDGET],
    ];

    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.singleStrategyDataSet.locale = user.locale;
      this.locale = user.locale;
    });
    this.userSelectionService.selectedCurrency$.pipe(untilDestroyed(this)).subscribe((currency) => {
      this.singleStrategyDataSet.currency = currency;
      this.currency = currency;
      this.agGrid?.api?.redrawRows();
    });
    this.strategyCache.strategyIndex$.pipe(untilDestroyed(this)).subscribe((i) => (this.strategyIndex = i));

    this.statsService.previousPeriodDailyPlacementStats$
      .pipe(untilDestroyed(this))
      .subscribe((previousData: AdStatsData) => {
        this.previousDataMap.clear();
        this.previousDataArrayMap.clear();

        Array.from(groupBy<string>(previousData.data, (x) => x.strategyId?.toString()).values()).forEach(
          (d: AdStatsEx) => {
            this.previousDataMap.set(d.strategyId, d);
          },
        );

        previousData.data.forEach((d: AdStatsEx) => {
          Utils.insertInArrayMap(this.previousDataArrayMap, d.strategyId, d);
        });

        // Have to redraw rows because we need all the row data and not just the cells
        this.agGrid?.api.redrawRows();
      });

    this.gridData$ = combineLatest<[AdStatsData, Map<number, StrategyEx>, Map<number, SegmentEx>]>([
      this.statsService.dailyPlacementStats$,
      this.strategyCache.strategyIndex$,
      this.segmentService.segmentIndex$,
    ]).pipe(
      untilDestroyed(this),
      map(([data, strategyIndex, segmentIndex]) => {
        this.segmentIndex = segmentIndex;
        this.strategyIndex = strategyIndex;

        const strategyStats = Array.from(groupBy<string>(data.data, (x) => x.strategyId?.toString()).values());

        const globalStrategyStats: GlobalStrategyStats[] = strategyStats
          .map((d: AdStatsEx) => {
            const strategy = this.strategyIndex.get(d.strategyId);
            const strategyName = d.strategyId
              ? strategyIndex.get(d.strategyId)
                ? strategyIndex.get(d.strategyId).getName()
                : "Deleted strategy"
              : NON_M19_STRATEGIES;
            const strategyStats: StrategyStats = toStrategyStats(d, strategy, strategyName);
            this.strategyNameOrSub.set(strategy?.strategyId, strategyStats?.strategyName);

            return {
              ...strategyStats,
              algoMode: FblModeComponent.fblMode[strategy?.constraint]?.name,
            };
          })
          .filter((stats) => this.dataFilter(stats));

        return globalStrategyStats;
      }),
    );

    this.statsService.strategyConfigHistory$.pipe(untilDestroyed(this)).subscribe((configHistory: Strategy[]) => {
      this.strategyConfigHistory = new Map();
      const strategyIds = new Set(configHistory.map((s) => s.strategyId));
      for (const strategyId of strategyIds.values()) {
        this.strategyConfigHistory.set(
          strategyId,
          indexStrategyByDate(configHistory.filter((x) => x.strategyId == strategyId)),
        );
      }
    });
  }

  private displayStrategyPlacementGrid(strategyId: number) {
    const modalOptions: ModalOptions = {
      initialState: {
        strategy: this.strategyIndex.get(strategyId),
        currency: this.currency,
        locale: this.locale,
      },
      class: "modal-xxl modal-dialog-centered",
    };
    this.modalService.show(StrategyDetailStatsModalComponent, modalOptions);
  }

  getContextMenuItems = (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
    const data = params.node.data;

    const strategyId: number = data.strategyId;

    const result: (string | MenuItemDef)[] = ["copy", "export", "chartRange"];

    result.push("separator");

    result.push({
      name: "Display Strategy Graph",
      icon: `<img width="16" height="16" src="${GRAPH_ICON}"/>`,

      action: () => {
        this.displayStrategyGraph(params);
      },
    });

    return result;
  };

  private displayStrategyGraph(params: ICellRendererParams | GetContextMenuItemsParams, totalGraph = false) {
    const strategyIds: number[] = [];
    const strategies: StrategyEx[] = [];
    const additionalMetrics: Set<Metric<StrategyStats>> = new Set();
    if (totalGraph) {
      params.api.forEachNodeAfterFilter((n: IRowNode<GlobalStrategyStats>) => {
        strategyIds.push(n.data.strategyId);
        if (n.data.strategy) {
          strategies.push(n.data.strategy);
        }
      });
    } else {
      strategyIds.push(params.node.data.strategyId);
      if (params.node.data.strategy) {
        strategies.push(params.node.data.strategy);
      }
    }

    const chartData$ = combineLatest<[AdStatsData, AdStatsData]>([
      this.statsService.dailyPlacementStats$,
      this.statsService.previousPeriodDailyPlacementStats$,
    ]).pipe(
      map(([dailyPlacementStats, previousPeriodDailyPlacementStats]) => {
        const stats = dailyPlacementStats.data.filter((d) => strategyIds.includes(d.strategyId));
        const previousStats = previousPeriodDailyPlacementStats.data.filter((d) => strategyIds.includes(d.strategyId));
        const acosTargetHistory: {
          date: string;
          acosTarget: number;
          minDailySpend: number;
          dailyBudget: number;
          monthlyBudget: number;
        }[] = [];
        if (strategyIds.length === 1)
          // only display acos target history when displaying one strategy graph
          for (const [date, strategy] of (this.strategyConfigHistory.get(strategyIds[0]) ?? new Map()).entries()) {
            const rate = marketplaceToCurrencyRate(this.accountMarketplace.marketplace, this.currency);
            const data = {
              date,
              acosTarget: strategy.acosTarget,
              minDailySpend: rate * strategy.minDailySpend,
              dailyBudget: rate * strategy.dailyBudget,
              monthlyBudget: rate * strategy.monthlyBudget,
              computedDailyBudget: rate * strategy.computedDailyBudget,
            };
            if (!isNaN(data.dailyBudget)) additionalMetrics.add(DAILY_BUDGET);
            if (data.minDailySpend !== 0) additionalMetrics.add(MIN_DAILY_SPEND);
            if (!isNaN(data.monthlyBudget)) additionalMetrics.add(MONTHLY_BUDGET);
            acosTargetHistory.push(data);
          }
        const totalData = stats.reduce((prev, curr) => mergeSeveralDates(prev, curr), {});
        const totalPreviousData = previousStats?.reduce((curr, prev) => mergeSeveralDates(curr, prev), {});
        return {
          data: [...acosTargetHistory, ...stats],
          previousData: previousStats,
          totalData,
          totalPreviousData,
        };
      }),
    );
    const focusedStrategy = totalGraph || strategyIds.length > 1 ? "Total" : params.node.data.strategyName;
    const modalOpts: ModalOptions = {
      initialState: {
        title: `Strategy Graph - ${focusedStrategy}`,
        dataset: this.singleStrategyDataSet,
        metrics: this.METRIC_COLUMNS,
        localStorageKey: MetricsSelectorLocalStorageKey.overviewDetails,
        selectMetricCallback: (metrics) => {
          if ([ACOS, COST].every((i) => metrics.includes(i))) return [...metrics, TARGET_ACOS, ...additionalMetrics];
          if (metrics.includes(ACOS)) {
            return [...metrics, TARGET_ACOS];
          }
          if (metrics.includes(COST)) {
            return [...metrics, ...additionalMetrics];
          }
          return metrics;
        },
        chartData$,
        withEventAnnotations: true,
        annotations$: this.activityService.getStrategiesActivityEventAnnotation(
          this.accountMarketplace.accountId,
          this.accountMarketplace.marketplace,
          strategies,
        ),
      },
      class: "modal-xxl modal-dialog-centered",
    };
    this.modalService.show(ChartRendererComponent, modalOpts);
  }

  exportGridCsv(fileName: string): void {
    const exportParams: CsvExportParams = {
      shouldRowBeSkipped: (params: ShouldRowBeSkippedParams) => {
        const strategyName = params.node.data.strategyName;
        return !strategyName || strategyName === NON_M19_STRATEGIES || strategyName === "Deleted strategy";
      },
      processCellCallback: (params) => {
        if (params.column.getId() === "algoMode") {
          const strategy = this.strategyIndex.get(params.node.data.strategyId);
          return FblModeComponent.fblMode[strategy?.constraint]?.name;
        }
        return params.formatValue(params.value);
      },
      columnKeys: this.agGrid?.api.getColumns().flatMap((c) => {
        if (c.getId() == "targetAcos") {
          return [
            this.agGrid?.api.getColumn("acosTarget"),
            this.agGrid?.api.getColumn("suggestedBid"),
            this.agGrid?.api.getColumn("dailyBudget"),
            this.agGrid?.api.getColumn("monthlyBudget"),
          ];
        }
        if (c.getId() == "actions") {
          return [];
        }
        return [c];
      }),
      fileName: fileName,
    };

    exportGridCsv(this.agGrid?.api, exportParams);
  }

  restoreDefaultColumns(): void {
    this.agGrid.api?.resetColumnState();
    this.agGrid.api?.autoSizeAllColumns();
  }
}

export function toStrategyStats(adStats: AdStatsEx, strategy: StrategyEx, strategyName: string): StrategyStats {
  return {
    ...adStats,
    subStrategyId: undefined,
    strategyName: strategyName,
    state: strategy?.state,
    campaignType: strategy?.campaignType,
    dayPartingEnabled: strategy?.daypartingPauseHour != null && strategy?.daypartingReactivationHour != null,
    dayPartingPauseHour: strategy?.daypartingPauseHour,
    dayPartingReactivationHour: strategy?.daypartingReactivationHour,
    algorithm: strategy?.algoMode,
    acosTarget: strategy?.algoMode === AlgoMode.ACOS_TARGET ? strategy?.acosTarget : undefined,
    suggestedBid: strategy?.algoMode === AlgoMode.PRODUCT_LAUNCH ? strategy?.suggestedBid : undefined,
    dailyBudget: strategy?.algoMode === AlgoMode.PRODUCT_LAUNCH ? strategy?.dailyBudget : undefined,
    minDailySpend: strategy?.algoMode === AlgoMode.PRODUCT_LAUNCH ? strategy?.minDailySpend : undefined,
    monthlyBudget: strategy?.algoMode === AlgoMode.MONTHLY_BUDGET_TARGET ? strategy?.monthlyBudget : undefined,
    today: strategy?.today,
    nextMonthlyBudget: strategy?.nextMonthlyBudget,
    currentMonthSpend: strategy?.currentMonthSpend,
    strategy: strategy,
  };
}
