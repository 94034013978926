import { combineLatest, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { AccountSelectionService, currencyRateForOrder, StatsApiClientService, Utils } from '.';
import { Currency, Marketplace, Order, OrderApi } from './api-client';
import { OrderStats } from './models';
import { UserSelectionService } from './user.selection.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  public readonly globalDataByDates$: Observable<Order[]>;
  public readonly previousGlobalDataByDates$: Observable<Order[]>;

  constructor(
    private orderApi: OrderApi,
    private userSelectionService: UserSelectionService,
    private accountSelectionService: AccountSelectionService,
  ) {
    this.globalDataByDates$ = combineLatest([
      this.accountSelectionService.singleAccountMarketplaceSelection$,
      this.userSelectionService.dateRange$,
    ]).pipe(
      switchMap(([am, dateRange]) => {
        return this.getOrders(am.accountId, am.marketplace, dateRange[0], dateRange[1]);
      }),
      shareReplay(1),
    );
    this.previousGlobalDataByDates$ = combineLatest([
      this.accountSelectionService.singleAccountMarketplaceSelection$,
      userSelectionService.dateRange$,
      this.userSelectionService.periodComparison$,
    ]).pipe(
      switchMap(([am, dateRange, periodComparison]) => {
        if (!periodComparison?.period) return of([]);
        const dateIntervalInDays = Utils.getDateIntervalInDays(dateRange);
        if (dateIntervalInDays > StatsApiClientService.maxComp) {
          return of([]);
        }
        const dateGap = Utils.getDateIntervalInDays([periodComparison.period[0], dateRange[0]]);
        return this.getOrders(
          am.accountId,
          am.marketplace,
          periodComparison.period[0],
          periodComparison.period[1],
        ).pipe(
          map((orders) => {
            return orders.map((order) => this.alignDate(order, dateGap));
          }),
        );
      }),
      shareReplay(1),
    );
  }

  private alignDate(order: Order, dateIntervalInDays: number): Order {
    let date = Utils.toMoment(order.day!);
    date = date.add(dateIntervalInDays, 'days');
    order.day = Utils.formatMomentDate(date);
    return order;
  }

  public getOrders(
    accountId: string,
    marketplace: Marketplace,
    minDate: string,
    maxDate: string,
  ): Observable<Array<Order>> {
    return this.orderApi.getOrder({
      accountId: accountId,
      marketplace: marketplace,
      minDate: minDate,
      maxDate: maxDate,
    });
  }
}

export function orderToOrderStats(order: Order, selectedCurrency: Currency, cog: number): OrderStats {
  const currencyRate_ = currencyRateForOrder(order, selectedCurrency);

  // Sales
  const sales = order.sales! * currencyRate_ || 0;
  const chargebackRefunded = order.chargebackRefunded || 0;
  const a2zGuaranteeRefunded = order.a2zGuaranteeRefunded || 0;
  const orderReturnRefunded = order.orderReturnRefunded || 0;
  const refunds = chargebackRefunded + a2zGuaranteeRefunded + orderReturnRefunded;
  const refundedSales = order.refundSales! * currencyRate_ || 0;

  const sellableReturns = order.sellableReturns || 0;
  const replacementRefunded = order.replacementRefunded || 0;
  const amazonVine = order.amazonVine || 0;

  const reimbursement = order.reimbursement! * currencyRate_ || 0;

  // Tax
  const baseTax = order.tax! * currencyRate_ || 0;
  const taxShipping = order.taxShipping! * currencyRate_ || 0;
  const taxGiftWrap = order.taxGiftWrap! * currencyRate_ || 0;
  const taxOther = order.otherTax! * currencyRate_ || 0;

  // Promotion
  const basePromotion = order.promotion! * currencyRate_ || 0;
  const giftWrapPromotion = order.promotionGiftWrap! * currencyRate_ || 0;
  const shippingPromotion = order.promotionShipping! * currencyRate_ || 0;
  const taxDiscount = order.taxDiscount! * currencyRate_ || 0;
  const otherPromotion = order.otherPromotion! * currencyRate_ || 0;

  // Shipping & gift wrap
  const shipping = order.shipping! * currencyRate_ || 0;
  const shippingHB = order.shippingHB! * currencyRate_ || 0;
  const shippingChargeBack = order.shippingChargeBack! * currencyRate_ || 0;
  const giftWrap = order.giftWrap! * currencyRate_ || 0;
  const giftWrapChargeBack = order.giftWrapChargeBack! * currencyRate_ || 0;

  // Fee
  let commission = order.commission! * currencyRate_ || 0;
  // quick fix for positive refundCommission
  // the code has been fixed in dailyOrderGenerator
  // we just need to backfill the data
  let refundCommission = order.refundCommission! * currencyRate_ || 0;
  if (refundCommission > 0) refundCommission *= -1;
  // TODO
  // merge refundCommission and commission should be done in dailyOrderGenerator
  commission += refundCommission;

  const fbaFee = order.fBAPerUnitFulfillmentFee! * currencyRate_ || 0;
  const giftWrapCommission = order.giftWrapCommission! * currencyRate_ || 0;

  const otherFee = order.otherItemFees! * currencyRate_ || 0;
  const returnFees = order.returnFees! * currencyRate_ || 0;
  const feeAdjustment = order.feeAdjustment! * currencyRate_ || 0;

  // Charge
  const exportCharge = 0;

  //Global stats
  const globalSales = sales + refundedSales + reimbursement; // refunded sales is negative

  const tax = baseTax + taxShipping + taxGiftWrap + taxOther + taxDiscount;

  const promotion = basePromotion + giftWrapPromotion + shippingPromotion + otherPromotion;

  let shippingGiftWrap = shipping + shippingHB + shippingChargeBack + giftWrap + giftWrapChargeBack;
  if (order.shippingAndGiftWrap) {
    shippingGiftWrap = order.shippingAndGiftWrap * currencyRate_ || 0;
  }

  const fee = commission + fbaFee + giftWrapCommission + otherFee + returnFees + feeAdjustment + exportCharge;

  const costOfGoods = -cog * (order.quantity! + replacementRefunded + refunds - sellableReturns) || 0;

  const profit = globalSales + promotion + shippingGiftWrap + fee + costOfGoods;

  return {
    // Global stats
    globalSales: globalSales,
    tax: tax,
    promotion: promotion,
    shippingGiftWrap: shippingGiftWrap,
    fee: fee,
    advertising: 0,

    // Sales
    sales: sales,
    refunds: refunds,
    reimbursement: reimbursement,
    refunded: refundedSales,
    chargebackRefunded: chargebackRefunded,
    a2zGuaranteeRefunded: a2zGuaranteeRefunded,
    orderReturnRefunded: orderReturnRefunded,
    replacementRefunded: replacementRefunded,
    sellableReturns: sellableReturns,
    amazonVine: amazonVine,

    // Tax
    baseTax: baseTax,
    shippingTax: taxShipping,
    giftWrapTax: taxGiftWrap,
    otherTax: taxOther,
    taxDiscount: taxDiscount,

    // Promotion
    basePromotion: basePromotion,
    giftWrapPromotion: giftWrapPromotion,
    shippingPromotion: shippingPromotion,
    otherPromotion: otherPromotion,

    // Shipping & gift wrap
    shipping: shipping,
    shippingHB: shippingHB,
    shippingChargeBack: shippingChargeBack,
    giftWrap: giftWrap,
    giftWrapChargeBack: giftWrapChargeBack,

    // Fee
    referralFee: commission,
    fbaFee: fbaFee,
    giftWrapFee: giftWrapCommission,
    otherFee: otherFee,
    fbaStorageFee: 0,
    returnFees: returnFees,
    feeAdjustment: feeAdjustment,
    exportCharge: exportCharge,

    // Advertising
    spAdvertising: 0,
    sbAdvertising: 0,
    sdAdvertising: 0,

    // Quantity
    quantity: order.quantity,

    // COG
    costOfGoods: costOfGoods,
    profit: profit,

    currency: selectedCurrency,
    sku: order.sku || '',
    asin: order.asin || '',
    marketplace: order.marketplace,
    date: order.day ?? '',
  };
}
