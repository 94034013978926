import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
  combineLatest,
  distinctUntilChanged,
  shareReplay,
  switchMap,
} from 'rxjs';
import { Marketplace } from './api-client';

export type ByMarketplace<T> = Map<Marketplace, T>;
export type ByAsin<T> = Map<string, T>;
export type BySearchTerm<T> = Map<string, T>;
export type ByAccountMarketplace<T> = Map<string, T>;
export type AccountMarketplaceKey = string;

export function accountMarketplaceKey(accountId: string, marketplace: Marketplace | string) {
  return `${accountId}:${marketplace}`;
}

export function accountMarketplaceOrganizationKey(
  accountId: string,
  marketplace: Marketplace | string,
  organizationId: number,
) {
  return `${accountId}:${marketplace}:${organizationId}`;
}

export type ByAccountMarketplaceOrganization<T> = Map<string, T>;

type AccountMarketplaceCacheKey = { accountId: string; marketplace: Marketplace };
type AccountMarketplaceOrganizationCacheKey = {
  accountId: string;
  marketplace: Marketplace;
  organizationId: number;
};

/**
 * A convenient class to cache data
 * It holds a "context" state which will trigger a reload of keys when it changes
 */
export abstract class SimpleDataCache<K, T, C> {
  // cache the observables
  private readonly cache: Map<string, Observable<T>> = new Map();
  // used to trigger reload of a specific key
  private readonly reload$: Map<string, BehaviorSubject<void>> = new Map();

  protected abstract serializeKey(key: K): string;

  protected abstract loadKey(key: K, context: C): Observable<T>;

  protected abstract compareContext(c1: C, c2: C): boolean;

  public constructor(protected context: Observable<C>) {}

  public getFromCache(key: K): Observable<T> {
    const keyStr = this.serializeKey(key);
    if (this.cache.has(keyStr)) {
      return this.cache.get(keyStr)!;
    }
    const reloadKey$ = new BehaviorSubject<void>(void 0);
    this.reload$.set(keyStr, reloadKey$);
    const obs = combineLatest([
      this.context.pipe(distinctUntilChanged((a, b) => this.compareContext(a, b))),
      reloadKey$,
    ]).pipe(
      switchMap(([context]) => this.loadKey(key, context)),
      shareReplay(1), // cache the last value
    );
    this.cache.set(keyStr, obs);
    return obs;
  }

  public reload(key: K) {
    const keyStr = this.serializeKey(key);
    if (!this.cache.has(keyStr) || !this.reload$.has(keyStr)) {
      return;
    }
    this.reload$.get(keyStr)!.next();
  }
}

/**
 * A convenient class to cache data
 * It holds a "context" state which will invalidate the whole cache when it changes
 */
export abstract class DataCache<K, T, C> {
  private readonly cache: Map<string, Subject<T>> = new Map();
  private readonly values: Map<string, T> = new Map();
  private context?: C = undefined;
  private subscriptions: Map<string, Subscription> = new Map();

  protected abstract serializeKey(key: K): string;

  protected abstract loadKey(key: K, context: C): Observable<T>;

  protected abstract compareContext(c1: C, c2: C): boolean;

  private sameContext(context: C) {
    return this.context && this.compareContext(this.context, context);
  }

  protected getFromCache(key: K, context: C): Observable<T> {
    const keyStr = this.serializeKey(key);
    if (this.sameContext(context) && this.cache.has(keyStr)) {
      return this.cache.get(keyStr)!;
    }
    // clear the cache if context has changed
    if (!this.sameContext(context)) {
      this.cache.clear();
      this.values.clear();
      this.context = context;
      this.subscriptions.forEach((s) => s.unsubscribe());
      this.subscriptions.clear();
    }
    // put value observable in the cache
    const subject = new ReplaySubject<T>(1);
    subject.subscribe((val) => {
      this.values.set(keyStr, val);
    });
    this.cache.set(keyStr, subject);
    if (this.subscriptions.has(keyStr)) {
      this.subscriptions.get(keyStr)!.unsubscribe();
    }
    this.subscriptions.set(
      keyStr,
      this.loadKey(key, context).subscribe((data) => {
        if (!this.cache.has(keyStr)) {
          return;
        }
        this.cache.get(keyStr)!.next(data);
      }),
    );
    return subject;
  }

  public reloadKey(key: K) {
    const keyStr = this.serializeKey(key);
    if (!this.cache.has(keyStr)) {
      return;
    }
    if (this.subscriptions.has(keyStr)) {
      this.subscriptions.get(keyStr)!.unsubscribe();
    }
    this.subscriptions.set(
      keyStr,
      this.loadKey(key, this.context!).subscribe((data) => {
        if (!this.cache.has(keyStr)) {
          return;
        }
        this.cache.get(keyStr)!.next(data);
      }),
    );
  }

  public updateValue(key: K, newValue: (oldValue: T) => T) {
    const keyStr = this.serializeKey(key);
    if (!this.cache.has(keyStr) || !this.values.has(keyStr)) {
      return;
    }
    if (this.subscriptions.has(keyStr)) {
      this.subscriptions.get(keyStr)!.unsubscribe();
    }
    this.cache.get(keyStr)!.next(newValue(this.values.get(keyStr)!));
  }
}

/**
 * A convenient class to cache data per account marketplace.
 * It holds a "context" state which will invalidate the whole cache when it changes
 */
export class AccountMarketplaceDataCache<T, C> extends DataCache<AccountMarketplaceCacheKey, T, C> {
  constructor(
    private keyLoader: (account: string, marketplace: Marketplace, context: C) => Observable<T>,
    private contextComparer: (c1: C, c2: C) => boolean,
  ) {
    super();
  }

  protected serializeKey({ accountId, marketplace }: AccountMarketplaceCacheKey): string {
    return accountMarketplaceKey(accountId, marketplace);
  }

  protected loadKey({ accountId, marketplace }: AccountMarketplaceCacheKey, context: C): Observable<T> {
    return this.keyLoader(accountId, marketplace, context);
  }

  protected compareContext(c1: C, c2: C): boolean {
    return this.contextComparer(c1, c2);
  }

  public get(accountId: string, marketplace: Marketplace, context: C): Observable<T> {
    return super.getFromCache({ accountId, marketplace }, context);
  }
}

/**
 * A convenient class to cache data per account, marketplace, organization.
 * It holds a "context" state which will invalidate the whole cache when it changes
 */
class AccountMarketplaceOrganizationDataCache<T, C> extends DataCache<AccountMarketplaceOrganizationCacheKey, T, C> {
  constructor(
    private keyLoader: (account: string, marketplace: Marketplace, organizationId: number, context: C) => Observable<T>,
    private contextComparer: (c1: C, c2: C) => boolean,
  ) {
    super();
  }

  protected serializeKey({ accountId, marketplace, organizationId }: AccountMarketplaceOrganizationCacheKey): string {
    return accountMarketplaceOrganizationKey(accountId, marketplace, organizationId);
  }

  protected loadKey(
    { accountId, marketplace, organizationId }: AccountMarketplaceOrganizationCacheKey,
    context: C,
  ): Observable<T> {
    return this.keyLoader(accountId, marketplace, organizationId, context);
  }

  protected compareContext(c1: C, c2: C): boolean {
    return this.contextComparer(c1, c2);
  }

  public get(accountId: string, marketplace: Marketplace, organizationId: number, context: C): Observable<T> {
    return super.getFromCache({ accountId, marketplace, organizationId }, context);
  }

  public update(accountId: string, marketplace: Marketplace, organizationId: any, newValue: (oldValue: T) => T) {
    this.updateValue({ accountId, marketplace, organizationId }, newValue);
  }
}

const NoneContext = 'None';
type NoneContext = typeof NoneContext;

export class AccountMarketplaceOrganizationDataCacheNoContext<T> extends AccountMarketplaceOrganizationDataCache<
  T,
  NoneContext
> {
  constructor(keyLoader: (account: string, marketplace: Marketplace, organizationId: number) => Observable<T>) {
    super(
      (accountId: string, markeplace: Marketplace, organizationId) => keyLoader(accountId, markeplace, organizationId),
      () => true,
    );
  }

  public override get(accountId: string, marketplace: Marketplace, organizationId: number): Observable<T> {
    return super.getFromCache({ accountId, marketplace, organizationId }, NoneContext);
  }

  public reload(accountId: string, marketplace: Marketplace, organizationId: number) {
    super.reloadKey({ accountId, marketplace, organizationId });
  }
}

export class AccountMarketplaceDataCacheNoContext<T> extends AccountMarketplaceDataCache<T, NoneContext> {
  constructor(keyLoader: (account: string, marketplace: Marketplace) => Observable<T>) {
    super(
      (accountId: string, markeplace: Marketplace) => keyLoader(accountId, markeplace),
      () => true,
    );
  }

  public override get(accountId: string, marketplace: Marketplace): Observable<T> {
    return super.getFromCache({ accountId, marketplace }, NoneContext);
  }

  public reload(accountId: string, marketplace: Marketplace) {
    super.reloadKey({ accountId, marketplace });
  }
}
