import { formatNumber } from "@angular/common";
import { Component, HostListener, Input, OnInit, Pipe, PipeTransform, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import {
  faEdit,
  faEllipsisV,
  faExclamationTriangle,
  faMinusCircle,
  faPauseCircle,
  faPencilAlt,
  faPlusCircle,
  faSearch,
  faShoppingCart,
  faTable,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  AccountMarketplace,
  AccountSelectionService,
  AsinService,
  ConfigService,
  Currency,
  FulfillmentChannel,
  InventoryRule,
  InventoryRules,
  InventoryStats,
  Marketplace,
  marketplaceToCurrencyRate,
  MetricRegistry,
  StrategyEx,
  StrategyStateEnum,
} from "@front/m19-services";
import { ExpandableDataSource } from "@m19-board/models/ExpandableDataSource";
import {
  FBM_STOCK,
  FBM_STOCK_VALUE,
  FULFILLABLE_STOCK_VALUE,
  INBOUND_STOCK,
  INBOUND_STOCK_VALUE,
  ORDERS_30D,
  ORDERS_7D,
  RESERVED_STOCK,
  RESERVED_STOCK_VALUE,
  UNFULFILLABLE_STOCK,
  UNFULFILLABLE_STOCK_VALUE,
} from "@m19-board/models/MetricsDef";
import { ProductDetailsComponent } from "@m19-board/sales-advertising/product-details/product-details.component";
import {
  constantField,
  CsvExportService,
  fieldExtractor,
  metricField,
  simpleField,
} from "@m19-board/services/csv-export.service";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { merge } from "rxjs";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { FULFILLABLE_STOCK } from "../models/MetricsDef";
import { AsinInventoryRuleModalComponent } from "./inventory-rules/asin-inventory-rules-modal.component";
import { TranslocoService } from "@jsverse/transloco";

@UntilDestroy()
@Component({
  selector: "app-inventory-table",
  templateUrl: "./inventory-table.component.html",
  styleUrls: ["./inventory-table.component.scss"],
})
export class InventoryTableComponent implements OnInit {
  readonly quantityMetrics = [INBOUND_STOCK, RESERVED_STOCK, UNFULFILLABLE_STOCK];
  readonly valueMetrics = [INBOUND_STOCK_VALUE, RESERVED_STOCK_VALUE, UNFULFILLABLE_STOCK_VALUE];
  readonly orderMetrics = [ORDERS_7D, ORDERS_30D];
  readonly metrics = this.quantityMetrics.concat(this.valueMetrics).concat(this.orderMetrics);

  readonly otherColumns = ["asinStockDetailExpandBtn", "productImg", "productTitle", "productPrice", "strategies"];
  displayedColumns = this.getDisplayedColumns(false);
  dataSource = new ExpandableDataSource<InventoryStats>([]);

  productsFilter = "";
  strategies: Map<string, StrategyEx[]> = new Map();
  openedAsinDetailedStock: Set<string> = new Set();
  allProducts: Map<string, string> = new Map();
  accountGroupName: string;
  displayStockValue = false;
  @ViewChild(MatPaginator) set paginator(matPaginator: MatPaginator) {
    this.dataSource.paginator = matPaginator;
  }
  @ViewChild(MatSort) set sort(matSort: MatSort) {
    this.dataSource.sort = matSort;
  }

  @Input() locale: string;

  @Input() currency: Currency;

  @Input() accountId: string;

  @Input() marketplace: Marketplace;

  @Input() inventoryRules: InventoryRules;

  @Input() loading = true;

  private _data: InventoryStats[];
  @Input() set data(data: InventoryStats[]) {
    data.sort((stats1, stats2) => {
      return stats2.fulfillableQuantity - stats1.fulfillableQuantity;
    });
    let rate = 1;
    if (data.length) {
      rate = marketplaceToCurrencyRate(this.marketplace, data[0].currency);
    }

    this._data = data.map((stat) => ({
      ...stat,
      price: stat.price * rate,
    }));
    this.dataSource.data = this._data;

    for (const inventoryStat of data) {
      const asin = inventoryStat.asin;
      this.allProducts.set(asin, inventoryStat.title);
    }
  }

  smallMode = false;
  isReadOnly = false;
  renderedData: InventoryStats[];

  readonly faShoppingCart = faShoppingCart;
  readonly faSearch = faSearch;
  readonly faTable = faTable;
  readonly faTimes = faTimes;
  readonly faEdit = faEdit;
  readonly faPlus = faPlusCircle;
  readonly faMinus = faMinusCircle;
  readonly faPause = faPauseCircle;
  readonly faPencil = faPencilAlt;
  readonly faEllipsis = faEllipsisV;
  readonly faWarning = faExclamationTriangle;
  readonly FULFILLABLE_STOCK = FULFILLABLE_STOCK;
  readonly FULFILLABLED_STOCK_VALUE = FULFILLABLE_STOCK_VALUE;
  readonly FulfillmentChannel = FulfillmentChannel;

  constructor(
    private asinService: AsinService,
    private configService: ConfigService,
    private modalService: BsModalService,
    private toasterService: ToastrService,
    private accountSelectionService: AccountSelectionService,
    private csvExportService: CsvExportService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.isPrimaryData = () => true;
    this.dataSource.filterPredicate = (row, filter) => {
      if (filter) {
        const regexp = new RegExp(filter, "i");
        return regexp.test(row.asin) || (this.allProducts.has(row.asin) && regexp.test(this.allProducts.get(row.asin)));
      }
      return true;
    };
    this.dataSource.sortingDataAccessor = (item, property): string | number => {
      if (property === "strategies") return this.getActiveStrategies(item.asin);
      if (property === "productImg") return item.asin;
      if (property === "productPrice") return item.price;
      if (property === "productTitle") return this.allProducts.get(item.asin) ?? "";
      if (property === "availableStock") return item.fbmStock + item.fulfillableQuantity;
      if (property === "availableStockValue") return item.fbmStockValue + item.fulfillableQuantityValue;

      const metric = MetricRegistry.get(property);
      return metric ? metric.value(item) : item[property];
    };
    this.dataSource
      .connect()
      .pipe(untilDestroyed(this))
      .subscribe((d) => (this.renderedData = d));
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        tap((am: AccountMarketplace) => {
          this.accountGroupName = am.accountGroupName;
        }),
        switchMap(() => this.configService.asinStrategyIndex$),
        untilDestroyed(this),
      )
      .subscribe((x) => {
        this.strategies = x;
      });
    this.smallMode = this.isSmallMode(window.innerWidth);
    this.accountSelectionService.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((b) => (this.isReadOnly = b));
  }

  changeProductsFilter(productsFilter: string): void {
    const filter = productsFilter.trim();
    this.productsFilter = filter;
    this.dataSource.filter = filter;
  }

  async downloadFile() {
    this.csvExportService.exportCsv(
      {
        prefix: "inventory",
        accountGroupName: this.accountGroupName,
        marketplace: this.marketplace,
      },
      this.dataSource.data,
      [
        simpleField("asin"),
        fieldExtractor("title", (stat) => stat.title),
        constantField("currency", this.currency),
        fieldExtractor("price", (stat) => stat.price?.toFixed(2) ?? "-"),
        metricField(ORDERS_7D),
        metricField(ORDERS_30D),
        fieldExtractor("estimatedDaysOfStock", (stat) => stat["estimatedDaysOfStock"].toString()),
        metricField(FULFILLABLE_STOCK),
        metricField(FULFILLABLE_STOCK_VALUE, "availableValue"),
        metricField(FBM_STOCK),
        metricField(FBM_STOCK_VALUE, "availableFbmValue"),
        metricField(INBOUND_STOCK),
        metricField(INBOUND_STOCK_VALUE, "inboundValue"),
        metricField(RESERVED_STOCK),
        metricField(RESERVED_STOCK_VALUE, "reservedValue"),
        metricField(UNFULFILLABLE_STOCK),
        metricField(UNFULFILLABLE_STOCK_VALUE, "unfulfillableValue"),
        fieldExtractor(
          "pauseAdvertisingThreshold",
          (stat) =>
            this.inventoryRules.asinInventoryRule.get(stat.asin)?.advertisingPauseThreshold.toFixed(0) ?? "None",
        ),
        fieldExtractor("isAdvertisingPaused", (stat) =>
          this.inventoryRules.execute(stat.asin, stat as InventoryStats).shouldPauseAdvertising ? "Yes" : "No",
        ),
      ],
    );
  }

  getActiveStrategies(asin: string) {
    return (this.strategies.get(asin) ?? []).filter((s) => s.state == StrategyStateEnum.ENABLED).length;
  }

  openInfo(asin: string) {
    const modalOptions: ModalOptions = {
      initialState: {
        strategies: this.strategies.get(asin) ?? [],
        asinnumber: asin,
        marketplace: this.marketplace,
      },
    };
    this.modalService.show(ProductDetailsComponent, modalOptions);
  }

  toggleStockValue() {
    this.displayStockValue = !this.displayStockValue;
    this.displayedColumns = this.getDisplayedColumns(this.displayStockValue);
  }

  formatNumber(value: number) {
    return formatNumber(value, this.locale, "1.0-0");
  }

  setupRuleOnAsin(product: InventoryStats): void {
    const rule = this.inventoryRules.asinInventoryRule.get(product.asin);
    const modalOptions: ModalOptions = {
      initialState: {
        accountId: this.accountId,
        marketplace: this.marketplace,
        asin: product.asin,
        rule: rule,
        warningMessage:
          product.orders30d < InventoryRules.ORDERS30D_ELLIGIBILITY_THRESHOLD
            ? "Threshold to pause advertising will not be considered for this product as there is less than 10 orders over the last 30 days"
            : undefined,
      },
    };
    const modalRef = this.modalService.show(AsinInventoryRuleModalComponent, modalOptions);

    const subscription = modalRef.content.saveRule
      .pipe(
        filter(
          (newRule) =>
            !rule ||
            rule.advertisingPauseThreshold != newRule.advertisingPauseThreshold ||
            rule.activateAdvertisingWhenInbound != newRule.activateAdvertisingWhenInbound,
        ),
        switchMap((newRule) => this.asinService.setAsinInventoryRules(newRule)),
      )
      .subscribe(
        () => {
          this.toasterService.success(
            `Advertising pausing rule setup for ASIN ${product.asin}`,
            "Inventory rule updated",
          );
          subscription.unsubscribe();
        },
        (error) => {
          this.toasterService.error(
            `Error when setting an advertising pausing rule setup for asin ${product.asin}: ${error}`,
            "Inventory rule update error",
          );
          subscription.unsubscribe();
        },
      );
    if (rule) {
      subscription.add(
        modalRef.content.deleteRule
          .pipe(
            switchMap(() => this.asinService.deleteAsinInventoryRules(this.accountId, this.marketplace, product.asin)),
          )
          .subscribe(
            () => {
              this.toasterService.success(
                `Advertising pausing rule removed for ASIN ${product.asin}`,
                "Inventory rule deleted",
              );
              subscription.unsubscribe();
            },
            (error) => {
              this.toasterService.error(
                `Error deleting advertising pausing rule setup for ASIN ${product.asin}: ${error}`,
                "Inventory rule deletion error",
              );
              subscription.unsubscribe();
            },
          ),
      );
    }
  }

  getNumberOfAsins(): number {
    return this.dataSource.filteredData.length;
  }

  setupRuleForAllAsins(): void {
    const modalOptions: ModalOptions = {
      initialState: {
        accountId: this.accountId,
        marketplace: this.marketplace,
        warningMessage: `Warning: this will override the limit already defined on the ASINs.`,
        multipleAsinsBatchLength: this.getNumberOfAsins(),
      },
    };
    const modalRef = this.modalService.show(AsinInventoryRuleModalComponent, modalOptions);

    const subscription = merge(modalRef.content.saveRule, modalRef.content.deleteRule)
      .pipe(
        map((rule) => {
          const asins = new Set<string>();
          const rules: InventoryRule[] = [];
          for (const stat of this.dataSource.filteredData) {
            if (asins.has(stat.asin)) {
              continue;
            }
            asins.add(stat.asin);
            rules.push({
              accountId: this.accountId,
              marketplace: this.marketplace,
              asin: stat.asin,
              advertisingPauseThreshold: rule ? rule.advertisingPauseThreshold : -1,
              activateAdvertisingWhenInbound: rule ? rule.activateAdvertisingWhenInbound : false,
            });
          }
          return rules;
        }),
        switchMap((rules) => this.asinService.setInventoryRulesInBulk(rules)),
      )
      .subscribe(
        () => {
          this.toasterService.success(`Advertising pausing rule setup for all visible ASINs`, "Inventory rule updated");
          subscription.unsubscribe();
        },
        (error) => {
          this.toasterService.error(
            `Error when setting an advertising pausing rule setup for all ASINs: ${error}`,
            "Inventory rule update error",
          );
          subscription.unsubscribe();
        },
      );
  }

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.smallMode = this.isSmallMode(window.innerWidth);
  }

  private isSmallMode(innerWidth: number): boolean {
    return innerWidth < 1550; // small screen when < 1550px
  }

  private getDisplayedColumns(displayStockValue: boolean) {
    return this.otherColumns
      .concat(this.orderMetrics.map((m) => m.id))
      .concat("estimatedDaysOfStock")
      .concat("advertisingAction")
      .concat("availableStock" + (displayStockValue ? "Value" : ""))
      .concat((displayStockValue ? this.valueMetrics : this.quantityMetrics).map((m) => m.id));
  }

  protected readonly Number = Number;
}

@Pipe({ name: "fulfillmentNetworkStockFormatPipe" })
export class FulfillmentNetworkStockFormatPipe implements PipeTransform {
  transform(stats: InventoryStats, locale: string, currency: string, valueMode: boolean): string {
    const result = [];
    if (stats.fulfillableQuantity > 0) {
      const metric = valueMode ? FULFILLABLE_STOCK_VALUE : FULFILLABLE_STOCK;
      result.push(`FBA: ${metric.format(stats, locale, currency, "1.0-2")}`);
    }
    if (stats.fbmStock > 0) {
      const metric = valueMode ? FBM_STOCK_VALUE : FBM_STOCK;
      result.push(`FBM: ${metric.format(stats, locale, currency, "1.0-2")}`);
    }
    if (result.length > 1) {
      const metric = valueMode ? FULFILLABLE_STOCK_VALUE : FULFILLABLE_STOCK;
      const value = valueMode
        ? stats.fbmStockValue + stats.fulfillableQuantityValue
        : stats.fbmStock + stats.fulfillableQuantity;
      result.push(`Total: ${metric.format(value, locale, currency, "1.0-2")}`);
    }
    if (result.length == 0) {
      const metric = valueMode ? FULFILLABLE_STOCK_VALUE : FULFILLABLE_STOCK;
      return metric.format(0, locale, currency, "1.0-2");
    }
    return result.join(" - ");
  }
}
