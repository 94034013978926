@if (isReadOnly === true) {
  <div>You are not allowed to access the strategy group migration page</div>
}
@if (isReadOnly === false) {
  <div class="flex h-full w-full">
    <div class="flex w-1/2 flex-col border-r pt-4">
      <h3 class="mb-4 flex justify-between px-4">
        <span>Strategy Candidates</span
        ><a href="#" class="ml-1" (click)="openExplanationModal(explanation); $event.preventDefault()"
          ><small>More info</small></a
        >
      </h3>
      <app-strategy-product-group-hybrid-dropdown
        placeholder="Select a Strategy or a Product Group"
        [strategies]="allStrategies"
        [productGroups]="allProductGroups"
        (selectedStrategyOrProductGroup)="setStrategyOrProductGroup($event)"
        class="mb-4 px-4"
      ></app-strategy-product-group-hybrid-dropdown>
      <div class="flex flex-col gap-3 overflow-auto px-4">
        @for (candidate of candidates; track candidate) {
          <ng-container *ngTemplateOutlet="candidateCard; context: { candidate: candidate, content: candidateActions }">
          </ng-container>
        }
      </div>
    </div>
    <div class="flex flex-auto flex-col py-4 pl-4">
      <h3 class="mb-4">New Strategy Group</h3>
      @if (!stratOrProductGroupFilter) {
        <div>Strategy Group to create will be displayed here</div>
      }
      @if (stratOrProductGroupFilter && loading) {
        <div class="flex items-center justify-center">
          <app-spinner [display]="true"></app-spinner>
        </div>
      }
      @if (stratOrProductGroupFilter) {
        <form [formGroup]="form" (ngSubmit)="submitMigrationForm()" class="flex h-[95%] flex-col justify-between">
          <div class="input-section">
            <input
              type="text"
              maxlength="80"
              class="form-control simple-input"
              placeholder="Enter a name for the strategy group to create"
              id="strategyGrouoName"
              [class.disabled]="selectedCandidates.length <= 1"
              [formControl]="form.controls.strategyGroupName"
              [class.border-danger]="formSubmitted && form.controls.strategyGroupName.errors"
            />
            <div class="hint">{{ form.controls.strategyGroupName.value.length }}/80</div>
          </div>
          <div class="flex flex-auto flex-col gap-3 overflow-auto px-4">
            @for (candidate of selectedCandidates; track candidate) {
              <ng-container
                *ngTemplateOutlet="candidateCard; context: { candidate: candidate, content: selectedCandidateActions }"
              >
              </ng-container>
            }
          </div>
          <div class="mt-4 flex items-center justify-between">
            <div class="font-bold">
              <a (click)="showStrategyGroupAsins(); $event.preventDefault()" class="asins"
                >{{ strategyGroupAsins.size }} ASIN{{ strategyGroupAsins.size > 1 ? "s" : "" }}</a
              >
            </div>
            <button type="submit" class="btn btn-primary">Create Strategy Group</button>
          </div>
        </form>
      }
    </div>
  </div>
}

<ng-template #candidateActions let-candidate="candidate">
  <div class="font-bold">Tranform to:</div>
  @for (type of strategyTypes; track type) {
    <IButton
      variant="outline"
      [block]="true"
      class="w-full"
      [tooltipValue]="candidate.message[type]"
      (onClick)="addToStrategyGroup(candidate, type)"
      [label]="StrategyTypeStr[type] + ' strategy'"
      [disabled]="!candidate.possibleStrategyTypes.includes(type)"
      size="xs"
    />
  }
</ng-template>

<ng-template #selectedCandidateActions let-candidate="candidate">
  @if (candidate.strategyType !== StrategyType.PRODUCT) {
    <ISelect
      [options]="candidateOptions"
      [selected]="{ value: candidate.strategyType, label: StrategyTypeStr[candidate.strategyType] }"
      (selectedChange)="toggleStrategyType(candidate, $event.value)"
    />
  } @else {
    <IButton variant="outline" [block]="true" class="w-full" [label]="StrategyTypeStr[candidate.strategyType]">
      {{ StrategyTypeStr[candidate.strategyType] }}
    </IButton>
  }
  <div class="flex justify-end">
    <IButton
      tooltipValue="Remove from strategy group"
      color="red"
      [icon]="ICON_TRASH"
      (onClick)="removeFromStrategyGroup(candidate)"
    />
  </div>
</ng-template>

<ng-template #candidateCard let-candidate="candidate" let-content="content">
  <div class="flex flex-col rounded border bg-white p-2" [class.disabled]="candidate.selected">
    <h4 class="flex items-center justify-between">
      <div>
        <span
          class="mr-2"
          matTooltipPosition="above"
          [matTooltip]="candidate.strategy.state === State.ENABLED ? 'Status: Active' : 'Status: Inactive'"
        >
          @if (candidate.strategy.state === State.ENABLED) {
            <fa-icon [icon]="faPlayCircle" class="activated"></fa-icon>
          }
          @if (candidate.strategy.state !== State.ENABLED) {
            <fa-icon [icon]="faPauseCircle" class="desactivated"></fa-icon>
          }</span
        >{{ candidate.strategy.name }}
      </div>
      <a
        class="btn btn-ghost-secondary btn-lg custom-btn-dark-hover"
        target="_blank"
        routerLink="{{ strategyPageUrl }}/{{ candidate.strategy.strategyId }}"
        queryParamsHandling="merge"
        matTooltip="Go to Strategy Page"
      >
        <fa-icon [icon]="faExternalLink"></fa-icon>
      </a>
    </h4>
    @if (candidate.linkedStrategy) {
      <div [style.color]="candidate.linkColor" class="mb-3">
        <fa-icon [icon]="faLink"></fa-icon>&nbsp;Linked to strategy {{ candidate.linkedStrategy.name }}
      </div>
    }
    <div class="flex justify-between">
      <div class="mr-2 flex w-60 flex-col justify-between">
        <app-algo-target-renderer
          [algoTarget]="candidate"
          [locale]="locale"
          [currency]="currency"
          [readonly]="true"
          [addLabel]="true"
        ></app-algo-target-renderer>
        <div>
          <a (click)="showStrategyAsins(candidate.strategy); $event.preventDefault()" class="asins"
            >{{ candidate.strategy.asins.length }} ASIN{{ candidate.strategy.asins.length > 1 ? "s" : "" }}</a
          >
        </div>
        <div>{{ candidate.strategy.tactics.length }} Tactic{{ candidate.strategy.tactics.length > 1 ? "s" : "" }}</div>
        @if (candidate.strategy.tactics.length > 0) {
          <ul>
            @for (tacticDetails of getTacticDetails(candidate.strategy) | keyvalue; track tacticDetails) {
              <li>{{ tacticDetailsKeys[tacticDetails.key] }}: {{ tacticDetails.value }}</li>
            }
          </ul>
        }
        <div>AI Targeting: {{ candidate.strategy.autoAlgoExplorationEnabled ? "ON" : "OFF" }}</div>
      </div>
      <div class="flex flex-auto flex-col gap-2">
        <ng-container *ngTemplateOutlet="content; context: { candidate: candidate }"></ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #explanation>
  <div class="p-4">
    <h4>What is a Strategy Group?</h4>
    <p>
      A Strategy Group is an innovative feature designed to streamline your advertising efforts. It acts as an umbrella,
      uniting multiple advertising strategies under a single, cohesive structure. This setup is particularly useful for
      managing different advertising approaches for the same set of products based on various traffic types.
    </p>
    <p>
      For instance, you might want to advertise certain products in one way when dealing with branded traffic, and in a
      completely different style for other types of traffic. The Strategy Group facilitates this by allowing you to
      create a 'Brand Defense Strategy' specifically for your branded traffic, while simultaneously maintaining a
      separate strategy for all other traffic types. All these strategies are neatly housed within the same Strategy
      Group, ensuring a coordinated and efficient advertising process.
    </p>
    <h4>About this page</h4>
    <div>
      <p>The aim of this page is to help you to migrate existing strategies to the new strategy group format.</p>
      <p>
        First, select a strategy or a product group. On the left panel, this should display strategies running on the
        same ASINs of the selected strategy/product group. They are your <b>strategy candidates</b>.
      </p>
      <p>
        You can then select a strategy candiate and add it to a new strategy group. It can be migrated as a
        <b>main</b> strategy, as a <b>brand defense</b> strategy or as a <b>focus</b> strategy with the following
        limitations:
      </p>
      <ul>
        <li>
          Main strategy: ASINs of main strategies cannot overlap (the same ASIN cannot be in two main strategies of the
          same strategy group)
        </li>
        <li>
          Brand defense strategy: A brand strategy has one non-blacklist tactic and if this tactic is on a product
          segment, the products should be in your catalog. Also AI targeting should be OFF. Also there should be a main
          strategy running with the ASINs of this strategy.
        </li>
        <li>
          Focus strategy: A forcus strategy has one non-blacklist tactic and if this tactic is on a product segment, the
          products could be in your catalog or not. Also AI targeting should be OFF. Also there should be a main
          strategy running with the ASINs of this strategy.
        </li>
      </ul>
    </div>
  </div>
</ng-template>
