import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import {
  faChartLine,
  faCheckSquare,
  faMinusCircle,
  faPauseCircle,
  faPercent,
  faPlayCircle,
  faPlusCircle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  AccountMarketplace,
  AccountSelectionService,
  ACOS,
  AD_CONVERSIONS,
  AD_SALES,
  AdStatsEx,
  CampaignType,
  CLICKS,
  CONVERSION_RATE,
  COST,
  CPC,
  Marketplace,
  mergeSeveralDates,
  Metric,
  MetricType,
  ROAS,
  StrategyEx,
  StrategyStateEnum,
  UserSelectionService,
  Utils,
} from "@front/m19-services";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { Option } from "@front/m19-ui";
import { CsvExportService, fieldExtractor, metricField, simpleField } from "@m19-board/services/csv-export.service";
import { ICON_CHART_LINE } from "@m19-board/utils/iconsLabels";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { BehaviorSubject, Observable, of } from "rxjs";
import { StrategyStats } from "../../../models/Metric";
import { TARGET_ACOS } from "../../../models/MetricsDef";
import { CAMPAIGN_TYPE_NAME } from "../../overview/overview.component";
import { DayPartingPopUpComponent } from "../day-parting-pop-up/day-parting-pop-up.component";

@UntilDestroy()
@Component({
  selector: "app-hourly-table",
  templateUrl: "./hourly-table.component.html",
  styleUrls: ["./hourly-table.component.scss"],
})
export class HourlyTableComponent implements OnInit, OnDestroy {
  public readonly MetricType = MetricType;
  readonly ICON_CHART = ICON_CHART_LINE;

  @Input() currency: string;
  @Input() locale: string;
  @Input() strategiesMap: Map<number, StrategyEx> = new Map();

  readonly HOURS = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
  ];

  readonly CampaignTypes = [CampaignType.SP];
  readonly CampaignTypeNames = CAMPAIGN_TYPE_NAME;

  readonly DEFAULT_STRATEGY = ["strategyStatus", "strategyName"];

  readonly campaignTypeOptions: Option<CampaignType | null>[] = [
    {
      label: "All Campaign Types",
      value: null,
    },
    {
      label: this.CampaignTypeNames[CampaignType.SP],
      value: CampaignType.SP,
    },
  ];
  selectedCampaignType = signal<Option<CampaignType | null>>(this.campaignTypeOptions[0]);

  displayedColumns: string[];
  dataSource = new MatTableDataSource<Map<string, StrategyStats>>([]);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  dataByStrategiesHour: Map<string, StrategyStats>[] = [];
  avgDataByStrategiesHour: Map<string, StrategyStats>[] = [];

  @Input() set statsByHour(data: Map<string, AdStatsEx[]>) {
    const tmp = [];
    if (data) {
      data.forEach((values, key, map) => {
        tmp.push(new Map(values.map((x) => [x.hour.toString(), x])));
        tmp[tmp.length - 1].set("total", this.getTotal(values));
      });
    }
    this.dataByStrategiesHour = tmp;
    this.dataSource.data = tmp;
  }

  @Input() set avgByHour(data: Map<string, AdStatsEx[]>) {
    const tmp = [];
    if (data) {
      data.forEach((values, key, map) => {
        tmp.push(new Map(values.map((x) => [x.hour.toString(), x])));
        tmp[tmp.length - 1].set("total", this.getTotal(values));
      });
    }
    this.avgDataByStrategiesHour = tmp;
  }

  @Input()
  accountMarketplace$: Observable<AccountMarketplace>;

  accountGroupName: string;
  marketplace: Marketplace;

  @Input()
  selectedMetric$: BehaviorSubject<Metric<StrategyStats>[]>;

  @Input()
  customMetrics$: BehaviorSubject<Metric<AdStatsEx>[]>;
  customMetrics: Metric<AdStatsEx>[] = [];

  @Input()
  chartDisplayed;

  @Output()
  chartDisplayChange = new EventEmitter<boolean>();

  private modal: BsModalRef;
  smallMode = false;
  showAbsValues = true;
  displayOnlyActiveStrategies = false;
  public filter = "";

  dayPartingStrategyOver: number = undefined;
  dayPartingHoverHour: number = undefined;
  dayPartingPauseHour: number = undefined;
  dayPartingReactivationHour: number = undefined;
  dayPartingStrategySelected: number = undefined;
  firstClick: boolean;
  clickInside = false;

  init = false;
  isReadOnly = false;

  readonly faSearch = faSearch;
  readonly faChartLine = faChartLine;
  readonly faPlus = faPlusCircle;
  readonly faMinus = faMinusCircle;
  readonly faPercentage = faPercent;
  readonly faPlayCircle = faPlayCircle;
  readonly faPauseCircle = faPauseCircle;
  readonly faCheckSquare = faCheckSquare;
  readonly faSquare = faSquare;

  constructor(
    private userSelectionService: UserSelectionService,
    private modalService: BsModalService,
    private accountSelectionService: AccountSelectionService,
    private csvExportService: CsvExportService,
  ) {}

  ngOnInit(): void {
    this.displayedColumns = this.DEFAULT_STRATEGY.concat(this.HOURS).concat(["total"]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.dataSource.sortingDataAccessor = (item, property): string | number => {
      let stat: Map<string, StrategyStats> | StrategyStats = item;
      if (property in this.HOURS) {
        stat = stat.get(property);
      } else if (property == "total") {
        stat = stat.get("total");
      } else {
        stat = stat.values().next().value;
      }
      if (property == "strategyName") return (stat as StrategyStats).strategyName;
      if (property == "strategyStatus") return (stat as StrategyStats).state;

      return this.selectedMetric$.value[0] ? this.selectedMetric$.value[0].value(stat as StrategyStats) : 0;
    };

    // overridding internal function so it triggers even if this.datasource.filter has not changed
    this.dataSource._filterData = (data: Map<string, StrategyStats>[]) => {
      this.dataSource.filteredData = data.filter((obj) => this.dataSource.filterPredicate(obj, this.dataSource.filter));
      return this.dataSource.filteredData;
    };
    this.dataSource.filterPredicate = (row, filter) => {
      const stat = row.values().next().value;
      const regex = new RegExp(filter, "i");
      return (
        (!this.selectedCampaignType().value || stat.campaignType == this.selectedCampaignType().value) &&
        (!this.displayOnlyActiveStrategies || stat.state == StrategyStateEnum.ENABLED) &&
        regex.test(stat.strategyName)
      );
    };

    this.accountMarketplace$.pipe(untilDestroyed(this)).subscribe((am) => {
      this.accountGroupName = am.accountGroupName;
      this.marketplace = am.marketplace;
    });
    this.accountSelectionService.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((b) => (this.isReadOnly = b));

    this.customMetrics$.pipe(untilDestroyed(this)).subscribe((metrics) => {
      this.customMetrics = metrics;
      this.smallMode = this.isSmallMode(window.innerWidth, this.displayedColumns.length);
    });

    this.selectedMetric$.pipe(untilDestroyed(this)).subscribe((x) => {
      if (x.length === 0) this.dataSource.data = [];
      else if (x[0].type === MetricType.RATIO) {
        this.dataSource.data = this.avgDataByStrategiesHour;
      } else {
        this.dataSource.data = this.dataByStrategiesHour;
      }
    });

    this.init = true;
  }

  ngOnDestroy() {
    if (this.modal) this.modal.hide();
  }

  setFilter(filter: string, campaigns: Option<CampaignType | null>, displayActive: boolean) {
    this.filter = filter;
    this.selectedCampaignType.set(campaigns);
    this.displayOnlyActiveStrategies = displayActive;
    this.dataSource.filter = filter;
    this.dataSource._updateChangeSubscription();
  }

  changeFilter(filter: string) {
    this.filter = filter;
    this.dataSource.filter = filter;
  }

  downloadFile() {
    this.csvExportService.exportCsv<StrategyStats>(
      {
        prefix: "strategy_hourly_stats",
        accountGroupName: this.accountGroupName,
        marketplace: this.marketplace,
        dateRange: this.userSelectionService.getDateRangeStr(),
      },
      this.dataSource.data
        .flatMap((x: Map<string, StrategyStats>) => Array.from(x.values()))
        .filter((x) => !!x.accountId),
      [
        fieldExtractor("strategyId", (d) => d.strategyId?.toString() ?? "-"),
        simpleField("strategyName"),
        simpleField("hour"),
        simpleField("currency"),
        metricField(CLICKS),
        metricField(COST),
        metricField(AD_CONVERSIONS),
        metricField(AD_SALES),
        metricField(ACOS),
        metricField(TARGET_ACOS),
        metricField(CONVERSION_RATE),
        metricField(ROAS),
        metricField(CPC),
      ],
    );
  }

  getStripesClass(product: Map<string, StrategyStats>, h: string): string {
    const hour = parseInt(h);
    const stat = product.values().next().value;
    return stat && Utils.isInHourInterval(hour, stat.dayPartingPauseHour, stat.dayPartingReactivationHour)
      ? " stripes"
      : "";
  }

  getSelectedClass(product: Map<string, StrategyStats>, h: string): string {
    const hour = parseInt(h);
    const stat = product.values().next().value;
    return stat &&
      stat.strategyId === this.dayPartingStrategySelected &&
      Utils.isInHourInterval(hour, this.dayPartingPauseHour, this.dayPartingHoverHour)
      ? " selected"
      : "";
  }

  getSelectableClass(product: Map<string, StrategyStats>): string {
    const stat = product.values().next().value;
    return stat.strategyId ? " selectable" : "";
  }

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.smallMode = this.isSmallMode(window.innerWidth, this.displayedColumns.length);
  }

  toggleChartDisplay(): void {
    this.chartDisplayChange.emit(!this.chartDisplayed);
  }

  private isSmallMode(innerWidth: number, columnCount: number): boolean {
    return innerWidth / columnCount < 100;
  }

  changeAbsRelValues(): void {
    this.showAbsValues = !this.showAbsValues;
  }

  getTotal(stats: AdStatsEx[]): AdStatsEx {
    let res: AdStatsEx = {};
    for (let i = 0; i < stats.length && i < 24; i++) {
      res = mergeSeveralDates(res, stats[i]);
    }
    return res;
  }

  getArray<T, D extends { hour: number }>(m: Map<T, D>): D[] {
    return Array.from(m.values()).sort((a, b) => a.hour - b.hour);
  }

  removePercent(str: string): string {
    if (str.endsWith("%")) return str.substring(0, str.length - 1);
    return str;
  }

  @HostListener("document:keyup.escape", ["$event"])
  @HostListener("document:click", ["$event"])
  clickedOutCell(event) {
    if (!this.clickInside || event.type === "keyup") {
      this.dayPartingPauseHour = undefined;
      this.dayPartingStrategySelected = undefined;
      this.firstClick = true;
    }
    this.clickInside = false;
  }

  hourClicked(product: Map<string, StrategyStats>, h: string): void {
    this.clickInside = true;
    const hour = parseInt(h);
    const strat = product.values().next().value;
    if (!strat.strategyId || !strat.campaignType)
      // we do not want to select non M19 operated campaigns
      return;
    if (
      this.dayPartingStrategySelected === undefined ||
      this.dayPartingStrategySelected !== strat.strategyId ||
      this.firstClick
    ) {
      this.dayPartingPauseHour = hour;
      this.dayPartingStrategySelected = strat.strategyId;
      this.dayPartingReactivationHour = undefined;
      this.firstClick = false;
    } else {
      this.firstClick = true;
      this.dayPartingReactivationHour = hour;

      const modalOptions: ModalOptions = {
        initialState: {
          strategy$: of(this.strategiesMap.get(strat.strategyId)),
          currencyCode: this.currency,
          locale: this.locale,
          dayPartingPauseHour: this.dayPartingPauseHour,
          dayPartingReactivationHour: this.dayPartingReactivationHour + 1,
        },
        class: "modal-lg",
      };
      // back to initial state
      this.dayPartingPauseHour = undefined;
      this.dayPartingReactivationHour = undefined;
      this.dayPartingStrategySelected = undefined;

      this.modalService.show(DayPartingPopUpComponent, modalOptions);
    }
  }

  onHover(h: string): void {
    const hour = parseInt(h);
    this.dayPartingHoverHour = hour;
  }

  onOut(): void {
    this.dayPartingHoverHour = undefined;
  }
}
