import { Component, Input, OnInit } from "@angular/core";
import { Marketplace, Metric, OrderStats } from "@front/m19-services";
import {
  FBA_STORAGE_FEE,
  getOrderMetricDetails,
  ORDER_GLOBAL_SALES,
  REIMBURSEMENT,
} from "@m19-board/models/MetricsDef";
import { ProfitDetail } from "@m19-board/product360/product360.service";
import {
  PROFIT_DETAIL_AMOUNT,
  PROFIT_DETAIL_SHARE,
} from "@m19-board/widget/profit-detail-widget/profit-details-widget.component";

export type ProfitDetailsParams = {
  metrics: Metric<OrderStats>[];
  data: OrderStats;
  totalData: OrderStats;
  locale: string;
  currency: string;
  fullDetail: boolean;
  asin: string;
  marketplace: Marketplace;
};

@Component({
  selector: "app-profit-details",
  templateUrl: "./profit-details.component.html",
})
export class ProfitDetailsComponent implements OnInit {
  @Input() asin: string;
  @Input() marketplace: Marketplace;
  @Input() totalData: OrderStats;

  @Input() metrics: Metric<OrderStats>[] = [];

  @Input() data: OrderStats;

  @Input() locale: string;

  @Input() currency: string;

  tooltipsByMetricId: Map<string, string> = new Map();

  profitDetails: Map<string, ProfitDetail>;

  constructor() {
    this.tooltipsByMetricId.set(
      REIMBURSEMENT.id,
      "Only contains reversal reimbursement aggregated at the purchased date of the reletad sale",
    );
    this.tooltipsByMetricId.set(FBA_STORAGE_FEE.id, "Doesn't include long term storage");
    // This events get called by all clicks on the page
  }

  ngOnInit(): void {
    this.profitDetails = this.dataToProfitDetails(this.data);
  }

  getShareValue(share: ProfitDetail): number {
    return PROFIT_DETAIL_SHARE.value(share);
  }

  formatShare(share: ProfitDetail) {
    let val = this.getShareValue(share);
    val = isNaN(val) ? 0 : val < 0 ? -val : val;
    return PROFIT_DETAIL_SHARE.format(val, this.locale, this.currency);
  }

  private dataToProfitDetails(data: OrderStats): Map<string, ProfitDetail> {
    const res = new Map();
    const totalRevenue = ORDER_GLOBAL_SALES.value(this.data);

    for (const m of this.metrics) {
      const aggValue = m.value(data);
      for (const m2 of getOrderMetricDetails(m, true)) {
        // only keep currency metric
        // only keep non null values
        const val = m2.value(this.data);
        if (val != 0) {
          res.set(m2.id, {
            field: m2.title,
            category: m.title,
            value: m2.value(data),
            currency: data.currency,
            aggValue,
            totalRevenue,
          });
        }
      }
    }
    return res;
  }

  readonly PROFIT_DETAIL_SHARE = PROFIT_DETAIL_SHARE;
  readonly PROFIT_DETAIL_AMOUNT = PROFIT_DETAIL_AMOUNT;
}
