import { formatCurrency, formatPercent } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { AlgoMode, AlgoTarget, Currencies, Currency, Strategy } from "@front/m19-services";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { SwitchTargetAlgoModalComponent } from "../switch-target-algo-modal/switch-target-algo-modal.component";
import { ICON_EDIT_O } from "@m19-board/utils/iconsLabels";
import { TranslocoService } from "@jsverse/transloco";

@Component({
  selector: "app-algo-target-renderer",
  templateUrl: "./algo-target-renderer.component.html",
  styleUrls: ["./algo-target-renderer.component.scss"],
})
export class AlgoTargetRendererComponent implements ICellRendererAngularComp, OnInit {
  readonly faEdit = faPencilAlt;
  readonly ICON_EDIT_O = ICON_EDIT_O;

  targetFormatted: string;
  private _algoTarget: AlgoTarget;

  @Input() set algoTarget(t: AlgoTarget | Strategy) {
    this._algoTarget = t as AlgoTarget;
    if (this.currency && this.locale) {
      this.initAlgoTarget(this._algoTarget);
    }
  }

  @Input() locale: string;

  @Input() currency: Currency;

  @Input() readonly: boolean;

  @Input() addLabel: boolean;

  minBid: number;

  constructor(
    private modalService: BsModalService,
    private translocoService: TranslocoService,
  ) {}

  agInit(
    params: ICellRendererParams<AlgoTarget> & {
      locale: string;
      currency: Currency;
      readonly: boolean;
      minBid: number;
      averageDailyBudget: number;
    },
  ): void {
    this.locale = params.locale;
    this._algoTarget = {
      ...params.data,
      dailyBudget: params.averageDailyBudget || params.data.dailyBudget,
      algorithm: params.data.algorithm as AlgoMode,
    };
    this.readonly = params.readonly;
    this.currency = params.currency;
    this.addLabel = false;
    this.minBid = params.minBid;
  }

  ngOnInit(): void {
    this.initAlgoTarget(this._algoTarget);
  }

  private initAlgoTarget(algoTarget: AlgoTarget) {
    switch (algoTarget.algorithm) {
      case AlgoMode.ACOS_TARGET:
        this.targetFormatted = `${formatPercent(this._algoTarget.acosTarget, this.locale, "1.0-0")} ${
          this._algoTarget.dailyBudget
            ? `/ ${formatCurrency(
                this._algoTarget.dailyBudget,
                this.locale,
                Currencies[this.currency].currencySymbol,
                this.currency,
                "1.0-0",
              )}`
            : ""
        }`;
        break;
      case AlgoMode.PRODUCT_LAUNCH:
        this.targetFormatted = `${formatCurrency(
          this._algoTarget.suggestedBid,
          this.locale,
          Currencies[this.currency].currencySymbol,
          this.currency,
          "1.0-2",
        )} / ${formatCurrency(
          this._algoTarget.dailyBudget,
          this.locale,
          Currencies[this.currency].currencySymbol,
          this.currency,
          "1.0-0",
        )}`;
        break;
      case AlgoMode.MONTHLY_BUDGET_TARGET:
        this.targetFormatted = formatCurrency(
          this._algoTarget.monthlyBudget,
          this.locale,
          Currencies[this.currency].currencySymbol,
          this.currency,
          "1.0-0",
        );
        break;
    }
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }

  openTargetModal(): void {
    const options: ModalOptions = {
      initialState: {
        algoTarget: this._algoTarget,
        locale: this.locale,
        minBid: this.minBid,
      },
      class: "modal-primary modal-dialog-centered",
    };
    this.modalService.show(SwitchTargetAlgoModalComponent, options);
  }

  getTooltip(): string {
    switch (this._algoTarget.algorithm) {
      case AlgoMode.ACOS_TARGET:
        if (this._algoTarget.dailyBudget) {
          return this.translocoService.translate("algo-target-renderer.acos_target_with_daily_budget");
        }
        return this.translocoService.translate("algo-target-renderer.acos_target");
      case AlgoMode.PRODUCT_LAUNCH:
        return this.translocoService.translate("algo-target-renderer.suggested_bid");
      case AlgoMode.MONTHLY_BUDGET_TARGET:
        return this.translocoService.translate("metrics.MONTHLY_BUDGET_tooltip");
    }
  }

  getLabel(): string {
    switch (this._algoTarget.algorithm) {
      case AlgoMode.ACOS_TARGET:
        if (this._algoTarget.dailyBudget) {
          return this.translocoService.translate("algo-target-renderer.acos_target_with_daily_budget");
        }
        return this.translocoService.translate("algo-target-renderer.acos_target");
      case AlgoMode.PRODUCT_LAUNCH:
        return this.translocoService.translate("algo-target-renderer.bid_budget");
      case AlgoMode.MONTHLY_BUDGET_TARGET:
        return this.translocoService.translate("metrics.MONTHLY_BUDGET_tooltip");
    }
  }
}
