<app-spinner [display]="loading"></app-spinner>
<ng-container *transloco="let t">
  <div *ngIf="strategy">
    <div class="alert alert-danger" *ngIf="bidderIssue" role="alert">{{ bidderIssue.warningMessage }}</div>
    @if (isStrategyBrandDeleted) {
      <div class="alert alert-warning">
        {{ t("strategy-page.brand_removed") }}
      </div>
    }
    <div class="flex justify-between">
      <div class="flex items-center">
        <a
          class="btn btn-ghost-secondary btn-lg items-center"
          type="button"
          [routerLink]="getStrategyManagerPage()"
          queryParamsHandling="merge"
          matTooltip="{{ t('strategy-page.back_to_strategy_list', [getCampaignTypeName(campaignType)]) }}"
          matTooltipPosition="above"
          data-testid="back-to-strategy-list-button"
        >
          {{ getCampaignTypeName(campaignType) }}s
        </a>
        <span [class]="ICON_CHEVRON_DOWN" class="ml-2 -rotate-90 text-lg text-gray-700"></span>

        <a
          class="btn btn-ghost-secondary btn-lg items-center"
          *ngIf="strategyGroup && !isSingleStrategyInStrategyGroup"
          routerLink="{{ strategyGroupPageUrl }}{{ strategyGroup.strategyGroupId }}"
          queryParamsHandling="merge"
          matTooltip="{{ t('strategy-page.back_to_strategy_group') }}"
          matTooltipPosition="above"
          data-testid="strategy-name"
        >
          {{ strategyGroup.strategyGroupName }}
        </a>
        @if (strategyGroup && !isSingleStrategyInStrategyGroup) {
          <span [class]="ICON_CHEVRON_DOWN + ' ml-2 -rotate-90 text-lg text-gray-700'"></span>
        }
        <button
          data-testid="strategy-name"
          class="btn btn-ghost-secondary image-no-filter icon-right"
          [matMenuTriggerFor]="strategyListMenu"
          (menuOpened)="setFocus()"
        >
          <span class="d-inline-block font-lg">{{ strategy.getName() }}</span>

          <span [class]="ICON_CHEVRON_DOWN" class="ml-2 text-lg text-gray-700"></span>
        </button>

        <mat-menu #strategyListMenu="matMenu">
          <div class="dd-input-container flex items-center" (click)="$event.stopPropagation()">
            <input
              type="text"
              class="simple-input form-control"
              placeholder="{{ t('common.search') }}"
              [value]="strategyNameFilter"
              (input)="strategyNameFilter = getValueFromInputEvent($event)"
              (click)="$event.stopPropagation()"
              #search
            />
          </div>
          <ul class="product-list flex flex-col">
            <ng-container *ngFor="let s of strategyList | searchTextFilter: accessValue : strategyNameFilter">
              <li class="dd-item" (click)="navigateToStrategy(s)">
                {{ s.getName() }}
              </li>
            </ng-container>
          </ul>
        </mat-menu>

        <div
          [matMenuTriggerFor]="menu"
          class="pill ml-4"
          [ngClass]="strategy.state === 'ENABLED' ? 'pill-success' : 'pill-info'"
        >
          @if (strategy.state === "ENABLED") {
            <span [class]="ICON_PLAY + ' mr-2 text-lg text-green-600'"></span>
          } @else {
            <span [class]="ICON_PAUSE + ' mr-2 text-lg text-gray-500'"></span>
          }
          {{ strategy.state === "ENABLED" ? t("common.activated") : t("strategy-page.paused") }}
          <span [class]="ICON_CHEVRON_DOWN" class="ml-2 text-lg text-gray-700"></span>
        </div>

        <div class="pill pill-success ml-3" *ngIf="strategy.primeDayBoost && (boostActivated$ | async)">
          <span [class]="ICON_BOOST" class="text-main mr-2 text-lg"></span>
          {{ t("strategy-page.boost_activated") }}
        </div>
        @if (infoMessageLastUpdate) {
          <div class="pill pill-info ml-4">
            <span [class]="ICON_SYNC + ' mr-2'"></span>
            {{ infoMessageLastUpdate }}
          </div>
        }

        <mat-menu #menu="matMenu">
          <div class="dd-input-container flex items-center">
            <ul class="flex flex-col">
              <li class="dd-item" (click)="changeStatus()" [class.disabled]="isReadOnly || statusChangeDisabled()">
                {{
                  t("strategy-page.strategy_state_enabled_pause_activate_strategy", {
                    value: strategy.state === "ENABLED",
                  })
                }}
              </li>
            </ul>
          </div>
        </mat-menu>
      </div>
    </div>

    <div class="mt-3 flex justify-between px-3">
      <div class="flex items-start">
        <div
          class="strategy-info-bloc group"
          data-testid="info-strategy-name"
          [class.no-hover]="isReadOnly || strategy.defaultStrategy || !strategy.name"
          (click)="editStrategyNameModal(editStrategyNameRef)"
        >
          <div class="title mb-2">
            {{ t("sp-strategy-creation.strategy_name") }}
            <span [class]="ICON_EDIT + ' text-gray-500-filter ml-2 opacity-0 group-hover:opacity-100'"></span>
          </div>
          <div class="font-sm-medium" *ngIf="strategy.name && strategy.name !== ''">{{ strategy.getName() }}</div>
          <button class="desc link" *ngIf="!strategy.name">{{ t("strategy-page.set_strategy_name") }}</button>
        </div>
        <div
          class="strategy-info-bloc group ml-3"
          data-testid="info-algo-mode"
          (click)="changeAlgoMode()"
          [class.no-hover]="strategy.primeDayBoost || strategy.algoMode === AlgoModes.TACOS_TARGET"
          [matTooltip]="strategy.primeDayBoost ? t('strategy-page.cannot_change_when_boosted') : ''"
        >
          <div class="title mb-2">
            {{ t("sd-strategy-creation.algorithm") }}
            @if (!strategy.primeDayBoost && strategy.algoMode !== AlgoModes.TACOS_TARGET) {
              <span [class]="ICON_EDIT + ' text-gray-500-filter ml-2 opacity-0 group-hover:opacity-100'"></span>
            }
          </div>
          <div class="font-sm-medium">{{ AlgoModeStr[strategy.algoMode].shortDescription }}</div>
        </div>
        <div class="strategy-info-bloc group ml-3" (click)="openAlgoTargetModal()">
          <div class="title mb-2">
            {{ getStrategyAlgoValues().title }}
            <span [class]="ICON_EDIT + ' text-gray-500-filter ml-2 opacity-0 group-hover:opacity-100'"></span>
          </div>
          <div
            class="font-sm-medium"
            *ngIf="strategy.algoMode === AlgoModes.PRODUCT_LAUNCH"
            data-testid="suggested-bid-val"
          >
            {{ getStrategyAlgoValues().value | currency: getStrategyAlgoValues().unit : "symbol" : "1.2-2" : locale }}
          </div>
          <div
            class="font-sm-medium"
            *ngIf="strategy.algoMode === AlgoModes.MONTHLY_BUDGET_TARGET"
            data-testid="monthly-budget-val"
          >
            {{ getStrategyAlgoValues().value | currency: getStrategyAlgoValues().unit : "symbol" : "1.0-0" : locale }}
          </div>
          <div class="font-sm-medium" *ngIf="strategy.algoMode === AlgoModes.ACOS_TARGET" data-testid="acos-target-val">
            {{ strategy.acosTarget * 100 | number: "1.0-1" }} %
          </div>
          <div
            class="font-sm-medium"
            *ngIf="strategy.algoMode === AlgoModes.TACOS_TARGET"
            data-testid="acos-target-val"
          >
            {{ strategy.tacosTarget! * 100 | number: "1.0-1" }} %
          </div>
        </div>
        <div
          class="strategy-info-bloc group ml-3"
          *ngIf="strategy.algoMode !== AlgoModes.MONTHLY_BUDGET_TARGET"
          [class.no-hover]="!dailyBudgetVisible"
          (click)="dailyBudgetVisible ? editDailyBudget(dailyBudgetModalRef) : $event.preventDefault()"
        >
          <div class="title mb-2">
            {{
              strategy.algoMode === AlgoModes.PRODUCT_LAUNCH
                ? t("metrics.DAILY_BUDGET_title")
                : t("sp-strategy-group-page.average_daily_budget")
            }}
            <span [class]="ICON_EDIT + ' text-gray-500-filter ml-2 opacity-0 group-hover:opacity-100'"></span>
          </div>
          <div class="font-sm-medium" *ngIf="dailyBudgetVisible" data-testid="budget-val">
            {{ oldDailyBudget | currency: currencySymbol : "symbol" : "1.0-0" : locale }}
          </div>
          <div [matTooltip]="strategy.primeDayBoost ? t('strategy-page.cannot_set_avg') : ''">
            <button
              data-testid="set-budget"
              *ngIf="!dailyBudgetVisible"
              class="desc link"
              [class.disabled]="isReadOnly || strategy.primeDayBoost"
              (click)="editDailyBudget(dailyBudgetModalRef)"
            >
              Set
              {{
                strategy.algoMode === AlgoModes.PRODUCT_LAUNCH
                  ? t("metrics.DAILY_BUDGET_title")
                  : t("sp-strategy-group-page.average_daily_budget")
              }}
            </button>
          </div>
        </div>
        <div class="strategy-info-bloc no-hover ml-3" *ngIf="strategy.algoMode === AlgoModes.MONTHLY_BUDGET_TARGET">
          <div class="title mb-2 mr-4">{{ t("strategy-page.current_spend") }}</div>
          <app-spend-progress-bar
            [currentMonthSpend]="currentMonthSpend"
            [monthlyBudget]="monthlyBudget"
            [endOfCurrentMonth]="endOfMonth"
            [currencyCode]="currencyCode"
            [locale]="locale"
          >
          </app-spend-progress-bar>
        </div>

        <div class="strategy-info-bloc no-hover ml-3" *ngIf="fblMode[strategy.constraint]">
          <div class="title mb-2">
            {{ t("strategy-page.last_algorithm_mode_less_than") }}
            <app-fbl-mode class="d-table" [strategy]="strategy" [size]="FblModeSize.large"></app-fbl-mode>
          </div>
          <div class="strategy-info-bloc no-hover" *ngIf="strategy.strategyGroupId">
            <div class="title mb-2">{{ t("strategy-page.strategy_type") }}</div>
            <div class="font-sm-medium">{{ StrategyTypeStr[strategy.strategyType] }}</div>
          </div>
        </div>
      </div>
    </div>

    <ng-template #editStrategyNameRef>
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{ t("strategy-page.update_strategy_name") }}</h4>
      </div>
      <div class="modal-body">
        <div class="input-block">
          <label>{{ t("sp-strategy-creation.strategy_name") }}</label>
          <input
            data-testid="update-strategy-name-input"
            class="simple-input form-control"
            type="text"
            maxlength="80"
            placeholder="{{ t('sp-strategy-creation.strategy_name') }}"
            [value]="strategy.name"
            (input)="newStrategyName = getValueFromInputEvent($event)"
          />
          <span class="input-block__hint">{{ newStrategyName.length }}/80</span>
        </div>
      </div>
      <div class="modal-footer">
        <IButton label="{{ t('common.cancel') }}" color="gray" variant="soft" (onClick)="strategyTitleModal.hide()" />
        <IButton
          label="{{ t('strategy-page.update_strategy_name') }}"
          (onClick)="changeName(newStrategyName)"
          data-testid="btn-confirm"
        />
      </div>
    </ng-template>

    <ng-template #dailyBudgetModalRef>
      <div class="modal-header">
        <h4 class="modal-title pull-left">
          Update
          {{
            strategy.algoMode === AlgoModes.PRODUCT_LAUNCH
              ? t("metrics.DAILY_BUDGET_title")
              : t("sp-strategy-group-page.average_daily_budget")
          }}
        </h4>
      </div>
      <div class="modal-body">
        <div class="input-block">
          <label>{{
            strategy.algoMode === AlgoModes.PRODUCT_LAUNCH
              ? t("metrics.DAILY_BUDGET_title")
              : t("sp-strategy-group-page.average_daily_budget")
          }}</label>
          <div class="flex items-center">
            <input
              type="number"
              data-testid="daily-budget-input"
              class="simple-input form-control"
              [value]="oldDailyBudget"
              [min]="getDailyBudgetLowerBound()"
              [step]="1"
              [disabled]="isReadOnly"
              (input)="dailyBudget = +getValueFromInputEvent($event)"
            />
            <div class="font-sm-medium ml-2">{{ currencySymbol }}</div>
            @if (strategy.algoMode !== AlgoModes.PRODUCT_LAUNCH) {
              <IButton
                [icon]="ICON_TRASH"
                color="gray"
                variant="ghost"
                tooltipValue="{{ t('sp-strategy-group-page.delete_daily_budget') }}"
                (onClick)="deleteDailyBudget()"
              />
            }
          </div>
          <span class="input-block__hint text-danger mt-2" *ngIf="dailyBudget < getDailyBudgetLowerBound()">
            {{ dailyBudget | intInputErrorPipe: getDailyBudgetLowerBound() : undefined }}
          </span>
        </div>
      </div>
      <div class="modal-footer">
        <IButton label="{{ t('common.cancel') }}" color="gray" variant="soft" (onClick)="dailyBudgetModal.hide()" />
        <IButton
          [label]="
            t('common.update') +
            ' ' +
            (strategy.algoMode === AlgoModes.PRODUCT_LAUNCH
              ? t('metrics.DAILY_BUDGET_title')
              : t('sp-strategy-group-page.average_daily_budget'))
          "
          (onClick)="changeDailyBudget()"
          [disabled]="isInValidDailyBudget()"
          testid="btn-confirm"
        />
      </div>
    </ng-template>

    <ng-content select="[withSbCreatives]"></ng-content>
    <ng-content select="[withSpAsins]"></ng-content>
    <ng-content select="[withSdAsins]"></ng-content>

    <ng-content select="[withSdTargetings]"></ng-content>

    <!-- Tactics -->
    @if (withTacticSection) {
      <div *ngIf="strategy" class="tactics card-perso collapse-card left-child right-child card mb-0">
        <div
          class="card-perso-header collapse-card-header top-child card-header"
          (click)="tacticsvisible = !tacticsvisible"
        >
          <span class="icon-prepend float-left">
            <app-dropdown-toggle-icon [expand]="tacticsvisible"></app-dropdown-toggle-icon>
          </span>
          <h5 class="card-name mb-0">
            {{ t("strategy-page.tactics") }}
            <app-target-limit-warning [targetLimit]="targetLimit" class="d-inline-block"></app-target-limit-warning>
          </h5>
        </div>
      </div>

      <div class="card-perso-body bottom-child card-body" [collapse]="!tacticsvisible">
        <div>
          <div data-testid="tactic-list">
            @if (strategy?.tactics) {
              <app-tactic
                *ngFor="let tactic of strategy.tactics"
                [tactic]="tactic"
                [strategy]="strategy"
                [showKW]="campaignType !== CampaignType.SD"
                [showProducts]="!strategy.disableProductSegment || tactic.tacticType === 'BLACKLIST'"
                [isReadOnly]="isReadOnly"
              ></app-tactic>
            }
          </div>
          <p class="default-message" *ngIf="strategy?.tactics?.length === 0">{{ t("strategy-page.no_tactic") }}</p>
          @if (tacticsvisible) {
            <IButton
              [icon]="ICON_ADD"
              color="white"
              label="{{ t('strategy-page.add_a_tactic') }}"
              [disabled]="isReadOnly"
              (onClick)="createTactic()"
              testid="btn-add-tactic"
            />
          }
        </div>
      </div>
    }

    <!-- Advanced settings -->
    @if (strategy && displayAdvancedSettingsSection) {
      <div class="card-perso collapse-card left-child right-child mb-0">
        <div
          class="card-perso-header collapse-card-header top-child card-header"
          (click)="advancedSettingVisible = !advancedSettingVisible"
          data-testid="adv-settings"
        >
          <span class="icon-prepend float-left">
            <app-dropdown-toggle-icon [expand]="advancedSettingVisible"></app-dropdown-toggle-icon>
          </span>
          <h5 class="card-name mb-0">{{ t("advanced-settings-modal.advanced_settings") }}</h5>
        </div>

        <div class="card-perso-body bottom-child card-body" [collapse]="!advancedSettingVisible">
          <div class="card-body border-shadow left-child right-child">
            <div class="flex w-2/6 flex-col gap-3">
              <!-- Strategy label -->
              @if (advancedSettings.strategyLabel) {
                <div class="flex justify-between">
                  <h6 class="d-inline-block">{{ t("advanced-settings-modal.strategy_label") }}</h6>
                  <div>
                    <IButton
                      color="gray"
                      variant="ghost"
                      [label]="strategy.strategyLabel ? '' + strategy.strategyLabel : t('common.add')"
                      (onClick)="openStrategyLabelModal()"
                      [disabled]="isReadOnly"
                      [icon]="ICON_EDIT"
                    />
                  </div>
                </div>
              }
              <!-- Target Min Daily Spend -->
              @if (advancedSettings.targetMinDailySpend) {
                <div class="flex justify-between">
                  <h6 class="d-inline-block">{{ t("strategy-page.target_min_daily_spend") }}</h6>
                  <IButton
                    color="gray"
                    variant="ghost"
                    [label]="strategy.minDailySpend ? strategy.minDailySpend + ' ' + currencyCode : t('common.add')"
                    (onClick)="openMinDailySpendModal()"
                    [disabled]="isReadOnly"
                    [icon]="ICON_EDIT"
                    testid="min-spend-val"
                  />
                </div>
              }

              <!-- Disable Auto Campaign -->
              @if (advancedSettings.autoCampaign) {
                <div class="flex w-full justify-between">
                  @if (campaignType === CampaignType.SB) {
                    <h6 class="d-inline-block">{{ t("strategy-page.automatic_targeting_campaign_broad_match") }}</h6>
                  } @else {
                    <h6 class="d-inline-block">{{ t("advanced-settings-modal.automatic_targeting_campaign") }}</h6>
                  }
                  <app-switch-input
                    testid="target-campaign"
                    [disabled]="isReadOnly"
                    [checked]="strategy.autoTargetCampainEnabled"
                    switchId="disableAutoCampaignSPSwitch"
                    (onInput)="switchAutoTargetCampain()"
                  ></app-switch-input>
                </div>
              }

              <!-- Disable Product targeting Campaign -->
              @if (advancedSettings.productTargeting) {
                @if (campaignType !== CampaignType.SB) {
                  <div class="flex justify-between">
                    <h6 class="d-inline-block">{{ t("advanced-settings-modal.product_targeting") }}</h6>
                    <app-switch-input
                      testid="product-targeting"
                      [checked]="strategy.productTargetingEnabled"
                      [disabled]="isReadOnly || disableProductTargeting()"
                      switchId="disableAutoCampaignSwitch"
                      (onInput)="switchProductTargeting()"
                    ></app-switch-input>
                  </div>
                }
              }

              <!-- Disable Automated Keyword Management -->
              @if (advancedSettings.aiPoweredTargeting) {
                <div class="flex justify-between">
                  <h6 class="d-inline-block">
                    <app-disable-auto-warning
                      class="d-inline-block"
                      *ngIf="!strategy.autoAlgoExplorationEnabled"
                      [campaignType]="campaignType"
                    ></app-disable-auto-warning>
                    {{ t("advanced-settings-modal.ai-powered_targeting") }}
                  </h6>
                  <app-switch-input
                    testid="ai-targeting"
                    [checked]="strategy.autoAlgoExplorationEnabled"
                    [disabled]="isReadOnly || disableAITargetingToggle()"
                    switchId="disableAutomatedKeywordSwitch"
                    (onInput)="switchAutoAlgoExploration()"
                  ></app-switch-input>
                </div>
                <p *ngIf="!strategy.autoAlgoExplorationEnabled" class="invalid">
                  {{ t("advanced-settings-modal.disabled_warning") }}
                </p>
              }

              <!-- Disable Dayparting -->
              @if (advancedSettings.dayparting) {
                <app-day-parting-inputs
                  [disabled]="isReadOnly"
                  [strategy$]="strategy$"
                  [locale]="singleStrategyDataSet.locale"
                ></app-day-parting-inputs>
              }

              <!-- Strategy Group -->
              @if (advancedSettings.strategyGroup) {
                <div class="my-4 flex items-center gap-4">
                  <IButton
                    color="white"
                    label="{{ t('sp-strategy-group-page.create_brand_defense_strategy') }}"
                    (onClick)="openSubStrategyCreationPage(StrategyType.BRAND)"
                    [disabled]="isReadOnly"
                  />
                  @if ((strategyGroup?.keywordStrategies?.length ?? 0) === 0) {
                    <IButton
                      color="white"
                      label="{{ t('sp-strategy-group-page.create_focus_strategy') }}"
                      (onClick)="openSubStrategyCreationPage(StrategyType.KEYWORD)"
                      [disabled]="isReadOnly"
                    />
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    }

    <!-- Targeting Stats -->
    <ng-content select="[withTargetingStats]"></ng-content>

    <!-- Stats -->
    <div *ngIf="strategy && withStatsSection" class="card-perso collapse-card left-child right-child card mb-0">
      <div class="card-perso-header collapse-card-header top-child card-header" (click)="statsVisible = !statsVisible">
        <span class="icon-prepend float-left">
          <app-dropdown-toggle-icon [expand]="statsVisible"></app-dropdown-toggle-icon>
        </span>
        <div class="flex items-center">
          <h5 class="card-name mb-0 !w-auto">{{ t("overview.strategy_stats") }}</h5>
        </div>
      </div>
      <div class="card-perso-body bottom-child card-body" *ngIf="statsVisible">
        <ng-container *ngIf="strategy">
          <div class="metrics-header mb-4">
            <app-entry-selector
              [pageMetrics]="CHART_METRICS"
              [data]="globalData"
              [previousPeriodData]="previousPeriodGlobalData"
              (chartMetricsChanges)="selectMetrics($event)"
              [localStorageKey]="MetricsSelectorLocalStorageKey.strategy"
            ></app-entry-selector>
          </div>

          <div class="border-shadow card mb-0 p-2" [class.hidden]="isStatsChartHidden">
            <div class="mb-2 flex flex-row justify-between">
              <div class="flex flex-auto items-center">
                <mat-slide-toggle
                  color="primary"
                  [checked]="displayEventAnnotation$ | async"
                  (change)="toggleEventAnnotation($event)"
                  [disabled]="disableEventAnnotation"
                  class="mr-4"
                  ><span class="events-toggle">{{ t("chart-renderer.events") }}</span></mat-slide-toggle
                >
                <activity-event-filters
                  class="w-1/4"
                  [displayEventAnnotation]="(displayEventAnnotation$ | async) ?? false"
                  [disableEventAnnotation]="disableEventAnnotation"
                  [allEventAnnotationTypes]="allEventAnnotationTypes"
                />
              </div>
              <div class="flex items-center justify-end gap-1">
                <app-date-aggreation-switch-button
                  class="ml-4"
                  [selected]="dateAggregation$ | async"
                  (dateAggSelected)="selectAggregation($event)"
                  [displayTitle]="false"
                ></app-date-aggreation-switch-button>
                <IButton
                  color="gray"
                  variant="ghost"
                  tooltipValue="{{ t('common.hide_chart') }}"
                  [icon]="ICON_CLOSE"
                  (onClick)="toggleStatsChartDisplay(true)"
                />
              </div>
            </div>
            <canvas
              #strategyChart="base-chart"
              baseChart
              class="chart"
              [datasets]="singleStrategyDataSet.chartDataSet"
              type="line"
              [labels]="singleStrategyDataSet.labels"
              [options]="singleStrategyDataSet.lineChartOptions"
            >
            </canvas>
          </div>
          <div class="my-3 flex w-full justify-end gap-1">
            <app-export-button
              tooltipValue="{{ t('strategy-detail-stats-modal.export_startegy_csv') }}"
              (export)="exportStrategyStatsCsv()"
            />
            @if (isStatsChartHidden) {
              <IButton
                tooltipValue="{{ t('common.show_chart') }}"
                [icon]="ICON_CHART"
                [square]="true"
                (onClick)="toggleStatsChartDisplay(false)"
                color="white"
              />
            }
          </div>
          @if (strategy.campaignType === CampaignType.SB) {
            <mat-tab-group
              animationDuration="0ms"
              class="mt-2"
              [selectedIndex]="selectedStatsTab"
              (selectedIndexChange)="selectedStatsTab = $event"
            >
              <mat-tab label="{{ t('strategy-detail-stats-modal.by_creative') }}">
                <div class="mt-2 h-[30rem]">
                  <app-sb-creative-stats-grid
                    #creativeGrid
                    [strategy]="strategy"
                    [currency]="currency"
                    [locale]="locale"
                  />
                </div>
              </mat-tab>
              <mat-tab label="{{ t('strategy-detail-stats-modal.by_tactic_placement') }}">
                <div class="mt-2 h-[30rem]">
                  <app-strategy-placement-stats-grid
                    #tacticPlacementGrid
                    [strategy]="strategy"
                    [currency]="currency"
                    [locale]="locale"
                  />
                </div>
              </mat-tab>
            </mat-tab-group>
          } @else {
            <div class="mt-2 h-[30rem]">
              <app-strategy-placement-stats-grid
                #tacticPlacementGrid
                [strategy]="strategy"
                [currency]="currency"
                [locale]="locale"
              />
            </div>
          }
        </ng-container>
      </div>
    </div>

    <!-- Activities -->
    <div *ngIf="strategy && withActivitiesSection" class="card-perso collapse-card left-child right-child card">
      <div
        class="card-perso-header collapse-card-header top-child card-header"
        (click)="activitiesVisible = !activitiesVisible"
      >
        <span class="icon-prepend float-left">
          <app-dropdown-toggle-icon [expand]="activitiesVisible"></app-dropdown-toggle-icon>
        </span>
        <h5 class="card-name mb-0">{{ t("v2-sidebar.activities") }}</h5>
      </div>

      <div class="card-perso-body bottom-child card-body activity-section" *ngIf="activitiesVisible">
        <p *ngIf="dateRange" class="text-gray-700">
          {{ t("sp-strategy-group-page.activities_registered_within_selected_date_range") }}
          <b>{{
            (dateRange[0] | date: "shortDate" : undefined : locale) +
              " " +
              t("day-parting-inputs.to_hours") +
              " " +
              (maxActivitiesDate | date: "shortDate" : undefined : locale)
          }}</b>
        </p>
        <app-strategy-activity-component [strategy]="strategy"></app-strategy-activity-component>
      </div>
    </div>

    <div *ngIf="!strategy && !loading">{{ t("strategy-page.strategy_page_cannot_be_found") }}.</div>

    <ng-template #updateMinDailySpendModal>
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{ t("strategy-page.update_min_daily_spend") }}</h4>
      </div>
      <div class="modal-body">
        <div class="input-block">
          <label>{{ t("metrics.MIN_DAILY_SPEND_title") }}</label>
          <app-integer-value
            testid="min-spend-input"
            [min]="getMinDailyBudgetLowerBound()"
            [max]="maxMinDailySpend"
            [value]="minDailySpend"
            [unit]="currencyCode"
            [size]="14"
            (onChange)="minDailySpend = $event"
          />
          @if (minDailySpend > maxMinDailySpend) {
            <p class="mb-0 mt-2 pl-2 text-sm text-red-400">
              {{
                t("advanced-settings-modal.min_daily_spend_must_be_lower_than") +
                  (dailyBudget > 0
                    ? (dailyBudget / 2 | currency: currencyCode : "symbol-narrow" : "1.0-0") +
                      t("strategy-page.half_of_the_average_daily_budget_and_lower_than")
                    : "") +
                  (getUserMaxMinDailyBudgetInEuros() | currency: currencyCode : "symbol-narrow" : "1.0-0")
              }}
            </p>
          }
        </div>
      </div>
      <div class="modal-footer">
        <div class="flex w-full justify-between">
          <IButton label="Cancel" color="gray" variant="soft" (onClick)="cancelMinDailySpend()" />
          <div class="flex flex-1 justify-end">
            @if (strategy.minDailySpend) {
              <IButton
                [icon]="ICON_TRASH"
                label="{{ t('common.remove') }}"
                color="red"
                variant="soft"
                [disabled]="minDailySpend === 0"
                class="mr-2"
                (onClick)="resetMinDailySpend()"
              />
            }
            <IButton
              testid="btn-spend-confirm"
              label="{{ t('common.update') }}"
              [disabled]="
                !minDailySpend || minDailySpend < getMinDailyBudgetLowerBound() || minDailySpend > maxMinDailySpend
              "
              (onClick)="updateMinDailySpend()"
              [block]="true"
              class="w-2/4"
            />
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #updateLabelModal>
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{ t("advanced-settings-modal.strategy_label") }}</h4>
      </div>
      <div class="modal-body">
        <div class="input-block">
          <label>{{ t("strategy-page.label") }}</label>
          <input
            class="simple-input form-control"
            [value]="strategy.strategyLabel ?? ''"
            placeholder="{{ t('strategy-page.enter_strategy_label') }}"
            [maxLength]="80"
            (keydown.enter)="changeStrategyLabel()"
            (input)="strategyLabel = getValueFromInputEvent($event)"
          />
          <span class="input-block__hint">{{ strategy.strategyLabel ? strategy.strategyLabel?.length : "0" }}/80</span>
        </div>
      </div>
      <div class="modal-footer">
        <div class="flex w-full justify-between">
          <IButton label="{{ t('common.cancel') }}" color="gray" variant="soft" (onClick)="openedModalRef?.hide()" />
          <div class="flex flex-1 justify-end">
            @if (strategy.strategyLabel) {
              <IButton
                [icon]="ICON_TRASH"
                label="{{ t('common.remove') }}"
                color="red"
                variant="soft"
                class="mr-2"
                (onClick)="removeStrategyLabel()"
              />
            }
            <IButton
              label="{{ t('common.update') }}"
              [disabled]="strategyLabel === strategy.strategyLabel"
              (click)="changeStrategyLabel()"
              [block]="true"
              class="w-2/4"
            />
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</ng-container>
