import { Marketplaces, Product } from "@front/m19-services";
import { ColDef } from "@ag-grid-community/core";
import { ImageComponent } from "@m19-board/shared/image/image.component";
import { ACTIONS_COL_ID, emptyColumnHeader, IMAGE_COL_ID } from "./grid-config";

export const MarketplaceColumn: ColDef = {
  field: "marketplace",
  headerName: "Marketplace",
  headerTooltip: "Marketplace",
  cellRendererSelector: (params) => {
    if (params.node.isRowPinned()) return undefined;
    const marketplace = params.value;
    if (!marketplace) return undefined;
    return {
      component: ImageComponent,
      params: {
        imageUrl: `assets/img/mp_flags/${marketplace.toLowerCase()}.svg`,
        altText: marketplace,
        width: "20",
        height: "20",
        tooltip: `${marketplace} - ${Marketplaces[marketplace].name}`,
      },
    };
  },
  floatingFilter: true,
  filter: "agSetColumnFilter",
  filterValueGetter: (params) => {
    if (params.data.marketplace) {
      const marketplace = params.data.marketplace;
      const marketplaceDesc = Marketplaces[params.data.marketplace];
      return `${marketplaceDesc.flag} ${marketplace} - ${marketplaceDesc.name}`;
    }
    return "";
  },
  cellStyle: { textAlign: "center" },
};

export const ImageColumn: ColDef<Product> = {
  headerName: "Image",
  colId: IMAGE_COL_ID,
  pinned: "left",
  field: "asin",
  filter: false,
  sortable: false,
  cellClass: "sensitive-data",
};

export const CurrencyColumn: ColDef = {
  headerName: "Currency",
  colId: "currency",
  field: "currency",
  suppressFiltersToolPanel: true,
  hide: true,
};

export function actionColumnProperties<T, V>(): Partial<ColDef<T, V>> {
  return {
    headerName: "Actions",
    suppressColumnsToolPanel: true,
    colId: ACTIONS_COL_ID,
    pinned: "right",
    lockPinned: true,
    lockPosition: "right",
    filter: false,
    sortable: false,
    suppressSizeToFit: true,
    suppressFiltersToolPanel: true,
    ...emptyColumnHeader(),
    cellClass: "flex items-center",
  };
}
