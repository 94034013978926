import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { faList, faPlusCircle, faSearch, faThLarge } from "@fortawesome/free-solid-svg-icons";
import {
  AccountMarketplace,
  AccountSelectionService,
  AccountState,
  AuthService,
  CampaignType,
  ConfigService,
  StrategyEx,
  StrategyGroupEx,
  StrategyStateEnum,
  StrategyType,
  UserSelectionService,
  Utils,
} from "@front/m19-services";
import { Option } from "@front/m19-ui";
import { SwitchButtonType } from "@m19-board/shared/switch-button/switch-button.component";
import { ICON_ADD, ICON_IMPORT, ICON_WRENCH } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Constant } from "libs/m19-services/src/lib/m19-services/constant";
import { StrategyCache } from "libs/m19-services/src/lib/m19-services/strategy.cache";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { combineLatest } from "rxjs";
import { delay, switchMap, tap } from "rxjs/operators";
import {
  BulkImportService,
  StrategyBulkOperations,
  StrategyUploadResult,
} from "../strategy-bulk-upload-modal/bulk-import.service";
import { StrategyBulkUploadModalComponent } from "../strategy-bulk-upload-modal/strategy-bulk-upload-modal.component";
import { StrategyBulkUploadReportModalComponent } from "../strategy-bulk-upload-modal/strategy-bulk-upload-report-modal.component";
import { StrategyBulkUploadResultModalComponent } from "../strategy-bulk-upload-modal/strategy-bulk-upload-result-modal.component";

export enum ViewTypeEnum {
  CARD = "CARD",
  LIST = "LIST",
}

export enum StrategyStatusFilter {
  ALL,
  ON,
  OFF,
}

@Component({
  selector: "app-strategy-list",
  templateUrl: "./strategy-list.component.html",
})
@UntilDestroy()
export class StrategyListComponent implements OnInit {
  @Input()
  campaignType: CampaignType;
  @Output()
  strategyCreationClick = new EventEmitter<AccountMarketplace>();

  public StrategyStateEnum = StrategyStateEnum;
  public ViewTypeEnum = ViewTypeEnum;
  public SwitchButtonType = SwitchButtonType;

  viewType = ViewTypeEnum.LIST;
  loading = true;
  accountMarketplace: AccountMarketplace;
  bidderOn = true;
  strategies: StrategyEx[];
  strategyGroups: StrategyGroupEx[];
  strategiesAndGroups: (StrategyEx | StrategyGroupEx)[] = [];
  userSearch: string;
  isReadOnly = false;
  pageSize = 10;
  pageIndex = 0;
  strategyGroupMigrationUrl = "/strategies/strategy-group-migration";

  readonly faSearch = faSearch;
  readonly faPlus = faPlusCircle;
  readonly faTable = faThLarge;
  readonly faListAlt = faList;
  readonly CampaignType = CampaignType;
  readonly StrategyEx = StrategyEx;
  readonly ICON_IMPORT = ICON_IMPORT;
  readonly ICON_WRENCH = ICON_WRENCH;
  readonly ICON_ADD = ICON_ADD;
  asinsByStrategy: Map<number, string[]> = new Map(); // map strategy id -> list of asins

  readonly strategyStatusOptions: Option<StrategyStatusFilter>[] = [
    {
      label: "All Strategies",
      value: StrategyStatusFilter.ALL,
    },
    {
      label: "Active Strategies",
      value: StrategyStatusFilter.ON,
    },
    {
      label: "Paused Strategies",
      value: StrategyStatusFilter.OFF,
    },
  ];
  strategyFilter: Option<StrategyStatusFilter> = this.strategyStatusOptions.find(
    (o) => o.value === StrategyStatusFilter.ALL,
  )!;

  constructor(
    private strategyCache: StrategyCache,
    private configService: ConfigService,
    private accountSelection: AccountSelectionService,
    private toasterService: ToastrService,
    private modalService: BsModalService,
    private router: Router,
    private userService: UserSelectionService,
    private bulkImportService: BulkImportService,
    private authService: AuthService,
  ) {
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      if ((user?.uiVersion ?? 0) > 0) {
        this.strategyGroupMigrationUrl = "/advertising/sponsored-product/strategy-group-migration";
      }
    });
  }

  ngOnInit(): void {
    this.loading = true;
    combineLatest<[Map<string, StrategyEx[]>, Map<number, StrategyEx>, Map<number, StrategyGroupEx>]>([
      this.accountSelection.singleAccountMarketplaceSelection$.pipe(
        tap((am: AccountMarketplace) => {
          this.accountMarketplace = am;
          this.bidderOn = this.accountMarketplace.state == AccountState.BIDDER_ON;
        }),
        switchMap((am: AccountMarketplace) => this.configService.asinStrategyIndex$),
      ),
      this.strategyCache.strategyIndex$,
      this.strategyCache.strategyGroupIndex$,
    ])
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.loading = true;
        }),
        delay(10),
      )
      .subscribe(([asinStrategyIndex, strategies, strategyGroups]) => {
        // only display legacy strategy type
        this.strategies = [
          ...this.sortStrategies(
            Array.from(strategies.values()).filter(
              (x) =>
                x.strategyType == StrategyType.LEGACY && // only display legacy strategy type
                x.campaignType == this.campaignType,
            ),
          ),
        ];
        this.asinsByStrategy.clear();
        for (const [asin, asinStrategies] of asinStrategyIndex.entries()) {
          for (const strategy of asinStrategies) {
            if (strategy.campaignType !== this.campaignType) {
              continue;
            }
            if (!this.asinsByStrategy.has(strategy.strategyId)) {
              this.asinsByStrategy.set(strategy.strategyId, [asin]);
            } else {
              this.asinsByStrategy.get(strategy.strategyId).push(asin);
            }
          }
        }
        this.loading = false;
        this.strategyGroups = this.campaignType == CampaignType.SP ? Array.from(strategyGroups.values()) : [];

        this.search();
      });

    const typePreference = this.userService.getUserViewTypePreference();
    if (typePreference) {
      this.viewType = ViewTypeEnum[typePreference];
    } else {
      this.userService.setUserViewTypePreference(this.viewType.toString());
    }

    this.accountSelection.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((b) => (this.isReadOnly = b));
  }

  displayBy(viewType: ViewTypeEnum): void {
    this.viewType = viewType;
    this.userService.setUserViewTypePreference(this.viewType.toString());
  }

  upgradePlan(event: MouseEvent): void {
    event.preventDefault();
    this.router.navigate(["billing-registration"]);
  }

  createStrategy(): void {
    this.strategyCreationClick.emit(this.accountMarketplace);
  }

  private compareStrategies(a: StrategyEx, b: StrategyEx) {
    if (a.state == b.state) {
      if (a.getName() == "All other products") return 1;
      else if (b.getName() == "All other products") return -1;
      return Utils.strCompare(a.getName(), b.getName());
    }
    return a.compareState(b);
  }

  private sortStrategies(strategies: StrategyEx[]) {
    return strategies.sort((a, b) => this.compareStrategies(a, b));
  }

  search(): void {
    if (this.strategies == undefined) return;
    this.strategiesAndGroups = [];
    const rexp = new RegExp(this.userSearch, "i");
    for (const strat of this.strategies) {
      if (
        (this.strategyFilter.value === StrategyStatusFilter.ON && strat.state === StrategyStateEnum.PAUSED) ||
        (this.strategyFilter.value === StrategyStatusFilter.OFF && strat.state === StrategyStateEnum.ENABLED)
      ) {
        continue;
      } else if (strat.getName().search(rexp) != -1) {
        this.strategiesAndGroups.push(strat);
      } else {
        if ((this.asinsByStrategy.get(strat.strategyId) ?? []).some((x) => x.search(rexp) != -1)) {
          this.strategiesAndGroups.push(strat);
        }
      }
    }
    for (const strategyGroup of this.strategyGroups) {
      if (this.strategyFilter.value === StrategyStatusFilter.ON && !this.hasLiveStrategies(strategyGroup)) {
        continue;
      } else if (this.strategyFilter.value === StrategyStatusFilter.OFF && this.hasLiveStrategies(strategyGroup)) {
        continue;
      } else {
        if (strategyGroup.strategyGroupName.search(rexp) != -1) {
          this.strategiesAndGroups.push(strategyGroup);
        } else if (strategyGroup.asins.some((x) => x.search(rexp) != -1)) {
          this.strategiesAndGroups.push(strategyGroup);
        }
      }
    }
    this.strategiesAndGroups.sort((a, b) => {
      const aState = this.isStrategy(a)
        ? a.state
        : this.hasLiveStrategies(a as StrategyGroupEx)
          ? StrategyStateEnum.ENABLED
          : StrategyStateEnum.PAUSED;
      const bState = this.isStrategy(b)
        ? b.state
        : this.hasLiveStrategies(b as StrategyGroupEx)
          ? StrategyStateEnum.ENABLED
          : StrategyStateEnum.PAUSED;
      return aState.localeCompare(bState);
    });
    this.pageIndex = 0; // reset page index
  }

  hasLiveStrategies(strategyGroup: StrategyGroupEx): boolean {
    return strategyGroup.strategies.some((s) => s.state === StrategyStateEnum.ENABLED);
  }

  uploadStrategyData(bulkData = ""): void {
    const modalOptions: ModalOptions = {
      initialState: {
        accountId: this.accountMarketplace.accountId,
        marketplace: this.accountMarketplace.marketplace,
        campaignType: this.campaignType,
        strategies: this.strategies
          .concat(this.strategyGroups.flatMap((sg) => sg.productStrategies))
          .concat(this.strategyGroups.flatMap((sg) => sg.brandStrategies))
          .concat(this.strategyGroups.flatMap((sg) => sg.keywordStrategies)),
        bulkData: bulkData,
      },
      class: "modal-xl",
    };
    const modalRef = this.modalService.show(StrategyBulkUploadModalComponent, modalOptions);

    const subscription = modalRef.content.strategyBulkOperations.subscribe((result) => {
      this.displayBulkOperations(result);
      subscription.unsubscribe();
    });
  }

  private displayBulkOperations(bulkOperations: StrategyBulkOperations) {
    const modalOptions: ModalOptions = {
      initialState: {
        strategyBulkOperations: bulkOperations,
        accountId: this.accountMarketplace.accountId,
        marketplace: this.accountMarketplace.marketplace,
        campaignType: this.campaignType,
      },
      class: "modal-xxl",
    };
    const modalRef = this.modalService.show<StrategyBulkUploadReportModalComponent>(
      StrategyBulkUploadReportModalComponent,
      modalOptions,
    );
    const subscriptions = modalRef.content.bulkOperationResult.subscribe((result) => {
      this.displayUploadResult(result);
      subscriptions.unsubscribe();
    });
    subscriptions.add(
      modalRef.content.uploadCancelled.subscribe(() => {
        this.uploadStrategyData(bulkOperations.bulkData);
        subscriptions.unsubscribe();
      }),
    );
  }

  private displayUploadResult(uploadResult: StrategyUploadResult) {
    const modalOptions: ModalOptions = {
      initialState: {
        uploadResult: uploadResult,
      },
      class: "modal-primary",
    };
    this.modalService.show<StrategyBulkUploadResultModalComponent>(
      StrategyBulkUploadResultModalComponent,
      modalOptions,
    );
  }

  exportStrategyData(): void {
    const strategies = this.strategies
      .concat(this.strategyGroups.flatMap((sg) => sg.productStrategies))
      .concat(this.strategyGroups.flatMap((sg) => sg.brandStrategies))
      .concat(this.strategyGroups.flatMap((sg) => sg.keywordStrategies));
    if (strategies.length == 0) {
      this.toasterService.info("No data to export");
      return;
    }
    this.bulkImportService.exportStrategyCsv(this.accountMarketplace, this.campaignType, strategies);
  }

  getStrategyStatusFilterText(status: StrategyStatusFilter): string {
    switch (status) {
      case StrategyStatusFilter.ALL:
        return "All strategies";

      case StrategyStatusFilter.ON:
        return "Active strategies";

      case StrategyStatusFilter.OFF:
        return "Paused strategies";
    }
  }

  liveStrategies() {
    return this.strategies?.filter((x) => x.state === StrategyStateEnum.ENABLED) ?? [];
  }

  isStrategy(strategyOrGroup: StrategyEx | StrategyGroupEx): strategyOrGroup is StrategyEx {
    return strategyOrGroup instanceof StrategyEx;
  }

  getSBStrategyLimitWarning(): "NONE" | "REACHED" | "SOON" {
    if (this.campaignType != CampaignType.SB) {
      return "NONE";
    }
    const liveSBStrategies = this.configService.getNumberOfLiveStrategies(CampaignType.SB);
    if (liveSBStrategies >= Constant.maxSbStrategies) {
      return "REACHED";
    }
    if (liveSBStrategies >= Constant.maxSbStrategies * 0.9) {
      return "SOON";
    }
    return "NONE";
  }

  changePage(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
  }
}
