import { Component, inject, signal } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { UserApi } from "@front/m19-services";
import { LayoutSelectorService } from "@m19-board/layout-selector.service";
import { environment } from "../../../environments/environment";
import { RequestPasswordResetFormValue } from "@front/m19-ui";
import { AjaxResponse } from "rxjs/ajax";

@UntilDestroy()
@Component({
  selector: "app-request-password-reset",
  templateUrl: "request-password-reset.component.html",
})
export class RequestPasswordResetComponent {
  private readonly userApi = inject(UserApi);
  private readonly layoutSelector = inject(LayoutSelectorService);

  isTest = !environment.production;

  loading = signal(false);
  success = signal(false);
  error = signal<string | null>(null);

  requestPasswordReset(formValue: RequestPasswordResetFormValue): void {
    if (!formValue) return;
    this.loading.set(true);
    this.userApi
      .forgotPassword({ email: formValue.email, domain: window.location.hostname, color: "#4b5563" })
      .subscribe({
        next: () => {
          this.loading.set(false);
          this.success.set(true);
          this.error.set(null);
        },
        error: (err: AjaxResponse<any>) => {
          this.error.set(err.response.message);
          this.loading.set(false);
          this.success.set(false);
        },
      });
  }
}
