import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AccountMarketplace, AccountSelectionService, AuthService, Marketplace, StrategyEx } from "@front/m19-services";
import { ICON_CHEVRON_DOWN } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  template: `<div class="main-container mx-auto mb-3 flex items-center justify-between px-2">
      <div class="flex items-center">
        <a
          class="btn btn-ghost-secondary inline-flex items-center"
          type="button"
          [routerLink]="sdStrategyPageUrl"
          queryParamsHandling="merge"
          matTooltip="Back to Sponsored Display strategy list"
          matTooltipPosition="above"
        >
          Sponsored Display
        </a>
        <span [class]="ICON_CHEVRON_DOWN + ' -rotate-90'"></span>
        <a
          class="btn btn-ghost-secondary inline-flex items-center"
          type="button"
          routerLink="."
          queryParamsHandling="merge"
        >
          New Strategy
        </a>
      </div>
      <div>
        Marketplace:&nbsp;<strong>{{ marketplace }}</strong>
      </div>
    </div>
    <app-sd-strategy-creation
      [sdStrategyPageUrl]="sdStrategyPageUrl"
      (strategyCreated)="onStrategyCreated($event)"
    ></app-sd-strategy-creation>`,
  styleUrls: ["../../../strategies/strategies/strategy-styles.scss"],
})
export class SdStrategyCreationPageComponent implements OnInit {
  marketplace: Marketplace;
  readonly ICON_CHEVRON_DOWN = ICON_CHEVRON_DOWN;
  sdStrategyPageUrl = "/strategies/sponsored-display";

  constructor(
    private accountSelection: AccountSelectionService,
    private router: Router,
    private authService: AuthService,
  ) {
    this.authService.loggedUser$.subscribe((user) => {
      if ((user?.uiVersion ?? 0) > 0) {
        this.sdStrategyPageUrl = "/advertising/sponsored-display";
      }
    });
  }

  ngOnInit(): void {
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(untilDestroyed(this))
      .subscribe((am: AccountMarketplace) => {
        this.marketplace = am.marketplace;
      });
  }

  onStrategyCreated(strategy: StrategyEx) {
    this.router.navigate([this.sdStrategyPageUrl + "/" + strategy.strategyId], { queryParamsHandling: "merge" });
  }
}
