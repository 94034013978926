import { combineLatest, forkJoin, Observable, of, throwError } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { catchError, filter, map, mergeMap, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AccountSelectionService, CreateStrategyRequest, OrganizationAccountGroupService } from '.';
import {
  AccountMarketplace,
  AlgoMode,
  AudienceExpressionType,
  AudienceMatchType,
  AudienceTargeting,
  CampaignType,
  CreateStrategyGroupRequest,
  Intensity,
  Marketplace,
  MatchType,
  Response,
  SbCreativeType,
  Strategy,
  StrategyApi,
  StrategyAsin,
  StrategyGroup,
  StrategyGroupApi,
  StrategyStateEnum,
  StrategyToMigate,
  StrategyType,
  TacticType,
  Targeting,
  UpdateStrategyGroupBlacklistActionEnum,
  UpdateStrategyKwTargetingActionEnum,
  UpdateStrategyRequest,
  UpdateStrategyTopOfSearchRankingsActionEnum,
} from './api-client';
import { AsinService } from './asin.service';
import {
  AlgoModeStr,
  Catalog,
  ProductGroupEx,
  SegmentConfigType,
  StrategyEx,
  StrategyGroupBlacklistUpdateParams,
  StrategyGroupEx,
  StrategyGroupUpdateParams,
  StrategyTacticEx,
  StrategyTypeStr,
  StrategyUpdateParams,
} from './models';
import { currencyRateToEuro, marketplaceCurrency } from './stats-api-client.service';
import { catchAjaxError, Utils } from './utils';
import { StrategyCache } from './strategy.cache';
import { Constant } from './constant';
import { SegmentService } from './segmentService';
import { inject, Injectable } from '@angular/core';
import { ALERT_SERVICE } from './injection-token/injection-token';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private readonly alertService = inject(ALERT_SERVICE);
  private selectedAccountMarketplace: AccountMarketplace | undefined = undefined;

  public readonly asinStrategyIndex$: Observable<Map<string, StrategyEx[]>>;
  private numberOfAsinsPerStrategy?: Map<number, number>;

  private liveStrategiesLimit = 0;

  constructor(
    private strategyCache: StrategyCache,
    private strategyService: StrategyApi,
    private asinService: AsinService,
    accountSelectionService: AccountSelectionService,
    private segmentService: SegmentService,
    private organizationService: OrganizationAccountGroupService,
    private strategyGroupService: StrategyGroupApi,
  ) {
    accountSelectionService.singleAccountMarketplaceSelection$.subscribe((x) => {
      this.selectedAccountMarketplace = x;
    });
    combineLatest([
      accountSelectionService.singleAccountMarketplaceSelection$,
      this.organizationService.allOrganizationAccountGroups$,
    ]).subscribe(([selectedAccountMarketplace, allOrgs]) => {
      const selectedOrgs = allOrgs
        ? allOrgs.find((x) => x.id == selectedAccountMarketplace.resourceOrganizationId)
        : undefined;
      if (!selectedOrgs?.getBillingPlan()?.plan) {
        this.liveStrategiesLimit = 0;
        return;
      }
      this.liveStrategiesLimit = selectedOrgs.getBillingPlan()?.strategyLimit ?? +Infinity;
    });
    this.asinStrategyIndex$ = this.strategyCache.strategyIndex$.pipe(
      filter((strategies) => strategies.size > 0),
      switchMap((strategies) => {
        const strat = Array.from(strategies.values())[0];
        return this.asinService
          .getCatalog(strat.accountId, strat.marketplace)
          .pipe(map((catalog) => ({ strategies: strategies, catalog: catalog })));
      }),
      map((a) => {
        return Array.from(a.strategies.values()).map((s) => ({
          strategy: s,
          asins: this.retrieveStrategyAsins(s, a.catalog),
        }));
      }),
      map((stratAsins) => {
        const result = new Map<string, StrategyEx[]>();
        for (const { strategy, asins } of stratAsins) {
          for (const asin of asins) {
            if (!result.has(asin.asin!)) {
              result.set(asin.asin!, [strategy]);
            } else {
              result.get(asin.asin!)!.push(strategy);
            }
          }
        }
        return result;
      }),
      shareReplay(1),
    );
    this.asinStrategyIndex$.subscribe((asinStrategyIndex) => {
      const asinCount = new Map<number, number>();
      asinStrategyIndex.forEach((strategies) => {
        strategies.forEach((s) => {
          if (!asinCount.has(s.strategyId)) {
            asinCount.set(s.strategyId, 1);
          } else {
            asinCount.set(s.strategyId, asinCount.get(s.strategyId)! + 1);
          }
        });
      });
      this.numberOfAsinsPerStrategy = asinCount;
    });
  }

  public getNumberOfLiveStrategies(campaignType: CampaignType) {
    return this.strategyCache
      .getAllStrategies()
      .filter((s) => s.campaignType == campaignType && s.state == StrategyStateEnum.ENABLED).length;
  }

  public getLiveStrategyLimit() {
    return this.liveStrategiesLimit;
  }

  private isSBStrategyLimitReached(): boolean {
    const liveSBStrategies = this.getNumberOfLiveStrategies(CampaignType.SB);
    if (liveSBStrategies >= Constant.maxSbStrategies) {
      return true;
    } else {
      switch (this.liveStrategiesLimit) {
        case 0:
          return true;
        case +Infinity:
          return false;
        default:
          return liveSBStrategies >= this.liveStrategiesLimit;
      }
    }
  }

  public isStrategyLimitReached(campaignType: CampaignType): boolean {
    if (campaignType == CampaignType.SB) {
      return this.isSBStrategyLimitReached();
    }
    switch (this.liveStrategiesLimit) {
      case 0:
        return true;
      case +Infinity:
        return false;
      default:
        return this.getNumberOfLiveStrategies(campaignType) >= this.liveStrategiesLimit;
    }
  }

  public getStrategyGroupById(strategyGroupId: number): Observable<StrategyGroupEx | undefined> {
    return this.strategyCache.strategyGroupIndex$.pipe(map((index) => index.get(strategyGroupId)));
  }

  private retrieveStrategyAsinsAllOtherProducts(strategy: StrategyEx, catalog: Catalog): Set<string> {
    // All other products case
    if (!catalog) return new Set();
    const asinsWithoutStrats: Set<string> = new Set(catalog.childAsins);
    for (const strat of this.strategyCache.getAllStrategies()) {
      if (
        strat.accountId == strategy.accountId &&
        strat.marketplace == strategy.marketplace &&
        strat.strategyId != strategy.strategyId &&
        strat.campaignType == CampaignType.SP
      ) {
        strat.asins.forEach((asin) => asinsWithoutStrats.delete(asin.asin!));
      }
    }
    return asinsWithoutStrats;
  }

  private retrieveStrategyAsins(strategy: StrategyEx, catalog: Catalog): StrategyAsin[] {
    if (!strategy.isAllOtherProduct() && strategy.campaignType != CampaignType.SB) return strategy.asins;
    else if (strategy.campaignType == CampaignType.SP) {
      // All other products case
      return Array.from(this.retrieveStrategyAsinsAllOtherProducts(strategy, catalog).values()).map((a) => ({
        asin: a,
      }));
    } else if (strategy.campaignType == CampaignType.SB && strategy.sbCreatives) {
      const r: Set<string> = new Set();
      strategy.sbCreatives.forEach((creative) => {
        if (creative.creativeAsins)
          creative.creativeAsins.forEach((cluster) => {
            r.add(cluster.asin1);
            if (cluster.asin2) r.add(cluster.asin2);
            if (cluster.asin3) r.add(cluster.asin3);
          });
      });
      return Array.from(r).map((a) => ({
        asin: a,
      }));
    } else return [];
  }

  public getStrategyAsinsAllOtherProducts(strategy: StrategyEx): Observable<Set<string>> {
    return this.asinService
      .getCatalog(strategy.accountId, strategy.marketplace)
      .pipe(map((catalog) => this.retrieveStrategyAsinsAllOtherProducts(strategy, catalog)));
  }

  public getStrategyAsins(strategy: StrategyEx): Observable<StrategyAsin[]> {
    return this.asinService
      .getCatalog(strategy.accountId, strategy.marketplace)
      .pipe(map((catalog) => this.retrieveStrategyAsins(strategy, catalog)));
  }

  private updateStrategyAsins(strategy: Strategy, newAsins: string[]) {
    const strategyEx = this.strategyCache.get(strategy.strategyId!)!;
    // create a copy of the StrategyEx object
    const modifiedStrat = Object.assign(
      new StrategyEx(
        strategyEx,
        this.strategyCache.getStrategySegmentAsMap(strategy),
        this.strategyCache.getCreativesMap(strategy.strategyId!),
        this.strategyCache.strategyCurrentMonthSpendIndex,
      ),
      strategyEx,
    );
    modifiedStrat.asins = newAsins.flatMap((a) => ({ asin: a }));
    this.strategyCache.updateStrategy(modifiedStrat);
    if (
      modifiedStrat.strategyGroupId! > 0 &&
      this.strategyCache.hasStrategyGroupIndex(modifiedStrat.strategyGroupId!)
    ) {
      const strategyGroup = this.strategyCache.getStrategyGroupIndex(modifiedStrat.strategyGroupId!)!;
      switch (modifiedStrat.strategyType) {
        case StrategyType.BRAND:
          strategyGroup.brandStrategies = strategyGroup.brandStrategies.map((s) =>
            s.strategyId == modifiedStrat.strategyId ? modifiedStrat : s,
          );
          break;
        case StrategyType.KEYWORD:
          strategyGroup.keywordStrategies = strategyGroup.keywordStrategies.map((s) =>
            s.strategyId == modifiedStrat.strategyId ? modifiedStrat : s,
          );
          break;
        case StrategyType.PRODUCT:
          strategyGroup.productStrategies = strategyGroup.productStrategies.map((s) =>
            s.strategyId == modifiedStrat.strategyId ? modifiedStrat : s,
          );
          break;
      }
      const asins = new Set(strategyGroup.productStrategies.flatMap((s) => s.asins.map((a) => a.asin!)));

      strategyGroup!.asins = Array.from(asins.values());
      this.strategyCache.strategyGroupIndexSubjectNext();
    }
  }

  public deleteAsinsFromStrategy(strategy: Strategy, asins: string[]) {
    // asins must be sorted before pushing them on Db
    const allAsins = strategy
      .asins!.flatMap((x) => x.asin!)
      .filter((a) => !asins.includes(a))
      .sort();

    return this.strategyService
      .updateStrategyAsins({
        accountId: strategy.accountId,
        marketplace: strategy.marketplace,
        strategyId: strategy.strategyId!,
        requestBody: allAsins,
      })
      .pipe(
        catchAjaxError('Error deleting ASINs from Strategy: '),
        tap(() => this.updateStrategyAsins(strategy, allAsins)),
      );
  }

  public addAsinsToStrategy(strategy: Strategy, asins: string[]): Observable<Response> {
    if (strategy.asins!.length + asins.length >= ProductGroupEx.MaxProductGroupItems) {
      return throwError(() => `Exceeding the limit of ${ProductGroupEx.MaxProductGroupItems} ASINs per strategy`);
    }
    const existing = strategy.asins!.find((a) => asins.includes(a.asin!));
    if (existing) {
      return throwError(() => `Unable to add ASIN ${existing.asin} as this ASIN is already in the strategy`);
    }
    // asins must be sorted before pushing them on Db
    const allAsins = strategy
      .asins!.flatMap((x) => x.asin!)
      .concat(asins)
      .sort();

    return this.strategyService
      .updateStrategyAsins({
        accountId: strategy.accountId,
        marketplace: strategy.marketplace,
        strategyId: strategy.strategyId!,
        requestBody: allAsins,
      })
      .pipe(
        catchAjaxError('Error adding ASINs to Strategy: '),
        tap(() => this.updateStrategyAsins(strategy, allAsins)),
      );
  }

  public addTargetingToStrategy(strategy: Strategy, targetings: Targeting[]): Observable<Response> {
    if (strategy.strategyType != StrategyType.BRAND && strategy.strategyType != StrategyType.KEYWORD) {
      return throwError(() => `Cannot add targetings to ${StrategyTypeStr[strategy.strategyType!]} strategies`);
    }
    if (
      strategy.targetings!.filter((t) => t.matchType === MatchType.asinSameAs).length +
        targetings.filter((t) => t.matchType === MatchType.asinSameAs).length >
      Constant.maxAsinTargetingByStrategy
    ) {
      return throwError(
        () => `Exceeding the limit of ${Constant.maxAsinTargetingByStrategy} product targetings per strategy`,
      );
    }
    if (
      strategy.targetings!.filter((t) => t.matchType === MatchType.exact || t.matchType === MatchType.phrase).length +
        targetings.filter((t) => t.matchType === MatchType.exact || t.matchType === MatchType.phrase).length >
      Constant.maxKwTargetingByStrategy
    ) {
      return throwError(
        () => `Exceeding the limit of ${Constant.maxKwTargetingByStrategy} keyword targetings per strategy`,
      );
    }
    const existing = strategy.targetings!.find(
      (t1) => targetings.findIndex((t2) => t1.matchType == t2.matchType && t1.targetingValue == t2.targetingValue) > -1,
    );
    if (existing) {
      return throwError(
        () =>
          `Unable to add targeting ${existing.targetingValue} [${existing.matchType}] as this targeting is already part of the strategy`,
      );
    }
    return this.strategyService
      .updateStrategyKwTargeting({
        accountId: strategy.accountId,
        marketplace: strategy.marketplace,
        strategyId: strategy.strategyId!,
        action: UpdateStrategyKwTargetingActionEnum.ADD,
        targeting: targetings,
      })
      .pipe(
        catchAjaxError('Error adding targeting to strategy: '),
        switchMap((resp) => {
          // if there are some exact kw and if TOSRO is activated on the strategy
          const exactKw = targetings.filter((t) => t.matchType == MatchType.exact).map((t) => t.targetingValue);
          if (exactKw.length > 0 && strategy.topOfSearchRankings && strategy.topOfSearchRankings.length > 0) {
            return this.updateTopOfSearchRankings(
              strategy,
              this.selectedAccountMarketplace!.resourceOrganizationId!,
              exactKw,
              UpdateStrategyTopOfSearchRankingsActionEnum.ADD,
            );
          }
          return of(resp);
        }),
        tap(() => {
          const strategyEx = this.strategyCache.get(strategy.strategyId!)!;
          // create a copy of the StrategyEx object
          const modifiedStrat = Object.assign(
            new StrategyEx(
              strategyEx,
              this.strategyCache.getStrategySegmentAsMap(strategy),
              this.strategyCache.getCreativesMap(strategy.strategyId!),
              this.strategyCache.strategyCurrentMonthSpendIndex,
            ),
            strategyEx,
          );
          const newTargetings = [...modifiedStrat.targetings];
          for (const targeting of targetings) {
            if (
              newTargetings.findIndex(
                (t1) => t1.matchType == targeting.matchType && t1.targetingValue == targeting.targetingValue,
              ) < 0
            ) {
              newTargetings.push(targeting);
            }
          }
          modifiedStrat.targetings = newTargetings;
          this.strategyCache.updateStrategy(modifiedStrat);
          if (
            modifiedStrat.strategyGroupId! > 0 &&
            this.strategyCache.hasStrategyGroupIndex(modifiedStrat.strategyGroupId!)
          ) {
            const strategyGroup = this.strategyCache.getStrategyGroupIndex(modifiedStrat.strategyGroupId!)!;
            switch (modifiedStrat.strategyType) {
              case StrategyType.BRAND:
                strategyGroup.brandStrategies = strategyGroup.brandStrategies.map((s) =>
                  s.strategyId == modifiedStrat.strategyId ? modifiedStrat : s,
                );
                break;
              case StrategyType.KEYWORD:
                strategyGroup.keywordStrategies = strategyGroup.keywordStrategies.map((s) =>
                  s.strategyId == modifiedStrat.strategyId ? modifiedStrat : s,
                );
                break;
            }
            this.strategyCache.strategyGroupIndexSubjectNext();
          }
        }),
      );
  }

  public removeTargetingFromStrategy(strategy: Strategy, targetings: Targeting[]): Observable<Response> {
    if (strategy.strategyType != StrategyType.BRAND && strategy.strategyType != StrategyType.KEYWORD) {
      return throwError(() => `Cannot remove targetings from ${StrategyTypeStr[strategy.strategyType!]} strategies`);
    }
    return this.strategyService
      .updateStrategyKwTargeting({
        accountId: strategy.accountId,
        marketplace: strategy.marketplace,
        strategyId: strategy.strategyId!,
        action: UpdateStrategyKwTargetingActionEnum.DELETE,
        targeting: targetings,
      })
      .pipe(
        catchAjaxError(),
        switchMap((resp) => {
          // if there are some exact kw and if TOSRO is activated on the strategy
          const exactKw = targetings.filter((t) => t.matchType == MatchType.exact).map((t) => t.targetingValue);
          if (exactKw.length > 0 && strategy.topOfSearchRankings && strategy.topOfSearchRankings.length! > 0) {
            return this.updateTopOfSearchRankings(
              strategy,
              this.selectedAccountMarketplace!.resourceOrganizationId!,
              exactKw,
              UpdateStrategyTopOfSearchRankingsActionEnum.DELETE,
            );
          }
          return of(resp);
        }),
        tap(() => {
          const strategyEx = this.strategyCache.get(strategy.strategyId!)!;
          // create a copy of the StrategyEx object
          const modifiedStrat = Object.assign(
            new StrategyEx(
              strategyEx,
              this.strategyCache.getStrategySegmentAsMap(strategy),
              this.strategyCache.getCreativesMap(strategy.strategyId!),
              this.strategyCache.strategyCurrentMonthSpendIndex,
            ),
            strategyEx,
          );
          const newTargetings = [...modifiedStrat.targetings].filter(
            (t1) =>
              targetings.findIndex((t2) => t1.matchType == t2.matchType && t1.targetingValue == t2.targetingValue) < 0,
          );
          modifiedStrat.targetings = newTargetings;
          this.strategyCache.updateStrategy(modifiedStrat);
          if (
            modifiedStrat.strategyGroupId! > 0 &&
            this.strategyCache.hasStrategyGroupIndex(modifiedStrat.strategyGroupId!)
          ) {
            const strategyGroup = this.strategyCache.getStrategyGroupIndex(modifiedStrat.strategyGroupId!)!;
            switch (modifiedStrat.strategyType) {
              case StrategyType.BRAND:
                strategyGroup.brandStrategies = strategyGroup.brandStrategies.map((s) =>
                  s.strategyId == modifiedStrat.strategyId ? modifiedStrat : s,
                );
                break;
              case StrategyType.KEYWORD:
                strategyGroup.keywordStrategies = strategyGroup.keywordStrategies.map((s) =>
                  s.strategyId == modifiedStrat.strategyId ? modifiedStrat : s,
                );
                break;
            }
            this.strategyCache.strategyGroupIndexSubjectNext();
          }
        }),
      );
  }

  public moveAsinsToStrategy(asins: string[], source: Strategy, target: Strategy): Observable<Response> {
    return this.addAsinsToStrategy(target, asins).pipe(switchMap(() => this.deleteAsinsFromStrategy(source, asins)));
  }

  private createStrategyInDb(strategy: Strategy): Observable<StrategyEx> {
    return this.strategyService
      .createStrategy({
        accountId: strategy.accountId,
        marketplace: strategy.marketplace,
        strategyGroupId: strategy.strategyGroupId ?? null,
        strategyType: strategy.strategyType ?? StrategyType.LEGACY,
        campaignType: strategy.campaignType,
        algoMode: strategy.algoMode,
        priority: strategy.priority ?? 0,
        name: strategy.name,
        state: strategy.state,
        acosTarget: strategy.acosTarget,
        suggestedBid: strategy.suggestedBid,
        dailyBudget: strategy.dailyBudget,
        monthlyBudget: strategy.monthlyBudget,
        minDailySpend: strategy.minDailySpend,
        disableOtherQueries: strategy.disableOtherQueries,
        disableAutoSegment: strategy.disableAutoSegment,
        disableProductSegment: strategy.disableProductSegment,
        organizationId: this.selectedAccountMarketplace!.resourceOrganizationId,
        brandEntityId: strategy.brandEntityId,
      } as CreateStrategyRequest)
      .pipe(
        map((response: Response) => {
          const strat = response.entity as Strategy;
          const newStrategy = new StrategyEx(
            strat,
            this.strategyCache.getStrategySegmentAsMap(strategy),
            this.strategyCache.getCreativesMap(strategy.strategyId!),
            this.strategyCache.strategyCurrentMonthSpendIndex,
          );
          this.strategyCache.updateStrategy(newStrategy);
          if (newStrategy.strategyGroupId! > 0 && this.strategyCache.hasStrategyGroupIndex(strategy.strategyGroupId!)) {
            const strategyGroup = this.strategyCache.getStrategyGroupIndex(strategy.strategyGroupId!)!;
            strategyGroup.strategies = [...strategyGroup.strategies!, newStrategy];
            if (newStrategy.strategyType == StrategyType.PRODUCT) {
              strategyGroup.productStrategies = [...strategyGroup.productStrategies, newStrategy];
            } else if (newStrategy.strategyType == StrategyType.BRAND) {
              strategyGroup.brandStrategies = [...strategyGroup.brandStrategies, newStrategy];
            } else if (newStrategy.strategyType == StrategyType.KEYWORD) {
              strategyGroup.keywordStrategies = [...strategyGroup.keywordStrategies, newStrategy];
            }
            this.strategyCache.strategyGroupIndexSubjectNext();
          }
          return newStrategy;
        }),
        catchError((createStrategy: AjaxError) => {
          return throwError(
            () =>
              'Error creating Strategy: ' +
              (createStrategy.response ? createStrategy.response.message : createStrategy.message),
          );
        }),
      );
  }

  private checkStrategyParams(strategy: Strategy): string[] {
    const errors: string[] = [];
    // check strategy ASINs
    if (
      (strategy.asins == undefined || strategy.asins.length == 0) &&
      !strategy.defaultStrategy &&
      (strategy.campaignType === CampaignType.SP || strategy.campaignType === CampaignType.SD)
    ) {
      errors.push('Strategy should have a list of ASINs');
    }
    // check strategy state
    if (!strategy.state) {
      errors.push('Invalid strategy status');
    }
    // check strategy name
    try {
      this.checkStrategyName(strategy.name!, strategy.campaignType);
    } catch (error) {
      errors.push(error as string);
    }
    // check strategy label
    if (strategy.strategyLabel) {
      if (!Constant.nameRegexp.test(strategy.strategyLabel)) {
        errors.push('Invalid character used, Strategy Label can only use characters allowed in Amazon campaign names');
      }
      if (
        (strategy.campaignType == CampaignType.SB || strategy.campaignType == CampaignType.SD) &&
        Constant.invalidSBSDNameRegexp.test(strategy.strategyLabel)
      ) {
        errors.push(
          "Invalid character used, Strategy Label can only use characters allowed in Amazon campaign names. Characters: '!%#<>.' are not allowed for SD and SB.",
        );
      }
    }
    if (!strategy.algoMode || !AlgoMode[strategy.algoMode]) {
      errors.push('Invalid algorithm');
    }
    // check ACOS target
    if (strategy.algoMode === AlgoMode.ACOS_TARGET && strategy.acosTarget == undefined) {
      errors.push('ACOS target must be specified for ACOS target algorithm');
    }
    if (
      (strategy.algoMode === AlgoMode.PRODUCT_LAUNCH && strategy.acosTarget !== undefined) ||
      (strategy.algoMode === AlgoMode.MONTHLY_BUDGET_TARGET && strategy.acosTarget !== undefined)
    ) {
      errors.push('Impossible to set ACOS target for ' + StrategyEx.getAlgoModeStrShort(strategy.algoMode));
    }
    if (strategy.algoMode === AlgoMode.ACOS_TARGET && (strategy.acosTarget! < 0.01 || strategy.acosTarget! > 2)) {
      errors.push('Invalid ACOS target, must be between 1% and 200%');
    }
    // check Suggested Bid
    if (
      strategy.algoMode === AlgoMode.PRODUCT_LAUNCH &&
      (strategy.suggestedBid == undefined || strategy.dailyBudget == undefined)
    ) {
      errors.push('A daily budget and a suggested bid should be defined for Product Launch');
    }
    if (strategy.algoMode !== AlgoMode.PRODUCT_LAUNCH && strategy.suggestedBid != undefined) {
      errors.push('Not possible to set a suggested bid for ' + StrategyEx.getAlgoModeStrShort(strategy.algoMode));
    }
    if (
      strategy.algoMode === AlgoMode.PRODUCT_LAUNCH &&
      (strategy.suggestedBid! <= 0 ||
        strategy.dailyBudget! < strategy.suggestedBid! ||
        5 * strategy.suggestedBid! > strategy.dailyBudget! ||
        strategy.dailyBudget! > 1_000_000_000)
    ) {
      errors.push(
        'Invalid suggested bid or daily budget, must satisfy 0 < suggested bid <= daily budget, also suggested bid should not exceed 20% of the Daily Budget',
      );
    }
    // check Monthly budget
    if (strategy.monthlyBudget && strategy.algoMode !== AlgoMode.MONTHLY_BUDGET_TARGET) {
      errors.push('Monthtly budget cannot be defined for ' + AlgoModeStr[strategy.algoMode]);
    }
    if (
      strategy.algoMode === AlgoMode.MONTHLY_BUDGET_TARGET &&
      (strategy.monthlyBudget == undefined || strategy.monthlyBudget <= 0)
    ) {
      errors.push('Monthly budget must be greater than 0');
    }
    if (strategy.algoMode === AlgoMode.MONTHLY_BUDGET_TARGET && strategy.monthlyBudget! > 1_000_000_000) {
      errors.push('Monthly budget should be lower than 1 billion');
    }
    // check daily budget
    if (strategy.algoMode === AlgoMode.MONTHLY_BUDGET_TARGET && strategy.dailyBudget != undefined) {
      errors.push('Impossible to set a daily budget for Monthly budget algorithm');
    }
    if (
      strategy.algoMode === AlgoMode.ACOS_TARGET &&
      strategy.dailyBudget != undefined &&
      strategy.minDailySpend != undefined
    ) {
      if (strategy.dailyBudget < 2 * strategy.minDailySpend) {
        errors.push('Average Daily Budget must be at least 2 times higher than Min Daily Spend');
      } else if (strategy.dailyBudget > 1_000_000_000) {
        errors.push('Average Daily Budget must be less than 1 billion');
      }
    }

    // check min daily spend
    if (strategy.algoMode === AlgoMode.PRODUCT_LAUNCH && strategy.minDailySpend) {
      errors.push('Min daily spend cannot be defined for Force product visibility');
    }
    const currency = marketplaceCurrency(this.selectedAccountMarketplace!.marketplace);
    const minDailyBudgetLimit = Math.round(
      this.selectedAccountMarketplace!.minDailyBudgetLimit! / currencyRateToEuro(currency),
    );
    if (strategy.minDailySpend! > minDailyBudgetLimit) {
      errors.push(`Min daily Spend must be lower than ${minDailyBudgetLimit} ${currency}`);
    }
    if (strategy.minDailySpend! < 0) {
      errors.push('Min daily spend should be greater than 0');
    }

    // check disableOtherQueries aka AI-powered targeting
    if (
      strategy.campaignType === CampaignType.SD &&
      strategy.disableOtherQueries &&
      strategy.tactics.length == 0 &&
      strategy.audienceTargetings.length == 0
    ) {
      errors.push('Not possible to disable AI-powered targeting targeting when the strategy has no targetings');
    } else if (
      strategy.campaignType !== CampaignType.SD &&
      strategy.disableOtherQueries &&
      strategy.tactics.length == 0
    ) {
      errors.push('Not possible to disable AI-powered targeting when the strategy has no tactic');
    }
    // check product targeting aka disableProductSegment & disableOtherQueries aka AI-powered targeting
    if (
      strategy.disableProductSegment &&
      strategy.disableOtherQueries &&
      strategy.tactics.length > 0 &&
      strategy.tactics.every(
        (t) =>
          (this.segmentService.getSegmentById(t.segmentId!)?.segmentType ?? SegmentConfigType.ProductSegment) ==
          SegmentConfigType.ProductSegment,
      )
    ) {
      errors.push(
        'Not possible to deactivate product targeting when AI-powered targeting is disabled and strategy only has product targeting tactics',
      );
    }
    // check boost
    if (strategy.primeDayBoost && strategy.algoMode !== AlgoMode.ACOS_TARGET) {
      errors.push('Strategy boost can only be activated on strategy with target ACOS');
    }
    if (strategy.dailyBudget! > 0 && strategy.primeDayBoost) {
      errors.push('Cannot add a target daily budget on a strategy with promo boost');
    }
    // check dayparting inputs
    if (strategy.daypartingPauseHour != null && strategy.daypartingPauseHour == strategy.daypartingReactivationHour) {
      errors.push('Dayparting pause and reactivation hours must be different');
    }

    // check strategy type / strategy group
    if (strategy.strategyGroupId !== undefined) {
      if (strategy.campaignType != CampaignType.SP) {
        errors.push('Strategy group can only be set for SP');
      }
    }
    if (strategy.strategyGroupId && (!strategy.strategyType || strategy.strategyType == StrategyType.LEGACY)) {
      errors.push('When attached to a strategy group, a strategy type has to be defined');
    }
    return errors;
  }

  public checkStrategyCreation(strategy: Strategy): string[] {
    const errors: string[] = [];
    errors.push(...this.checkStrategyParams(strategy));
    // check strategy state
    if (strategy.state == StrategyStateEnum.ENABLED) {
      // check if the limit is reached
      if (strategy.campaignType == CampaignType.SB && this.isSBStrategyLimitReached()) {
        errors.push(
          `Cannot activate a new strategy as the limit of ${Constant.maxSbStrategies} live Sponsored Brands strategies has been reached`,
        );
      }
      if (this.isStrategyLimitReached(strategy.campaignType)) {
        errors.push(
          'Cannot activate a new strategy as the limit of live strategies of your plan has been reached (please contact us)',
        );
      }
      if (strategy.asins && strategy.asins.length! > ProductGroupEx.MaxProductGroupItems) {
        errors.push(`Cannot activate a strategy with more than ${ProductGroupEx.MaxProductGroupItems} ASINs.`);
      }
    }
    // check that we have less than 10 kw strategies and less than 5 brand defense strategies
    if (strategy.strategyGroupId && strategy.strategyType == StrategyType.BRAND) {
      const strategyGroup = this.strategyCache.getStrategyGroupIndex(strategy.strategyGroupId);
      if (strategyGroup && strategyGroup.brandStrategies.length >= Constant.maxBrandDefenseStrategiesByStrategyGroup) {
        errors.push(
          `The same strategy group cannot have more than ${Constant.maxBrandDefenseStrategiesByStrategyGroup} brand defense strategies`,
        );
      }
    } else if (strategy.strategyGroupId && strategy.strategyType == StrategyType.KEYWORD) {
      const strategyGroup = this.strategyCache.getStrategyGroupIndex(strategy.strategyGroupId);
      if (strategyGroup && strategyGroup.keywordStrategies.length >= Constant.maxKeywordStrategiesByStrategyGroup) {
        errors.push(
          `The same strategy group cannot have more than ${Constant.maxKeywordStrategiesByStrategyGroup} focus strategies`,
        );
      }
    }
    return errors;
  }

  public createStrategyGroup(
    strategyGroup: Required<CreateStrategyGroupRequest>,
    productStrategy: Strategy,
  ): Observable<StrategyGroupEx> {
    const errors = this.checkStrategyCreation(productStrategy);
    if (errors.length > 0) {
      return throwError(() => errors.join(', '));
    }

    return this.strategyGroupService.createStrategyGroup(strategyGroup).pipe(
      catchAjaxError('Error creating the strategy group: '),
      switchMap((response) => {
        const strategyGroup = response.entity as StrategyGroup;
        const productStrategyForCreation = {
          ...productStrategy,
          strategyGroupId: strategyGroup.strategyGroupId,
          strategyType: StrategyType.PRODUCT,
        };
        return this.createStrategyAsync(productStrategyForCreation).pipe(
          map((strategy) => {
            const strategyGroupEx = {
              ...strategyGroup,
              strategies: [strategy],
              productStrategies: [strategy],
              brandStrategies: [],
              keywordStrategies: [],
              asins: strategy.asins.map((p) => p.asin!),
              lastUpdate: Utils.historyNow(),
            };
            return strategyGroupEx;
          }),
          tap((strategyGroup) => {
            this.strategyCache.setStrategyGroupIndex(strategyGroup as StrategyGroupEx);
          }),
        );
      }),
    );
  }

  public deleteStrategyGroup(strategyGroupId: number): Observable<void> {
    const strategyGroup = this.strategyCache.getStrategyGroupIndex(strategyGroupId);
    if (!strategyGroup) {
      return of(void 0);
    }
    const strategies = [
      ...strategyGroup.productStrategies,
      ...strategyGroup.brandStrategies,
      ...strategyGroup.keywordStrategies,
    ];
    return (
      strategies.length == 0
        ? of([])
        : forkJoin(
            strategies.map((strategy) =>
              this.strategyService.deleteStrategy({
                accountId: this.selectedAccountMarketplace!.accountId,
                marketplace: this.selectedAccountMarketplace!.marketplace,
                strategyId: strategy.strategyId,
                organizationId: this.selectedAccountMarketplace!.resourceOrganizationId!,
              }),
            ),
          )
    ).pipe(
      switchMap(() =>
        this.strategyGroupService.deleteStrategyGroup({
          accountId: this.selectedAccountMarketplace!.accountId,
          marketplace: this.selectedAccountMarketplace!.marketplace,
          strategyGroupId,
        }),
      ),
      catchAjaxError('Error deleting strategy group: '),
      tap(() => {
        this.strategyCache.deleteStrategyGroupIndex(strategyGroup.strategyGroupId!);
        this.strategyCache.deleteStrategies(strategies);
      }),
      map(() => void 0),
    );
  }

  public migrateStrategyGroup(strategyGroupName: string, strategies: StrategyToMigate[]): Observable<StrategyGroupEx> {
    return this.strategyGroupService
      .migrateToNewStrategyGroup({
        accountId: this.selectedAccountMarketplace!.accountId,
        marketplace: this.selectedAccountMarketplace!.marketplace,
        strategyGroupMigration: {
          strategyGroupName,
          strategies,
        },
      })
      .pipe(
        catchAjaxError('Error migrating strategies to strategy group: '),
        map((response) => {
          const strategyGroup = response.entity as StrategyGroup;
          const strategies = strategyGroup.strategies!.map(
            (strat) =>
              new StrategyEx(
                strat,
                this.strategyCache.getStrategySegmentAsMap(strat),
                this.strategyCache.getCreativesMap(strat.strategyId!),
                this.strategyCache.strategyCurrentMonthSpendIndex,
              ),
          );
          this.strategyCache.updateStrategies(strategies);
          const strategyGroupEx = this.strategyCache.buildStrategyGroupEx(strategyGroup, strategies);
          this.strategyCache.setStrategyGroupIndex(strategyGroupEx);
          return strategyGroupEx;
        }),
      );
  }

  public createStrategyAsync(strategy: Strategy): Observable<StrategyEx> {
    const errors = this.checkStrategyCreation(strategy);
    if (errors.length > 0) {
      return throwError(() => errors.join(', '));
    }
    return this.createStrategyInDb(strategy).pipe(
      switchMap((s) => {
        if (strategy.campaignType == CampaignType.SP || strategy.campaignType == CampaignType.SD) {
          return this.addAsinsToStrategy(s, strategy.asins!.map((a) => a.asin!)!).pipe(
            map(() => {
              s.asins = strategy.asins!;
              return s;
            }),
            catchAjaxError('Impossible to add ASINs to the strategy: '),
          );
        }
        return of(s);
      }),
      switchMap((s) => {
        if (
          (strategy.strategyType == StrategyType.BRAND || strategy.strategyType == StrategyType.KEYWORD) &&
          strategy.targetings &&
          strategy.targetings.length > 0
        ) {
          return this.addTargetingToStrategy(s, strategy.targetings!).pipe(
            map(() => {
              s.targetings = strategy.targetings!;
              return s;
            }),
            catchAjaxError('Impossible to add Targetings to the strategy: '),
          );
        }
        return of(s);
      }),
    );
  }

  public deleteStrategy(strategy: StrategyEx): Observable<void> {
    if (
      strategy.defaultStrategy &&
      (strategy.campaignType == CampaignType.SP || strategy.campaignType == CampaignType.SD)
    ) {
      return throwError(() => 'The default Strategy cannot be deleted');
    }
    if (!this.strategyCache.get(strategy.strategyId)) {
      return throwError(() => 'Unknown strategy ' + strategy.strategyId);
    }
    return this.strategyService
      .deleteStrategy({
        accountId: strategy.accountId,
        marketplace: strategy.marketplace,
        strategyId: strategy.strategyId,
        organizationId: this.selectedAccountMarketplace!.resourceOrganizationId!,
      })
      .pipe(
        catchAjaxError(),
        switchMap(() => {
          if (strategy.strategyGroupId) {
            const strategyGroup = this.strategyCache.getStrategyGroupIndex(strategy.strategyGroupId)!;
            if (strategy.strategyType == StrategyType.BRAND) {
              strategyGroup.brandStrategies = strategyGroup.brandStrategies.filter(
                (s) => s.strategyId !== strategy.strategyId,
              );
            } else if (strategy.strategyType == StrategyType.KEYWORD) {
              strategyGroup.keywordStrategies = strategyGroup.keywordStrategies.filter(
                (s) => s.strategyId !== strategy.strategyId,
              );
            } else if (strategy.strategyType == StrategyType.PRODUCT) {
              strategyGroup.productStrategies = strategyGroup.productStrategies.filter(
                (s) => s.strategyId !== strategy.strategyId,
              );
              // recompute list of ASINs
              const asins = strategyGroup.productStrategies.flatMap((s) => s.asins.map((p) => p.asin!));
              strategyGroup.asins = asins;
            }
            strategyGroup.strategies = strategyGroup.strategies!.filter((s) => s.strategyId !== strategy.strategyId);
            this.strategyCache.strategyGroupIndexSubjectNext();
          }
          this.strategyCache.deleteStrategies([strategy]);
          return of(void 0);
        }),
        map(() => void 0),
      );
  }

  public checkStrategyName(name: string, campaignType: CampaignType): void {
    if (!Constant.nameRegexp.test(name)) {
      throw 'Invalid character used. Strategy Name can only use characters allowed in Amazon campaign names';
    }
    if (
      (campaignType == CampaignType.SB || campaignType == CampaignType.SD) &&
      Constant.invalidSBSDNameRegexp.test(name)
    ) {
      throw "Invalid character used, Strategy Name can only use characters allowed in Amazon campaign names. Characters: '!%#<>.' are not allowed for SD and SB.";
    }
  }

  public checkStrategyUpdate(strategyUpdateParams: StrategyUpdateParams): string[] {
    const strategy = this.strategyCache.get(strategyUpdateParams.strategyId);
    if (!strategy) {
      return [`Error updating Strategy: invalid strategyId ${strategyUpdateParams.strategyId}`];
    }
    if (strategyUpdateParams.name && strategy.defaultStrategy) {
      return ['Cannot modify name of "All Other Products" strategy'];
    }
    // check strategy state
    if (strategyUpdateParams.state) {
      if (strategyUpdateParams.state == StrategyStateEnum.ENABLED && strategy.state !== StrategyStateEnum.ENABLED) {
        // check if the limit is reached
        if (strategy.campaignType == CampaignType.SB && this.isSBStrategyLimitReached()) {
          return [
            `Cannot activate a new strategy as the limit of ${Constant.maxSbStrategies} live Sponsored Brands strategies has been reached`,
          ];
        }
        if (this.isStrategyLimitReached(strategy.campaignType)) {
          return [
            'Cannot activate a new strategy as the limit of live strategies has been reached (please contact us)',
          ];
        }
        if ((this.numberOfAsinsPerStrategy!.get(strategy.strategyId) ?? 0) > ProductGroupEx.MaxProductGroupItems) {
          return [`Cannot activate a strategy with more than ${ProductGroupEx.MaxProductGroupItems} ASINs.`];
        }
        // do not activate all other products for VENDORS
        if (
          strategy.accountId.startsWith('ENTITY') &&
          strategy.campaignType == CampaignType.SP &&
          strategy.isAllOtherProduct()
        ) {
          return ["Vendor accounts cannot activate 'All other products' strategies"];
        }
        // do not activate SB strategy if there is a product collection with no custom image
        if (strategy.campaignType == CampaignType.SB) {
          const sbCreatives = this.strategyCache.getCreatives(strategy.strategyId);
          const productCollectionWithNoCustomImage = sbCreatives.some(
            (c) => c.creativeType == SbCreativeType.productCollection && !c.customImage,
          );
          if (productCollectionWithNoCustomImage) {
            return [
              'Cannot activate a Sponsored Brands strategy with a product collection ad line without custom image',
            ];
          }
        }
      }
    }
    // check strategy ASIN modifications
    if (
      (strategyUpdateParams.asinsToAdd.length > 0 || strategyUpdateParams.asinsToDelete.length > 0) &&
      strategy.defaultStrategy
    ) {
      return ['Cannot modify ASIN list for "All Other Products" strategy'];
    }
    const updatedStrategy = { ...strategy, ...strategyUpdateParams };
    updatedStrategy.asins = updatedStrategy.asins
      .concat(strategyUpdateParams.asinsToAdd.map((a) => ({ asin: a })))
      .filter((a) => !strategyUpdateParams.asinsToDelete.includes(a.asin!));
    return this.checkStrategyParams(updatedStrategy);
  }

  public updateStrategyGroup(strategyGroupUpdateParams: StrategyGroupUpdateParams): Observable<StrategyGroupEx> {
    return this.strategyGroupService
      .updateStrategyGroup({
        accountId: strategyGroupUpdateParams.accountId,
        marketplace: strategyGroupUpdateParams.marketplace,
        organizationId: strategyGroupUpdateParams.organizationId,
        strategyGroupId: strategyGroupUpdateParams.strategyGroupId,
        strategyGroupName: strategyGroupUpdateParams.strategyGroupName,
      })
      .pipe(
        catchAjaxError('Error updating Strategy Group: '),
        map((response) => {
          const strategyGroup = response.entity as StrategyGroup;
          const strategyGroupEx = {
            ...this.strategyCache.getStrategyGroupIndex(strategyGroup.strategyGroupId!)!,
            ...strategyGroup,
          };
          this.strategyCache.setStrategyGroupIndex(strategyGroupEx);
          return strategyGroupEx;
        }),
      );
  }

  public updateStrategyGroupBlacklist(
    params: StrategyGroupBlacklistUpdateParams,
  ): Observable<StrategyGroupEx | undefined> {
    if (params.toAdd.length == 0 && params.toDelete.length == 0) {
      return this.getStrategyGroupById(params.strategyGroupId);
    }

    const updateBlacklist: Observable<Response | undefined> =
      params.toDelete && params.toDelete.length > 0
        ? this.strategyGroupService.updateStrategyGroupBlacklist({
            accountId: params.accountId,
            marketplace: params.marketplace,
            strategyGroupId: params.strategyGroupId,
            targeting: params.toDelete,
            action: UpdateStrategyGroupBlacklistActionEnum.DELETE,
          })
        : of(undefined);

    return updateBlacklist.pipe(
      switchMap(() => {
        return params.toAdd && params.toAdd.length > 0
          ? this.strategyGroupService.updateStrategyGroupBlacklist({
              accountId: params.accountId,
              marketplace: params.marketplace,
              strategyGroupId: params.strategyGroupId,
              targeting: params.toAdd,
              action: UpdateStrategyGroupBlacklistActionEnum.ADD,
            })
          : of(void 0);
      }),
      catchAjaxError('Error updating Strategy Group blacklist: '),
      map(() => {
        const strategyGroupEx = this.strategyCache.getStrategyGroupIndex(params.strategyGroupId)!;
        strategyGroupEx.blacklist = strategyGroupEx
          .blacklist!.concat(params.toAdd)
          .filter(
            (t) =>
              params.toDelete.findIndex((d) => d.matchType == t.matchType && d.targetingValue == t.targetingValue) < 0,
          );
        this.strategyCache.setStrategyGroupIndex(strategyGroupEx);
        return strategyGroupEx as unknown as StrategyGroupEx;
      }),
    );
  }

  public updateStrategyAsync(strategyUpdateParams: StrategyUpdateParams): Observable<StrategyEx> {
    const error = this.checkStrategyUpdate(strategyUpdateParams);
    if (error.length > 0) {
      return throwError(() => error);
    }
    const strategy = this.strategyCache.get(strategyUpdateParams.strategyId);
    let updateNeeded = false;

    const strategyUpdateRequest: UpdateStrategyRequest = {
      accountId: strategyUpdateParams.accountId,
      marketplace: strategyUpdateParams.marketplace,
      strategyId: strategyUpdateParams.strategyId,
      organizationId: this.selectedAccountMarketplace!.resourceOrganizationId!,
    };

    for (const key in strategyUpdateParams) {
      // TODO: refactor this
      // prevent update of algo mode and monthly budget in this function
      if (key == 'algoMode' || key == 'monthlyBudget' || key == 'nextMonthlyBudget') {
        continue;
      }

      if (
        strategyUpdateParams[key as keyof StrategyUpdateParams] !== undefined &&
        strategy![key as keyof StrategyEx] !== strategyUpdateParams[key as keyof StrategyUpdateParams]
      ) {
        strategyUpdateRequest[key as keyof UpdateStrategyRequest] = (strategyUpdateParams[
          key as keyof StrategyUpdateParams
        ] ?? 'null') as never;
        updateNeeded = true;
      }
    }

    if (!updateNeeded) {
      // no change detected
      return of(strategy!);
    }

    return this.strategyService.updateStrategy(strategyUpdateRequest).pipe(
      map((response: Response) => this.parseResponseAndUpdateStrategy(response)),
      mergeMap((s) => {
        if (strategyUpdateParams.asinsToAdd.length > 0) {
          return this.addAsinsToStrategy(s, strategyUpdateParams.asinsToAdd).pipe(
            map(() => {
              return this.strategyCache.get(s.strategyId);
            }),
          );
        } else {
          return of(s);
        }
      }),
      mergeMap((s) => {
        if (strategyUpdateParams.asinsToDelete.length > 0) {
          return this.deleteAsinsFromStrategy(s!, strategyUpdateParams.asinsToDelete).pipe(
            map(() => {
              return this.strategyCache.get(s!.strategyId)!;
            }),
          );
        } else {
          return of(s!);
        }
      }),
      catchAjaxError('Error updating Strategy ' + strategy!.getName() + ': '),
    );
  }

  private updateStrategyWithAlert(params: StrategyUpdateParams) {
    this.updateStrategyAsync(params).subscribe(
      (strategy) => {
        this.alertService.success('Strategy ' + strategy.getName() + ' successfully updated', 'Strategy Updated');
      },
      (error) => {
        this.alertService.error(error, 'Strategy Update Error');
      },
    );
  }

  public updateStrategyName(accountId: string, marketplace: Marketplace, strategyId: number, name: string): void {
    this.updateStrategyWithAlert({
      accountId,
      marketplace,
      strategyId,
      name,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updateStrategyLabel(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    strategyLabel: string,
  ): void {
    this.updateStrategyWithAlert({
      accountId,
      marketplace,
      strategyId,
      strategyLabel,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updateStrategyDailyBudget(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    dailyBudget: number,
  ): void {
    this.updateStrategyWithAlert({
      accountId,
      marketplace,
      strategyId,
      dailyBudget,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  private parseResponseAndUpdateStrategy(response: Response): StrategyEx {
    const strat = response.entity as Strategy;
    const modifiedStrategy = new StrategyEx(
      strat,
      this.strategyCache.getStrategySegmentAsMap(strat),
      this.strategyCache.getCreativesMap(strat.strategyId!),
      this.strategyCache.strategyCurrentMonthSpendIndex,
    );
    this.strategyCache.updateStrategy(modifiedStrategy);
    if (strat.strategyGroupId) {
      const strategyGroup = this.strategyCache.getStrategyGroupIndex(strat.strategyGroupId)!;
      strategyGroup.strategies = strategyGroup.strategies!.map((s) =>
        s.strategyId == strat.strategyId ? modifiedStrategy : s,
      );
      if (strategyGroup.strategies.length === 1) {
        if (strategyGroup.strategyGroupName !== modifiedStrategy.name) {
          strategyGroup.strategyGroupName = modifiedStrategy.name;
        }
      }
      switch (strat.strategyType) {
        case StrategyType.BRAND:
          strategyGroup.brandStrategies = strategyGroup.brandStrategies.map((s) =>
            s.strategyId == strat.strategyId ? modifiedStrategy : s,
          );
          // sort strategies by priority
          strategyGroup.brandStrategies.sort((s1, s2) => s1.priority! - s2.priority!);
          break;
        case StrategyType.KEYWORD:
          strategyGroup.keywordStrategies = strategyGroup.keywordStrategies.map((s) =>
            s.strategyId == strat.strategyId ? modifiedStrategy : s,
          );
          // sort strategies by priority
          strategyGroup.keywordStrategies.sort((s1, s2) => s1.priority! - s2.priority!);
          break;
        case StrategyType.PRODUCT:
          strategyGroup.productStrategies = strategyGroup.productStrategies.map((s) =>
            s.strategyId == strat.strategyId ? modifiedStrategy : s,
          );
          break;
      }
      this.strategyCache.strategyGroupIndexSubjectNext();
    }
    return modifiedStrategy;
  }

  public updateStrategyPriority(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    priority: number,
  ): Observable<StrategyEx> {
    return this.updateStrategyAsync({
      accountId,
      marketplace,
      strategyId,
      priority,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public switchStrategyAlgoModeAsync(
    strategy: Strategy,
    algoMode: AlgoMode,
    acosTarget: number,
    suggestedBid: number,
    dailyBudget: number,
    monthlyBudget: number,
  ): Observable<StrategyEx> {
    return this.strategyService
      .switchStrategyAlgoMode({
        accountId: strategy.accountId,
        marketplace: strategy.marketplace,
        strategyId: strategy.strategyId!,
        algoMode: algoMode,
        acosTarget: acosTarget,
        suggestedBid: suggestedBid,
        dailyBudget: dailyBudget,
        monthlyBudget: monthlyBudget,
      })
      .pipe(
        map((response: Response) => {
          return this.parseResponseAndUpdateStrategy(response);
        }),
        catchAjaxError('Error updating Algo mode: '),
      );
  }

  public updateStrategyMonthlyBudgetAsync(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    monthlyBudget: number,
    nextMonthlyBudget: number,
    currentMonth: string,
  ): Observable<StrategyEx> {
    if (monthlyBudget <= 0) {
      return throwError(() => 'Monthly budget must be greater than 0');
    }
    if (monthlyBudget >= 1_000_000_000) {
      return throwError(() => 'Monthly budget should be lower than 1 billion');
    }
    if (nextMonthlyBudget < 0) {
      return throwError(() => 'Next Monthly budget must be greater than 0');
    }
    if (nextMonthlyBudget >= 1_000_000_000) {
      return throwError(() => 'Next Monthly budget should be lower than 1 billion');
    }
    return this.strategyService
      .updateStrategyMonthlyBudget({
        accountId,
        marketplace,
        strategyId,
        monthlyBudget: monthlyBudget,
        nextMonthlyBudget: nextMonthlyBudget,
        currentMonth: currentMonth,
      })
      .pipe(
        map((response: Response) => {
          return this.parseResponseAndUpdateStrategy(response);
        }),
        catchAjaxError('Error updating Monthly budget: '),
      );
  }

  public updatePrimeDayBoost(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    primeDayBoost: number,
  ): void {
    this.updateStrategyWithAlert({
      accountId,
      marketplace,
      strategyId,
      primeDayBoost,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updatePrimeDayBoostAsync(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    primeDayBoost: number,
  ): Observable<StrategyEx> {
    return this.updateStrategyAsync({
      accountId,
      marketplace,
      strategyId,
      primeDayBoost,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updateStrategyState(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    state: StrategyStateEnum,
  ): void {
    this.updateStrategyWithAlert({
      accountId,
      marketplace,
      strategyId,
      state,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updateStrategyAutoAlgoExploration(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    autoAlgoExplorationEnabled: boolean,
  ): void {
    // The naming is still in the "disable" way but the logic isn't
    // if “automated algorithm’s exploration” = “off” then “automated targeting campaign” has to be “off”
    const disableOtherQueries = !autoAlgoExplorationEnabled;
    this.updateStrategyWithAlert({
      accountId,
      marketplace,
      strategyId,
      disableOtherQueries,
      disableAutoSegment: !autoAlgoExplorationEnabled ? true : undefined,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updateStrategyTargetCampain(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    autoTargetCampainEnabled: boolean,
  ): void {
    // The naming is still in the "disable" way but the logic isn't
    const disableAutoSegment = !autoTargetCampainEnabled;
    // if “automated targeting campaign” is on then “automated algorithm’s exploration” have to be “on”
    this.updateStrategyWithAlert({
      accountId,
      marketplace,
      strategyId,
      disableAutoSegment,
      disableOtherQueries: autoTargetCampainEnabled ? false : undefined,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updateStrategyProductTargeting(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    productTargetingEnabled: boolean,
  ): void {
    // The naming is still in the "disable" way but the logic isn't
    const disableProductSegment = !productTargetingEnabled;
    this.updateStrategyWithAlert({
      accountId,
      marketplace,
      strategyId,
      disableProductSegment,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updateStrategyDisableDayParting(accountId: string, marketplace: Marketplace, strategyId: number): void {
    this.updateStrategyWithAlert({
      accountId,
      marketplace,
      strategyId,
      daypartingPauseHour: null,
      daypartingReactivationHour: null,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updateStrategyHoursDayParting(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    daypartingPauseHour: number,
    daypartingReactivationHour: number,
  ): void {
    this.updateStrategyWithAlert({
      accountId,
      marketplace,
      strategyId,
      daypartingPauseHour,
      daypartingReactivationHour,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  public updateStrategyMinDailySpend(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    minDailySpend: number,
  ) {
    this.updateStrategyWithAlert({
      accountId,
      marketplace,
      strategyId,
      minDailySpend,
      asinsToAdd: [],
      asinsToDelete: [],
    });
  }

  // WARN Bad design:
  // the tactic should be updated in the subscribe
  // we should not reload everything on error
  public updateTacticIntensity(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    segmentId: number,
    intensity: Intensity,
  ): void {
    this.strategyService
      .updateTactic({
        accountId: accountId,
        marketplace: marketplace,
        strategyId: strategyId,
        segmentId: segmentId,
        intensity: intensity,
      })
      .subscribe({
        next: () => {
          this.alertService.success('Tactic intensity successfully updated', 'Tactic Updated');
        },
        error: (error: AjaxError) => {
          this.alertService.error(
            'Error updating tactic intensity: ' + (error.response ? error.response.message : error.message),
            'Tactic Update Error',
          );
          this.strategyCache.reloadCache();
        },
      });
  }

  // WARN Bad design:
  // the tactic should be updated in the subscribe
  // we should not reload everything on error
  public updateTacticBoostPlacementTop(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    segmentId: number,
    boostPlacementTop: boolean,
  ): void {
    this.strategyService
      .updateTactic({
        accountId: accountId,
        marketplace: marketplace,
        strategyId: strategyId,
        segmentId: segmentId,
        boostPlacementTop: boostPlacementTop,
      })
      .subscribe({
        next: () => {
          this.alertService.success(
            'Tactic to have only top of search placement successfully updated',
            'Tactic Updated',
          );
        },
        error: (error: AjaxError) => {
          this.alertService.error(
            'Error updating tactic to have only top of search placement: ' +
              (error.response ? error.response.message : error.message),
            'Tactic Update Error',
          );
          this.strategyCache.reloadCache();
        },
      });
  }

  public addTacticToStrategyAsync(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    segmentId: number,
    tacticType: TacticType,
  ): Observable<StrategyTacticEx> {
    return this.strategyService
      .addTactic({
        accountId: accountId,
        marketplace: marketplace,
        strategyId: strategyId,
        segmentId: segmentId,
        tacticType: tacticType,
        intensity: Intensity.NEUTRAL,
        boostPlacementTop: false,
      })
      .pipe(
        catchAjaxError('Error creating tactic: '),
        map((response) => {
          const strategy = this.strategyCache.get(strategyId)!;
          const segment = this.segmentService.getSegmentById(segmentId)!;
          const tactic = new StrategyTacticEx(response.entity!, segment);
          strategy.tactics.push(tactic);
          if (strategy.strategyGroupId && strategy.strategyType == StrategyType.PRODUCT) {
            const strategyGroup = this.strategyCache.getStrategyGroupIndex(strategy.strategyGroupId)!;
            strategyGroup.productStrategies = strategyGroup.productStrategies.map((s) =>
              s.strategyId == strategy.strategyId ? strategy : s,
            );
            this.strategyCache.strategyGroupIndexSubjectNext();
          }
          this.strategyCache.buildAccountConfig();
          return tactic;
        }),
      );
  }

  public removeTacticFromStrategy(accountId: string, strategyId: number, segmentId: number): void {
    const strategy = this.strategyCache.get(strategyId);
    if (!strategy) {
      this.alertService.error('Error updating Strategy: invalid strategyId ' + strategyId, 'Strategy Update');
      return;
    }
    const index = strategy.tactics.findIndex((x) => x.segmentId == segmentId);
    if (index == -1) {
      this.alertService.error(
        'Error updating Strategy: segmentId ' + segmentId + ' is not part of Strategy ' + strategy.getName(),
        'Strategy Update',
      );
      return;
    }
    this.strategyService
      .deleteTactic({
        accountId: accountId,
        marketplace: strategy.marketplace,
        strategyId: strategyId,
        segmentId: segmentId,
      })
      .subscribe({
        next: () => {
          strategy.tactics.splice(index, 1);
          if (strategy.strategyGroupId && strategy.strategyType == StrategyType.PRODUCT) {
            const strategyGroup = this.strategyCache.getStrategyGroupIndex(strategy.strategyGroupId)!;
            strategyGroup.productStrategies = strategyGroup.productStrategies.map((s) =>
              s.strategyId == strategy.strategyId ? strategy : s,
            );
            this.strategyCache.strategyGroupIndexSubjectNext();
          }
          this.strategyCache.buildAccountConfig();
          this.alertService.success('Tactic successfully deleted', 'Tactic Deleted');
        },
        error: (error: AjaxError) => {
          this.alertService.error(
            'Error deleting tactic: ' + (error.response ? error.response.message : error.message),
            'Tactic Deletion Error',
          );
          this.strategyCache.reloadCache();
        },
      });
  }

  public addRemarketingAudienceToSdStrategyAsync(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    audienceExpressionType: AudienceExpressionType,
    audienceMatchType: AudienceMatchType,
    lookback: number,
  ): Observable<AudienceTargeting> {
    return this.strategyService
      .addAudienceTargeting({
        accountId: accountId,
        marketplace: marketplace,
        strategyId: strategyId,
        expressionType: audienceExpressionType,
        matchType: audienceMatchType,
        lookback: lookback,
      })
      .pipe(
        catchAjaxError('Error creating Remarketing targeting: '),
        map((response) => {
          const strategy = this.strategyCache.get(strategyId)!;
          const audience = response.entity as AudienceTargeting;
          strategy.audienceTargetings.push(audience);
          this.strategyCache.buildAccountConfig();
          return audience;
        }),
      );
  }

  public removeRemarketingAudienceToSdStrategy(accountId: string, strategyId: number, audienceTargetId: number): void {
    const strategy = this.strategyCache.get(strategyId);
    if (!strategy) {
      this.alertService.error('Error updating Strategy: invalid strategyId ' + strategyId, 'Strategy Update');
      return;
    }
    const index = strategy.audienceTargetings.findIndex((x) => x.audienceTargetId == audienceTargetId);
    if (index == -1) {
      this.alertService.error(
        'Error updating Strategy: audienceId ' + audienceTargetId + ' is not part of Strategy ' + strategy.getName(),
        'Strategy Update',
      );
      return;
    }
    this.strategyService
      .deleteAudienceTargeting({
        accountId: accountId,
        marketplace: strategy.marketplace,
        audienceTargetId: audienceTargetId,
        strategyId: strategyId,
      })
      .subscribe({
        next: () => {
          strategy.audienceTargetings.splice(index, 1);
          this.strategyCache.buildAccountConfig();
          this.alertService.success('Audience successfully deleted', 'Audience Deleted');
        },
        error: (error: AjaxError) => {
          this.alertService.error(
            'Error deleting Audience: ' + (error.response ? error.response.message : error.message),
            'Audience Deletion Error',
          );
          this.strategyCache.reloadCache();
        },
      });
  }

  public updateTopOfSearchRankings(
    strategy: Strategy,
    organizationId: number,
    keywords: string[],
    action: UpdateStrategyTopOfSearchRankingsActionEnum,
  ): Observable<Response> {
    return this.strategyService
      .updateStrategyTopOfSearchRankings({
        accountId: strategy.accountId,
        marketplace: strategy.marketplace,
        strategyId: strategy.strategyId!,
        organizationId,
        action,
        requestBody: keywords,
      })
      .pipe(
        catchAjaxError(),
        tap(() => {
          const strat = this.strategyCache.get(strategy.strategyId!)!;
          if (!strategy.topOfSearchRankings) {
            strategy.topOfSearchRankings = [];
          }
          if (action == UpdateStrategyTopOfSearchRankingsActionEnum.ADD) {
            strategy.topOfSearchRankings.push(...keywords.map((k) => ({ keyword: k })));
          } else {
            strategy.topOfSearchRankings = strategy.topOfSearchRankings.filter((k) => !keywords.includes(k.keyword!));
          }

          if (strat.strategyGroupId) {
            this.strategyCache.strategyGroupIndexSubjectNext();
          }
        }),
      );
  }
}
