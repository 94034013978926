<div class="flex flex-wrap gap-2" *transloco="let t">
  <ISelect
    class="inline-block"
    placeholder="{{ t('filter-tags.add_filter') }}"
    [options]="_filters"
    (selectedChange)="selectFilter($event)"
    popperWidth="250"
    rounded
    withCategories
    searchable
  >
    <ng-template #leadingSlot>
      <span class="icon-[mdi--filter-variant] size-5 text-gray-500"></span>
    </ng-template>

    <ng-template #labelSlot>
      <span class="text-gray-600">{{ t("filter-tags.add_filter") }}</span>
    </ng-template>
  </ISelect>

  @for (filter of filterValues; track filter.id) {
    <IMultiSelect
      [options]="filter.filter.options()"
      [selected]="filter.values"
      [withSelectAll]="!filter.filter.singleValue"
      (selectedChange)="selectValue(filter, $event)"
      [placeholder]="t('filter-tags.select_filter', [filter.filter.label])"
      popperWidth="250"
      by="value"
      searchable
      rounded
      openOnInit
    >
      @if (filter.filter.excludeOption) {
        <ng-template #headerSlot>
          <div class="px-1 pb-2 pt-1">
            <mat-radio-group
              [value]="filter.exclude"
              (change)="excludeFilter(filter.id, $event.value)"
              class="flex w-full gap-3 [&_.mdc-radio]:!p-0 [&_label]:m-0 [&_label]:ml-2"
            >
              <mat-radio-button disableRipple color="primary" class="radio-button-center" [value]="false">
                <div class="text-sm">{{ t("filter-tags.include") }}</div>
              </mat-radio-button>
              <mat-radio-button disableRipple color="primary" class="radio-button-center" [value]="true">
                <div class="text-sm">{{ t("filter-tags.exclude") }}</div>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </ng-template>
      }
      <ng-template #leadingSlot>
        <button
          class="flex items-center rounded-full bg-transparent p-[0.1rem] hover:bg-gray-200"
          matTooltip="{{ t('filter-tags.clear_filter') }}"
          (click)="clearFilter(filter.id); $event.stopPropagation()"
        >
          <span [class]="ICON_CLOSE + ' size-4 text-gray-500'"></span>
        </button>
        @if (filter.exclude) {
          <span class="text-sm font-medium uppercase text-red-500">{{ t("filter-tags.not") }}</span>
        }
      </ng-template>
      <ng-template #labelSlot>
        <span class="font-medium text-gray-500">
          {{ filter.filter.label }}
        </span>
        <span> {{ t("filter-tags.selected_filter", { count: filter.values.length }) }}</span>
      </ng-template>
      <ng-template #optionSlot let-o>
        <!-- @if (o?.badge) { -->
        <!-- <app-campaign-type-badge [campaignType]="o.badge.label" /> -->
        <!-- } -->
        <span class="truncate text-sm">{{ o.label }}</span>
      </ng-template>
    </IMultiSelect>
  }
</div>
