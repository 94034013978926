import { formatDate } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AuthService, Currency, CurrencyService, UpdateUserRequest, User } from "@front/m19-services";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ICON_CHEVRON_DOWN, ICON_EMAIL, ICON_USER } from "@m19-board/utils/iconsLabels";

@UntilDestroy()
@Component({
  selector: "app-profile-settings",
  templateUrl: "./profile-settings.component.html",
})
export class ProfileSettingsComponent implements OnInit {
  user: User;

  readonly ICON_USER = ICON_USER;
  readonly ICON_EMAIL = ICON_EMAIL;
  readonly ICON_CHEVRON_DOWN = ICON_CHEVRON_DOWN;

  isLoading = false;
  form = new FormGroup({
    userName: new FormControl<string>("", Validators.required),
    email: new FormControl<string>("", [Validators.required, Validators.email]),
    defaultCurrency: new FormControl<Currency>(Currency.EUR),
    locale: new FormControl<string>("", Validators.required),
  });

  locales: object = {
    "de-DE": "German (DE)",
    "de-CH": "German (CH)",
    "en-US": "English (US)",
    "en-GB": "English (GB)",
    "en-CA": "English (CA)",
    "en-AU": "English (AU)",
    "es-ES": "Spanish (ES)",
    "fr-FR": "French (FR)",
    "fr-CA": "French (CA)",
    "fr-CH": "French (CH)",
    "it-IT": "Italian (IT)",
    "it-CH": "Italian (CH)",
    "ja-JP": "Japanese (JP)",
  };

  constructor(
    private authService: AuthService,
    public currencyService: CurrencyService,
    private modalService: BsModalService,
    private toasterService: ToastrService,
  ) {}

  ngOnInit(): void {
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.user = { ...user };
      this.form.patchValue(user);
    });
  }

  saveUser(): void {
    if (this.form.invalid) return;
    this.isLoading = true;

    const updateRequest: UpdateUserRequest = {};
    updateRequest.email = this.form.controls.email.value;
    updateRequest.userName = this.form.controls.userName.value;
    updateRequest.locale = this.form.controls.locale.value;
    updateRequest.defaultCurrency = this.form.controls.defaultCurrency.value;

    this.authService.updateUser(
      updateRequest,
      () => {
        this.toasterService.success("User settings successfully updated");
        this.isLoading = false;
      },
      (error: string) => {
        this.isLoading = false;
        this.toasterService.error("Error updating user settings: " + error, "User settings update error");
      },
    );
  }

  dateExample(locale: string) {
    return formatDate(Date.now(), "shortDate", locale);
  }

  getInitials(): string {
    return this.user?.userName?.charAt(0).toUpperCase();
  }

  changePassword() {
    const modalOptions: ModalOptions = {
      initialState: {
        user: this.user,
      },
      class: "modal-primary",
    };
    this.modalService.show(ChangePasswordComponent, modalOptions);
  }

  readonly Currency = Currency;
}
