import { Marketplace } from '../api-client/models/Marketplace';
import { Currency } from '../api-client/models/Currency';
import { currencyRate } from '../stats-api-client.service';
import { CurrencyStat } from './CurrencyStat';

export interface OrderStats extends CurrencyStat {
  globalSales: number;
  tax: number;
  promotion: number;
  shippingGiftWrap: number;
  fee: number;
  advertising: number;
  costOfGoods?: number;
  profit?: number;

  // dim
  marketplace?: Marketplace;
  sku?: string;
  asin?: string;
  currency?: Currency;
  date?: string;

  // detailed stats

  // globalSales
  sales?: number;
  refunds?: number;
  reimbursement?: number;
  reimbursementClawback?: number;

  // globalSales quantities
  quantity?: number;
  refunded?: number;
  chargebackRefunded?: number;
  a2zGuaranteeRefunded?: number;
  orderReturnRefunded?: number;
  replacementRefunded?: number;
  sellableReturns?: number;
  amazonVine?: number;

  // tax
  baseTax?: number;
  shippingTax?: number;
  giftWrapTax?: number;
  otherTax?: number;
  taxDiscount?: number;

  // promotion
  basePromotion?: number;
  giftWrapPromotion?: number;
  shippingPromotion?: number;
  otherPromotion?: number;

  // shippingGiftWrap
  shipping?: number;
  shippingHB?: number;
  shippingChargeBack?: number;
  giftWrap?: number;
  giftWrapChargeBack?: number;

  // FEES
  // commissions
  referralFee?: number;
  // fba fulfillment fee
  fbaFee?: number;
  giftWrapFee?: number;
  returnFees?: number;
  feeAdjustment?: number;
  fbaStorageFee?: number;
  exportCharge?: number;

  // from global settlement fees
  longTermStorageFee?: number;
  liquidations?: number;
  internationalFreight?: number;

  otherFee?: number;

  // advertising
  spAdvertising?: number;
  sbAdvertising?: number;
  sdAdvertising?: number;
}

export function emptyOrderStat(asin: string, date: string, currency: Currency): OrderStats {
  return {
    globalSales: 0,
    tax: 0,
    promotion: 0,
    shippingGiftWrap: 0,
    fee: 0,
    advertising: 0,
    asin: asin,
    date: date,
    profit: 0,
    fbaStorageFee: 0,
    spAdvertising: 0,
    sbAdvertising: 0,
    sdAdvertising: 0,
    currency: currency,
  };
}

function sum(a?: number, b?: number): number {
  return a ? (b ? a + b : a) : (b as number);
}

export function sumOrderStat(orderStat: OrderStats, other: OrderStats): OrderStats {
  const sumRes: OrderStats = emptyOrderStat(orderStat.asin!, orderStat.date!, orderStat.currency! ?? other.currency);

  if (orderStat.currency && other.currency && orderStat.currency != other.currency) {
    throw "Can't merge orders with different currency " + orderStat.currency + '!=' + other.currency;
  }

  sumRes.globalSales = sum(orderStat.globalSales, other.globalSales);
  sumRes.tax = sum(orderStat.tax, other.tax);
  sumRes.promotion = sum(orderStat.promotion, other.promotion);
  sumRes.shippingGiftWrap = sum(orderStat.shippingGiftWrap, other.shippingGiftWrap);
  sumRes.fee = sum(orderStat.fee, other.fee);
  sumRes.advertising = sum(orderStat.advertising, other.advertising);

  sumRes.sales = sum(orderStat.sales, other.sales);
  sumRes.refunds = sum(orderStat.refunds, other.refunds);
  sumRes.refunded = sum(orderStat.refunded, other.refunded);
  sumRes.chargebackRefunded = sum(orderStat.chargebackRefunded, other.chargebackRefunded);
  sumRes.a2zGuaranteeRefunded = sum(orderStat.a2zGuaranteeRefunded, other.a2zGuaranteeRefunded);
  sumRes.orderReturnRefunded = sum(orderStat.orderReturnRefunded, other.orderReturnRefunded);
  sumRes.replacementRefunded = sum(orderStat.replacementRefunded, other.replacementRefunded);
  sumRes.sellableReturns = sum(orderStat.sellableReturns, other.sellableReturns);
  sumRes.amazonVine = sum(orderStat.amazonVine, other.amazonVine);

  sumRes.baseTax = sum(orderStat.baseTax, other.baseTax);
  sumRes.shippingTax = sum(orderStat.shippingTax, other.shippingTax);
  sumRes.giftWrapTax = sum(orderStat.giftWrapTax, other.giftWrapTax);
  sumRes.otherTax = sum(orderStat.otherTax, other.otherTax);
  sumRes.taxDiscount = sum(orderStat.taxDiscount, other.taxDiscount);

  sumRes.basePromotion = sum(orderStat.basePromotion, other.basePromotion);
  sumRes.giftWrapPromotion = sum(orderStat.giftWrapPromotion, other.giftWrapPromotion);
  sumRes.shippingPromotion = sum(orderStat.shippingPromotion, other.shippingPromotion);
  sumRes.otherPromotion = sum(orderStat.otherPromotion, other.otherPromotion);

  sumRes.shipping = sum(orderStat.shipping, other.shipping);
  sumRes.shippingHB = sum(orderStat.shippingHB, other.shippingHB);
  sumRes.shippingChargeBack = sum(orderStat.shippingChargeBack, other.shippingChargeBack);
  sumRes.giftWrap = sum(orderStat.giftWrap, other.giftWrap);
  sumRes.giftWrapChargeBack = sum(orderStat.giftWrapChargeBack, other.giftWrapChargeBack);

  sumRes.reimbursement = sum(orderStat.reimbursement, other.reimbursement);
  sumRes.reimbursementClawback = sum(orderStat.reimbursementClawback, other.reimbursementClawback);

  sumRes.referralFee = sum(orderStat.referralFee, other.referralFee);
  sumRes.fbaFee = sum(orderStat.fbaFee, other.fbaFee);
  sumRes.liquidations = sum(orderStat.liquidations, other.liquidations);
  sumRes.giftWrapFee = sum(orderStat.giftWrapFee, other.giftWrapFee);
  sumRes.otherFee = sum(orderStat.otherFee, other.otherFee);
  sumRes.fbaStorageFee = sum(orderStat.fbaStorageFee, other.fbaStorageFee);
  sumRes.feeAdjustment = sum(orderStat.feeAdjustment, other.feeAdjustment);
  sumRes.returnFees = sum(orderStat.returnFees, other.returnFees);

  sumRes.spAdvertising = sum(orderStat.spAdvertising, other.spAdvertising);
  sumRes.sbAdvertising = sum(orderStat.sbAdvertising, other.sbAdvertising);
  sumRes.sdAdvertising = sum(orderStat.sdAdvertising, other.sdAdvertising);

  sumRes.exportCharge = sum(orderStat.exportCharge, other.exportCharge);

  sumRes.quantity = sum(orderStat.quantity, other.quantity);

  sumRes.costOfGoods = sum(orderStat.costOfGoods, other.costOfGoods);

  sumRes.profit = sum(orderStat.profit, other.profit);

  sumRes.currency = orderStat.currency;
  sumRes.marketplace = orderStat.marketplace;

  return sumRes;
}

export function currencyRateOrderStat(orderStat: OrderStats, currency: Currency) {
  const rate = currencyRate(orderStat, currency);
  orderStat.currency = currency;

  orderStat.globalSales *= rate;
  orderStat.tax *= rate;
  orderStat.promotion *= rate;
  orderStat.shippingGiftWrap *= rate;
  orderStat.fee *= rate;
  orderStat.advertising *= rate;

  orderStat.sales! *= rate;
  orderStat.refunded! *= rate;

  orderStat.baseTax! *= rate;
  orderStat.shippingTax! *= rate;
  orderStat.giftWrapTax! *= rate;
  orderStat.otherTax! *= rate;
  orderStat.taxDiscount! *= rate;

  orderStat.basePromotion! *= rate;
  orderStat.giftWrapPromotion! *= rate;
  orderStat.shippingPromotion! *= rate;
  orderStat.otherPromotion! *= rate;

  orderStat.shipping! *= rate;
  orderStat.shippingHB! *= rate;
  orderStat.shippingChargeBack! *= rate;
  orderStat.giftWrap! *= rate;
  orderStat.giftWrapChargeBack! *= rate;

  orderStat.referralFee! *= rate;
  orderStat.fbaFee! *= rate;
  orderStat.giftWrapFee! *= rate;
  orderStat.otherFee! *= rate;
  orderStat.feeAdjustment! *= rate;
  orderStat.liquidations! *= rate;
  orderStat.returnFees! *= rate;

  orderStat.reimbursement! *= rate;
  orderStat.reimbursementClawback! *= rate;

  orderStat.spAdvertising! *= rate;
  orderStat.sbAdvertising! *= rate;
  orderStat.sdAdvertising! *= rate;

  orderStat.exportCharge! *= rate;

  orderStat.costOfGoods! *= rate;

  orderStat.profit! *= rate;
}
