import { BehaviorSubject, combineLatest, map, Observable, ReplaySubject, Subject, switchMap } from 'rxjs';
import { AdStatsEx, SbCreativeEx, SegmentEx, StrategyEx, StrategyGroupEx } from './models';
import {
  AccountMarketplace,
  AccountType,
  AlgoMode,
  CampaignType,
  EntityIdType,
  History,
  HistoryActionEnum,
  HistoryApi,
  Marketplace,
  StatsApi,
  Strategy,
  StrategyApi,
  StrategyGroup,
  StrategyGroupApi,
  StrategyType,
} from './api-client';
import { AccountSelectionService } from './accountSelection.service';
import { Utils } from './utils';
import moment from 'moment';
import { StrategyCacheReloaded } from './strategyCacheReloaded';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StrategyCache {
  private readonly reload$: Subject<void> = new Subject();
  private selectedAccountMarketplace: AccountMarketplace | undefined = undefined;

  private readonly strategyGroupIndex: Map<number, StrategyGroupEx> = new Map();
  public readonly strategyCurrentMonthSpendIndex: Map<number, number> = new Map();

  private readonly strategyQueue: Map<number, Subject<StrategyEx>> = new Map();

  private strategyIndex: Map<number, StrategyEx> = new Map();
  private readonly strategyIndexSubject: BehaviorSubject<Map<number, StrategyEx>> = new BehaviorSubject(new Map());
  public readonly strategyIndex$: Observable<Map<number, StrategyEx>> = this.strategyIndexSubject.asObservable();

  private sbCreativeIndexByStrategyId: Map<number, SbCreativeEx[]> = new Map();

  private segmentIndex: Map<number, SegmentEx> = new Map();

  private readonly strategyGroupIndexSubject: ReplaySubject<Map<number, StrategyGroupEx>> = new ReplaySubject(1);
  public readonly strategyGroupIndex$: Observable<Map<number, StrategyGroupEx>> =
    this.strategyGroupIndexSubject.asObservable();

  public readonly strategiesBySegmentId$: Observable<Map<number, StrategyEx[]>> = this.strategyIndex$.pipe(
    map((x) => {
      const index = new Map();
      for (const s of x.values()) {
        for (const t of s.tactics) {
          if (!index.has(t.segmentId)) {
            index.set(t.segmentId, new Array<StrategyEx>());
          }
          index.get(t.segmentId).push(s);
        }
      }
      return index;
    }),
  );

  constructor(
    accountSelectionService: AccountSelectionService,
    private strategyApi: StrategyApi,
    private historyService: HistoryApi,
    private statsService: StatsApi,
    private strategyCacheReloaded: StrategyCacheReloaded,
    private strategyGroupService: StrategyGroupApi,
  ) {
    this.reload$
      .pipe(
        switchMap(() => {
          const accountId = this.selectedAccountMarketplace!.accountId;
          const marketplace = this.selectedAccountMarketplace!.marketplace;

          return combineLatest([
            this.strategyCacheReloaded.segmentIndexReloaded,
            this.getAllStrategiesAsync(accountId, marketplace),
            this.getAllStrategyConfigHistory(accountId, marketplace),
            this.getAllStrategyCurrentMonthSpend(accountId, marketplace),
            this.getAllStrategyGroups(accountId, marketplace),
            this.strategyCacheReloaded.sbCreativeIndexReloaded,
          ]);
        }),
      )
      .subscribe(this.reload);

    accountSelectionService.singleAccountMarketplaceSelection$.subscribe((x) => {
      this.selectedAccountMarketplace = x;
      this.reload$.next();
    });
  }

  public reloadCache() {
    this.reload$.next();
  }

  private getAllStrategiesAsync(accountId: string, marketplace: Marketplace): Observable<Strategy[]> {
    return this.strategyApi.listStrategies({ accountId: accountId, marketplace: marketplace });
  }

  private getAllStrategyConfigHistory(accountId: string, marketplace: Marketplace): Observable<Strategy[]> {
    return this.statsService.getStrategyConfigurationHistory({
      accountId: accountId,
      marketplace: marketplace,
      minDate: Utils.formatDateForApiFromToday(-1),
      maxDate: Utils.formatDateForApiFromToday(0),
    });
  }

  private getAllStrategyCurrentMonthSpend(accountId: string, marketplace: Marketplace): Observable<AdStatsEx[]> {
    const now = Utils.getNow(marketplace);
    const m = moment(now.format('YYYY-MM-DD'));
    return this.statsService.getStrategySpend({
      accountId: accountId,
      marketplace: marketplace,
      minDate: Utils.formatDateForApi(m.startOf('month').toDate()),
      maxDate: Utils.formatDateForApi(m.endOf('month').toDate()),
    });
  }

  private getAllStrategyGroups(accountId: string, marketplace: Marketplace): Observable<StrategyGroup[]> {
    return this.strategyGroupService.listStrategyGroup({
      accountId: accountId,
      marketplace: marketplace,
    });
  }

  private reload = ([
    segmentIndex,
    strategies,
    strategiesHistory,
    strategiesCurrentMonthSpend,
    strategyGroups,
    sbCreativeIndexById, // sbCreativeIndexById is required by buildAccountConfig called by reloadStrategyIndex
  ]: [Map<number, SegmentEx>, Strategy[], Strategy[], AdStatsEx[], StrategyGroup[], Map<number, SbCreativeEx>]) => {
    sbCreativeIndexById.get(0); // to ensure sbCreativeIndexById is loaded after build optimization
    this.updateAllSegments(segmentIndex);

    this.strategyCurrentMonthSpendIndex.clear();
    for (const strategySpend of strategiesCurrentMonthSpend) {
      this.strategyCurrentMonthSpendIndex.set(strategySpend.strategyId!, strategySpend.cost!);
    }

    const strategyIndex: Map<number, StrategyEx> = new Map();

    const strategiesPerStrategyGroup = new Map<number, StrategyEx[]>();
    for (const strategy of strategies) {
      const strategyEx = new StrategyEx(
        strategy,
        this.getStrategySegmentAsMap(strategy),
        this.getCreativesMap(strategy.strategyId!),
        this.strategyCurrentMonthSpendIndex,
      );
      // do not display all other products strategies for vendor
      if (this.selectedAccountMarketplace!.accountType === AccountType.VENDOR && strategyEx.isAllOtherProduct()) {
        continue;
      }
      strategyIndex.set(strategy.strategyId!, strategyEx);
      // build SP strategyGroup <-> strategy map
      if (strategy.strategyGroupId) {
        if (strategiesPerStrategyGroup.has(strategy.strategyGroupId)) {
          strategiesPerStrategyGroup.get(strategy.strategyGroupId!)!.push(strategyEx);
        } else {
          strategiesPerStrategyGroup.set(strategy.strategyGroupId, [strategyEx]);
        }
      }
    }

    for (const strategy of strategiesHistory) {
      const s = strategyIndex.get(strategy.strategyId!);
      if (s) s.constraint = strategy.constraint;
    }
    for (const strategySpend of strategiesCurrentMonthSpend) {
      const s = strategyIndex.get(strategySpend.strategyId!);
      if (s) s.currentMonthSpend = strategySpend.cost;
    }
    this.strategyGroupIndex.clear();
    for (const strategyGroup of strategyGroups) {
      const strategyGroupEx = this.buildStrategyGroupEx(
        strategyGroup,
        strategiesPerStrategyGroup.get(strategyGroup.strategyGroupId!) ?? [],
      );
      this.strategyGroupIndex.set(strategyGroup.strategyGroupId!, strategyGroupEx);
    }
    this.strategyGroupIndexSubject.next(this.strategyGroupIndex);

    this.reloadStrategyIndex(strategyIndex);
  };

  public buildStrategyGroupEx(strategyGroup: StrategyGroup, strategies: StrategyEx[]): StrategyGroupEx {
    const strategyPerTypes: StrategyGroupEx = strategies.reduce(
      (acc: StrategyGroupEx, s) => {
        switch (s.strategyType) {
          case StrategyType.PRODUCT:
            acc.asins.push(...s.asins.map((p) => p.asin!)!);
            acc.productStrategies.push(s);
            break;
          case StrategyType.BRAND:
            acc.brandStrategies.push(s);
            break;
          case StrategyType.KEYWORD:
            acc.keywordStrategies.push(s);
            break;
        }
        return acc;
      },
      { asins: [], productStrategies: [], brandStrategies: [], keywordStrategies: [] } as unknown as StrategyGroupEx,
    ) as StrategyGroupEx;
    // sort brand and keyword strategies by priority
    strategyPerTypes.brandStrategies.sort((s1, s2) => s1.priority! - s2.priority!);
    strategyPerTypes.keywordStrategies.sort((s1, s2) => s1.priority! - s2.priority!);
    return { ...strategyGroup, strategies, ...strategyPerTypes };
  }

  // Strategies

  public getStrategyById(strategyId: number): Observable<StrategyEx> {
    let subject = this.strategyQueue.get(strategyId);
    if (subject) return subject;
    const strategy = this.strategyIndex.get(strategyId);
    if (strategy) {
      subject = new BehaviorSubject<StrategyEx>(strategy);
    } else {
      subject = new ReplaySubject<StrategyEx>();
    }
    this.strategyQueue.set(strategyId, subject);
    return subject;
  }

  public getAllStrategies(): StrategyEx[] {
    return Array.from(this.strategyIndex.values());
  }

  public get(strategyId: number): StrategyEx | undefined {
    return this.strategyIndex.get(strategyId);
  }

  public reloadStrategyIndex(strategyIndex: Map<number, StrategyEx>) {
    this.strategyIndex = strategyIndex;
    this.buildAccountConfig();
  }

  public updateStrategy(strategy: StrategyEx) {
    this.strategyIndex.set(strategy.strategyId!, strategy);
    this.buildAccountConfig();
  }

  public updateStrategies(strategies: StrategyEx[]) {
    for (const strategy of strategies) {
      this.strategyIndex.set(strategy.strategyId, strategy);
    }
    this.buildAccountConfig();
  }

  public deleteStrategies(strategies: StrategyEx[]) {
    for (const strategy of strategies) {
      this.strategyIndex.delete(strategy.strategyId);
    }
    this.buildAccountConfig();
  }

  public getStrategySegmentAsMap(strategy: Strategy): Map<number, SegmentEx> {
    const res = new Map<number, SegmentEx>();
    if (!strategy.tactics) return res;
    for (const tactic of strategy.tactics) {
      const segment = this.segmentIndex.get(tactic.segmentId!);
      if (segment) {
        res.set(tactic.segmentId!, segment);
      }
    }
    return res;
  }

  public updateAllSegments(segmentIndex: Map<number, SegmentEx>) {
    this.segmentIndex = segmentIndex;
  }

  // Creatives
  public getCreatives(strategyId: number): SbCreativeEx[] {
    return this.sbCreativeIndexByStrategyId.get(strategyId) ?? [];
  }

  public getCreativesMap(strategyId: number): Map<number, SbCreativeEx[]> {
    const res = new Map<number, SbCreativeEx[]>();
    res.set(strategyId, this.sbCreativeIndexByStrategyId.get(strategyId) ?? []);
    return res;
  }

  // when all creatives are reloaded, buildAccountConfig will be called after to reload all strategies
  // => it's too early to call buildAccountConfig
  public updateAllCreatives(sbCreativeIndexByStrategyId: Map<number, SbCreativeEx[]>) {
    this.sbCreativeIndexByStrategyId = sbCreativeIndexByStrategyId;
  }

  public updateStrategyCreatives(strategyId: number, creatives: SbCreativeEx[]) {
    this.sbCreativeIndexByStrategyId.set(strategyId, creatives);
    this.buildAccountConfig();
  }

  public addStrategyCreative(strategyId: number, creative: SbCreativeEx) {
    if (this.sbCreativeIndexByStrategyId.has(strategyId)) {
      this.sbCreativeIndexByStrategyId.get(strategyId)?.push(creative);
    } else {
      this.sbCreativeIndexByStrategyId.set(strategyId, [creative]);
    }
    this.buildAccountConfig();
  }

  // strategyGroup

  public hasStrategyGroupIndex(strategyGroupId: number): boolean {
    return this.strategyGroupIndex.has(strategyGroupId);
  }

  public getStrategyGroupIndex(strategyGroupId: number): StrategyGroupEx | undefined {
    return this.strategyGroupIndex.get(strategyGroupId);
  }

  public setStrategyGroupIndex(strategyGroup: StrategyGroupEx) {
    this.strategyGroupIndex.set(strategyGroup.strategyGroupId!, strategyGroup as StrategyGroupEx);
    this.strategyGroupIndexSubjectNext();
  }

  public deleteStrategyGroupIndex(strategyGroupId: number) {
    this.strategyGroupIndex.delete(strategyGroupId!);
    this.strategyGroupIndexSubjectNext();
  }

  public strategyGroupIndexSubjectNext() {
    this.strategyGroupIndexSubject.next(this.strategyGroupIndex);
  }

  // reload strategies index

  public buildAccountConfig() {
    this.strategyIndexSubject.next(this.strategyIndex);
    // update unique value on SP all other product strategy
    const spStrategies = this.getAllStrategies().filter((x) => x.campaignType == CampaignType.SP);
    if (spStrategies.length === 1) {
      spStrategies[0].unique = true;
    }

    this.strategyIndex.forEach((strategy: StrategyEx) => {
      strategy.tactics.forEach((tactic) => {
        tactic.segment = this.segmentIndex.get(tactic.segmentId)!;
      });
      strategy.sbCreatives = this.sbCreativeIndexByStrategyId.get(strategy.strategyId) ?? [];
    });

    this.strategyQueue.forEach((subject: Subject<StrategyEx>, strategyId: number) => {
      const strategy = this.strategyIndex.get(strategyId)!;
      subject.next(strategy);
    });
  }
}
