import { Component, OnInit, signal } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  AccountSelectionService,
  AccountType,
  BrandAsset,
  CampaignType,
  MediaType,
  SbCreativeEx,
  SbCreativeType,
  StrategyAsin,
  StrategyEx,
} from "@front/m19-services";
import { Option } from "@front/m19-ui";
import { FormMode, SbStrategyFormComponent } from "@m19-board/strategies/sb-strategy-form/sb-strategy-form.component";
import { ICON_ADD } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Constant } from "libs/m19-services/src/lib/m19-services/constant";
import { SbStrategiesService } from "libs/m19-services/src/lib/m19-services/sb-strategies.service";
import { StrategyCache } from "libs/m19-services/src/lib/m19-services/strategy.cache";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { Observable } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";

@UntilDestroy()
@Component({
  selector: "app-sb-page",
  templateUrl: "./sb-page.component.html",
})
export class SbPageComponent implements OnInit {
  strategy$: Observable<StrategyEx>;

  adLinesVisible = true;

  strategy: StrategyEx;

  allCreatives: SbCreativeEx[] = [];
  allCreativesFiltered: SbCreativeEx[] = [];

  creativesProduct: SbCreativeEx[] = [];
  creativesVideo: SbCreativeEx[] = [];
  creativesBrandVideo: SbCreativeEx[] = [];
  creativesSpotlight: SbCreativeEx[] = [];
  accountType: AccountType;
  isReadOnly = false;
  creativesWithoutCustomImage = 0;
  targetingStatsVisible = false;

  readonly customImages$: Observable<BrandAsset[]> = this.sbStrategiesService.brandAssets$.pipe(
    map((assets: BrandAsset[]) => assets.filter((b) => !b.deleted && b.mediaType === MediaType.customImage)),
  );

  readonly ICON_ADD = ICON_ADD;

  readonly creativeTypeFilterOptions: Option<SbCreativeType>[] = [
    {
      label: "Product Collection",
      value: SbCreativeType.productCollection,
    },
    {
      label: "Video",
      value: SbCreativeType.video,
    },
    {
      label: "Brand Video",
      value: SbCreativeType.brandVideo,
    },
    {
      label: "Store Spotlight",
      value: SbCreativeType.storeSpotlight,
    },
  ];
  selectedCreativeTypes = signal<Option<SbCreativeType>[]>([]);

  constructor(
    private route: ActivatedRoute,
    private strategyCache: StrategyCache,
    private sbStrategiesService: SbStrategiesService,
    private modalService: BsModalService,
    private accountSelectionService: AccountSelectionService,
  ) {}

  ngOnInit(): void {
    this.strategy$ = this.route.paramMap.pipe(
      switchMap((params) => {
        const id = Number(params.get("id"));
        return this.strategyCache.getStrategyById(id);
      }),
      map((s) => (s && s.campaignType == CampaignType.SB ? s : undefined)),
    );
    this.strategy$
      .pipe(
        untilDestroyed(this),
        filter((s) => !s),
      )
      .subscribe((_) => {
        this.strategy = undefined;
      });
    this.strategy$
      .pipe(
        untilDestroyed(this),
        filter((s) => !!s),
      )
      .subscribe((strat) => {
        this.strategy = strat;
        if (strat && strat.sbCreatives) {
          this.creativesVideo = strat.sbCreatives.filter((x) => x.creativeType == SbCreativeType.video);
          this.creativesBrandVideo = strat.sbCreatives.filter((x) => x.creativeType == SbCreativeType.brandVideo);
          this.creativesProduct = strat.sbCreatives.filter((x) => x.creativeType == SbCreativeType.productCollection);
          this.creativesSpotlight = strat.sbCreatives.filter((x) => x.creativeType == SbCreativeType.storeSpotlight);

          this.allCreatives = this.creativesProduct
            .concat(this.creativesVideo)
            .concat(this.creativesBrandVideo)
            .concat(this.creativesSpotlight);
          this.allCreativesFiltered = this.allCreatives;

          if (!this.strategy.brandEntityId) {
            // old Sb strategies have no brandEntityId at strategy level
            // try to retrieve it from creatives when all creatives have the same brandEntityId
            // to prevent client to mix several brandEntityIds in the same strategy (not compatible with new sb structure)
            const brandEntityIds = new Set(strat.sbCreatives.map((x) => x.brandEntityId).filter((x) => !!x));
            if (brandEntityIds.size == 1) {
              this.strategy.brandEntityId = [...brandEntityIds][0];
            }
          }

          this.creativesWithoutCustomImage = this.creativesProduct.filter((c) => !c.customImage).length;
        }
      });
    this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(untilDestroyed(this)).subscribe((am) => {
      this.accountType = am.accountType;
    });

    this.accountSelectionService.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((b) => (this.isReadOnly = b));
  }

  toggleTargetingStatsVisibility() {
    this.targetingStatsVisible = !this.targetingStatsVisible;
  }

  newCreative() {
    if (!this.canCreateNewCreative()) return;

    const modalOptions: ModalOptions = {
      initialState: {
        strategy: this.strategy,
        formMode: FormMode.NEW_CREATIVE,
      },
      class: "modal-dialog-centered big-modal modal-limit",
      keyboard: false,
      backdrop: "static",
    };
    this.modalService.show(SbStrategyFormComponent, modalOptions);
  }

  creativesLimitSoonReached() {
    const creativesCount = this.creativeCount();
    return (
      creativesCount >= Constant.maxCreativesPerStrategy * 0.8 && creativesCount < Constant.maxCreativesPerStrategy
    );
  }

  creativeCount() {
    return (
      this.creativesVideo.length +
      this.creativesProduct.length +
      this.creativesSpotlight.length +
      this.creativesBrandVideo.length
    );
  }

  creativesLimitReached() {
    return this.creativeCount() >= Constant.maxCreativesPerStrategy;
  }

  canCreateNewCreative(): boolean {
    return !this.creativesLimitReached();
  }

  getAsins(strategyAsin: StrategyAsin): string {
    return strategyAsin.asin;
  }

  applyCreativeTypeFilter(values: Option<SbCreativeType>[]) {
    this.selectedCreativeTypes.set(values);
    if (!values?.length) this.allCreativesFiltered = this.allCreatives;
    else this.allCreativesFiltered = this.allCreatives.filter((c) => values.some((v) => v.value === c.creativeType));
  }

  protected readonly SbCreativeType = SbCreativeType;
  protected readonly CampaignType = CampaignType;
}
