// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface UserAllOf
 */
export interface UserAllOf {
    /**
     * @type {number}
     * @memberof UserAllOf
     */
    userId: number;
    /**
     * @type {string}
     * @memberof UserAllOf
     */
    locale: string;
    /**
     * @type {number}
     * @memberof UserAllOf
     */
    ownedOrganizationId?: number;
    /**
     * @type {boolean}
     * @memberof UserAllOf
     */
    isAdmin?: boolean;
    /**
     * @type {string}
     * @memberof UserAllOf
     */
    userName?: string;
    /**
     * @type {string}
     * @memberof UserAllOf
     */
    board?: string;
    /**
     * @type {boolean}
     * @memberof UserAllOf
     */
    allowWhiteLabel?: boolean;
    /**
     * @type {boolean}
     * @memberof UserAllOf
     */
    allowCustomCampaignName?: boolean;
    /**
     * @type {boolean}
     * @memberof UserAllOf
     */
    betaTester?: boolean;
    /**
     * @type {string}
     * @memberof UserAllOf
     */
    currentPassword?: string;
    /**
     * @type {boolean}
     * @memberof UserAllOf
     */
    passwordSet?: boolean;
    /**
     * @type {string}
     * @memberof UserAllOf
     */
    creationTime?: string;
    /**
     * @type {number}
     * @memberof UserAllOf
     */
    uiVersion: number;
}
