import { Component, inject, OnInit, signal } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AuthService, User } from "@front/m19-services";
import { environment } from "../../../environments/environment";
import { ToastrService } from "ngx-toastr";
import { switchMap } from "rxjs/operators";
import { ResetPasswordFormValue } from "@front/m19-ui";
import { throwError } from "rxjs";

@UntilDestroy()
@Component({
  selector: "app-password-reset",
  templateUrl: "password-reset.component.html",
})
export class PasswordResetComponent implements OnInit {
  private readonly authService = inject(AuthService);
  private readonly toasterService = inject(ToastrService);
  private readonly router = inject(Router);
  private readonly route = inject(ActivatedRoute);

  init = signal(true);
  loading = signal(false);
  success = signal(false);
  error = signal<string | null>(null);
  user = signal<User | null | undefined>(undefined);

  isTest = !environment.production;

  ngOnInit(): void {
    const token = this.route.snapshot.queryParamMap.get("token");
    if (!token) {
      this.init.set(false);
      return;
    }
    this.authService.setAccessToken(token, 3600, false);
    this.authService
      .isLoggedIn()
      .pipe(
        switchMap((loggedIn) => {
          if (!loggedIn) {
            this.init.set(true);
            return throwError(() => {});
          }
          return this.authService.loggedUser$;
        }),
        untilDestroyed(this),
      )
      .subscribe({
        next: (user) => {
          this.init.set(true);
          if (!user) {
            return;
          }
          this.user.set({ ...user });
        },
        error: () => {
          this.user.set(null);
          this.init.set(true);
        },
      });
  }

  requestPasswordReset(formValue: ResetPasswordFormValue): void {
    const user = this.user();
    if (!formValue || !user) return;
    this.loading.set(true);
    user.newPassword = formValue.password;
    this.user.set(user);
    this.authService.updatePassword(
      user,
      () => {
        this.toasterService.success("Password successfully updated");
        this.authService.reloadUser(
          (_) => {
            this.router.navigate(["dashboard"]);

            this.loading.set(false);
          },
          (error: string) => {
            this.error.set("Error: " + error);
            this.loading.set(false);
          },
        );
      },
      (error: string) => {
        this.error.set("Error updating password: " + error);
        this.loading.set(false);
      },
    );
  }
}
