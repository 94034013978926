import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  AccountSelectionService,
  AsinService,
  CampaignType,
  Catalog,
  ConfigService,
  SegmentEx,
  StrategyAsin,
  StrategyEx,
  StrategyTacticEx,
  TacticType,
} from "@front/m19-services";
import { MoveStrategyAsinsModalComponent } from "@m19-board/strategies/strategy-asins/move-strategy-asins-modal.component";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Observable, Subscription } from "rxjs";
import { AjaxError } from "rxjs/ajax";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { SdStrategyCreationComponent } from "../sd-manager/sd-strategy-creation/sd-strategy-creation.component";
import { SdTargetingAddPopupComponent } from "../sd-targeting/sd-targeting-add-popup.component";
import { ProductSegmentModalComponent } from "@m19-board/segments/product-segment-modal.component";
import { ICON_ADD } from "@m19-board/utils/iconsLabels";
import { StrategyCache } from "libs/m19-services/src/lib/m19-services/strategy.cache";

@Component({
  selector: "app-sd-page",
  templateUrl: "./sd-page.component.html",
})
@UntilDestroy()
export class SdPageComponent implements OnInit {
  strategy$: Observable<StrategyEx>;
  strategy: StrategyEx;
  asinsVisible = true;
  targetingsVisible = true;
  asinsList: StrategyAsin[] = [];
  ineligibleAsins: string[] = [];
  asinEligibility: Map<string, { status: boolean; reason: string }> = new Map();
  isReadOnly = false;
  isLastTargeting = false;
  ineligibleAsinsCollapsed: boolean;
  brands: Set<string> = new Set();

  readonly faPlus = faPlusCircle;
  readonly ICON_ADD = ICON_ADD;
  readonly CampaignType = CampaignType;

  @ViewChild("productAdd") productAdd: TemplateRef<any>;
  productModalRef: BsModalRef;

  constructor(
    private route: ActivatedRoute,
    private strategyCache: StrategyCache,
    private configurationService: ConfigService,
    private toasterService: ToastrService,
    private asinService: AsinService,
    private modalService: BsModalService,
    private toastrService: ToastrService,
    private accountSelectionService: AccountSelectionService,
  ) {}

  ngOnInit() {
    this.ineligibleAsinsCollapsed = true;
    this.strategy$ = this.route.paramMap.pipe(
      switchMap((params) => {
        const id = Number(params.get("id"));
        return this.strategyCache.getStrategyById(id);
      }),
      map((s) => (s && s.campaignType == CampaignType.SD ? s : undefined)),
    );
    this.strategy$
      .pipe(
        untilDestroyed(this),
        filter((s) => !s),
      )
      .subscribe((_) => {
        this.strategy = undefined;
        this.asinsList = undefined;
      });
    this.strategy$
      .pipe(
        untilDestroyed(this),
        filter((s) => !!s),
        tap((s) => {
          this.strategy = s;
          const nbTargetings =
            (this.strategy.autoAlgoExplorationEnabled ? 1 : 0) +
            this.strategy.tactics.filter((x) => x.tacticType == TacticType.LEGACY).length +
            this.strategy.audienceTargetings.length;

          this.isLastTargeting = nbTargetings <= 1;
        }),
        switchMap((s) => this.configurationService.getStrategyAsins(s)),
      )
      .subscribe((al) => {
        this.asinsList = al;
      });
    this.strategy$
      .pipe(
        filter((s) => !!s),
        switchMap((strategy) => this.asinService.getCatalog(strategy.accountId, strategy.marketplace)),
        untilDestroyed(this),
      )
      .subscribe((catalog: Catalog) => {
        this.ineligibleAsins = [];
        this.brands.clear();
        if (catalog) {
          this.asinEligibility = catalog.getSPEligibility();

          if (this.strategy.asins)
            this.strategy.asins.forEach((item) => {
              if (!this.asinEligibility.has(item.asin) || this.asinEligibility.get(item.asin).status == false)
                this.ineligibleAsins.push(item.asin);
              if (catalog.asinOffers.has(item.asin)) {
                const brand = catalog.asinOffers.get(item.asin)?.brand;
                if (brand) this.brands.add(brand);
              }
            });
        }
      });

    this.accountSelectionService.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((b) => (this.isReadOnly = b));
  }

  filterBlacklist(tactics: Array<StrategyTacticEx>, isBlacklist: boolean) {
    return tactics.filter((x) =>
      isBlacklist ? x.tacticType === TacticType.BLACKLIST : x.tacticType !== TacticType.BLACKLIST,
    );
  }

  toggleAsinsVisibility() {
    this.asinsVisible = !this.asinsVisible;
  }

  toggleProductAdd() {
    this.productModalRef = this.modalService.show(this.productAdd, {
      class: "modal-xl modal-primary modal-dialog-centered",
    });
  }

  private prettyPrintAsinList(asins: string[]) {
    const maxAsins = 5;
    let message = asins.slice(0, maxAsins).join(", ");
    if (asins.length > maxAsins) {
      message += "…";
    }
    return message;
  }

  deleteStrategyAsins(toDelete: StrategyAsin[]) {
    this.configurationService
      .deleteAsinsFromStrategy(
        this.strategy,
        toDelete.map((a) => a.asin),
      )
      .subscribe(
        () => {
          const toDeleteMsg = this.prettyPrintAsinList(toDelete.map((a) => a.asin));
          this.toasterService.success(
            `ASIN${
              toDelete.length > 1 ? "s" : ""
            } ${toDeleteMsg} removed from SD strategy "${this.strategy.getName()}"`,
            "Strategy ASIN updated",
          );
        },
        (error: AjaxError) => {
          const toDeleteMsg = this.prettyPrintAsinList(toDelete.map((a) => a.asin));
          this.toasterService.error(
            `Error removing Strategy ASIN${toDelete.length > 1 ? "s" : ""} ${toDeleteMsg}: ${
              error.response ? error.response.message : error.message
            }`,
            "Strategy ASIN update error",
          );
        },
      );
  }

  moveStrategyAsins(toMove: StrategyAsin[]): void {
    const asinsToMove = toMove.map((sa) => sa.asin);
    const modalOptions: ModalOptions = {
      initialState: {
        source: this.strategy,
        asinsToMove: asinsToMove,
      },
      class: "modal-primary",
    };
    const modalRef = this.modalService.show(MoveStrategyAsinsModalComponent, modalOptions);
    let creationModalRef: BsModalRef = null;

    const subscription = modalRef.content.selectedStrategy
      .pipe(switchMap((target) => this.configurationService.moveAsinsToStrategy(asinsToMove, this.strategy, target)))
      .subscribe(
        () => {
          this.toasterService.success("ASINs succesfully moved to other strategy");
          subscription.unsubscribe();
        },
        (error: string) => {
          this.toasterService.error(`Error moving ASINs to other strategy: ${error}`, "Error moving ASINS");
          subscription.unsubscribe();
        },
      );
    subscription.add(
      modalRef.content.newStrategyCreation
        .pipe(
          switchMap(() => {
            const modalOptions: ModalOptions = {
              initialState: {
                asins: toMove,
              },
              class: "modal-xl",
            };

            creationModalRef = this.modalService.show(SdStrategyCreationComponent, modalOptions);
            return creationModalRef.content.strategyCreated;
          }),
          switchMap(() => this.configurationService.deleteAsinsFromStrategy(this.strategy, asinsToMove)),
        )
        .subscribe(
          () => {
            this.toasterService.success("ASINs succesfully moved to new strategy");
            subscription.unsubscribe();
            creationModalRef.hide();
          },
          (error: string) => {
            this.toasterService.error(`Error moving ASINs to new strategy: ${error}`, "Error moving ASINS");
            subscription.unsubscribe();
            creationModalRef.hide();
          },
        ),
    );
  }

  addStrategyAsins(toAdd: StrategyAsin[]) {
    this.configurationService
      .addAsinsToStrategy(
        this.strategy,
        toAdd.map((a) => a.asin),
      )
      .subscribe(
        () => {
          const toAddMsg = this.prettyPrintAsinList(toAdd.map((a) => a.asin));
          this.toasterService.success(
            `ASIN${toAdd.length > 1 ? "s" : ""} ${toAddMsg} added to SD strategy "${this.strategy.getName()}"`,
            "Strategy ASIN updated",
          );
        },
        (error: string) => {
          const toAddMsg = this.prettyPrintAsinList(toAdd.map((a) => a.asin));
          this.toasterService.error(
            `Error adding Strategy ASIN${toAdd.length > 1 ? "s" : ""} ${toAddMsg}: ${error}`,
            "Strategy ASIN update error",
          );
        },
      );
  }

  getAsins(strategyAsin: StrategyAsin): string {
    return strategyAsin.asin;
  }

  toggleIneligibleAsinDetails() {
    this.ineligibleAsinsCollapsed = !this.ineligibleAsinsCollapsed;
  }

  getBrands(): string {
    return Array.from(this.brands).join(" - ");
  }

  createTargeting() {
    const modalOptions: ModalOptions = {
      initialState: {
        strategy: this.strategy,
      },
      class: "modal-lg",
    };
    const modalRef = this.modalService.show(SdTargetingAddPopupComponent, modalOptions);
    const subscription = modalRef.content.segmentCreationRequested
      .pipe(untilDestroyed(this))
      .subscribe((segmentCreationRequest) => {
        const segmentCreationModalOption: ModalOptions = {
          initialState: {
            accountId: this.strategy.accountId,
            marketplace: this.strategy.marketplace,
          },
          class: "modal-xl",
        };
        const segmentCreationRef = this.modalService.show(ProductSegmentModalComponent, segmentCreationModalOption);
        this.createTacticAfterSegmentCreation(
          subscription,
          segmentCreationRequest.tacticType,
          segmentCreationRef.content.segmentCreated,
          segmentCreationRef.content.segmentEditionCanceled,
        );
      });
    subscription.add(
      modalRef.content.targetingCreationCancelled
        .pipe(untilDestroyed(this))
        .subscribe(() => subscription.unsubscribe()),
    );
  }

  private createTacticAfterSegmentCreation(
    subscription: Subscription,
    tacticType: TacticType,
    segmentCreated: Observable<SegmentEx>,
    segmentCreationCancelled: Observable<void>,
  ) {
    subscription.add(
      segmentCreated
        .pipe(
          untilDestroyed(this),
          switchMap((s) => {
            return this.configurationService.addTacticToStrategyAsync(
              this.strategy.accountId,
              this.strategy.marketplace,
              this.strategy.strategyId,
              s.segmentId,
              tacticType,
            );
          }),
        )
        .subscribe({
          next: () => {
            this.toastrService.success("Targeting sucessfully added to strategy");
            subscription.unsubscribe();
          },
          error: (error) => {
            this.toastrService.error(error, "Targeting creation error");
            subscription.unsubscribe();
          },
        }),
    );
    subscription.add(segmentCreationCancelled.pipe(untilDestroyed(this)).subscribe(() => subscription.unsubscribe()));
  }
}
