import { AgGridAngular, AgGridModule } from "@ag-grid-community/angular";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule, NgOptimizedImage, registerLocaleData } from "@angular/common";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import localeDe from "@angular/common/locales/de";
import localeDeCh from "@angular/common/locales/de-CH";
import localeEnAu from "@angular/common/locales/en-AU";
import localeEnCa from "@angular/common/locales/en-CA";
import localeEnGb from "@angular/common/locales/en-GB";
import localeEs from "@angular/common/locales/es";
import localeFr from "@angular/common/locales/fr";
import localeFrCa from "@angular/common/locales/fr-CA";
import localeFrCh from "@angular/common/locales/fr-CH";
import localeIt from "@angular/common/locales/it";
import localeItCh from "@angular/common/locales/it-CH";
import localeJa from "@angular/common/locales/ja";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { ServiceWorkerModule } from "@angular/service-worker";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgChartsModule, ThemeService } from "ng2-charts";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";

// Import 3rd party components
import { ModalModule } from "ngx-bootstrap/modal";
import { NgxStripeModule } from "ngx-stripe";
import { ToastrModule, ToastrService } from "ngx-toastr";
import { AccountSelectorComponent } from "./account-selector/account-selector.component";
import { MarketplaceSelectorComponent } from "./account-selector/marketplace-selector.component";
import { SearchTrendsMarketplaceSelectorComponent } from "./account-selector/search-trends-marketplace-selector.component";
import { ActivitiesComponent } from "./activities/activities.component";
import { ActivityComponent } from "./activities/activity/activity.component";
import { CurrencySelectionComponent } from "./currency-selector/currency-selection.component";
// Import & Configure the Swagger generated API client
import { AppComponent } from "./app.component";
// Import routing module
import { AppRoutingModule } from "./app.routing";
import { SearchTermCardComponent } from "./brand-analytics/search-term-card/search-term-card.component";
import { SearchTermRanksComponent } from "./brand-analytics/search-term-ranks/search-term-ranks.component";
import { SeedsEditionModalComponent } from "./catalog/seeds/seeds-edition-modal.component";
import { UploadCatalogDataModalComponent } from "./catalog/upload-catalog-data-modal/upload-catalog-data-modal.component";
import { UploadCatalogDataReportModalComponent } from "./catalog/upload-catalog-data-modal/upload-catalog-data-report-modal.component";
import { EditableTitleComponent } from "./shared/editable-title/editable-title.component";
import { DropdownToggleIconComponent } from "./shared/expand-icon/dropdown-toggle-icon.component";
// Import containers
import { OverlayModule } from "@angular/cdk/overlay";
import { MatButtonModule } from "@angular/material/button";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { AdminLoginComponent } from "@m19-board/auth/admin-login/admin-login.component";
import { LoginComponent } from "@m19-board/auth/login/login.component";
import { PasswordResetComponent } from "@m19-board/auth/password-reset/password-reset.component";
import { RegisterComponent } from "@m19-board/auth/register/register.component";
import { RequestPasswordResetComponent } from "@m19-board/auth/request-password-reset/request-password-reset.component";
import { Angulartics2Module } from "angulartics2";
import { ActivityCommentComponent } from "./activities/activity-comment/activity-comment.component";
import { ActivityService } from "./activities/activity.service";
import { ModalActivityCommentComponent } from "./activities/modal-activity-comment/modal-activity-comment.component";
import { AgencyBoardComponent } from "./agency-board/agency-board.component";
import { CatalogUploadComponent } from "./catalog/catalog-upload.component";
import { AddToSeedsModalComponent } from "./catalog/seeds/add-to-seeds-modal.component";
import { DefaultLayoutComponent } from "./containers";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { DateRangeSelectionModule } from "./date-range-selection/date-range-selection.module";
import { ColumnMetricSelectorComponent } from "./insights/column-metric-selector/column-metric-selector.component";
import { EntrySelectorComponent } from "./insights/entry-selector/entry-selector.component";
import { DayPartingPopUpComponent } from "./insights/hourly-view/day-parting-pop-up/day-parting-pop-up.component";
import { HourlyChartComponent } from "./insights/hourly-view/hourly-chart/hourly-chart.component";
import { HourlyPageComponent } from "./insights/hourly-view/hourly-page/hourly-page.component";
import { HourlyTableComponent } from "./insights/hourly-view/hourly-table/hourly-table.component";
import { ActionButtonsComponent } from "./insights/overview/action-buttons/action-buttons.component";
import { AlgoTargetRendererComponent } from "./insights/overview/algo-target-renderer/algo-target-renderer.component";
import { OverviewGridComponent } from "./insights/overview/overview-grid.component";
import { InsightOverviewComponent } from "./insights/overview/overview.component";
import { SwitchTargetAlgoModalComponent } from "./insights/overview/switch-target-algo-modal/switch-target-algo-modal.component";
import { AsinInventoryRuleModalComponent } from "./inventory/inventory-rules/asin-inventory-rules-modal.component";
import {
  AsinHasInventoryRulePipe,
  AsinInelligilibleForInventoryRulePipe,
  ExecuteRulePipe,
} from "./inventory/inventory-rules/inventory-rules.pipe";
import { LowInventoryWarningComponent } from "./inventory/inventory-rules/low-inventory-warning.component";
import { FulfillmentNetworkStockFormatPipe, InventoryTableComponent } from "./inventory/inventory-table.component";
import { InventoryComponent } from "./inventory/inventory.component";
import { AddKeywordTrackingModalComponent } from "./keyword-tracker/add-keyword-tracking-modal/add-keyword-tracking-modal.component";
import { KeywordTrackerDetailsComponent } from "./keyword-tracker/keyword-tracker-details/keyword-tracker-details.component";
import { KeywordTrackerPageComponent } from "./keyword-tracker/keyword-tracker-page.component";
import {
  GetRankPipe,
  KeywordTrackingTimelineTableComponent,
} from "./keyword-tracker/keyword-tracking-timeline/keyword-tracking-timeline-table/keyword-tracking-timeline-table.component";
import { KeywordTrackingTimelineComponent } from "./keyword-tracker/keyword-tracking-timeline/keyword-tracking-timeline.component";
import { TrackedAsinSelectionComponent } from "./keyword-tracker/keyword-tracking-timeline/tracked-asin-selection/tracked-asin-selection.component";
import { StopKeywordTrackingModalComponent } from "./keyword-tracker/stop-tracking-modal/stop-keyword-tracking-modal.component";
import { TrackingStrategyInfoModalComponent } from "./keyword-tracker/tracking-strategy-info/tracking-strategy-info-modal.component";
import { LayoutSelectorService } from "./layout-selector.service";
import { MarketplacesComponent } from "./marketplaces/marketplaces.component";
import { RankEvolutionComponent } from "./metric-evolution/rank-evolution/rank-evolution.component";
import { MetricModule } from "./models/Metric";
import { NotificationsCenterComponent } from "./notifications-center/notifications-center.component";
import { NotificationCardSwitchComponent } from "./notifications/notification-card-switch.component";
import { NotificationCardComponent } from "./notifications/notification-card.component";
import {
  NotificationCardBidderIssueComponent,
  NotificationCardCampaignOverlapComponent,
  NotificationCardDeletedBrandAssetComponent,
  NotificationCardStrategyOverlapComponent,
  NotificationInvalidSellingPartnerAccessComponent,
} from "./notifications/notification-cards.component";
import { HourlyStatsOverlayComponent } from "./overlay/hourly-stats-overlay.component";
import { KeywordRankingOverlayComponent } from "./overlay/keyword-ranking-overlay.component";
import { OverlayComponent } from "./overlay/overlay.component";
import { StatsOverlayComponent } from "./overlay/stats-overlay.component";
import { StrategyOverlayComponent } from "./overlay/strategy-overlay.component";
import { AddProductTrackingModalComponent } from "./product-tracker/add-product-tracking-modal/add-product-tracking-modal.component";
import { ProductTrackerKeywordChartComponent } from "./product-tracker/product-tracker-views/product-tracker-keyword-chart/product-tracker-keyword-chart.component";
import { ProductTrackerKeywordTableComponent } from "./product-tracker/product-tracker-views/product-tracker-keyword-table/product-tracker-keyword-table.component";
import { ProductTrackerTimelineComponent } from "./product-tracker/product-tracker-views/product-tracker-timeline/product-tracker-timeline.component";
import { ProductTrackerTrackedKeywordsComponent } from "./product-tracker/product-tracker-views/product-tracker-tracked-keywords/product-tracker-tracked-keywords.component";
import { ProductTrackerComponent } from "./product-tracker/product-tracker-views/product-tracker.component";
import { AsinLinkComponent } from "./product-view/asin-link.component";
import { ProductThumbnailComponent } from "./product-view/product-thumbnail.component";
import { ProductViewComponent } from "./product-view/product-view.component";
import { ProductsComponent } from "./products/products.component";
import { ProfitAndLossComponent } from "./profit-and-loss/profit-and-loss.component";
import { ProfitDetailsComponent } from "./profit-board/profit-details/profit-details.component";
import { ProfitService } from "./profit-board/profit-service";
import { ProductDetailsComponent } from "./sales-advertising/product-details/product-details.component";
import { SalesAdvertisingPageComponent } from "./sales-advertising/sales-advertising-page.component";
import {
  DetailedOrderMetricsPipe,
  NullDetailedOrderMetricsPipe,
  SalesAdvertisingComponent,
} from "./sales-advertising/sales-advertising.component";
import { AddToSegmentModalComponent } from "./segments/add-to-segment-modal.component";
import { KeywordSegmentModalComponent } from "./segments/keyword-segment-modal.component";
import { ProductSegmentModalComponent } from "./segments/product-segment-modal.component";
import { SegmentDetailStrategiesModalComponent } from "./segments/segment-detail/segment-detail-strategies-modal.component";
import { SegmentsComponent } from "./segments/segment-manager.component";
import { StrategiesInfoComponent } from "./segments/strategies-info/strategies-info.component";
import { CsvExportService } from "./services/csv-export.service";
import { KeywordTrackerService } from "./services/keyword-tracker.service";
import { ProductTrackerService } from "./services/product-tracker.service";
import { AccountSettingsService } from "./settings/account-settings.service";
import { ModalAddAccountGroupComponent } from "./settings/account-settings/account-group/add-account-group-pop-up/add-account-group.popup.component";
import { CustomCampaignNameHighlightPipe } from "./settings/account-settings/account-group/custom-campaign-name-pop-up/custom-campaign-name-highlight-pipe";
import { UpdateCustomCampaignNameComponent } from "./settings/account-settings/account-group/custom-campaign-name-pop-up/update-custom-campaign-name.component";
import { LinkYourAccountButtonComponent } from "./settings/account-settings/account-group/link-your-account-button.component";
import { ManageAuthorizedUsersModalComponent } from "./settings/account-settings/account-group/manage-authorized-users-modal/manage-authorized-users-modal.component";
import { ModalUpdateMaxBidComponent } from "./settings/account-settings/account-group/max-bid-pop-up/update-max-bid.popup";
import { AccountNameLinkComponent } from "./settings/account-settings/account-name-link/account-name-link.component";
import {
  AccountSettingComponent,
  ModalCreateAccountGroupComponent,
} from "./settings/account-settings/account-setting.component";
import { ModalUpdateVendorMetricsComponent } from "./settings/account-settings/custom-campaign-name-pop-up/vendor-metrics.popup";
import { SellingPartnerIntegrationValidationModalComponent } from "./settings/account-settings/selling-partner-integration/selling-partner-integration-validation-modal.component";
import { BillingAccountSelectionComponent } from "./settings/billing-settings/billing-account-selection/billing-account-selection.component";
import { BillingPlanSelectionComponent } from "./settings/billing-settings/billing-plan-selection/billing-plan-selection.component";
import { BillingRegistrationPaymentComponent } from "./settings/billing-settings/billing-registration-payment/billing-registration-payment.component";
import { BillingRegisterComponent } from "./settings/billing-settings/billing-registration/billing-registration.component";
import { BillingSettingsComponent } from "./settings/billing-settings/billing-settings.component";
import { CreditCardInfoComponent } from "./settings/billing-settings/credit-card-info/credit-card-info.component";
import { ModalStripeCreateTokenComponent } from "./settings/billing-settings/credit-card/stripe-create-token.component";
import { BillingCustomerComponent } from "./settings/billing-settings/customer/billing-customer.component";
import { InvoiceDetailsModalComponent } from "./settings/billing-settings/invoice-details-modal/invoice-details-modal.component";
import { BillingInvoicesComponent } from "./settings/billing-settings/invoices/billing-invoices.component";
import { RegistrationButtonComponent } from "./settings/billing-settings/registration-button/registration-button.component";
import { SelfServiceFreemiumComponent } from "./settings/billing-settings/selfservice-freemium/selfservice-freemium.component";
import { SubscriptionComponentCardComponent } from "./settings/billing-settings/subscription-card/subscription-card.component";
import { DataSharingComponent } from "./settings/data-sharing/data-sharing.component";
import { ChangePasswordComponent } from "./settings/profile-settings/change-password/change-password.component";
import { ProfileSettingsComponent } from "./settings/profile-settings/profile-settings.component";
import { AddAdminModalComponent } from "./settings/user-management/organization-card/add-admin-modal/add-admin-modal.component";
import { ManageAuthorizedAccessModalComponent } from "./settings/user-management/organization-card/manage-authorized-user-modal/manage-authorized-access-modal.component";
import { UserLimitationModalComponent } from "./settings/user-management/organization-card/user-limitation-modal/user-limitation-modal.component";
import { UserActivitiesGridComponent } from "./settings/user-management/user-activities-grid/user-activities-grid.component";
import { UserManagementComponent } from "./settings/user-management/user-management.component";
import { FilterStringListPipe, ShareOfVoiceComponent } from "./share-of-voice/share-of-voice.component";
import { AsinItemComponent } from "./shared/asin-item/asin-item.component";
import { AutocompleteComponent } from "./shared/autocomplete/autocomplete.component";
import { CampaignTypeBadgeComponent } from "./shared/campaign-type-badge/campaign-type-badge.component";
import { ChartRendererComponent } from "./shared/chart-renderer/chart-renderer.component";
import { CustomIconComponent } from "./shared/custom-icon/custom-icon.component";
import { DecimalInputComponent } from "./shared/decimal-input/decimal-input.component";
import { ClusterAsinCardComponent } from "./shared/drag-drop-asins/cluster-asin-card/cluster-asin-card.component";
import { DragDropAsinsComponent } from "./shared/drag-drop-asins/drag-drop-asins.component";
import { SearchDropdownComponent } from "./shared/gen-dropdown/search-dropdown/search-dropdown.component";
import { GridRangeValuesComponent } from "./shared/grid-range-values/grid-range-values.component";
import { GridViewerComponent } from "./shared/grid-viewer/grid-viewer.component";
import { InputModalComponent } from "./shared/input-modal/input-modal.component";
import { IntInputComponent, IntInputErrorPipe } from "./shared/int-input/int-input.component";
import { IntegerValueComponent } from "./shared/integer-value/integer-value.component";
import { KeywordItemComponent } from "./shared/keyword-item/keyword-item.component";
import { LinkComponent } from "./shared/link/link.component";
import { MediaInputComponent } from "./shared/media-input/media-input.component";
import { SlideToggleRendererComponent } from "./shared/slide-toggle-renderer/slide-toggle-renderer.component";
import { SovMainChartComponent } from "./shared/sov-main-chart/sov-main-chart.component";
import { SovMainTableComponent } from "./shared/sov-main-table/sov-main-table.component";
import { SovPieChartComponent } from "./shared/sov-pie-chart/sov-pie-chart.component";
import { SpendProgressBarComponent } from "./shared/spend-progress-bar/spend-progress-bar.component";
import { StrategyProductGroupHybridDropdownComponent } from "./shared/strategy-product-group-hybrid-dropdown/strategy-product-group-hybrid-dropdown.component";
import { DateAggreationComponent } from "./shared/switch-button/date-aggregation-switch-button.component";
import { SwitchButtonComponent } from "./shared/switch-button/switch-button.component";
import { SwitchInputComponent } from "./shared/switch-input/switch-input.component";
import { TextInputComponent } from "./shared/text-input/text-input.component";
import { BetaTesterFilterPipe, SidebarComponent } from "./sidebar/sidebar.component";
import { SpinnerComponent } from "./spinner/spinner.component";
import { CreativeDisplayComponent } from "./sponsored-brand/creative-display/creative-display.component";
import { SBCardComponent } from "./sponsored-brand/sb-card/sb-card.component";
import { SbManagerComponent } from "./sponsored-brand/sb-manager/sb-manager.component";
import { SbPageComponent } from "./sponsored-brand/sb-page/sb-page.component";
import { SdCardComponent } from "./sponsored-display/sd-card/sd-card.component";
import { SdManagerComponent } from "./sponsored-display/sd-manager/sd-manager.component";
import { SdStrategyCreationPageComponent } from "./sponsored-display/sd-manager/sd-strategy-creation/sd-strategy-creation-page.component";
import { SdStrategyCreationComponent } from "./sponsored-display/sd-manager/sd-strategy-creation/sd-strategy-creation.component";
import { SdPageComponent } from "./sponsored-display/sd-page/sd-page.component";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { SparklinesModule } from "@ag-grid-enterprise/sparklines";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AmazonLoginHandler, MaxAllowedBidPipe, provideM19APIs, UtilityPipesModule } from "@front/m19-services";
import {
  IAlertComponent,
  IBadgeComponent,
  IButtonComponent,
  ICardComponent,
  ICheckboxComponent,
  IInputComponent,
  IMultiSelectComponent,
  ISelectComponent,
  LoginFormComponent,
  RegisterFormComponent,
  RequestPasswordResetFormComponent,
  ResetPasswordFormComponent,
} from "@front/m19-ui";
import { Chart } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import { AsyncSubject, Observable } from "rxjs";
import {
  ALERT_SERVICE,
  AMAZON_LOGIN_HANDLER,
  API_BASE_URL,
  SELLING_PARTNER_DOMAIN,
} from "../../../../libs/m19-services/src/lib/m19-services/injection-token/injection-token";
import { environment } from "../environments/environment";
import { ActivityEventFiltersComponent } from "./activity-event-filters.component";
import { BrandAliasModalComponent } from "./brand/brand-alias-modal.component";
import { BrandAliasComponent } from "./brand/brand-alias.component";
import { BrandAsinsModalComponent } from "./brand/brand-asins-modal.component";
import { BrandMergeModalComponent } from "./brand/brand-merge-modal.component";
import { CatalogPageComponent } from "./catalog/catalog-page.component";
import { CogsUpdateModalComponent as CogsEditionComponent } from "./catalog/cog-update-modal/cogs-upload-modal.component";
import { CustomFieldEditionComponent } from "./catalog/custom-field-edition.component";
import { CustomFieldSettingsComponent } from "./catalog/custom-field-settings.component";
import { ImportManagedCatalogModalComponent } from "./catalog/import-managed-catalog-modal.component";
import { AppLayoutComponent } from "./containers/app-layout/app-layout.component";
import { Dashboard360LayoutComponent } from "./containers/dashboard360-layout/dashboard360-layout.component";
import { LayoutSelectorComponent } from "./containers/layout-selector.component";
import { V2LayoutComponent } from "./containers/v2-layout/v2-layout.component";
import { V2SidebarComponent } from "./containers/v2-layout/v2-sidebar.component";
import { DspStatsDetailsModalComponent } from "./dsp/dsp-stats-details-modal.component";
import { DspStatsComponent } from "./dsp/dsp-stats.component";
import { StrategyDetailStatsModalComponent } from "./insights/overview/strategy-detail-stats-modal/strategy-detail-stats-modal.component";
import { MetricEvoComponent } from "./metric-evo/metric-evo.component";
import { StatsOverlayWrapperComponent } from "./overlay/stats-overlay-wrapper.component";
import { I18nService } from "./services/i18n.service";
import { ObfuscationService } from "./services/obfuscation.service";
import { LinkDspModalComponent } from "./settings/account-settings/link-dsp.component";
import { RenewAmazonAccessModalComponent } from "./settings/account-settings/renew-amazon-access/renew-amazon-access-modal.component";
import { ShowDspComponent } from "./settings/account-settings/show-dsp.component";
import { AlgoStateCellComponent } from "./shared/algo-state-cell/algo-state-cell.component";
import { CarouselComponent } from "./shared/carousel/carousel.component";
import { FilterTagsComponent } from "./shared/filter-tags/filter-tags.component";
import { ImageComponent } from "./shared/image/image.component";
import { ExportButtonComponent } from "./shared/ui/export-buttons/export-button.component";
import { IMeterComponent } from "./shared/ui/imeter/imeter.component";
import { SdTargetingAddPopupComponent } from "./sponsored-display/sd-targeting/sd-targeting-add-popup.component";
import {
  ModalRemoveAIPoweredTargetingFromStrategyComponent,
  ModalRemoveAudienceFromStrategyComponent,
  SdTargetingComponent,
} from "./sponsored-display/sd-targeting/sd-targeting.component";
import { SPCardComponent } from "./sponsored-product/sp-card/sp-card.component";
import { SPStrategyGroupCardComponent } from "./sponsored-product/sp-card/sp-strategy-group-card.component";
import { SpStrategyCreationComponent } from "./sponsored-product/sp-creation/sp-strategy-creation/sp-strategy-creation.component";
import { SpStrategyGroupCreationPageComponent } from "./sponsored-product/sp-creation/sp-strategy-group-creation-page.component";
import { SpManagerComponent } from "./sponsored-product/sp-manager/sp-manager.component";
import { StrategyGroupAddProductModalComponent } from "./sponsored-product/sp-page/add-product/strategy-group-add-product-modal.component";
import { BlacklistModalComponent } from "./sponsored-product/sp-page/blacklist/blacklist-modal.component";
import { BlacklistViewComponent } from "./sponsored-product/sp-page/blacklist/blacklist-view.component";
import { StrategyGroupMoveProductModalComponent } from "./sponsored-product/sp-page/move-product/strategy-group-move-product-modal.component";
import { SpPageComponent } from "./sponsored-product/sp-page/sp-page.component";
import { SpStrategyGroupPageComponent } from "./sponsored-product/sp-page/sp-strategy-group-page.component";
import { FromBrandSelectionModalComponent } from "./sponsored-product/sp-page/sp-substrategy-creation/from-brand-selection-modal/from-brand-selection-modal.component";
import { SpSubStrategyCreationPageComponent } from "./sponsored-product/sp-page/sp-substrategy-creation/sp-substrategy-creation-page.component";
import { SpSubStrategyCreationComponent } from "./sponsored-product/sp-page/sp-substrategy-creation/sp-substrategy-creation.component";
import { SpSubStrategyPageComponent } from "./sponsored-product/sp-page/sp-substrategy-page.component";
import { SpSubStrategyComponent } from "./sponsored-product/sp-page/sp-substrategy.component";
import { StrategyKeywordComponent } from "./sponsored-product/sp-page/strategy-keyword/strategy-keyword.component";
import { TargetingAsinStatsComponent } from "./sponsored-product/sp-page/targeting-asin-stats/targeting-asin-stats.component";
import { StrategyKeywordModalComponent } from "./sponsored-product/sp-page/targetings-modal/strategy-keyword-modal.component";
import { StrategyProductTargetingsModalComponent } from "./sponsored-product/sp-page/targetings-modal/strategy-product-targetings-modal.component";
import { StrategyGroupMigrationPageComponent } from "./sponsored-product/strategy-group-migration/strategy-group-migration-page.component";
import { DayPartingInputsComponent } from "./strategies/day-parting-inputs/day-parting-inputs.component";
import { DisableAutoWarningComponent } from "./strategies/disable-auto/disable-auto-warning.component";
import { FblModeComponent } from "./strategies/fbl-mode/fbl-mode.component";
import { KeywordsListComponent } from "./strategies/keywords-list/keywords-list.component";
import { LimitWarningComponent } from "./strategies/limit-warning/limit-warning.component";
import { ProductGroupBulkUploadModalComponent } from "./strategies/product-group/product-group-bulk/product-group-bulk-upload-modal.component";
import { ProductGroupBulkUploadReportModalComponent } from "./strategies/product-group/product-group-bulk/product-group-bulk-upload-report-modal.component";
import { ProductGroupBulkUploadResultModalComponent } from "./strategies/product-group/product-group-bulk/product-group-bulk-upload-result-modal.component";
import { ProductGroupDetailsPopupComponent } from "./strategies/product-group/product-group-detail-popup.component";
import { ProductGroupPageComponent } from "./strategies/product-group/product-group-page/product-group-page.component";
import { ProductGroupThumbnailComponent } from "./strategies/product-group/product-group-thumbnail.component";
import {
  ModalCreateProductGroupComponent,
  ModalDeleteProductGroupComponent,
  ProductGroupComponent,
} from "./strategies/product-group/product-group.component";
import { SbCreativeStatsGridComponent } from "./strategies/sb-creative-stats-grid/sb-creative-stats-grid.component";
import {
  CreativeTypeNamePipe,
  SbFormAdFormatComponent,
} from "./strategies/sb-strategy-form/sb-form-ad-format/sb-form-ad-format.component";
import { SbFormAlgoComponent } from "./strategies/sb-strategy-form/sb-form-algo/sb-form-algo.component";
import { SbFormAsinsComponent } from "./strategies/sb-strategy-form/sb-form-asins/sb-form-asins.component";
import { SbFormCreaPreviewComponent } from "./strategies/sb-strategy-form/sb-form-crea-preview/sb-form-crea-preview.component";
import { SbFormCreativeComponent } from "./strategies/sb-strategy-form/sb-form-creative/sb-form-creative.component";
import { SbFormInfoComponent } from "./strategies/sb-strategy-form/sb-form-info/sb-form-info.component";
import { StoreSpotlightPagesComponent } from "./strategies/sb-strategy-form/sb-spotlight-pages/store-spotlight-pages.component";
import { SbStrategyFormComponent } from "./strategies/sb-strategy-form/sb-strategy-form.component";
import { AlgoModeSelectionComponent } from "./strategies/strategies/algo-mode-selection/algo-mode-selection.component";
import { SbCreativeWarningComponent } from "./strategies/strategies/sb-creative-warning/sb-creative-warning.component";
import { StrategiesComponent } from "./strategies/strategies/strategies.component";
import { StrategyActivityModalComponent } from "./strategies/strategies/strategy-activities/strategy-activities-modal.component";
import { StrategyActivityComponent } from "./strategies/strategies/strategy-activities/strategy-activities.component";
import { StrategyStatsComponent } from "./strategies/strategies/strategy-stats/stategy-stats.component";
import { AsinsSelectionComponent } from "./strategies/strategy-asins/asins-selection.component";
import { MoveStrategyAsinsModalComponent } from "./strategies/strategy-asins/move-strategy-asins-modal.component";
import { StrategyAsinsComponent } from "./strategies/strategy-asins/strategy-asins.component";
import { StrategyBoostBulkReportComponent } from "./strategies/strategy-boost/strategy-boost-bulk-upload/strategy-boost-bulk-report.component";
import { StrategyBoostBulkUploadComponent } from "./strategies/strategy-boost/strategy-boost-bulk-upload/strategy-boost-bulk-upload.component";
import { StrategyBoostTableComponent } from "./strategies/strategy-boost/strategy-boost-table/strategy-boost-table.component";
import { StrategyBoostComponent } from "./strategies/strategy-boost/strategy-boost.component";
import { BulkImportService } from "./strategies/strategy-bulk-upload-modal/bulk-import.service";
import { ProductListDetailsPopupComponent } from "./strategies/strategy-bulk-upload-modal/product-list-detail-popup.component";
import { StrategyBulkUploadModalComponent } from "./strategies/strategy-bulk-upload-modal/strategy-bulk-upload-modal.component";
import { StrategyBulkUploadReportModalComponent } from "./strategies/strategy-bulk-upload-modal/strategy-bulk-upload-report-modal.component";
import { StrategyBulkUploadResultModalComponent } from "./strategies/strategy-bulk-upload-modal/strategy-bulk-upload-result-modal.component";
import { StrategyCardComponent } from "./strategies/strategy-card/strategy-card.component";
import {
  StrategyTacticsCardDetailsComponent,
  TacticIntensityPipe,
} from "./strategies/strategy-card/strategy-tactics-card-details.component";
import { StrategyLimitationModalComponent } from "./strategies/strategy-limitation-modal/strategy-limitation-modal.component";
import { StrategyLinkComponent } from "./strategies/strategy-link/strategy-link.component";
import { StrategyListComponent } from "./strategies/strategy-list/strategy-list.component";
import { StrategyPageComponent } from "./strategies/strategy-page/strategy-page.component";
import { SwitchAlgoModeComponent } from "./strategies/strategy-page/switch-algo-mode-popup/switch-algo-mode.component";
import { TacticAddPopupComponent } from "./strategies/strategy-page/tactic-add-popup.component";
import { StrategyPlacementStatsGridComponent } from "./strategies/strategy-placement-stats/strategy-placement-stats-grid.component";
import { StrategyTableComponent } from "./strategies/strategy-table/strategy-table.component";
import { StrategyWarningComponent } from "./strategies/strategy-warning/strategy-warning.component";
import { ModalRemoveTacticFromStrategyComponent, TacticComponent } from "./strategies/tactic/tactic.component";
import { TargetLimitWarningComponent } from "./strategies/target-limit-warning/target-limit-warning.component";
import { TabbedNavComponent } from "./tabbed-nav/tabbed-nav.component";
import { TargetingDetailComponent } from "./targeting/targeting-detail.component";
import { ReviewScoreComponent } from "./tracking/review-score/review-score.component";
import { TopQueriesListComponent } from "./traffic-stats/top-queries-list.component";
import { TrafficStatsChartComponent } from "./traffic-stats/traffic-stats-chart.component";
import { TrafficStatsFilterComponent } from "./traffic-stats/traffic-stats-filter.component";
import { TrafficStatsComponent } from "./traffic-stats/traffic-stats.component";
import { TranslocoRootModule } from "./transloco-root.module";
import { AppUpdateService } from "./update-service/update-service";
import { SafePipe } from "./utils/pipes";

registerLocaleData(localeJa);
registerLocaleData(localeEs);
registerLocaleData(localeDe);
registerLocaleData(localeDeCh);
registerLocaleData(localeIt);
registerLocaleData(localeItCh);
registerLocaleData(localeEnAu);
registerLocaleData(localeEnCa);
registerLocaleData(localeEnGb);
registerLocaleData(localeFrCa);
registerLocaleData(localeFrCh);
registerLocaleData(localeFr);

// Register chartjs and no data annotation plugin
Chart.register(annotationPlugin);
Chart.register({
  id: "nodataplugin",
  beforeDraw: (chart) => {
    const { ctx } = chart;
    ctx.save();
    ctx.globalCompositeOperation = "destination-over";
    ctx.fillStyle = "#ffffff";
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
  afterDraw: function (chart) {
    if (chart.data.datasets.length === 0) {
      // No data is present
      const ctx = chart.ctx;
      const width = chart.width;
      const height = chart.height;
      chart.clear();

      ctx.save();
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.font = "16px normal 'Helvetica Nueue'";
      ctx.fillText("No data to display", width / 2, height / 2);
      ctx.restore();
    }
  },
});

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  CsvExportModule,
  MenuModule,
  RangeSelectionModule,
  RowGroupingModule,
  ClipboardModule,
  SetFilterModule,
  MultiFilterModule,
  StatusBarModule,
  SparklinesModule,
]);

const CLIENT_ID = "amzn1.application-oa2-client.de308868b4984b2b8ceac09afed2b2dd";
declare const amazon: any;

export function loadAmazonScript(): Observable<AmazonLoginHandler> {
  const amazonHandlerProvider = new AsyncSubject<AmazonLoginHandler>();
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = "https://assets.loginwithamazon.com/sdk/na/login1.js";
  script.onload = function () {
    amazon.Login.setClientId(CLIENT_ID);
    // eslint-disable-next-line no-console
    console.info("Amazon script loaded");
    amazonHandlerProvider.next(amazon.Login);
    amazonHandlerProvider.complete();
  };
  document.getElementsByTagName("head")[0].appendChild(script);
  return amazonHandlerProvider;
}

@NgModule({
  declarations: [
    AppComponent,
    AccountSelectorComponent,
    AdminLoginComponent,
    LoginComponent,
    RegisterComponent,
    PasswordResetComponent,
    RequestPasswordResetComponent,
    DefaultLayoutComponent,
    TacticAddPopupComponent,
    SdTargetingAddPopupComponent,
    SwitchAlgoModeComponent,
    ModalCreateProductGroupComponent,
    ModalDeleteProductGroupComponent,
    ModalRemoveTacticFromStrategyComponent,
    ModalRemoveAudienceFromStrategyComponent,
    ModalRemoveAIPoweredTargetingFromStrategyComponent,
    ProductViewComponent,
    KeywordTrackerPageComponent,
    ProductGroupComponent,
    SpManagerComponent,
    ProductGroupPageComponent,
    StrategyPageComponent,
    TacticComponent,
    KeywordsListComponent,
    StrategyCardComponent,
    TargetingAsinStatsComponent,
    ProductsComponent,
    MarketplacesComponent,
    SbManagerComponent,
    ProductDetailsComponent,
    IntegerValueComponent,
    TextInputComponent,
    SBCardComponent,
    SbPageComponent,
    CreativeDisplayComponent,
    SdManagerComponent,
    SdPageComponent,
    SdCardComponent,
    ProfileSettingsComponent,
    BillingSettingsComponent,
    SelfServiceFreemiumComponent,
    ModalStripeCreateTokenComponent,
    ModalCreateAccountGroupComponent,
    ModalUpdateMaxBidComponent,
    UpdateCustomCampaignNameComponent,
    ModalUpdateVendorMetricsComponent,
    ModalAddAccountGroupComponent,
    CustomCampaignNameHighlightPipe,
    FilterStringListPipe,
    InsightOverviewComponent,
    BillingCustomerComponent,
    BillingInvoicesComponent,
    CreditCardInfoComponent,
    SubscriptionComponentCardComponent,
    RegistrationButtonComponent,
    EntrySelectorComponent,
    BillingPlanSelectionComponent,
    BillingRegisterComponent,
    BillingRegistrationPaymentComponent,
    AgencyBoardComponent,
    DashboardComponent,
    SegmentsComponent,
    StrategiesInfoComponent,
    ActivityComponent,
    ActivitiesComponent,
    TrafficStatsComponent,
    TopQueriesListComponent,
    TrafficStatsChartComponent,
    TrafficStatsFilterComponent,
    SegmentDetailStrategiesModalComponent,
    TargetingDetailComponent,
    SdTargetingComponent,
    SeedsEditionModalComponent,
    DropdownToggleIconComponent,
    StrategyListComponent,
    StrategyCardComponent,
    SPCardComponent,
    StrategyTacticsCardDetailsComponent,
    SpPageComponent,
    LimitWarningComponent,
    TargetLimitWarningComponent,
    StrategyWarningComponent,
    EditableTitleComponent,
    UploadCatalogDataModalComponent,
    AddAdminModalComponent,
    ManageAuthorizedAccessModalComponent,
    ManageAuthorizedUsersModalComponent,
    SearchTermRanksComponent,
    SearchTermCardComponent,
    TabbedNavComponent,
    ProductsComponent,
    SalesAdvertisingComponent,
    NotificationsCenterComponent,
    NotificationCardComponent,
    StrategyBoostTableComponent,
    NotificationCardSwitchComponent,
    NotificationCardStrategyOverlapComponent,
    NotificationCardCampaignOverlapComponent,
    NotificationInvalidSellingPartnerAccessComponent,
    NotificationCardBidderIssueComponent,
    NotificationCardDeletedBrandAssetComponent,
    ChangePasswordComponent,
    SidebarComponent,
    DetailedOrderMetricsPipe,
    NullDetailedOrderMetricsPipe,
    StrategiesComponent,
    MaxAllowedBidPipe,
    TacticIntensityPipe,
    InventoryComponent,
    InventoryTableComponent,
    ProfitDetailsComponent,
    ProfitAndLossComponent,
    StrategyAsinsComponent,
    AsinsSelectionComponent,
    ProductGroupThumbnailComponent,
    ProductGroupDetailsPopupComponent,
    UploadCatalogDataReportModalComponent,
    StrategyBulkUploadModalComponent,
    StrategyBulkUploadResultModalComponent,
    StrategyBulkUploadReportModalComponent,
    ProductListDetailsPopupComponent,
    StrategyLimitationModalComponent,
    LinkYourAccountButtonComponent,
    StatsOverlayComponent,
    MarketplaceSelectorComponent,
    SearchTrendsMarketplaceSelectorComponent,
    OverlayComponent,
    StrategyOverlayComponent,
    FulfillmentNetworkStockFormatPipe,
    AsinInventoryRuleModalComponent,
    AsinInelligilibleForInventoryRulePipe,
    AsinHasInventoryRulePipe,
    ExecuteRulePipe,
    LowInventoryWarningComponent,
    MoveStrategyAsinsModalComponent,
    StrategyProductGroupHybridDropdownComponent,
    SpendProgressBarComponent,
    BillingAccountSelectionComponent,
    UserLimitationModalComponent,
    InvoiceDetailsModalComponent,
    HourlyChartComponent,
    HourlyTableComponent,
    HourlyStatsOverlayComponent,
    MediaInputComponent,
    HourlyPageComponent,
    DayPartingPopUpComponent,
    CatalogUploadComponent,
    KeywordRankingOverlayComponent,
    StopKeywordTrackingModalComponent,
    BetaTesterFilterPipe,
    ProductSegmentModalComponent,
    KeywordSegmentModalComponent,
    AddToSegmentModalComponent,
    StrategyTableComponent,
    AddToSeedsModalComponent,
    ModalActivityCommentComponent,
    StrategyBoostComponent,
    SellingPartnerIntegrationValidationModalComponent,
    DataSharingComponent,
    ShareOfVoiceComponent,
    TrackingStrategyInfoModalComponent,
    KeywordTrackerPageComponent,
    KeywordTrackerDetailsComponent,
    AddKeywordTrackingModalComponent,
    KeywordTrackingTimelineComponent,
    SovMainChartComponent,
    SovMainTableComponent,
    SovPieChartComponent,
    TrackedAsinSelectionComponent,
    KeywordTrackingTimelineTableComponent,
    ColumnMetricSelectorComponent,
    GetRankPipe,
    ProductTrackerComponent,
    ProductTrackerTrackedKeywordsComponent,
    ProductTrackerTimelineComponent,
    AddProductTrackingModalComponent,
    ChartRendererComponent,
    AsinLinkComponent,
    StrategyLinkComponent,
    RankEvolutionComponent,
    AccountSettingComponent,
    UserManagementComponent,
    UserActivitiesGridComponent,
    LinkComponent,
    SlideToggleRendererComponent,
    SwitchTargetAlgoModalComponent,
    AlgoTargetRendererComponent,
    InputModalComponent,
    StoreSpotlightPagesComponent,
    SpStrategyCreationComponent,
    SdStrategyCreationComponent,
    DragDropAsinsComponent,
    ClusterAsinCardComponent,
    ActivityCommentComponent,
    SpStrategyGroupCreationPageComponent,
    SpStrategyGroupPageComponent,
    CurrencySelectionComponent,
    SPStrategyGroupCardComponent,
    BlacklistModalComponent,
    SpSubStrategyComponent,
    StrategyGroupAddProductModalComponent,
    AlgoModeSelectionComponent,
    StrategyStatsComponent,
    StrategyActivityComponent,
    StrategyActivityModalComponent,
    SpStrategyCreationComponent,
    StrategyKeywordComponent,
    ProductTrackerKeywordChartComponent,
    ProductTrackerKeywordTableComponent,
    SpSubStrategyPageComponent,
    SpSubStrategyCreationPageComponent,
    SpSubStrategyCreationComponent,
    OverviewGridComponent,
    StrategyGroupMoveProductModalComponent,
    SdStrategyCreationPageComponent,
    StrategyGroupMigrationPageComponent,
    SalesAdvertisingPageComponent,
    StatsOverlayWrapperComponent,
    ProductGroupBulkUploadModalComponent,
    ProductGroupBulkUploadReportModalComponent,
    ProductGroupBulkUploadResultModalComponent,
    BrandAliasComponent,
    BrandAliasModalComponent,
    BrandMergeModalComponent,
    SbCreativeWarningComponent,
    AppLayoutComponent,
    Dashboard360LayoutComponent,
    BrandAsinsModalComponent,
    SbStrategyFormComponent,
    SbFormInfoComponent,
    SbFormAlgoComponent,
    SbFormAdFormatComponent,
    SbFormCreativeComponent,
    SbFormAsinsComponent,
    SbFormCreaPreviewComponent,
    CatalogPageComponent,
    CustomFieldEditionComponent,
    CogsEditionComponent,
    CustomFieldSettingsComponent,
    StrategyBoostBulkUploadComponent,
    StrategyBoostBulkReportComponent,
    RenewAmazonAccessModalComponent,
    StrategyDetailStatsModalComponent,
    ImportManagedCatalogModalComponent,
    StrategyKeywordModalComponent,
    StrategyProductTargetingsModalComponent,
    V2LayoutComponent,
    LinkDspModalComponent,
    ShowDspComponent,
    DspStatsComponent,
    LayoutSelectorComponent,
    DspStatsDetailsModalComponent,
  ],
  bootstrap: [AppComponent],
  exports: [ProductThumbnailComponent],
  imports: [
    AgGridModule,
    Angulartics2Module.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    AppRoutingModule,
    CollapseModule.forRoot(),
    NgChartsModule,
    FormsModule,
    DateRangeSelectionModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    DragDropModule,
    ReactiveFormsModule,
    NgxStripeModule.forRoot(""),
    ServiceWorkerModule.register("ngsw-worker.js"),
    FontAwesomeModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatTooltipModule,
    ToastrModule.forRoot(),
    MatMenuModule,
    MatSlideToggleModule,
    OverlayModule,
    CustomIconComponent,
    CampaignTypeBadgeComponent,
    GridViewerComponent,
    ActionButtonsComponent,
    AccountNameLinkComponent,
    MatTabsModule,
    MatSelectModule,
    MatButtonModule,
    SearchDropdownComponent,
    DateAggreationComponent,
    SwitchButtonComponent,
    UtilityPipesModule,
    MetricModule,
    AutocompleteComponent,
    ReviewScoreComponent,
    BlacklistViewComponent,
    NgOptimizedImage,
    MatRadioModule,
    SpinnerComponent,
    GridRangeValuesComponent,
    KeywordItemComponent,
    ProductThumbnailComponent,
    AsinItemComponent,
    ImageComponent,
    SwitchInputComponent,
    DayPartingInputsComponent,
    IButtonComponent,
    IntInputErrorPipe,
    IInputComponent,
    IMeterComponent,
    FblModeComponent,
    IntInputComponent,
    ICardComponent,
    IBadgeComponent,
    ISelectComponent,
    IMultiSelectComponent,
    MetricEvoComponent,
    AlgoStateCellComponent,
    SbCreativeStatsGridComponent,
    FilterTagsComponent,
    DecimalInputComponent,
    ExportButtonComponent,
    FromBrandSelectionModalComponent,
    StrategyPlacementStatsGridComponent,
    ICheckboxComponent,
    CarouselComponent,
    V2SidebarComponent,
    DisableAutoWarningComponent,
    TranslocoRootModule,
    CreativeTypeNamePipe,
    SafePipe,
    AgGridAngular,
    IAlertComponent,
    RegisterFormComponent,
    LoginFormComponent,
    RequestPasswordResetFormComponent,
    ResetPasswordFormComponent,
    ActivityEventFiltersComponent,
  ],
  providers: [
    {
      provide: API_BASE_URL,
      useValue: environment.apiEndPoint,
    },
    {
      provide: SELLING_PARTNER_DOMAIN,
      useValue: environment.spOauthRedirectDomain,
    },
    {
      provide: ALERT_SERVICE,
      useClass: ToastrService,
    },
    {
      provide: AMAZON_LOGIN_HANDLER,
      useValue: loadAmazonScript(),
    },
    provideM19APIs(),
    ThemeService,
    LayoutSelectorService,
    AppUpdateService,
    CsvExportService,
    KeywordTrackerService,
    ProductTrackerService,
    ProfitService,
    AccountSettingsService,
    ActivityService,
    BulkImportService,
    ObfuscationService,
    I18nService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
