import { Component, Input, OnInit } from "@angular/core";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faCheckSquare, faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  AccountGroup,
  AccountMarketplace,
  AccountMarketplaceService,
  AccountType,
  AmazonUser,
  AmazonUserService,
  Marketplace,
  Marketplaces,
  OrganizationAccountGroupService,
} from "@front/m19-services";
import { Option } from "@front/m19-ui";
import { BoardType, LayoutSelectorService } from "@m19-board/layout-selector.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Angulartics2 } from "angulartics2";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { TranslocoService } from "@jsverse/transloco";
@UntilDestroy()
@Component({
  templateUrl: "./add-account-group.popup.component.html",
  styleUrls: ["./add-account-group.popup.component.scss"],
})
export class ModalAddAccountGroupComponent implements OnInit {
  readonly faSearch = faSearch;
  readonly faSquare = faSquare;
  readonly faCheckedSquare = faCheckSquare;

  readonly Marketplaces = Marketplaces;

  accountGroupFilter: RegExp;
  amazonAccountFilter: RegExp;
  customerName: string;
  selectedAccount: AccountMarketplace;
  selectedMarketplaces: Map<Marketplace, boolean> = new Map();
  selectedAccountMarketplaces: AccountMarketplace[];
  alreadyUsedAccountMarketplaces: AccountMarketplace[];
  selectedAccountGroup: AccountGroup | undefined = undefined;
  selectedAccountGroupName = this.translocoService.translate("add-account-group.popup.new_account_group");
  accountGroupName: string;
  accountGroupNameTouched: boolean;
  errorMessage: string = undefined;
  successMessage: string = undefined;
  loading = false;
  amazonToken: string;
  amazonUser: AmazonUser;

  availableAccountMarketplaces: AccountMarketplace[] = [];
  // distinct accountId
  availableAccounts: AccountMarketplace[];

  availableAccountsOptions: Option<AccountMarketplace>[] = [];

  accountGroupOptions: Option<AccountGroup>[] = [];

  allAccountGroups: AccountGroup[];

  @Input()
  organizationId: number;

  @Input()
  canLoginWithAmazon: boolean;

  constructor(
    private accountService: OrganizationAccountGroupService,
    private accountMarketplaceService: AccountMarketplaceService,
    private amazonUserService: AmazonUserService,
    private layoutSelectorService: LayoutSelectorService,
    private angulartics2: Angulartics2,
    private bsModalRef: BsModalRef,
    private toasterService: ToastrService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.loading = false;

    this.accountService.allOrganizationAccountGroups$.pipe(untilDestroyed(this)).subscribe((organizations) => {
      this.allAccountGroups = organizations.filter((o) => o.id === this.organizationId).flatMap((o) => o.accountGroups);
    });

    this.accountMarketplaceService.accountMarketplaces$
      .pipe(untilDestroyed(this))
      .subscribe((accountMarketplaces) => (this.alreadyUsedAccountMarketplaces = accountMarketplaces));
  }

  changeName(name: string) {
    this.accountGroupName = name ?? name.trim();
    this.accountGroupNameTouched = true;
  }

  selectAccount(selected: AccountMarketplace) {
    this.selectedAccount = selected;
    this.selectedMarketplaces.clear();
    this.availableAccountMarketplaces
      .filter((x) => x.accountId == this.selectedAccount.accountId)
      .filter((x, i, array) => array.indexOf(x) === i)
      .map((a) => a.marketplace)
      .forEach((m) => this.selectedMarketplaces.set(m, true));
    if (selected && !this.accountGroupNameTouched) {
      this.accountGroupName = selected.accountName;
    }

    const currentAccountType = this.selectedAccount?.accountId?.startsWith("ENTITY")
      ? AccountType.VENDOR
      : AccountType.SELLER;

    if (this.allAccountGroups?.length > 0) {
      this.accountGroupOptions = [
        { value: null, label: this.translocoService.translate("add-account-group.popup.new_account_group") },
        ...this.allAccountGroups
          .filter((x) => x.type === currentAccountType)
          .map((x) => ({
            value: x,
            label: x.name,
          })),
      ];
    } else {
      this.accountGroupOptions = [];
    }

    this.updateSelectedAccountMarketplacesList();
  }

  selectTargetAccountGroupId(accountGroup: AccountGroup): void {
    this.selectedAccountGroup = accountGroup;
    this.selectedAccountGroupName = accountGroup
      ? accountGroup.name
      : this.translocoService.translate("add-account-group.popup.new_account_group");
  }

  updateSelectedAccountMarketplacesList(): void {
    this.selectedAccountMarketplaces = this.availableAccountMarketplaces.filter(
      (x) => x.accountId == this.selectedAccount.accountId && this.selectedMarketplaces.get(x.marketplace),
    );
  }

  toggleMarketplace(marketplace: Marketplace) {
    this.selectedMarketplaces.set(marketplace, !this.selectedMarketplaces.get(marketplace));
    this.updateSelectedAccountMarketplacesList();
  }

  save(): void {
    if (this.disableSave()) return;
    if (this.selectedAccountGroup) {
      const group = this.allAccountGroups.find((x) => x.id === this.selectedAccountGroup.id);

      const invalidMarketplaces = [];
      for (const acc of this.selectedAccountMarketplaces) {
        if (group?.containsMktp(acc.marketplace)) invalidMarketplaces.push(acc.marketplace);
      }

      if (invalidMarketplaces.length > 0) {
        this.toasterService.error(
          group?.name +
            " already contains marketplace" +
            (invalidMarketplaces.length > 1 ? "s " : " ") +
            invalidMarketplaces.join(", ") +
            ". Please remove " +
            (invalidMarketplaces.length > 1 ? "them" : "it") +
            " from the selection.",
          "Add Account",
        );
        return;
      }
    }
    this.loading = true;
    this.accountMarketplaceService.addNewAccountsToGroup(
      this.selectedAccountMarketplaces,
      this.accountGroupName,
      this.amazonToken,
      this.organizationId,
      this.selectedAccountGroup ? this.selectedAccountGroup.id : undefined,
      () => {
        this.loading = false;
        if (this.layoutSelectorService.getBoardType() === BoardType.WHITELABEL_SELFSERVICE) {
          for (const accountMarketplace of this.selectedAccountMarketplaces) {
            this.accountMarketplaceService.changeCampaignLabel(
              accountMarketplace,
              this.layoutSelectorService.getAgencySubDomain().slice(0, 4),
              () => {
                // do nothing
              },
              (error) => {
                this.toasterService.error(error.response.message, "Updating campaign label");
              },
            );
          }
        }
        this.successMessage =
          "Successfully linked account " +
          this.selectedAccount.accountName +
          " (" +
          this.selectedAccountMarketplaces.map((x) => x.marketplace).join(", ") +
          ") to " +
          (this.accountGroupName ?? this.allAccountGroups.find((x) => x.id === this.selectedAccountGroup.id).name) +
          ".";
        this.toasterService.success(
          "Account Group " +
            (this.accountGroupName ?? this.allAccountGroups.find((x) => x.id === this.selectedAccountGroup.id).name) +
            " successfully created",
          "Amazon Account linked",
        );
        this.accountGroupName = "";
        this.accountGroupNameTouched = false;
        this.updateAvailableAccountsAndMarketplaces(this.alreadyUsedAccountMarketplaces, this.amazonUser);
        if (this.availableAccountMarketplaces.length > 0) {
          this.successMessage += " You can now link more accounts.";
        }
      },
      (error) => {
        this.loading = false;
        this.toasterService.error(error.response.message, "Amazon account link error");
      },
    );
  }

  exit(): void {
    this.bsModalRef.hide();
  }

  updateAvailableAccountsAndMarketplaces(usedAccountMarketplaces: AccountMarketplace[], amazonUser: AmazonUser): void {
    if (!amazonUser) return;
    this.amazonToken = amazonUser.token;

    const usedProfiles = new Set<string>(
      usedAccountMarketplaces
        .filter((a) => a.resourceOrganizationId == this.organizationId)
        .map((x) => x.accountId + "." + x.marketplace),
    );
    this.availableAccountMarketplaces = amazonUser.accountMarketplaces.filter(
      (x) => !usedProfiles.has(x.accountId + "." + x.marketplace),
    );
    if (this.availableAccountMarketplaces.length === 0) {
      this.errorMessage = "All the accounts linked to this Amazon user has already been added to your account.";
    }
    this.availableAccounts = Array.from(
      new Set(this.availableAccountMarketplaces.map((x) => x.accountId)).values(),
    ).map((accountId) => {
      const accountMarketplace = this.availableAccountMarketplaces.find((x) => x.accountId == accountId);
      accountMarketplace.resourceOrganizationId = this.organizationId;
      return accountMarketplace;
    });
    this.availableAccountsOptions = this.availableAccounts.map((x) => ({
      value: x,
      label: x.accountName,
      track: x.accountId,
      marketplaces: this.availableAccountMarketplaces
        .filter((a) => a.accountId === x.accountId)
        .map((m) => m.marketplace),
    }));
    this.selectAccount(this.availableAccounts[0]);
  }

  setAccountGroupFilter(query: string): void {
    this.accountGroupFilter = query ? new RegExp(query, "i") : undefined;
  }

  showAccountGroup(accountGroup: string): boolean {
    return accountGroup && (!this.accountGroupFilter || accountGroup.search(this.accountGroupFilter) != -1);
  }

  setAmazonAccountFilter(query: string): void {
    this.amazonAccountFilter = query ? new RegExp(query, "i") : undefined;
  }

  showAmazonAccount(amazonAccount: string): boolean {
    return amazonAccount && (!this.amazonAccountFilter || amazonAccount.search(this.amazonAccountFilter) != -1);
  }

  logWithAmazon(): void {
    this.loading = true;
    this.amazonUserService.getAmazonUser(
      this.organizationId,
      (amazonUser: AmazonUser) => {
        this.loading = false;
        this.errorMessage = undefined;
        this.amazonUser = amazonUser;
        this.updateAvailableAccountsAndMarketplaces(this.alreadyUsedAccountMarketplaces, amazonUser);
        this.angulartics2.eventTrack.next({
          action: "login_with_amazon",
          properties: { category: "login", label: "success_" + amazonUser.email },
        });
      },
      (errorMessage: string) => {
        this.loading = false;
        this.errorMessage = errorMessage;
        this.angulartics2.eventTrack.next({
          action: "login_with_amazon",
          properties: { category: "login", label: "error_" + errorMessage },
        });
      },
    );
  }

  disableSave(): boolean {
    return (
      this.loading ||
      this.selectedAccountMarketplaces?.length == 0 ||
      ((!this.accountGroupName || this.accountGroupName.length == 0) && !this.selectedAccountGroup) ||
      (this.doesGroupExist(this.accountGroupName) && !this.selectedAccountGroup)
    );
  }

  doesGroupExist(name: string): boolean {
    return this.allAccountGroups.find((account) => account.name === name) !== undefined;
  }
}
