<ng-container *transloco="let t">
  <app-strategy-card
    strategyPage="sponsored-brands"
    [canDelete]="true"
    [strategy]="_strategy"
    [isReadOnly]="isReadOnly"
  >
    @if (!_strategy.sbCreatives || _strategy.sbCreatives.length === 0) {
      <div class="customheight-5 flex">
        <p class="my-auto text-center">{{ t("sb-card.no_creatives_associated_with_this_strategy") }}</p>
      </div>
    }

    @if (nbProduct !== 0) {
      <div class="flex pt-2">
        @if (logoUrl) {
          <img class="brandlogo sensitive-data object-contain pr-3" [src]="logoUrl" />
        } @else {
          <div class="flex flex-col justify-center pr-3">
            <fa-icon
              [icon]="faImage"
              class="asset-alt mb-1 text-center"
              matTooltip="{{ t('creative-display.no_preview_available') }}"
            ></fa-icon>
          </div>
        }
        <p class="my-auto">{{ headline }}</p>
      </div>
      @if (_strategy.sbCreatives.length > 1) {
        <div class="pull-right">
          {{ t("sb-card.sbCreatives_more", { videoCount: nbVideo, len: _strategy.sbCreatives.length - 1 }) }}
        </div>
      }
    }

    @if (nbProduct === 0 && nbVideo !== 0) {
      <div class="customheight-5 flex">
        <fa-icon [icon]="faVideo" class="video-icon my-auto" size="4x"></fa-icon>
        <p class="my-auto ml-4">{{ t("sb-card.nbvideo_video_ad_lines", [nbVideo]) }}</p>
      </div>
    }
  </app-strategy-card>
</ng-container>
