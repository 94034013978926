<ng-container *transloco="let t">
  @if (form) {
    <form [classList]="['flex flex-col ' + (!reviewMode && 'h-full')]" [formGroup]="form" (ngSubmit)="submitForm(true)">
      <div class="flex-1 p-5">
        <h4 class="text-xl font-medium">{{ t("sb-form-ad-format.ad_format") }}</h4>
        @if (!reviewMode) {
          <p class="m-0 text-gray-500">{{ t("sb-form-ad-format.choose_an_ad_format_for_your_strategy") }}</p>
        }

        <div class="mt-3">
          @if (!reviewMode) {
            <div class="flex w-full flex-col">
              @for (a of SbCreativeType | keyvalue: orderAdFormat; track a) {
                <div
                  class="checkbox-card"
                  [ngClass]="{
                    'checkbox-card-selected': form.controls.creativeType.value === SbCreativeType[a.key],
                    disabled: a.key === SbCreativeType.video && videoDisabled,
                  }"
                  (click)="clickAdFormat(SbCreativeType[a.key])"
                  attr.data-testid="ad-format-card-{{ a.key }}"
                >
                  <div class="flex w-full items-center justify-between">
                    <div class="flex flex-col items-start">
                      <div class="text-md font-medium">{{ SbCreativeType[a.key] | creativeTypeName }}</div>
                      <div class="text-sm text-gray-500">{{ AD_FORMATS_DESCRIPTIONS[a.value] }}</div>
                    </div>
                    <div
                      [class.checked]="form.controls.creativeType.value === SbCreativeType[a.key]"
                      class="radio-btn shrink-0"
                    >
                      <div></div>
                    </div>
                  </div>
                </div>
                @if (a.key === SbCreativeType.video && videoDisabled) {
                  <p class="mt-2 text-sm text-gray-500">
                    {{ t("sb-form-ad-format.video_is_not_available_for_this_marketplace") }}
                  </p>
                }
              }
            </div>
          } @else {
            <IBadge [label]="form.controls.creativeType.value" color="gray" />
          }

          <mat-radio-group formControlName="landingPage" class="my-3 flex flex-col">
            @if (form.controls.creativeType.value === SbCreativeType.productCollection) {
              <mat-radio-button
                class="[&_.mdc-label]:!mb-0"
                [value]="LandingPage.ProductListing"
                [checked]="form.controls.landingPage.value === LandingPage.ProductListing"
                data-testid="ProductListing-radio"
                >{{ t("sb-form-ad-format.product_listing") }}
              </mat-radio-button>
              <mat-radio-button
                class="[&_.mdc-label]:!mb-0"
                [value]="LandingPage.Store"
                [checked]="form.controls.landingPage.value === LandingPage.Store"
                data-testid="Store-radio"
                >{{ t("sb-form-ad-format.store_page") }}
              </mat-radio-button>
            }
          </mat-radio-group>

          @if (form.controls.landingPage.value === LandingPage.Store) {
            @if (
              pageOptions?.length > 0 &&
              (form.controls.creativeType.value !== SbCreativeType.storeSpotlight || storeOptions.length > 0)
            ) {
              @if (!reviewMode) {
                <label
                  class="m-0 mb-1 block pl-1 text-sm font-medium text-gray-700 after:ms-0.5 after:text-red-500 after:content-['*']"
                  >Store
                  {{
                    form.controls.creativeType.value === SbCreativeType.storeSpotlight ? "" : t("common.page")
                  }}</label
                >
                <ISelect
                  [options]="
                    form.controls.creativeType.value === SbCreativeType.storeSpotlight ? storeOptions : pageOptions
                  "
                  [selected]="selectedPage"
                  (selectedChange)="selectPage($event)"
                  class="sensitive-data"
                />
              } @else {
                <IInput label="Store Page" [control]="storeNameControl" />
              }
            } @else {
              <div class="relative mt-2 w-full overflow-hidden rounded-lg bg-orange-50 p-4 text-orange-500">
                <div class="tems-start flex gap-3">
                  <span class="icon-[material-symbols--error-circle-rounded-outline] size-5 shrink-0"></span>
                  <div class="w-0 flex-1">
                    <div class="mt-0 text-sm leading-5 opacity-90">
                      {{ t("sb-form-ad-format.no_store_available") }}
                    </div>
                    @if (form.controls.creativeType.value === SbCreativeType.storeSpotlight) {
                      <div class="mt-0 text-sm leading-5 opacity-90">
                        {{ t("sb-form-ad-format.no_store_spotlight") }}
                      </div>
                    }
                  </div>
                </div>
              </div>
            }
          }
        </div>
      </div>

      @if (!reviewMode) {
        <div class="flex justify-end border-t border-gray-300 bg-white p-4">
          <div class="flex gap-x-2">
            @if (formMode === FormMode.NEW_STRATEGY) {
              <IButton
                (onClick)="onFormPrevious.emit(); $event.preventDefault()"
                label="{{ t('common.previous') }}"
                variant="soft"
                color="gray"
                icon="icon-[material-symbols--chevron-left-rounded]"
              />
            }
            <IButton
              [disabled]="form.invalid"
              label="{{ t('common.next') }}"
              type="submit"
              icon="icon-[material-symbols--chevron-right-rounded]"
              [trailing]="true"
              testid="next-button"
            />
          </div>
        </div>
      }
    </form>
  }
</ng-container>
