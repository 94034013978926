import { combineLatest, Observable, of } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';
import { AccountSelectionService, StatsApiClientService } from '.';
import { FbaStorageFeesApi, Marketplace } from './api-client';
import { UserSelectionService } from './user.selection.service';
import { Utils } from './utils';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FbaStorageFeeService {
  public readonly globalDataByDates$: Observable<Map<string, Map<string, number>>>;
  public readonly previousGlobalDataByDates$: Observable<Map<string, Map<string, number>>>;

  constructor(
    private fbaStorageFeesApi: FbaStorageFeesApi,
    private userSelectionService: UserSelectionService,
    private accountSelectionService: AccountSelectionService,
  ) {
    this.globalDataByDates$ = combineLatest([
      this.accountSelectionService.singleAccountMarketplaceSelection$,
      this.userSelectionService.dateRange$,
    ]).pipe(
      switchMap(([am, dateRange]) => {
        return this.getFees(am.accountId, am.marketplace, dateRange[0], dateRange[1]);
      }),
      shareReplay(1),
    );
    this.previousGlobalDataByDates$ = combineLatest([
      this.accountSelectionService.singleAccountMarketplaceSelection$,
      this.userSelectionService.dateRange$,
      this.userSelectionService.periodComparison$,
    ]).pipe(
      switchMap(([am, dateRange, periodComparison]) => {
        if (!periodComparison?.period) return of(new Map<string, Map<string, number>>());
        const dateIntervalInDays = Utils.getDateIntervalInDays(dateRange);
        if (dateIntervalInDays > StatsApiClientService.maxComp) {
          return of(new Map<string, Map<string, number>>());
        }
        const dateGap = Utils.getDateIntervalInDays([periodComparison.period[0], dateRange[0]]);
        return this.getFees(
          am.accountId,
          am.marketplace,
          periodComparison.period[0],
          periodComparison.period[1],
          dateGap,
        );
      }),
      shareReplay(1),
    );
  }

  public getFees(
    accountId: string,
    marketplace: Marketplace,
    minDate: string,
    maxDate: string,
    dateGap: number = 0,
  ): Observable<Map<string, Map<string, number>>> {
    let start = new Date(minDate);
    // FBA storage fees of the previous month are only available between the 7th and the 15th of the month
    // Let estimate recent storage fees based on previous month fees
    start = new Date(start.getFullYear(), start.getMonth() - 2, 1);
    let end = new Date(maxDate);
    end = new Date(end.getFullYear(), end.getMonth() + 1, 0);

    return this.fbaStorageFeesApi
      .getFbaStorageFees({
        accountId: accountId,
        marketplace: marketplace,
        minDate: Utils.formatDateForApi(start),
        maxDate: Utils.formatDateForApi(end),
      })
      .pipe(
        switchMap((monthlyFees) => {
          const dailyFees = new Map<string, Map<string, number>>();

          for (const m of monthlyFees) {
            const currDate = new Date(m.monthOfCharge!);
            const nbDays = new Date(currDate.getFullYear(), currDate.getMonth(), 0).getDate();
            // Convert monthly fee to daily fees
            for (let i = 0; i < nbDays; i++) {
              let dateAsStr = Utils.formatDateForApi(currDate);
              if (dateGap > 0) {
                let momentDate = Utils.toMoment(dateAsStr);
                momentDate = momentDate.add(dateGap, 'days');
                dateAsStr = Utils.formatMomentDate(momentDate);
              }
              if (!dailyFees.has(dateAsStr)) {
                dailyFees.set(dateAsStr, new Map<string, number>());
              }
              dailyFees.get(dateAsStr)!.set(m.asin!, (-1 * m.monthlyStorageFee!) / nbDays);
              currDate.setDate(currDate.getDate() + 1);
            }
          }

          // create missing data based on previous data
          for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
            const date = new Date(d);
            const dateAsStr = Utils.formatDateForApi(date);
            const yesterday = new Date(d);
            yesterday.setDate(yesterday.getDate() - 1);
            const yesterdayAsStr = Utils.formatDateForApi(yesterday);
            if (!dailyFees.has(dateAsStr) && dailyFees.has(yesterdayAsStr)) {
              dailyFees.set(dateAsStr, dailyFees.get(yesterdayAsStr)!);
            }
          }
          return of(dailyFees);
        }),
      );
  }
}
