<div class="flex flex-col gap-5" *transloco="let t">
  <h1 class="text-2xl">{{ t("terms-of-use.header") }}</h1>
  <div class="flex flex-col">
    <span class="text-gray-700">{{ t("register.accept_the_terms") }}</span>
    <ICheckbox
      class="mt-4 inline-block"
      inputName="termsAndConditions"
      inputId="termsAndConditions"
      [control]="termsOfUseControl"
    >
      <ng-template #labelSlot>
        <label
          class="m-0 cursor-pointer text-sm font-medium text-gray-700 dark:text-gray-200"
          [innerHTML]="
            t('register.terms_conditions_agree_text', {
              agency,
              termsAndConditionLink: termsAndConditionLink
                ? '<a target=&quot;_blank&quot; href=&quot;' +
                  termsAndConditionLink +
                  '&quot; class=&quot;link&quot;>' +
                  t('register.condition_of_use') +
                  '</a>'
                : '<span>' + t('register.condition_of_use') + '</span>',
            })
          "
        >
        </label>
      </ng-template>
    </ICheckbox>
  </div>
  <IButton
    [label]="t('common.continue')"
    [disabled]="!termsOfUseControl.value"
    (onClick)="acceptTermsOfUse()"
    [loading]="loading()"
  ></IButton>
</div>
