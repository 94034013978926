<ng-container *transloco="let t">
  <mat-table [dataSource]="dataSource" matSort class="responsive-table table-striped-two table-hover">
    <ng-container matColumnDef="strategyStatus">
      <mat-header-cell *matHeaderCellDef class="flex-1 items-center"></mat-header-cell>
      <mat-cell *matCellDef="let strat" class="flex flex-1 justify-center">
        @if (strat.strategyId > 0 || strat.strategies?.length === 1) {
          <IButton
            [icon]="
              (strat.strategyId > 0 && strat.state === 'ENABLED') ||
              (strat.strategies && strat.strategies[0].state === 'ENABLED')
                ? ICON_PLAY
                : ICON_PAUSE
            "
            [color]="
              (strat.strategyId > 0 && strat.state === 'ENABLED') ||
              (strat.strategies && strat.strategies[0].state === 'ENABLED')
                ? 'main'
                : 'gray'
            "
            variant="ghost"
            [disabled]="isReadOnly"
            (onClick)="$event.stopPropagation(); changeStatus(strat)"
            [tooltipValue]="getStrategyStatusTooltip(strat)"
          />
        } @else {
          <div class="p-2">
            @if (hasLiveStrategies(strat)) {
              <span [class]="ICON_PLAY + ' text-main-600 text-xl'"></span>
            } @else {
              <span [class]="ICON_PAUSE + ' text-xl text-gray-600'"></span>
            }
          </div>
        }
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="strategyName">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        matTooltip="{{ t('common.strategy') }}"
        matTooltipPosition="above"
        class="flex-1"
        >{{ t("strategy-table.strategy_strategy_group") }}
      </mat-header-cell>
      <mat-cell *matCellDef="let strat" class="flex flex-1 items-center justify-between">
        @if (strat.strategyId > 0) {
          <a
            [attr.data-testid]="'name-' + strat.strategyGroupName"
            [routerLink]="strategyPagePrefix + strategyPage + '/' + strat.strategyId"
            queryParamsHandling="merge"
            class="link truncate"
            matTooltip="{{ strat.getName() }}"
            >{{ strat.getName() }}</a
          >
          @if (strat.campaignType === CampaignType.SB && strat?.version === 0) {
            <span
              class="ml-1"
              matTooltip="
  {{ t('strategy-table.no_advantage_sb') }}"
            >
              <fa-icon [icon]="faInfo"></fa-icon
            ></span>
          }
        } @else {
          <a
            [attr.data-testid]="'name-' + strat.strategyGroupName"
            routerLink="{{ strategyGroupPagePrefix }}{{ strat.strategyGroupId }}"
            queryParamsHandling="merge"
            class="link truncate"
            matTooltip="{{ strat.strategyGroupName }}"
            >{{ strat.strategyGroupName }}</a
          >
        }
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="algoMode">
      <mat-header-cell
        *matHeaderCellDef
        matTooltip="{{ t('strategy-table.algo_mode') }}"
        matTooltipPosition="above"
        class="flex flex-1 items-center text-center"
        >{{ t("strategy-table.algo_mode") }}
      </mat-header-cell>
      <mat-cell *matCellDef="let strat" class="flex flex-1 justify-center">
        @if (
          (strat.strategyId > 0 && strat.algoMode === AlgoModes.ACOS_TARGET) ||
          (strat.strategies?.length === 1 && strat.strategies[0].algoMode === AlgoModes.ACOS_TARGET)
        ) {
          <app-fbl-mode
            [strategy]="strat.strategyId > 0 ? strat : strat.strategies[0]"
            [size]="FblModeSize.small"
          ></app-fbl-mode>
        }
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="warnings">
      <mat-header-cell *matHeaderCellDef class="flex-1 items-center">{{ t("strategy-table.warning") }}</mat-header-cell>
      <mat-cell *matCellDef="let strat" class="flex flex-1 justify-center">
        @if (strat.strategyId > 0) {
          <app-target-limit-warning class="error pb-0 pr-0 pt-0"></app-target-limit-warning>
          @if (strat.disableOtherQueries && strat.campaignType !== CampaignType.SD) {
            <app-disable-auto-warning
              class="sc-warning pb-0 pr-0 pt-0"
              [campaignType]="strat.campaignType"
            ></app-disable-auto-warning>
          }
          <app-strategy-warning [strategy]="strat"></app-strategy-warning>
          @if (isDayPartingEnabled(strat)) {
            <div class="pr-2">
              <fa-icon
                class="activated align-center ml-2"
                [icon]="faClock"
                size="lg"
                [matTooltip]="getDayPartingToolTip(strat)"
              ></fa-icon>
            </div>
          }
          <!-- Other Boost -->
          @if (strat.primeDayBoost && (promoBoostActivated$ | async)) {
            <span
              [class]="ICON_BOOST"
              class="text-main text-xl"
              matTooltip="{{ t('strategy-card.boost_has_been_activated_on_this_strategy') }}"
            ></span>
          }
        }
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="target">
      <mat-header-cell *matHeaderCellDef class="items-center text-center"
        >{{ t("strategy-table.target") }}
      </mat-header-cell>
      <mat-cell *matCellDef="let strat" class="flex flex-1 justify-center">
        @if (strat.strategyId > 0 || strat.strategies?.length === 1) {
          <app-algo-target-renderer
            [algoTarget]="strat.strategyId > 0 ? strat : strat.strategies[0]"
            [locale]="locale"
            [currency]="currency"
            [readonly]="isReadOnly"
            [addLabel]="true"
          ></app-algo-target-renderer>
        }
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="nbAsins">
      <mat-header-cell *matHeaderCellDef class="items-center text-center">{{ t("common.asins") }}</mat-header-cell>
      <mat-cell *matCellDef="let strat" class="flex flex-1 justify-center">
        @if (strat.strategyId > 0) {
          {{
            t("sp-strategy-creation.selected_asins", {
              count: asinsByStrategy.has(strat.strategyId) ? asinsByStrategy.get(strat.strategyId).length : 0,
            })
          }}
          <app-limit-warning
            class="ml-1"
            [current]="asinsByStrategy.get(strat.strategyId)?.length"
            [limit]="MaxAsins"
            [message]="t('sp-strategy-group-card.too_many_asins_some_functionalities_are_deactivated')"
          ></app-limit-warning>
        } @else {
          {{ t("sd-strategy-creation.asin_selected", { count: strat.asins.length }) }}
          <app-limit-warning
            class="ml-1"
            [current]="strat.asins.length"
            [limit]="MaxAsins"
            [message]="t('sp-strategy-group-card.too_many_asins_some_functionalities_are_deactivated')"
          ></app-limit-warning>
        }
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="tactics">
      <mat-header-cell *matHeaderCellDef class="flex-1">{{ t("strategy-table.tactics_strategies") }}</mat-header-cell>
      <mat-cell *matCellDef="let strat" class="flex-1">
        @if (strat.strategyId > 0 || strat.strategies?.length === 1) {
          <div class="tactics flex flex-col justify-start">
            @if (getTactics(strat).length > 0) {
              <div class="tactic">
                <div class="tactic-body">
                  <p class="tactic-name mb-0">
                    {{ getTactics(strat)[0].segment.name }}
                  </p>
                  @if (getTactics(strat)[0].tacticType === "BLACKLIST") {
                    <IBadge color="gray" label="Blacklist" class="ml-2" />
                  }
                  @if (getTactics(strat)[0].tacticType !== "BLACKLIST" && strat.algoMode !== AlgoModes.PRODUCT_LAUNCH) {
                    <span class="intensity" (click)="$event.stopPropagation()">
                      <input
                        type="range"
                        #intensity
                        [value]="getTactics(strat)[0] | tacticIntensityPipe"
                        min="1"
                        max="5"
                        step="1"
                        class="slider"
                        disabled
                      />
                      <div class="labels">
                        <span class="label label-L">
                          <span class="mt-1">L</span>
                        </span>
                        <span class="label"></span>
                        <span class="label label-N">
                          <span class="mt-1">N</span>
                        </span>
                        <span class="label"></span>
                        <span class="label label-H">
                          <span class="mt-1">H</span>
                        </span>
                      </div>
                    </span>
                  }
                </div>
              </div>
            }
            @if (getTactics(strat).length > 1) {
              <div class="tactic">
                <div class="tactic-body">
                  <p class="tactic-name w-100 smaller mb-0">
                    {{ t("strategy-tactics-card-details.other_tactics", { count: getTactics(strat).length - 1 }) }}
                  </p>
                </div>
              </div>
            }
          </div>
        } @else {
          <div>
            @if (strat.brandStrategies.length > 0) {
              <span class="mr-1"
                >{{ t("strategy-table.strat_n_brand_defense", [strat.brandStrategies.length]) }} |</span
              >
            }
            @if (strat.keywordStrategies.length > 0) {
              <span class="mr-1">{{ t("strategy-table.strat_n_focus", [strat.keywordStrategies.length]) }} |</span>
            }
            <span>{{ t("strategy-table.strat_n_main", [strat.productStrategies.length]) }}</span>
          </div>
        }
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="adLines">
      <mat-header-cell *matHeaderCellDef class="flex-1">{{ t("strategy-table.ad_lines") }}</mat-header-cell>
      <mat-cell *matCellDef="let strat" class="flex-1">
        @if (!strat.sbCreatives || strat.sbCreatives.length === 0) {
          <div class="flex">
            <p class="my-auto text-center">{{ t("strategy-table.no_creatives_associated_with_this_strategy") }}.</p>
          </div>
        }
        @if (strat.creativesProduct.length !== 0) {
          <div class="flex items-center pr-1">
            @if (strat.brandAssetUrl | async) {
              <img class="brandlogo sensitive-data pr-3" [src]="strat.brandAssetUrl | async" />
            } @else {
              <div class="flex flex-col justify-center pr-3">
                <fa-icon
                  [icon]="faImage"
                  class="asset-alt mb-1 text-center"
                  matTooltip="{{ t('creative-display.no_preview_available') }}"
                ></fa-icon>
              </div>
            }
            <p class="my-auto">{{ strat.creativesProduct[0].headline }}</p>
          </div>
          @if (strat.sbCreatives.length > 1) {
            <div class="pull-right text-right">
              {{ t("strategy-table.n_more", [strat.sbCreatives.length - 1]) }}
              {{ strat.creativesVideo.length !== 0 ? t("strategy-table.incl_video") : "" }}
            </div>
          }
        }
        @if (strat.creativesProduct.length === 0 && strat.creativesVideo.length !== 0) {
          <div class="flex">
            <fa-icon [icon]="faVideo" class="video-icon my-auto" size="3x"></fa-icon>
            <p class="my-auto ml-4">{{ t("sb-card.nbvideo_video_ad_lines", [strat.creativesVideo.length]) }}</p>
          </div>
        }
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="sdTargetings">
      <mat-header-cell *matHeaderCellDef class="flex-2">{{ t("sd-strategy-creation.targetings") }}</mat-header-cell>
      <mat-cell *matCellDef="let strat" class="flex-2">
        @if (getSdProductTactics(strat).length > 0) {
          <IBadge color="gray" label="Product Page" class="mr-1" size="xs" />
          {{ getSdProductTactics(strat)[0].segment?.name | titlecase }}
        } @else if (strat.audienceTargetings.length > 0) {
          <IBadge color="gray" label="Audience" class="mr-1" size="xs" />

          {{ strat.audienceTargetings[0].expressionType | titlecase }}
          {{ supportedAudienceMatchType[strat.audienceTargetings[0].matchType] }} last
          {{ strat.audienceTargetings[0].lookback }}d
        }
        @if (getSdProductTactics(strat).length + strat.audienceTargetings.length > 1) {
          , …
        }
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="buttons">
      <mat-header-cell *matHeaderCellDef class="flex-1"></mat-header-cell>
      <mat-cell *matCellDef="let strat" class="flex flex-1 justify-end">
        @if (strat.strategyId > 0) {
          @if (!strat.defaultStrategy) {
            <IButton
              [icon]="ICON_TRASH_O"
              [testid]="'delete-strat-' + strat.strategyName"
              color="gray"
              variant="ghost"
              [disabled]="isReadOnly"
              [square]="true"
              (onClick)="$event.stopPropagation(); deleteStrategy(strat)"
              tooltipValue="{{ t('strategy-card.delete_strategy') }}"
            />
          }
        } @else {
          <IButton
            [icon]="ICON_TRASH_O"
            [testid]="'delete-strat-' + strat.strategyGroupName"
            color="gray"
            variant="ghost"
            [disabled]="isReadOnly"
            [square]="true"
            (onClick)="$event.stopPropagation(); deleteStrategyGroup(strat)"
            tooltipValue="{{ t('sp-strategy-group-card.delete_strategy_group') }}"
          />
        }
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="header"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="pointer"
      [routerLink]="
        row.strategyId > 0
          ? strategyPagePrefix + strategyPage + '/' + row.strategyId
          : row.strategies?.length === 1
            ? strategyGroupPagePrefix + row.strategyGroupId + '/' + row.strategies[0].strategyId
            : strategyGroupPagePrefix + row.strategyGroupId
      "
      queryParamsHandling="merge"
    ></mat-row>
  </mat-table>
  <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
</ng-container>
