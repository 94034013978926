import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  AccountSelectionService,
  AsinService,
  BrandingFilter,
  CatalogBrandService,
  ConfigService,
  ProductGroupEx,
  ProductGroupService,
  SegmentConfigType,
  SegmentEx,
  StrategyEx,
} from "@front/m19-services";
import { Option } from "@front/m19-ui";
import { PALETTE } from "@m19-board/models/Metric";
import { FilterTag, FilterTagVal } from "@m19-board/shared/filter-tags/filter-tags.component";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SegmentService } from "libs/m19-services/src/lib/m19-services/segmentService";
import { StrategyCache } from "libs/m19-services/src/lib/m19-services/strategy.cache";
import { combineLatest, switchMap } from "rxjs";

export type Filter<T> = {
  filterValue: T;
  exclude: boolean;
};

export interface TrafficStatsFilter {
  enableMultiAsin: boolean;
  productSegments: Filter<SegmentEx[]>[];
  keywordSegments: Filter<SegmentEx[]>[];
  advertisedProducts: Filter<string[]>[];
}

export const DefaultTrafficStatsFilter: TrafficStatsFilter = {
  enableMultiAsin: true,
  productSegments: [],
  keywordSegments: [],
  advertisedProducts: [],
};

enum FilterType {
  KeywordSegment = "keywordSegment",
  ProductSegment = "productSegment",
  ProductGroup = "productGroup",
  Strategy = "strategy",
  BrandTraffic = "brandTraffic",
  BrandProducts = "brandProducts",
  CustomField1 = "customField1",
  CustomField2 = "customField2",
}

const Targetings = "Targetings";
const AdvertisedProducts = "Advertised Products";

@UntilDestroy()
@Component({
  selector: "app-traffic-stats-filter",
  templateUrl: "./traffic-stats-filter.component.html",
  styleUrls: ["./traffic-stats-filter.component.scss"],
})
export class TrafficStatsFilterComponent implements OnInit {
  @Input()
  set initSegment(value: SegmentEx) {
    if (value) {
      const filterType =
        value.segmentType === SegmentConfigType.KeywordSegment ? FilterType.KeywordSegment : FilterType.ProductSegment;
      this.initFilterValue = [
        {
          filter: this.filters.find((f) => f.type === filterType),
          values: [{ label: value.name ?? "", value: value }],
          id: crypto.randomUUID(),
          exclude: false,
        },
      ];
    } else {
      this.initFilterValue = [];
    }
  }

  @Output()
  filterChange = new EventEmitter<TrafficStatsFilter>();

  filter: TrafficStatsFilter = DefaultTrafficStatsFilter;
  initFilterValue: FilterTagVal<SegmentEx | ProductGroupEx | StrategyEx | BrandingFilter | string>[] = [];

  keywordSegmentOptions: Option<SegmentEx>[] = [];
  productSegmentOptions: Option<SegmentEx>[] = [];
  advertisedProductGroupsOptions: Option<ProductGroupEx>[] = [];
  advertisedProductInStrategiesOptions: Option<StrategyEx>[] = [];
  brandTrafficOptions: Option<BrandingFilter>[] = [];
  customField1Values: Option<string>[] = [];
  customField2Values: Option<string>[] = [];
  customFiel1dMap: Map<string, string[]> = new Map();
  customField2Map: Map<string, string[]> = new Map();
  strategyAsinMap: Map<number, string[]> = new Map();

  readonly filters: FilterTag<SegmentEx | ProductGroupEx | StrategyEx | BrandingFilter | string>[] = [
    {
      type: FilterType.KeywordSegment,
      label: "Keyword Segments",
      tooltip: "Filter traffic on keywords",
      category: Targetings,
      noValuePlaceholder: "No keyword segment available",
      options: () => this.keywordSegmentOptions,
      color: PALETTE[0],
      excludeOption: true,
    },
    {
      type: FilterType.ProductSegment,
      label: "Product Segments",
      tooltip: "Filter traffic on targeted products",
      category: Targetings,
      noValuePlaceholder: "No product segment available",
      options: () => this.productSegmentOptions,
      color: PALETTE[1],
      excludeOption: true,
    },
    {
      type: FilterType.BrandTraffic,
      label: "Branded Traffic",
      tooltip: "Filter traffic on your brand targetings",
      category: Targetings,
      noValuePlaceholder: "No brands available",
      options: () => this.brandTrafficOptions,
      color: PALETTE[4],
      excludeOption: true,
    },
    {
      type: FilterType.ProductGroup,
      label: "Product Groups",
      tooltip: "Filter traffic redirecting to advertised products of a product group",
      category: AdvertisedProducts,
      noValuePlaceholder: "No product group available",
      options: () => this.advertisedProductGroupsOptions,
      color: PALETTE[2],
      excludeOption: true,
    },
    {
      type: FilterType.Strategy,
      label: "Strategy Products",
      tooltip: "Filter traffic redirecting to advertised products of a strategy",
      category: AdvertisedProducts,
      noValuePlaceholder: "No strategy available",
      options: () => this.advertisedProductInStrategiesOptions,
      color: PALETTE[3],
      excludeOption: true,
    },
    {
      type: FilterType.BrandProducts,
      label: "Brands",
      tooltip: "Filter traffic redirecting to your brand advertised products",
      category: AdvertisedProducts,
      noValuePlaceholder: "No brands available",
      options: () => this.brandTrafficOptions,
      color: PALETTE[5],
      excludeOption: true,
    },
    {
      type: FilterType.CustomField1,
      label: "Custom Field 1",
      tooltip: "Filter traffoc redirecting to product with a specific value in Custom Field 1",
      category: AdvertisedProducts,
      noValuePlaceholder: "No custom field value available",
      options: () => this.customField1Values,
      color: PALETTE[6],
      excludeOption: true,
    },
    {
      type: FilterType.CustomField2,
      label: "Custom Field 2",
      tooltip: "Filter traffoc redirecting to product with a specific value in Custom Field 2",
      category: AdvertisedProducts,
      noValuePlaceholder: "No custom field value available",
      options: () => this.customField2Values,
      color: PALETTE[7],
      excludeOption: true,
    },
  ];

  constructor(
    private accountSelection: AccountSelectionService,
    private strategyCache: StrategyCache,
    private segmentService: SegmentService,
    private configService: ConfigService,
    private productGroupService: ProductGroupService,
    private catalogBrandService: CatalogBrandService,
    private asinService: AsinService,
  ) {}

  ngOnInit(): void {
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am) =>
          combineLatest<[Map<number, SegmentEx>, Map<number, StrategyEx>, ProductGroupEx[], BrandingFilter[]]>([
            this.segmentService.segmentIndex$,
            this.strategyCache.strategyIndex$,
            this.productGroupService.getProductGroups(am.accountId, am.marketplace),
            this.catalogBrandService.getBrandingFilters(am.accountId, am.marketplace),
          ]),
        ),
      )
      .subscribe(([segmentIndex, strategyIndex, productGroups, brandingFilters]) => {
        const kwSegments = Array.from(segmentIndex.values()).filter(
          (s) => s.segmentType === SegmentConfigType.KeywordSegment,
        );
        const productSegments = Array.from(segmentIndex.values()).filter(
          (s) => s.segmentType === SegmentConfigType.ProductSegment,
        );
        this.keywordSegmentOptions = kwSegments.map(this.toSegmentDropdownOption);
        this.productSegmentOptions = productSegments.map(this.toSegmentDropdownOption);
        this.advertisedProductGroupsOptions = productGroups.map(this.toProductGroupOption);
        this.advertisedProductInStrategiesOptions = Array.from(strategyIndex.values()).map(this.toStrategyOption);
        this.brandTrafficOptions = brandingFilters.map(this.toBrandTrafficOption);

        // reset the filter on account marketplace change
        if (this.filter !== DefaultTrafficStatsFilter) {
          this.initFilterValue = [];
          this.filter = DefaultTrafficStatsFilter;
          this.filterChange.emit(this.filter);
        }
      });
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am) => this.asinService.getInventoryConfig(am.accountId, am.marketplace)),
      )
      .subscribe((config) => {
        if (config.customField1Name) {
          const filter = this.filters.find((f) => f.type === FilterType.CustomField1);
          filter.label = config.customField1Name;
        }
        if (config.customField2Name) {
          const filter = this.filters.find((f) => f.type === FilterType.CustomField2);
          filter.label = config.customField2Name;
        }
      });
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am) => this.asinService.getCatalog(am.accountId, am.marketplace)),
      )
      .subscribe((catalog) => {
        const customField1Values = new Set<string>();
        const customField2Values = new Set<string>();
        for (const product of catalog.products) {
          if (product.customField1) customField1Values.add(product.customField1);
          if (product.customField2) customField2Values.add(product.customField2);
        }
        this.customField1Values = Array.from(customField1Values).map((v) => ({ label: v, value: v }));
        this.customField2Values = Array.from(customField2Values).map((v) => ({ label: v, value: v }));
        // map custom field values to asin
        this.customFiel1dMap.clear();
        this.customField2Map.clear();
        for (const product of catalog.products) {
          if (product.customField1) {
            if (!this.customFiel1dMap.has(product.customField1)) {
              this.customFiel1dMap.set(product.customField1, []);
            }
            this.customFiel1dMap.get(product.customField1).push(product.asin);
          }
          if (product.customField2) {
            if (!this.customField2Map.has(product.customField2)) {
              this.customField2Map.set(product.customField2, []);
            }
            this.customField2Map.get(product.customField2).push(product.asin);
          }
        }
      });
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am) => this.configService.asinStrategyIndex$),
      )
      .subscribe((asinStrategyIndex) => {
        this.strategyAsinMap.clear();
        for (const [asin, strategies] of asinStrategyIndex) {
          for (const strategy of strategies) {
            if (!this.strategyAsinMap.has(strategy.strategyId)) {
              this.strategyAsinMap.set(strategy.strategyId, []);
            }
            this.strategyAsinMap.get(strategy.strategyId).push(asin);
          }
        }
      });
  }

  enableMultiAsin() {
    this.filter.enableMultiAsin = !this.filter.enableMultiAsin;
    this.filterChange.emit(this.filter);
  }

  applyFilter(filters: FilterTagVal<SegmentEx | ProductGroupEx | StrategyEx | BrandingFilter | string>[]) {
    const keywordSegments: Filter<SegmentEx[]>[] = [];
    const productSegments: Filter<SegmentEx[]>[] = [];
    const advertisedProducts: Filter<string[]>[] = [];
    for (const filter of filters) {
      if (filter.values.length === 0) {
        continue;
      }
      const values = filter.values.map((v) => v.value);
      if (filter.filter.type === FilterType.KeywordSegment) {
        keywordSegments.push({ filterValue: values as SegmentEx[], exclude: filter.exclude });
      } else if (filter.filter.type === FilterType.ProductSegment) {
        productSegments.push({ filterValue: values as SegmentEx[], exclude: filter.exclude });
      } else if (filter.filter.type === FilterType.ProductGroup) {
        const productGroupAsins = new Set((values as ProductGroupEx[]).flatMap((pg) => pg.items));
        advertisedProducts.push({ filterValue: Array.from(productGroupAsins.values()), exclude: filter.exclude });
      } else if (filter.filter.type === FilterType.Strategy) {
        const strategyAsins = new Set((values as StrategyEx[]).flatMap((s) => this.strategyAsinMap.get(s.strategyId)));
        advertisedProducts.push({ filterValue: Array.from(strategyAsins.values()), exclude: filter.exclude });
      } else if (filter.filter.type === FilterType.BrandProducts) {
        const brandAsins = new Set((values as BrandingFilter[]).flatMap((b) => b.asins));
        advertisedProducts.push({ filterValue: Array.from(brandAsins.values()), exclude: filter.exclude });
      } else if (filter.filter.type === FilterType.BrandTraffic) {
        keywordSegments.push({
          filterValue: (values as BrandingFilter[]).map((b) => b.keywordSegment),
          exclude: filter.exclude,
        });
        productSegments.push({
          filterValue: (values as BrandingFilter[]).map((b) => b.productSegment),
          exclude: filter.exclude,
        });
      } else if (filter.filter.type === FilterType.CustomField1) {
        const asins = (values as string[]).flatMap((v) => this.customFiel1dMap.get(v));
        advertisedProducts.push({ filterValue: asins, exclude: filter.exclude });
      } else if (filter.filter.type === FilterType.CustomField2) {
        const asins = (values as string[]).flatMap((v) => this.customField2Map.get(v));
        advertisedProducts.push({ filterValue: asins, exclude: filter.exclude });
      }
    }
    this.filter = { ...this.filter, keywordSegments, productSegments, advertisedProducts };
    this.filterChange.emit(this.filter);
  }

  private toSegmentDropdownOption(segment: SegmentEx): Option<SegmentEx> {
    return {
      label: segment.name,
      value: segment,
    };
  }

  private toProductGroupOption(productGroup: ProductGroupEx): Option<ProductGroupEx> {
    return {
      label: productGroup.name,
      value: productGroup,
    };
  }

  private toStrategyOption(strategy: StrategyEx): Option<StrategyEx> {
    return {
      label: strategy.name ?? "",
      value: strategy,
    };
  }

  private toBrandTrafficOption(brand: BrandingFilter): Option<BrandingFilter> {
    return {
      label: brand.name,
      value: brand,
    };
  }
}
