import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Marketplace, ProductGroup, ProductGroupEx, ProductGroupService } from "@front/m19-services";
import {
  BulkImportService,
  ProductGroupBulkOperations,
  ProductGroupCreation,
  ProductGroupUpdate,
  ProductGroupUploadResult,
} from "@m19-board/strategies/strategy-bulk-upload-modal/bulk-import.service";
import {
  AsinLabel,
  ProductListDetailsPopupComponent,
} from "@m19-board/strategies/strategy-bulk-upload-modal/product-list-detail-popup.component";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { forkJoin, of } from "rxjs";
import { catchError } from "rxjs/operators";

@UntilDestroy()
@Component({
  selector: "app-product-group-bulk-upload-report-modal",
  templateUrl: "./product-group-bulk-upload-report-modal.component.html",
  styleUrls: ["./product-group-bulk-upload-report-modal.component.scss"],
})
export class ProductGroupBulkUploadReportModalComponent implements OnInit {
  @Input()
  public productGroupBulkOperations: ProductGroupBulkOperations;
  @Input()
  public accountId: string;
  @Input()
  public marketplace: Marketplace;
  @Output()
  public bulkOperationResult = new EventEmitter<ProductGroupUploadResult>();
  @Output()
  public uploadCancelled = new EventEmitter<void>();

  readonly genericBulkCsvFields = this.bulkImportService.ProductGroupBulkConfig.filter(
    (f) => f.field != "items" && f.field != "productGroupId",
  );

  creationColumns: (keyof ProductGroup | "select")[] = [];
  updateColumns: (keyof ProductGroup | "select")[] = [];
  readonly selectedProductGroupCreation = new Map<number, ProductGroupCreation>();
  readonly selectedProductGroupUpdate = new Map<number, ProductGroupUpdate>();
  readonly creationsDataSource = new MatTableDataSource<ProductGroupCreation>([]);
  readonly updateDataSource = new MatTableDataSource<ProductGroupUpdate>([]);
  readonly faSquare = faSquare;
  readonly faCheckedSquare = faCheckSquare;

  constructor(
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private bulkImportService: BulkImportService,
    private productGroupService: ProductGroupService,
  ) {}

  ngOnInit(): void {
    this.creationsDataSource.data = this.productGroupBulkOperations.creations;
    this.updateDataSource.data = this.productGroupBulkOperations.updates;
    this.productGroupBulkOperations.creations.forEach((c) => this.selectedProductGroupCreation.set(c.lineIndex, c));
    this.productGroupBulkOperations.updates.forEach((c) => this.selectedProductGroupUpdate.set(c.lineIndex, c));
    this.creationColumns = [
      "select",
      ...this.bulkImportService.ProductGroupBulkConfig.filter((s) => s.field != "productGroupId").map((f) => f.field),
    ];
    this.updateColumns = ["select", ...this.bulkImportService.ProductGroupBulkConfig.map((f) => f.field)];
  }

  isProductGroupCreationSelected(creation: ProductGroupCreation): boolean {
    return this.selectedProductGroupCreation.has(creation.lineIndex);
  }

  isProductGroupUpdateSelected(update: ProductGroupCreation): boolean {
    return this.selectedProductGroupUpdate.has(update.lineIndex);
  }

  toggleProductGroupCreation(creation: ProductGroupCreation): void {
    if (this.selectedProductGroupCreation.has(creation.lineIndex)) {
      this.selectedProductGroupCreation.delete(creation.lineIndex);
    } else {
      this.selectedProductGroupCreation.set(creation.lineIndex, creation);
    }
  }

  toggleProductGroupUpdate(update: ProductGroupUpdate): void {
    if (this.selectedProductGroupUpdate.has(update.lineIndex)) {
      this.selectedProductGroupUpdate.delete(update.lineIndex);
    } else {
      this.selectedProductGroupUpdate.set(update.lineIndex, update);
    }
  }

  showProductDetails(asins: string[], event: MouseEvent): void {
    event.preventDefault();
    const modalOptions: ModalOptions = {
      initialState: {
        asins: asins,
        accountId: this.accountId,
        marketplace: this.marketplace,
      },
    };
    this.modalService.show(ProductListDetailsPopupComponent, modalOptions);
  }

  showProductDetailsForUpdate(update: ProductGroupUpdate, event: MouseEvent): void {
    event.preventDefault();
    const asins = new Set<string>();
    const labels = new Map<string, AsinLabel>();
    for (const asin of update.updatedFields.asinsToAdd) {
      asins.add(asin);
      labels.set(asin, AsinLabel.NEW);
    }
    for (const asin of update.updatedFields.asinsToDelete) {
      asins.add(asin);
      labels.set(asin, AsinLabel.DELETED);
    }
    for (const asin of update.productGroupToUpdate.items) {
      asins.add(asin);
    }
    const modalOptions: ModalOptions = {
      initialState: {
        asins: Array.from(asins),
        accountId: this.accountId,
        marketplace: this.marketplace,
        asinLabels: labels,
      },
    };
    this.modalService.show(ProductListDetailsPopupComponent, modalOptions);
  }

  cancel(): void {
    this.uploadCancelled.emit();
    this.bsModalRef.hide();
  }

  uploadData(): void {
    const errors: string[] = [];
    const creations =
      this.selectedProductGroupCreation.size === 0
        ? of([] as ProductGroup[])
        : forkJoin(
            Array.from(this.selectedProductGroupCreation.values()).map((creation) => {
              return this.productGroupService
                .createProductGroup(
                  this.accountId,
                  this.marketplace,
                  creation.productGroup.productGroupName,
                  creation.productGroup.items,
                )
                .pipe(
                  catchError((err: string[] | string) => {
                    if (typeof err == "string") {
                      errors.push(
                        `Impossible to create product group ${creation.productGroup.productGroupName}: ${err}`,
                      );
                    } else {
                      err.forEach((e) =>
                        errors.push(
                          `Impossible to create product group ${creation.productGroup.productGroupName}: ${e}`,
                        ),
                      );
                    }
                    return of(null as ProductGroupEx);
                  }),
                );
            }),
          );

    const updates =
      this.selectedProductGroupUpdate.size === 0
        ? of([] as ProductGroup[])
        : forkJoin(
            Array.from(this.selectedProductGroupUpdate.values()).map((update) => {
              return this.productGroupService
                .updateProductGroup(this.accountId, this.marketplace, {
                  accountId: this.accountId,
                  productGroupId: update.productGroupToUpdate.productGroupId,
                  productGroupName:
                    update.updatedFields.productGroupName ?? update.productGroupToUpdate.productGroupName,
                  items: update.updatedFields.asins,
                })
                .pipe(
                  catchError((err: string) => {
                    errors.push(
                      `Impossible to update product group ${update.productGroupToUpdate.productGroupName}: ${err}`,
                    );
                    return of(null as ProductGroupEx);
                  }),
                );
            }),
          );
    forkJoin([creations, updates])
      .pipe(untilDestroyed(this))
      .subscribe(([created, updated]) => {
        this.bulkOperationResult.emit({
          created: created.filter((s) => !!s),
          updated: updated.filter((s) => !!s),
          errors: errors,
        });
        this.bsModalRef.hide();
      });
  }
}
