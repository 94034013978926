<div class="flex max-h-screen min-h-screen flex-1 flex-col overflow-hidden" *transloco="let t">
  @if (isTest || isAdminLogin()) {
    <div class="flex w-full justify-between bg-orange-700 px-4 text-white">
      <div class="flex gap-6">
        @if (isTest) {
          <span class="font-bold">Demo environment</span>
          @if (isAdminLogin()) {
            <span class="font-bold">|</span>
          }
        }
        @if (isAdminLogin()) {
          <span>Logged via Admin Board</span>
        }
      </div>
      <a (click)="printDebugInfo()">Debug Info</a>
    </div>
  }
  @if (globalWarning$ | async) {
    <div class="border-sha w-full rounded-sm bg-red-100 px-4 py-1 text-red-700">
      {{ globalWarning$ | async }}
    </div>
  }
  <!-- header -->
  <div class="m-0 flex w-full flex-row items-center justify-between border-b border-gray-200 bg-white p-0 px-4 py-2">
    <div class="flex items-center">
      <a class="w-[155px]" routerLink="/v2/dashboard" queryParamsHandling="merge"
        ><img
          [src]="navbarBrandFull.src"
          [height]="navbarBrandFull.height"
          [alt]="navbarBrandFull.alt"
          class="sensitive-data"
      /></a>
      <app-account-selector [hidden]="displayedSelector !== Selector.AccountSelector" scope="dashboard" />
      @if (
        organizations &&
        organizations.length &&
        selectedOrganizations &&
        displayedSelector === Selector.OrganizationSelector
      ) {
        <div class="flex w-52 min-w-0 max-w-72 items-center">
          <IMultiSelect
            [options]="organizations"
            [selected]="selectedOrganizations"
            (selectedChange)="selectOrganization($event)"
            class="sensitive-data mr-3"
            [searchable]="true"
            [minSelectedOptions]="1"
            [withSelectAll]="true"
          >
            <ng-template #labelSlot>
              <span class="font-medium text-gray-500"> Organizations </span>
              @if (selectedOrganizations.length) {
                {{
                  selectedOrganizations[0].label +
                    (selectedOrganizations.length > 1 ? " (+" + (selectedOrganizations.length - 1) + ")" : "")
                }}
              }
            </ng-template>
          </IMultiSelect>
        </div>
      }
      @if (displayedSelector === Selector.MarketplaceSelector) {
        <app-search-trends-marketplace-selector></app-search-trends-marketplace-selector>
      }

      <div class="flex items-center">
        @if (boardType === BoardType.M19 || boardType === BoardType.WHITELABEL_SELFSERVICE) {
          <app-link-your-account-button class="mx-1 mt-1"></app-link-your-account-button>
        }
        @if (boardType === BoardType.M19 || boardType === BoardType.WHITELABEL_SELFSERVICE) {
          <app-registration-button></app-registration-button>
        }
        @if (displayedSelector === Selector.AccountSelector && hasInvalidToken()) {
          <button
            class="btn btn-warning mx-1 h-full"
            matTooltip="{{ t('default-layout.login_tooltip', [logInPlaceForAPIGrantAccess()]) }}"
            (click)="requestGrant()"
          >
            {{ t("default-layout.grant_access") }}
          </button>
        }
        @if (displayedSelector === Selector.AccountSelector && hasInvalidToken()) {
          <IButton
            color="orange"
            [label]="t('default-layout.validate_catalog')"
            [matTooltip]="t('default-layout.validate_seeling_partner_api')"
            (click)="validateAccountIntegration()"
          />
        }
        @if (displayedSelector === Selector.AccountSelector && hasPendingVendorIntegration()) {
          <IButton
            class="mx-1"
            color="orange"
            [label]="t('default-layout.validate_catalog')"
            [matTooltip]="t('default-layout.validate_seeling_partner_api')"
            (click)="validateAccountIntegration()"
          />
        }
      </div>
    </div>

    <div class="flex items-center gap-2">
      <app-currency-selection></app-currency-selection>
      <app-date-range-selection></app-date-range-selection>

      <div class="flex items-center gap-1">
        <div class="relative" #notificationsCtn>
          <IButton
            [icon]="ICON_BELL"
            tooltipValue="{{ t('common.notifications') }}"
            (onClick)="openNotifications($event)"
            variant="ghost"
            color="gray"
            size="sm"
          />
          @if (notificationsCount > 0) {
            <span class="absolute right-0 top-0 rounded-full bg-red-500 px-1 text-xs text-white">
              {{ notificationsCount > 99 ? "99+" : notificationsCount }}
            </span>
          }
          @if (notificationsOpened) {
            <div class="notif-ctn scrollbar absolute">
              <div class="notif-ctn-header relative flex justify-center">
                <span>{{ t("v2-layout.notifications", { count: notifications.length }) }}</span>
              </div>
              <div class="scrollbar notif-box-ctn relative">
                @for (notification of notifications; track notification) {
                  <app-notification-card-switch
                    [notification]="notification"
                    [small]="true"
                  ></app-notification-card-switch>
                }
                @if (!notifications.length) {
                  <span class="no-notif block text-center">{{ t("default-layout.no_notifications") }}</span>
                }
                @if (notificationsCount > 50) {
                  <span class="mb-3 font-bold">…</span>
                }
              </div>
              <div class="show-more-ctn relative flex items-center justify-center">
                <button
                  class="btn btn-primary show-more-btn"
                  routerLink="/notifications-center"
                  queryParamsHandling="merge"
                  (click)="notificationsOpened = false"
                >
                  {{ t("common.show_more") }}
                </button>
              </div>
            </div>
          }
        </div>
        <IButton
          [icon]="ICON_SETTINGS"
          tooltipValue="{{ t('common.settings') }}"
          variant="ghost"
          color="gray"
          size="sm"
          [matMenuTriggerFor]="settingsMenu"
        />
        <mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
          <ul class="flex flex-col">
            @for (item of PROFILE_ITEMS; track item) {
              @if (
                !item.supportedBoards ||
                item.supportedBoards.includes(boardType) ||
                (item.label === "Billing" && isBillingOwnerOfActiveSubscription)
              ) {
                <li class="dd-item p-0">
                  @if (item.href) {
                    <a [attr.href]="item.href" [attr.target]="item.target" class="px-2 py-1">
                      <span [class]="item.icon + ' mr-2 text-xl text-gray-700'"></span>
                      {{ item.label }}
                    </a>
                  } @else {
                    <a
                      [routerLink]="item.routerLink"
                      [queryParamsHandling]="item.queryParamsHandling"
                      class="px-2 py-1"
                      (click)="item.onClick ? item.onClick() : null"
                    >
                      <span [class]="item.icon + ' text-gray-7000 mr-2 text-xl'"></span>
                      {{ item.label }}
                    </a>
                  }
                </li>
              }
            }
          </ul>
        </mat-menu>
        <IButton
          [icon]="ICON_QUESTION"
          tooltipValue="{{ t('common.help') }}"
          [href]="boardType === BoardType.M19 ? 'https://help.m19.com/' : whitelabelContactUsLink"
          target="_blank"
          variant="ghost"
          color="gray"
          size="sm"
        />
      </div>
    </div>
  </div>

  <div class="flex w-full flex-1 flex-row overflow-hidden">
    <app-v2-sidebar></app-v2-sidebar>

    <!-- Main content -->
    <div class="min-w-[1200px] flex-1">
      <div class="h-full min-w-[1200px] flex-1 overflow-auto p-4">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
