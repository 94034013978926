import { Component, Input, OnInit } from "@angular/core";
import {
  AccountMarketplace,
  AccountMarketplaceEx,
  AccountMarketplaceService,
  AuthService,
  BillingService,
  Organization,
  OrganizationService,
} from "@front/m19-services";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { IBadgeComponent, IButtonComponent } from "@front/m19-ui";
import { SpinnerComponent } from "@m19-board/spinner/spinner.component";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";

@UntilDestroy()
@Component({
  standalone: true,
  imports: [IBadgeComponent, SpinnerComponent, IButtonComponent, TranslocoRootModule],
  templateUrl: "./self-service-transfer.component.html",
})
export class SelfServiceTransferModalComponent implements OnInit {
  @Input()
  childOrganization: Organization;

  @Input()
  accountMarketplaces: AccountMarketplace[];

  parentOrganization: Organization;
  canBeTransferred: boolean;
  missingAccounts: AccountMarketplace[];
  targetAccountGroupName = new Map<number, string>();
  loading = false;

  missingAccountValue = "Not found in Agency Organization";

  constructor(
    private accountMarketplaceService: AccountMarketplaceService,
    private organizationService: OrganizationService,
    private billingService: BillingService,
    public bsModalRef: BsModalRef,
    private toastrService: ToastrService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.loading = false;
    this.organizationService.organizations$.pipe(untilDestroyed(this)).subscribe((orgs: Organization[]) => {
      this.parentOrganization = orgs.find((x) => x.organizationId === this.childOrganization.parentOrganizationId);
    });

    this.accountMarketplaceService.accountMarketplaces$
      .pipe(untilDestroyed(this))
      .subscribe((orgs: AccountMarketplaceEx[]) => {
        for (const acc of this.accountMarketplaces) {
          this.targetAccountGroupName.set(acc.profileId, this.missingAccountValue);
        }

        orgs
          .filter((x) => x.resourceOrganizationId === this.childOrganization.parentOrganizationId)
          .filter((x) => this.targetAccountGroupName.has(x.profileId))
          .forEach((x) => this.targetAccountGroupName.set(x.profileId, x.accountGroupName));

        this.missingAccounts = this.accountMarketplaces.filter(
          (x) => this.targetAccountGroupName.get(x.profileId) === this.missingAccountValue,
        );

        this.canBeTransferred = this.missingAccounts.length === 0;
      });
  }

  submit() {
    this.loading = true;
    this.billingService.transferManagementService(
      this.childOrganization.organizationId,
      () => {
        this.toastrService.success("Management successfully transferred");
        this.loading = false;
        this.authService.reloadUser();
        this.bsModalRef.hide();
      },
      (err) => {
        this.toastrService.error(err.message, "Management transfer failure");
        this.loading = false;
      },
    );
  }
}
