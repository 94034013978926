import { LocaleService } from "@ag-grid-community/core";
import { Injectable } from "@angular/core";
import { AuthService } from "@front/m19-services";
import { getBrowserLang, TranslocoService } from "@jsverse/transloco";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
// import { defineLocale } from "ngx-bootstrap/chronos";
// import { frLocale } from "ngx-bootstrap/locale";
//
// defineLocale("fr", frLocale);

@Injectable({
  providedIn: "root",
})
export class I18nService {
  private static readonly languageStorageKey = "language";

  public readonly language: BehaviorSubject<string>;

  constructor(
    private translocoService: TranslocoService,
    private authService: AuthService,
    private bsLocaleService: BsLocaleService,
  ) {
    this.language = new BehaviorSubject<string>(
      localStorage.getItem(I18nService.languageStorageKey) ?? getBrowserLang() ?? "en",
    );
    this.authService.user$.subscribe((user) => {
      if (!user) {
        this.changeLanguage(localStorage.getItem(I18nService.languageStorageKey) ?? getBrowserLang() ?? "en");
      } else {
        // TODO: we should use the user's language here
        this.changeLanguage(localStorage.getItem(I18nService.languageStorageKey) ?? "en");
      }
    });
  }

  // should be called in app.component.ts
  public initLanguage(): void {
    this.translocoService.setActiveLang(this.language.value);
    // this.bsLocaleService.use(this.language.value);
  }

  // call this method to change the language
  public changeLanguage(lang: string): void {
    if (lang == this.language.value) {
      return;
    }
    this.translocoService.setActiveLang(lang);
    // this.bsLocaleService.use(lang);
    this.language.next(lang);
  }

  // used in debug mode
  public changeLanguageInLocalStorage(lang: string): void {
    localStorage.setItem(I18nService.languageStorageKey, lang);
    this.changeLanguage(lang);
  }
}
