import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AuthService, ConfigService, getStrategies, StrategyEx, StrategyGroupEx } from "@front/m19-services";
import { BehaviorSubject, map, switchMap } from "rxjs";

@UntilDestroy()
@Component({
  template: `@if (strategyGroup && strategy) {
    <app-sp-substrategy
      [strategyGroup]="strategyGroup"
      [strategy$]="strategy$"
      [strategy]="strategy"
    ></app-sp-substrategy>
  }`,
})
export class SpSubStrategyPageComponent implements OnInit {
  strategyGroup: StrategyGroupEx;
  strategy$ = new BehaviorSubject<StrategyEx>(undefined); // TODO: remove this
  strategy: StrategyEx;
  strategyGroupUrl = "/strategies/strategy-group/sponsored-product/";
  legacyStrategyUrl = "/strategies/sponsored-product";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private configurationService: ConfigService,
    private authService: AuthService,
  ) {
    this.authService.loggedUser$.subscribe((user) => {
      if ((user?.uiVersion ?? 0) > 0) {
        this.legacyStrategyUrl = "/advertising/sponsored-product";
        this.strategyGroupUrl = "/strategies/strategy-group/sponsored-product/";
      }
    });
  }

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        switchMap((params) => {
          const id = Number(params.get("id"));
          const subId = Number(params.get("subid"));
          return this.configurationService.getStrategyGroupById(id).pipe(
            map((strategyGroup: StrategyGroupEx) => {
              if (!strategyGroup) {
                return this.legacyStrategyUrl;
              }
              const strategies = getStrategies(strategyGroup);
              // do not display subsctrategy page when strategy group has more than one strategy
              if (strategies.length != 1) {
                return this.strategyGroupUrl + id;
              }
              const strategy = strategies.find((s) => s.strategyId == subId);
              if (!strategy) {
                return this.strategyGroupUrl + id;
              }
              return { strategyGroup, subStrategy: strategy };
            }),
          );
        }),
        untilDestroyed(this),
      )
      .subscribe((res: { strategyGroup: StrategyGroupEx; subStrategy: StrategyEx } | string) => {
        // redirect to strategy page if the strategy group cannot be found
        if (typeof res == "string") {
          this.router.navigate([res], { queryParamsHandling: "merge" });
          return;
        }
        this.strategyGroup = res.strategyGroup;
        this.strategy$.next(res.subStrategy);
        this.strategy = res.subStrategy;
      });
  }
}
