import { Component, Input, OnInit } from "@angular/core";
import {
  AlgoMode,
  AlgoTarget,
  ConfigService,
  Currency,
  currencyRateToEuro,
  CurrencyService,
  StrategyEx,
  StrategyUpdateParams,
  Utils,
} from "@front/m19-services";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { tap } from "rxjs";
import moment from "moment-timezone";
import { ICON_SYNC, ICON_TRASH_O } from "@m19-board/utils/iconsLabels";

@Component({
  selector: "app-switch-target-algo-modal",
  templateUrl: "./switch-target-algo-modal.component.html",
  styleUrls: ["./switch-target-algo-modal.component.scss"],
})
export class SwitchTargetAlgoModalComponent implements OnInit {
  @Input() locale: string;

  @Input() algoTarget: AlgoTarget;
  @Input() minBid: number;

  updateLoading = false;
  currencySymbol: string;
  currencyCode: Currency;

  acosTarget: number;
  suggestedBid: number;
  dailyBudget: number;
  monthlyBudget: number;
  nextMonthlyBudget: number;
  nextMonth: Date;
  endOfCurrentMonth: Date;
  currentMonthSpend: number;
  protected readonly AlgoMode = AlgoMode;
  protected readonly Math = Math;

  readonly ICON_TRASH = ICON_TRASH_O;
  readonly ICON_SYNC = ICON_SYNC;

  constructor(
    public bsModalRef: BsModalRef,
    private configService: ConfigService,
    private toasterService: ToastrService,
    private currencyService: CurrencyService,
  ) {}

  ngOnInit(): void {
    this.acosTarget = this.algoTarget.acosTarget;
    this.dailyBudget = this.algoTarget.dailyBudget;
    this.suggestedBid = this.algoTarget.suggestedBid;
    this.monthlyBudget = this.algoTarget.monthlyBudget;
    this.nextMonthlyBudget = this.algoTarget.nextMonthlyBudget;
    this.currentMonthSpend = this.algoTarget.currentMonthSpend;
    const today = moment(this.algoTarget.today, "YYYY-MM-DD");
    this.endOfCurrentMonth = today.endOf("month").toDate();
    this.nextMonth = today.startOf("month").add(1, "M").toDate();

    this.currencySymbol = this.currencyService.getCurrencySymbolFromMarketplace(this.algoTarget.marketplace);
    this.currencyCode = this.currencyService.getCurrencyCode(this.algoTarget.marketplace);
  }

  getTargetName(): string {
    switch (this.algoTarget.algorithm) {
      case AlgoMode.ACOS_TARGET:
        return "ACOS target";
      case AlgoMode.PRODUCT_LAUNCH:
        return "Suggested bid / Daily budget";
      case AlgoMode.MONTHLY_BUDGET_TARGET:
        return "Monthly budget";
    }
  }

  deleteDailyBudget() {
    this.dailyBudget = null;
    this.update();
  }

  update(): void {
    const updateParams: StrategyUpdateParams = {
      accountId: this.algoTarget.accountId,
      marketplace: this.algoTarget.marketplace,
      strategyId: this.algoTarget.strategyId,
      asinsToAdd: [],
      asinsToDelete: [],
    };

    if (this.algoTarget.algorithm === AlgoMode.ACOS_TARGET) {
      updateParams.acosTarget = this.acosTarget;
      if (this.dailyBudget && this.dailyBudget < this.getDailyBudgetLowerBound()) return;

      updateParams.dailyBudget = this.dailyBudget;
      this.updateLoading = true;
      this.updateStrategy(updateParams);
    }
    if (this.algoTarget.algorithm === AlgoMode.PRODUCT_LAUNCH) {
      if (this.suggestedBid < this.minBid) return;
      updateParams.suggestedBid = this.suggestedBid;
      updateParams.dailyBudget = this.dailyBudget;
      this.updateLoading = true;
      this.updateStrategy(updateParams);
    }
    if (this.algoTarget.algorithm === AlgoMode.MONTHLY_BUDGET_TARGET) {
      this.updateLoading = true;
      this.updateMonthlyBudget();
    }
  }

  updateStrategy(updateParams: StrategyUpdateParams) {
    this.configService
      .updateStrategyAsync(updateParams)
      .pipe(tap(() => (this.updateLoading = true)))
      .subscribe({
        next: (strategy: StrategyEx) => {
          this.toasterService.success(`${this.getTargetName()} updated for strategy ${strategy.name}`);
          this.bsModalRef.hide();
          this.updateLoading = false;
        },
        error: (e) => {
          this.toasterService.error(`Error updating ${this.getTargetName()}: ${e}`);
          this.bsModalRef.hide();
          this.updateLoading = false;
        },
      });
  }

  updateMonthlyBudget() {
    this.configService
      .updateStrategyMonthlyBudgetAsync(
        this.algoTarget.accountId,
        this.algoTarget.marketplace,
        this.algoTarget.strategyId,
        this.monthlyBudget,
        this.nextMonthlyBudget,
        Utils.formatMonthForApi(this.endOfCurrentMonth),
      )
      .pipe(tap(() => (this.updateLoading = true)))
      .subscribe({
        next: (strategy: StrategyEx) => {
          this.toasterService.success(`Monthly budget updated for strategy ${strategy.name}`, "Strategy updated");
          this.bsModalRef.hide();
          this.updateLoading = false;
        },
        error: (e) => {
          this.toasterService.error(`Error when updating Monthly budget: ${e}`, "Strategy update error");
          this.bsModalRef.hide();
          this.updateLoading = false;
        },
      });
  }

  minAllowedBid() {
    const result = 0.1 / currencyRateToEuro(this.currencyCode as Currency);
    return Math.floor(result * 100 + Number.EPSILON) / 100;
  }

  getDailyBudgetLowerBound(): number {
    return Math.ceil(1 / currencyRateToEuro(this.currencyCode as Currency));
  }

  getMaxSuggestedBid(dailyBudget: number) {
    return Math.floor((dailyBudget / 5) * 100 + Number.EPSILON) / 100;
  }
}
