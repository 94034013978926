<ng-container *transloco="let t">
  <app-stats-overlay>
    @if (!loading && marketplacesWithoutData.length > 0) {
      @if (vendorSelected) {
        <div>{{ t("profit-and-loss.p_and_l_not_available_for_vendors") }}</div>
      }
      {{ t("profit-and-loss.no_data_for_marketplace", { count: marketplacesWithoutData.length }) }}:
    }
    <div>
      <div class="box card border-shadow left-child right-child p-4">
        @if (loading) {
          <app-spinner size="s" [display]="true" [main]="false" type="default"></app-spinner>
        } @else {
          <div class="mb-2 text-right">
            <app-export-button
              (export)="downloadFile()"
              tooltipValue="{{ t('common.export_as_csv') }}"
            ></app-export-button>
          </div>
          <table mat-table [dataSource]="dataSource" class="table">
            <ng-container matColumnDef="metric">
              <mat-header-cell *matHeaderCellDef [ngClass]="'w-200'">{{
                t("profit-and-loss.pl_in_currency", [currency])
              }}</mat-header-cell>
              <mat-cell *matCellDef="let row" [ngClass]="'w-200'">
                <a
                  (click)="toggleMetricDetails(row)"
                  class="child-expand link p-2"
                  [ngClass]="{ hidden: !row.isGlobalMetric || !DETAILED_METRICS.has(row.metricId) }"
                >
                  <fa-icon
                    class="icon-detail"
                    [icon]="detailedMetricIds.has(row.metricId) ? faMinus : faPlus"
                  ></fa-icon>
                </a>
                <span [ngClass]="{ tab: !row.isGlobalMetric }" [matTooltip]="row.tooltip ? row.tooltip : null">
                  {{ row.name }}
                  @if (row.tooltip) {
                    <fa-icon [icon]="info" class="icon-info"></fa-icon>
                  }
                </span>
              </mat-cell>
            </ng-container>
            @for (date of displayedColumns | slice: 1; track date) {
              <ng-container matColumnDef="{{ date }}">
                <mat-header-cell class="right-align" *matHeaderCellDef>{{ date }}</mat-header-cell>
                <mat-cell class="right-align" *matCellDef="let row"> {{ row.data.get(date) }}</mat-cell>
              </ng-container>
            }
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </table>
        }
        @if (minMonthWithoutEstimation) {
          <div>{{ t("profit-and-loss.amount_estimated", [minMonthWithoutEstimation]) }}</div>
        }
      </div>
    </div>
  </app-stats-overlay>
</ng-container>
