import { Component, Input, Signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { faPauseCircle } from "@fortawesome/free-regular-svg-icons";
import { faPause, faPlay, faPlayCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import {
  AccountMarketplace,
  AccountSelectionService,
  AlgoMode,
  AuthService,
  CampaignType,
  ConfigService,
  Currency,
  Marketplaces,
  ProductGroupEx,
  StrategyGroupEx,
  StrategyStateEnum,
  User,
} from "@front/m19-services";
import { ConfirmPopupComponent } from "@m19-board/shared/confirm-popup/confirm-popup.component";
import { StrategyLimitationModalComponent } from "@m19-board/strategies/strategy-limitation-modal/strategy-limitation-modal.component";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { map, switchMap, take } from "rxjs";
import { FblModeSize } from "../../strategies/fbl-mode/fbl-mode.component";

@Component({
  selector: "app-sp-strategy-group-card",
  templateUrl: "./sp-strategy-group-card.component.html",
  styleUrls: ["./sp-strategy-group-card.component.scss"],
})
export class SPStrategyGroupCardComponent {
  @Input() strategyGroup: StrategyGroupEx;
  @Input() readonly: boolean;

  readonly faTrash = faTrashAlt;
  readonly faPause = faPause;
  readonly faPlay = faPlay;
  readonly faPlayCircle = faPlayCircle;
  readonly faPauseCircle = faPauseCircle;
  readonly State = StrategyStateEnum;
  readonly AlgoMode = AlgoMode;

  readonly currency: Signal<Currency>;
  readonly locale: Signal<string>;
  private organizationId: number;
  readonly MaxAsins = ProductGroupEx.MaxProductGroupItems;

  submenu = false;
  submenuhover = false;
  strategyGroupUrlPrefix = "/strategies/strategy-group/sponsored-product/";

  constructor(
    private configService: ConfigService,
    private toastrService: ToastrService,
    private authService: AuthService,
    private modalService: BsModalService,
    private accountSelectionService: AccountSelectionService,
  ) {
    this.locale = toSignal(this.authService.loggedUser$.pipe(map((user: User) => user.locale)));
    this.currency = toSignal(
      this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(
        map((selection) => Marketplaces[selection.marketplace].currency),
      ),
    );
    this.accountSelectionService.singleAccountMarketplaceSelection$.subscribe((selection: AccountMarketplace) => {
      this.organizationId = selection.resourceOrganizationId;
    });
    this.authService.loggedUser$.subscribe((user) => {
      if ((user?.uiVersion ?? 0) > 0) {
        this.strategyGroupUrlPrefix = "/advertising/sponsored-product/strategy-group/";
      }
    });
  }

  deleteStrategyGroup() {
    const modalOptions: ModalOptions = {
      initialState: {
        title: "Strategy Group Deletion",
        message: `Are you sure you want to delete strategy group "${this.strategyGroup.strategyGroupName}"?`,
        confirmCta: "Delete",
      },
      class: "modal-danger",
    };
    const modalRef = this.modalService.show(ConfirmPopupComponent, modalOptions);
    modalRef.content.confirm
      .pipe(switchMap(() => this.configService.deleteStrategyGroup(this.strategyGroup.strategyGroupId).pipe(take(1))))
      .subscribe({
        next: () => {
          this.toastrService.success("Strategy group deleted");
        },
        error: (err) => {
          this.toastrService.error(err, "Strategy group deletion error");
        },
      });
  }

  hasLiveStategies() {
    return this.strategyGroup.strategies.some((strategy) => strategy.state === StrategyStateEnum.ENABLED);
  }

  changeStatus(): void {
    // status update is allowed only if there is a single main strategy
    if (this.strategyGroup.strategies.length !== 1) {
      return;
    }
    const newState =
      this.strategyGroup.strategies[0].state == StrategyStateEnum.ENABLED
        ? StrategyStateEnum.PAUSED
        : StrategyStateEnum.ENABLED;
    if (newState == StrategyStateEnum.ENABLED && this.configService.isStrategyLimitReached(CampaignType.SP)) {
      const modalOptions: ModalOptions = {
        initialState: {
          liveStrategyLimit: this.configService.getLiveStrategyLimit(),
          campaignType: CampaignType.SP,
        },
        class: "modal-primary",
      };
      this.modalService.show(StrategyLimitationModalComponent, modalOptions);
      return;
    }
    const sub = this.configService.updateStrategyState(
      this.strategyGroup.accountId,
      this.strategyGroup.marketplace,
      this.strategyGroup.strategies[0].strategyId,
      newState,
    );
  }

  protected readonly FblModeSize = FblModeSize;
}
