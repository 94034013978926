import { AgGridAngular } from "@ag-grid-community/angular";
import { ColDef, GridOptions, ValueFormatterParams, ValueGetterParams } from "@ag-grid-community/core";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import {
  DspAdgroup,
  DspAdgroupStateEnum,
  DspDeliveryStatus,
  DspStats,
  getBasicGridOptions,
  SIDE_BAR_NO_PIVOT,
} from "@front/m19-services";
import { TranslocoService } from "@jsverse/transloco";
import { exportGridCsv, getMetricsColDef } from "@m19-board/grid-config/grid-config";
import { BsModalRef } from "ngx-bootstrap/modal";
import { DspDeliveryStatusDesc, DspMetrics, sumDspStats } from "./dsp-stats.component";

@Component({
  selector: "app-dsp-stats-details-modal",
  template: `
    <div class="modal-header bg-primary">
      <h4 class="modal-title">
        <span>{{ title }}</span>
      </h4>
      <h4 class="text-secondary pointer" (click)="close()">&times;</h4>
    </div>
    <div class="modal-body" *transloco="let t">
      <div class="mb-2 flex w-full items-center justify-end gap-1">
        <IButton
          [tooltipValue]="t('common.restore_default_columns')"
          [label]="t('common.restore_columns')"
          color="white"
          (onClick)="restoreDefaultColumns()"
        />
        <app-export-button [tooltipValue]="t('common.export_as_csv')" (export)="exportGridCsv()" />
      </div>
      <div class="ag-theme-quartz grid">
        <ag-grid-angular
          #grid
          [rowData]="rowData"
          [gridOptions]="gridOptions"
          [animateRows]="true"
          class="h-[40vh] w-full"
        >
        </ag-grid-angular>
      </div>
    </div>
  `,
})
export class DspStatsDetailsModalComponent implements OnInit {
  @Input()
  title: string = "";
  @Input()
  lineItemsData: DspStats[] = [];
  @Input()
  lineItems: Map<string, DspAdgroup> = new Map();
  @Input()
  locale: string = "";
  @Input()
  currency: string = "";

  rowData: DspLineItemStats[] = [];
  readonly gridOptions: GridOptions<DspLineItemStats> = {
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
    },
    ...getBasicGridOptions("dsp_adgroup_stats", true, false, true, sumDspStats as any), // TODO: ugly code here to force aggregation function
    sideBar: SIDE_BAR_NO_PIVOT,
    columnDefs: [
      {
        headerValueGetter: () => this.translocoService.translate("dsp-stats.line_item"),
        field: "name",
        floatingFilter: true,
        filter: "agTextColumnFilter",
        pinned: "left",
      },
      {
        headerValueGetter: () => this.translocoService.translate("dsp-stats.state"),
        field: "state",
        pinned: "left",
        valueFormatter: (params: ValueFormatterParams<DspLineItemStats, DspAdgroupStateEnum>) =>
          params.value ? this.translocoService.translate(DspLineItemState[params.value]) : "",
        floatingFilter: true,
        filter: "agSetColumnFilter",
        filterValueGetter: (params: ValueGetterParams<DspLineItemStats, any>) =>
          params.data?.state ? this.translocoService.translate(DspLineItemState[params.data.state]) : "",
      },
      {
        headerValueGetter: () => this.translocoService.translate("dsp-stats.deliveryStatus"),
        field: "deliveryStatus",
        pinned: "left",
        valueFormatter: (params: ValueFormatterParams<DspLineItemStats, DspDeliveryStatus>) =>
          params.value ? this.translocoService.translate(DspDeliveryStatusDesc[params.value]) : "",
        filterValueGetter: (params: ValueGetterParams<DspLineItemStats, any>) =>
          params.data?.deliveryStatus
            ? this.translocoService.translate(DspDeliveryStatusDesc[params.data.deliveryStatus])
            : "",
        floatingFilter: true,
        filter: "agSetColumnFilter",
      },
      ...(getMetricsColDef(DspMetrics) as ColDef<DspLineItemStats, any>[]).map((def) => ({
        ...def,
        cellRendererParams: (params: any) => {
          return {
            ...def.cellRendererParams(params),
            currency: this.currency,
            locale: this.locale,
          };
        },
      })),
      {
        headerValueGetter: () => this.translocoService.translate("common.currency"),
        colId: "currency",
        field: "currency",
        suppressFiltersToolPanel: true,
        hide: true,
        valueGetter: () => this.currency,
      },
    ],
  };

  @ViewChild("grid") grid!: AgGridAngular;

  constructor(
    private modalRef: BsModalRef,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    const dataPerLineItems = new Map<string, DspLineItemStats>();
    for (const stat of this.lineItemsData) {
      if (!stat.lineItemId) {
        continue;
      }
      if (!dataPerLineItems.has(stat.lineItemId)) {
        const lineItem = this.lineItems.get(stat.lineItemId);
        if (!lineItem) {
          continue;
        }
        dataPerLineItems.set(stat.lineItemId, {
          ...stat,
          name: lineItem.name,
          state: lineItem.state,
          deliveryStatus: lineItem.deliveryStatus,
        });
      } else {
        const existing = dataPerLineItems.get(stat.lineItemId);
        if (existing) {
          dataPerLineItems.set(stat.lineItemId, {
            ...existing,
            ...sumDspStats(existing, stat),
          });
        }
      }
    }
    this.rowData = Array.from(dataPerLineItems.values());
  }

  close() {
    this.modalRef.hide();
  }

  restoreDefaultColumns() {
    this.grid.api?.resetColumnState();
    this.grid.api?.autoSizeAllColumns();
  }

  exportGridCsv() {
    const columnKeys: string[] = this.grid?.api
      .getAllDisplayedColumns()
      .map((c) => c.getColId())
      .concat(["currency"]);
    // add translations
    this.setCsvColumnHeader("name", "dsp-stats.line_item");
    this.setCsvColumnHeader("state", "dsp-stats.state");
    this.setCsvColumnHeader("deliveryStatus", "dsp-stats.deliveryStatus");
    this.setCsvColumnHeader("currency", "common.currency");

    exportGridCsv(this.grid?.api, { fileName: "dsp_line_item_stats", columnKeys, skipColumnGroupHeaders: true });
  }

  private setCsvColumnHeader(colId: string, translationKey: string): void {
    const columnDef = this.grid.api?.getColumnDef(colId);
    if (columnDef) {
      columnDef.headerName = this.translocoService.translate(translationKey);
    }
  }
}

type DspLineItemStats = DspStats & {
  name?: string;
  state?: DspAdgroupStateEnum;
  deliveryStatus?: DspDeliveryStatus;
};

const DspLineItemState: { [key in DspAdgroupStateEnum]: string } = {
  [DspAdgroupStateEnum.ACTIVE]: "dsp-stats.adgroupstate_active",
  [DspAdgroupStateEnum.INACTIVE]: "dsp-stats.adgroupstate_inactive",
};
