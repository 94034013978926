@if (this.to()) {
  <a
    [attr.data-testid]="testid()"
    [class]="buttonConfig()"
    [routerLink]="to()"
    [queryParams]="queryParams()"
    [target]="target()"
    [queryParamsHandling]="queryParamsHandling()"
    (click)="handleClick($event)"
    class="hover:no-underline"
  >
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </a>
} @else if (this.href()) {
  <a
    [class]="buttonConfig()"
    [routerLink]="href()"
    [attr.data-testid]="testid()"
    [target]="target()"
    (click)="handleClick($event)"
    class="hover:no-underline"
  >
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </a>
} @else {
  <button
    [attr.data-testid]="testid()"
    [disabled]="disabled() || loading()"
    [type]="type()"
    [class]="buttonConfig()"
    (click)="handleClick($event)"
  >
    @if (loading()) {
      <ng-container [ngTemplateOutlet]="spinner"></ng-container>
    }
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </button>
}

<ng-template #content>
  @if (icon() && !trailing()) {
    <span [ngClass]="iconConfig()"></span>
  }
  @if (label()) {
    <span class="active:transform-none">{{ label() }}</span>
  }
  @if (icon() && trailing()) {
    <span [ngClass]="iconConfig()"></span>
  }
</ng-template>

<ng-template #spinner>
  <span class="icon-[mdi--loading] animate-spin text-xl"></span>
</ng-template>
