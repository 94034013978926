import { Component, Input, signal } from "@angular/core";
import {
  AccountMarketplace,
  AccountMarketplaceService,
  AmazonUser,
  AmazonUserService,
  DspAdvertiser,
  Marketplaces,
} from "@front/m19-services";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-link-dsp-modal",
  template: `
    <div class="modal-header">
      <div class="modal-title text-xl ">
        Link a DSP Advertiser to
        <span class="font-bold"
          >{{ accountMarketplace.accountName }} {{ Marketplaces[accountMarketplace.marketplace].flag }}</span
        >
      </div>
    </div>
    <div class="modal-body">
      @if (loading()) {
        <div class="flex w-full items-center justify-center">
          <app-spinner [display]="true"></app-spinner>
        </div>
      } @else if (dspAdvertisers === undefined || dspAdvertisers.length === 0 || errorMessage) {
        @if (errorMessage || dspAdvertisers !== undefined) {
          <div class="p-2">
            <div class="border-l-4 border-orange-500 bg-orange-100 p-4 text-orange-700">
              {{ errorMessage ?? "No DSP Advertisers found. Retry Amazon login." }}
            </div>
          </div>
        }
        <div class="p-2">
          <div class="full flex items-center justify-center gap-2">
            <div>Login with Amazon to link your DSP Advertiser</div>
            <span class="icon-[mdi--arrow-right-thick] text-main-500 float-right animate-pulse text-2xl"></span>
            <input
              class="amazon-login-button"
              type="image"
              (click)="logWithAmazon()"
              src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gry_156x132.png"
            />
          </div>
        </div>
      } @else if (dspAdvertisers.length === 1) {
        <div class="mb-4">Can you confirm you want to link the following DSP Advertiser?</div>
        <ICard class="!bg-main-50 ring-main-300 ring-2">
          <ng-template #body>
            <div class="mb-2 flex gap-3">
              <div class="text-main-500 font-semibold">{{ dspAdvertisers[0].name }}</div>
              <a [href]="dspAdvertisers[0].url" target="_blank" class="text-main-500">{{ dspAdvertisers[0].url }}</a>
            </div>
            <IBadge
              color="gray"
              size="xs"
              label="ID: {{ dspAdvertisers[0].dspAdvertiserId }}"
              tooltipValue="This is the DSP Advertiser ID"
            ></IBadge>
          </ng-template>
        </ICard>
      } @else if (dspAdvertisers.length > 1) {
        <div>Select the DSP Advertiser you want to link:</div>
        @for (dspAdvertiser of dspAdvertisers; track dspAdvertiser.dspAdvertiserId) {
          <ICard
            [class]="
              'group relative mt-4 cursor-pointer pl-4 duration-75 hover:bg-gray-100' +
              (dspAdvertiser === selectedDspAdvertiser ? '!bg-main-50 ring-main-300 ring-2' : '')
            "
            (click)="selectDspAdvertiser(dspAdvertiser)"
          >
            <ng-template #body>
              @if (dspAdvertiser === selectedDspAdvertiser) {
                <span
                  class="icon-[material-symbols--check-circle-rounded] text-main-500 absolute left-1 top-1 text-xl"
                ></span>
              }
              <div class="mb-2 flex gap-3">
                <div class="text-main-500 font-semibold">{{ dspAdvertiser.name }}</div>
                <a [href]="dspAdvertiser.url" target="_blank" class="text-main-500">{{ dspAdvertiser.url }}</a>
              </div>
              <IBadge
                color="gray"
                size="xs"
                label="ID: {{ dspAdvertiser.dspAdvertiserId }}"
                tooltipValue="This is the DSP Advertiser ID"
              ></IBadge>
            </ng-template>
          </ICard>
        }
      }
    </div>
    <div class="modal-footer">
      @if (selectedDspAdvertiser) {
        <button type="button" class="btn btn-primary" (click)="save()">Confirm</button>
      }
      <button type="button" class="btn btn-secondary" (click)="close()">Exit</button>
    </div>
  `,
})
@UntilDestroy()
export class LinkDspModalComponent {
  @Input()
  accountMarketplace: AccountMarketplace;

  readonly Marketplaces = Marketplaces;
  loading = signal(false);
  dspAdvertisers: DspAdvertiser[];
  selectedDspAdvertiser: DspAdvertiser;
  errorMessage: string;

  constructor(
    private amazonUserService: AmazonUserService,
    private modalRef: BsModalRef,
    private toastrService: ToastrService,
    private accountMarketplaceService: AccountMarketplaceService,
  ) {}

  logWithAmazon(): void {
    this.loading.set(true);
    this.selectedDspAdvertiser = undefined;
    this.amazonUserService.getAmazonUser(
      this.accountMarketplace.resourceOrganizationId,
      (amazonUser: AmazonUser) => {
        this.loading.set(false);
        this.dspAdvertisers = (amazonUser.dspAdvertisers ?? []).filter(
          (dspAdvertiser) => dspAdvertiser.marketplace === this.accountMarketplace.marketplace,
        );
        if (this.dspAdvertisers.length > 0) {
          this.selectedDspAdvertiser = this.dspAdvertisers[0];
        }
      },
      (errorMessage: string) => {
        this.loading.set(false);
        this.toastrService.error(errorMessage);
        this.errorMessage = errorMessage;
      },
    );
  }

  selectDspAdvertiser(dspAdvertiser: DspAdvertiser): void {
    this.selectedDspAdvertiser = dspAdvertiser;
  }

  save(): void {
    this.accountMarketplaceService
      .linkDspAdvertiser(this.accountMarketplace, this.selectedDspAdvertiser.dspAdvertiserId)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.toastrService.success("DSP Advertiser linked successfully");
          this.modalRef.hide();
        },
        error: (error) => {
          this.errorMessage = error;
          this.toastrService.error(error, "Error linking DSP Advertiser");
        },
      });
  }

  close(): void {
    this.modalRef.hide();
  }
}
