import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  AccountMarketplace,
  AccountSelectionService,
  InventoryStats,
  AsinService,
  AuthService,
  ConfigService,
  Currency,
  Marketplace,
  ProductGroupEx,
  ProductGroupService,
  StrategyEx,
  UserSelectionService,
  InventoryService,
  InventoryRules,
  RuleExecutionResultEnum,
} from "@front/m19-services";
import { isProductGroupEx } from "@m19-board/shared/strategy-product-group-hybrid-dropdown/strategy-product-group-hybrid-dropdown.component";
import { BehaviorSubject, combineLatest, of } from "rxjs";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { StrategyCache } from "libs/m19-services/src/lib/m19-services/strategy.cache";

export enum InventoryAsinsFilter {
  LowInventory = 0,
}

type DataFilter = StrategyEx | ProductGroupEx | InventoryAsinsFilter;

@UntilDestroy()
@Component({
  selector: "app-inventory",
  templateUrl: "./inventory.component.html",
  styleUrls: ["./inventory.component.scss"],
})
export class InventoryComponent implements OnInit {
  productGroups: ProductGroupEx[];
  strategies: StrategyEx[];
  private selectedStrategyOrProductGroup: ProductGroupEx | StrategyEx = null;
  private dataFilter$: BehaviorSubject<DataFilter> = new BehaviorSubject(null);

  loading = true;
  accountId: string;
  marketplace: Marketplace;
  currency: Currency = Currency.USD;
  locale: string;
  private inventoryStats: InventoryStats[] = [];
  filteredInventoryStats: InventoryStats[] = [];
  inventoryRules: InventoryRules;

  lowInventoryAsins: InventoryStats[] = [];
  lowInventoryAsinsPaused: InventoryStats[] = [];
  filterLowInventory = false;

  readonly faFilter = faFilter;

  constructor(
    private accountSelection: AccountSelectionService,
    private productGroupService: ProductGroupService,
    private asinService: AsinService,
    private inventoryService: InventoryService,
    private userSelectionService: UserSelectionService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private strategyCache: StrategyCache,
    private configService: ConfigService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      const filter = parseInt(params.filter) as InventoryAsinsFilter;
      switch (filter) {
        case InventoryAsinsFilter.LowInventory:
          this.dataFilter$.next(InventoryAsinsFilter.LowInventory);
          break;
        default:
        // not supported
      }
    });
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am: AccountMarketplace) => this.productGroupService.getProductGroups(am.accountId, am.marketplace)),
        untilDestroyed(this),
      )
      .subscribe((x) => (this.productGroups = x));
    this.strategyCache.strategyIndex$
      .pipe(untilDestroyed(this))
      .subscribe((s) => (this.strategies = Array.from(s.values())));
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => (this.locale = user.locale));
    this.userSelectionService.selectedCurrency$.pipe(untilDestroyed(this)).subscribe((c) => {
      this.currency = c;
    });

    // remove spinner when no account selected
    this.accountSelection.noAccountGroupSelected$.pipe(untilDestroyed(this)).subscribe(() => {
      this.loading = false;
    });

    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        tap((am: AccountMarketplace) => {
          this.marketplace = am.marketplace;
          this.accountId = am.accountId;
          this.loading = true;
          this.filterLowInventory = false;
          this.selectedStrategyOrProductGroup = null;
        }),
        switchMap((am: AccountMarketplace) =>
          combineLatest([
            this.inventoryService.getInventoryStats(am.accountId, am.marketplace),
            this.asinService.getInventoryRules(am.accountId, am.marketplace),
          ]),
        ),
      )
      .subscribe(([inventoryStats, rules]) => {
        this.inventoryRules = rules;
        this.inventoryStats = inventoryStats;
        this.filteredInventoryStats = this.inventoryStats;
        this.loading = false;
        this.lowInventoryAsins = [];
        this.lowInventoryAsinsPaused = [];
        for (const stat of this.inventoryStats) {
          const ruleExecution = rules.execute(stat.asin, stat);
          if (ruleExecution.detail == RuleExecutionResultEnum.LOW_STOCK) {
            this.lowInventoryAsins.push(stat);
          }
          if (ruleExecution.shouldPauseAdvertising) {
            this.lowInventoryAsinsPaused.push(stat);
          }
        }
        this.dataFilter$.next(this.dataFilter$.value);
      });
    this.dataFilter$
      .pipe(
        untilDestroyed(this),
        filter((filter) => filter === InventoryAsinsFilter.LowInventory),
      )
      .subscribe(() => {
        if (this.lowInventoryAsins.length > 0) {
          this.filteredInventoryStats = [...this.lowInventoryAsins];
          this.filterLowInventory = true;
          this.updateRouteUrl(InventoryAsinsFilter.LowInventory);
        }
      });
    this.dataFilter$
      .pipe(
        untilDestroyed(this),
        filter((filter) => filter !== InventoryAsinsFilter.LowInventory),
        switchMap((filter) => {
          if (filter && isProductGroupEx(filter)) {
            return of((asin: string) => filter.containsAsin(asin));
          }
          if (filter) {
            /* use a set to improve efficienty when dealing with big arrays */
            if (filter.name === "All other products" || filter.name === "All products")
              return this.configService
                .getStrategyAsinsAllOtherProducts(filter as StrategyEx)
                .pipe(map((stratAsins: Set<string>) => (asin: string) => stratAsins.has(asin)));

            return this.configService
              .getStrategyAsins(filter as StrategyEx)
              .pipe(map((stratAsins) => (asin: string) => stratAsins.map((sa) => sa.asin).includes(asin)));
          }
          return of((asin: string) => true);
        }),
      )
      .subscribe((filter) => {
        this.filteredInventoryStats = this.inventoryStats.filter((s) => filter(s.asin));
        this.filterLowInventory = false;
        this.updateRouteUrl();
      });
  }

  toggleLowInventoryFilter(): void {
    this.filterLowInventory = !this.filterLowInventory;
    if (this.filterLowInventory) {
      this.dataFilter$.next(InventoryAsinsFilter.LowInventory);
    } else {
      this.dataFilter$.next(this.selectedStrategyOrProductGroup);
    }
  }

  setStrategyOrProductGroup(strategyOrProductGroup: ProductGroupEx | StrategyEx): void {
    this.selectedStrategyOrProductGroup = strategyOrProductGroup;
    this.dataFilter$.next(strategyOrProductGroup ?? null);
  }

  private updateRouteUrl(filter?: InventoryAsinsFilter) {
    const queryParams = { filter: filter != null ? filter.toString() : null };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: "merge",
    });
  }
}
