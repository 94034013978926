import { formatNumber } from "@angular/common";
import { Currency, ProductEx } from "@front/m19-services";
import { PALETTE, printCurrency, ProductMetric } from "./Metric";

export class CategoryBsrMetric extends ProductMetric {
  constructor() {
    super({
      id: "BSR_CATEGORY",
      color: PALETTE[1],
      title: "Sub Category BSR",
      reverseAxis: true,
      tooltip: "Sub Category Best Seller Rank",
      minAtZero: false,
    });
  }

  public value(d: ProductEx) {
    return d?.categoryRank;
  }

  public format(d: ProductEx, locale: string) {
    const data = typeof d === "number" ? d : d?.categoryRank === null ? undefined : d?.categoryRank;
    return formatNumber(data, locale, "1.0-0");
  }

  public compare(d1: ProductEx, d2: ProductEx) {
    return d2.categoryRank - d1.categoryRank;
  }
}

export class DisplayGroupBsrMetric extends ProductMetric {
  constructor() {
    super({
      id: "BSR_DISPLAY_GROUP",
      color: PALETTE[2],
      title: "Display Group BSR",
      reverseAxis: true,
      tooltip: "Display Group Best Seller Rank",
      minAtZero: false,
    });
  }

  public value(d: ProductEx) {
    return d?.displayGroupRank;
  }

  public format(d: ProductEx, locale: string) {
    const data = typeof d === "number" ? d : d?.displayGroupRank === null ? undefined : d?.displayGroupRank;
    return formatNumber(data, locale, "1.0-0");
  }

  public compare(d1: ProductEx, d2: ProductEx) {
    return d2.displayGroupRank - d1.displayGroupRank;
  }
}

export class PriceMetric extends ProductMetric {
  private currency: Currency;

  constructor() {
    super({
      id: "PRICE",
      color: PALETTE[2],
      title: "Price",
      tooltip: "Price",
    });
  }

  public value(d: ProductEx) {
    return d?.price;
  }

  public valueForCsv(d: ProductEx): string {
    const value = this.value(d);
    if (isFinite(value)) {
      return value.toFixed(2);
    }
    return "-";
  }

  public format(d: number | ProductEx, locale?: string): string {
    const value = d === undefined ? 0 : typeof d === "number" ? d : this.value(d);
    return printCurrency(value, locale, this.currency, "1.2-2");
  }

  public compare(d1: ProductEx, d2: ProductEx) {
    return d1.price - d2.price;
  }

  public setCurrency(currency: Currency) {
    this.currency = currency;
  }
}

export class ReviewScoreMetric extends ProductMetric {
  constructor() {
    super({
      id: "REVIEW_SCORE",
      color: PALETTE[3],
      title: "Review Score",
      maxAt: 5,
      minAtZero: true,
      tooltip: "Average Review Score",
    });
  }

  public value(d: ProductEx) {
    return d?.reviewScore;
  }

  public format(d: ProductEx, locale: string) {
    const data = typeof d === "number" ? d : d?.reviewScore === null ? undefined : d?.reviewScore;
    return formatNumber(data, locale, "1.0-1");
  }

  public compare(d1: ProductEx, d2: ProductEx) {
    return d1.reviewScore - d2.reviewScore;
  }
}

export class ReviewsMetric extends ProductMetric {
  constructor() {
    super({
      id: "REVIEWS",
      color: PALETTE[4],
      title: "Number of Reviews",
      minAtZero: false,
      tooltip: "Number of Reviews",
    });
  }

  public value(d: ProductEx) {
    return d?.reviews;
  }

  public format(d: ProductEx, locale: string) {
    const data = typeof d === "number" ? d : d?.reviews === null ? undefined : d?.reviews;
    return formatNumber(data, locale, "1.0-0");
  }

  public compare(d1: ProductEx, d2: ProductEx) {
    return d1.reviews - d2.reviews;
  }
}
