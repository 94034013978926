import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { InventoryConfig } from "@front/m19-services";
import { BsModalRef } from "ngx-bootstrap/modal";
import { TranslocoService } from "@jsverse/transloco";
@Component({
  selector: "app-custom-field-edition",
  template: ` <div
    class="flex h-96
   flex-col items-start justify-between p-6"
    *transloco="let t"
  >
    <p class="mb-4 text-sm">
      {{ t("custom-field-settings.custom_field_tuto") }}
    </p>
    <div class="input-block w-full">
      <label class="mb-2 font-semibold">{{ t("custom-field-edition.custom_field_1") }}</label>
      <input
        class="simple-input form-control"
        [value]="customField1Name ?? ''"
        placeholder="{{ t('custom-field-settings.enter_a_name_for_this_custom_field') }}"
        maxlength="128"
        (input)="customField1Name = getValueFromInputEvent($event)"
      />
      <span class="input-block__hint">{{ customField1Name?.length ?? "0" }}/128</span>
    </div>
    <div class="input-block w-full">
      <label class="mb-2 font-semibold">{{ t("custom-field-edition.custom_field_2") }}</label>
      <input
        class="simple-input form-control"
        [value]="customField2Name ?? ''"
        placeholder="{{ t('custom-field-settings.enter_a_name_for_this_custom_field') }}"
        maxlength="128"
        (input)="customField2Name = getValueFromInputEvent($event)"
      />
      <span class="input-block__hint">{{ customField2Name?.length ?? "0" }}/128</span>
    </div>
    <div class="mt-4 flex w-full justify-end gap-2">
      <IButton label="{{ t('common.update') }}" (onClick)="updateCustomFieldName()"></IButton>
    </div>
  </div>`,
})
export class CustomFieldSettingsComponent implements OnInit {
  @Input() inventoryConfig: InventoryConfig;

  @Output() valueChange = new EventEmitter<InventoryConfig>();

  customField1Name: string;
  customField2Name: string;

  constructor(
    private modalRef: BsModalRef,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.customField1Name = this.inventoryConfig.customField1Name;
    this.customField2Name = this.inventoryConfig.customField2Name;
  }

  updateCustomFieldName() {
    this.modalRef.hide();
    if (!this.customField1Name || this.customField1Name.trim().length === 0) {
      this.customField1Name = this.translocoService.translate("custom-field-edition.custom_field_1");
    }
    if (!this.customField2Name || this.customField2Name.trim().length === 0) {
      this.customField2Name = this.translocoService.translate("custom-field-edition.custom_field_2");
    }
    this.valueChange.emit({
      ...this.inventoryConfig,
      customField1Name: this.customField1Name,
      customField2Name: this.customField2Name,
    });
  }

  getValueFromInputEvent(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }
}
