import { Component, OnInit, TemplateRef } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { faPauseCircle } from "@fortawesome/free-regular-svg-icons";
import { faExternalLinkAlt, faLink, faPlayCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  AccountMarketplace,
  AccountSelectionService,
  AsinService,
  AuthService,
  CampaignType,
  Catalog,
  ConfigService,
  Currency,
  Marketplaces,
  ProductGroupEx,
  ProductGroupService,
  SegmentConfigType,
  StrategyEx,
  StrategyStateEnum,
  StrategyType,
  StrategyTypeStr,
  TacticType,
  User,
  Utils,
} from "@front/m19-services";
import { Option } from "@front/m19-ui";
import { isProductGroupEx } from "@m19-board/shared/strategy-product-group-hybrid-dropdown/strategy-product-group-hybrid-dropdown.component";
import { ProductListDetailsPopupComponent } from "@m19-board/strategies/strategy-bulk-upload-modal/product-list-detail-popup.component";
import { ICON_TRASH_O } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Constant } from "libs/m19-services/src/lib/m19-services/constant";
import { StrategyCache } from "libs/m19-services/src/lib/m19-services/strategy.cache";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { switchMap } from "rxjs";

type BaseCandidate = {
  strategy: StrategyEx;
  possibleStrategyTypes: StrategyType[];
  linkedStrategy?: StrategyEx;
  linkColor?: string;
};

type Candidate = BaseCandidate & {
  message?: Partial<Record<StrategyType, string>>;
  selected: boolean;
};

type SelectedCandidate = BaseCandidate & {
  strategyType: StrategyType;
  priority?: number;
};

@UntilDestroy()
@Component({
  selector: "app-strategy-group-migration-page",
  templateUrl: "./strategy-group-migration-page.component.html",
  styleUrls: ["./strategy-group-migration-page.component.scss"],
})
export class StrategyGroupMigrationPageComponent implements OnInit {
  // inputs
  isReadOnly: boolean;
  allProductGroups: ProductGroupEx[];
  allStrategies: StrategyEx[];
  locale: string;
  currency: Currency;
  accountMarketplace: AccountMarketplace;
  catalog: Catalog;

  // state
  stratOrProductGroupFilter: StrategyEx | ProductGroupEx;
  candidates: Candidate[];
  selectedCandidates: SelectedCandidate[];
  loading = false;
  readonly form = new FormGroup({
    strategyGroupName: new FormControl<string>("", [Validators.required, Validators.maxLength(80)]),
  });
  formSubmitted = false;
  strategyGroupAsins = new Set<string>();

  candidateOptions: Option<StrategyType>[] = [
    { value: StrategyType.BRAND, label: StrategyTypeStr[StrategyType.BRAND] },
    { value: StrategyType.KEYWORD, label: StrategyTypeStr[StrategyType.KEYWORD] },
  ];
  strategyGroupPageUrl = "/strategies/strategy-group/sponsored-product";
  strategyPageUrl = "/strategies/sponsored-product";

  // constants
  readonly State = StrategyStateEnum;
  readonly faPlayCircle = faPlayCircle;
  readonly faPauseCircle = faPauseCircle;
  readonly faExternalLink = faExternalLinkAlt;
  readonly faLink = faLink;
  readonly faTrash = faTrash;
  readonly ICON_TRASH = ICON_TRASH_O;
  readonly tacticDetailsKeys = {
    keywords: "keywords",
    blacklistedKeywords: "blacklisted keywords",
    targetedProducts: "targeted products",
    blacklistedProducts: "blacklisted products",
  };
  readonly StrategyType = StrategyType;
  readonly strategyTypes = [StrategyType.PRODUCT, StrategyType.BRAND, StrategyType.KEYWORD];
  readonly StrategyTypeStr = StrategyTypeStr;
  readonly formControlNames = {
    strategyGroupName: "Strategy Group Name",
  };

  constructor(
    private authservice: AuthService,
    private productGroupService: ProductGroupService,
    private strategyCache: StrategyCache,
    private configService: ConfigService,
    private accountSelectionService: AccountSelectionService,
    private modalService: BsModalService,
    private asinService: AsinService,
    private toastrService: ToastrService,
    private router: Router,
    private authService: AuthService,
  ) {
    this.authservice.loggedUser$.pipe(untilDestroyed(this)).subscribe((user: User) => {
      this.locale = user.locale;
    });
    this.strategyCache.strategyIndex$.pipe(untilDestroyed(this)).subscribe((s: Map<number, StrategyEx>) => {
      this.allStrategies = Array.from(s.values()).filter(
        (s) => s.campaignType == CampaignType.SP && s.strategyType == StrategyType.LEGACY && !s.isAllOtherProduct(),
      );
    });
    this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(untilDestroyed(this)).subscribe((am) => {
      this.currency = Marketplaces[am.marketplace].currency;
      this.accountMarketplace = am;
      this.setStrategyOrProductGroup(undefined);
    });
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am: AccountMarketplace) => this.asinService.getCatalog(am.accountId, am.marketplace)),
        untilDestroyed(this),
      )
      .subscribe((catalog: Catalog) => {
        this.catalog = catalog;
      });
    this.accountSelectionService.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((r) => {
      this.isReadOnly = r;
    });
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      if ((user?.uiVersion ?? 0) > 0) {
        this.strategyGroupPageUrl = "/advertising/sponsored-product/strategy-group";
        this.strategyPageUrl = "/advertising/sponsored-product";
      }
    });
  }

  ngOnInit(): void {
    this.productGroupService
      .getProductGroups(this.accountMarketplace.accountId, this.accountMarketplace.marketplace)
      .pipe(untilDestroyed(this))
      .subscribe((x) => (this.allProductGroups = x));
  }

  openExplanationModal(template: TemplateRef<void>) {
    this.modalService.show(template, { class: "modal-lg" });
  }

  setStrategyOrProductGroup(stratOrProductGroup: StrategyEx | ProductGroupEx) {
    this.stratOrProductGroupFilter = stratOrProductGroup;
    const candidateStrategies = [];
    this.selectedCandidates = [];
    if (!stratOrProductGroup) {
      this.candidates = [];
      return;
    }
    let asins: string[];
    if (isProductGroupEx(stratOrProductGroup)) {
      asins = stratOrProductGroup.items;
    } else {
      asins = stratOrProductGroup.asins.map((a) => a.asin);
    }
    for (const s of this.allStrategies) {
      const strategyAsins = new Set(s.asins.map((a) => a.asin));
      if (asins.every((a) => strategyAsins.has(a))) {
        candidateStrategies.push(s);
      }
    }
    this.loading = true;
    this.candidates = candidateStrategies.map((s) => ({
      strategy: s,
      possibleStrategyTypes: [],
      selected: false,
    }));
    this.spotLinkedStrategies();
    // sort candidates -> linked strategies and live strategies first
    this.candidates.sort(
      (a, b) =>
        (a.linkedStrategy ? -1 : 1) - (b.linkedStrategy ? -1 : 1) ||
        (a.strategy.state == StrategyStateEnum.ENABLED ? -1 : 1) -
          (b.strategy.state == StrategyStateEnum.ENABLED ? -1 : 1),
    );

    this.strategyGroupAsins.clear();
    this.buildCandidates();
  }

  private buildCandidates() {
    this.loading = false;
    const strategyGroupAsins = new Set<string>();
    for (const selectedCandidate of this.selectedCandidates) {
      for (const asin of selectedCandidate.strategy.asins) {
        strategyGroupAsins.add(asin.asin);
      }
    }
    for (const candidate of this.candidates) {
      candidate.possibleStrategyTypes = [];
      candidate.message = {};
      // check if can be a product strategy
      if (candidate.strategy.asins.findIndex((a) => strategyGroupAsins.has(a.asin)) > -1) {
        candidate.message = {
          ...candidate.message,
          [StrategyType.PRODUCT]:
            "It cannot be set as main strategy because it would overlap with ASINs of other main strategies",
        };
      } else if (candidate.linkedStrategy && !candidate.strategy.autoAlgoExplorationEnabled) {
        candidate.message = {
          ...candidate.message,
          [StrategyType.PRODUCT]:
            "This strategy is linked to another (same ASIN and tactic) and does not have AI targeting ON.",
        };
      } else {
        candidate.possibleStrategyTypes = [...candidate.possibleStrategyTypes, StrategyType.PRODUCT];
      }
      // check for brand defense or main/kw
      if (candidate.linkedStrategy) {
        if (candidate.strategy.autoAlgoExplorationEnabled) {
          candidate.message = {
            ...candidate.message,
            [StrategyType.BRAND]: "This strategy is linked to another (same ASIN and tactic) and has AI targeting ON.",
            [StrategyType.KEYWORD]:
              "This strategy is linked to another (same ASIN and tactic) and has AI targeting ON.",
          };
        } else if (candidate.linkedStrategy.asins.findIndex((a) => strategyGroupAsins.has(a.asin)) > -1) {
          candidate.message = {
            ...candidate.message,
            [StrategyType.BRAND]:
              "This strategy is linked to another but its ASINs overlap with other main strategies.",
            [StrategyType.KEYWORD]:
              "This strategy is linked to another but its ASINs overlap with other main strategies.",
          };
        } else {
          if (candidate.strategy.tactics[0].segment.segmentType == SegmentConfigType.KeywordSegment) {
            if (candidate.strategy.tactics[0].segment.items.length > Constant.maxKwTargetingByStrategy) {
              candidate.message = {
                ...candidate.message,
                [StrategyType.BRAND]: `The strategy tactic has more than ${Constant.maxKwTargetingByStrategy} keywords`,
                [StrategyType.KEYWORD]: `The strategy tactic has more than ${Constant.maxKwTargetingByStrategy} keywords`,
              };
            } else {
              candidate.possibleStrategyTypes = [
                ...candidate.possibleStrategyTypes,
                StrategyType.BRAND,
                StrategyType.KEYWORD,
              ];
            }
          } else {
            if (candidate.strategy.tactics[0].segment.items.length > Constant.maxAsinTargetingByStrategy) {
              candidate.message = {
                ...candidate.message,
                [StrategyType.BRAND]: `The strategy tactic has more than ${Constant.maxAsinTargetingByStrategy} targeted products`,
                [StrategyType.KEYWORD]: `The strategy tactic has more than ${Constant.maxAsinTargetingByStrategy} targeted products`,
              };
            } else if (
              candidate.strategy.tactics[0].segment.items.findIndex((a) => !this.catalog.contains(a.targetingValue)) >
              -1
            ) {
              candidate.possibleStrategyTypes = [...candidate.possibleStrategyTypes, StrategyType.KEYWORD];
              candidate.message = {
                ...candidate.message,
                [StrategyType.BRAND]: `The strategy tactic targets products not in the catalog.`,
              };
            } else {
              candidate.possibleStrategyTypes = [
                ...candidate.possibleStrategyTypes,
                StrategyType.BRAND,
                StrategyType.KEYWORD,
              ];
            }
          }
        }
      } else {
        if (candidate.strategy.autoAlgoExplorationEnabled) {
          candidate.message = {
            ...candidate.message,
            [StrategyType.BRAND]: "Brand defense strategy cannot have AI targetting activated.",
            [StrategyType.KEYWORD]: "Focus strategy cannot have AI targetting activated.",
          };
        } else if (
          candidate.strategy.tactics.length != 1 ||
          candidate.strategy.tactics[0].tacticType != TacticType.LEGACY
        ) {
          candidate.message = {
            ...candidate.message,
            [StrategyType.BRAND]: "Should have a single tactic.",
            [StrategyType.KEYWORD]: "Should have a single tactic.",
          };
        } else if (candidate.strategy.asins.findIndex((a) => strategyGroupAsins.has(a.asin)) < 0) {
          candidate.message = {
            ...candidate.message,
            [StrategyType.BRAND]: "Strategy ASINS should be in a main strategy",
            [StrategyType.KEYWORD]: "Strategy ASINS should be in a main strategy",
          };
        } else if (candidate.strategy.tactics[0].segment.segmentType == SegmentConfigType.KeywordSegment) {
          if (candidate.strategy.tactics[0].segment.items.length > Constant.maxKwTargetingByStrategy) {
            candidate.message = {
              ...candidate.message,
              [StrategyType.BRAND]: `Strategy tactic has more than ${Constant.maxKwTargetingByStrategy} keywords`,
              [StrategyType.KEYWORD]: `Strategy tactic has more than ${Constant.maxKwTargetingByStrategy} keywords`,
            };
          } else {
            candidate.possibleStrategyTypes = [
              ...candidate.possibleStrategyTypes,
              StrategyType.BRAND,
              StrategyType.KEYWORD,
            ];
          }
        } else {
          if (candidate.strategy.tactics[0].segment.items.length > Constant.maxAsinTargetingByStrategy) {
            candidate.message = {
              ...candidate.message,
              [StrategyType.BRAND]: `This strategy tactic has more than ${Constant.maxAsinTargetingByStrategy} targeted products`,
              [StrategyType.KEYWORD]: `This strategy tactic has more than ${Constant.maxAsinTargetingByStrategy} targeted products`,
            };
          } else if (
            candidate.strategy.tactics[0].segment.items.findIndex((a) => !this.catalog.contains(a.targetingValue)) > -1
          ) {
            candidate.possibleStrategyTypes = [...candidate.possibleStrategyTypes, StrategyType.KEYWORD];
            candidate.message = {
              ...candidate.message,
              [StrategyType.BRAND]: `This strategy tactic more than ${Constant.maxAsinTargetingByStrategy} targeted products`,
            };
          } else {
            candidate.possibleStrategyTypes = [
              ...candidate.possibleStrategyTypes,
              StrategyType.KEYWORD,
              StrategyType.BRAND,
            ];
          }
        }
      }
    }
  }

  private arrangeSelectedCandidates() {
    // update the list of strategy group ASINs
    this.strategyGroupAsins.clear();
    for (const c of this.selectedCandidates) {
      for (const asin of c.strategy.asins) {
        this.strategyGroupAsins.add(asin.asin);
      }
    }
    // sort
    this.selectedCandidates.sort(
      (a, b) =>
        (a.linkedStrategy ? -1 : 1) - (b.linkedStrategy ? -1 : 1) ||
        (a.strategy.state == StrategyStateEnum.ENABLED ? -1 : 1) -
          (b.strategy.state == StrategyStateEnum.ENABLED ? -1 : 1),
    );
    // compute priorities
    let kwP = 0;
    let brandP = 0;
    for (let i = 0; i < this.selectedCandidates.length; i++) {
      if (this.selectedCandidates[i].strategyType == StrategyType.BRAND) {
        this.selectedCandidates[i].priority = brandP;
        brandP = Math.floor((Number.MAX_SAFE_INTEGER + brandP) / 2);
      }
      if (this.selectedCandidates[i].strategyType == StrategyType.KEYWORD) {
        this.selectedCandidates[i].priority = kwP;
        kwP = Math.floor((Number.MAX_SAFE_INTEGER + kwP) / 2);
      }
    }
  }

  public addToStrategyGroup(candidate: Candidate, strategyType: StrategyType) {
    if (candidate.selected) {
      return;
    }
    this.selectedCandidates.push({ ...candidate, strategyType });
    candidate.selected = true;
    if (candidate.linkedStrategy) {
      const linkedCandidate = this.candidates.find((c) => c.strategy.strategyId == candidate.linkedStrategy.strategyId);
      this.selectedCandidates.push({ ...linkedCandidate, strategyType: linkedCandidate.possibleStrategyTypes[0] });
      linkedCandidate.selected = true;
    }
    this.buildCandidates();
    this.arrangeSelectedCandidates();
    // set the strategy group name if only one strategy will be migrated
    if (this.selectedCandidates.length == 1) {
      this.form.controls.strategyGroupName.setValue(candidate.strategy.name);
    }
  }

  public toggleStrategyType(selectedCandidate: SelectedCandidate, strategyType: StrategyType) {
    if (
      strategyType == StrategyType.PRODUCT ||
      selectedCandidate.strategyType == StrategyType.PRODUCT ||
      !selectedCandidate.possibleStrategyTypes.includes(strategyType)
    ) {
      return;
    }
    selectedCandidate.strategyType = strategyType;
    this.arrangeSelectedCandidates();
  }

  public removeFromStrategyGroup(selectedCandidate: SelectedCandidate) {
    this.selectedCandidates.splice(this.selectedCandidates.indexOf(selectedCandidate), 1);
    const candidate = this.candidates.find((c) => c.strategy.strategyId == selectedCandidate.strategy.strategyId);
    candidate.selected = false;
    if (selectedCandidate.linkedStrategy) {
      const linkedCandidate = this.candidates.find(
        (c) => c.strategy.strategyId == selectedCandidate.linkedStrategy.strategyId,
      );
      linkedCandidate.selected = false;
      const selectedLinkedCandidateIndex = this.selectedCandidates.findIndex(
        (c) => c.strategy.strategyId == selectedCandidate.linkedStrategy.strategyId,
      );
      this.selectedCandidates.splice(selectedLinkedCandidateIndex, 1);
    }
    // if strategy type is product also remove strategies with the same ASINs
    if (selectedCandidate.strategyType == StrategyType.PRODUCT || selectedCandidate.linkedStrategy) {
      const asins = new Set<string>();
      for (const asin of selectedCandidate.strategy.asins) {
        asins.add(asin.asin);
      }
      // iterate in reverse order to remove element along the iteration
      for (let i = this.selectedCandidates.length - 1; i >= 0; i--) {
        const c = this.selectedCandidates[i];
        if (c.strategy.asins.findIndex((a) => asins.has(a.asin)) >= 0) {
          this.selectedCandidates.splice(i, 1);
          const unselectedCandidate = this.candidates.find((c2) => c2.strategy.strategyId == c.strategy.strategyId);
          unselectedCandidate.selected = false;
        }
      }
    }
    this.buildCandidates();
    this.arrangeSelectedCandidates();
    // set the strategy group name if only one strategy is left
    if (this.selectedCandidates.length == 1) {
      this.form.controls.strategyGroupName.setValue(this.selectedCandidates[0].strategy.name);
    }
  }

  private spotLinkedStrategies() {
    // 2 strategies are linked when they have
    // - same ASINs
    // - one single same segment in the tactic
    // and one strategy has a legacy tactic + AI targeting OFF
    // the other other strategy has a blacklist tactic with AI trageting ON
    for (const c of this.candidates) {
      if (c.linkedStrategy) {
        continue;
      }
      const alreadyLinked = this.candidates.find((c) => c.linkedStrategy?.strategyId == c.strategy.strategyId);
      if (alreadyLinked) {
        c.linkedStrategy = alreadyLinked.strategy;
        c.linkColor = alreadyLinked.linkColor;
        continue;
      }
      if (c.strategy.tactics.length != 1) {
        continue;
      }
      const tactic = c.strategy.tactics[0];
      if (tactic.tacticType == TacticType.LEGACY && c.strategy.autoAlgoExplorationEnabled) {
        continue;
      }
      if (tactic.tacticType == TacticType.BLACKLIST && !c.strategy.autoAlgoExplorationEnabled) {
        continue;
      }
      for (const c2 of this.candidates) {
        if (c2.strategy.strategyId == c.strategy.strategyId) {
          continue;
        }
        // check segment
        if (c2.strategy.tactics.length != 1) {
          continue;
        }
        const tactic2 = c2.strategy.tactics[0];
        if (tactic.segmentId != tactic2.segmentId) {
          continue;
        }
        if (
          tactic.tacticType == TacticType.LEGACY &&
          (tactic2.tacticType == TacticType.LEGACY || !c2.strategy.autoAlgoExplorationEnabled)
        ) {
          continue;
        }
        if (
          tactic.tacticType == TacticType.BLACKLIST &&
          (tactic2.tacticType == TacticType.BLACKLIST || c2.strategy.autoAlgoExplorationEnabled)
        ) {
          continue;
        }
        // check ASINs
        const asins = new Set(c.strategy.asins.map((a) => a.asin));
        if (c.strategy.asins.length != c2.strategy.asins.length || c2.strategy.asins.find((a) => !asins.has(a.asin))) {
          continue;
        }
        c.linkedStrategy = c2.strategy;
        c.linkColor = Utils.genColor("" + c.linkedStrategy.strategyId);
        break;
      }
    }
  }

  showStrategyAsins(strategy: StrategyEx) {
    const modalOptions: ModalOptions = {
      initialState: {
        asins: strategy.asins.map((a) => a.asin),
        accountId: this.accountMarketplace.accountId,
        marketplace: this.accountMarketplace.marketplace,
      },
    };
    this.modalService.show(ProductListDetailsPopupComponent, modalOptions);
  }

  showStrategyGroupAsins() {
    const modalOptions: ModalOptions = {
      initialState: {
        asins: Array.from(this.strategyGroupAsins.values()),
        accountId: this.accountMarketplace.accountId,
        marketplace: this.accountMarketplace.marketplace,
      },
    };
    this.modalService.show(ProductListDetailsPopupComponent, modalOptions);
  }

  getTacticDetails(strategy: StrategyEx) {
    const res: {
      keywords?: number;
      blacklistedKeywords?: number;
      targetedProducts?: number;
      blacklistedProducts?: number;
    } = {};
    if (strategy.tactics.length == 0) {
      return res;
    }
    for (const tactic of strategy.tactics) {
      if (tactic.tacticType == TacticType.LEGACY) {
        if (tactic.segment.segmentType == SegmentConfigType.KeywordSegment) {
          res.keywords = (res.keywords ?? 0) + tactic.segment.items.length;
        } else {
          res.targetedProducts = (res.targetedProducts ?? 0) + tactic.segment.items.length;
        }
      } else {
        if (tactic.segment.segmentType == SegmentConfigType.KeywordSegment) {
          res.blacklistedKeywords = (res.blacklistedKeywords ?? 0) + tactic.segment.items.length;
        } else {
          res.blacklistedProducts = (res.blacklistedProducts ?? 0) + tactic.segment.items.length;
        }
      }
    }
    return res;
  }

  submitMigrationForm() {
    this.formSubmitted = true;
    if (this.form.invalid) {
      for (const c in this.form.controls) {
        if (this.form.controls[c].invalid) {
          this.toastrService.error("Invalid/missing field: " + this.formControlNames[c]);
        }
      }
      return;
    }
    if (this.selectedCandidates.length == 0) {
      this.toastrService.error("No strategy candidates have been selected");
      return;
    }
    // check the strategy group name is the same as the strategy group
    if (
      this.selectedCandidates.length == 1 &&
      this.form.controls.strategyGroupName.value != this.selectedCandidates[0].strategy.name
    ) {
      this.toastrService.error("The strategy group name should be the same as the strategy name");
      return;
    }
    const strategiesToMigrate = this.selectedCandidates.map((c) => ({
      strategyId: c.strategy.strategyId,
      strategyType: c.strategyType,
      priority: c.priority,
    }));

    this.configService.migrateStrategyGroup(this.form.controls.strategyGroupName.value, strategiesToMigrate).subscribe({
      next: (strategyGroup) => {
        this.toastrService.success("New Strategy Group Created");
        this.router.navigate([this.strategyGroupPageUrl + "/" + strategyGroup.strategyGroupId], {
          queryParamsHandling: "merge",
        });
      },
      error: (e) => {
        this.toastrService.error(e, "Strategy Group Migration Error");
      },
    });
  }
}
