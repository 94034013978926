import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { faInfoCircle, faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  ConfigService,
  SegmentConfigType,
  SegmentEx,
  StrategyEx,
  StrategyTacticEx,
  TacticType,
} from "@front/m19-services";
import { Option } from "@front/m19-ui";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SegmentService } from "libs/m19-services/src/lib/m19-services/segmentService";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";

export interface SegmentCreactionRequest {
  tacticType: TacticType;
  segmentType: SegmentConfigType;
}

/**
 * Modal component to create a new tactic
 */
@UntilDestroy()
@Component({
  selector: "app-tactic-add-popup",
  templateUrl: "./tactic-add-popup.component.html",
})
export class TacticAddPopupComponent implements OnInit {
  @Input()
  strategy: StrategyEx;

  @Input()
  set allowedSegmentTacticTypes(supported: [SegmentConfigType, TacticType][]) {
    this._allowedSegmentTacticTypes = new Set(supported.map((s) => s[0] + "_" + s[1]));
    this.segmentType = supported[0][0];
    this.tacticType = supported[0][1];
  }

  private _allowedSegmentTacticTypes: Set<string>;
  segment: Option<SegmentEx | undefined> | undefined;
  creation: boolean;
  tacticType: TacticType = TacticType.LEGACY;
  segmentType: SegmentConfigType = SegmentConfigType.KeywordSegment;

  readonly TacticType = TacticType;
  readonly SegmentType = SegmentConfigType;
  readonly faSearch = faSearch;
  readonly faInfo = faInfoCircle;

  @Output()
  segmentCreationRequested = new EventEmitter<SegmentCreactionRequest>();

  @Output()
  tacticCreationCancelled = new EventEmitter();

  @Output()
  tacticCreated = new EventEmitter<StrategyTacticEx>();

  tacticTypesOptions: Option<SegmentCreactionRequest>[] = [];
  selectedTacticType: Option<SegmentCreactionRequest> | undefined;

  allSegments: SegmentEx[] = [];
  segmentOptions: Option<SegmentEx | undefined>[] = [];
  newSegmentOption: Option<SegmentEx | undefined> = {
    value: undefined,
    label: "Create new segment",
    testid: "btn-create-segment",
  };

  constructor(
    public configurationService: ConfigService,
    public segmentService: SegmentService,
    private bsModalRef: BsModalRef,
    private toasterService: ToastrService,
  ) {
    this.allowedSegmentTacticTypes = [
      [SegmentConfigType.KeywordSegment, TacticType.LEGACY],
      [SegmentConfigType.KeywordSegment, TacticType.BLACKLIST],
      [SegmentConfigType.ProductSegment, TacticType.LEGACY],
      [SegmentConfigType.ProductSegment, TacticType.BLACKLIST],
    ];
  }

  ngOnInit(): void {
    this.allSegments = this.segmentService.getAllowedSegmentsForStrategy(this.strategy);

    this.segmentOptions = this.getSegmentOptions();
    this.tacticTypesOptions = [
      {
        label: "Keyword Targeting",
        value: { tacticType: TacticType.LEGACY, segmentType: SegmentConfigType.KeywordSegment },
        disabled: true,
        testid: `btn-${TacticType.LEGACY}_${SegmentConfigType.KeywordSegment}`,
      },
      {
        label: "Product Targeting",
        value: { tacticType: TacticType.LEGACY, segmentType: SegmentConfigType.ProductSegment },
        disabled: !this.supportedSegmentType(TacticType.LEGACY, SegmentConfigType.ProductSegment),
        testid: `btn-${TacticType.LEGACY}_${SegmentConfigType.ProductSegment}`,
      },
      {
        label: "Blacklist Keywords",
        value: { tacticType: TacticType.BLACKLIST, segmentType: SegmentConfigType.KeywordSegment },
        disabled: !this.supportedSegmentType(TacticType.BLACKLIST, SegmentConfigType.KeywordSegment),
        testid: `btn-${TacticType.BLACKLIST}_${SegmentConfigType.KeywordSegment}`,
      },
      {
        label: "Blacklist Products",
        value: { tacticType: TacticType.BLACKLIST, segmentType: SegmentConfigType.ProductSegment },
        disabled: !this.supportedSegmentType(TacticType.BLACKLIST, SegmentConfigType.ProductSegment),
        testid: `btn-${TacticType.BLACKLIST}_${SegmentConfigType.ProductSegment}`,
      },
    ];

    this.selectedTacticType = this.tacticTypesOptions.find(
      (o) => !o.disabled && o.value.tacticType === this.tacticType && o.value.segmentType === this.segmentType,
    );
  }

  private getSegmentOptions(): Option<SegmentEx | undefined>[] {
    return [
      this.newSegmentOption,
      ...this.allSegments
        .filter((s) => s.segmentType === this.segmentType && s.name)
        .map((s) => ({ value: s, label: s.name })),
    ];
  }

  supportedSegmentType(tacticType: TacticType, segmentType: SegmentConfigType) {
    return this._allowedSegmentTacticTypes.has(segmentType + "_" + tacticType);
  }

  selectTacticType(tacticType: Option<SegmentCreactionRequest>) {
    if (!this.supportedSegmentType(tacticType.value.tacticType, tacticType.value.segmentType)) return;
    this.selectedTacticType = tacticType;
    this.tacticType = tacticType.value.tacticType;
    this.segmentType = tacticType.value.segmentType;
    this.segment = undefined;
    this.creation = false;
    this.segmentOptions = this.getSegmentOptions();
  }

  selectSegment(segment: Option<SegmentEx | undefined>) {
    this.segment = segment;
    this.creation = segment.value == undefined;
  }

  close(): void {
    this.bsModalRef.hide();
    this.tacticCreationCancelled.emit();
  }

  create(): void {
    if (this.segment && this.segment.value) {
      this.configurationService
        .addTacticToStrategyAsync(
          this.strategy.accountId,
          this.strategy.marketplace,
          this.strategy.strategyId,
          this.segment.value.segmentId,
          this.tacticType,
        )
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (tactic) => {
            this.toasterService.success("Tactic successfully added to strategy", "Tactic created");
            this.tacticCreated.emit(tactic);
            this.bsModalRef.hide();
          },
          error: (error) => {
            this.toasterService.error(error, "Tactic creation error");
            this.bsModalRef.hide();
            this.tacticCreationCancelled.emit();
          },
        });
    } else {
      // request segment creation request
      this.segmentCreationRequested.emit({ tacticType: this.tacticType, segmentType: this.segmentType });
      this.bsModalRef.hide();
    }
  }
}
