import { AdStatsEx } from './AdStatsEx';
import { Metric, MetricCategory, RatioMetric, SummableMetric } from './Metric';

export const PALETTE = [
  '#181d4d',
  '#4bae99',
  '#f0ad4e',
  '#7b9fe8',
  '#969696',
  '#8e6ba0',
  '#f9b1c7',
  '#334061',
  '#ed7d24',
  '#becae6',
  '#ed2496',
  '#e6d687',
  '#f72b25',
  '#636dce',
  '#a3d7cc',
  '#f7d6a6',
  '#bccef3',
  '#cacaca',
  '#c6b4cf',
  '#fcd7e3',
  '#8898c0',
  '#f6bd91',
  '#dee4f2',
  '#f691ca',
  '#f2eac3',
  '#fb9491',
  '#6C2F66',
];

export const AD_SALES: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: 'AD_SALES',
  field: 'adSales',
  title: 'Ad Sales',
  category: MetricCategory.AD_STATS,
  color: PALETTE[1],
  currency: true,
  titleSmall: 'Sp. Sales',
  tooltip: 'Sales attributed to advertising efforts',
});

export const COST: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: 'COST',
  field: 'cost',
  title: 'Cost',
  category: MetricCategory.AD_STATS,
  color: PALETTE[0],
  currency: true,
  tooltip: 'Total amount spent on advertising',
  higherIsBetter: false,
});

export const ACOS: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: 'ACOS',
  numerator: COST,
  denominator: AD_SALES,
  title: 'ACOS',
  category: MetricCategory.AD_STATS,
  color: PALETTE[3],
  isPercent: true,
  inverseColors: true,
  tooltip: 'Ratio of ad spend to ad revenue',
  higherIsBetter: false,
  mustApplyEvolutionStyle: false,
});

export const CLICKS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: 'CLICKS',
  field: 'clicks',
  title: 'Clicks',
  category: MetricCategory.AD_STATS,
  color: PALETTE[5],
  tooltip: 'Number of times users have clicked on an ad',
});

export const IMPRESSIONS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: 'IMPRESSIONS',
  field: 'impressions',
  title: 'Impressions',
  category: MetricCategory.AD_STATS,
  color: PALETTE[6],
  titleSmall: 'Impr.',
  tooltip: 'Number of times an ad is displayed',
});

export const CPC: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: 'CPC',
  numerator: COST,
  denominator: CLICKS,
  title: 'CPC',
  category: MetricCategory.AD_STATS,
  color: PALETTE[23],
  precision: '1.0-2',
  currency: true,
  tooltip: 'Price of a sponsored click',
  higherIsBetter: false,
});

export const ROAS: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: 'ROAS',
  numerator: AD_SALES,
  denominator: COST,
  title: 'ROAS',
  category: MetricCategory.AD_STATS,
  color: PALETTE[11],
  precision: '1.0-2',
  tooltip: 'Ratio of revenue generated from advertising to the cost of advertising',
  mustApplyEvolutionStyle: false,
});

export const CLICK_THROUGH_RATE: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: 'CLICK_THROUGH_RATE',
  numerator: CLICKS,
  denominator: IMPRESSIONS,
  title: 'CTR',
  category: MetricCategory.AD_STATS,
  color: PALETTE[7],
  isPercent: true,
  precision: '1.2-2',
  tooltip: 'Percentage of users who click on an ad after seeing it',
});

export const AD_CONVERSIONS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: 'AD_CONVERSIONS',
  field: 'adConversions',
  title: 'Ad Orders',
  category: MetricCategory.AD_STATS,
  color: PALETTE[2],
  titleSmall: 'Ad Orders',
  tooltip: 'Number of orders through advertising',
});

export const CONVERSION_RATE: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: 'CONVERSION_RATE',
  numerator: AD_CONVERSIONS,
  denominator: CLICKS,
  title: 'CR',
  category: MetricCategory.AD_STATS,
  color: PALETTE[4],
  isPercent: true,
  tooltip: 'Percentage of users who purchased after clicking on the ad',
});
