import { Component, Input, OnInit } from "@angular/core";
import { faImage, faVideo } from "@fortawesome/free-solid-svg-icons";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BrandAsset, ConfigService, SbCreativeType, SbCreativeEx, StrategyEx } from "@front/m19-services";
import { csLocale } from "ngx-bootstrap/chronos";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@UntilDestroy()
@Component({
  selector: "app-sb-card",
  templateUrl: "./sb-card.component.html",
  styleUrls: ["./sb-card.component.scss"],
})
export class SBCardComponent {
  _strategy: StrategyEx;

  nbProduct: number;
  nbVideo: number;
  headline: string;
  logoUrl: string;
  readonly faImage = faImage;
  readonly faVideo = faVideo;

  @Input()
  set strategy(strategy) {
    this._strategy = strategy;
    this.nbVideo =
      strategy?.sbCreatives?.filter(
        (x) => x.creativeType == SbCreativeType.video || x.creativeType == SbCreativeType.brandVideo,
      )?.length ?? 0;
    this.nbProduct =
      strategy?.sbCreatives?.filter(
        (x) => x.creativeType == SbCreativeType.productCollection || x.creativeType === SbCreativeType.storeSpotlight,
      )?.length ?? 0;

    const creativeProduct = strategy?.sbCreatives?.find(
      (x) => x.creativeType == SbCreativeType.productCollection || x.creativeType == SbCreativeType.storeSpotlight,
    );
    this.logoUrl = creativeProduct?.logoAsset?.url;
    this.headline = creativeProduct?.headline;
  }

  @Input() isReadOnly = false;
}
