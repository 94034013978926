import { Injectable } from "@angular/core";
import { Meta } from "@angular/platform-browser";
import { OrganizationAccountGroupService, User } from "@front/m19-services";
import { environment } from "../environments/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

type Agency = {
  subDomain: string;
  name: string;
  smallLogo: string;
  logo: string;
  termsAndConditionsLink: string;
  isSelfService: boolean;
  contact?: string;
  upgrade?: string;
  upgradeTarget?: LinkTarget;
  noAgencyAccountMessage: string;
};

export enum BoardType {
  M19 = "M19",
  WHITELABEL = "WHITELABEL",
  WHITELABEL_SELFSERVICE = "WHITELABEL_SELFSERVICE",
}

export type Link = {
  url: string;
  target: "_self" | "_blank";
};

export type LinkTarget = "_self" | "_blank";

@Injectable()
export class LayoutSelectorService {
  private readonly NO_SUB_DOMAIN = "";
  private readonly M19 = "m19";
  private boardType: BoardType = undefined;

  // Don't forget to update the column board of configdb.m19Users table when adding/updating an agency
  private readonly _agencies: Array<Agency> = [
    {
      subDomain: this.NO_SUB_DOMAIN,
      name: "",
      smallLogo: "blank_40.png",
      logo: "blank_40.png",
      termsAndConditionsLink: "",
      isSelfService: false,
      noAgencyAccountMessage: "Don't have an account yet?",
    },
    {
      subDomain: this.M19,
      name: "M19",
      smallLogo: "logo.png",
      logo: "logo_header.png",
      termsAndConditionsLink: "https://www.m19.com/form/terms-and-conditions",
      isSelfService: false,
      noAgencyAccountMessage: "Don't have a M19 account yet?",
    },
    {
      subDomain: "ppclogic",
      name: "PPC Logic",
      smallLogo: "ppclogic_small.png",
      logo: "ppclogic.png",
      termsAndConditionsLink: "https://ppclogic.com/terms-of-service/",
      isSelfService: true,
      upgrade: "https://customers.ppclogic.com/register.php",
      upgradeTarget: "_self",
      noAgencyAccountMessage: "Don't have a PPC Logic account yet?",
    },
    {
      subDomain: "etail-agency",
      name: "eTail Agency",
      smallLogo: "etail-agency_small.png",
      logo: "etail-agency.png",
      termsAndConditionsLink: "https://www.etail-agency.com",
      isSelfService: false,
      noAgencyAccountMessage: "Don't have an eTail Agency account yet?",
    },
    {
      subDomain: "signalytics",
      name: "Signalytics",
      smallLogo: "signalytics_small.png",
      logo: "signalytics.png",
      termsAndConditionsLink: "https://signalytics.ai/terms-and-conditions-ppc/",
      isSelfService: true,
      contact: "https://www.signalytics.ai/contact/",
      upgrade: "https://www.signalytics.ai/services/ppc-pricing/",
      noAgencyAccountMessage: "Don't have a Signalytics account yet?",
    },
    {
      subDomain: "m2asolutions",
      name: "M2A Solutions",
      smallLogo: "m2a_small.png",
      logo: "m2a.svg",
      termsAndConditionsLink: "https://m2asolutions.com/",
      isSelfService: false,
      noAgencyAccountMessage: "Don't have a M2A Solutions account yet?",
    },
    {
      subDomain: "sellingz",
      name: "Sellingz",
      smallLogo: "sellingz_small.png",
      logo: "sellingz.png",
      termsAndConditionsLink: "https://sellingz.com/",
      isSelfService: false,
      noAgencyAccountMessage: "Don't have a Sellingz account yet?",
    },
    {
      subDomain: "southparc",
      name: "Southparc",
      smallLogo: "logo.png",
      logo: "logo_header.png",
      termsAndConditionsLink: "https://southparc.nl/fr/algemene_voorwaarden_southparc/",
      isSelfService: true,
      contact: "https://southparc.nl/m19/m19-contact/",
      upgrade: "https://southparc.nl/m19/",
      noAgencyAccountMessage: "Don't have a Southparc account yet?",
    },
    {
      subDomain: "upcounting",
      name: "Upcounting",
      smallLogo: "upcounting_small.png",
      logo: "upcounting.png",
      termsAndConditionsLink: "https://upcounting.com/terms-and-conditions/",
      isSelfService: true,
      contact: "https://calendly.com/nik-bakhshalian/30min",
      upgrade: "https://calendly.com/nik-bakhshalian/30min",
      noAgencyAccountMessage: "Don't have an Upcounting account yet?",
    },
    {
      subDomain: "amzppc",
      name: "AMZPPC",
      smallLogo: "amzppc_small.png",
      logo: "amzppc.png",
      termsAndConditionsLink: "https://www.amazonppc.co.uk/software-terms-and-conditions/",
      isSelfService: true,
      contact: "https://www.amazonppc.co.uk/contact",
      upgrade: "https://www.amazonppc.co.uk/contact",
      noAgencyAccountMessage: "Don't have an AMZPPC account yet?",
    },
    {
      subDomain: "astra-sellrbox",
      name: "Astra",
      smallLogo: "sellrbox.png",
      logo: "sellrbox_logo.png",
      termsAndConditionsLink: "https://astra.sellrbox.com/terms-of-service",
      isSelfService: true,
      contact: "https://intercom.help/astra-by-sellrbox/en/",
      upgrade: "https://astra.sellrbox.com/pricing",
      noAgencyAccountMessage: "Don't have an Astra account yet?",
    },
  ];

  readonly agencies: Map<string, Agency> = this._agencies.reduce(function (map, obj) {
    map.set(obj.subDomain, obj);
    return map;
  }, new Map<string, Agency>());

  public readonly isWhitelabel: boolean;
  private readonly hostname: string;
  public readonly canLoginWithAmazon$: Observable<Map<number, boolean>>;

  constructor(private accountGroupService: OrganizationAccountGroupService) {
    if (environment["whitelabelDomain"]) {
      this.hostname = environment["whitelabelDomain"];
    } else {
      const locationHostname = window.location.hostname;
      if (locationHostname.endsWith("m19.com")) {
        this.hostname = "m19";
      } else if (locationHostname.includes("localhost")) {
        this.hostname = "m19";
      } else {
        this.hostname = locationHostname.split(".")[0];
      }
    }
    this.isWhitelabel = !this.hostname.includes("m19");

    this.canLoginWithAmazon$ = this.accountGroupService.allOrganizationAccountGroups$.pipe(
      map((organizations) => {
        // canLoginWithAmazon is overriden for whitelabel parent ppc board organization
        const canLoginWithAmazonGlobal =
          this.getBoardType() === BoardType.M19 || this.getBoardType() === BoardType.WHITELABEL_SELFSERVICE;
        const canLoginWithAmazon = new Map<number, boolean>();
        organizations.forEach((o) => {
          if (o.isParentPpcBoard) {
            canLoginWithAmazon.set(o.id, true);
          } else {
            canLoginWithAmazon.set(o.id, canLoginWithAmazonGlobal);
          }
        });
        return canLoginWithAmazon;
      }),
    );
  }

  getBoardType(): BoardType {
    if (this.boardType) {
      return this.boardType;
    }

    if (this.isWhitelabel) {
      this.boardType = this.agencies.get(this.getSubDomain()).isSelfService
        ? BoardType.WHITELABEL_SELFSERVICE
        : BoardType.WHITELABEL;
      return this.boardType;
    }
    this.boardType = BoardType.M19;
    return this.boardType;
  }

  getTermsAndConditionLink(): string {
    return this.agencies.get(this.getSubDomain()).termsAndConditionsLink;
  }

  getAgency(): Agency {
    return this.agencies.get(this.getSubDomain());
  }

  getAgencyName(): string {
    return this.agencies.get(this.getSubDomain()).name;
  }

  getAgencySubDomain(): string {
    return this.agencies.get(this.getSubDomain()).subDomain;
  }

  getSmallIcon(): string {
    return "assets/img/" + this.agencies.get(this.getSubDomain()).smallLogo;
  }

  getLogo(): string {
    return "assets/img/" + this.agencies.get(this.getSubDomain()).logo;
  }

  getConctact(): string {
    return this.agencies.get(this.getSubDomain()).contact;
  }

  getUpgrade(user: User): Link {
    // 'encrypt' user email in base64
    const userEmailQueryParam = user?.email ? `?u=${btoa(user.email)}` : "";
    const agency = this.agencies.get(this.getSubDomain());
    return { url: agency.upgrade + userEmailQueryParam, target: agency.upgradeTarget };
  }

  getSubDomain(): string {
    if (!this.isWhitelabel) return this.M19;
    if (this.agencies.has(this.hostname)) {
      return this.hostname;
    }
    return this.NO_SUB_DOMAIN;
  }

  getBoardTheme(): string {
    const subDomain = this.getSubDomain();
    if (!this.isWhitelabel || subDomain === this.NO_SUB_DOMAIN) return this.M19 + "-theme";
    return subDomain + "-theme";
  }

  getPageTitle() {
    const subDomain = this.getSubDomain();
    return subDomain.charAt(0).toUpperCase() + subDomain.slice(1) + " Dashboard";
  }

  setDocumentMetadata(document: Document, meta: Meta): void {
    document.querySelector<HTMLLinkElement>("#favicon").href = this.getSmallIcon();

    meta.updateTag({ name: "description", content: this.getPageTitle() });
    meta.updateTag({ name: "author", content: this.getSubDomain() });
    meta.updateTag({ name: "keyword", content: `${this.getSubDomain()},Amazon,Advertising,PPC` });
  }
}
