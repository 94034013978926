import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  standalone: true,
  selector: "app-switch-input",
  templateUrl: "./switch-input.component.html",
  styleUrls: ["./switch-input.component.scss"],
})
export class SwitchInputComponent {
  @Input() testid = "";
  @Input() checked = false;

  @Input() disabled = false;

  @Input() switchId = "toggleSwitch";

  @Output() onInput: EventEmitter<boolean> = new EventEmitter();

  changeStatus(value: any) {
    this.onInput.emit(value.target.checked);
  }
}
