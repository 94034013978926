import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  AccountSelectionService,
  AsinService,
  CampaignType,
  Catalog,
  ConfigService,
  StrategyAsin,
  StrategyEx,
} from "@front/m19-services";
import { MoveStrategyAsinsModalComponent } from "@m19-board/strategies/strategy-asins/move-strategy-asins-modal.component";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { combineLatest, Observable } from "rxjs";
import { AjaxError } from "rxjs/ajax";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { SpStrategyCreationComponent } from "../sp-creation/sp-strategy-creation/sp-strategy-creation.component";
import { StrategyCache } from "libs/m19-services/src/lib/m19-services/strategy.cache";

@UntilDestroy()
@Component({
  selector: "app-sp-page",
  templateUrl: "./sp-page.component.html",
})
export class SpPageComponent implements OnInit {
  strategy$: Observable<StrategyEx>;
  strategy: StrategyEx;
  asinsVisible = true;
  asinsList: StrategyAsin[] = [];
  ineligibleAsins: string[] = [];
  asinEligibility: Map<string, { status: boolean; reason: string }> = new Map();
  isReadOnly = false;
  ineligibleAsinsCollapsed: boolean;
  brands: Set<string> = new Set();
  targetingStatsVisible = false;

  readonly faPlus = faPlusCircle;
  readonly CampaignType = CampaignType;

  @ViewChild("productAdd") productAddModal: TemplateRef<any>;
  addAsinModalRef: BsModalRef;

  constructor(
    private route: ActivatedRoute,
    private strategyCache: StrategyCache,
    private configurationService: ConfigService,
    private toasterService: ToastrService,
    private asinService: AsinService,
    private modalService: BsModalService,
    private accountSelectionService: AccountSelectionService,
  ) {}

  ngOnInit() {
    this.brands.clear();
    this.ineligibleAsinsCollapsed = true;
    this.strategy$ = this.route.paramMap.pipe(
      switchMap((params) => {
        const id = Number(params.get("id"));
        return this.strategyCache.getStrategyById(id);
      }),
      map((s) => (s && s.campaignType == CampaignType.SP ? s : undefined)),
    );

    this.strategy$
      .pipe(
        untilDestroyed(this),
        filter((s) => !s),
      )
      .subscribe((_) => {
        this.strategy = undefined;
        this.asinsList = [];
        this.brands.clear();
      });

    const strategyAsins$: Observable<StrategyAsin[]> = this.strategy$.pipe(
      untilDestroyed(this),
      filter((s) => !!s),
      tap((s) => {
        this.strategy = s;
      }),
      switchMap((s) => this.configurationService.getStrategyAsins(s)),
    );
    const catalog$: Observable<Catalog> = this.strategy$.pipe(
      untilDestroyed(this),
      filter((s) => !!s),
      switchMap((strategy) => this.asinService.getCatalog(strategy.accountId, strategy.marketplace)),
    );

    combineLatest([strategyAsins$, catalog$])
      .pipe(untilDestroyed(this))
      .subscribe(([asins, catalog]: [StrategyAsin[], Catalog]) => {
        this.asinsList = asins;
        this.ineligibleAsins = [];
        if (catalog) {
          this.asinEligibility = catalog.getSPEligibility();

          if (this.asinsList)
            this.asinsList.forEach((item) => {
              if (!this.asinEligibility.has(item.asin) || this.asinEligibility.get(item.asin).status == false)
                this.ineligibleAsins.push(item.asin);
              if (catalog.asinOffers.has(item.asin)) {
                const brand = catalog.asinOffers.get(item.asin)?.brand;
                if (brand) this.brands.add(brand);
              }
            });
        }
      });

    this.accountSelectionService.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((b) => (this.isReadOnly = b));
  }

  toggleAsinsVisibility() {
    this.asinsVisible = !this.asinsVisible;
  }

  showAddAsinModal() {
    this.addAsinModalRef = this.modalService.show(this.productAddModal, {
      class: "modal-xl modal-primary modal-dialog-centered",
    });
  }

  toggleTargetingStatsVisibility() {
    this.targetingStatsVisible = !this.targetingStatsVisible;
  }

  private prettyPrintAsinList(asins: string[]) {
    const maxAsins = 5;
    let message = asins.slice(0, maxAsins).join(", ");
    if (asins.length > maxAsins) {
      message += "…";
    }
    return message;
  }

  getAsins(strategyAsin: StrategyAsin): string {
    return strategyAsin.asin;
  }

  deleteStrategyAsins(toDelete: StrategyAsin[]) {
    this.configurationService
      .deleteAsinsFromStrategy(
        this.strategy,
        toDelete.map((a) => a.asin),
      )
      .subscribe(
        () => {
          const toDeleteMsg = this.prettyPrintAsinList(toDelete.map((a) => a.asin));
          this.toasterService.success(
            `ASIN${
              toDelete.length > 1 ? "s" : ""
            } ${toDeleteMsg} removed from SP strategy "${this.strategy.getName()}"`,
            "Strategy ASIN updated",
          );
        },
        (error: AjaxError) => {
          const toDeleteMsg = this.prettyPrintAsinList(toDelete.map((a) => a.asin));
          this.toasterService.error(
            `Error removing Strategy ASIN${toDelete.length > 1 ? "s" : ""} ${toDeleteMsg}: ${
              error.response ? error.response.message : error.message
            }`,
            "Strategy ASIN update error",
          );
        },
      );
  }

  moveStrategyAsins(toMove: StrategyAsin[]): void {
    const asinsToMove = toMove.map((sa) => sa.asin);
    const modalOptions: ModalOptions = {
      initialState: {
        source: this.strategy,
        asinsToMove: asinsToMove,
      },
      class: "modal-primary",
    };
    const modalRef = this.modalService.show(MoveStrategyAsinsModalComponent, modalOptions);
    let creationModalRef: BsModalRef = null;

    const subscription = modalRef.content.selectedStrategy
      .pipe(switchMap((target) => this.configurationService.moveAsinsToStrategy(asinsToMove, this.strategy, target)))
      .subscribe(
        () => {
          this.toasterService.success("ASINs succesfully moved to other strategy");
          subscription.unsubscribe();
        },
        (error: string) => {
          this.toasterService.error(`Error moving ASINs to other strategy: ${error}`, "Error moving ASINs");
          subscription.unsubscribe();
        },
      );
    subscription.add(
      modalRef.content.newStrategyCreation
        .pipe(
          switchMap(() => {
            const modalOptions: ModalOptions = {
              initialState: {
                asins: toMove,
              },
              class: "modal-xl",
            };
            creationModalRef = this.modalService.show(SpStrategyCreationComponent, modalOptions);
            return creationModalRef.content.strategyCreated;
          }),
          switchMap(() => this.configurationService.deleteAsinsFromStrategy(this.strategy, asinsToMove)),
        )
        .subscribe(
          () => {
            this.toasterService.success("ASINs succesfully moved to new strategy");
            subscription.unsubscribe();
            creationModalRef.hide();
          },
          (error: string) => {
            this.toasterService.error(`Error moving ASINs to new strategy: ${error}`, "Error moving ASINs");
            subscription.unsubscribe();
            creationModalRef.hide();
          },
        ),
    );
  }

  addStrategyAsins(toAdd: StrategyAsin[]) {
    this.configurationService
      .addAsinsToStrategy(
        this.strategy,
        toAdd.map((a) => a.asin),
      )
      .subscribe(
        () => {
          const toAddMsg = this.prettyPrintAsinList(toAdd.map((a) => a.asin));
          this.toasterService.success(
            `ASIN${toAdd.length > 1 ? "s" : ""} ${toAddMsg} added to SP strategy "${this.strategy.getName()}"`,
            "Strategy ASIN updated",
          );
        },
        (error: string) => {
          const toAddMsg = this.prettyPrintAsinList(toAdd.map((a) => a.asin));
          this.toasterService.error(
            `Error adding Strategy ASIN${toAdd.length > 1 ? "s" : ""} ${toAddMsg}: ${error}`,
            "Strategy ASIN update error",
          );
        },
      );
  }

  toggleIneligibleAsinDetails() {
    this.ineligibleAsinsCollapsed = !this.ineligibleAsinsCollapsed;
  }

  getBrands(): string {
    return Array.from(this.brands).join(" - ");
  }
}
