import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { Marketplace, Marketplaces, mediaQuery } from "@front/m19-services";
import { Option } from "@front/m19-ui";
import { UntilDestroy } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-marketplace-selector",
  templateUrl: "./marketplace-selector.component.html",
})
export class MarketplaceSelectorComponent implements OnInit, OnChanges {
  @Input()
  marketplaces: Marketplace[];

  @Input()
  marketplaceSelected: Marketplace;

  @Output()
  marketplaceSelectedChange = new EventEmitter<Marketplace>();

  @Output()
  marketplacesSelected = new EventEmitter<Marketplace>();

  dropdownOptions: Option<Marketplace>[] = [];
  selected: Option<Marketplace> | undefined;

  isWrapped = false;
  selection: Map<Marketplace, boolean> = new Map();

  readonly Marketplaces = Marketplaces;

  ngOnInit(): void {
    this.buildSelection();
  }

  ngOnChanges(): void {
    this.buildSelection();
  }

  private buildSelection() {
    const width = 1200 + this.selection.size * 50;
    mediaQuery(`(max-width: ${width}px)`).subscribe((matches) => {
      this.isWrapped = matches && this.selection.size > 4;
    });

    this.dropdownOptions = this.marketplaces.map((m: Marketplace) => ({
      label: `${Marketplaces[m].flag} ${m} - ${Marketplaces[m].name}`,
      value: m,
    }));
    this.selected = this.toOption(this.marketplaceSelected);

    this.marketplaces.forEach((m) => this.selection.set(m, false));
    if (this.marketplaceSelected) {
      this.selection.set(this.marketplaceSelected, true);
    } else {
      this.selection.set(this.marketplaces[0], true);
    }
  }

  toggleMarketplaceOption(option: Option<Marketplace>): void {
    this.selected = option;
    this.toggleMarketplace(option.value);
  }

  toggleMarketplace(marketplace: Marketplace): void {
    this.selected = this.toOption(marketplace);
    for (const k of this.selection.keys()) {
      this.selection.set(k, marketplace == k);
      if (marketplace == k) localStorage.setItem("currentMarketplace", marketplace);
    }
    this.marketplaceSelectedChange.emit(marketplace);
  }

  toOption(marketplace: Marketplace): { label: string; value: Marketplace } {
    return { label: marketplace, value: marketplace };
  }
}
