import { Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  AccessLevel,
  AccountMarketplace,
  AccountMarketplaceService,
  AccountSelectionService,
  AccountSubType,
  AccountType,
} from "@front/m19-services";
import { BoardType, LayoutSelectorService } from "@m19-board/layout-selector.service";
import { AccountSettingsService } from "@m19-board/settings/account-settings.service";
import { filter } from "rxjs";

@UntilDestroy()
@Component({
  selector: "app-stats-overlay",
  templateUrl: "./stats-overlay.component.html",
  styleUrls: ["./overlay.component.scss"],
})
export class StatsOverlayComponent implements OnInit {
  public readonly BoardType = BoardType;
  public readonly AccessLevel = AccessLevel;

  @Input() checkOrganicDataAccess = false;

  noAccountGroupSetup = false;
  boardType: BoardType;
  isSelfService = false;
  loading = true;
  invalidApiAccess = false;
  accountMarketplace: AccountMarketplace;
  freshData = true;
  hasAccessToAdvertising = true;

  constructor(
    private accountMarketplaceService: AccountMarketplaceService,
    private layoutSelectorService: LayoutSelectorService,
    private accountSelectionService: AccountSelectionService,
    private accountSettingsService: AccountSettingsService,
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.boardType = this.layoutSelectorService.getBoardType();
    this.accountMarketplaceService.accountMarketplaces$
      .pipe(filter((b): b is AccountMarketplace[] => !!b && b.length > 0))
      .subscribe((accountMarketplaces) => {
        this.noAccountGroupSetup =
          accountMarketplaces.length == 0 ||
          accountMarketplaces.findIndex((am) => am.accountGroupId && am.accountGroupId > 0) < 0;
        this.loading = false;
      });

    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(untilDestroyed(this))
      .subscribe((am: AccountMarketplace) => {
        this.invalidApiAccess = !am.isValidToken;
        this.accountMarketplace = am;
        this.freshData = this.accountMarketplaceService.hasFreshStats(am, 30);
        this.hasAccessToAdvertising = am.hasAccessToAdvertising;
      });
  }

  requestGrant(): void {
    this.accountSettingsService.grantSellingPartnerAccess(
      this.accountMarketplace.accountId,
      this.accountMarketplace.marketplace,
      this.accountMarketplace.accountType,
      this.accountMarketplace.accountSubType == AccountSubType.KDP_AUTHOR,
    );
  }

  logInPlaceForAPIGrantAccess() {
    return this.accountMarketplace.accountType == AccountType.SELLER ? "Seller Central" : "Vendor Central";
  }
}
