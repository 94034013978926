import { Component, EventEmitter, Input, Output } from "@angular/core";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faExclamationTriangle, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import {
  AlgoMode,
  CampaignType,
  ConfigService,
  Intensity,
  MatchType,
  SegmentConfigType,
  StrategyEx,
  StrategyTacticEx,
  TacticType,
} from "@front/m19-services";
import { KeywordSegmentModalComponent } from "@m19-board/segments/keyword-segment-modal.component";
import { ProductSegmentModalComponent } from "@m19-board/segments/product-segment-modal.component";
import { ConfirmPopupComponent } from "@m19-board/shared/confirm-popup/confirm-popup.component";
import { ICON_EDIT_O, ICON_TRASH_O } from "@m19-board/utils/iconsLabels";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { TranslocoService } from "@jsverse/transloco";
/* Modal component to delete the Tactic from the Strategy */
@Component({
  selector: "app-modal-content",
  templateUrl: "./remove-tactic-popup.component.html",
})
export class ModalRemoveTacticFromStrategyComponent {
  accountId: string;
  strategyId: number;
  tactic: StrategyTacticEx;
  isSdTargeting: false;

  constructor(
    public configurationService: ConfigService,
    public bsModalRef: BsModalRef,
  ) {}

  delete() {
    this.configurationService.removeTacticFromStrategy(this.accountId, this.strategyId, this.tactic.segment.segmentId);
    this.bsModalRef.hide();
  }
}

/* Main Component*/
@Component({
  selector: "app-tactic",
  templateUrl: "./tactic.component.html",
  styleUrls: ["./tactic.component.scss"],
})
export class TacticComponent {
  _tactic: StrategyTacticEx;
  _strategy: StrategyEx;
  AlgoModeTypes = AlgoMode;
  boostTopSearchEnabled = false;
  segmentType: SegmentConfigType;
  readonly faPencil = faPencilAlt;
  readonly faTrash = faTrashAlt;
  readonly faWarning = faExclamationTriangle;
  readonly SegmentType = SegmentConfigType;
  readonly ICON_TRASH = ICON_TRASH_O;
  readonly ICON_EDIT = ICON_EDIT_O;

  @Input() showKW = true;
  @Input() showProducts = true;
  @Input() isReadOnly = false;

  @Input()
  set tactic(tactic: StrategyTacticEx) {
    this._tactic = tactic;
    this.segmentType = tactic.segment.segmentType;
  }

  @Input()
  set strategy(strategy: StrategyEx) {
    this._strategy = strategy;
    this.boostTopSearchEnabled = strategy.campaignType == CampaignType.SP;
  }

  @Output() tacticDeleted = new EventEmitter<number>();

  constructor(
    public configurationService: ConfigService,
    private modalService: BsModalService,
    private translocoService: TranslocoService,
  ) {}

  nbKeywords(): number {
    return this._tactic.segment.items.filter((x) => x.matchType == MatchType.exact || x.matchType == MatchType.phrase)
      .length;
  }

  nbProductTargeting(): number {
    return this._tactic.segment.items.filter((x) => x.matchType == MatchType.asinSameAs).length;
  }

  lastProductKeyword(): boolean {
    return (
      this._strategy &&
      this._tactic.tacticType != TacticType.BLACKLIST &&
      this._strategy.tactics.filter((x) => x.segment && x.tacticType != TacticType.BLACKLIST).length == 1
    );
  }

  assertUnreachable(x): never {
    throw new Error("Didn't expect to get here");
  }

  getIntensity() {
    if (this._tactic.intensity) {
      const throwIntensityEnumError: Intensity = <any>Intensity.LOW;
      switch (this._tactic.intensity) {
        case Intensity.LOW:
          return 1;
        case Intensity.MEDIUM_LOW:
          return 2;
        case Intensity.NEUTRAL:
          return 3;
        case Intensity.MEDIUM_HIGH:
          return 4;
        case Intensity.HIGH:
          return 5;
        default:
          this.assertUnreachable(throwIntensityEnumError);
      }
    }
  }

  setIntensity(val: number | string) {
    let tmpIntensity = undefined;
    switch (+val) {
      case 1:
        tmpIntensity = Intensity.LOW;
        break;
      case 2:
        tmpIntensity = Intensity.MEDIUM_LOW;
        break;
      case 3:
        tmpIntensity = Intensity.NEUTRAL;
        break;
      case 4:
        tmpIntensity = Intensity.MEDIUM_HIGH;
        break;
      case 5:
        tmpIntensity = Intensity.HIGH;
        break;
    }

    if (tmpIntensity !== this._tactic.intensity) {
      this._tactic.intensity = tmpIntensity;
      this.configurationService.updateTacticIntensity(
        this._strategy.accountId,
        this._strategy.marketplace,
        this._strategy.strategyId,
        this._tactic.segmentId,
        this._tactic.intensity,
      );
    }
  }

  switchBoostPlacementTop(): void {
    this._tactic.boostPlacementTop = !this._tactic.boostPlacementTop;
    this.configurationService.updateTacticBoostPlacementTop(
      this._strategy.accountId,
      this._strategy.marketplace,
      this._strategy.strategyId,
      this._tactic.segmentId,
      this._tactic.boostPlacementTop,
    );
  }

  editSegment() {
    if (this.segmentType == SegmentConfigType.KeywordSegment) {
      const modalOptions: ModalOptions = {
        initialState: {
          segment: this._tactic?.segment,
          isReadOnly: this.isReadOnly,
        },
        class: "modal-xl",
      };
      this.modalService.show(KeywordSegmentModalComponent, modalOptions);
    } else if (this.segmentType == SegmentConfigType.ProductSegment) {
      const modalOptions: ModalOptions = {
        initialState: {
          segment: this._tactic?.segment,
          isReadOnly: this.isReadOnly,
        },
        class: "modal-xl",
      };
      this.modalService.show(ProductSegmentModalComponent, modalOptions);
    }
  }

  removeTacticFromStrategy() {
    const ref = this.modalService.show(ConfirmPopupComponent, {
      initialState: {
        type: "danger",
        title: `Remove Tactic from Strategy`,
        message: `Are you sure you want to delete ${this._tactic.segment.name} tactic from this strategy?`,
        confirmCta: "Delete",
      },
      class: "modal-dialog-centered",
    });
    ref.content.confirm.subscribe(() => {
      this.configurationService.removeTacticFromStrategy(
        this._strategy.accountId,
        this._strategy.strategyId,
        this._tactic.segment.segmentId,
      );
      this.tacticDeleted.emit(this._tactic.segmentId);
    });
  }

  getSegmentTypeLabel() {
    switch (this.segmentType) {
      case SegmentConfigType.KeywordSegment:
        return this.translocoService.translate("common.keyword");
      case SegmentConfigType.ProductSegment:
        return this.translocoService.translate("common.product");
      case SegmentConfigType.MixedSegment:
      default:
        return this.translocoService.translate("tactic.product_and_keyword");
    }
  }

  getSegmentTypeDesc() {
    switch (this.segmentType) {
      case SegmentConfigType.KeywordSegment:
        return this.translocoService.translate("tactic.keywords");
      case SegmentConfigType.ProductSegment:
        return this.translocoService.translate("tactic.product_pages");
      case SegmentConfigType.MixedSegment:
      default:
        return this.translocoService.translate("tactic.product_pages_and_keywords");
    }
  }
}
