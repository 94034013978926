// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    Intensity,
    TacticType,
} from './';

/**
 * @export
 * @interface StrategyTactic
 */
export interface StrategyTactic {
    /**
     * @type {number}
     * @memberof StrategyTactic
     */
    segmentId?: number;
    /**
     * @type {TacticType}
     * @memberof StrategyTactic
     */
    tacticType?: TacticType;
    /**
     * @type {Intensity}
     * @memberof StrategyTactic
     */
    intensity?: Intensity;
    /**
     * @type {boolean}
     * @memberof StrategyTactic
     */
    boostPlacementTop?: boolean;
}
