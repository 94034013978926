// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    Marketplace,
    Response,
    StrategyGroup,
    StrategyGroupMigration,
    Targeting,
} from '../models';

export interface CreateStrategyGroupRequest {
    accountId: string;
    marketplace: Marketplace;
    strategyGroupName: string;
}

export interface DeleteStrategyGroupRequest {
    accountId: string;
    marketplace: Marketplace;
    strategyGroupId: number;
}

export interface GetStrategyGroupByIdRequest {
    accountId: string;
    marketplace: Marketplace;
    strategyGroupId: number;
}

export interface ListStrategyGroupRequest {
    accountId: string;
    marketplace: Marketplace;
}

export interface MigrateToNewStrategyGroupRequest {
    accountId: string;
    marketplace: Marketplace;
    strategyGroupMigration?: StrategyGroupMigration;
}

export interface UpdateStrategyGroupRequest {
    accountId: string;
    marketplace: Marketplace;
    organizationId: number;
    strategyGroupId: number;
    strategyGroupName?: string;
}

export interface UpdateStrategyGroupBlacklistRequest {
    accountId: string;
    marketplace: Marketplace;
    strategyGroupId: number;
    action: UpdateStrategyGroupBlacklistActionEnum;
    targeting: Array<Targeting>;
}

/**
 * no description
 */
export class StrategyGroupApi extends BaseAPI {

    /**
     * Create a new strategy Group
     */
    createStrategyGroup({ accountId, marketplace, strategyGroupName }: CreateStrategyGroupRequest): Observable<Response>
    createStrategyGroup({ accountId, marketplace, strategyGroupName }: CreateStrategyGroupRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    createStrategyGroup({ accountId, marketplace, strategyGroupName }: CreateStrategyGroupRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'createStrategyGroup');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'createStrategyGroup');
        throwIfNullOrUndefined(strategyGroupName, 'strategyGroupName', 'createStrategyGroup');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'strategyGroupName': strategyGroupName,
        };

        return this.request<Response>({
            url: '/strategyGroup/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'POST',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Delete a strategy group
     */
    deleteStrategyGroup({ accountId, marketplace, strategyGroupId }: DeleteStrategyGroupRequest): Observable<Response>
    deleteStrategyGroup({ accountId, marketplace, strategyGroupId }: DeleteStrategyGroupRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    deleteStrategyGroup({ accountId, marketplace, strategyGroupId }: DeleteStrategyGroupRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'deleteStrategyGroup');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'deleteStrategyGroup');
        throwIfNullOrUndefined(strategyGroupId, 'strategyGroupId', 'deleteStrategyGroup');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Response>({
            url: '/strategyGroup/{accountId}/{marketplace}/{strategyGroupId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{strategyGroupId}', encodeURI(strategyGroupId)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Get a strategyGroup by its id
     */
    getStrategyGroupById({ accountId, marketplace, strategyGroupId }: GetStrategyGroupByIdRequest): Observable<StrategyGroup>
    getStrategyGroupById({ accountId, marketplace, strategyGroupId }: GetStrategyGroupByIdRequest, opts?: OperationOpts): Observable<AjaxResponse<StrategyGroup>>
    getStrategyGroupById({ accountId, marketplace, strategyGroupId }: GetStrategyGroupByIdRequest, opts?: OperationOpts): Observable<StrategyGroup | AjaxResponse<StrategyGroup>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'getStrategyGroupById');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getStrategyGroupById');
        throwIfNullOrUndefined(strategyGroupId, 'strategyGroupId', 'getStrategyGroupById');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<StrategyGroup>({
            url: '/strategyGroup/{accountId}/{marketplace}/{strategyGroupId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{strategyGroupId}', encodeURI(strategyGroupId)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * List strategy groups by accountId / marketplace
     */
    listStrategyGroup({ accountId, marketplace }: ListStrategyGroupRequest): Observable<Array<StrategyGroup>>
    listStrategyGroup({ accountId, marketplace }: ListStrategyGroupRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<StrategyGroup>>>
    listStrategyGroup({ accountId, marketplace }: ListStrategyGroupRequest, opts?: OperationOpts): Observable<Array<StrategyGroup> | AjaxResponse<Array<StrategyGroup>>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'listStrategyGroup');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'listStrategyGroup');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<StrategyGroup>>({
            url: '/strategyGroup/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Create a new strategy Group with existing strategies
     */
    migrateToNewStrategyGroup({ accountId, marketplace, strategyGroupMigration }: MigrateToNewStrategyGroupRequest): Observable<Response>
    migrateToNewStrategyGroup({ accountId, marketplace, strategyGroupMigration }: MigrateToNewStrategyGroupRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    migrateToNewStrategyGroup({ accountId, marketplace, strategyGroupMigration }: MigrateToNewStrategyGroupRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'migrateToNewStrategyGroup');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'migrateToNewStrategyGroup');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Response>({
            url: '/strategyGroupMigration/{accountId}/{marketplace}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)),
            method: 'POST',
            headers,
            body: strategyGroupMigration,
        }, opts?.responseOpts);
    };

    /**
     * Update single field of a strategy group by its id
     */
    updateStrategyGroup({ accountId, marketplace, organizationId, strategyGroupId, strategyGroupName }: UpdateStrategyGroupRequest): Observable<Response>
    updateStrategyGroup({ accountId, marketplace, organizationId, strategyGroupId, strategyGroupName }: UpdateStrategyGroupRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    updateStrategyGroup({ accountId, marketplace, organizationId, strategyGroupId, strategyGroupName }: UpdateStrategyGroupRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'updateStrategyGroup');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'updateStrategyGroup');
        throwIfNullOrUndefined(organizationId, 'organizationId', 'updateStrategyGroup');
        throwIfNullOrUndefined(strategyGroupId, 'strategyGroupId', 'updateStrategyGroup');

        const headers: HttpHeaders = {
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'organizationId': organizationId,
        };

        if (strategyGroupName != null) { query['strategyGroupName'] = strategyGroupName; }

        return this.request<Response>({
            url: '/strategyGroup/{accountId}/{marketplace}/{strategyGroupId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{strategyGroupId}', encodeURI(strategyGroupId)),
            method: 'PUT',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Add / delete blacklisted targeting item to / from a strategyGroup
     */
    updateStrategyGroupBlacklist({ accountId, marketplace, strategyGroupId, action, targeting }: UpdateStrategyGroupBlacklistRequest): Observable<Response>
    updateStrategyGroupBlacklist({ accountId, marketplace, strategyGroupId, action, targeting }: UpdateStrategyGroupBlacklistRequest, opts?: OperationOpts): Observable<AjaxResponse<Response>>
    updateStrategyGroupBlacklist({ accountId, marketplace, strategyGroupId, action, targeting }: UpdateStrategyGroupBlacklistRequest, opts?: OperationOpts): Observable<Response | AjaxResponse<Response>> {
        throwIfNullOrUndefined(accountId, 'accountId', 'updateStrategyGroupBlacklist');
        throwIfNullOrUndefined(marketplace, 'marketplace', 'updateStrategyGroupBlacklist');
        throwIfNullOrUndefined(strategyGroupId, 'strategyGroupId', 'updateStrategyGroupBlacklist');
        throwIfNullOrUndefined(action, 'action', 'updateStrategyGroupBlacklist');
        throwIfNullOrUndefined(targeting, 'targeting', 'updateStrategyGroupBlacklist');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            // oauth required
            ...(this.configuration.accessToken != null
                ? { Authorization: typeof this.configuration.accessToken === 'function'
                    ? this.configuration.accessToken('accessToken', [])
                    : this.configuration.accessToken }
                : undefined
            ),
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'action': action,
        };

        return this.request<Response>({
            url: '/strategyGroup/blacklist/{accountId}/{marketplace}/{strategyGroupId}'.replace('{accountId}', encodeURI(accountId)).replace('{marketplace}', encodeURI(marketplace)).replace('{strategyGroupId}', encodeURI(strategyGroupId)),
            method: 'POST',
            headers,
            query,
            body: targeting,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum UpdateStrategyGroupBlacklistActionEnum {
    ADD = 'ADD',
    DELETE = 'DELETE'
}
