import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";
import { CAMPAIGN_TYPE_COLOR, CAMPAIGN_TYPE_NAME } from "../../insights/overview/overview.component";

@Component({
  selector: "app-campaign-type-badge",
  standalone: true,
  imports: [CommonModule, MatTooltipModule],
  template: ` <span
    [matTooltip]="CAMPAIGN_TYPE_NAME[campaignType]"
    [ngStyle]="{ 'background-color': CAMPAIGN_TYPE_COLOR[campaignType] }"
    class="badge rounded-full text-white"
    >{{ campaignType }}
  </span>`,
  styleUrls: ["./campaign-type-badge.component.scss"],
})
export class CampaignTypeBadgeComponent implements ICellRendererAngularComp {
  @Input() campaignType: string;

  protected readonly CAMPAIGN_TYPE_NAME = CAMPAIGN_TYPE_NAME;
  protected readonly CAMPAIGN_TYPE_COLOR = CAMPAIGN_TYPE_COLOR;

  agInit(params: ICellRendererParams & { campaignType: string }): void {
    this.campaignType = params.campaignType;
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }
}
