import { Currencies, Marketplaces } from '.';
import { Currency, Marketplace } from './api-client';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  public getCurrencies(): Currency[] {
    return Object.keys(Currency) as Currency[];
  }

  public getCurrencySymbolFromMarketplace(marketplace: Marketplace): string {
    return this.getCurrencySymbol(this.getCurrencyCode(marketplace));
  }

  public getCurrencySymbol(code: Currency): string {
    return Currencies[code].currencySymbol;
  }

  public printCurrency(code: Currency): string {
    return this.getCurrencySymbol(code) + ' ' + code;
  }

  public getCurrencyCode(marketplace: Marketplace): Currency {
    return Marketplaces[marketplace]?.currency;
  }
}
