<ng-container *transloco="let t">
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ t("link-your-account-button.link_account") }}</h4>
  </div>
  <div class="modal-body">
    <app-spinner [display]="loading" [main]="true"></app-spinner>
    @if (successMessage) {
      <div class="alert alert-success"><span [innerHtml]="successMessage"></span></div>
    }
    @if (errorMessage) {
      <div class="alert alert-warning"><span [innerHtml]="errorMessage"></span></div>
    }
    @if (availableAccountMarketplaces?.length > 0) {
      <div class="p-2">
        <div class="form-group row">
          <span class="col-sm-3 col-form-label">{{ t("add-account-group.select_your_amazon_account") }}</span>
          <div class="col-sm-9">
            <ISelect
              [options]="availableAccountsOptions"
              [selected]="{ value: selectedAccount, label: selectedAccount.accountName }"
              (selectedChange)="selectAccount($event.value)"
              placeholder="Select Account"
              by="track"
              searchable
            >
              <ng-template #optionSlot #labelSlot let-o>
                <span class="inline-flex w-full items-center justify-between">
                  <div class="truncate">
                    <span class="mr-2 text-sm">{{ o.label }}</span>
                    <IBadge
                      [label]="o.value.accountId.startsWith('ENTITY') ? t('common.vendor') : t('common.seller')"
                      color="gray"
                      size="xs"
                      class="ml-1"
                    />
                  </div>
                  <span class="ml-2">
                    @for (m of o.marketplaces; track m) {
                      <span [matTooltip]="Marketplaces[m].name" [class.mr-2]="!$last">{{ Marketplaces[m].flag }}</span>
                    }
                  </span>
                </span>
              </ng-template>
            </ISelect>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">{{ t("add-account-group.select_your_marketplaces") }}</label>
          <div class="col-sm-9">
            @for (entry of selectedMarketplaces | keyvalue; track entry) {
              <div class="cursor-pointer" (click)="toggleMarketplace(entry.key)">
                <fa-icon [icon]="entry.value ? faCheckedSquare : faSquare" class="text-primary" size="lg"></fa-icon>
                {{ entry.key }}
              </div>
            }
          </div>
        </div>
        <div class="form-group row">
          <span class="col-sm-3 col-form-label">{{ t("add-account-group.add_to") }}</span>
          <div class="col-sm-9">
            <ISelect
              [options]="accountGroupOptions"
              [selected]="{ value: selectedAccountGroup, label: selectedAccountGroupName }"
              (selectedChange)="selectTargetAccountGroupId($event.value)"
              placeholder="Select Account Group"
            />
          </div>
        </div>
        @if (!selectedAccountGroup) {
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">{{ t("common.name") }}</label>
            <div class="col-sm-9">
              <input
                type="text"
                maxlength="80"
                class="border-shadow form-control"
                placeholder="{{ t('add-account-group.give_your_account_a_name') }}"
                [value]="accountGroupName ? accountGroupName : ''"
                (input)="changeName(newName.value)"
                (keydown.enter)="changeName(newName.value)"
                #newName
              />
              @if (doesGroupExist(accountGroupName) && !loading) {
                <p class="invalid-feedback block">{{ t("add-account-group.account_name_already_exists") }}</p>
              }
            </div>
          </div>
        }
        <div class="row mt-4">
          <div class="col-sm-6 offset-3">
            @if (availableAccountMarketplaces?.length > 0) {
              <button type="button" class="btn btn-primary w-full" (click)="save()" [disabled]="disableSave()">
                {{ t("add-account-group.link_account") }}
              </button>
            }
          </div>
        </div>
      </div>
    }
    @if (canLoginWithAmazon && availableAccountMarketplaces.length === 0) {
      <div class="p-2">
        <div class="row">
          <div class="col-6 offset-3 text-center">
            <input
              class="amazon-login-button"
              type="image"
              (click)="logWithAmazon()"
              src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gry_156x132.png"
            />
          </div>
        </div>
      </div>
    }
    @if (!canLoginWithAmazon && availableAccountMarketplaces.length === 0) {
      <div class="p-2 text-center">
        <span>{{ t("add-account-group.no_accounts_available") }}</span>
      </div>
    }
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="exit()">{{ t("common.exit") }}</button>
  </div>
</ng-container>
