import { Component, Input, OnInit } from "@angular/core";
import { faPauseCircle, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import {
  faExclamationCircle,
  faImage,
  faPause,
  faPencilAlt,
  faPlay,
  faPlayCircle,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import {
  AccountType,
  AsinService,
  BrandAsset,
  Catalog,
  InventoryRules,
  InventoryService,
  InventoryStats,
  M19Status,
  MediaType,
  NotificationBidderIssueEx,
  NotificationEx,
  NotificationService,
  NotificationType,
  PreModeration,
  PreModerationStatus,
  ProductEx,
  SbCreative,
  SbCreativeEx,
  SbCreativeType,
  State,
  StrategyEx,
  Utils,
} from "@front/m19-services";
import { ConfirmPopupComponent } from "@m19-board/shared/confirm-popup/confirm-popup.component";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { combineLatest, tap } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { FormMode, SbStrategyFormComponent } from "@m19-board/strategies/sb-strategy-form/sb-strategy-form.component";
import { SbStrategiesService } from "libs/m19-services/src/lib/m19-services/sb-strategies.service";

@Component({
  selector: "app-creative-display",
  templateUrl: "./creative-display.component.html",
  styleUrls: ["./creative-display.component.scss"],
})
export class CreativeDisplayComponent implements OnInit {
  @Input() strategy: StrategyEx;
  @Input() creative: SbCreativeEx;
  @Input() accountType: AccountType;
  @Input() disabled = false;
  @Input() disableDeletion = false;

  @Input() set customImages(images: BrandAsset[]) {
    this._customImages = images;
  }

  _customImages: BrandAsset[];
  toUpload: BrandAsset;

  faultyCreativeReason: string;
  faultyReason: string;
  status: M19Status;
  SbCreativeType = SbCreativeType;
  hasNoAsins = false;
  customImageLoading = false;
  readonly faPause = faPause;
  readonly faPlay = faPlay;
  readonly faPauseCircle = faPauseCircle;
  readonly faPlayCircle = faPlayCircle;
  readonly faExclamationCircle = faExclamationCircle;
  readonly faImage = faImage;
  readonly faVideo = faVideo;
  readonly faTrash = faTrashAlt;
  readonly faPencil = faPencilAlt;
  readonly M19Status = M19Status;

  constructor(
    private asinService: AsinService,
    private modalService: BsModalService,
    private sbStrategiesService: SbStrategiesService,
    private toastrService: ToastrService,
    private notificationService: NotificationService,
    private inventoryService: InventoryService,
    private toasterService: ToastrService,
  ) {}

  ngOnInit() {
    combineLatest<[InventoryRules, InventoryStats[], Catalog, NotificationEx]>([
      this.asinService.getInventoryRules(this.strategy.accountId, this.strategy.marketplace),
      this.inventoryService.getInventoryStats(this.strategy.accountId, this.strategy.marketplace),
      this.asinService.getCatalog(this.strategy.accountId, this.strategy.marketplace),
      this.notificationService.getNotifications$.pipe(
        map((x: NotificationEx[]) =>
          x
            .filter((n) => n.type === NotificationType.BIDDER_ISSUE)
            .find((n) => n.subStrategyId === this.creative.creativeId),
        ),
      ),
    ]).subscribe(([rules, stats, catalog, notif]) => {
      const sbNotif = notif as NotificationBidderIssueEx;
      [this.status, this.faultyCreativeReason] = Utils.getSBFaultyReasons(
        this.creative,
        catalog,
        rules,
        stats,
        sbNotif,
      );
      this.faultyReason = undefined;
      this.hasNoAsins = false;
      if (sbNotif) {
        if (sbNotif?.moderationDetails && sbNotif?.moderationDetails.length > 0) {
          const unique = [...new Set(sbNotif.moderationDetails.map((x) => x.segment))];
          const nb =
            `${sbNotif.moderationDetails.length} Ad${Utils.pluriel(sbNotif.moderationDetails)} ` +
            `(campaign${Utils.pluriel(unique)}: ${unique.join(", ")}) `;
          this.faultyReason = nb + Utils.m19StatusToReadableStr(this.status) + ": " + this.faultyCreativeReason;
        } else {
          this.faultyReason = `Some Ads ${Utils.m19StatusToReadableStr(
            this.status,
          )} (More details should be available in the following days)`;
        }
      }
      if (this.creative?.creativeAsins && this.creative?.creativeAsins.length === 0) {
        this.faultyReason = "This Ad Line is missing ASINs, please edit your Ad Line to add ASINs";
        this.hasNoAsins = true;
      }
    });
  }

  editCreative() {
    const modaloptions: ModalOptions = {
      initialState: {
        strategy: this.strategy,
        creative: this.creative,
        formMode: FormMode.EDIT_CREATIVE,
      },
      class: "big-modal modal-dialog-centered modal-limit",
      keyboard: false,
      backdrop: "static",
    };
    this.modalService.show(SbStrategyFormComponent, modaloptions);
  }

  uploadCreativeCustomImage(customImage: File) {
    this.customImageLoading = true;
    return this.sbStrategiesService
      .uploadBrandAsset({
        accountId: this.creative.accountId,
        marketplace: this.creative.marketplace,
        brandEntityId: this.creative.brandEntityId,
        mediaType: MediaType.customImage,
        file: customImage,
      })
      .pipe(
        tap((asset: BrandAsset) => {
          this.toUpload = asset;
          this.customImageLoading = true;
        }),
        switchMap((asset: BrandAsset) =>
          this.sbStrategiesService.sbPreModeration(
            this.creative.accountId,
            this.creative.marketplace,
            undefined,
            undefined,
            [asset.url],
          ),
        ),
      )
      .subscribe({
        next: (premod: PreModeration) => {
          const res = premod.textComponents.find((component) => component.id == "customImage");
          if (res && res.preModerationStatus === PreModerationStatus.REJECTED) {
            this.toastrService.error(res.policyViolations[0]?.policyDescription, "Asset Pre Moderation");
            this.customImageLoading = false;
          } else this.updateCreativeCustomImage(this.toUpload);
        },
        error: (err) => {
          this.toasterService.error(err, "SB creative update error");
          this.customImageLoading = false;
        },
      });
  }

  copyCreativeId() {
    navigator.clipboard.writeText(this.creative.creativeId.toString());
    this.toastrService.success("Creative ID copied to clipboard");
  }

  updateCreativeCustomImage(image: BrandAsset) {
    const newCreative: SbCreative = { ...this.creative, customImageAssetId: image.assetId };
    this.sbStrategiesService.updateSbCreative(this.creative, newCreative).subscribe({
      next: () => {
        this.toasterService.success("SB creative updated");
        this.customImageLoading = false;
      },
      error: (err) => {
        this.toasterService.error(err, "SB creative update error");
        this.customImageLoading = false;
      },
    });
  }

  toggleStatus() {
    if (this.creative.state == State.ON) {
      if (this.strategy.sbCreatives.filter((x) => x.state == State.ON).length == 1) {
        this.toastrService.error(
          "You cannot pause all the creatives in a strategy, you should pause the strategy instead.",
        );
        return;
      }
      this.creative.state = State.OFF;
    } else this.creative.state = State.ON;
    this.sbStrategiesService.updateSbCreativeStatus(this.creative.toSbCreative()).subscribe({
      next: () => {
        this.toastrService.success("Status updated to " + this.creative.state);
      },
      error: (err) => {
        this.toastrService.error(err, "SB creative status update error");
      },
    });
  }

  deleteCreative() {
    const modalOptions: ModalOptions = {
      initialState: {
        title: "Creative Deletion",
        message: `Are you sure you want to delete this Creative?`,
        confirmCta: "Delete",
      },
      class: "modal-danger",
    };
    const modalRef = this.modalService.show(ConfirmPopupComponent, modalOptions);
    modalRef.content.confirm.pipe(switchMap(() => this.sbStrategiesService.deleteSbCreative(this.creative))).subscribe({
      next: () => {
        this.toastrService.success("SB creative successfully deleted");
      },
      error: (err) => {
        this.toastrService.error(err, "SB creative deletion error");
      },
    });
  }

  getProductImage(asin: string) {
    return this.asinService
      .getProductWithMarketplace(asin, this.strategy.marketplace)
      .pipe(map((p: ProductEx) => p.imageUrl));
  }

  protected readonly MediaType = MediaType;
}
