import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AccountSelectionService, AccountType } from "@front/m19-services";
import { SELLER_PRODUCT_TABS, VENDOR_PRODUCT_TABS } from "@m19-board/tabbed-nav/tabbed-nav.component";
import { NavData } from "@m19-board/_nav";

@UntilDestroy()
@Component({
  selector: "app-products",
  template: `<app-tabbed-nav [links]="tabs"> </app-tabbed-nav> <router-outlet></router-outlet>`,
})
export class ProductsComponent {
  tabs: NavData[] = SELLER_PRODUCT_TABS;

  constructor(private accountSelectionService: AccountSelectionService) {
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(untilDestroyed(this))
      .subscribe((accountMarketplace) => {
        this.tabs = accountMarketplace.accountType == AccountType.SELLER ? SELLER_PRODUCT_TABS : VENDOR_PRODUCT_TABS;
      });
  }
}
