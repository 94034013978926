import { Component, Input } from "@angular/core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { AsinService, Marketplace, Utils } from "@front/m19-services";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-import-managed-catalog-modal",
  template: ` <div class="modal-header bg-primary">
      <h4 class="modal-title pull-left">Catalog data upload report</h4>
      <a>
        <h4 class="modal-title pull-right" (click)="bsModalRef.hide()">&times;</h4>
      </a>
    </div>
    <div class="m-4">
      <textarea
        type="text"
        #asins
        class="textarea-width form-control coding-font"
        placeholder="asin1&#10;asin2"
      ></textarea>
      <div class="mt-2 flex items-center justify-end">
        <IButton
          [label]="mode === 'managed' ? 'Restore To Managed Catalog' : 'Stop Managing Products'"
          [disabled]="isUploadButtonDisabled(asins.value)"
          (onClick)="import(asins.value); asins.value = ''"
        />
        <IButton label="Close" class="ml-2" (onClick)="bsModalRef.hide()" color="white" />
      </div>
      <div class="mt-2">
        @if (errors && errors.length > 0) {
          <ul class="list-group">
            <li class="list-group-item list-group-item list-group-item-warning flex justify-between">
              <span>The following ASINs have been ignored:</span>
              <button
                type="button"
                class="btn btn-ghost-secondary"
                (click)="errors = []"
                matTooltip="Close error table"
              >
                <fa-icon [icon]="faTimes" class="font-lg"></fa-icon>
              </button>
            </li>
            @for (error of errors; track error) {
              <li class="list-group-item list-group-item-secondary py-0">
                {{ error }}
              </li>
            }
          </ul>
        }
      </div>
    </div>`,
  styles: [
    `
      textarea {
        resize: none;
        height: 250px;
        border-bottom-right-radius: 0%;
      }
    `,
  ],
})
export class ImportManagedCatalogModalComponent {
  @Input() accountId: string;
  @Input() marketplace: Marketplace;
  @Input() mode: "managed" | "unmanaged";
  @Input() unmanagedCatalog: string[];
  @Input() managedCatalog: string[];

  errors: string[] = [];

  readonly faTimes = faTimes;

  constructor(
    private asinService: AsinService,
    private toastrService: ToastrService,
    public bsModalRef: BsModalRef,
  ) {}

  isUploadButtonDisabled(content: string): boolean {
    return !content || content.trim().length === 0;
  }

  import(inputAsins: string): void {
    const asins = [];
    this.errors = [];
    for (const asin of inputAsins.split(/[\s,;\n]+/)) {
      const normalizedAsin = Utils.normalizeASIN(asin);
      if (normalizedAsin === "") {
        this.errors.push(asin + ": Invalid ASIN");
        continue;
      }
      if (asins.includes(normalizedAsin)) {
        this.errors.push(asin + ": Duplicated");
        continue;
      }
      if (this.mode === "managed" && this.managedCatalog.includes(normalizedAsin)) {
        this.errors.push(asin + ": Already in managed catalog");
        continue;
      }
      if (this.mode === "managed" && !this.unmanagedCatalog.includes(normalizedAsin)) {
        this.errors.push(asin + ": ASIN is unknown");
        continue;
      }
      if (this.mode === "unmanaged" && this.unmanagedCatalog.includes(normalizedAsin)) {
        this.errors.push(asin + ": Already in unmanaged catalog");
        continue;
      }
      if (this.mode === "unmanaged" && !this.managedCatalog.includes(normalizedAsin)) {
        this.errors.push(asin + ": ASIN is unknown");
        continue;
      }

      asins.push(normalizedAsin);
    }
    if (asins.length == 0) {
      this.errors.push(`No asins to add to catalog`);
      return;
    }

    if (this.mode === "managed") {
      this.asinService.addAsins(this.accountId, this.marketplace, asins).subscribe({
        next: () => {
          this.toastrService.success("Products are back to managed catalog");
        },
        error: (err) => {
          this.toastrService.error(err, "Failed to move back products to managed catalog");
        },
      });
    } else {
      this.asinService.deleteAsins(this.accountId, this.marketplace, asins).subscribe({
        next: () => {
          this.toastrService.success("Products move to unmanaged catalog");
        },
        error: (err) => {
          this.toastrService.error(err, "Failed to move products to unmanaged catalog");
        },
      });
    }
  }
}
