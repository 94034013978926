import { Injectable } from "@angular/core";
import {
  AccountMarketplace,
  AccountMarketplaceService,
  AccountType,
  AsinService,
  Marketplace,
  NotificationService,
  NotificationType,
  SellingPartnerService,
} from "@front/m19-services";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { switchMap, take } from "rxjs";
import { SellingPartnerIntegrationValidationModalComponent } from "./account-settings/selling-partner-integration/selling-partner-integration-validation-modal.component";

@Injectable({
  providedIn: "root",
})
export class AccountSettingsService {
  constructor(
    private sellingPartnerService: SellingPartnerService,
    private toasterService: ToastrService,
    private notificationService: NotificationService,
    private modalService: BsModalService,
    private asinService: AsinService,
    private accountMarketplaceService: AccountMarketplaceService,
  ) {}

  public grantSellingPartnerAccess(
    accountId: string,
    marketplace: Marketplace,
    accountType: AccountType,
    isKDP: boolean,
  ) {
    if (isKDP) {
      this.toasterService.warning(
        "Organic data API access is not yet supported for KDP accounts",
        "Selling partner API access warning",
      );
      return;
    }
    const sub = this.sellingPartnerService
      .requestSellingPartnerAccess(accountId, marketplace, accountType === AccountType.VENDOR, isKDP)
      .pipe(take(1))
      .subscribe({
        next: (id: string) => {
          this.toasterService.success(
            "API access successfully granted for account " + id,
            "Selling partner API access validated",
          );
          this.notificationService.deleteNotification(accountId, NotificationType.SELLING_PARTNER_API_ACCESS_LOST);
          sub.unsubscribe();
        },
        error: (err: string) => {
          this.toasterService.error(err, "Selling partner API access error");
          sub.unsubscribe();
        },
      });
  }

  public validateIntegration(account: AccountMarketplace) {
    const modalOptions: ModalOptions = {
      initialState: {
        accountId: account.accountId,
        accountName: account.accountName,
        marketplace: account.marketplace,
      },
      class: "modal-xl",
    };
    const modalRef = this.modalService.show(SellingPartnerIntegrationValidationModalComponent, modalOptions);
    const subscription = modalRef.content.validated
      .pipe(switchMap(() => this.asinService.validateVendorCatalog(account.accountId, account.marketplace)))
      .subscribe({
        next: () => {
          this.toasterService.success(`Selling partner API integration validated`);
          this.accountMarketplaceService.reload();
          subscription.unsubscribe();
        },
        error: (err) => {
          this.toasterService.error(err, "Selling partner API integration error");
          subscription.unsubscribe();
        },
      });
    subscription.add(
      modalRef.content.rejected
        .pipe(switchMap(() => this.asinService.rejectVendorCatalog(account.accountId, account.marketplace)))
        .subscribe({
          next: () => {
            this.toasterService.success(`Selling partner API integration rejected`);
            this.accountMarketplaceService.reload();
            subscription.unsubscribe();
          },
          error: (err) => {
            this.toasterService.error(err, "Selling partner API integration rejection error");
            subscription.unsubscribe();
          },
        }),
    );
  }
}
