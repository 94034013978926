@if (strategy_.state === "ENABLED" && constraint) {
  <div>
    <img
      [src]="constraint.src"
      [width]="size_"
      [height]="size_"
      [matTooltip]="constraint.title"
      matTooltipPosition="below"
    />
  </div>
}
