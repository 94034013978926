import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  AuthorizedAccess,
  AuthorizedAccessUpdate,
  OrganizationUser,
  UpdateAuthorizedAccessActionEnum,
  UserApi,
} from './api-client';
import { AuthService } from './auth.service';
import { catchAjaxError } from './utils';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OrganizationUsersService {
  constructor(
    private authService: AuthService,
    private userApi: UserApi,
  ) {}

  private readonly reloadOrganizations = new BehaviorSubject<any>(null);

  public listOrganizations(): Observable<OrganizationUser[]> {
    return this.reloadOrganizations.asObservable().pipe(switchMap(() => this.userApi.listOrganizationUsers()));
  }

  public addAdmin(organizationId: number, email: string) {
    return this.userApi.addAdmin({ organizationId: organizationId, email: email }).pipe(
      tap(() => {
        this.reloadOrganizations.next(null);
      }),
      catchError((error) => throwError(() => error.response.message)),
    );
  }

  public revokeAdminAccess(
    organizationId: number,
    m19UserId: number,
    onSuccess?: () => void,
    onError?: (error?: any) => void,
  ): void {
    this.userApi
      .revokeAdminAccess({ organizationId: organizationId, adminId: m19UserId })
      .pipe(withLatestFrom(this.authService.loggedUser$))
      .subscribe(([_, loggedUser]) => {
        if (loggedUser!.userId === m19UserId) this.authService.reloadUser();
        this.reloadOrganizations.next(null);
        if (onSuccess) {
          onSuccess();
        }
      }, onError);
  }

  public listAuthorizedAccess(): Observable<AuthorizedAccess[]> {
    // todo(oussama) remove duplicate calls to get authorized access, only reload when reloadOrganizations emits
    return this.reloadOrganizations.asObservable().pipe(switchMap(() => this.userApi.listAuthorizedAccess()));
  }

  public addAuthorizedAccessAsync(authorizedAccess: AuthorizedAccess[]): Observable<void> {
    return this.userApi
      .updateAuthorizedAccess({
        action: UpdateAuthorizedAccessActionEnum.ADD,
        authorizedAccessUpdate: authorizedAccess.map((x) => ({
          organizationId: x.organizationId,
          profileId: x.profileId,
          email: x.email,
          accessLevel: x.accessLevel,
        })) as AuthorizedAccessUpdate[],
      })
      .pipe(
        catchAjaxError(),
        tap(() => {
          this.reloadOrganizations.next(null);
        }),
        map(() => void 0),
      );
  }

  public revokeAuthorizedAccessAsync(authorizedAccessToDelete: AuthorizedAccess[]): Observable<void> {
    return this.userApi
      .updateAuthorizedAccess({
        action: UpdateAuthorizedAccessActionEnum.DELETE,
        authorizedAccessUpdate: authorizedAccessToDelete.map((x) => ({
          organizationId: x.organizationId,
          profileId: x.profileId,
          email: x.email,
        })) as AuthorizedAccessUpdate[],
      })
      .pipe(
        catchAjaxError(),
        tap(() => {
          this.reloadOrganizations.next(null);
        }),
        map(() => void 0),
      );
  }
}
