<div class="modal-header bg-primary">
  <h4 class="modal-title pull-left">{{ segment.name }} strategies</h4>
  <a><h4 class="modal-title pull-right" (click)="bsModalRef.hide()">&times;</h4></a>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-4">
      <app-strategies-info [campaignType]="CampaignType.SP" [strategies]="linkedStrategies"></app-strategies-info>
    </div>
    <div class="col-4">
      <app-strategies-info [campaignType]="CampaignType.SB" [strategies]="linkedStrategies"></app-strategies-info>
    </div>
    <div class="col-4">
      <app-strategies-info [campaignType]="CampaignType.SD" [strategies]="linkedStrategies"></app-strategies-info>
    </div>
  </div>
</div>
