import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  AccountSelectionService,
  AccountType,
  addAdStats,
  AuthService,
  getBasicGridOptions,
  Marketplace,
  Marketplaces,
  mergeSeveralDates,
  Metric,
  MetricType,
  StatsApiClientService,
  UserSelectionService,
  Utils,
} from "@front/m19-services";
import { AdStatsEx } from "@front/m19-services";

import { AgGridAngular } from "@ag-grid-community/angular";
import { ColDef, ColGroupDef, GridOptions, IRowNode } from "@ag-grid-community/core";
import { MarketplaceColumn } from "@m19-board/grid-config/grid-columns";
import { getMetricsColDef } from "@m19-board/grid-config/grid-config";
import { DonutDataSet } from "@m19-board/models/DonutDataSet";
import { CsvExportService, fieldExtractor, metricField, simpleField } from "@m19-board/services/csv-export.service";
import { combineLatest, debounceTime, Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";

@UntilDestroy()
@Component({
  selector: "app-marketplaces",
  templateUrl: "./marketplaces.component.html",
})
export class MarketplacesComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private userSelectionService: UserSelectionService,
    private statsApiClientService: StatsApiClientService,
    private accountSelectionService: AccountSelectionService,
    private csvExporterService: CsvExportService,
  ) {}

  @Input()
  mainMetrics$: Observable<Metric<AdStatsEx>>;
  @Input()
  accountType: AccountType;

  @Input()
  metrics: Metric<AdStatsEx>[];

  readonly RATIO = MetricType.RATIO;

  // state
  currency: string;
  locale: string;
  accountGroupName: string;
  loading = true;
  mainMetric: Metric<AdStatsEx>;
  marketplaceCount: number;

  // donut chart config
  donutDataSet = new DonutDataSet<AdStatsEx>(addAdStats, (x) => ({
    key: x.marketplace,
    label: `${Marketplaces[x.marketplace].flag} ${x.marketplace}`,
    color: Marketplaces[x.marketplace].color,
  }));

  // ag-grid config and data

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  readonly DASHBOARD_GRID_KEY = "MarketplaceDataTable";
  gridOptions: GridOptions;
  gridData: AdStatsEx[];
  previousPeriodMktplData: Map<Marketplace, AdStatsEx> = new Map();

  private getColumnDefs(): ColDef[] {
    return [
      { ...MarketplaceColumn, pinned: "left" },
      ...getMetricsColDef(this.metrics, true).map((def: ColGroupDef<AdStatsEx>) => ({
        ...def,
        children: def.children.map((c: ColDef) => ({
          ...c,
          cellRendererParams: (params) => {
            return {
              ...c.cellRendererParams(params),
              previousData: this.getPreviousNodeData(params.node),
              currency: this.currency,
              locale: this.locale,
            };
          },
        })),
      })),
    ];
  }

  ngOnInit(): void {
    this.gridOptions = {
      ...getBasicGridOptions(this.DASHBOARD_GRID_KEY + this.accountType, true),
      context: { componentParent: this },
      columnDefs: this.getColumnDefs(),
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        floatingFilter: false,
      },
      showOpenedGroup: false,
      rowGroupPanelShow: "never",
      enableRangeSelection: true,
      pagination: false,
    };

    this.mainMetrics$.pipe(untilDestroyed(this)).subscribe((m) => {
      this.mainMetric = m;
      this.reloadChartData();
    });
    this.accountSelectionService.accountMarketplacesSelection$
      .pipe(untilDestroyed(this))
      .subscribe((am) => (this.marketplaceCount = am.length));

    this.accountSelectionService.accountGroupSelection$.pipe(untilDestroyed(this)).subscribe((accountGroup) => {
      this.accountGroupName = accountGroup.name;
    });
    this.userSelectionService.selectedCurrency$.pipe(untilDestroyed(this)).subscribe((x) => {
      this.currency = x;
      this.donutDataSet.currency = x;
    });
    this.authService.loggedUser$.subscribe((x) => {
      this.locale = x.locale;
      this.donutDataSet.locale = x.locale;
    });

    combineLatest([this.accountSelectionService.accountMarketplacesSelection$, this.userSelectionService.dateRange$])
      .pipe(
        untilDestroyed(this),
        switchMap(([accountMarketplaces, dateRange]) => {
          const data$ = accountMarketplaces.map((am) =>
            this.statsApiClientService
              .getGlobalDataStats(am.accountId, am.marketplace, am.useSourcingMetrics)
              .pipe(map((data) => ({ marketplace: am.marketplace, data }))),
          );
          this.agGrid?.api.showLoadingOverlay();
          return combineLatest(data$);
        }),
        debounceTime(100), // wait for all data to be loaded before updating the grid
      )
      .subscribe((markeplaceData) => {
        this.loading = false;
        this.agGrid.api?.hideOverlay();
        this.gridData = [];
        for (const dataLine of markeplaceData) {
          let gridDataLine = { marketplace: dataLine.marketplace } as AdStatsEx;
          for (const d of dataLine.data) {
            gridDataLine = mergeSeveralDates(gridDataLine, d);
          }
          this.gridData.push(gridDataLine);
        }
        this.reloadChartData();
      });

    combineLatest([
      this.accountSelectionService.accountMarketplacesSelection$,
      this.userSelectionService.periodComparison$,
    ])
      .pipe(
        untilDestroyed(this),
        switchMap(([accountMarketplaces, comparison]) => {
          if (!comparison.period || Utils.getDateIntervalInDays(comparison.period) > StatsApiClientService.maxComp) {
            return of(accountMarketplaces.map((am) => ({ marketplace: am.marketplace, data: [] })));
          }
          const data$ = accountMarketplaces.map((am) =>
            this.statsApiClientService
              .getPreviousPeriodGlobalDataStats(am.accountId, am.marketplace, am.useSourcingMetrics)
              .pipe(map((data) => ({ marketplace: am.marketplace, data }))),
          );
          return combineLatest(data$);
        }),
        debounceTime(100), // wait for all data to be loaded before updating the grid
      )
      .subscribe((markeplaceData) => {
        this.previousPeriodMktplData.clear();
        for (const dataLine of markeplaceData) {
          let gridDataLine = { marketplace: dataLine.marketplace } as AdStatsEx;
          for (const d of dataLine.data) {
            gridDataLine = mergeSeveralDates(gridDataLine, d);
          }
          this.previousPeriodMktplData.set(gridDataLine.marketplace, gridDataLine);
        }
        this.agGrid?.api.refreshCells({ force: true });
      });
  }

  private getPreviousNodeData(node: IRowNode): AdStatsEx {
    return this.previousPeriodMktplData.get(node.data.marketplace);
  }

  // Allows to reload chart data, when it has not been necessarily updated, for example when main metric is changed
  reloadChartData(): void {
    if (!this.mainMetric) return;

    const chartData = this.gridData
      ? [...this.gridData].sort((a, b) => this.mainMetric.value(b) - this.mainMetric.value(a))
      : undefined;
    this.donutDataSet.buildDataSet(chartData, this.mainMetric);
  }

  downloadFile() {
    const dateRange = this.userSelectionService.getDateRangeStr();
    this.csvExporterService.exportCsv(
      { prefix: "marketplace_stats", accountGroupName: this.accountGroupName, dateRange: dateRange },
      this.gridData,
      [
        simpleField("marketplace"),
        fieldExtractor("currency", () => this.currency),
        ...this.metrics.map((m) => metricField(m)),
      ],
    );
  }
}
