import { Component, OnInit } from "@angular/core";
import { Currency, CurrencyService, UserSelectionService } from "@front/m19-services";
import { Option } from "@front/m19-ui";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-currency-selection",
  templateUrl: "./currency-selection.component.html",
})
export class CurrencySelectionComponent implements OnInit {
  selectedCurrency: Option<Currency> | undefined;

  currencies: Option<Currency>[] = [
    { label: this.currencyService.getCurrencySymbol(Currency.EUR), value: Currency.EUR },
    { label: this.currencyService.getCurrencySymbol(Currency.USD), value: Currency.USD },
    { label: this.currencyService.getCurrencySymbol(Currency.GBP), value: Currency.GBP },
  ];

  constructor(
    public currencyService: CurrencyService,
    public userSelectionService: UserSelectionService,
  ) {}

  ngOnInit() {
    this.userSelectionService.selectedCurrency$.pipe(untilDestroyed(this)).subscribe((x) => {
      this.currencies = this.currencies.concat(this.getOtherCurrencies());

      this.selectedCurrency = this.currencies?.find((c) => c.value === x);
    });
  }

  private getOtherCurrencies(): { label: string; value: Currency }[] {
    return this.currencyService
      .getCurrencies()
      .filter((c: Currency) => !this.currencies.some((ct) => ct.value === c))
      .map((c: Currency) => ({ label: this.currencyService.getCurrencySymbol(c), value: c }));
  }

  currencyChange(currency: Option<Currency>) {
    this.userSelectionService.setCurrency(Currency[currency.value]);
  }

  protected readonly Currency = Currency;
}
