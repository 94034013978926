import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { SbCreativeType, StrategyEx, StrategyStateEnum } from "@front/m19-services";
import { StrategyCache } from "libs/m19-services/src/lib/m19-services/strategy.cache";
import { BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: "app-sb-creative-warning",
  templateUrl: "./sb-creative-warning.component.html",
})
export class SbCreativeWarningComponent implements OnInit {
  creativesWithoutCustomImage = 0;
  campaignWithoutCustomImage = new Set<StrategyEx>();
  campaignWithoutCustomImageArr: StrategyEx[];

  // missing cutom image modal ref
  @ViewChild("missingCustomImgModal") customImageModal: TemplateRef<any>;

  constructor(
    private strategyCache: StrategyCache,
    private modalService: BsModalService,
  ) {}

  ngOnInit(): void {
    this.strategyCache.strategyIndex$.subscribe((strategies) => {
      this.creativesWithoutCustomImage = 0;
      this.campaignWithoutCustomImage.clear();
      // detect live strategies without custom image
      strategies.forEach((strategy) => {
        const campaignCreativesWithoutCustomImage =
          strategy.sbCreatives?.filter((c) => c.creativeType === SbCreativeType.productCollection && !c.customImage) ??
          [];
        if (campaignCreativesWithoutCustomImage.length > 0 && strategy.state == StrategyStateEnum.ENABLED) {
          this.campaignWithoutCustomImage.add(strategy);
          this.creativesWithoutCustomImage += campaignCreativesWithoutCustomImage.length;
        }
      });
      this.campaignWithoutCustomImageArr = Array.from(this.campaignWithoutCustomImage.values());
    });
  }

  showStrategiesWithoutCustomImage() {
    this.modalService.show(this.customImageModal, {
      class: "modal-primary",
    });
  }
}
