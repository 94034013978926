import { NgClass } from "@angular/common";
import { Component, computed, EventEmitter, inject, Input, Output, signal } from "@angular/core";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import {
  AccountMarketplace,
  ConfigService,
  MatchType,
  Strategy,
  StrategyApi,
  StrategyAsin,
  StrategyGroupEx,
  StrategyTargetingType,
  StrategyType,
  Targeting,
} from "@front/m19-services";
import { IBadgeComponent, IButtonComponent, IInputComponent } from "@front/m19-ui";
import { AsinItemComponent } from "@m19-board/shared/asin-item/asin-item.component";
import { ConfirmPopupComponent } from "@m19-board/shared/confirm-popup/confirm-popup.component";
import { StrategyAsinSelectionMode } from "@m19-board/strategies/strategy-asins/asins-selection.component";
import { ICON_CLOSE, ICON_EDIT_O, ICON_SEARCH } from "@m19-board/utils/iconsLabels";
import { Constant } from "libs/m19-services/src/lib/m19-services/constant";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Observable, of, Subject, switchMap } from "rxjs";
import { FromBrandSelectionModalComponent } from "../sp-substrategy-creation/from-brand-selection-modal/from-brand-selection-modal.component";
import { StrategyKeywordModalComponent } from "./strategy-keyword-modal.component";
import { StrategyProductTargetingsModalComponent } from "./strategy-product-targetings-modal.component";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";

@Component({
  selector: "app-targetings-modal",
  standalone: true,
  imports: [
    MatSlideToggle,
    IButtonComponent,
    IBadgeComponent,
    IInputComponent,
    AsinItemComponent,
    NgClass,
    TranslocoRootModule,
  ],
  templateUrl: "./targetings-modal.component.html",
})
export class TargetingsModalComponent {
  readonly modalRef = inject(BsModalRef);
  readonly modalService = inject(BsModalService);
  readonly toastrService = inject(ToastrService);
  readonly configurationService = inject(ConfigService);
  readonly strategyApi = inject(StrategyApi);

  readonly ICON_CLOSE = ICON_CLOSE;
  readonly ICON_EDIT = ICON_EDIT_O;
  readonly ICON_SEARCH = ICON_SEARCH;

  readonly MAX_STRATEGY_ASINS = Constant.maxAsinTargetingByStrategy;

  @Input() strategy: Strategy;
  @Input() strategyType: StrategyType;
  @Input() targetingType: StrategyTargetingType;
  @Input() strategyGroup: StrategyGroupEx;
  @Input() accountMarketplace: AccountMarketplace;

  @Input() set targetings(targetings: Targeting[]) {
    this.keywords.set(targetings.filter((t) => t.matchType !== MatchType.asinSameAs));
    this.products.set(targetings.filter((t) => t.matchType === MatchType.asinSameAs));
  }

  searchKeywordQuery = signal<string>("");
  searchProductQuery = signal<string>("");

  keywords = signal<Targeting[]>([]);
  products = signal<Targeting[]>([]);
  allTargetings = computed(() => [...this.keywords(), ...this.products()]);

  shownKeywords = computed(() => {
    const regex = new RegExp(this.searchKeywordQuery(), "i");
    return this.keywords().filter((t) => regex.test(t.targetingValue));
  });
  shownProducts = computed(() => {
    const regex = new RegExp(this.searchProductQuery(), "i");
    return this.products().filter((t) => regex.test(t.targetingValue));
  });

  @Input() isReadOnly: boolean;

  @Output() onIsolationUpdated = new EventEmitter<{ strategy: Strategy; isolation: boolean }>();

  // From Brand imports

  toggleTargetingType() {
    if (this.allTargetings().length > 0) {
      const modalOpts: ModalOptions = {
        initialState: {
          title: `Change to ${this.targetingType == StrategyTargetingType.KEYWORDS ? "product" : "keyword"} targeting`,
          message: `This will remove the current ${this.allTargetings().length} ${
            this.targetingType == StrategyTargetingType.KEYWORDS ? "keyword" : "product"
          } targeting${this.allTargetings().length > 1 ? "s" : ""} from the strategy`,
        },
        class: "modal-dialog-centered",
      };
      const modalRef = this.modalService.show(ConfirmPopupComponent, modalOpts);
      modalRef.content.confirm
        .pipe(
          switchMap(() => this.configurationService.removeTargetingFromStrategy(this.strategy, this.allTargetings())),
        )
        .subscribe({
          next: () => {
            this.toastrService.success(`All targetings removed from strategy`, "Strategy Targetings Updated");
            this.keywords.set([]);
            this.products.set([]);
            this.setTargetingType(
              this.targetingType === StrategyTargetingType.KEYWORDS
                ? StrategyTargetingType.PRODUCTS
                : StrategyTargetingType.KEYWORDS,
            );
          },
          error: (error: string) => {
            this.toastrService.error(
              `Error removing Strategy targetings: ${error}`,
              "Strategy Targetings Update Error",
            );
          },
        });
    } else {
      this.setTargetingType(
        this.targetingType === StrategyTargetingType.KEYWORDS
          ? StrategyTargetingType.PRODUCTS
          : StrategyTargetingType.KEYWORDS,
      );
    }
  }

  private setTargetingType(type: StrategyTargetingType) {
    this.targetingType = type;
  }

  updateIsolation(isolation: boolean) {
    this.strategyApi
      .updateStrategy({
        accountId: this.strategy.accountId,
        marketplace: this.strategy.marketplace,
        strategyId: this.strategy.strategyId,
        organizationId: this.accountMarketplace.resourceOrganizationId,
        asinIsolation: isolation,
      })
      .subscribe({
        next: () => {
          this.toastrService.success(
            `${isolation ? "Enable" : "Disabled"} ASIN level isolation for strategy ${this.strategy.name}`,
            "Asin level isolation update",
          );
          if (this.strategy.strategyType === StrategyType.BRAND) {
            this.strategyGroup.brandStrategies.find((s) => s.strategyId === this.strategy.strategyId).asinIsolation =
              isolation;
          } else {
            this.strategyGroup.keywordStrategies.find((s) => s.strategyId === this.strategy.strategyId).asinIsolation =
              isolation;
          }
          this.onIsolationUpdated.emit({ strategy: this.strategy, isolation });
        },
        error: (e: string) => {
          this.toastrService.error(e, "Strategy isolation update error");
        },
      });
  }

  editKeywordTargetings() {
    const ref = this.modalService.show(StrategyKeywordModalComponent, {
      class: "modal-dialog-centered modal-primary modal-xl",
      initialState: {
        title: "Edit Keyword Targetings",
        marketplace: this.accountMarketplace.marketplace,
        keywords: this.keywords(),
        isReadOnly: this.isReadOnly,
      },
    });
    if (!ref.content) {
      return;
    }

    ref.content.addedKeywords
      .pipe(
        switchMap((targetings: Targeting[]) => {
          ref.content!.loading = true; // add a loading overlay
          return this.addKeywords(targetings);
        }),
      )
      .subscribe(() => {
        ref.content!.loading = false; // hide loading overlay
      });
    ref.content.deletedKeywords
      .pipe(
        switchMap((targetings: Targeting[]) => {
          ref.content!.loading = true; // add a loading overlay
          return this.deleteKeywords(targetings);
        }),
      )
      .subscribe(() => {
        ref.content!.loading = false; // // hide loading overlay
      });
  }

  private addKeywords(targetings: Targeting[]): Observable<void> {
    const filteredTargetings = targetings.filter(
      (t) => !this.keywords().find((a) => a.targetingValue == t.targetingValue),
    );

    if (filteredTargetings.length == 0) {
      this.toastrService.info("No new keywords to add");
      return of(void 0);
    }
    const result = new Subject<void>();
    this.configurationService.addTargetingToStrategy(this.strategy, filteredTargetings).subscribe({
      next: () => {
        this.toastrService.success(
          `Keyword${filteredTargetings.length > 1 ? "s" : ""} added to strategy`,
          "Strategy Targeting Updated",
        );
        this.keywords.set([...this.keywords(), ...filteredTargetings]);
        result.next();
        result.complete();
      },
      error: (error: string) => {
        this.toastrService.error(
          `Error adding Strategy keyword${filteredTargetings.length > 1 ? "s" : ""}: ${error}`,
          "Strategy Targeting Update Error",
        );
        result.next();
        result.complete();
      },
    });
    return result;
  }

  private deleteKeywords(targetings: Targeting[]): Observable<void> {
    const result = new Subject<void>();
    this.configurationService.removeTargetingFromStrategy(this.strategy, targetings).subscribe({
      next: () => {
        this.toastrService.success(
          `Keyword${targetings.length > 1 ? "s" : ""} removed from strategy`,
          "Strategy Targeting Updated",
        );
        this.keywords.set(
          this.keywords().filter(
            (t) =>
              targetings.findIndex((k) => k.matchType === t.matchType && k.targetingValue === t.targetingValue) < 0,
          ),
        );
        result.next();
        result.complete();
      },
      error: (error: string) => {
        this.toastrService.error(
          `Error removing Strategy keyword${targetings.length > 1 ? "s" : ""}: ${error}`,
          "Strategy Targeting Update Error",
        );
        result.next();
        result.complete();
      },
    });
    return result;
  }

  editProductTargetings() {
    let productSelectionModes: { selectionMode: StrategyAsinSelectionMode; label: string }[] = [];
    let productTargetingAllowAsinFromOtherCatalog = false;

    if (this.strategyType === StrategyType.BRAND) {
      productSelectionModes = [
        { selectionMode: StrategyAsinSelectionMode.FromCatalog, label: "From Catalog" },
        { selectionMode: StrategyAsinSelectionMode.Bulk, label: "From ASIN list" },
        { selectionMode: StrategyAsinSelectionMode.FromProductGroups, label: "From Product Groups" },
      ];
      productTargetingAllowAsinFromOtherCatalog = false;
    } else {
      productSelectionModes = [{ selectionMode: StrategyAsinSelectionMode.Bulk, label: "ASIN list" }];
      productTargetingAllowAsinFromOtherCatalog = true;
    }

    const strategyAsins: StrategyAsin[] = this.products().map((t) => ({ asin: t.targetingValue }));

    const ref = this.modalService.show(StrategyProductTargetingsModalComponent, {
      class: "modal-xl modal-primary modal-dialog-centered",
      initialState: {
        title: "Edit Product Targetings",
        asins: strategyAsins,
        accountId: this.accountMarketplace.accountId,
        marketplace: this.accountMarketplace.marketplace,
        allowAsinFromOtherCatalog: productTargetingAllowAsinFromOtherCatalog,
        bulkPlaceholderText: `List of ASINs to target (max ${this.MAX_STRATEGY_ASINS} ASINs per strategy)`,
        maxProducts: this.MAX_STRATEGY_ASINS,
        selectionModes: productSelectionModes,
      },
    });

    ref.content.onAdd.subscribe((asins: StrategyAsin[]) => {
      this.addProduct(asins);
      strategyAsins.push(...asins);
      ref.content.asins = strategyAsins;
      ref.content.reloadAsins();
    });

    ref.content.onDelete.subscribe((asins: StrategyAsin[]) => {
      this.deleteProduct(asins);
      strategyAsins.splice(
        0,
        strategyAsins.length,
        ...strategyAsins.filter((a) => !asins.map((t) => t.asin).includes(a.asin)),
      );
      ref.content.asins = strategyAsins;
      ref.content.reloadAsins();
    });
  }

  private addProduct(asins: StrategyAsin[]) {
    if (asins.length == 0) {
      return;
    }
    const filtered: Targeting[] = asins
      .filter((a) => !this.products().find((t) => t.targetingValue == a.asin))
      .map((a) => ({ targetingValue: a.asin, matchType: MatchType.asinSameAs }));

    if (filtered.length == 0) {
      this.toastrService.info("No new product targetings to add");
      return;
    }

    this.configurationService.addTargetingToStrategy(this.strategy, filtered).subscribe({
      next: () => {
        this.toastrService.success(
          `Product Targeting${filtered.length > 1 ? "s" : ""} added to strategy`,
          "Strategy Targeting Updated",
        );
        this.products.set([...this.products(), ...filtered]);
        this.strategy.targetings = [...this.keywords(), ...this.products()];
      },
      error: (error: string) => {
        this.toastrService.error(
          `Error adding Strategy product Targeting${filtered.length > 1 ? "s" : ""}: ${error}`,
          "Strategy Targeting Update Error",
        );
      },
    });
  }

  private deleteProduct(asins: StrategyAsin[]) {
    const targetings: Targeting[] = asins.map((a) => ({ targetingValue: a.asin, matchType: MatchType.asinSameAs }));

    this.configurationService.removeTargetingFromStrategy(this.strategy, targetings).subscribe({
      next: () => {
        this.toastrService.success(
          `Product Targeting${targetings.length > 1 ? "s" : ""} removed from strategy`,
          "Strategy Targeting Updated",
        );
        this.products.update((products) =>
          products.filter((a) => !targetings.map((t) => t.targetingValue).includes(a.targetingValue)),
        );
        this.strategy.targetings = [...this.keywords(), ...this.products()];
      },
      error: (error: string) => {
        this.toastrService.error(
          `Error removing strategy product targeting${targetings.length > 1 ? "s" : ""}: ${error}`,
          "Strategy Targeting Update Error",
        );
      },
    });
  }

  addTargetFromBrand() {
    const ref = this.modalService.show(FromBrandSelectionModalComponent, {
      class: "modal-primary modal-dialog-centered",
    });

    ref.content.emitTargetings.subscribe((targetings) => {
      this.addKeywords(targetings.keywords);
      this.addProduct(targetings.products);
    });
  }

  addTargetingsFromBrand() {
    throw new Error("Not implemented");
  }

  readonly StrategyTargetingType = StrategyTargetingType;
  readonly MatchType = MatchType;
  readonly StrategyType = StrategyType;
}
