import { Component, Input } from "@angular/core";
import { KeywordTrackerConfig, KeywordTrackingService, Marketplace } from "@front/m19-services";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";

@Component({
  templateUrl: "./stop-keyword-tracking-modal.component.html",
})
export class StopKeywordTrackingModalComponent {
  @Input()
  accountId: string;
  @Input()
  marketplace: Marketplace;
  @Input()
  organizationId: number;
  @Input()
  searchTerm: string;

  constructor(
    private keywordTrackingService: KeywordTrackingService,
    private toastrService: ToastrService,
    public bsModalRef: BsModalRef,
  ) {}

  delete() {
    const toDelete: KeywordTrackerConfig[] = [
      {
        accountId: this.accountId,
        marketplace: this.marketplace,
        organizationId: this.organizationId,
        searchTerm: this.searchTerm,
      },
    ];
    const subscription = this.keywordTrackingService
      .removeKeywordTrackingConfig(this.accountId, this.marketplace, this.organizationId, toDelete)
      .subscribe({
        next: () => {
          this.toastrService.success(`Tracking of "${this.searchTerm}" has been stopped`, "Keyword tracking stopped");
          this.bsModalRef.hide();
          subscription.unsubscribe();
        },
        error: (e) => {
          this.toastrService.error(
            `Error when stopping tracking "${this.searchTerm}". ${e}`,
            "Keyword tracking stop error",
          );
          subscription.unsubscribe();
        },
      });
  }
}
