import { InjectionToken } from '@angular/core';
import { AlertService } from '../alert.service';
import { Observable } from 'rxjs';
import { AmazonLoginHandler } from '@front/m19-services';

export const API_BASE_URL = new InjectionToken<string>('API_BASE_URL');

export const SELLING_PARTNER_DOMAIN = new InjectionToken<string>('SELLING_PARTNER_DOMAIN');

export const ALERT_SERVICE = new InjectionToken<AlertService>('ALERT_SERVICE');

export const AMAZON_LOGIN_HANDLER = new InjectionToken<Observable<AmazonLoginHandler>>('AMAZON_LOGIN_HANDLER');
