import { Component, EventEmitter, Input, OnInit, Output, ViewChild, inject } from "@angular/core";

import { AgGridAngular, AgGridModule } from "@ag-grid-community/angular";
import { SubStrategyStats } from "../../models/Metric";
import { GridApi, GridOptions } from "@ag-grid-community/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatTooltipModule } from "@angular/material/tooltip";
import { AuthService, CampaignType, Currency, StrategyEx, UserSelectionService } from "@front/m19-services";
import { BsModalRef } from "ngx-bootstrap/modal";
import { MatTabsModule } from "@angular/material/tabs";
import { SbCreativeStatsGridComponent } from "../../strategies/sb-creative-stats-grid/sb-creative-stats-grid.component";
import { NgTemplateOutlet } from "@angular/common";
import { ExportButtonComponent } from "../ui/export-buttons/export-button.component";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";
import { untilDestroyed } from "@ngneat/until-destroy";

@Component({
  selector: "app-grid-viewer",
  standalone: true,
  templateUrl: "./grid-viewer.component.html",
  styleUrls: ["./grid-viewer.component.scss"],
  imports: [
    AgGridModule,
    FontAwesomeModule,
    MatTooltipModule,
    MatTabsModule,
    SbCreativeStatsGridComponent,
    NgTemplateOutlet,
    ExportButtonComponent,
    TranslocoRootModule,
  ],
})
export class GridViewerComponent implements OnInit {
  private userSelectionService = inject(UserSelectionService);
  private authService = inject(AuthService);
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  @Input() withCreativeTab: boolean;
  @Input() strategy: StrategyEx;
  @Input() gridData: SubStrategyStats[];
  @Input() gridOptions: GridOptions;
  @Input() strategyType: CampaignType;
  @Input() title: string;

  @Output() onExportCsv: EventEmitter<GridApi> = new EventEmitter();

  selectedTab = 0;
  tabChanged = false;
  currency: Currency;
  locale: string;

  constructor(private bsModalRef: BsModalRef) {}

  ngOnInit(): void {
    this.userSelectionService.selectedCurrency$.pipe(untilDestroyed(this)).subscribe((currency) => {
      this.currency = currency;
    });
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.locale = user.locale;
    });
  }

  onExportCsvClicked() {
    this.onExportCsv.emit(this.agGrid?.api);
  }

  close() {
    this.bsModalRef.hide();
  }
}
