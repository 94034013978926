import { formatCurrency } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { AccountMarketplace, AccountMarketplaceService, AuthService, CurrencyService } from "@front/m19-services";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";

@Component({
  templateUrl: "./update-max-bid.popup.component.html",
  styleUrls: ["./update-max-bid.popup.scss"],
})
export class ModalUpdateMaxBidComponent implements OnInit {
  accountMarketplace: AccountMarketplace;
  maxBid: number;
  currencySymbol: string;
  locale: string;

  constructor(
    public bsModalRef: BsModalRef,
    private accountMarketplaceService: AccountMarketplaceService,
    private currencyService: CurrencyService,
    private toasterService: ToastrService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.currencySymbol = this.currencyService.getCurrencySymbolFromMarketplace(this.accountMarketplace.marketplace);
    this.maxBid = this.accountMarketplace.maxBid;
    this.authService.user$.subscribe((user) => {
      this.locale = user.locale;
    });
  }

  updateMaxBid() {
    this.accountMarketplaceService.updateMaxBid(
      this.accountMarketplace,
      this.maxBid,
      () => {
        this.toasterService.success(
          "Max Bid successfully changed to " +
            formatCurrency(this.maxBid, this.locale, this.currencySymbol) +
            " on " +
            this.accountMarketplace.accountName +
            ".",
          "Max Bid updated",
        );
      },
      (error) => this.toasterService.error(error, "Max bid update error"),
    );
  }

  update() {
    this.updateMaxBid();
    this.bsModalRef.hide();
  }
}
