import { Component, Input } from "@angular/core";
import { StrategyEx, StrategyTacticEx, SupportedAudienceMatchType, TacticType } from "@front/m19-services";

@Component({
  selector: "app-sd-card",
  templateUrl: "./sd-card.component.html",
  styleUrls: ["./sd-card.component.scss"],
})
export class SdCardComponent {
  _strategy: StrategyEx;
  _productTactics: StrategyTacticEx[];
  readonly supportedAudienceMatchType = SupportedAudienceMatchType;

  @Input() accountMarketplace;

  @Input()
  set strategy(strategy) {
    this._strategy = strategy;
    this._productTactics = strategy.tactics.filter((tactic) => tactic.tacticType === TacticType.LEGACY);
  }

  @Input() isReadOnly = false;
}
