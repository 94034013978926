import { Injectable } from '@angular/core';
import { Marketplace, Strategy, StrategyApi, TacosStrategyGroup, TacosStrategyGroupApi } from './api-client';
import { AccountSelectionService } from './accountSelection.service';
import { BehaviorSubject, combineLatest, map, Observable, switchMap } from 'rxjs';
import { TacosStrategyEx } from './models/TacosStrategyEx';

@Injectable({
  providedIn: 'root',
})
export class TacosStrategyService {
  private readonly tacosStrategyIndex: Map<number, TacosStrategyEx> = new Map();
  private readonly tacosStrategyIndexSubject: BehaviorSubject<Map<number, TacosStrategyEx>> = new BehaviorSubject(
    new Map(),
  );
  public readonly tacosStrategyIndex$: Observable<Map<number, TacosStrategyEx>> =
    this.tacosStrategyIndexSubject.asObservable();

  constructor(
    accountSelectionService: AccountSelectionService,
    private tacosStrategyGroupApi: TacosStrategyGroupApi,
    private strategyApi: StrategyApi,
  ) {
    accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(switchMap((x) => this.load(x.accountId, x.marketplace)))
      .subscribe();
  }

  load(accountId: string, marketplace: Marketplace): Observable<Map<number, TacosStrategyEx>> {
    return combineLatest([
      this.tacosStrategyGroupApi.listTacosStrategyGroup({ accountId: accountId, marketplace: marketplace }),
      this.strategyApi.listStrategies({ accountId: accountId, marketplace: marketplace }),
    ]).pipe(map(this.reload));
  }

  private reload = ([tacosStrategyGroups, strategies]: [TacosStrategyGroup[], Strategy[]]) => {
    const strategyIds = new Set<number>();
    for (const tacosStrategy of tacosStrategyGroups) {
      if (tacosStrategy.spStrategyId) {
        strategyIds.add(tacosStrategy.spStrategyId);
      }
      if (tacosStrategy.sbStrategyId) {
        strategyIds.add(tacosStrategy.sbStrategyId);
      }
      if (tacosStrategy.sdStrategyId) {
        strategyIds.add(tacosStrategy.sdStrategyId);
      }
    }

    const strategyIndex = new Map<number, Strategy>();

    for (const strategy of strategies.filter((x) => x.strategyId && strategyIds.has(x.strategyId))) {
      strategyIndex.set(strategy.strategyId!, strategy);
    }

    this.tacosStrategyIndex.clear();
    for (const tacosStrategy of tacosStrategyGroups) {
      const t = tacosStrategy as TacosStrategyEx;
      t.spStrategy = strategyIndex.get(t.spStrategyId!)!;
      t.sbStrategy = t.sbStrategyId ? strategyIndex.get(t.sbStrategyId) : undefined;
      t.sdStrategy = t.sdStrategyId ? strategyIndex.get(t.sdStrategyId) : undefined;
      this.tacosStrategyIndex.set(t.tacosStrategyGroupId!, t);
    }
    this.tacosStrategyIndexSubject.next(this.tacosStrategyIndex);
    return this.tacosStrategyIndex;
  };
}
