import { Component, Input } from "@angular/core";
import { StrategyEx } from "@front/m19-services";

@Component({
  selector: "app-sp-card",
  templateUrl: "./sp-card.component.html",
  styleUrls: ["./sp-card.component.scss"],
})
export class SPCardComponent {
  @Input() strategy: StrategyEx;

  @Input() accountMarketplace;

  @Input() isReadOnly = false;
}
