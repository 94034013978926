import {
  AccountType,
  AD_SALES,
  addAdStats,
  AdStatsWithStrategyHistory,
  combineProducts,
  COST,
  CurrencyStat,
  InventoryStats,
  Metric,
  MetricCategory,
  MetricRegistry,
  MetricType,
  OrderStats,
  percent,
  ProductEx,
  RatioMetric,
  RegisteredMetric,
  SummableMetric,
  sumOrderStat,
  SupportedAccountType,
} from "@front/m19-services";
import { AdStatsEx } from "@front/m19-services";

import { PALETTE, printCurrency, StrategyStats, SumMetric } from "./Metric";

export const TARGET_ACOS = new (class extends RegisteredMetric<AdStatsWithStrategyHistory> {
  public value(d) {
    return d?.acosTarget;
  }

  public valueForCsv(d: AdStatsWithStrategyHistory): string {
    const value = this.value(d);
    if (isFinite(value)) {
      return value.toFixed(2);
    }
    return "-";
  }

  public format(d, locale) {
    const data = typeof d === "number" ? d : d?.acosTarget === null ? undefined : d?.acosTarget;
    return percent(data, locale, "1.0-0");
  }

  public formatSmall(d: number | AdStatsWithStrategyHistory, locale?: string) {
    return this.format(d, locale);
  }

  public compare(d1, d2) {
    return d2.acosTarget - d1.acosTarget;
  }

  public set(d, val) {
    // do nothing
  }
})({
  id: "TARGET_ACOS",
  title: "Target ACOS",
  titleSmall: "Target ACOS",
  category: MetricCategory.STRATEGY_CONFIG,
  type: MetricType.RATIO,
  color: "rgba(255, 150, 150, 1)",
  graphTension: 0,
  graphBorderDash: [10, 5],
  tooltip: "Target Advertising Cost of Sales",
});

export const MIN_DAILY_SPEND = new (class extends RegisteredMetric<AdStatsWithStrategyHistory> {
  public value(d) {
    return d?.minDailySpend;
  }

  public valueForCsv(d: StrategyStats): string {
    const value = this.value(d);
    if (isFinite(value)) {
      return value.toFixed(2);
    }
    return "-";
  }

  public format(d, locale, currency) {
    const data = typeof d === "number" ? d : d?.minDailySpend === null ? undefined : d?.minDailySpend;
    return printCurrency(data, locale, currency, "1.0-2");
  }

  public formatSmall(d: number | StrategyStats, locale?: string, currency?: string) {
    return this.format(d, locale, currency);
  }

  public compare(d1, d2) {
    return d2.minDailySpend - d1.minDailySpend;
  }

  public set(d, val) {
    // do nothing
  }
})({
  id: "MIN_DAILY_SPEND",
  title: "Min Daily Spend",
  titleSmall: "Min Daily Spend",
  category: MetricCategory.STRATEGY_CONFIG,
  type: MetricType.SUMMABLE,
  color: "rgba(0, 91, 141, 1)",
  graphTension: 0,
  graphBorderDash: [10, 5],
  tickDisplay: false,
  tooltip: "Minimum daily spend",
});

export const DAILY_BUDGET = new (class extends RegisteredMetric<AdStatsWithStrategyHistory> {
  public value(d) {
    return d?.dailyBudget;
  }

  public valueForCsv(d: AdStatsWithStrategyHistory): string {
    const value = this.value(d);
    if (isFinite(value)) {
      return value.toFixed(2);
    }
    return "-";
  }

  public format(d, locale, currency) {
    const data = typeof d === "number" ? d : d?.dailyBudget;
    return printCurrency(data, locale, currency, "1.0-2");
  }

  public formatSmall(d: number | AdStatsWithStrategyHistory, locale?: string, currency?: string) {
    return this.format(d, locale, currency);
  }

  public compare(d1, d2) {
    return d2.dailyBudget - d1.dailyBudget;
  }

  public set(d, val) {
    // do nothing
  }
})({
  id: "DAILY_BUDGET",
  title: "Daily Budget",
  titleSmall: "Daily Budget",
  category: MetricCategory.STRATEGY_CONFIG,
  type: MetricType.SUMMABLE,
  color: "rgba(195, 97, 99, 1)",
  graphTension: 0,
  graphBorderDash: [10, 5],
  tickDisplay: false,
  tooltip: "Daily budget",
});

export const MONTHLY_BUDGET = new (class extends RegisteredMetric<AdStatsWithStrategyHistory> {
  public value(d) {
    return d?.monthlyBudget ? d?.computedDailyBudget : undefined;
  }

  public valueForCsv(d: AdStatsWithStrategyHistory): string {
    const value = this.value(d);
    if (isFinite(value)) {
      return value.toFixed(2);
    }
    return "-";
  }

  public format(d, locale, currency) {
    const data =
      typeof d === "number" ? d : d?.monthlyBudget && d?.computedDailyBudget ? d.computedDailyBudget : undefined;
    return printCurrency(data, locale, currency, "1.0-2");
  }

  public formatSmall(d: number | AdStatsWithStrategyHistory, locale?: string, currency?: string) {
    return this.format(d, locale, currency);
  }

  public compare(d1, d2) {
    return d2.computedDailyBudget - d1.computedDailyBudget;
  }

  public set(d, val) {
    // do nothing
  }
})({
  id: "MONTHLY_BUDGET",
  title: "Monthly Budget (per day)",
  titleSmall: "Monthly Budget (per day)",
  category: MetricCategory.STRATEGY_CONFIG,
  type: MetricType.SUMMABLE,
  color: "rgba(23, 190, 187, 1)",
  graphTension: 0,
  graphBorderDash: [10, 5],
  tickDisplay: false,
  tooltip: "Monthly budget",
});

export const TOTAL_SALES: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "TOTAL_SALES",
  field: "allSales",
  title: "Sales",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[8],
  supportedAccountType: SupportedAccountType.VENDOR_AND_SELLER,
  currency: true,
  requireSellingPartnerAccess: true,
  tooltip: "Sum of organic and ad sales",
});

export const TOTAL_ORDERS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "TOTAL_ORDERS",
  field: "allOrderedUnits",
  title: "Units Sold",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[10],
  supportedAccountType: SupportedAccountType.VENDOR_AND_SELLER,
  requireSellingPartnerAccess: true,
  tooltip: "Number of items sold through organic and ad sales",
});
export const VENDOR_SHIPPED_COGS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "VENDOR_SHIPPED_COGS",
  field: "vendorShippedCogs",
  title: "Shipped Cogs",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[16],
  supportedAccountType: SupportedAccountType.VENDOR,
  currency: true,
  requireSellingPartnerAccess: true,
  tooltip: "Sum of shipped cogs",
  mustApplyEvolutionStyle: false,
});

export const VENDOR_CUSTOMER_RETURNS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "VENDOR_CUSTOMER_RETURNS",
  field: "vendorCustomerReturns",
  title: "Customer Returns",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[18],
  supportedAccountType: SupportedAccountType.VENDOR,
  currency: true,
  requireSellingPartnerAccess: true,
  tooltip: "Sum of customer returns",
  higherIsBetter: false,
});
export const ORDERS_7D: Metric<AdStatsEx> = new SummableMetric<InventoryStats>({
  id: "ORDERS_7D",
  field: "orders7d",
  title: "Orders Last 7 Days",
  category: MetricCategory.INVENTORY,
  color: PALETTE[0],
  supportedAccountType: SupportedAccountType.SELLER,
  titleSmall: "Orders 7d",
  tooltip: "Orders over the last 7 days",
  requireSellingPartnerAccess: true,
});
export const ORDERS_30D: Metric<AdStatsEx> = new SummableMetric<InventoryStats>({
  id: "ORDERS_30D",
  field: "orders30d",
  title: "Orders Last 30 Days",
  category: MetricCategory.INVENTORY,
  color: PALETTE[1],
  supportedAccountType: SupportedAccountType.SELLER,
  titleSmall: "Orders 30d",
  tooltip: "Orders over the last 30 days",
  requireSellingPartnerAccess: true,
});
export const FULFILLABLE_STOCK: Metric<InventoryStats> = new SummableMetric<InventoryStats>({
  id: "FULFILLABLE_STOCK",
  field: "fulfillableQuantity",
  title: "FBA Available Inventory",
  category: MetricCategory.INVENTORY,
  color: PALETTE[0],
  supportedAccountType: SupportedAccountType.SELLER,
  titleSmall: "Avail.",
  tooltip: "Available inventory fulfilled by Amazon",
  requireSellingPartnerAccess: true,
  mustApplyEvolutionStyle: false,
});
export const FULFILLABLE_STOCK_VALUE: Metric<InventoryStats> = new SummableMetric<InventoryStats>({
  id: "FULFILLABLE_STOCK_VALUE",
  field: "fulfillableQuantityValue",
  title: "FBA Available Inventory Value",
  category: MetricCategory.INVENTORY,
  color: PALETTE[0],
  currency: true,
  supportedAccountType: SupportedAccountType.SELLER,
  titleSmall: "Avail.",
  tooltip: "Available inventory value fulfilled by Amazon",
  requireSellingPartnerAccess: true,
  mustApplyEvolutionStyle: false,
});

export const FBM_STOCK: Metric<InventoryStats> = new SummableMetric<InventoryStats>({
  id: "FBM_STOCK",
  field: "fbmStock",
  title: "Available Inventory Via Merchant",
  category: MetricCategory.INVENTORY,
  color: PALETTE[0],
  supportedAccountType: SupportedAccountType.SELLER,
  titleSmall: "Avail. FBM",
  tooltip: "Stock available on Amazon and ready to be sold by merchant",
  requireSellingPartnerAccess: true,
  mustApplyEvolutionStyle: false,
});

export const FBM_STOCK_VALUE: Metric<InventoryStats> = new SummableMetric<InventoryStats>({
  id: "FBM_STOCK_VALUE",
  field: "fbmStockValue",
  title: "Available Inventory Value Via Merchant",
  category: MetricCategory.INVENTORY,
  color: PALETTE[0],
  currency: true,
  supportedAccountType: SupportedAccountType.SELLER,
  titleSmall: "Avail. FBM",
  tooltip: "Stock value available on Amazon and ready to be sold by merchant",
  requireSellingPartnerAccess: true,
  mustApplyEvolutionStyle: false,
});

export const INBOUND_STOCK: Metric<InventoryStats> = new SummableMetric<InventoryStats>({
  id: "INBOUND_STOCK",
  field: "inboundQuantity",
  title: "Inbound Stock",
  category: MetricCategory.INVENTORY,
  color: PALETTE[1],
  supportedAccountType: SupportedAccountType.SELLER,
  titleSmall: "In.",
  tooltip: "Inventory shipped but it but has not yet been received",
  requireSellingPartnerAccess: true,
  mustApplyEvolutionStyle: false,
});
export const INBOUND_STOCK_VALUE: Metric<InventoryStats> = new SummableMetric<InventoryStats>({
  id: "INBOUND_STOCK_VALUE",
  field: "inboundQuantityValue",
  title: "Inbound Stock Value",
  category: MetricCategory.INVENTORY,
  color: PALETTE[1],
  currency: true,
  supportedAccountType: SupportedAccountType.SELLER,
  titleSmall: "In.",
  tooltip: "Value of inventory shipped but not yet received",
  requireSellingPartnerAccess: true,
  mustApplyEvolutionStyle: false,
});
export const RESERVED_STOCK: Metric<InventoryStats> = new SummableMetric<InventoryStats>({
  id: "RESERVED_STOCK",
  field: "reservedQuantity",
  title: "Reserved Stock",
  category: MetricCategory.INVENTORY,
  color: PALETTE[2],
  supportedAccountType: SupportedAccountType.SELLER,
  titleSmall: "Res.",
  tooltip: "Inventory already sold but hasn't been shipped to the customer yet",
  requireSellingPartnerAccess: true,
  mustApplyEvolutionStyle: false,
});
export const RESERVED_STOCK_VALUE: Metric<InventoryStats> = new SummableMetric<InventoryStats>({
  id: "RESERVED_STOCK_VALUE",
  field: "reservedQuantityValue",
  title: "Reserved Stock Value",
  category: MetricCategory.INVENTORY,
  color: PALETTE[2],
  currency: true,
  supportedAccountType: SupportedAccountType.SELLER,
  titleSmall: "Res.",
  tooltip: "Value of inventory already sold but hasn't been shipped to the customer yet",
  requireSellingPartnerAccess: true,
  mustApplyEvolutionStyle: false,
});
export const UNFULFILLABLE_STOCK: Metric<InventoryStats> = new SummableMetric<InventoryStats>({
  id: "UNFULLFILLABLE_STOCK",
  field: "unsellableQuantity",
  title: "Unfulfillable Stock",
  category: MetricCategory.INVENTORY,
  color: PALETTE[3],
  supportedAccountType: SupportedAccountType.SELLER,
  titleSmall: "Unful.",
  tooltip: "Damaged or expired items that cannot be sold",
  requireSellingPartnerAccess: true,
  mustApplyEvolutionStyle: false,
});
export const UNFULFILLABLE_STOCK_VALUE: Metric<InventoryStats> = new SummableMetric<InventoryStats>({
  id: "UNFULFILLABLE_STOCK_VALUE",
  field: "unsellableQuantityValue",
  title: "Unfulfillable Stock Value",
  category: MetricCategory.INVENTORY,
  color: PALETTE[3],
  currency: true,
  supportedAccountType: SupportedAccountType.SELLER,
  titleSmall: "Unful.",
  tooltip: "Value of damaged or expired items that cannot be sold",
  requireSellingPartnerAccess: true,
  mustApplyEvolutionStyle: false,
});

export const NET_PPM: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "NET_PPM",
  field: "netPureProductMargin",
  title: "Net PPM",
  category: MetricCategory.HIDDEN,
  color: PALETTE[10],
  supportedAccountType: SupportedAccountType.VENDOR,
  tooltip: "Net Pure Product Margin",
});
export const NET_PURE_PRODUCT_MARGIN: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: "NET_PURE_PRODUCT_MARGIN",
  numerator: NET_PPM,
  denominator: undefined,
  title: "Net PPM",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[10],
  graphTension: 1,
  stepped: true,
  keepLastValue: true,
  supportedAccountType: SupportedAccountType.VENDOR,
  requireSellingPartnerAccess: true,
  tooltip: "last Net Pure Product Margin",
  isPercent: true,
  precision: "1.0-0",
});
export const VENDOR_SHIPPED_COGS_RATIO: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: "SHIPPED_COGS_RATIO",
  numerator: VENDOR_SHIPPED_COGS,
  denominator: TOTAL_SALES,
  title: "Shipped Cogs Ratio",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[3],
  isPercent: true,
  inverseColors: true,
  tooltip: "Ratio of Shipped Cogs to Shipped Sales",
  mustApplyEvolutionStyle: false,
  supportedAccountType: SupportedAccountType.VENDOR,
});

export const TACOS: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: "TACOS",
  numerator: COST,
  denominator: TOTAL_SALES,
  title: "TACOS",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[9],
  isPercent: true,
  tooltip: "Spending as a percentage of total sales",
  requireSellingPartnerAccess: true,
  higherIsBetter: false,
  mustApplyEvolutionStyle: false,
});

export const SPONSORED_SALES_SHARE: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: "SPONSORED_SALES_SHARE",
  numerator: AD_SALES,
  denominator: TOTAL_SALES,
  title: "Ad Sales Share",
  category: MetricCategory.AD_STATS,
  color: PALETTE[12],
  isPercent: true,
  precision: "1.0-2",
  titleSmall: "Sp. Sales %",
  tooltip: "Percentage of sales generated through advertising",
  requireSellingPartnerAccess: true,
});

// Traffic metrics
export const WEB_SESSIONS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "WEB_SESSIONS",
  field: "browserSessions",
  title: "Web Sessions",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[13],
  titleSmall: "Web Sess.",
  requireSellingPartnerAccess: true,
  supportedAccountType: SupportedAccountType.SELLER,
  tooltip: "Number of unique visits to your Amazon page by Web users within a 24-hour period",
});
export const MOBILE_SESSIONS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "MOBILE_SESSIONS",
  field: "mobileAppSessions",
  title: "Mobile Sessions",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[14],
  titleSmall: "Mobile Sess.",
  requireSellingPartnerAccess: true,
  supportedAccountType: SupportedAccountType.SELLER,
  tooltip: "Number of unique visits to your Amazon page by mobile users within a 24-hour period",
});

export const WEB_PAGE_VIEWS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "WEB_PAGE_VIEWS",
  field: "browserPageViews",
  title: "Web Page Views",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[15],
  titleSmall: "Web pv.",
  requireSellingPartnerAccess: true,
  supportedAccountType: SupportedAccountType.SELLER,
  tooltip: "Number of times a product detail page is viewed by Web users",
});
export const MOBILE_PAGE_VIEWS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "MOBILE_PAGE_VIEWS",
  field: "mobileAppPageViews",
  title: "Mobile Page Views",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[16],
  titleSmall: "Mobile pv.",
  requireSellingPartnerAccess: true,
  supportedAccountType: SupportedAccountType.SELLER,
  tooltip: "Number of times a product detail page is viewed by mobile users",
});
export const BUY_BOX_PAGE_VIEW: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "BUY_BOX_PAGE_VIEW",
  field: "buyBoxPageViews",
  title: "Buy Box Page Views",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[17],
  titleSmall: "Bbox pv.",
  requireSellingPartnerAccess: true,
  supportedAccountType: SupportedAccountType.SELLER,
  tooltip: "Number of times a product detail page is viewed with the Buy Box",
});

export const SESSIONS: Metric<AdStatsEx> = new SumMetric<AdStatsEx>({
  id: "SESSIONS",
  metric1: WEB_SESSIONS,
  metric2: MOBILE_SESSIONS,
  title: "Sessions",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[18],
  titleSmall: "Sess.",
  supportedAccountType: SupportedAccountType.SELLER,
  requireSellingPartnerAccess: true,
  tooltip: "Number of unique visits to your Amazon page by a user within a 24-hour period",
});

export const PAGE_VIEWS: Metric<AdStatsEx> = new SumMetric<AdStatsEx>({
  id: "PAGE_VIEWS",
  metric1: WEB_PAGE_VIEWS,
  metric2: MOBILE_PAGE_VIEWS,
  title: "Page Views",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[19],
  titleSmall: "pv.",
  requireSellingPartnerAccess: true,
  tooltip: "Number of times a product detail page is viewed",
  supportedAccountType: SupportedAccountType.VENDOR_AND_SELLER,
});

export const APP_RATIO: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: "APP_RATIO",
  numerator: MOBILE_SESSIONS,
  denominator: SESSIONS,
  title: "Mobile Shares",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[20],
  isPercent: true,
  precision: "1.0-0",
  titleSmall: "Mobile %",
  supportedAccountType: SupportedAccountType.SELLER,
  requireSellingPartnerAccess: true,
  tooltip: "Share of the total session from mobile",
});

export const BUY_BOX_RATE: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: "BUY_BOX_RATE",
  numerator: BUY_BOX_PAGE_VIEW,
  denominator: PAGE_VIEWS,
  title: "Buy Box Win Rate",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[21],
  isPercent: true,
  precision: "1.0-0",
  titleSmall: "Bbox %",
  supportedAccountType: SupportedAccountType.SELLER,
  requireSellingPartnerAccess: true,
  tooltip: "Percentage of time a seller's product listing wins the Buy Box",
});

export const TOTAL_CONVERSION_RATE: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: "TOTAL_CONVERSION_RATE",
  numerator: TOTAL_ORDERS,
  denominator: SESSIONS,
  title: "TCR",
  category: MetricCategory.SALES_STATS,
  color: PALETTE[22],
  isPercent: true,
  tooltip: "Total conversion rate = all units sold / sessions",
  supportedAccountType: SupportedAccountType.SELLER,
  requireSellingPartnerAccess: true,
});

export const ASINS_WITH_TOP_OF_SEARCH_RANKINGS_FIRED: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "ASINS_WITH_TOP_OF_SEARCH_RANKINGS_FIRED",
  field: "nbAsinsWithRuleFired",
  title: "ASINs with T.O.S.R. fired",
  category: MetricCategory.OTHER,
  color: "rgba(255, 150, 150, 1)",
  graphTension: 0,
  graphBorderDash: [10, 5],
  currency: false,
  tooltip: "Number of ASINs with Top of Search Rankings fired",
});

// VENDOR INVENTORY METRICS
export const VENDOR_SELLABLE_UNITS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "SELLABLE_UNITS",
  parentField: "vendorInventoryAdStats",
  field: "inventorySellableUnits",
  title: "Sellable Units",
  category: MetricCategory.INVENTORY,
  color: PALETTE[1],
  titleSmall: "Sellable Units",
  tooltip: "Number of sellable units",
  mustApplyEvolutionStyle: false,
  supportedAccountType: SupportedAccountType.VENDOR,
  nullAsZero: false,
});

export const VENDOR_SELLABLE_COST: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "SELLABLE_COST",
  parentField: "vendorInventoryAdStats",
  field: "inventorySellableCost",
  title: "Sellable Cost",
  currency: true,
  category: MetricCategory.INVENTORY,
  color: PALETTE[3],
  titleSmall: "Sellable Cost",
  tooltip: "Sum of inventory sellable cost",
  mustApplyEvolutionStyle: false,
  supportedAccountType: SupportedAccountType.VENDOR,
  nullAsZero: false,
});
export const VENDOR_UNSELLABLE_UNITS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "UNSELLABLE_UNITS",
  parentField: "vendorInventoryAdStats",
  field: "inventoryUnsellableUnits",
  title: "Unsellable Units",
  category: MetricCategory.INVENTORY,
  color: PALETTE[5],
  titleSmall: "Unsellable Units",
  tooltip: "Number of unsellable units",
  supportedAccountType: SupportedAccountType.VENDOR,
  higherIsBetter: false,
  nullAsZero: false,
});

export const VENDOR_UNSELLABLE_COST: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "UNSELLABLE_COST",
  parentField: "vendorInventoryAdStats",
  field: "inventoryUnsellableCost",
  title: "Unsellable Cost",
  currency: true,
  category: MetricCategory.INVENTORY,
  color: PALETTE[7],
  titleSmall: "Unsellable Cost",
  tooltip: "Sum of unsellable cost",
  supportedAccountType: SupportedAccountType.VENDOR,
  higherIsBetter: false,
  nullAsZero: false,
});

export const VENDOR_OPEN_PURCHASE_ORDER_UNITS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "OPEN_PURCHASE_ORDER_UNITS",
  parentField: "vendorInventoryAdStats",
  field: "inventoryOpenPurchaseOrderUnits",
  title: "Open Purchase Order Units",
  category: MetricCategory.INVENTORY,
  color: PALETTE[9],
  titleSmall: "Open Purchase Units",
  tooltip: "Number of open purchase order units",
  supportedAccountType: SupportedAccountType.VENDOR,
  mustApplyEvolutionStyle: false,
  nullAsZero: false,
});

export const VENDOR_AGED90P_SELLABLE_UNITS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "AGED90P_SELLABLE_UNITS",
  parentField: "vendorInventoryAdStats",
  field: "inventoryAged90pSellableUnits",
  title: "Aged 90 Plus Days Sellable Units",
  category: MetricCategory.INVENTORY,
  color: PALETTE[11],
  titleSmall: "Old Sellable Units",
  tooltip: "Number of aged 90 plus days sellable inventory units",
  supportedAccountType: SupportedAccountType.VENDOR,
  nullAsZero: false,
});

export const VENDOR_AGED90P_SELLABLE_COST: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "AGED90P_SELLABLE_COST",
  parentField: "vendorInventoryAdStats",
  field: "inventoryAged90pSellableCost",
  title: "Aged 90 Plus Days Sellable Cost",
  category: MetricCategory.INVENTORY,
  currency: true,
  color: PALETTE[13],
  titleSmall: "Old Sellable Cost",
  tooltip: "Number of aged 90 plus days sellable inventory cost",
  supportedAccountType: SupportedAccountType.VENDOR,
  higherIsBetter: false,
  nullAsZero: false,
});

// see https://github.com/m19-dev/main-repo/issues/6387
export const VENDOR_OUT_OF_STOCK_VIEWS: Metric<AdStatsEx> = new SummableMetric<AdStatsEx>({
  id: "VENDOR_OUT_OF_STOCK_VIEWS",
  field: "outOfStockGlanceViews",
  title: "Out of Stock Glance Views",
  category: MetricCategory.INVENTORY,
  color: PALETTE[15],
  tooltip:
    "Number of times products are shown as unavailable for purchase on the product detail page, even though they can be sourced from a 1P vendor. Factors that affect this metric include temporary sales suppression due to repeat customer complaints or safety concerns (Andon Cords), manual buyer suppressions, and lack of inventory.",
  supportedAccountType: SupportedAccountType.VENDOR,
  higherIsBetter: false,
});

// see https://github.com/m19-dev/main-repo/issues/6387
export const VENDOR_SOROOS: Metric<AdStatsEx> = new RatioMetric<AdStatsEx>({
  id: "SoROOS",
  numerator: VENDOR_OUT_OF_STOCK_VIEWS,
  denominator: PAGE_VIEWS,
  isPercent: true,
  precision: "1.2-2",
  title: "SoROOS",
  category: MetricCategory.INVENTORY,
  color: PALETTE[16],
  tooltip:
    "Sourceable Replenishment Out of Stock. This metric measures the instances when products are shown as unavailable for purchase on the product detail page, even though they can be sourced from a 1P vendor. Factors that affect SoROOS include temporary sales suppression due to repeat customer complaints or safety concerns (Andon Cords), manual buyer suppressions, and lack of inventory.",
  supportedAccountType: SupportedAccountType.VENDOR,
  higherIsBetter: false,
});

// Global order stats metrics

function buildSummableOrderMetric(
  id: string,
  field: string,
  title: string,
  color: string,
  tooltip: string,
  titleSmall?: string,
  category?: MetricCategory,
) {
  return new SummableMetric<OrderStats>({
    id,
    field,
    title,
    category: category ?? MetricCategory.ORDER_STATS,
    color,
    currency: true,
    titleSmall,
    supportedAccountType: SupportedAccountType.SELLER,
    requireSellingPartnerAccess: true,
    tooltip: tooltip,
  });
}

export const ORDER_GLOBAL_SALES: Metric<OrderStats> = buildSummableOrderMetric(
  "ORDER_GLOBAL_SALES",
  "globalSales",
  "Sales excluding tax",
  PALETTE[0],
  "Sales",
  "Sales excl. tax",
  MetricCategory.PROFIT_STATS,
);

export const ORDER_GLOBAL_TAX: Metric<OrderStats> = buildSummableOrderMetric(
  "ORDER_GLOBAL_TAX",
  "tax",
  "Tax",
  PALETTE[1],
  "Tax",
  undefined,
  MetricCategory.PROFIT_STATS,
);

export const ORDER_GLOBAL_PROMOTION: Metric<OrderStats> = buildSummableOrderMetric(
  "ORDER_GLOBAL_PROMOTION",
  "promotion",
  "Promotion",
  PALETTE[2],
  "Promotion",
  "Promo.",
  MetricCategory.PROFIT_STATS,
);

export const ORDER_GLOBAL_SHIPPING_GIFT_WRAP: Metric<OrderStats> = buildSummableOrderMetric(
  "ORDER_GLOBAL_SHIPPING_GIFT_WRAP",
  "shippingGiftWrap",
  "Shipping & Gift Wrap",
  PALETTE[10],
  "Shipping & Gift Wrap",
  undefined,
  MetricCategory.PROFIT_STATS,
);

export const ORDER_GLOBAL_FEE: Metric<OrderStats> = buildSummableOrderMetric(
  "ORDER_GLOBAL_FEE",
  "fee",
  "Fees",
  PALETTE[4],
  "Fees",
  undefined,
  MetricCategory.PROFIT_STATS,
);

export const OTHER: Metric<OrderStats> = buildSummableOrderMetric("OTHER", "other", "Other", PALETTE[4], "Other");

export const ORDER_GLOBAL_ADV: Metric<OrderStats> = buildSummableOrderMetric(
  "ORDER_GLOBAL_ADV",
  "advertising",
  "Advertising",
  PALETTE[5],
  "Amount spent on advertising on a given period",
  "Adv.",
);

export const ORDER_GLOBAL_CHARGE: Metric<OrderStats> = buildSummableOrderMetric(
  "ORDER_GLOBAL_CHARGE",
  "charge",
  "Charge",
  PALETTE[6],
  "Charge",
);

// Order metrics

// Sales
export const ORDER_SALES: Metric<OrderStats> = buildSummableOrderMetric(
  "ORDER_SALES",
  "sales",
  "Sales",
  PALETTE[0],
  "Sales",
);
export const REIMBURSEMENT: Metric<OrderStats> = buildSummableOrderMetric(
  "REIMBURSEMENT",
  "reimbursement",
  "Reimbursement",
  PALETTE[0],
  "Reimbursement",
);

export const REIMBURSEMENT_CLAWBACK: Metric<OrderStats> = buildSummableOrderMetric(
  "REIMBURSEMENT_CLAWBACK",
  "reimbursementClawback",
  "Reimbursement Clawback",
  PALETTE[0],
  "Reimbursement Clawback",
);
export const A2Z_GUARANTEE_REFUNDED: Metric<OrderStats> = new SummableMetric<OrderStats>({
  id: "A2Z_GUARANTEE_REFUNDED",
  field: "a2zGuaranteeRefunded",
  title: "A-to-Z Guarantee",
  category: MetricCategory.ORDER_STATS,
  color: PALETTE[0],
  higherIsBetter: false,
  supportedAccountType: SupportedAccountType.SELLER,
  tooltip: "Refunds issued to customers as part of the A-to-Z Guarantee",
});
export const REFUNDS: Metric<OrderStats> = new SummableMetric<OrderStats>({
  id: "REFUNDS",
  field: "refunds",
  title: "Refunds",
  category: MetricCategory.ORDER_STATS,
  color: PALETTE[0],
  higherIsBetter: false,
  supportedAccountType: SupportedAccountType.SELLER,
  tooltip: "Refunds issued to customers",
});
export const ORDER_RETURN: Metric<OrderStats> = new SummableMetric<OrderStats>({
  id: "ORDER_RETURN",
  field: "orderReturnRefunded",
  title: "Order returns",
  category: MetricCategory.ORDER_STATS,
  color: PALETTE[0],
  higherIsBetter: false,
  supportedAccountType: SupportedAccountType.SELLER,
  tooltip: "Number of returned orders",
});
export const CHARGE_BACK: Metric<OrderStats> = new SummableMetric<OrderStats>({
  id: "CHARGE_BACK",
  field: "chargebackRefunded",
  title: "Chargeback",
  category: MetricCategory.ORDER_STATS,
  color: PALETTE[0],
  supportedAccountType: SupportedAccountType.SELLER,
  tooltip: "Chargeback",
});

export const REFUND_SALES: Metric<OrderStats> = buildSummableOrderMetric(
  "REFUND_SALES",
  "refunded",
  "Refunded",
  PALETTE[0],
  "Refunded",
);
export const QUANTITY: Metric<OrderStats> = new SummableMetric<OrderStats>({
  id: "QUANTITY",
  field: "quantity",
  title: "Quantity",
  category: MetricCategory.ORDER_STATS,
  color: PALETTE[0],
  supportedAccountType: SupportedAccountType.SELLER,
  tooltip: "Number of units sold",
});
export const AMAZON_VINE: Metric<OrderStats> = new SummableMetric<OrderStats>({
  id: "AMAZON_VINE",
  field: "amazonVine",
  title: "including Vine",
  category: MetricCategory.ORDER_STATS,
  color: PALETTE[0],
  supportedAccountType: SupportedAccountType.SELLER,
  tooltip: "Amazon Vine",
});
export const SELLABLE_RETURNS: Metric<OrderStats> = new SummableMetric<OrderStats>({
  id: "SELLABLE_RETURNS",
  field: "sellableReturns",
  title: "Sellable Returns",
  category: MetricCategory.ORDER_STATS,
  color: PALETTE[0],
  higherIsBetter: false,
  supportedAccountType: SupportedAccountType.SELLER,
  tooltip: "Sellable Returns",
});
export const REPLACEMENT_REFUNDED: Metric<OrderStats> = new SummableMetric<OrderStats>({
  id: "REPLACEMENT_REFUNDED",
  field: "replacementRefunded",
  title: "Replacement Refunded",
  category: MetricCategory.ORDER_STATS,
  color: PALETTE[0],
  mustApplyEvolutionStyle: false,
  supportedAccountType: SupportedAccountType.SELLER,
  tooltip: "Replacement Refunded",
});

export const ORDER_SALES_DETAILS: Metric<OrderStats>[] = [
  ORDER_SALES,
  QUANTITY,
  AMAZON_VINE,
  REFUND_SALES,
  ORDER_RETURN,
  A2Z_GUARANTEE_REFUNDED,
  CHARGE_BACK,
  SELLABLE_RETURNS,
  REIMBURSEMENT,
  REPLACEMENT_REFUNDED,
];

export const ORDER_SALES_DETAILS_SUMMARY: Metric<OrderStats>[] = [
  ORDER_SALES,
  QUANTITY,
  AMAZON_VINE,
  REFUND_SALES,
  REFUNDS,
  SELLABLE_RETURNS,
  REIMBURSEMENT,
  REPLACEMENT_REFUNDED,
];

export const ORDER_SALES_OVERVIEW_DETAILS: Metric<OrderStats>[] = [
  ORDER_SALES,
  REFUND_SALES,
  REIMBURSEMENT,
  REIMBURSEMENT_CLAWBACK,
];

// Tax
export const BASE_TAX: Metric<OrderStats> = buildSummableOrderMetric(
  "BASE_TAX",
  "baseTax",
  "Base",
  PALETTE[1],
  "Base tax",
);
export const SHIPPING_TAX: Metric<OrderStats> = buildSummableOrderMetric(
  "SHIPPING_TAX",
  "shippingTax",
  "Shipping",
  PALETTE[1],
  "Shipping tax",
);
export const GIFT_WRAP_TAX: Metric<OrderStats> = buildSummableOrderMetric(
  "GIFT_WRAP_TAX",
  "giftWrapTax",
  "Gift Wrap",
  PALETTE[1],
  "Gift wrap tax",
);
export const OTHER_TAX: Metric<OrderStats> = buildSummableOrderMetric(
  "OTHER_TAX",
  "otherTax",
  "Other",
  PALETTE[1],
  "Other Tax",
);

export const TAX_DISCOUNT: Metric<OrderStats> = buildSummableOrderMetric(
  "TAX_DISCOUNT",
  "taxDiscount",
  "Tax Discount",
  PALETTE[1],
  "Tax Discount",
);

export const ORDER_TAX_DETAILS: Metric<OrderStats>[] = [BASE_TAX, SHIPPING_TAX, GIFT_WRAP_TAX, OTHER_TAX, TAX_DISCOUNT];

// Promotion
export const BASE_PROMO: Metric<OrderStats> = buildSummableOrderMetric(
  "BASE_PROMO",
  "basePromotion",
  "Cost",
  PALETTE[2],
  "Base promotion",
);
export const GIFT_WRAP_PROMO: Metric<OrderStats> = buildSummableOrderMetric(
  "GIFT_WRAP_PROMO",
  "giftWrapPromotion",
  "Gift Wrap",
  PALETTE[2],
  "Cost of wrapping in decorative paper or packaging",
  undefined,
);
export const SHIPPING_PROMO: Metric<OrderStats> = buildSummableOrderMetric(
  "SHIPPING_PROMO",
  "shippingPromotion",
  "Shipping",
  PALETTE[2],
  undefined,
  "Shipping cost",
);
export const OTHER_PROMO: Metric<OrderStats> = buildSummableOrderMetric(
  "OTHER_PROMO",
  "otherPromotion",
  "Other",
  PALETTE[2],
  "Other promotion",
);

export const ORDER_PROMO_DETAILS: Metric<OrderStats>[] = [BASE_PROMO, GIFT_WRAP_PROMO, SHIPPING_PROMO, OTHER_PROMO];

// Shipping & gift wrap
export const SHIPPING: Metric<OrderStats> = buildSummableOrderMetric(
  "SHIPPING",
  "shipping",
  "Shipping",
  PALETTE[3],
  "Shipping",
);
export const SHIPPING_HB: Metric<OrderStats> = buildSummableOrderMetric(
  "SHIPPING_HB",
  "shippingHB",
  "Shipping HB",
  PALETTE[3],
  "Shipping HB",
);
export const SHIPPING_CHARGE_BACK: Metric<OrderStats> = buildSummableOrderMetric(
  "SHIPPING_CHARGE_BACK",
  "shippingChargeBack",
  "Shipping Charge Back",
  PALETTE[3],
  "Shipping Charge Back",
);
export const GIFT_WRAP: Metric<OrderStats> = buildSummableOrderMetric(
  "GIFT_WRAP",
  "giftWrap",
  "Gift Wrap",
  PALETTE[3],
  "Gift Wrap",
);
export const GIFT_WRAP_CHARGE_BACK: Metric<OrderStats> = buildSummableOrderMetric(
  "GIFT_WRAP_CHARGE_BACK",
  "giftWrapChargeBack",
  "Gift Wrap Charge Back",
  PALETTE[3],
  "Gift Wrap Charge Back",
);

export const ORDER_SHIPPING_GIFT_WRAP_DETAILS: Metric<OrderStats>[] = [
  SHIPPING,
  SHIPPING_HB,
  SHIPPING_CHARGE_BACK,
  GIFT_WRAP,
  GIFT_WRAP_CHARGE_BACK,
];

// Fee
export const REFERRAL_FEE: Metric<OrderStats> = buildSummableOrderMetric(
  "REFERRAL_FEE",
  "referralFee",
  "Referral",
  PALETTE[4],
  undefined,
  "A fee Amazon charges for each item sold on their platform",
);

export const FBA_FEE: Metric<OrderStats> = buildSummableOrderMetric(
  "FBA_FEE",
  "fbaFee",
  "FBA",
  PALETTE[4],
  undefined,
  "Amazon's fulfillment services (storage, picking, packing, shipping, and customer service)",
);
export const GIFT_WRAP_FEE: Metric<OrderStats> = buildSummableOrderMetric(
  "GIFT_WRAP_FEE",
  "giftWrapFee",
  "Gift Wrap",
  PALETTE[4],
  "Gift Wrap",
);
export const SHIPPING_AND_GIFT_WRAP_FEE: Metric<OrderStats> = buildSummableOrderMetric(
  "SHIPPING_AND_GIFT_WRAP_FEE",
  "shippingGiftWrap",
  "Shipping & Gift Wrap",
  PALETTE[4],
  "Shipping & Gift Wrap",
);
export const OTHER_FEE: Metric<OrderStats> = buildSummableOrderMetric(
  "OTHER_FEE",
  "otherFee",
  "Other",
  PALETTE[4],
  "Other Fees",
);
export const FBA_STORAGE_FEE: Metric<OrderStats> = buildSummableOrderMetric(
  "FBA_STORAGE_FEE",
  "fbaStorageFee",
  "Storage",
  PALETTE[4],
  undefined,
  "The costs for storing inventory on Amazon warehouses",
);
export const FEE_ADJUSTMENT: Metric<OrderStats> = buildSummableOrderMetric(
  "FEE_ADJUSTMENT",
  "feeAdjustment",
  "Adjustment",
  PALETTE[4],
  "Adjustment",
);
export const RETURN_FEE: Metric<OrderStats> = buildSummableOrderMetric(
  "RETURN_FEE",
  "returnFees",
  "Return Fees",
  PALETTE[4],
  "Return Fees",
);
export const LIQUIDATIONS_FEE: Metric<OrderStats> = buildSummableOrderMetric(
  "LIQUIDATIONS_FEE",
  "liquidations",
  "Liquidations",
  PALETTE[4],
  "Liquidations",
);
export const INTERNATIONAL_FREIGHT_FEE: Metric<OrderStats> = buildSummableOrderMetric(
  "INTERNATIONAL_FREIGHT_FEE",
  "internationalFreight",
  "International Freight",
  PALETTE[4],
  "International Freight",
);

export const CHARGE_EXPORT: Metric<OrderStats> = buildSummableOrderMetric(
  "CHARGE_EXPORT",
  "chargeExport",
  "Export",
  PALETTE[6],
  "Export",
);

export const ORDER_FEE_DETAILS: Metric<OrderStats>[] = [
  REFERRAL_FEE,
  FBA_FEE,
  FBA_STORAGE_FEE,
  GIFT_WRAP_FEE,
  FEE_ADJUSTMENT,
  RETURN_FEE,
  CHARGE_EXPORT,
  INTERNATIONAL_FREIGHT_FEE,
  LIQUIDATIONS_FEE,
  OTHER_FEE,
];

// Advertising
export const SP_ADVERTISING: Metric<OrderStats> = buildSummableOrderMetric(
  "SP_ADVERTISING",
  "spAdvertising",
  "SP",
  PALETTE[5],
  "Sponsored Products Advertising",
);
export const SB_ADVERTISING: Metric<OrderStats> = buildSummableOrderMetric(
  "SB_ADVERTISING",
  "sbAdvertising",
  "SB",
  PALETTE[5],
  "Sponsored Brand Advertising",
);
export const SD_ADVERTISING: Metric<OrderStats> = buildSummableOrderMetric(
  "SD_ADVERTISING",
  "sdAdvertising",
  "SD",
  PALETTE[5],
  "Sponsored Display Advertising",
);

export const ORDER_ADVERTISING_DETAILS: Metric<OrderStats>[] = [SP_ADVERTISING, SB_ADVERTISING, SD_ADVERTISING];

// Profit
export const COST_OF_GOODS: Metric<OrderStats> = buildSummableOrderMetric(
  "COST_OF_GOODS",
  "costOfGoods",
  "Cost Of Goods",
  PALETTE[8],
  "Total cost of producing a product",
  undefined,
  MetricCategory.PROFIT_STATS,
);

// Profit
export const PROFIT: Metric<OrderStats> = buildSummableOrderMetric(
  "PROFIT",
  "profit",
  "Profit",
  "#4287f5",
  "Net profit after deducting COGS, taxes, and other expenses (excluding global fees like long-term storage/international freight)",
  undefined,
  MetricCategory.PROFIT_STATS,
);

export const PROFIT_WITH_GLOBAL_FEES: Metric<OrderStats> = buildSummableOrderMetric(
  "PROFIT_WITH_GLOBAL_FEES",
  "profit",
  "Profit",
  "#4287f5",
  "Net profit after deducting COGS, taxes, and other expenses (including global fees like long-term storage/international freight)",
  undefined,
  MetricCategory.GLOBAL_PROFIT_STATS,
);

// Margin
export const ORDER_GLOBAL_MARGIN: Metric<OrderStats> = new RatioMetric<OrderStats>({
  id: "ORDER_GLOBAL_MARGIN",
  numerator: PROFIT,
  denominator: ORDER_GLOBAL_SALES,
  title: "Margin",
  isPercent: true,
  category: MetricCategory.PROFIT_STATS,
  color: "#4287f5",
  supportedAccountType: SupportedAccountType.SELLER,
  tooltip: "Percentage of remaining as profit after all expenses",
});

// Margin
export const MARGIN: Metric<OrderStats> = new RatioMetric<OrderStats>({
  id: "MARGIN",
  numerator: PROFIT,
  denominator: ORDER_SALES,
  title: "Margin",
  isPercent: true,
  category: MetricCategory.ORDER_STATS,
  color: "#4287f5",
  supportedAccountType: SupportedAccountType.SELLER,
  tooltip: "Percentage of remaining as profit after all expenses",
});

export const ROI: Metric<OrderStats> = new RatioMetric<OrderStats>({
  id: "ROI",
  numerator: PROFIT,
  denominator: COST_OF_GOODS,
  title: "ROI",
  isPercent: true,
  category: MetricCategory.ORDER_STATS,
  color: "#4287f5",
  coeff: -1,
  supportedAccountType: SupportedAccountType.SELLER,
  tooltip: "Return on investment",
});

export const PROFIT_DETAILS: Metric<OrderStats>[] = [PROFIT, ROI];

export function getMetric(id: string): Metric<CurrencyStat> {
  return MetricRegistry.get(id);
}

export function isMetricSupportedForAccountType(metric: Metric<AdStatsEx>, accountType: AccountType) {
  switch (metric.supportedAccountType) {
    case SupportedAccountType.VENDOR:
      return accountType == AccountType.VENDOR;
    case SupportedAccountType.SELLER:
      return accountType == AccountType.SELLER;
    case SupportedAccountType.VENDOR_AND_SELLER:
      return true;
  }
}

export function getOrderMetricDetails(metric: Metric<OrderStats>, fullDetail: boolean): Metric<OrderStats>[] {
  switch (metric) {
    case ORDER_GLOBAL_SALES:
      return fullDetail ? ORDER_SALES_DETAILS : ORDER_SALES_DETAILS_SUMMARY;
    case ORDER_GLOBAL_TAX:
      return ORDER_TAX_DETAILS;
    case ORDER_GLOBAL_PROMOTION:
      return ORDER_PROMO_DETAILS;
    case ORDER_GLOBAL_SHIPPING_GIFT_WRAP:
      return ORDER_SHIPPING_GIFT_WRAP_DETAILS;
    case ORDER_GLOBAL_FEE:
      return ORDER_FEE_DETAILS;
    case ORDER_GLOBAL_ADV:
      return ORDER_ADVERTISING_DETAILS;
    case PROFIT:
      return PROFIT_DETAILS;
    case COST_OF_GOODS:
      return [COST_OF_GOODS];
    case ORDER_GLOBAL_MARGIN:
      return [MARGIN];
  }
}

export function getOrderMetricOverviewDetails(metric: Metric<OrderStats>): Metric<OrderStats>[] {
  switch (metric) {
    case ORDER_GLOBAL_SALES:
      return ORDER_SALES_OVERVIEW_DETAILS;
    case ORDER_GLOBAL_FEE:
      return ORDER_FEE_DETAILS;
    case ORDER_GLOBAL_ADV:
      return ORDER_ADVERTISING_DETAILS;
    default:
      return [];
  }
}

export function groupByCategory<T>(metrics: Metric<T>[]): Map<MetricCategory, Metric<T>[]> {
  const groupedPageMetrics = new Map();
  for (const m of metrics) {
    if (!groupedPageMetrics.has(m.category)) {
      groupedPageMetrics.set(m.category, [m]);
    } else {
      groupedPageMetrics.get(m.category).push(m);
    }
  }
  for (const k of groupedPageMetrics.keys()) {
    groupedPageMetrics.get(k).sort((a, b) => (a.title > b.title ? 1 : a.title === b.title ? 0 : -1));
  }
  return groupedPageMetrics;
}

export function getAggregationFunction(metricTypes: Set<MetricCategory>) {
  let f: AggFunctionCombinator<
    | AdStatsEx
    | OrderStats
    | ProductEx
    | (AdStatsEx & OrderStats)
    | (AdStatsEx & OrderStats & ProductEx)
    | (OrderStats & ProductEx)
  > = AggFunctionCombinator.empty;
  let aggAdStats = false;
  for (const metricType of metricTypes.values()) {
    if (metricType == MetricCategory.PRODUCT) {
      f = f.with<ProductEx>((p1, p2) => combineProducts(p1, p2));
    }
    if (metricType == MetricCategory.AD_STATS || metricType == MetricCategory.SALES_STATS) {
      if (!aggAdStats) {
        f = f.with(addAdStats);
        aggAdStats = true;
      }
    }
    if (metricType == MetricCategory.PROFIT_STATS) {
      f = f.with(sumOrderStat);
    }
  }
  return f.agg;
}

// helper to combine several aggregator
class AggFunctionCombinator<T extends object> {
  constructor(public agg: (x: T, y: T) => T) {}

  with<U extends object>(f: (x: U, y: U) => U): AggFunctionCombinator<T & U> {
    return new AggFunctionCombinator((x: T & U, y: T & U) => ({ ...this.agg(x, y), ...f(x, y) }));
  }

  static empty = new AggFunctionCombinator(() => ({}));
}
