import { Component, Input, OnInit, ViewChild } from "@angular/core";
import {
  AccountMarketplace,
  AccountSelectionService,
  AccountType,
  AuthService,
  Currency,
  getBasicGridOptions,
  MetricCategory,
  RatioMetric,
  SummableMetric,
  SupportedAccountType,
  UserSelectionService,
} from "@front/m19-services";
import { getMetricsColDef, STATUS_BAR } from "@m19-board/grid-config/grid-config";
import { PALETTE } from "@m19-board/models/Metric";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { map, Observable } from "rxjs";
import { ProfitDetail } from "../../product360/product360.service";
import { AgGridAngular } from "@ag-grid-community/angular";
import { ColDef, GetGroupRowAggParams, GridOptions } from "@ag-grid-community/core";

export const PROFIT_DETAIL_AMOUNT = new SummableMetric<ProfitDetail>({
  id: "PROFIT_DETAIL_AMOUNT",
  field: "value",
  title: "Amount",
  category: MetricCategory.ORDER_STATS,
  color: PALETTE[0],
  currency: true,
  supportedAccountType: SupportedAccountType.SELLER,
  tooltip: "Profit",
});

export const PROFIT_DETAIL_TOTAL_REVENUE = new SummableMetric<ProfitDetail>({
  id: "PROFIT_DETAIL_TOTAL_REVENUE",
  field: "totalRevenue",
  title: "Amount",
  category: MetricCategory.ORDER_STATS,
  color: PALETTE[1],
  currency: true,
  supportedAccountType: SupportedAccountType.SELLER,
  tooltip: "Total Revenue",
});

export const PROFIT_DETAIL_SHARE = new RatioMetric<ProfitDetail>({
  id: "PROFIT_DETAIL_SHARE",
  title: "% of Revenue",
  isPercent: true,
  numerator: PROFIT_DETAIL_AMOUNT,
  denominator: PROFIT_DETAIL_TOTAL_REVENUE,
  category: MetricCategory.ORDER_STATS,
  color: PALETTE[2],
  supportedAccountType: SupportedAccountType.SELLER,
  tooltip: "Profit share",
});

@UntilDestroy()
@Component({
  templateUrl: "./profit-details-widget.component.html",
  styleUrls: ["../widget.scss"],
})
export class ProfitDetailsWidgetComponent implements OnInit {
  @Input()
  id: string;
  @Input()
  showTitle = true;
  @Input()
  expandedOptions = false;
  @Input() data$: Observable<ProfitDetail[]>;

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  gridOptions: GridOptions<ProfitDetail>;

  // state
  loading = true;
  locale: string;
  currency: Currency;
  overlayMessage$: Observable<string>;

  readonly columnDefs: ColDef[] = [
    {
      field: "category",
      headerName: "Category",
      colId: "category",
      rowGroup: true,
      hide: true,
    },
    ...getMetricsColDef([PROFIT_DETAIL_AMOUNT]).map((def: ColDef) => ({
      ...def,
      cellRendererParams: (params) => ({
        ...def.cellRendererParams(params),
        currency: this.currency,
        locale: this.locale,
      }),
    })),
    this.shareColumn(),
  ];

  constructor(
    private authService: AuthService,
    private userSelectionService: UserSelectionService,
    private accountSelectionService: AccountSelectionService,
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.data$.pipe(untilDestroyed(this)).subscribe(() => {
      this.loading = false;
    });
    this.overlayMessage$ = this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(
      map((am: AccountMarketplace) =>
        am.accountType === AccountType.VENDOR ? "Profit details are not available for Vendor" : "",
      ),
    );

    this.gridOptions = {
      ...getBasicGridOptions("p360_profit_details" + (this.expandedOptions ? "_exp" : ""), false, true),
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
      },
      columnDefs: this.columnDefs,
      autoGroupColumnDef: {
        headerName: "Revenue/Cost",
        field: "field",
      },
      getGroupRowAgg: (params: GetGroupRowAggParams<ProfitDetail>) => {
        const result: ProfitDetail = {} as ProfitDetail;
        for (const n of params.nodes) {
          if (n.data) {
            result.value = n.data.aggValue;
            result.totalRevenue = n.data.totalRevenue;
            break;
          }
        }
        return result;
      },
    };
    if (!this.expandedOptions) {
      this.gridOptions.sideBar = false;
    }
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.locale = user.locale;
    });
    this.userSelectionService.selectedCurrency$.pipe(untilDestroyed(this)).subscribe((currency) => {
      this.currency = currency;
    });
  }

  private shareColumn(): ColDef {
    return {
      headerName: PROFIT_DETAIL_SHARE.title,
      headerTooltip: PROFIT_DETAIL_SHARE.title,
      colId: PROFIT_DETAIL_SHARE.id,
      enableValue: true,
      enablePivot: true,
      type: "numericColumn",
      filter: "agNumberColumnFilter", // Authorize filter on values (greater, equal...)
      valueGetter: (params) => {
        // Here, we set the intrinsic value of the cell.
        // We can get non summable metrics computed by metric.value() method
        // In case of grouping, this is the same process, we just pass "node.aggData" instead of "data"
        const val = PROFIT_DETAIL_SHARE.value(params.data ?? params.node.aggData);
        return isNaN(val) ? 0 : val * 100;
      },
      valueFormatter: (params) => {
        let val = PROFIT_DETAIL_SHARE.value(params.data ?? params.node.aggData);
        val = isNaN(val) ? 0 : val < 0 ? -val : val;
        return PROFIT_DETAIL_SHARE.format(val, this.locale, this.currency);
      },
      cellClassRules: {
        "text-green-500": (params) => params.value >= 0,
        "text-red-500": (params) => params.value < 0,
      },
    };
  }

  protected readonly STATUS_BAR = STATUS_BAR;
}
