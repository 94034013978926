<div class="card-group border-shadow pointer mb-2" #mainContainer>
  @for (m of metrics; track m; let i = $index) {
    <ng-container
      [ngTemplateOutlet]="usedMetricSlot"
      [ngTemplateOutletContext]="{ index: i, metric: m }"
    ></ng-container>
  }
  <div
    matTooltipPosition="above"
    matTooltip="Add metric"
    class="add-metric-button relative flex flex-col items-center justify-center p-2"
    [matMenuTriggerFor]="ddMenu"
    (menuOpened)="setFocus()"
    (menuClosed)="filter = ''"
    [matMenuTriggerData]="{ index: metrics.length, newMetric: true }"
  >
    <fa-icon [icon]="faPlus"></fa-icon>
  </div>
</div>

<mat-menu #ddMenu="matMenu" class="w-full">
  <ng-template matMenuContent let-index="index" let-newMetric="newMetric">
    <div class="dd-input-container flex items-center" (click)="$event.stopPropagation()" *transloco="let t">
      <input
        type="text"
        class="simple-input form-control"
        placeholder="{{ t('common.search') }}"
        [value]="filter"
        (input)="filter = getValueFromInputEvent($event)"
        (click)="$event.stopPropagation()"
        #search
      />
    </div>
    <ul class="p-0" *transloco="let t">
      @for (c of _groupedPageMetrics | keyvalue; track c) {
        @if (showMetricGroup(c.value)) {
          <li class="category dd-item" (click)="$event.stopPropagation()">
            {{ c.key }}
          </li>
          @for (m of c.value; track m) {
            @if (showMetric(m)) {
              <li
                class="dd-item"
                matTooltip="{{ t('metrics.' + m.id + '_tooltip') }}"
                matTooltipPosition="right"
                [class.metric-dropdown-unselectable]="newMetric && isMetricInSelector(m)"
                (click)="
                  newMetric && isMetricInSelector(m) ? $event.stopPropagation() : dropdownMetricSelected(index, m)
                "
              >
                <div class="flex w-full items-baseline justify-between">
                  {{ t("metrics." + m.id + "_title") }}
                </div>
              </li>
            }
          }
        }
      }
    </ul>
  </ng-template>
</mat-menu>

<ng-template #usedMetricSlot let-index="index" let-metric="metric">
  <div
    class="card relative"
    (click)="toggleChartMetric(metric)"
    [class.selected]="chartMetrics.includes(metric) && enableChartMetric"
    *transloco="let t"
  >
    <div class="chart-metric-marker" [style.background-color]="getColorForMetric(metric)">
      <div class="triangle" [style.border-bottom-color]="getColorForMetric(metric)"></div>
    </div>
    @if (!chartMetrics.includes(metric) && metrics.length > 1) {
      <fa-icon
        [icon]="faDelete"
        class="delete-icon absolute"
        (click)="removeMetricFromSelector(index)"
        matTooltip="Remove metric"
      ></fa-icon>
    }
    <div class="card-body p-1 text-center">
      @if (metric) {
        <div
          [matMenuTriggerFor]="ddMenu"
          [matMenuTriggerData]="{ index: index }"
          (click)="$event.stopPropagation()"
          (menuOpened)="setFocus()"
          (menuClosed)="filter = ''"
        >
          <div
            class="metric-title pointer flex items-center justify-center"
            [matTooltip]="t('metrics.' + metric.id + '_tooltip')"
          >
            <div>
              <div class="title">{{ t("metrics." + metric.id + "_title") }}</div>
              <div class="subtitle">{{ metric.subtitle }}</div>
            </div>
            <span [class]="ICON_CHEVRON_DOWN" class="ml-1 text-lg text-gray-700"></span>
          </div>
        </div>
      }
      <div class="relative">
        <div
          class="flex w-full flex-col items-center justify-center min-[2000px]:flex-row"
          [class.preview]="(shouldRequestSellingPartnerGrant$ | async) && metric.requireSellingPartnerAccess"
        >
          <div
            class="metric-value mb-0 py-1"
            matTooltip="{{ metric | metricFormatPipe: data : locale : currency }}"
            matTooltipPosition="below"
          >
            {{ metric | metricFormatPipe: data : locale : currency : undefined : undefined }}
          </div>
          @if (compareEnabledInPage && showCompare && periodComparison) {
            @if (!loadingPreviousPeriodMetrics) {
              <app-metric-evo
                class="ml-2"
                [metric]="metric"
                [currency]="currency"
                [locale]="locale"
                [currentData]="data"
                [previousData]="previousPeriodData_"
                [displayMode]="METRIC_EVO_DISPLAY.BADGE"
              />
            } @else {
              <div class="ml-2 text-center">(-)</div>
            }
          }
        </div>
        @if ((shouldRequestSellingPartnerGrant$ | async) && metric.requireSellingPartnerAccess) {
          <div class="absolute top-0 w-full">
            <button
              class="btn btn-warning btn-sm"
              (click)="requestGrant()"
              matTooltip="Grant access to organic data to view {{ metric.title }} trend"
            >
              {{ t("common.grant_access") }}
            </button>
          </div>
        }
      </div>
    </div>
  </div>
</ng-template>
