import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  AccountMarketplace,
  AsinService,
  CampaignType,
  Catalog,
  getStrategies,
  MatchType,
  MaxPriority,
  Strategy,
  StrategyAsin,
  StrategyGroupEx,
  StrategyStateEnum,
  StrategyTargetingType,
  StrategyType,
  StrategyTypeStr,
  Targeting,
  Utils,
} from "@front/m19-services";
import {
  AlgoModeConfig,
  AlgoModeConfigValid,
} from "@m19-board/strategies/strategies/algo-mode-selection/algo-mode-selection.component";
import { StrategyAsinSelectionMode } from "@m19-board/strategies/strategy-asins/asins-selection.component";
import {
  FromBrandSelectionModalComponent,
  SpGroupTargetings,
} from "./from-brand-selection-modal/from-brand-selection-modal.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { ICON_ARROW_LEFT } from "@m19-board/utils/iconsLabels";
import { Constant } from "libs/m19-services/src/lib/m19-services/constant";

const formControlNames = new Map([
  ["name", "Strategy Name"],
  ["algoModeConfig", "Algorithm"],
  ["asins", "ASINs"],
  ["targetingType", "Targetings"],
  ["keywords", "Targeted Keywords"],
  ["productTargetings", "Targeted Products"],
]);

@UntilDestroy()
@Component({
  selector: "app-sp-substrategy-creation",
  templateUrl: "./sp-substrategy-creation.component.html",
  styleUrls: ["./sp-substrategy-creation.component.scss", "../../../strategies/strategies/strategy-styles.scss"],
})
export class SpSubStrategyCreationComponent implements OnInit {
  @Input()
  accountMarketplace: AccountMarketplace;

  @Input()
  strategyGroup: StrategyGroupEx;

  @Input()
  strategyType: StrategyType;

  @Output()
  strategyToCreate = new EventEmitter<Strategy>();

  spForm = new FormGroup({
    name: new FormControl<string>(Utils.generateRandomName(), [Validators.required, Validators.maxLength(80)]),
    asins: new FormControl<StrategyAsin[]>([], [this.asinListValidator]),
    algoModeConfig: new FormControl<AlgoModeConfig>(undefined, [Validators.required]),
    targetingType: new FormControl<StrategyTargetingType>(undefined, []),
    keywords: new FormControl<Targeting[]>([], []),
    productTargetings: new FormControl<StrategyAsin[]>([], []),
  });

  productSelectionModes: { selectionMode: StrategyAsinSelectionMode; label: string }[];
  productTargetingSelectionModes: { selectionMode: StrategyAsinSelectionMode; label: string }[];
  productTargetingsAllowAsinFromOtherCatalog: boolean;
  strategyTypeStr: string;
  withTargetings: boolean;
  withMultipleTargetings: boolean;
  asinEligibility: Map<string, { status: boolean; reason: string }> = new Map();
  notDisplayedAsins: string[] = [];

  formSubmitted: boolean;
  invalidFields: string[] = [];

  readonly maxKwTargetingByStrategy = Constant.maxKwTargetingByStrategy;
  readonly maxAsinTargetingByStrategy = Constant.maxAsinTargetingByStrategy;
  readonly StrategyTargetingType = StrategyTargetingType;
  readonly StrategyType = StrategyType;
  readonly ICON_ARROW = ICON_ARROW_LEFT;

  constructor(
    private asinService: AsinService,
    private modalService: BsModalService,
  ) {}

  ngOnInit(): void {
    this.strategyTypeStr = StrategyTypeStr[this.strategyType];
    if (this.strategyType == StrategyType.BRAND || this.strategyType == StrategyType.KEYWORD) {
      this.productSelectionModes = [
        { selectionMode: StrategyAsinSelectionMode.FromCustomAsinList, label: "From Strategy Group ASINs" },
        { selectionMode: StrategyAsinSelectionMode.Bulk, label: "ASIN list" },
      ];
    } else {
      this.productSelectionModes = [
        { selectionMode: StrategyAsinSelectionMode.FromCatalog, label: "Catalog" },
        { selectionMode: StrategyAsinSelectionMode.Bulk, label: "Bulk" },
        { selectionMode: StrategyAsinSelectionMode.FromProductGroups, label: "Product Group" },
      ];
      this.notDisplayedAsins = this.strategyGroup.asins ?? [];
    }
    this.asinService
      .getCatalog(this.accountMarketplace.accountId, this.accountMarketplace.marketplace)
      .pipe(untilDestroyed(this))
      .subscribe((catalog: Catalog) => {
        this.asinEligibility = catalog.getSPEligibility();
      });
    this.withTargetings = this.strategyType == StrategyType.KEYWORD;
    this.withMultipleTargetings = this.strategyType == StrategyType.BRAND;
    if (this.strategyType == StrategyType.BRAND) {
      this.productTargetingSelectionModes = [
        { selectionMode: StrategyAsinSelectionMode.FromCatalog, label: "From Catalog" },
        { selectionMode: StrategyAsinSelectionMode.Bulk, label: "From ASIN list" },
        { selectionMode: StrategyAsinSelectionMode.FromProductGroups, label: "From Product Groups" },
      ];
      this.productTargetingsAllowAsinFromOtherCatalog = false;
    } else {
      this.productTargetingSelectionModes = [{ selectionMode: StrategyAsinSelectionMode.Bulk, label: "ASIN list" }];
      this.productTargetingsAllowAsinFromOtherCatalog = true;
    }
    if (this.withTargetings) {
      this.spForm.setValidators((form: typeof this.spForm) => {
        if (!form.controls.targetingType.value) {
          const error = { missingTargeting: true };
          form.controls.keywords.setErrors(null);
          form.controls.productTargetings.setErrors(null);
          form.controls.targetingType.setErrors(error);
          return error;
        }
        if (
          form.controls.targetingType.value == StrategyTargetingType.KEYWORDS &&
          (form.controls.keywords.value?.length ?? 0) == 0
        ) {
          const error = { missingKeywords: true };
          form.controls.targetingType.setErrors(null);
          form.controls.productTargetings.setErrors(null);
          form.controls.keywords.setErrors(error);
          return error;
        }
        if (
          form.controls.targetingType.value == StrategyTargetingType.PRODUCTS &&
          (form.controls.productTargetings.value?.length ?? 0) == 0
        ) {
          const error = { missingProductTargeting: true };
          form.controls.targetingType.setErrors(null);
          form.controls.keywords.setErrors(null);
          form.controls.productTargetings.setErrors(error);
          return error;
        }
        form.controls.targetingType.setErrors(null);
        form.controls.keywords.setErrors(null);
        form.controls.productTargetings.setErrors(null);
      });
    }
    if (this.withMultipleTargetings) {
      this.spForm.setValidators((form: typeof this.spForm) => {
        if (
          (form.controls.keywords.value?.length ?? 0) == 0 &&
          (form.controls.productTargetings.value?.length ?? 0) == 0
        ) {
          const error = { missingKeywords: true };
          form.controls.keywords.setErrors(error);
          return error;
        }
        form.controls.keywords.setErrors(null);
      });
    }
  }

  importFromBrandModal() {
    const ref = this.modalService.show(FromBrandSelectionModalComponent, {
      initialState: {
        matchType: MatchType.phrase,
      },
      class: "modal-dialog-centered",
    });
    ref.content.emitTargetings.subscribe((targetings: SpGroupTargetings) => {
      this.updateKeywords(targetings.keywords);
      this.addProductTargeting(targetings.products);
    });
  }

  addAsins(asins: StrategyAsin[]) {
    const newList = this.spForm.controls.asins.value.concat(asins);
    this.spForm.controls.asins.setValue(newList);
  }

  deleteAsins(asins: StrategyAsin[]) {
    const newList = this.spForm.controls.asins.value.filter((a) => !asins.map((a2) => a2.asin).includes(a.asin));
    this.spForm.controls.asins.setValue(newList);
  }

  toggleTargetingType(type: StrategyTargetingType) {
    this.spForm.controls.targetingType.setValue(type);
  }

  updateKeywords(keywords: Targeting[]) {
    this.spForm.controls.keywords.setValue(keywords);
  }

  addProductTargeting(asins: StrategyAsin[]) {
    const newList = this.spForm.controls.productTargetings.value.concat(asins);
    this.spForm.controls.productTargetings.setValue(newList);
  }

  deleteProductTargeting(asins: StrategyAsin[]) {
    const newList = this.spForm.controls.productTargetings.value.filter(
      (a) => !asins.map((a2) => a2.asin).includes(a.asin),
    );
    this.spForm.controls.productTargetings.setValue(newList);
  }

  setAlgoModeConfig(algoModeConfig: Partial<AlgoModeConfig>) {
    this.spForm.controls.algoModeConfig.setValue(algoModeConfig as AlgoModeConfig);
  }

  setAlgoModeConfigValid(valid: AlgoModeConfigValid) {
    this.spForm.controls.algoModeConfig.setErrors(valid.errors);
  }

  private getInvalidOrMissingFields() {
    const res = [];
    const keys = Object.keys(this.spForm.controls);
    for (const c of keys) {
      if (this.spForm.controls[c].invalid) {
        res.push(formControlNames.get(c));
      }
    }
    return res;
  }

  private computePriority() {
    if (this.strategyType != StrategyType.BRAND && this.strategyType != StrategyType.KEYWORD) {
      return undefined;
    }
    const strategies = getStrategies(this.strategyGroup, this.strategyType);
    if (strategies.length == 0) {
      return undefined;
    }
    return Math.floor((strategies[strategies.length - 1].priority + MaxPriority) / 2);
  }

  private asinListValidator(control: AbstractControl) {
    if (!control.value || control.value.length < 1) return { missingAsins: true };
    return null;
  }

  submitForm() {
    this.formSubmitted = true;
    if (this.spForm.invalid) {
      this.invalidFields = this.getInvalidOrMissingFields();
      return;
    }
    this.formSubmitted = false;

    const targetings: Targeting[] = [];
    if (this.withMultipleTargetings) {
      for (const k of this.spForm.controls.keywords.value) {
        targetings.push(k);
      }
      for (const p of this.spForm.controls.productTargetings.value) {
        targetings.push({
          targetingValue: p.asin,
          matchType: MatchType.asinSameAs,
        });
      }
    } else {
      if (this.spForm.controls.targetingType.value == StrategyTargetingType.KEYWORDS) {
        for (const k of this.spForm.controls.keywords.value) {
          targetings.push(k);
        }
      } else {
        for (const p of this.spForm.controls.productTargetings.value) {
          targetings.push({
            targetingValue: p.asin,
            matchType: MatchType.asinSameAs,
          });
        }
      }
    }
    this.strategyToCreate.emit({
      accountId: this.accountMarketplace.accountId,
      marketplace: this.accountMarketplace.marketplace,
      state: StrategyStateEnum.ENABLED,
      campaignType: CampaignType.SP,
      strategyGroupId: this.strategyGroup.strategyGroupId,
      strategyType: this.strategyType,
      defaultStrategy: false,
      tactics: [],
      audienceTargetings: [],
      targetings,
      name: this.spForm.controls.name.value,
      ...this.spForm.controls.algoModeConfig.value,
      acosTarget: this.spForm.controls.algoModeConfig.value?.acosTarget
        ? this.spForm.controls.algoModeConfig.value.acosTarget / 100
        : undefined,
      asins: this.spForm.controls.asins.value,
      priority: this.computePriority(),
    });
  }

  protected readonly MatchType = MatchType;
}
