import { catchError, map, Observable, throwError } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { SharedDataApi } from './api-client';
import { fromSharedReport, SharedReportEx } from './models';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataSharingService {
  constructor(private sharedDataApi: SharedDataApi) {}

  public listSharedReports(organizationId: number): Observable<SharedReportEx[]> {
    return this.sharedDataApi.listSharedReports({ organizationId }).pipe(
      map((reports) => reports.map((r) => fromSharedReport(r)).sort((a, b) => b.date!.localeCompare(a.date!))),
      catchError((error: AjaxError) => {
        return throwError(() => 'Error getting list of shared reports: ' + (error.response?.message ?? error.message));
      }),
    );
  }

  public downloadReport(report: SharedReportEx) {
    return this.sharedDataApi
      .downloadSharedReport({
        organizationId: report.organizationId!,
        reportDir: report.reportTypeEx.dir,
        marketplace: report.marketplace,
        fileName: report.fileName!,
      })
      .pipe(
        catchError((error: AjaxError) => {
          return throwError(() => 'Error downloading report: ' + (error.response?.message ?? error.message));
        }),
      );
  }
}
