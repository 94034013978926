import { Currency, Marketplace } from '..';
import { Platform } from './Platform';

export class MarketplaceEx {
  public readonly name!: string;
  public readonly currency!: Currency;
  public readonly timeZone!: string;
  public readonly topLevelDomain!: string;
  public readonly sellerCentralUrl!: string;
  public readonly vendorCentralUrl!: string;
  public readonly platform!: Platform;
  public readonly flag!: string;
  public readonly color!: string;
  public readonly defaultMaxBid!: number;
}

type MarketplaceDefinitions = {
  [key in Marketplace]: MarketplaceEx;
};

export const Marketplaces: MarketplaceDefinitions = {
  AE: {
    name: 'United Arab Emirates',
    currency: Currency.AED,
    timeZone: 'Asia/Dubai',
    topLevelDomain: 'ae',
    sellerCentralUrl: 'https://sellercentral.amazon.ae',
    vendorCentralUrl: 'https://vendorcentral.amazon.me',
    platform: Platform.EU,
    flag: '🇦🇪',
    color: '#181d4d',
    defaultMaxBid: 10,
  },
  AU: {
    name: 'Australia',
    currency: Currency.AUD,
    timeZone: 'Australia/Sydney',
    topLevelDomain: 'com.au',
    sellerCentralUrl: 'https://sellercentral.amazon.com.au',
    vendorCentralUrl: 'https://vendorcentral.amazon.com.au',
    platform: Platform.FE,
    flag: '🇦🇺',
    color: '#4bae99',
    defaultMaxBid: 4,
  },
  BE: {
    name: 'Belgium',
    currency: Currency.EUR,
    timeZone: 'Europe/Brussels',
    topLevelDomain: 'com.be',
    sellerCentralUrl: 'https://sellercentral.amazon.com.be',
    vendorCentralUrl: 'https://vendorcentral.amazon.com.be',
    platform: Platform.EU,
    flag: '🇧🇪',
    color: '#f0ad4e',
    defaultMaxBid: 2.5,
  },
  BR: {
    name: 'Brazil',
    currency: Currency.BRL,
    timeZone: 'America/Sao_Paulo',
    topLevelDomain: 'com.br',
    sellerCentralUrl: 'https://sellercentral.amazon.com.br',
    vendorCentralUrl: 'https://vendorcentral.amazon.com.br',
    platform: Platform.NA,
    flag: '🇧🇷',
    color: '#7b9fe8',
    defaultMaxBid: 2.5,
  },
  CA: {
    name: 'Canada',
    currency: Currency.CAD,
    timeZone: 'America/Los_Angeles',
    topLevelDomain: 'ca',
    sellerCentralUrl: 'https://sellercentral.amazon.ca',
    vendorCentralUrl: 'https://vendorcentral.amazon.ca',
    platform: Platform.NA,
    flag: '🇨🇦',
    color: '#969696',
    defaultMaxBid: 2.5,
  },
  DE: {
    name: 'Germany',
    currency: Currency.EUR,
    timeZone: 'Europe/Paris',
    topLevelDomain: 'de',
    sellerCentralUrl: 'https://sellercentral-europe.amazon.com',
    vendorCentralUrl: 'https://vendorcentral.amazon.de',
    platform: Platform.EU,
    flag: '🇩🇪',
    color: '#8e6ba0',
    defaultMaxBid: 2.5,
  },
  ES: {
    name: 'Spain',
    currency: Currency.EUR,
    timeZone: 'Europe/Paris',
    topLevelDomain: 'es',
    sellerCentralUrl: 'https://sellercentral-europe.amazon.com',
    vendorCentralUrl: 'https://vendorcentral.amazon.es',
    platform: Platform.EU,
    flag: '🇪🇸',
    color: '#f9b1c7',
    defaultMaxBid: 2.5,
  },
  EG: {
    name: 'Egypt',
    currency: Currency.EGP,
    timeZone: 'Europe/Paris',
    topLevelDomain: 'eg',
    sellerCentralUrl: 'https://sellercentral.amazon.eg',
    vendorCentralUrl: 'https://vendorcentral.amazon.me',
    platform: Platform.EU,
    flag: '🇪🇬',
    color: '#334061',
    defaultMaxBid: 10,
  },
  FR: {
    name: 'France',
    currency: Currency.EUR,
    timeZone: 'Europe/Paris',
    topLevelDomain: 'fr',
    sellerCentralUrl: 'https://sellercentral-europe.amazon.com',
    vendorCentralUrl: 'https://vendorcentral.amazon.fr',
    platform: Platform.EU,
    flag: '🇫🇷',
    color: '#ed7d24',
    defaultMaxBid: 2.5,
  },
  IT: {
    name: 'Italy',
    currency: Currency.EUR,
    timeZone: 'Europe/Paris',
    topLevelDomain: 'it',
    sellerCentralUrl: 'https://sellercentral-europe.amazon.com',
    vendorCentralUrl: 'https://vendorcentral.amazon.it',
    platform: Platform.EU,
    flag: '🇮🇹',
    color: '#becae6',
    defaultMaxBid: 2.5,
  },
  IN: {
    name: 'India',
    currency: Currency.INR,
    timeZone: 'Asia/Kolkata',
    topLevelDomain: 'in',
    sellerCentralUrl: 'https://sellercentral.amazon.in',
    vendorCentralUrl: 'https://www.vendorcentral.in', // see https://github.com/amzn/selling-partner-api-models/issues/2644
    platform: Platform.EU,
    flag: '🇮🇳',
    color: '#ed2496',
    defaultMaxBid: 200,
  },
  JP: {
    name: 'Japan',
    currency: Currency.JPY,
    timeZone: 'Asia/Tokyo',
    topLevelDomain: 'co.jp',
    sellerCentralUrl: 'https://sellercentral.amazon.co.jp',
    vendorCentralUrl: 'https://vendorcentral.amazon.co.jp',
    platform: Platform.FE,
    flag: '🇯🇵',
    color: '#e6d687',
    defaultMaxBid: 250,
  },
  MX: {
    name: 'Mexico',
    currency: Currency.MXN,
    timeZone: 'America/Los_Angeles',
    topLevelDomain: 'com.mx',
    sellerCentralUrl: 'https://sellercentral.amazon.mx',
    vendorCentralUrl: 'https://vendorcentral.amazon.com.mx',
    platform: Platform.NA,
    flag: '🇲🇽',
    color: '#f72b25',
    defaultMaxBid: 50,
  },
  NL: {
    name: 'Netherlands',
    currency: Currency.EUR,
    timeZone: 'Europe/Paris',
    topLevelDomain: 'nl',
    sellerCentralUrl: 'https://sellercentral-europe.amazon.com',
    vendorCentralUrl: 'https://vendorcentral.amazon.nl',
    platform: Platform.EU,
    flag: '🇳🇱',
    color: '#636dce',
    defaultMaxBid: 2.5,
  },
  PL: {
    name: 'Poland',
    currency: Currency.PLN,
    timeZone: 'Europe/Paris',
    topLevelDomain: 'pl',
    sellerCentralUrl: 'https://sellercentral-europe.amazon.com',
    vendorCentralUrl: 'https://vendorcentral.amazon.pl',
    platform: Platform.EU,
    flag: '🇵🇱',
    color: '#a3d7cc',
    defaultMaxBid: 10,
  },
  SA: {
    name: 'Saudi Arabia',
    currency: Currency.SAR,
    timeZone: 'Asia/Riyadh',
    topLevelDomain: 'sa',
    sellerCentralUrl: 'https://sellercentral.amazon.sa',
    vendorCentralUrl: 'https://vendorcentral.amazon.me',
    platform: Platform.EU,
    flag: '🇸🇦',
    color: '#bccef3',
    defaultMaxBid: 2.5,
  },
  SE: {
    name: 'Sweden',
    currency: Currency.SEK,
    timeZone: 'Europe/Paris',
    topLevelDomain: 'se',
    sellerCentralUrl: 'https://sellercentral-europe.amazon.com',
    vendorCentralUrl: 'https://vendorcentral.amazon.se',
    platform: Platform.EU,
    flag: '🇸🇪',
    color: '#f7d6a6',
    defaultMaxBid: 25,
  },
  SG: {
    name: 'Singapore',
    currency: Currency.SGD,
    timeZone: 'Asia/Singapore',
    topLevelDomain: 'sg',
    sellerCentralUrl: 'https://sellercentral.amazon.sg',
    vendorCentralUrl: 'https://vendorcentral.amazon.com.sg',
    platform: Platform.FE,
    flag: '🇸🇬',
    color: '#cacaca',
    defaultMaxBid: 2.5,
  },
  TR: {
    name: 'Turkey',
    currency: Currency.TRY,
    timeZone: 'Europe/Paris',
    topLevelDomain: 'com.tr',
    sellerCentralUrl: 'https://sellercentral.amazon.com.tr',
    vendorCentralUrl: 'https://vendorcentral.amazon.com.tr',
    platform: Platform.EU,
    flag: '🇹🇷',
    color: '#c6b4cf',
    defaultMaxBid: 10,
  },
  UK: {
    name: 'United Kingdom',
    currency: Currency.GBP,
    timeZone: 'Europe/London',
    topLevelDomain: 'co.uk',
    sellerCentralUrl: 'https://sellercentral-europe.amazon.com',
    vendorCentralUrl: 'https://vendorcentral.amazon.co.uk',
    platform: Platform.EU,
    flag: '🇬🇧',
    color: '#fcd7e3',
    defaultMaxBid: 2.5,
  },
  US: {
    name: 'United States',
    currency: Currency.USD,
    timeZone: 'America/Los_Angeles',
    topLevelDomain: 'com',
    sellerCentralUrl: 'https://sellercentral.amazon.com',
    vendorCentralUrl: 'https://vendorcentral.amazon.com',
    platform: Platform.NA,
    flag: '🇺🇸',
    color: '#8898c0',
    defaultMaxBid: 2.5,
  },
};
