import { Component, ViewChild } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  AccountMarketplace,
  AccountState,
  AccountType,
  ACOS,
  AD_CONVERSIONS,
  AD_SALES,
  AdStatsEx,
  alignDate,
  AuthService,
  CLICK_THROUGH_RATE,
  CLICKS,
  CONVERSION_RATE,
  convertToCurrency,
  COST,
  CPC,
  Currency,
  DataSet,
  DataSetEventAnnotation,
  DateAggregation,
  getBasicGridOptions,
  getFilteredLeafNodes,
  getGroupRowAgg,
  IMPRESSIONS,
  mergeSeveralDates,
  Metric,
  MetricsSelectorLocalStorageKey,
  OrganizationAccountGroupService,
  ROAS,
  StatsApiClientService,
  UserSelectionService,
  Utils,
} from "@front/m19-services";

import { AgGridAngular } from "@ag-grid-community/angular";
import {
  ColDef,
  GetRowIdParams,
  GridOptions,
  ICellRendererParams,
  IRowNode,
  ModelUpdatedEvent,
} from "@ag-grid-community/core";
import { actionColumnProperties, CurrencyColumn, MarketplaceColumn } from "@m19-board/grid-config/grid-columns";
import {
  ACTIONS_COL_ID,
  exportGridCsv,
  getCsvFileName,
  getMetricsColDef,
  STATUS_BAR,
} from "@m19-board/grid-config/grid-config";

import { ChartRendererComponent } from "@m19-board/shared/chart-renderer/chart-renderer.component";
import { LinkComponent } from "@m19-board/shared/link/link.component";
import { ICON_CHART_LINE, ICON_CLOSE } from "@m19-board/utils/iconsLabels";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { BehaviorSubject, combineLatest, Observable, of, Subject } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";
import { ActionButton, ActionButtonsComponent } from "../insights/overview/action-buttons/action-buttons.component";
import { TACOS, TOTAL_ORDERS, TOTAL_SALES } from "../models/MetricsDef";
import { ActivityService } from "@m19-board/activities/activity.service";

export interface SuperBoardData extends AdStatsEx {
  rowId: string;
  organizationName: string;
  organizationId: number;
  accountState?: string;
  accountGroupName?: string;
  accountType?: AccountType;
  vendorManufacturingAccess?: boolean;
  dailyStats: AdStatsEx[];
}

@UntilDestroy()
@Component({
  selector: "app-agency-board",
  templateUrl: "./agency-board.component.html",
})
export class AgencyBoardComponent {
  readonly localStorageKey = MetricsSelectorLocalStorageKey.superBoard;

  readonly METRICS = [
    TOTAL_SALES,
    TOTAL_ORDERS,
    AD_SALES,
    AD_CONVERSIONS,
    COST,
    ACOS,
    CLICKS,
    IMPRESSIONS,
    CLICK_THROUGH_RATE,
    CONVERSION_RATE,
    CPC,
    ROAS,
    TACOS,
  ];
  readonly STATUS_BAR = STATUS_BAR;
  readonly ICON_CHART = ICON_CHART_LINE;
  readonly ICON_CLOSE = ICON_CLOSE;

  private locale = "fr";
  private uiVersion = 0;
  private currency: string = Currency.EUR;

  // top metric selector
  totalData?: AdStatsEx; // entry selector
  previousTotalData?: AdStatsEx;
  private selectedMetrics$: BehaviorSubject<Metric<AdStatsEx>[]> = new BehaviorSubject([AD_SALES, COST]);

  // global chart config
  isGlobalChartHidden = false;
  globalDataset: DataSet<AdStatsEx>; // Dataset for global chart
  globalChartData$: Subject<{ data: AdStatsEx[]; previousData: AdStatsEx[] }> = new Subject();
  dateAggregation$ = new BehaviorSubject<DateAggregation>(DateAggregation.daily);

  // grid data
  gridData$: Observable<Map<string, SuperBoardData>>;
  previousDataByAccount$ = new BehaviorSubject(
    new Map<string, { totalPreviousData: AdStatsEx; previousDailyStats: AdStatsEx[] }>(),
  );
  statsByAccount = new Map<string, SuperBoardData>();

  minDate: string = "";
  maxDate: string = "";
  previousDateRange?: string[];

  // row chart config
  rowDataset: DataSet<AdStatsEx>;

  // Grid configuration
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  private GRID_KEY = "superBoardGrid";

  // Col defs common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  public columnDefs: ColDef[] = [
    {
      ...actionColumnProperties<SuperBoardData, string>(),
      cellRendererSelector: (params) => {
        return {
          component: ActionButtonsComponent,
          params: {
            actionButtons: [
              {
                icon: ICON_CHART_LINE,
                tooltip: "Display graph",
                onClick: (params: ICellRendererParams) => {
                  this.displayRowChart(params.node);
                },
              },
            ] as ActionButton[],
          },
        };
      },
    },
    {
      field: "organizationName",
      headerName: "Organization",
      pinned: "left",
      enableRowGroup: true,
      cellClass: "sensitive-data",
      filter: "agSetColumnFilter",
      floatingFilter: true,
    },
    {
      field: "accountGroupName",
      headerName: "Account Group",
      pinned: "left",
      enableRowGroup: true,
      cellClass: "sensitive-data",
      floatingFilter: true,
      filter: "agSetColumnFilter",
    },
    {
      field: "accountName",
      headerName: "Account",
      pinned: "left",
      cellRendererSelector: (params) => {
        const marketplace = params.node.data?.marketplace;
        const accountId = params.node.data?.accountId;
        const orgId = params.node.data?.organizationId;
        const routingLink = this.uiVersion == 0 ? "/home" : "/dashboard/advertising";
        return {
          component: LinkComponent,
          params: {
            routerLink: routingLink,
            queryParams: { accountId, marketplace, orgId },
            content: params.value,
            queryParamsHandling: "merge",
          },
        };
      },
      enableRowGroup: true,
      cellClass: "sensitive-data",
      filter: "agSetColumnFilter",
      floatingFilter: true,
    },
    { ...MarketplaceColumn, pinned: "left", enableRowGroup: true },
    {
      colId: "accountState",
      headerName: "State",
      pinned: "left",
      enableRowGroup: true,
      valueGetter: (params) => {
        switch (params.data?.accountState) {
          case AccountState.BIDDER_ON:
            return "Ads Automation";
          case AccountState.DOWNLOADER_ON:
            return "Pull Stats";
          default:
            return "-";
        }
      },
      filter: "agSetColumnFilter",
      floatingFilter: true,
    },
    {
      headerName: "Type",
      pinned: "left",
      enableRowGroup: true,
      valueGetter: (params) => {
        switch (params.data?.accountType) {
          case AccountType.SELLER:
            return "Seller";
          case AccountType.VENDOR:
            return "Vendor";
          default:
            return "-";
        }
      },
      floatingFilter: true,
      filter: "agSetColumnFilter",
    },
    {
      ...CurrencyColumn,
      valueGetter: () => this.currency,
    },
    // Metrics columns
    ...getMetricsColDef(this.METRICS).map((def: ColDef) => ({
      ...def,
      cellRendererParams: (params: any) => {
        return {
          ...def.cellRendererParams(params),
          previousData: this.getPreviousNodeData(params.node),
          currency: this.currency,
          locale: this.locale,
        };
      },
    })),
  ];

  private commonOptions = getBasicGridOptions(this.GRID_KEY, true);
  public gridOptions: GridOptions = {
    ...this.commonOptions,
    defaultColDef: this.defaultColDef,
    columnDefs: this.columnDefs,
    showOpenedGroup: false,
    context: { componentParent: this, locale: this.locale, currency: this.currency },
    rowGroupPanelShow: "always",
    autoGroupColumnDef: {
      pinned: "left",
    },
    onModelUpdated: (event: ModelUpdatedEvent<any>) => {
      if (this.commonOptions.onModelUpdated) this.commonOptions.onModelUpdated(event);
    },
    getGroupRowAgg: getGroupRowAgg,
    getRowId: (params: GetRowIdParams<SuperBoardData>) => params.data.rowId,
  };

  constructor(
    private authService: AuthService,
    private userSelectionService: UserSelectionService,
    private statsApiClient: StatsApiClientService,
    private modalService: BsModalService,
    private organizationAccountGroupService: OrganizationAccountGroupService,
    private activityService: ActivityService,
  ) {
    this.globalDataset = new DataSet<AdStatsEx>(3, this.selectedMetrics$.getValue(), mergeSeveralDates);
    this.rowDataset = new DataSet<AdStatsEx>(3, this.selectedMetrics$.getValue(), mergeSeveralDates);

    this.userSelectionService.selectedCurrency$.pipe(untilDestroyed(this)).subscribe((currency: Currency) => {
      this.currency = currency;
      this.globalDataset.currency = currency;
      this.rowDataset.currency = currency;
    });
    this.userSelectionService.periodComparison$.pipe(untilDestroyed(this)).subscribe((periodComparison) => {
      this.previousDateRange = periodComparison?.period;
    });

    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.locale = user.locale;
      this.globalDataset.locale = user.locale;
      this.rowDataset.locale = user.locale;
      this.uiVersion = user.uiVersion;
    });
    // get all marketplaces
    const accountMarketplaces$ = this.organizationAccountGroupService.allOrganizationAccountGroups$.pipe(
      map((organizations) => {
        const accountMarketplaces = new Array<AccountMarketplace & { organizationName: string }>();
        for (const organization of organizations ?? []) {
          for (const accountGroup of organization.accountGroups) {
            for (const accountMarketplace of accountGroup.getAccountMarketplaces()) {
              if (
                accountMarketplace.hasAccessToAdvertising &&
                (accountMarketplace.state == AccountState.BIDDER_ON ||
                  accountMarketplace.state == AccountState.DOWNLOADER_ON)
              ) {
                accountMarketplaces.push({
                  resourceOrganizationId: accountMarketplace.resourceOrganizationId,
                  accountId: accountMarketplace.accountId,
                  marketplace: accountMarketplace.marketplace,
                  accountName: accountMarketplace.accountName,
                  accountGroupName: accountMarketplace.accountGroupName,
                  state: accountMarketplace.state,
                  accountType: accountMarketplace.accountType,
                  useSourcingMetrics: accountMarketplace.useSourcingMetrics,
                  organizationName: organization.organizationName,
                });
              }
            }
          }
        }
        return accountMarketplaces;
      }),
      shareReplay(1),
    );

    // get all data
    const allData$ = combineLatest([this.userSelectionService.dateRange$, accountMarketplaces$]).pipe(
      switchMap(([dateRange, accountMarketplaces]) => {
        this.minDate = dateRange[0];
        this.maxDate = dateRange[1];
        this.agGrid?.api.showLoadingOverlay();
        return combineLatest([
          this.statsApiClient.getAggregatedAdvertisingStats(dateRange[0], dateRange[1], accountMarketplaces),
          this.statsApiClient.getAggregatedAllSales(dateRange[0], dateRange[1], accountMarketplaces),
          this.statsApiClient.getAggregatedVendorSales(dateRange[0], dateRange[1], accountMarketplaces),
          this.userSelectionService.selectedCurrency$,
        ]).pipe(
          map(([advStats, allSales, vendorSales, currency]) => {
            convertToCurrency(advStats, currency);
            convertToCurrency(allSales, currency);
            convertToCurrency(vendorSales, currency);
            return {
              advStats,
              allSales,
              vendorSales,
              accountMarketplaces,
              dateRange,
            };
          }),
        );
      }),
      shareReplay(1),
    );
    // get all previous data
    const allPreviousData$ = combineLatest([this.userSelectionService.periodComparison$, accountMarketplaces$]).pipe(
      switchMap(([periodComparison, accountMarketplaces]) => {
        if (!periodComparison?.period) {
          return of({
            previousAdvStats: [] as AdStatsEx[],
            previousAllSales: [] as AdStatsEx[],
            previousVendorSales: [] as AdStatsEx[],
          });
        }
        const dateRange = periodComparison?.period;
        return combineLatest([
          this.statsApiClient.getAggregatedAdvertisingStats(dateRange[0], dateRange[1], accountMarketplaces),
          this.statsApiClient.getAggregatedAllSales(dateRange[0], dateRange[1], accountMarketplaces),
          this.statsApiClient.getAggregatedVendorSales(dateRange[0], dateRange[1], accountMarketplaces),
          this.userSelectionService.selectedCurrency$,
        ]).pipe(
          map(([previousAdvStats, previousAllSales, previousVendorSales, currency]) => {
            convertToCurrency(previousAdvStats, currency);
            convertToCurrency(previousAllSales, currency);
            convertToCurrency(previousVendorSales, currency);
            return {
              previousAdvStats,
              previousAllSales,
              previousVendorSales,
            };
          }),
        );
      }),
      shareReplay(1),
    );

    combineLatest([allData$, allPreviousData$, this.userSelectionService.selectedOrganizations$])
      .pipe(untilDestroyed(this))
      .subscribe(
        ([
          { advStats, allSales, vendorSales, accountMarketplaces },
          { previousAdvStats, previousAllSales, previousVendorSales },
          organizations,
        ]) => {
          const accounts = new Set(
            organizations?.flatMap((o) =>
              o.accountGroups.flatMap((ag) => ag.getAccountMarketplaces()).map((a) => a.accountId),
            ),
          );
          const accountMarketplacesMap = new Map(accountMarketplaces.map((am) => [this.getKeyFromAdStat(am), am]));
          this.totalData = {};
          this.previousTotalData = {};
          const chartData: AdStatsEx[] = [];
          const previousChartData: AdStatsEx[] = [];
          for (const data of [advStats, vendorSales].flat()) {
            if (!accounts.has(data.accountId!)) {
              continue;
            }
            chartData.push(data);
            mergeSeveralDates(this.totalData, data);
          }
          // specific case for all sales
          for (const data of allSales) {
            if (!accounts.has(data.accountId!)) {
              continue;
            }
            if (accountMarketplacesMap.get(this.getKeyFromAdStat(data))?.accountType === AccountType.VENDOR) {
              continue;
            }
            mergeSeveralDates(this.totalData, data);
            chartData.push(data);
          }
          // previous data
          if (this.previousDateRange) {
            const dateInterval = Utils.getDateIntervalInDays([this.previousDateRange[0], this.minDate]);
            for (const data of [previousAdvStats, previousVendorSales].flat()) {
              if (!accounts.has(data.accountId!)) {
                continue;
              }
              alignDate(data, dateInterval);
              previousChartData.push(data);
              mergeSeveralDates(this.previousTotalData, data);
            }
            // specific case for all sales
            for (const data of previousAllSales) {
              if (!accounts.has(data.accountId!)) {
                continue;
              }
              if (accountMarketplacesMap.get(this.getKeyFromAdStat(data))?.accountType === AccountType.VENDOR) {
                continue;
              }
              alignDate(data, dateInterval);
              mergeSeveralDates(this.previousTotalData, data);
              previousChartData.push(data);
            }
          }
          this.globalChartData$.next({ data: chartData, previousData: previousChartData });
        },
      );

    this.gridData$ = allData$.pipe(
      map(({ advStats, allSales, vendorSales, accountMarketplaces }) => {
        const gridData = new Map<string, SuperBoardData>();
        for (const accountMarketplace of accountMarketplaces) {
          const key = this.getKeyFromAdStat(accountMarketplace);
          gridData.set(key, {
            rowId: key,
            organizationId: accountMarketplace.resourceOrganizationId!,
            organizationName: accountMarketplace.organizationName,
            accountName: accountMarketplace.accountName,
            accountId: accountMarketplace.accountId,
            marketplace: accountMarketplace.marketplace,
            accountGroupName: accountMarketplace.accountGroupName,
            accountState: accountMarketplace.state,
            accountType: accountMarketplace.accountType,
            vendorManufacturingAccess: accountMarketplace.vendorManufacturingAccess,
            dailyStats: [],
          });
        }
        for (const data of advStats) {
          const key = this.getKeyFromAdStat(data);
          const stats = gridData.get(key);
          if (!stats) continue;

          stats.dailyStats.push(data);
          mergeSeveralDates(stats, data);
        }
        for (const data of allSales) {
          const key = this.getKeyFromAdStat(data);
          const stats = gridData.get(key);
          if (!stats || stats.accountType === AccountType.VENDOR) {
            continue;
          }
          mergeSeveralDates(stats, data);
          stats.dailyStats.push(data);
        }
        for (const data of vendorSales) {
          const key = this.getKeyFromAdStat(data);
          const stats = gridData.get(key);
          if (!stats) continue;

          mergeSeveralDates(stats, data);
          stats.dailyStats.push(data);
        }
        return gridData;
      }),
      shareReplay(1),
    );

    allPreviousData$
      .pipe(untilDestroyed(this))
      .subscribe(({ previousAdvStats, previousAllSales, previousVendorSales }) => {
        const previousDataByAccount = new Map<
          string,
          { totalPreviousData: AdStatsEx; previousDailyStats: AdStatsEx[] }
        >();

        this.mergeStats(previousAdvStats, previousDataByAccount);
        this.mergeStats(previousAllSales, previousDataByAccount);
        this.mergeStats(previousVendorSales, previousDataByAccount);

        this.previousDataByAccount$.next(previousDataByAccount);
        this.agGrid?.api.redrawRows();
      });

    this.gridData$.pipe(untilDestroyed(this)).subscribe((gridData) => {
      this.agGrid?.api.setGridOption("rowData", Array.from(gridData.values()));
      this.agGrid?.api.redrawRows();
      this.agGrid?.api.hideOverlay();
    });

    combineLatest([this.globalChartData$, this.selectedMetrics$, this.dateAggregation$])
      .pipe(untilDestroyed(this))
      .subscribe(([{ data, previousData }, metrics, dateAggregation]) => {
        this.globalDataset.buildDataSet(
          data,
          metrics,
          dateAggregation,
          {
            minDate: this.minDate,
            maxDate: this.maxDate,
          },
          previousData.length > 0 ? { data: previousData, period: this.previousDateRange ?? [] } : undefined,
        );
      });
  }

  private mergeStats(
    previousAdvStats: AdStatsEx[],
    previousDataByAccount: Map<
      string,
      {
        totalPreviousData: AdStatsEx;
        previousDailyStats: AdStatsEx[];
      }
    >,
  ) {
    for (const data of previousAdvStats) {
      const key = this.getKeyFromAdStat(data);
      if (!previousDataByAccount.has(key)) {
        previousDataByAccount.set(key, { totalPreviousData: {}, previousDailyStats: [] });
      }

      const stats = previousDataByAccount.get(key)!;
      stats.previousDailyStats.push(data);
      mergeSeveralDates(stats.totalPreviousData, data);
    }
  }

  private getKeyFromAdStat(data: AdStatsEx | AccountMarketplace) {
    return data.accountId + "_" + data.marketplace;
  }

  private getKeyFromIRowNode(row: IRowNode<AdStatsEx | AccountMarketplace>) {
    return this.getKeyFromAdStat(row.data!);
  }

  private getPreviousNodeData(node: IRowNode): AdStatsEx | undefined {
    if (this.previousDataByAccount$.value.size === 0) {
      return undefined;
    }
    const rowsToProcess: IRowNode[] = getFilteredLeafNodes(node);
    const previousData: AdStatsEx = {};
    rowsToProcess.forEach((n: IRowNode) => {
      const previousNodeData = this.previousDataByAccount$.value.get(this.getKeyFromIRowNode(n));
      if (previousNodeData) mergeSeveralDates(previousData, previousNodeData.totalPreviousData);
    });
    return previousData;
  }

  displayRowChart(row: IRowNode<SuperBoardData>) {
    const rowsToProcess: IRowNode[] = getFilteredLeafNodes(row, this.agGrid?.api);
    const chartData$ = combineLatest([this.gridData$, this.previousDataByAccount$]).pipe(
      map(([gridData, previousDataByAccount]) => {
        const rowsData = rowsToProcess.map((row) => gridData.get(this.getKeyFromIRowNode(row))!);
        const totalData = rowsData.reduce((prev, curr) => mergeSeveralDates(prev, curr), {});
        const data = rowsData.flatMap((row) => row.dailyStats);
        const previousData = this.previousDateRange
          ? rowsData.flatMap((row) => previousDataByAccount.get(this.getKeyFromAdStat(row))?.previousDailyStats)
          : undefined;
        const totalPreviousData = this.previousDateRange
          ? rowsData.reduce((prev, curr) => {
              const previousData = previousDataByAccount.get(this.getKeyFromAdStat(curr));
              if (previousData) {
                mergeSeveralDates(prev, previousData.totalPreviousData);
              }
              return prev;
            }, {})
          : undefined;
        return {
          totalData,
          data,
          previousData,
          totalPreviousData,
        };
      }),
    );
    const title = row.isRowPinned()
      ? "Total"
      : row.data
        ? `${row.data.accountName} (${row.data.marketplace})`
        : row.key;
    let withEventAnnotations = false;
    let annotations$: Observable<DataSetEventAnnotation[]> = of([]);
    if (rowsToProcess.length === 1) {
      withEventAnnotations = true;
      annotations$ = this.activityService.getStrategiesActivityEventAnnotation(
        rowsToProcess[0].data.accountId,
        rowsToProcess[0].data.marketplace,
      );
    }
    const opts: ModalOptions = {
      initialState: {
        title: title,
        dataset: this.rowDataset,
        metrics: this.METRICS,
        minDate: this.minDate,
        maxDate: this.maxDate,
        localStorageKey: MetricsSelectorLocalStorageKey.superBoard,
        chartData$,
        withEventAnnotations,
        annotations$,
      },
      class: "modal-xxl modal-dialog-centered",
    };
    this.modalService.show(ChartRendererComponent, opts);
    this.agGrid.api.hideOverlay();
  }

  selectMetrics(metrics: Metric<AdStatsEx>[]) {
    this.selectedMetrics$.next(metrics);
  }

  restoreDefaultColumns() {
    this.agGrid.api?.resetColumnState();
    this.agGrid.api?.autoSizeAllColumns();
  }

  exportGridCsv() {
    const fileName = getCsvFileName("global_stats_", "", "", [this.minDate, this.maxDate]);
    const columnKeys: string[] = this.agGrid?.api
      .getAllDisplayedColumns()
      .map((c) => c.getColId())
      .filter((c) => !c.startsWith(ACTIONS_COL_ID))
      .concat(["currency"]);

    exportGridCsv(this.agGrid?.api, { fileName, columnKeys, skipColumnGroupHeaders: true });
  }

  selectAggregation(aggregation: DateAggregation) {
    this.dateAggregation$.next(aggregation);
  }

  toggleGlobalChartDisplay(isGlobalChartHidden: boolean) {
    this.isGlobalChartHidden = isGlobalChartHidden;
    this.userSelectionService.setUserChartDisplayedPreference(this.localStorageKey, !this.isGlobalChartHidden);
  }
}
