import { Clipboard } from "@angular/cdk/clipboard";
import { Component, Input } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { AuthService, Marketplace } from "@front/m19-services";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";
import { ICON_COPY_O } from "@m19-board/utils/iconsLabels";
import { ToastrService } from "ngx-toastr";

@UntilDestroy()
@Component({
  selector: "app-asin-link",
  templateUrl: "asin-link.component.html",
  styleUrls: ["./asin-link.component.scss"],
})
export class AsinLinkComponent implements ICellRendererAngularComp {
  readonly ICON_COPY_O = ICON_COPY_O;
  uiVersion = 0;
  prefix = "";

  @Input() asin: string;
  @Input() marketplace: string;
  @Input() tooltip: string;

  constructor(
    private toasterService: ToastrService,
    private clipboard: Clipboard,
    private authService: AuthService,
  ) {
    this.authService.loggedUser$.subscribe((user) => {
      this.uiVersion = user?.uiVersion ?? 0;
      if (this.uiVersion > 0) {
        this.prefix = "/product-center";
      }
    });
  }

  agInit(
    params: ICellRendererParams & {
      asin: string;
      marketplace: Marketplace;
    },
  ): void {
    this.marketplace = params.marketplace;
    this.asin = params.asin;
  }

  refresh(): boolean {
    return false;
  }

  copyToClipboard(str: string) {
    this.clipboard.copy(str);
    this.toasterService.success(`Copied ${str} to clipboard`);
  }
}
