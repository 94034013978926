import { Component, Directive, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  AccountMarketplace,
  AccountSubType,
  AccountType,
  buildProductEx,
  CampaignType,
  getAmazonProductUrl,
  M19Status,
  NotificationBidderIssueEx,
  NotificationEx,
  NotificationService,
  OrganizationAccountGroupService,
  Strategy,
  StrategyEx,
  StrategyStateEnum,
  Utils,
} from "@front/m19-services";
import { AccountSettingsService } from "@m19-board/settings/account-settings.service";
import { StrategyCache } from "libs/m19-services/src/lib/m19-services/strategy.cache";
import { take } from "rxjs";

type StrategyOverlap = {
  strategies?: number[];
  asins?: string[];
};

type CampaignOverlap = {
  campaigns?: string[];
  asins?: string[];
};

const EmptyStrategy = new StrategyEx(
  {
    accountId: "",
    marketplace: null,
    state: StrategyStateEnum.ENABLED,
    campaignType: null,
    algoMode: null,
    defaultStrategy: false,
    tactics: [],
    audienceTargetings: [],
  },
  new Map(),
  new Map(),
  new Map(),
);

export function getStrategyLink(strategy: Strategy): string {
  if (!strategy) return "/";

  let urlPrefix = "";
  switch (strategy.campaignType) {
    case CampaignType.SP:
      if (strategy.strategyGroupId) {
        urlPrefix = "strategies/strategy-group/sponsored-product/" + strategy.strategyGroupId;
      } else {
        urlPrefix = "strategies/sponsored-product";
      }
      break;
    case CampaignType.SD:
      urlPrefix = "strategies/sponsored-display";
      break;
    default:
      urlPrefix = "strategies/sponsored-brands";
      break;
  }
  return `/${urlPrefix}/${strategy.strategyId}`;
}

function getAsinLink(asin: string): string {
  const product = buildProductEx({
    asin: asin,
    marketplace: this.notification.marketplace,
  } as any);

  return getAmazonProductUrl(product);
}

@Directive()
class NotificationCard {
  @Input()
  notification: NotificationEx;

  @Input()
  small = false;
}

@UntilDestroy()
@Component({
  selector: "app-notification-card-invalid-selling-partner",
  templateUrl: "./notification-card-selling-partner.component.html",
})
export class NotificationInvalidSellingPartnerAccessComponent extends NotificationCard implements OnInit {
  account: AccountMarketplace;

  constructor(
    private accountGroupService: OrganizationAccountGroupService,
    private accountSettingsService: AccountSettingsService,
  ) {
    super();
  }

  logInPlaceForAPIGrantAccess() {
    return this.account.accountType == AccountType.SELLER ? "Seller Central" : "Vendor Central";
  }

  ngOnInit(): void {
    this.accountGroupService.allOrganizationAccountGroups$.pipe(untilDestroyed(this)).subscribe((organizations) => {
      const accountGroups = organizations.flatMap((o) => o.accountGroups);
      for (const accountGroup of accountGroups) {
        const account = accountGroup
          .getAccountMarketplaces()
          .find((account) => account.accountId == this.notification.accountId);
        if (account) {
          this.account = account;
          break;
        }
      }
    });
  }

  requestGrant(): void {
    this.accountSettingsService.grantSellingPartnerAccess(
      this.account.accountId,
      this.account.marketplace,
      this.account.accountType,
      this.account.accountSubType == AccountSubType.KDP_AUTHOR,
    );
  }
}

@UntilDestroy()
@Component({
  selector: "app-notification-card-campaign-overlap",
  templateUrl: "./notification-card-campaign-overlap.component.html",
})
export class NotificationCardCampaignOverlapComponent extends NotificationCard implements OnInit {
  strategy: StrategyEx = EmptyStrategy;
  overlappingCampaigns: string[] = [];
  asins: string[] = [];

  private json: CampaignOverlap = {};

  constructor(private strategyCache: StrategyCache) {
    super();
  }

  ngOnInit(): void {
    if (this.notification && this.notification.message) {
      try {
        this.json = JSON.parse(this.notification.message);
      } catch {
        this.json = {};
      }
      this.asins = this.json.asins ?? [];
      this.overlappingCampaigns = this.json.campaigns ?? [];
    }
    this.strategyCache.strategyIndex$.pipe(untilDestroyed(this)).subscribe((strategyIndex) => {
      this.strategy = strategyIndex.get(this.notification.strategyId) ?? EmptyStrategy;
    });
  }

  getStrategyLink = getStrategyLink;

  getAsinLink = getAsinLink;
}

@UntilDestroy()
@Component({
  selector: "app-notification-card-strategy-overlap",
  templateUrl: "./notification-card-strategy-overlap.component.html",
})
export class NotificationCardStrategyOverlapComponent extends NotificationCard implements OnInit {
  strategy: StrategyEx = EmptyStrategy;
  overlappingStrategies: StrategyEx[] = [];
  asins: string[] = [];

  private json: StrategyOverlap = {};

  constructor(
    private strategyCache: StrategyCache,
    private notificationService: NotificationService,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.notification && this.notification.message) {
      try {
        this.json = JSON.parse(this.notification.message);
      } catch {
        this.json = {};
      }
      this.asins = this.json.asins ?? [];
    }
    this.strategyCache.strategyIndex$.pipe(untilDestroyed(this)).subscribe((strategyIndex) => {
      this.strategy = strategyIndex.get(this.notification.strategyId) ?? EmptyStrategy;
      this.overlappingStrategies = [];
      if (this.json.strategies) {
        this.overlappingStrategies = this.json.strategies.map((s) => strategyIndex.get(s)).filter((s) => s);

        // check if it's still true
        const asinSet = new Set<string>();
        let valid = false;
        this.overlappingStrategies.forEach((strat) => {
          strat.asins.forEach((asin) => {
            if (asinSet.has(asin.asin)) valid = true;

            asinSet.add(asin.asin);
          });
        });
        if (!valid) {
          this.notificationService.deleteNotification(
            this.notification.accountId,
            this.notification.type,
            this.notification.marketplace,
            this.notification.strategyId,
          );
        }
      }
    });
  }

  getStrategyLink = getStrategyLink;

  getAsinLink = getAsinLink;
}

@UntilDestroy()
@Component({
  selector: "app-notification-card-deleted-brand-asset",
  templateUrl: "./notification-card-deleted-brand-asset.component.html",
})
export class NotificationCardDeletedBrandAssetComponent extends NotificationCard implements OnInit {
  strategy: StrategyEx = EmptyStrategy;

  constructor(private strategyCache: StrategyCache) {
    super();
  }

  ngOnInit(): void {
    this.strategyCache
      .getStrategyById(this.notification.strategyId)
      .pipe(take(1))
      .subscribe((s) => (this.strategy = s));
  }
}

@UntilDestroy()
@Component({
  selector: "app-notification-card-bidder-issue",
  templateUrl: "./notification-card-bidder-issue.component.html",
})
export class NotificationCardBidderIssueComponent extends NotificationCard implements OnInit {
  strategy: StrategyEx = EmptyStrategy;
  notif: NotificationBidderIssueEx;
  reasons = "";
  nb = "some";
  length = 0;
  isCreativeIssue = false;

  constructor(private strategyCache: StrategyCache) {
    super();
  }

  ngOnInit(): void {
    this.strategyCache
      .getStrategyById(this.notification.strategyId)
      .pipe(take(1))
      .subscribe((s) => (this.strategy = s));
    this.notif = this.notification as NotificationBidderIssueEx;
    this.isCreativeIssue = this.notif.warningType === M19Status.IGNORED_REJECTED;
    if (this.isCreativeIssue) {
      this.reasons = Utils.m19StatusToReadableStr(this.notif.warningType);
      this.length = this.notif.moderationDetails ? this.notif.moderationDetails.length : 0;
      this.nb = this.length > 0 ? "" + this.length : "some";
    } else {
      this.reasons = (this.notification as NotificationBidderIssueEx).warningMessage;
    }
  }

  getStrategyLink = getStrategyLink;
}
