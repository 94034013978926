import { Component, OnInit, signal, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
  AccessLevel,
  DataSharingService,
  Marketplace,
  OrganizationAccountGroups,
  OrganizationAccountGroupService,
  SharedReportEx,
  SharedReportType,
  SharedReportTypeEx,
  SharedReportTypes,
  UserSelectionService,
} from "@front/m19-services";
import { Option } from "@front/m19-ui";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { saveAs } from "file-saver";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, combineLatest, filter, Subject, switchMap, tap } from "rxjs";
type ReportFilter = {
  marketplace?: Marketplace;
  reportType?: SharedReportTypeEx;
};

@UntilDestroy()
@Component({
  selector: "app-data-sharing",
  templateUrl: "./data-sharing.component.html",
  styleUrls: ["./data-sharing.component.scss"],
})
export class DataSharingComponent implements OnInit {
  readonly DisplayedColumns = ["reportType", "marketplace", "date", "downloadLink"];
  organizations: OrganizationAccountGroups[];

  private readonly selectedOrganization$ = new Subject<OrganizationAccountGroups>();
  private readonly reportFilter$ = new BehaviorSubject<ReportFilter>({});
  sharedReports: SharedReportEx[] = [];
  readonly sharedReportTypes = new Map<SharedReportTypeEx, number>();
  readonly marketplaces = new Map<Marketplace, number>();
  readonly AccessLevel = AccessLevel;

  readonly dataSource = new MatTableDataSource<SharedReportEx>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  private sort: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = ms;
  }

  reportTypeFilter: SharedReportTypeEx;
  marketplaceFilter: Marketplace;

  organizationOptions: Option<OrganizationAccountGroups>[] = [];
  selectedOrganization = signal<Option<OrganizationAccountGroups> | undefined>(undefined);

  reportTypeOptions: Option<SharedReportType | null>[] = [];
  selectedReportType = signal<Option<SharedReportType | null> | undefined>(undefined);

  marketplaceOptions: Option<Marketplace>[] = [];
  selectedMarketplace = signal<Option<Marketplace> | undefined>(undefined);

  constructor(
    private accountGroupService: OrganizationAccountGroupService,
    private dataSharingService: DataSharingService,
    private userSelectionService: UserSelectionService,
    private toastrService: ToastrService,
  ) {}

  ngOnInit(): void {
    this.reportTypeOptions = [{ value: null, label: "All reports" }];
    for (const k in SharedReportTypes) {
      this.reportTypeOptions.push({ value: SharedReportTypes[k], label: SharedReportTypes[k].displayName });
    }
    this.selectedReportType.set(this.reportTypeOptions[0]);

    this.accountGroupService.allOrganizationAccountGroups$.pipe(untilDestroyed(this)).subscribe((organizations) => {
      this.organizations = organizations;
      this.organizations.sort((a, b) => (a.datahubAccess == b.datahubAccess ? a.id - b.id : a.datahubAccess ? -1 : 1));

      this.organizationOptions = organizations.map((o) => ({
        value: o,
        label: o.organizationName,
        disabled: o.accessLevel !== AccessLevel.ADMIN,
      }));
    });
    this.selectedOrganization$.pipe(untilDestroyed(this)).subscribe((o) => {
      this.selectedOrganization.set({ value: o, label: o.organizationName });
    });
    this.selectedOrganization$
      .pipe(
        tap(() => {
          this.sharedReports = [];
          this.sharedReportTypes.clear();
          this.marketplaces.clear();
          this.dataSource.data = [];
          this.reportFilter$.next({});
        }),
        untilDestroyed(this),
        filter((o) => o.datahubAccess && o.accessLevel == AccessLevel.ADMIN),
        switchMap((o) =>
          combineLatest<[SharedReportEx[], ReportFilter, string[]]>([
            this.dataSharingService.listSharedReports(o.id).pipe(
              tap((r) => {
                for (const report of r) {
                  const n = this.sharedReportTypes.get(report.reportTypeEx) ?? 0;
                  this.sharedReportTypes.set(report.reportTypeEx, n + 1);
                  const m = this.marketplaces.get(report.marketplace) ?? 0;
                  this.marketplaces.set(report.marketplace, m + 1);
                }

                this.marketplaceOptions = [{ value: null, label: "All marketplaces" }];
                for (const [k, v] of this.marketplaces) {
                  this.marketplaceOptions.push({ value: k, label: k });
                }
                this.selectedMarketplace.set(this.marketplaceOptions[0]);
              }),
            ),
            this.reportFilter$,
            this.userSelectionService.dateRange$,
          ]),
        ),
      )
      .subscribe({
        next: ([r, reportFilter, dateRange]) => {
          this.sharedReports = r;
          this.dataSource.data = this.filterData(r, reportFilter, dateRange);
        },
        error: (e) => {
          this.toastrService.error(e, "Shared reports");
        },
      });
    this.reportFilter$.pipe(untilDestroyed(this)).subscribe((reportFilter) => {
      this.reportTypeFilter = reportFilter.reportType;
      this.marketplaceFilter = reportFilter.marketplace;
    });
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (item, property): string | number => {
      if (property == "reportType") {
        return item.reportTypeEx.displayName;
      }
      if (property == "marketplace") {
        return item.marketplace;
      }
      if (property == "date") {
        return item.date;
      }
      return 0;
    };
  }

  private filterData(reports: SharedReportEx[], filter: ReportFilter, dateRange: string[]) {
    const result = [];
    for (const report of reports) {
      // Because of stats, maxDatePicker is upperbound to yesterday, if max date is yesterday, also include today
      const maxDatePickerDate = new Date();
      maxDatePickerDate.setDate(maxDatePickerDate.getDate() - 1);
      let maxDate = dateRange[1];
      if (maxDate == maxDatePickerDate.toISOString().substring(0, 10)) {
        maxDatePickerDate.setDate(maxDatePickerDate.getDate() + 1);
        maxDate = maxDatePickerDate.toISOString().substring(0, 10);
      }
      if (report.date.localeCompare(dateRange[0]) < 0 || report.date.localeCompare(maxDate) > 0) {
        continue;
      }
      if (filter.marketplace && report.marketplace != filter.marketplace) {
        continue;
      }
      if (filter.reportType && report.reportTypeEx != filter.reportType) {
        continue;
      }
      result.push(report);
    }
    return result;
  }

  selectOrganization(organization: Option<OrganizationAccountGroups>) {
    this.selectedOrganization.set(organization);
    this.selectedOrganization$.next(organization.value);
  }

  selectReportTypeFilter(reportType: Option<SharedReportType | null>) {
    this.selectedReportType.set(reportType);
    const reportTypeEx = reportType.value as unknown as SharedReportTypeEx;
    this.reportFilter$.next({ marketplace: this.marketplaceFilter, reportType: reportTypeEx });
  }

  selectMarketplaceFilter(marketplace: Option<Marketplace>) {
    this.selectedMarketplace.set(marketplace);
    this.reportFilter$.next({ marketplace: marketplace.value, reportType: this.reportTypeFilter });
  }

  downloadReport(report: SharedReportEx) {
    this.dataSharingService
      .downloadReport(report)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data: Blob) => {
          saveAs(data, report.fileName);
        },
        error: (e) => {
          this.toastrService.error(e, "Report download");
        },
      });
  }
}
