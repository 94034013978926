import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  AccountMarketplace,
  AccountSelectionService,
  AsinService,
  CampaignType,
  Catalog,
  ConfigService,
  Marketplace,
  Strategy,
  StrategyAsin,
  StrategyEx,
  StrategyGroupEx,
  StrategyStateEnum,
  Utils,
} from "@front/m19-services";
import { formControlNames } from "@m19-board/sponsored-display/sd-manager/sd-strategy-creation/sd-strategy-creation.component";
import {
  AlgoModeConfig,
  AlgoModeConfigValid,
} from "@m19-board/strategies/strategies/algo-mode-selection/algo-mode-selection.component";
import { ICON_ARROW_LEFT } from "@m19-board/utils/iconsLabels";
import { ToastrService } from "ngx-toastr";
import { switchMap } from "rxjs";

@UntilDestroy()
@Component({
  selector: "app-sp-strategy-creation",
  templateUrl: "./sp-strategy-creation.component.html",
  styleUrls: ["../../../strategies/strategies/strategy-styles.scss"],
})
export class SpStrategyCreationComponent implements OnInit {
  @Input() set asins(asins: StrategyAsin[]) {
    if (asins && asins.length) this.spForm.controls.asins.setValue(asins);
  }
  @Input()
  backToStratLink: string = "/strategies/sponsored-product";

  readonly ICON_ARROW = ICON_ARROW_LEFT;

  invalidFields: string[] = [];
  asinsList: StrategyAsin[] = [];
  formSubmitted: boolean;
  submitOngoing = false;
  accountId: string;
  accountGroupName: string;
  marketplace: Marketplace;
  asinEligibility: Map<string, { status: boolean; reason: string }> = new Map();

  @Output()
  public strategyCreated = new EventEmitter<StrategyEx>();
  @Output()
  public strategyGroupCreated = new EventEmitter<StrategyGroupEx>();

  spForm = new FormGroup({
    strategyName: new FormControl<string>(Utils.generateRandomName(), [Validators.required, Validators.maxLength(80)]),
    asins: new FormControl<StrategyAsin[]>([], [this.asinListValidator]),
    algoModeConfig: new FormControl<AlgoModeConfig>(undefined, [Validators.required]),
  });

  constructor(
    private accountSelection: AccountSelectionService,
    private asinService: AsinService,
    public configurationService: ConfigService,
    private toastrService: ToastrService,
  ) {}

  ngOnInit(): void {
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(untilDestroyed(this))
      .subscribe((am: AccountMarketplace) => {
        this.accountId = am.accountId;
        this.accountGroupName = am.accountGroupName;
        this.marketplace = am.marketplace;
      });
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am: AccountMarketplace) => this.asinService.getCatalog(am.accountId, am.marketplace)),
        untilDestroyed(this),
      )
      .subscribe((catalog: Catalog) => {
        this.asinEligibility = catalog.getSPEligibility();
      });
  }

  submitForm() {
    // prevent strategy creation on double click
    if (this.submitOngoing) {
      return;
    }
    this.formSubmitted = true;

    if (this.spForm.invalid) {
      this.invalidFields = this.getInvalidOrMissingFields();
      return;
    }

    this.submitOngoing = true;
    this.formSubmitted = false;
    const limitReached = this.configurationService.isStrategyLimitReached(CampaignType.SP);

    const strategy: Strategy = {
      accountId: this.accountId,
      marketplace: this.marketplace,
      state: limitReached ? StrategyStateEnum.PAUSED : StrategyStateEnum.ENABLED,
      campaignType: CampaignType.SP,
      defaultStrategy: false,
      tactics: [],
      audienceTargetings: [],
      name: this.spForm.controls.strategyName.value,
      asins: this.spForm.controls.asins.value,
      ...this.spForm.controls.algoModeConfig.value,
      acosTarget: this.spForm.controls.algoModeConfig.value?.acosTarget
        ? this.spForm.controls.algoModeConfig.value.acosTarget / 100
        : undefined,
    };
    const sub = this.configurationService
      .createStrategyGroup(
        {
          accountId: this.accountId,
          marketplace: this.marketplace,
          strategyGroupName: strategy.name,
        },
        strategy,
      )
      .subscribe({
        next: (strategyGroup) => {
          this.toastrService.success("Strategy Group created successfully", "Strategy Group created");
          this.strategyGroupCreated.emit(strategyGroup);
          this.submitOngoing = false;
          sub.unsubscribe();
        },
        error: (e: string) => {
          this.toastrService.error(e, "Strategy Group creation error");
          this.submitOngoing = false;
          sub.unsubscribe();
        },
      });
  }

  setAlgoModeConfig(algoModeConfig: Partial<AlgoModeConfig>) {
    this.spForm.controls.algoModeConfig.setValue(algoModeConfig as AlgoModeConfig);
  }

  setAlgoModeConfigValid(valid: AlgoModeConfigValid) {
    this.spForm.controls.algoModeConfig.setErrors(valid.errors);
  }

  addStrategyAsins(asins: StrategyAsin[]) {
    const newList = this.spForm.controls.asins.value.concat(asins);
    this.spForm.controls.asins.setValue(newList);
  }

  deleteStrategyAsins(asins: StrategyAsin[]) {
    const newList = this.spForm.controls.asins.value.filter((a) => !asins.map((a2) => a2.asin).includes(a.asin));
    this.spForm.controls.asins.setValue(newList);
  }

  getInvalidOrMissingFields() {
    const res = [];
    const keys = Object.keys(this.spForm.controls);

    for (const c of keys) {
      if (this.spForm.controls[c].invalid) {
        if (c === "asins") continue;
        res.push(formControlNames.get(c));
      }
    }

    return res;
  }

  private asinListValidator(control: AbstractControl) {
    if (!control.value || !control.value.length) return { missingAsins: true };
    return null;
  }
}
