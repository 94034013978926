<div class="flex flex-col" *transloco="let t">
  <ul class="nav-wrapper">
    <li class="nav-element" [class.active]="(segmentType$ | async) === SegmentType.KeywordSegment">
      <a class="nav-link x-1 mt-1" (click)="toggleSegmentType(SegmentType.KeywordSegment)">
        {{ t("add-keyword-tracking-modal.keyword_segments") }}
      </a>
    </li>
    <li
      class="nav-element"
      data-testid="product-segment-btn"
      [class.active]="(segmentType$ | async) === SegmentType.ProductSegment"
    >
      <a class="nav-link x-1 mt-1" (click)="toggleSegmentType(SegmentType.ProductSegment)">
        {{ t("add-product-tracking-modal.product_segments") }}
      </a>
    </li>
  </ul>
  <app-stats-overlay>
    <div class="my-3 flex justify-between">
      <div class="w-30">
        <div class="input-group">
          <input
            type="text"
            class="form-control simple-input"
            (input)="setSegmentFilter(getValueFromInputEvent($event))"
            placeholder="{{
              (segmentType$ | async) === SegmentType.KeywordSegment
                ? t('segment-manager.search_by_name_or_keyword')
                : t('segment-manager.search_by_name_or_asin')
            }}"
          />
        </div>
      </div>
      <div class="flex items-center gap-2">
        <IButton
          [disabled]="isReadOnly"
          [icon]="ICON_ADD"
          (onClick)="createNewSegment()"
          color="main"
          label="{{
            (segmentType$ | async) === SegmentType.KeywordSegment
              ? t('segment-manager.create_new_keyword_segment')
              : t('segment-manager.create_new_product_segment')
          }}"
        ></IButton>
        <app-export-button
          (export)="downloadCsv()"
          tooltipValue="{{ t('segment-manager.export_segment_list_as_csv') }}"
        ></app-export-button>
      </div>
    </div>
    <div class="table-container">
      <mat-table
        [dataSource]="dataSource"
        matSort
        class="responsive-table table-striped-two table-hover"
        multiTemplateDataRows
      >
        <ng-container matColumnDef="selection">
          <mat-header-cell *matHeaderCellDef class="flex-half flex flex-row items-center justify-between">
            <span
              class="select-button-box"
              matTooltip="{{
                allSelected() ? t('segment-manager.unselect_all_segments') : t('segment-manager.select_all_segments')
              }}"
            >
              <fa-icon
                [icon]="allSelected() ? faCheckedSquare : faSquare"
                size="lg"
                (click)="!isReadOnly && toggleSelectAll()"
              ></fa-icon>
            </span>
            @if (nbSelected() > 0) {
              <span
                class="delete-content"
                role="button"
                (click)="deleteSelectedSegments()"
                matTooltip="{{ t('segment-manager.delete_segments', [nbSelected()]) }}"
              >
                <fa-icon [icon]="faTrash"></fa-icon>
                ({{ allSelected() ? t("common.all") : nbSelected() }})
              </span>
            }
          </mat-header-cell>
          <mat-cell *matCellDef="let segment" class="flex-half flex flex-row justify-between">
            <span class="select-button-box" matTooltip="{{ t('segment-manager.select_segment', [segment.name]) }}">
              <fa-icon
                [icon]="selected(segment) ? faCheckedSquare : faSquare"
                size="lg"
                (click)="!isReadOnly && select(segment)"
              ></fa-icon>
            </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="segmentName">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="flex-3"
            >{{ t("segment-manager.segment_name") }}
          </mat-header-cell>
          <mat-cell *matCellDef="let segment" class="flex-3">
            <button
              class="btn btn-ghost-secondary btn-sm icon-right"
              matTooltip="{{ t('segment-manager.change_segment_name') }}"
              (click)="editSegmentName(segment)"
            >
              {{ segment.name }}
              <fa-icon class="ml-2" [icon]="faPencil"></fa-icon>
            </button>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="strategies">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            matTooltip="{{ t('common.startegies') }}"
            matTooltipPosition="above"
            class="items-center"
            >{{ t("common.startegies") }}
          </mat-header-cell>
          <mat-cell *matCellDef="let segment">
            <div
              (click)="$event.stopPropagation(); showActiveStrategies(segment)"
              [ngClass]="getActiveStrategies(segment) > 0 ? 'positive' : 'zero'"
              data-toggle="tooltip"
              data-placement="bottom"
              class="number pointer mx-auto"
              matTooltip="{{ t('segment-manager.number_active_stategy', { count: getActiveStrategies(segment) }) }}"
            >
              {{ getActiveStrategies(segment) }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="keywordsNb">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ t("common.keyword") }}</mat-header-cell>
          <mat-cell *matCellDef="let segment"> {{ segment.items.length }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="keywords">
          <mat-header-cell *matHeaderCellDef class="flex-3"></mat-header-cell>
          <mat-cell
            *matCellDef="let segment"
            (click)="segment.items.length > 5 && toggleSegmentExpansion(segment)"
            class="flex-3"
          >
            @if (!expanded(segment)) {
              <div>
                @for (item of segment.items.slice(0, 5); track item; let last = $last) {
                  <span>
                    <a
                      class="text-color"
                      [href]="item.targetingValue | amazonSearchUrlPipe: segment.marketplace"
                      target="_blank"
                      (click)="$event.stopPropagation()"
                      >{{ item.targetingValue }}</a
                    >{{ last ? "" : ", " }}</span
                  >
                }
                @if (segment.items.length > 5) {
                  <button
                    class="btn btn-ghost-secondary btn-sm"
                    matTooltip="{{ t('common.see_more') }}"
                    (click)="toggleSegmentExpansion(segment); $event.stopPropagation()"
                  >
                    …
                  </button>
                }
              </div>
            } @else {
              <div class="flex items-end justify-between">
                <div>
                  @for (item of segment.items; track item; let last = $last) {
                    <span>
                      <a
                        class="text-color"
                        [href]="item.targetingValue | amazonSearchUrlPipe: segment.marketplace"
                        target="_blank"
                        (click)="$event.stopPropagation()"
                        >{{ item.targetingValue }}</a
                      >{{ last ? "" : ", " }}</span
                    >
                  }
                </div>
                <button
                  class="btn btn-ghost-secondary btn-sm"
                  matTooltip="{{ t('segment-manager.hide_details') }}"
                  (click)="toggleSegmentExpansion(segment); $event.stopPropagation()"
                >
                  <fa-icon [icon]="faAngleUp"></fa-icon>
                </button>
              </div>
            }
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="productsNb">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="flex-half"
            >{{ t("common.products") }}
          </mat-header-cell>
          <mat-cell *matCellDef="let segment" class="flex-half"> {{ segment.items.length }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="products">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="flex-3"></mat-header-cell>
          <mat-cell
            *matCellDef="let segment"
            class="flex-3"
            (click)="segment.items.length > 3 && toggleSegmentExpansion(segment)"
          >
            @if (!expanded(segment)) {
              <div>
                @for (item of segment.items.slice(0, 3); track item; let last = $last) {
                  <span
                    (mouseenter)="displayProductDetails(trigger, item.targetingValue)"
                    type="button"
                    cdkOverlayOrigin
                    #trigger="cdkOverlayOrigin"
                  >
                    <a
                      class="text-color"
                      [href]="item.targetingValue | amazonAsinUrlPipe: segment.marketplace"
                      target="_blank"
                      (click)="$event.stopPropagation()"
                      >{{ item.targetingValue }}</a
                    >{{ last ? "" : ", " }}</span
                  >
                }
                &nbsp;
                @if (segment.items.length > 3) {
                  <button
                    class="btn btn-ghost-secondary btn-sm"
                    matTooltip="{{ t('common.see_more') }}"
                    (click)="toggleSegmentExpansion(segment); $event.stopPropagation()"
                  >
                    …
                  </button>
                }
              </div>
            } @else {
              <div class="flex items-end justify-between">
                <div>
                  @for (item of segment.items; track item; let last = $last) {
                    <span
                      (mouseenter)="displayProductDetails(trigger, item.targetingValue)"
                      cdkOverlayOrigin
                      #trigger="cdkOverlayOrigin"
                    >
                      <a
                        class="text-color"
                        [href]="item.targetingValue | amazonAsinUrlPipe: segment.marketplace"
                        target="_blank"
                        (click)="$event.stopPropagation()"
                        >{{ item.targetingValue }}</a
                      >{{ last ? "" : ", " }}</span
                    >
                  }
                </div>
                <button
                  class="btn btn-ghost-secondary btn-sm pull-right ml-1"
                  matTooltip="{{ t('segment-manager.hide_details') }}"
                  (click)="toggleSegmentExpansion(segment); $event.stopPropagation()"
                >
                  <fa-icon [icon]="faAngleUp"></fa-icon>
                </button>
              </div>
            }
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef class="flex-2"></mat-header-cell>
          <mat-cell *matCellDef="let segment" class="flex-2 justify-end">
            @if (canSegmentBeDeleted(segment)) {
              <button
                [attr.data-testid]="'btn-delete-' + segment.name"
                class="btn btn-ghost-secondary"
                (click)="deleteSegment(segment)"
                matTooltip="{{ t('segment-manager.delete_segments', [segment.name]) }}"
                [disabled]="isReadOnly"
              >
                <fa-icon [icon]="faTrash" class="font-xl"></fa-icon>
              </button>
            }
            <button
              type="button"
              class="btn btn-ghost-secondary"
              (click)="editSegment(segment)"
              matTooltip="{{ t('segment-manager.edit_segment', [segment.name]) }}"
            >
              <fa-icon [icon]="faPencil" class="font-xl mx-1 align-middle"></fa-icon>
            </button>
            <button
              class="btn btn-ghost-secondary pull-right"
              matTooltip="{{ t('common.more_actions') }}"
              [matMenuTriggerFor]="menu"
            >
              <fa-icon [icon]="faEllipsis"></fa-icon>
            </button>
            <mat-menu #menu="matMenu" yPosition="above">
              @if (segment.segmentType === SegmentType.KeywordSegment && canAccessKeywordRanking()) {
                <button mat-menu-item (click)="viewKeywordTracker(segment)">
                  {{ t("segment-manager.see_in_keyword_tracker") }}
                </button>
              }
              @if (segment.segmentType === SegmentType.ProductSegment && canAccessKeywordRanking()) {
                <button mat-menu-item (click)="viewProductTracker(segment)">
                  {{ t("segment-manager.see_in_product_tracker") }}
                </button>
              }
              @if (segment.segmentType === SegmentType.KeywordSegment && canAccessKeywordRanking()) {
                <button mat-menu-item (click)="trackKeywords(segment)">
                  {{ t("segment-manager.track_keywords") }}
                </button>
              }
              @if (segment.segmentType === SegmentType.ProductSegment && canAccessKeywordRanking()) {
                <button mat-menu-item (click)="trackProducts(segment)">
                  {{ t("segment-manager.track_products") }}
                </button>
              }
              <button mat-menu-item (click)="viewTrafficAnalysis(segment)">
                {{ t("segment-manager.view_traffic_stats") }}
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
          class="header mat-header-row-no-space"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{ 'h-auto': expanded(row) }"></mat-row>
      </mat-table>
    </div>
    <mat-paginator
      [pageSizeOptions]="[5, 10, 50, 100]"
      [pageSize]="10"
      showFirstLastButtons
      style="margin-bottom: 2rem"
      [hidden]="!dataSource.data || dataSource.data.length === 0"
    ></mat-paginator>
  </app-stats-overlay>
</div>
<!--Product details overlay content connected to ASIN hover -->
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="productDetailsOrigin"
  [cdkConnectedOverlayOpen]="!!productDetailsAsin"
>
  <div class="product-tooltip border-shadow" #productDetailsPopup>
    <app-product-view [asin]="productDetailsAsin" [marketplace]="accountMarketplace.marketplace"></app-product-view>
  </div>
  <!--Product details overlay content connected to ASIN hover -->
</ng-template>
