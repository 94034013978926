import { BrandAsset, Marketplace, SbAsins, SbCreative, SbCreativeType, State, MediaType } from '../api-client';

export const BrandNameMaxLength = 30;
export const HeadlineMaxLength = 50;

export class SbCreativeEx implements SbCreative {
  creativeId: number;
  strategyId: number;
  accountId: string;
  marketplace: Marketplace;
  brandEntityId?: string;
  brandName: string;
  logoAssetId!: string;
  videoAssetId!: string;
  headline: string;
  storePageId?: string;
  customImageAssetId?: string;
  customImageAssetId2?: string;
  customImageAssetId3?: string;
  customImageAssetId4?: string;
  customImageAssetId5?: string;
  creativeAsins?: SbAsins[];
  state: State;
  creativeType: SbCreativeType;
  // Added Fields
  logoAsset?: BrandAsset;
  videoAsset?: BrandAsset;
  storePage?: BrandAsset;
  customImage?: BrandAsset;
  customImage2?: BrandAsset;
  customImage3?: BrandAsset;
  customImage4?: BrandAsset;
  customImage5?: BrandAsset;

  constructor(creative: SbCreative, brandAssetIndex: Map<string, BrandAsset[]>) {
    this.creativeId = creative.creativeId;
    this.strategyId = creative.strategyId;
    this.accountId = creative.accountId;
    this.marketplace = creative.marketplace;
    this.brandEntityId = creative.brandEntityId;
    this.brandName = creative.brandName;
    this.headline = creative.headline;
    this.storePageId = creative.storePageId;
    this.creativeAsins = creative.creativeAsins;
    this.state = creative.state;
    this.creativeType = creative.creativeType;
    // Added Fields
    switch (creative.creativeType) {
      case SbCreativeType.video:
        this.videoAssetId = creative.videoAssetId!;
        break;
      case SbCreativeType.productCollection:
      case SbCreativeType.storeSpotlight:
        this.logoAssetId = creative.logoAssetId!;
        break;
      case SbCreativeType.brandVideo:
        this.logoAssetId = creative.logoAssetId!;
        this.videoAssetId = creative.videoAssetId!;
    }
    if (this.videoAssetId) {
      // brand entity id is empty for video
      this.videoAsset = brandAssetIndex.get(this.videoAssetId)?.find((x) => x.mediaType === MediaType.videoAsset);
    }
    if (this.logoAssetId) {
      // for vendor brand entity id is empty
      this.logoAsset = brandAssetIndex
        .get(this.logoAssetId)
        ?.filter(
          (x) =>
            creative.brandEntityId == x.brandEntityId || (creative.brandEntityId === null && x.brandEntityId === ''),
        )
        ?.find((x) => x.mediaType === MediaType.brandLogo);
    }
    if (creative.storePageId && brandAssetIndex.get(creative.storePageId)) {
      // No need to check brandEntityId for store page.
      this.storePage = brandAssetIndex.get(creative.storePageId!)!.slice(-1)[0];
    }

    this.customImageAssetId = creative.customImageAssetId;
    this.customImage = this.getBrandAssetFromCustomImage(
      creative.customImageAssetId!,
      creative.brandEntityId!,
      brandAssetIndex,
    );

    this.customImageAssetId2 = creative.customImageAssetId2;
    this.customImage2 = this.getBrandAssetFromCustomImage(
      creative.customImageAssetId2!,
      creative.brandEntityId!,
      brandAssetIndex,
    );

    this.customImageAssetId3 = creative.customImageAssetId3;
    this.customImage3 = this.getBrandAssetFromCustomImage(
      creative.customImageAssetId3!,
      creative.brandEntityId!,
      brandAssetIndex,
    );

    this.customImageAssetId4 = creative.customImageAssetId4;
    this.customImage4 = this.getBrandAssetFromCustomImage(
      creative.customImageAssetId4!,
      creative.brandEntityId!,
      brandAssetIndex,
    );

    this.customImageAssetId5 = creative.customImageAssetId5;
    this.customImage5 = this.getBrandAssetFromCustomImage(
      creative.customImageAssetId5!,
      creative.brandEntityId!,
      brandAssetIndex,
    );
  }

  private getBrandAssetFromCustomImage(
    customImageAssetId: string,
    brandEntityId: string,
    brandAssetIndex: Map<string, BrandAsset[]>,
  ): BrandAsset | undefined {
    if (customImageAssetId && brandAssetIndex.has(customImageAssetId)) {
      return brandAssetIndex
        .get(customImageAssetId)!
        .find(
          (x) =>
            (brandEntityId == x.brandEntityId || (brandEntityId === null && x.brandEntityId === '')) &&
            x.mediaType === MediaType.customImage,
        );
    }
    return undefined;
  }

  public toSbCreative(): SbCreative {
    return {
      creativeId: this.creativeId,
      strategyId: this.strategyId,
      accountId: this.accountId,
      marketplace: this.marketplace,
      brandEntityId: this.brandEntityId,
      brandName: this.brandName,
      logoAssetId: this.logoAssetId,
      videoAssetId: this.videoAssetId,
      headline: this.headline,
      storePageId: this.storePageId,
      customImageAssetId: this.customImage!.assetId,
      customImageAssetId2: this.customImage2?.assetId,
      customImageAssetId3: this.customImage3?.assetId,
      customImageAssetId4: this.customImage4?.assetId,
      customImageAssetId5: this.customImage5?.assetId,
      creativeAsins: this.creativeAsins,
      state: this.state,
      creativeType: this.creativeType,
    };
  }

  public getCreativeTitle() {
    switch (this.creativeType) {
      case SbCreativeType.brandVideo:
        return '[Brand Video] ' + this.videoAsset?.name;
      case SbCreativeType.video:
        return '[Video] ' + this.videoAsset?.name;
      case SbCreativeType.productCollection:
        return '[Product Collection] ' + this.headline;
      case SbCreativeType.storeSpotlight:
        return '[Store Spotlight] ' + this.headline;
    }
  }

  public getCreativeTypeStr() {
    switch (this.creativeType) {
      case SbCreativeType.productCollection:
        return 'Product Collection';
      case SbCreativeType.video:
        return 'Video';
      case SbCreativeType.brandVideo:
        return 'Brand Video';
      case SbCreativeType.storeSpotlight:
        return 'Store Spotlight';
    }
  }
}
