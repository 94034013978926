<ng-container *transloco="let t">
  <div class="mb-2 flex w-full items-center justify-end">
    <app-export-button tooltipValue="{{ t('common.export_as_csv') }}" (export)="downloadFile()"></app-export-button>
  </div>
  <div class="flex h-full" [ngClass]="{ disabled: isReadOnly }">
    <div class="row ml-1 mr-1 h-full w-1/2">
      <app-switch-button
        class="rank-switch mb-2 ml-auto"
        [options]="[MatchType.phrase, MatchType.exact]"
        [buttonTexts]="[t('keyword-item.phrase'), t('keyword-item.exact')]"
        [buttonTooltips]="[
          t('strategy-keyword.select_phrase_match_type'),
          t('strategy-keyword.select_exact_match_type'),
        ]"
        [selected]="matchType"
        (buttonClicked)="setMatchType($event)"
      ></app-switch-button>
      <textarea
        [(ngModel)]="bulkKeywords"
        class="asins simple-input form-control d-inline-block h-[80%] align-top"
        placeholder="{{
          t('keyword-segment-modal.limit_keyword_placeholder', [matchType === MatchType.exact ? 10 : 4])
        }}"
        aria-label="Keywords list text area for bulk import"
      ></textarea>
      <div class="m-1 flex w-full items-center justify-center">
        <div class="w-full">
          @if (importErrors && importErrors.length > 0) {
            <div>
              <div class="text-warning ml-1 flex content-start">
                <span> {{ t("keyword-segment-modal.ignored_keywords", { count: importErrors.length }) }} </span>
                <div>
                  <a class="ml-3 text-inherit hover:underline" (click)="openIgnoredKeywordModal()">
                    {{ t("common.see_more") }}</a
                  >
                </div>
              </div>
            </div>
          }
        </div>
        <button
          (click)="addKeyword()"
          [disabled]="keywordToAddInvalid()"
          class="btn btn-primary ml-2 h-min"
          matTooltip="{{ t('keyword-segment-modal.add_keywords') }}"
        >
          {{ t("common.add") }}
        </button>
      </div>
    </div>
    <div class="mx-2" role="button">
      <div class="arrow flex h-full w-full items-center justify-center">
        <fa-icon [icon]="faArrowRight" size="2x" (click)="addKeyword()"></fa-icon>
      </div>
    </div>
    <div class="border-shadow h-full w-1/2">
      <mat-table [dataSource]="datasourceKeyword" matSort class="responsive-table table-hover">
        <ng-container matColumnDef="selection">
          <mat-header-cell *matHeaderCellDef class="flex-half flex flex-row items-center justify-between">
            <span
              class="select-button-box"
              matTooltip="{{
                allSelected()
                  ? t('keyword-segment-modal.unselect_all_keywords')
                  : t('keyword-segment-modal.select_all_keywords')
              }}"
            >
              <fa-icon
                [icon]="allSelected() ? faCheckedSquare : faSquare"
                size="lg"
                (click)="toggleSelectAll()"
              ></fa-icon>
            </span>
            @if (nbSelectedKw() > 0) {
              <span>
                class="delete-content" (click)="deleteSelectedKeywords()" matTooltip="{{
                  t("keyword-segment-modal.delete_keywords", { count: nbSelectedKw() })
                }}" "
                <fa-icon [icon]="faTrash"></fa-icon>
                ({{ allSelected() ? t("common.all") : nbSelectedKw() }})
              </span>
            }
          </mat-header-cell>
          <mat-cell *matCellDef="let keyword" class="flex-half flex flex-row justify-between">
            <span class="select-button-box" matTooltip="Select the keyword {{ keyword.item.targetingValue }}">
              <fa-icon
                [icon]="keyword.selected ? faCheckedSquare : faSquare"
                size="lg"
                (click)="select(keyword); $event.stopPropagation()"
              ></fa-icon>
            </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="matchType">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="flex-half"></mat-header-cell>
          <mat-cell *matCellDef="let keyword" class="flex-half flex flex-row justify-between">
            <IBadge
              [label]="keyword.item.matchType"
              [color]="keyword.item.matchType === MatchType.exact ? 'main' : 'black'"
              size="xs"
            />
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="keyword">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="flex-3">
            <span class="search-bar input-group" (click)="$event.stopPropagation()">
              <div class="input-group-prepend">
                <span class="input-group-text"><fa-icon [icon]="faSearch"></fa-icon></span>
              </div>
              <input
                type="text"
                class="search-bar simple-input-prepend form-control"
                placeholder="{{ t('keyword-segment-modal.search_by_keywords') }}"
                (input)="setFilter(getValueFromInputEvent($event))"
                (keydown)="$event.stopPropagation()"
              />
            </span>
          </mat-header-cell>
          <mat-cell *matCellDef="let keyword" class="flex-3 flex flex-row justify-between">
            <div>
              <a
                class="text-color"
                [href]="keyword.item.targetingValue | amazonSearchUrlPipe: marketplace"
                target="_blank"
                (click)="$event.stopPropagation()"
              >
                {{ keyword.item.targetingValue }}
              </a>
            </div>
            <div class="delete-content mr-3">
              <span
                (click)="deleteKeyword(keyword.item)"
                matTooltip="{{ t('keyword-segment-modal.delete_keyword') }}"
                matTooltipPosition="left"
              >
                <fa-icon [icon]="faTrash" class="font-xl"></fa-icon>
              </span>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="['selection', 'matchType', 'keyword']"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: ['selection', 'matchType', 'keyword']"
          (click)="select(row)"
          class="pointer"
        ></mat-row>
      </mat-table>
      <mat-paginator
        class="mt-1"
        [pageSizeOptions]="pageSizeOptions"
        [pageSize]="5"
        showFirstLastButtons
        [hidden]="datasourceKeyword.data && datasourceKeyword.data.length === 0"
      ></mat-paginator>
    </div>
  </div>
</ng-container>
