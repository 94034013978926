import { Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AccountMarketplace, AccountType, Brand, Utils } from "@front/m19-services";
import { Option } from "@front/m19-ui";
import { SbStrategiesService } from "libs/m19-services/src/lib/m19-services/sb-strategies.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { distinctUntilChanged, take } from "rxjs";
import { ConfirmPopupComponent } from "../../../shared/confirm-popup/confirm-popup.component";
import { FormStepEvent, SbFormStep } from "../sb-strategy-form.component";

export interface SbInfo {
  name: string;
  brand: Brand;
}

@Component({
  selector: "app-sb-form-info",
  templateUrl: "./sb-form-info.component.html",
})
export class SbFormInfoComponent implements OnInit {
  private readonly sbStrategiesService = inject(SbStrategiesService);
  private readonly modalService = inject(BsModalService);
  readonly AccountType = AccountType;

  @Input() reviewMode = false;

  @Input() set am(a: AccountMarketplace) {
    if (a) {
      this._am = a;
      this.isVendor = a.accountType === AccountType.VENDOR;
      if (this.isVendor) this.form.controls.brand.disable();
      this.setBrands(a);
    }
  }

  _am: AccountMarketplace;

  @Input() set sbInfo(i: SbInfo) {
    if (this.reviewMode) {
      this.form.disable();
      if (i && !this.isVendor) this.brandNameControl = new FormControl({ value: i.brand.brandName, disabled: true });
    }
    this.form.patchValue(i, { emitEvent: false });
  }

  @Input()
  formCompletionStarted: boolean = false;

  @Output() onFormSubmit = new EventEmitter<FormStepEvent<SbInfo>>();

  brands: Brand[] = [];
  brandsLoading = false;
  brandOptions: Option<Brand>[] = [];
  selectedBrand: Option<Brand> | undefined;

  isVendor = false;
  brandNameControl: FormControl; // used in review to display brand asset name
  form = new FormGroup({
    name: new FormControl(Utils.generateRandomName(), [Validators.required, Validators.maxLength(80)]),
    brand: new FormControl<Brand>(null, Validators.required),
  });

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
      .subscribe((_) => {
        this.submitForm();
      });
  }

  submitForm(goNext = false) {
    if (this.form.valid) {
      this.onFormSubmit.emit({ formData: this.form.value as SbInfo, step: SbFormStep.INFO, goNext });
    }
  }

  setBrands(am: AccountMarketplace) {
    this.brandsLoading = true;
    this.sbStrategiesService.getBrands(am.accountId, am.marketplace).subscribe((brands) => {
      this.brands = brands;
      this.brandOptions = brands.map((b) => this.brandAsOption(b));
      if (this.brands.length > 0) {
        this.form.controls.brand.setValue(this.brands[0]);
      }

      this.selectBrand(this.brandAsOption(this.brands[0]));
      this.brandsLoading = false;
    });
  }

  brandAsOption(b: Brand): Option<Brand> {
    return { value: b, label: b?.brandName ?? "" };
  }

  updateBrand(brand: Brand) {
    this.form.controls.brand.setValue(brand);
  }

  selectBrand(brand: Option<Brand>) {
    this.selectedBrand = brand;
    if (this.formCompletionStarted) {
      const modalRef = this.modalService.show(ConfirmPopupComponent, {
        initialState: {
          type: "success",
          message: "Please note that changing this strategy brand will clear all form data. Do you want to proceed?",
        },
        class: "modal-primary modal-dialog-centered",
      });

      modalRef.onHide.pipe(take(1)).subscribe({
        next: () => this.updateBrand(brand.value),
      });
    } else {
      this.updateBrand(brand.value);
    }
  }
}
