import { Component, inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { environment } from "../../environments/environment";
import { LayoutSelectorService } from "@m19-board/layout-selector.service";
import { RouterOutlet } from "@angular/router";
import { TranslocoDirective } from "@jsverse/transloco";

@Component({
  selector: "auth.layout",
  standalone: true,
  imports: [CommonModule, RouterOutlet, TranslocoDirective],
  templateUrl: "./auth-layout.component.html",
})
export class AuthLayoutComponent {
  private readonly layoutSelector = inject(LayoutSelectorService);

  isTest = !environment.production;
  navbarBrandFull = { src: this.layoutSelector.getLogo(), height: 40, alt: "logo" };
}
