import { catchError, forkJoin, map, Observable, of, ReplaySubject, Subject, tap, throwError } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import {
  KeywordTrackerConfig,
  KeywordTrackingApi,
  Marketplace,
  ProductTrackerConfig,
  SearchTermAsinRank,
  SetKeywordTrackerConfigurationActionEnum,
  SetProductTrackerConfigurationActionEnum,
  SetProductTrackerConfigurationRequest,
} from './api-client';
import {
  AccountMarketplaceDataCacheNoContext,
  AccountMarketplaceOrganizationDataCacheNoContext,
  ByMarketplace,
  BySearchTerm,
} from './cache-utils';
import { Injectable } from '@angular/core';

export interface SearchTermAsinRanks {
  searchTerm: string;
  asinRanks: Map<string, SearchTermAsinRank[]>;
}

@Injectable({
  providedIn: 'root',
})
export class KeywordTrackingService {
  private readonly keywordTrackerConfigCache: AccountMarketplaceOrganizationDataCacheNoContext<KeywordTrackerConfig[]>;
  private readonly productTrackerConfigCache: AccountMarketplaceDataCacheNoContext<ProductTrackerConfig[]>;
  private readonly keywordTrackingTimelineCache: ByMarketplace<BySearchTerm<Subject<SearchTermAsinRanks>>> = new Map();

  constructor(private keywordTrackingApi: KeywordTrackingApi) {
    this.keywordTrackerConfigCache = new AccountMarketplaceOrganizationDataCacheNoContext(
      (accountId: string, marketplace: Marketplace, organizationId: number) => {
        return this.keywordTrackingApi.getKeywordTrackerConfiguration({ accountId, marketplace, organizationId });
      },
    );
    this.productTrackerConfigCache = new AccountMarketplaceDataCacheNoContext(
      (accountId: string, marketplace: Marketplace) => {
        return this.keywordTrackingApi.getProductTrackerConfiguration({ accountId, marketplace });
      },
    );
  }

  public getKeywordTrackingTimelines(
    searchTerms: string[],
    marketplace: Marketplace,
  ): Observable<SearchTermAsinRanks[]> {
    if (searchTerms.length == 0) {
      return of([]);
    }
    if (!this.keywordTrackingTimelineCache.has(marketplace)) {
      this.keywordTrackingTimelineCache.set(marketplace, new Map());
    }
    const marketplaceCache = this.keywordTrackingTimelineCache.get(marketplace);
    const result: Observable<SearchTermAsinRanks>[] = [];
    const requests: string[] = [];
    for (const searchTerm of searchTerms) {
      if (marketplaceCache!.has(searchTerm)) {
        result.push(marketplaceCache!.get(searchTerm)!);
        continue;
      }
      const subject = new ReplaySubject<SearchTermAsinRanks>(1);
      marketplaceCache!.set(searchTerm, subject);
      requests.push(searchTerm);
      result.push(subject);
    }
    if (requests.length > 0) {
      const subscription = this.keywordTrackingApi
        .getSearchTermRankTimeline({ marketplace, requestBody: requests })
        .subscribe({
          next: (searchTermRankTimeline) => {
            if (!searchTermRankTimeline || searchTermRankTimeline.length == 0) {
              // empty timeline
              for (const request of requests) {
                marketplaceCache!
                  .get(request)!
                  .next({ searchTerm: request, asinRanks: new Map<string, SearchTermAsinRank[]>() });
                marketplaceCache!.get(request)!.complete();
                marketplaceCache!.delete(request);
              }
            } else {
              const toProcess = new Set<string>(requests);
              for (const tl of searchTermRankTimeline) {
                const subject = marketplaceCache!.get(tl.searchTerm!)!;
                const timeline = {
                  searchTerm: tl.searchTerm!,
                  asinRanks: new Map<string, SearchTermAsinRank[]>(),
                };
                for (const asinRank of tl.asinsRanks ?? []) {
                  timeline.asinRanks.set(
                    asinRank.asin!,
                    asinRank.ranks!.sort((a, b) => a.timestamp! - b.timestamp!),
                  );
                }
                subject.next(timeline);
                subject.complete();
                toProcess.delete(tl.searchTerm!);
              }
              // also set a value for requests not returned by the backend
              for (const searchTerm of toProcess) {
                const subject = marketplaceCache!.get(searchTerm);
                subject!.next({ searchTerm, asinRanks: new Map<string, SearchTermAsinRank[]>() });
                subject!.complete();
              }
            }
            subscription.unsubscribe();
          },
          error: (error: AjaxError) => {
            const err =
              'Error getting keyword tracking timeline: ' + (error.response ? error.response.message : error.message);
            subscription.unsubscribe();
            for (const request of requests) {
              marketplaceCache!.get(request)!.error(err);
              marketplaceCache!.delete(request);
            }
          },
        });
    }
    return forkJoin(result);
  }

  public getKeywordTrackingTimeline(searchTerm: string, marketplace: Marketplace): Observable<SearchTermAsinRanks> {
    return this.getKeywordTrackingTimelines([searchTerm], marketplace).pipe(map((c) => c[0]));
  }

  public getKeywordTrackerConfig(
    accountId: string,
    marketplace: Marketplace,
    organizationId: number,
  ): Observable<KeywordTrackerConfig[]> {
    return this.keywordTrackerConfigCache.get(accountId, marketplace, organizationId);
  }

  public getProductTrackerConfig(accountId: string, marketplace: Marketplace): Observable<ProductTrackerConfig[]> {
    return this.productTrackerConfigCache.get(accountId, marketplace);
  }

  public updateKeywordTrackerConfig(kwTrackerConfig: KeywordTrackerConfig) {
    return this.keywordTrackingApi
      .setKeywordTrackerConfiguration({
        accountId: kwTrackerConfig.accountId!,
        marketplace: kwTrackerConfig.marketplace!,
        organizationId: kwTrackerConfig.organizationId!,
        action: SetKeywordTrackerConfigurationActionEnum.ADD,
        keywordTrackerConfig: [kwTrackerConfig],
      })
      .pipe(
        catchError((error: AjaxError) => {
          return throwError(
            () =>
              `Error updating tracked keyword ${kwTrackerConfig.searchTerm}: ` +
              (error.response ? error.response.message : error.message),
          );
        }),
        tap((_) => {
          this.keywordTrackerConfigCache.update(
            kwTrackerConfig.accountId!,
            kwTrackerConfig.marketplace!,
            kwTrackerConfig.organizationId,
            (config) =>
              config.map((c) => {
                if (kwTrackerConfig.searchTerm === c.searchTerm) {
                  return { ...c, ...kwTrackerConfig };
                } else {
                  return { ...c };
                }
              }),
          );
        }),
      );
  }

  public addKeywordTrackerConfig(
    accountId: string,
    marketplace: Marketplace,
    organizationId: number,
    kwTrackerConfig: KeywordTrackerConfig[],
  ): Observable<KeywordTrackerConfig[]> {
    if (kwTrackerConfig.length === 0) {
      return of(kwTrackerConfig);
    }
    return this.keywordTrackingApi
      .setKeywordTrackerConfiguration({
        accountId: accountId,
        marketplace: marketplace,
        organizationId: organizationId,
        action: SetKeywordTrackerConfigurationActionEnum.ADD,
        keywordTrackerConfig: kwTrackerConfig,
      })
      .pipe(
        catchError((error: AjaxError) => {
          return throwError(
            () => `Error setting keyword tracking: ` + (error.response ? error.response.message : error.message),
          );
        }),
        tap(() => {
          this.keywordTrackerConfigCache.reload(accountId, marketplace, organizationId);
        }),
        map(() => kwTrackerConfig),
      );
  }

  public addProductTrackerConfig(
    accountId: string,
    marketplace: Marketplace,
    productTrackerConfig: ProductTrackerConfig[],
  ): Observable<ProductTrackerConfig[]> {
    if (productTrackerConfig.length === 0) {
      return of(productTrackerConfig);
    }
    return this.keywordTrackingApi
      .setProductTrackerConfiguration({
        accountId: accountId,
        marketplace: marketplace,
        action: SetProductTrackerConfigurationActionEnum.ADD,
        requestBody: productTrackerConfig.map((s) => s.asin),
      } as SetProductTrackerConfigurationRequest)
      .pipe(
        catchError((error: AjaxError) => {
          return throwError(
            () => `Error setting product tracking: ` + (error.response ? error.response.message : error.message),
          );
        }),
        tap(() => {
          this.productTrackerConfigCache.reload(accountId, marketplace);
        }),
        map(() => productTrackerConfig),
      );
  }

  public removeKeywordTrackingConfig(
    accountId: string,
    marketplace: Marketplace,
    organizationId: number,
    kwTrackerConfig: KeywordTrackerConfig[],
  ) {
    if (kwTrackerConfig.length === 0) {
      return of();
    }
    return this.keywordTrackingApi
      .setKeywordTrackerConfiguration({
        accountId: accountId,
        marketplace: marketplace,
        organizationId: organizationId,
        action: SetKeywordTrackerConfigurationActionEnum.DELETE,
        keywordTrackerConfig: kwTrackerConfig,
      })
      .pipe(
        catchError((error: AjaxError) => {
          return throwError(
            () => `Error removing tracked keyword: ` + (error.response ? error.response.message : error.message),
          );
        }),
        tap(() => {
          this.keywordTrackerConfigCache.reload(accountId, marketplace, organizationId);
        }),
      );
  }

  public removeProductTrackingConfig(
    accountId: string,
    marketplace: Marketplace,
    productTrackerConfig: ProductTrackerConfig[],
  ) {
    if (productTrackerConfig.length === 0) {
      return of();
    }
    return this.keywordTrackingApi
      .setProductTrackerConfiguration({
        accountId: accountId,
        marketplace: marketplace,
        action: SetProductTrackerConfigurationActionEnum.DELETE,
        requestBody: productTrackerConfig.map((s) => s.asin),
      } as SetProductTrackerConfigurationRequest)
      .pipe(
        catchError((error: AjaxError) => {
          return throwError(
            () => `Error removing tracked product: ` + (error.response ? error.response.message : error.message),
          );
        }),
        tap(() => {
          this.productTrackerConfigCache.reload(accountId, marketplace);
        }),
      );
  }

  public getSOVTimeline(accountId: string, marketplace: Marketplace, organizationId: number, searchTerm?: string) {
    return this.keywordTrackingApi.getSOVTimeline({ accountId, marketplace, organizationId, searchTerm });
  }

  public getDailyHourlyKeywordNumber(organizationId: number) {
    return this.keywordTrackingApi.getHourlyDailyTrackedAsin({ organizationId: organizationId });
  }
}
