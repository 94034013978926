import { Component, Input, OnInit } from "@angular/core";
import { faPauseCircle } from "@fortawesome/free-regular-svg-icons";
import {
  faClock,
  faExclamationTriangle,
  faInfoCircle,
  faPause,
  faPlay,
  faPlayCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  AccountSelectionService,
  AlgoMode,
  AuthService,
  CampaignType,
  ConfigService,
  Currency,
  Marketplaces,
  StrategyEx,
  StrategyStateEnum,
} from "@front/m19-services";
import { ConfirmPopupComponent } from "@m19-board/shared/confirm-popup/confirm-popup.component";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { switchMap } from "rxjs";
import { StrategyLimitationModalComponent } from "../strategy-limitation-modal/strategy-limitation-modal.component";
import { TargetingLimit } from "../target-utils";
import { Observable } from "rxjs/internal/Observable";
import { ICON_BOOST } from "@m19-board/utils/iconsLabels";
import { FblModeSize } from "../fbl-mode/fbl-mode.component";
import { Constant } from "libs/m19-services/src/lib/m19-services/constant";

//Main component
@UntilDestroy()
@Component({
  selector: "app-strategy-card",
  templateUrl: "./strategy-card.component.html",
  styleUrls: ["./strategy-card.component.scss"],
})
export class StrategyCardComponent implements OnInit {
  _strategy: StrategyEx;
  AlgoMode = AlgoMode;
  algoMode: AlgoMode;
  currency: Currency;
  locale: string;

  submenu = false;
  submenuhover = false;
  targetLimit: TargetingLimit;
  readonly faPause = faPause;
  readonly faPlay = faPlay;
  readonly faPlayCircle = faPlayCircle;
  readonly faPauseCircle = faPauseCircle;
  readonly faWarning = faExclamationTriangle;
  readonly faClock = faClock;
  readonly faTrash = faTrashAlt;
  readonly faInfo = faInfoCircle;
  readonly CampaignType = CampaignType;
  readonly FblModeSize = FblModeSize;
  readonly ICON_BOOST = ICON_BOOST;
  readonly boostActivated$: Observable<boolean>;

  @Input() isReadOnly = false;
  @Input() canDelete = true;

  @Input()
  set strategy(strategy: StrategyEx) {
    this._strategy = strategy;
    this.targetLimit = new TargetingLimit(this._strategy);
    this.algoMode = strategy.algoMode;
    this.currency = Marketplaces[strategy.marketplace].currency;
  }

  @Input() strategyPage: string;

  strategyPagePrefix = "/strategies/";

  constructor(
    private modalService: BsModalService,
    public configurationService: ConfigService,
    private authService: AuthService,
    private toastrService: ToastrService,
    private accountSelectionService: AccountSelectionService,
  ) {
    this.boostActivated$ = accountSelectionService.promoBoostActivated$;
  }

  ngOnInit() {
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.locale = user.locale;
      if (user?.uiVersion > 0) {
        this.strategyPagePrefix = "/advertising/";
      }
    });
    this.submenu = false;
    this.submenuhover = false;
  }

  changeStatus() {
    const newState =
      this._strategy.state == StrategyStateEnum.ENABLED ? StrategyStateEnum.PAUSED : StrategyStateEnum.ENABLED;

    if (
      newState == StrategyStateEnum.ENABLED &&
      this.configurationService.isStrategyLimitReached(this._strategy.campaignType)
    ) {
      if (
        this._strategy.campaignType == CampaignType.SB &&
        this.configurationService.getNumberOfLiveStrategies(CampaignType.SB) >= Constant.maxSbStrategies
      ) {
        this.toastrService.error(
          `You have reached the maximum number of ${Constant.maxSbStrategies} active Sponsored Brands strategies`,
        );
        return;
      }
      const modalOptions: ModalOptions = {
        initialState: {
          liveStrategyLimit: this.configurationService.getLiveStrategyLimit(),
          campaignType: this._strategy.campaignType,
        },
        class: "modal-primary",
      };
      this.modalService.show(StrategyLimitationModalComponent, modalOptions);
      return;
    }
    this.configurationService.updateStrategyState(
      this._strategy.accountId,
      this._strategy.marketplace,
      this._strategy.strategyId,
      newState,
    );
  }

  deleteStrategy() {
    const strategyName = this._strategy.getName();
    const modalOptions: ModalOptions = {
      initialState: {
        title: "Strategy Deletion",
        message: `Are you sure you want to delete strategy "${strategyName}"?`,
        confirmCta: "Delete",
      },
      class: "modal-danger",
    };
    const modalRef = this.modalService.show(ConfirmPopupComponent, modalOptions);
    modalRef.content.confirm.pipe(switchMap(() => this.configurationService.deleteStrategy(this._strategy))).subscribe({
      next: () => {
        this.toastrService.success("Strategy " + strategyName + " successfully deleted", "Strategy Deleted");
      },
      error: (err) => {
        this.toastrService.error("Error deleting Strategy " + strategyName + ": " + err, "Strategy Deletion Error");
      },
    });
  }

  isDayPartingEnabled(): boolean {
    return this._strategy.daypartingPauseHour != null && this._strategy.daypartingReactivationHour != null;
  }

  getDayPartingToolTip(): string {
    return (
      "Strategy paused from " +
      this._strategy.daypartingPauseHour +
      ":00 to " +
      this._strategy.daypartingReactivationHour +
      ":00"
    );
  }
}
