import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { Targeting } from "@front/m19-services";

@Component({
  selector: "app-blacklist-view",
  template: `<div class="flex flex-wrap">
    @for (item of blacklist; track item; let last = $last) {
      <div class="item m-2" [ngClass]="item.matchType">
        {{ item.targetingValue }}
      </div>
    }
  </div>`,
  standalone: true,
  imports: [CommonModule],
  styleUrls: ["./blacklist-view.component.scss"],
})
export class BlacklistViewComponent {
  @Input()
  blacklist: Targeting[];
}
