<ng-container *transloco="let t">
  <app-strategy-card
    strategyPage="sponsored-display"
    [canDelete]="!isReadOnly"
    [strategy]="_strategy"
    [isReadOnly]="isReadOnly"
  >
    @if (_productTactics?.length > 0) {
      @for (tactic of _productTactics; track tactic.segmentId) {
        <div class="my-2 truncate text-xs text-gray-600">
          <IBadge color="gray" label="Product Page" class="mr-1" size="xs" />
          {{ t("sd-card.product_page", [tactic.segment?.name | titlecase]) }}
        </div>
      }
    }
    @if (_strategy.audienceTargetings.length > 0) {
      @for (audience of _strategy.audienceTargetings; track audience.audienceTargetId) {
        <div class="my-2 truncate text-xs text-gray-600">
          <IBadge color="gray" label="Audience" class="mr-1" size="xs" />
          {{ audience.expressionType | titlecase }}
          {{ supportedAudienceMatchType[audience.matchType] }} last {{ audience.lookback }}d
        </div>
      }
    }
  </app-strategy-card>
</ng-container>
