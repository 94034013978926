import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  AsinService,
  Catalog,
  Marketplace,
  ProductGroup,
  ProductGroupEx,
  ProductGroupService,
} from "@front/m19-services";
import {
  BulkError,
  BulkImportService,
  ProductGroupBulkOperations,
  ProductGroupCreation,
  ProductGroupUpdate,
} from "@m19-board/strategies/strategy-bulk-upload-modal/bulk-import.service";
import { ICON_UPLOAD } from "@m19-board/utils/iconsLabels";
import { BsModalRef } from "ngx-bootstrap/modal";

@UntilDestroy()
@Component({
  selector: "app-product-group-bulk-upload-modal",
  templateUrl: "./product-group-bulk-upload-modal.component.html",
  styleUrls: ["./product-group-bulk-upload-modal.component.scss"],
})
export class ProductGroupBulkUploadModalComponent implements OnInit {
  @Input()
  accountId: string;

  @Input()
  marketplace: Marketplace;

  @Input()
  productGroups: ProductGroupEx[];

  @Input()
  bulkData: string;

  @Output()
  public productGroupBulkOperations = new EventEmitter<ProductGroupBulkOperations>();

  textAreaPlaceholder = "";
  fileUploadError = "";
  readonly ICON_UPLOAD = ICON_UPLOAD;
  private catalog: Catalog;

  constructor(
    public bsModalRef: BsModalRef,
    private asinService: AsinService,
    private productGroupService: ProductGroupService,
    private bulkImportService: BulkImportService,
  ) {}

  ngOnInit(): void {
    this.asinService
      .getCatalog(this.accountId, this.marketplace)
      .pipe(untilDestroyed(this))
      .subscribe((c) => (this.catalog = c));
    this.textAreaPlaceholder = this.bulkImportService.getProductGroupTextAreaPlaceholderText();
  }

  isUploadButtonDisabled(content: string): boolean {
    return !content || content.trim().length === 0;
  }

  private getProductGroupIndex() {
    const productGroupIndex = new Map<number, ProductGroupEx>();
    for (const pg of this.productGroups) {
      productGroupIndex.set(pg.productGroupId, pg);
    }
    return productGroupIndex;
  }

  uploadProductGroups(input: string): void {
    const errors: BulkError[] = [];
    const creations: ProductGroupCreation[] = [];
    const updates: ProductGroupUpdate[] = [];
    const rows = input.split(/[\n]+/);
    const productGroupIndex = this.getProductGroupIndex();
    let lineIndex = 0;
    for (const row of rows) {
      const trimmedRow = row.trim();
      if (trimmedRow === "") {
        // discard empty row
        continue;
      }
      lineIndex++;
      const csvRow = this.bulkImportService.parseProductGroupCsvRow(trimmedRow.split(/\t/));
      if (!csvRow) {
        errors.push({ lineIndex: lineIndex, csvRow: trimmedRow, errors: ["Line is not properly formatted"] });
        continue;
      }
      if (csvRow.productGroupId === undefined) {
        errors.push({ lineIndex: lineIndex, csvRow: trimmedRow, errors: ["Product Group id is not an integer"] });
        continue;
      }
      if (csvRow.productGroupId === 0) {
        // product group creation
        const productGroup = {
          accountId: this.accountId,
          marketplace: this.marketplace,
          ...csvRow,
        } as ProductGroup;

        // check ASINs are in catalog
        const inexistingAsins = this.checkAsinsAreInCatalog(productGroup.items);
        if (inexistingAsins.length > 0) {
          errors.push({
            lineIndex: lineIndex,
            csvRow: trimmedRow,
            errors: [`Some ASINs are not in the catalog: ${inexistingAsins.join(", ")}`],
          });
          continue;
        }
        if (productGroup.items.length > ProductGroupEx.MaxProductGroupItems) {
          errors.push({
            lineIndex: lineIndex,
            csvRow: trimmedRow,
            errors: [`Cannot have more than ${ProductGroupEx.MaxProductGroupItems} ASINs in a product group`],
          });
          continue;
        }

        // check product group name
        const validNameError = this.productGroupService.isValidProductGroupName(
          productGroup.productGroupName,
          this.accountId,
          this.marketplace,
          0,
        );
        if (validNameError != undefined) {
          errors.push({
            lineIndex: lineIndex,
            csvRow: trimmedRow,
            errors: [validNameError],
          });
          continue;
        }

        creations.push({
          lineIndex: lineIndex,
          csvRow: trimmedRow,
          productGroup: productGroup,
        });
      } else {
        // product group update
        if (!productGroupIndex.has(csvRow.productGroupId)) {
          errors.push({
            lineIndex: lineIndex,
            csvRow: trimmedRow,
            errors: ["Product Group does not exist for this id"],
          });
          continue;
        }
        // check if duplicated strategy update
        if (updates.find((u) => u.productGroupToUpdate.productGroupId == csvRow.productGroupId)) {
          errors.push({
            lineIndex: lineIndex,
            csvRow: trimmedRow,
            errors: [`Duplicated update on product group with id ${csvRow.productGroupId}`],
          });
          continue;
        }
        const toUpdate = productGroupIndex.get(csvRow.productGroupId);
        const updateParams = this.bulkImportService.getProductGroupUpdateParams(csvRow, toUpdate);
        // check if something to update
        if (!this.isUpdating(updateParams)) {
          errors.push({
            lineIndex: lineIndex,
            csvRow: trimmedRow,
            errors: ["No fields to update on the product group"],
          });
          continue;
        }

        // check added ASINs
        const inexistingAsins = this.checkAsinsAreInCatalog(updateParams.asinsToAdd);
        if (inexistingAsins.length > 0) {
          errors.push({
            lineIndex: lineIndex,
            csvRow: trimmedRow,
            errors: [`Some ASINs are not in the catalog: ${inexistingAsins.join(", ")}`],
          });
          continue;
        }
        if (
          toUpdate.items.length + updateParams.asinsToAdd.length - updateParams.asinsToDelete.length >
          ProductGroupEx.MaxProductGroupItems
        ) {
          errors.push({
            lineIndex: lineIndex,
            csvRow: trimmedRow,
            errors: [`Cannot have more than ${ProductGroupEx.MaxProductGroupItems} ASINs in a product group`],
          });
          continue;
        }

        // check product group name
        if (updateParams.productGroupName) {
          const validNameError = this.productGroupService.isValidProductGroupName(
            updateParams.productGroupName,
            this.accountId,
            this.marketplace,
            0,
          );
          if (validNameError != undefined) {
            errors.push({
              lineIndex: lineIndex,
              csvRow: trimmedRow,
              errors: [validNameError],
            });
            continue;
          }
        }
        updates.push({
          lineIndex: lineIndex,
          csvRow: trimmedRow,
          productGroupToUpdate: toUpdate,
          updatedFields: updateParams,
        });
      }
    }
    this.productGroupBulkOperations.emit({
      bulkData: input,
      creations: creations,
      updates: updates,
      errors: errors,
    });
    this.bsModalRef.hide();
  }

  private checkAsinsAreInCatalog(asins: string[]) {
    const inexistingAsins: string[] = [];
    for (const asin of asins) {
      if (!this.catalog.contains(asin)) {
        inexistingAsins.push(asin);
      }
    }
    return inexistingAsins;
  }

  private isUpdating(
    params: Partial<ProductGroup> & {
      asinsToAdd: string[];
      asinsToDelete: string[];
      asins: string[];
    },
  ) {
    for (const key in params) {
      if (key === "productGroupId" || key === "accountId" || key == "marketplace" || key == "asins" || key == "items")
        continue;
      if (key === "asinsToAdd" || key === "asinsToDelete") {
        if (params[key].length > 0) {
          return true;
        } else {
          continue;
        }
      }
      if (params[key] !== undefined) return true;
    }
    return false;
  }

  handleKeydown(event: KeyboardEvent) {
    // prevent focus switch when pressing tab
    if (event.key == "Tab") {
      event.preventDefault();
      const target = event.target as HTMLTextAreaElement;
      const start = target.selectionStart;
      const end = target.selectionEnd;
      target.value = target.value.substring(0, start) + "\t" + target.value.substring(end);
      target.selectionStart = target.selectionEnd = start + 1;
    }
  }

  onCsvUpload(files: FileList): void {
    if (files.length > 0) {
      const csvFile = files[0];
      if (csvFile.type !== "text/csv" && csvFile.type !== "application/vnd.ms-excel") {
        this.fileUploadError = `${csvFile.name} is not a CSV file`;
        return;
      }
      const reader: FileReader = new FileReader();
      reader.readAsText(csvFile);
      reader.onload = () => {
        const csv: string = reader.result as string;
        this.uploadProductGroups(csv);
      };
    }
  }
}
