import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { OrganizationAccountGroups, OrganizationAccountGroupService, OrganizationService } from '.';
import {
  AccountMarketplace,
  BillingApi,
  Coupon,
  Customer,
  CustomerApi,
  Organization,
  Plan,
  Response,
  User,
} from './api-client';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  private user?: User;

  private organizations = new BehaviorSubject<OrganizationAccountGroups[]>([]);
  public organizations$ = this.organizations.asObservable();

  private organizationOnwer = new BehaviorSubject<OrganizationAccountGroups | undefined>(undefined);
  public organizationOnwer$ = this.organizationOnwer.asObservable();

  private availableBillingAccountMarketplace = new BehaviorSubject<Array<AccountMarketplace>>([]);

  public availableBillingAccountMarketplace$: Observable<Array<AccountMarketplace>> =
    this.availableBillingAccountMarketplace.asObservable();

  constructor(
    private authService: AuthService,
    private billingApi: BillingApi,
    private customerService: CustomerApi,
    accountGroupService: OrganizationAccountGroupService,
    private organizationBaseService: OrganizationService,
  ) {
    this.authService.user$
      .pipe(
        tap((user: User | undefined) => {
          this.user = { ...user } as User;
        }),
        switchMap(() => accountGroupService.allOrganizationAccountGroups$),
        switchMap((allOrganizations) => {
          if (this.user) {
            return this.customerService.listCustomers().pipe(
              catchError((error) =>
                // discard error
                {
                  return of([]);
                },
              ),
              map((allCustomers) => [allOrganizations, allCustomers]),
            );
          }
          return of([allOrganizations, []]);
        }),
      )
      .subscribe(([allOrgs, allCustomers]) => {
        const allOrganizations = allOrgs as OrganizationAccountGroups[];

        const customersById = new Map();

        if (allCustomers)
          allCustomers
            .filter((x) => !!x.customerId)
            .forEach((item) => {
              const id = item.customerId;
              customersById.set(id, item);
            });
        allOrganizations
          .filter((x) => !!x.customerId)
          .forEach((item) => {
            item.setCustomer(customersById.get(item.customerId));
          });
        this.organizationOnwer.next(allOrganizations.find((x) => x.organization.ownerId == this.user?.userId));

        allOrganizations.sort((a, b) =>
          a.organization.ownerId == this.user?.userId ? -1 : b.organization.ownerId == this.user?.userId ? 1 : 0,
        );
        this.organizations.next(allOrganizations);
      });
  }

  public loadAvailableAccountMarketplaces(organizationId: number): Observable<Array<AccountMarketplace>> {
    return this.customerService.resourcesWithBidderOff({ organizationId: organizationId });
  }

  private updateOrganizationCustomer(organizationId: number, customer: Customer) {
    const allOrgs = this.organizations.getValue();
    const org = allOrgs.find((o) => o.id == organizationId)!;
    org.customerId = customer.customerId!;
    org.setCustomer(customer);
    this.organizations.next(allOrgs);
  }

  private updateOrganizationByCustomer(customer: Customer) {
    const allOrgs = this.organizations.getValue();
    const org = allOrgs.find((o) => o.customerId == customer.customerId)!;
    org.setCustomer(customer);
    this.organizations.next(allOrgs);
  }

  private updateOrganization(organization: Organization) {
    this.organizationBaseService.updateOrganization(organization);
  }

  /**
   * @deprecated: use createCustomerV2 instead
   */
  public createCustomer(
    c: Customer,
    organizationId: number,
    onSuccess: (response: Response) => void,
    onError: (error: AjaxError) => void,
  ) {
    this.customerService.createCustomer({ organizationId: organizationId, customer: c }).subscribe(
      (response: Response) => {
        const customer = response.entity as Customer;
        this.updateOrganizationCustomer(organizationId, customer);
        onSuccess(response);
      },
      (error: AjaxError) => {
        onError(error);
      },
    );
  }

  public createCustomerV2(c: Customer, organizationId: number) {
    return this.customerService.createCustomer({ organizationId: organizationId, customer: c }).pipe(
      tap((response: Response) => {
        const customer = response.entity as Customer;
        this.updateOrganizationCustomer(organizationId, customer);
      }),
    );
  }

  public updateCustomer(
    c: Customer,
    updateVat: boolean,
    onSuccess: (response: Response) => void,
    onError: (error: AjaxError) => void,
  ) {
    this.customerService.updateCustomer({ customer: c, updateVat: updateVat }).subscribe(
      (response: Response) => {
        const customer = response.entity as Customer;
        this.updateOrganizationByCustomer(customer);
        onSuccess(response);
      },
      (error: AjaxError) => {
        onError(error);
      },
    );
  }

  public linkCreditCard(
    c: Customer,
    t: string,
    onSuccess: (response: Response) => void,
    onError: (error: AjaxError) => void,
  ) {
    this.customerService.linkCreditCard({ customer: c, token: t }).subscribe(
      (response: Response) => {
        const customer = response.entity as Customer;
        this.updateOrganizationByCustomer(customer);
        onSuccess(response);
      },
      (error: AjaxError) => {
        onError(error);
      },
    );
  }

  public removeCreditCard(customer: Customer, cardId: string): Observable<Customer> {
    return this.customerService.removeCreditCard({ customer, token: cardId }).pipe(
      map((response: Response) => {
        const customer = response.entity as Customer;
        this.updateOrganizationByCustomer(customer);
        return customer;
      }),
    );
  }

  /**
   * @deprecated: use createSubscriptionV2 instead
   */
  public createSubscription(
    organization: Organization,
    paymentMethodId: string,
    onSuccess: (response: Response) => void,
    onError: (error: AjaxError) => void,
  ) {
    return this.billingApi
      .createSubscription({ organization: organization, paymentMethodId: paymentMethodId })
      .subscribe(
        (response: Response) => {
          this.updateOrganization(response.entity as Organization);
          onSuccess(response);
        },
        (error: AjaxError) => {
          onError(error);
        },
      );
  }

  public createSubscriptionV2(organization: Organization, paymentMethodId?: string) {
    return this.billingApi
      .createSubscription({ organization: organization, paymentMethodId: paymentMethodId })
      .pipe(tap((response: Response) => this.updateOrganization(response.entity as Organization)));
  }

  public updateSubscription(
    organizationId: number,
    plan: Plan,
    onSuccess: (response: Response) => void,
    onError: (error: AjaxError) => void,
  ) {
    return this.billingApi.updateSubscription({ organizationId: organizationId, plan: plan }).subscribe(
      (response: Response) => {
        this.updateOrganization(response.entity as Organization);
        onSuccess(response);
      },
      (error: AjaxError) => {
        onError(error);
      },
    );
  }

  public stopSelfServiceSubscription(
    childOrganizationId: number,
    onSuccess: (response: Response) => void,
    onError: (error: AjaxError) => void,
  ) {
    return this.billingApi.stopSelfServiceSubscription({ organizationId: childOrganizationId }).subscribe(
      (response: Response) => {
        const org = this.organizationBaseService.find(childOrganizationId)!;
        org.billingPlan = undefined;
        this.updateOrganization(org);
        onSuccess(response);
      },
      (error: AjaxError) => {
        onError(error);
      },
    );
  }

  public getCoupon(couponCode: string): Observable<Coupon> {
    return this.billingApi.getCoupon({ coupon: couponCode });
  }

  public setCoupon(organizationId: number, couponCode: string): Observable<void> {
    return this.billingApi.setCoupon({ organizationId, coupon: couponCode }).pipe(
      catchError((error: AjaxError) => {
        return throwError(() => `Error setting coupon: ` + (error.response ? error.response.message : error.message));
      }),
      tap((org) => {
        this.updateOrganization(org);
      }),
      map(() => void 0),
    );
  }

  public transferManagementService(
    childOrganizationId: number,
    onSuccess: (response: Response) => void,
    onError: (error: AjaxError) => void,
  ) {
    this.billingApi.transferManagementService({ organizationId: childOrganizationId }).subscribe(
      (response: Response) => {
        onSuccess(response);
      },
      (error: AjaxError) => {
        onError(error);
      },
    );
  }
}

export const DEFAULT_BILLING_PLANS = [Plan.STARTER, Plan.PROFESSIONAL];
