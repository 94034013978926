import { Component, Input, signal } from "@angular/core";
import { AsinSeedTypeEnum, AsinService, Marketplace, MatchType } from "@front/m19-services";
import { Option } from "@front/m19-ui";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";

export enum SeedType {
  Keyword = "common.keyword",
  Product = "common.product",
}

@UntilDestroy()
@Component({
  selector: "app-add-to-seeds-modal",
  templateUrl: "./add-to-seeds-modal.component.html",
})
export class AddToSeedsModalComponent {
  @Input()
  seedType: SeedType = SeedType.Keyword;
  @Input()
  asin: string;
  @Input()
  targetingItemValue: string;
  @Input()
  accountId: string;
  @Input()
  marketplace: Marketplace;

  matchType = MatchType.exact;

  readonly SeedType = SeedType;
  readonly MatchType = MatchType;
  readonly AsinSeedTypeEnum = AsinSeedTypeEnum;

  seedsOptions: Option<AsinSeedTypeEnum>[] = [
    {
      label: "Seed",
      value: AsinSeedTypeEnum.WHITELIST,
    },
    {
      label: "Blacklist",
      value: AsinSeedTypeEnum.BLACKLIST,
    },
  ];
  asinSeedType = signal<Option<AsinSeedTypeEnum>>(this.seedsOptions[0]);

  constructor(
    private bsModalRef: BsModalRef,
    private asinService: AsinService,
    private toastrService: ToastrService,
  ) {}

  close(): void {
    this.bsModalRef.hide();
  }

  setMatchType(matchType: MatchType) {
    this.matchType = matchType;
  }

  setAsinSeedType(asinSeedType: Option<AsinSeedTypeEnum>) {
    this.asinSeedType.set(asinSeedType);
    if (this.seedType == SeedType.Keyword) {
      // reset match type to exact when changing from blacklist to whitelist
      this.setMatchType(MatchType.exact);
    }
  }

  save(): void {
    this.asinService
      .addSeedToAsin(this.accountId, this.marketplace, this.asin, {
        accountId: this.accountId,
        marketplace: this.marketplace,
        asin: this.asin,
        matchType: this.seedType == SeedType.Keyword ? this.matchType : MatchType.asinSameAs,
        targetingValue: this.targetingItemValue,
        type: this.asinSeedType().value,
      })
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.toastrService.success(`ASIN ${this.asin} seeds successfully updated`, "Product seeds added");
          this.close();
        },
        error: (err) => {
          this.toastrService.error(err, "Cannot add product seeds");
          this.close();
        },
      });
  }
}
