<div class="divide-y divide-gray-200 rounded-lg bg-white shadow ring-1 ring-gray-200">
  @if (header) {
    <div class="px-4 py-5 sm:p-6">
      <ng-container *ngTemplateOutlet="header"></ng-container>
    </div>
  }
  @if (body) {
    <div class="truncate px-4 py-5 sm:p-5">
      <ng-container *ngTemplateOutlet="body"></ng-container>
    </div>
  }

  @if (footer) {
    <div class="px-4 py-5 sm:p-6">
      <ng-container *ngTemplateOutlet="footer"></ng-container>
    </div>
  }
</div>
