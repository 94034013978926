import { Component, Input, OnInit } from "@angular/core";
import { interval } from "rxjs";

@Component({
  selector: "app-carousel",
  standalone: true,
  imports: [],
  templateUrl: "./carousel.component.html",
  styleUrl: "./carousel.component.scss",
})
export class CarouselComponent implements OnInit {
  SLIDE_INTERVAL = 3000;

  @Input({ required: true })
  set images(images: string[]) {
    this._images = images.filter((i) => !!i);
    this.activeImage = 0;
  }

  @Input()
  cover: boolean = false;

  ngOnInit() {
    interval(this.SLIDE_INTERVAL).subscribe(() => this.nextImage());
  }

  _images: string[] = [];

  activeImage: number = 0;

  nextImage() {
    this.activeImage = this.activeImage < this._images.length - 1 ? this.activeImage + 1 : 0;
  }
}
