import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { History, HistoryApi } from "@front/m19-services";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "app-modal-activity-comment",
  templateUrl: "./modal-activity-comment.component.html",
  styleUrls: ["./modal-activity-comment.component.scss"],
})
export class ModalActivityCommentComponent implements OnInit {
  @Input() comment = "";
  @Input() history: History;

  @Output() update: EventEmitter<string> = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef,
    private historyService: HistoryApi,
  ) {}

  ngOnInit(): void {
    this.comment = this.history.comment;
  }

  deleteComment() {
    this.comment = "";
    this.save();
  }

  save() {
    const subscription = this.historyService
      .updateHistory({
        accountId: this.history.accountId,
        marketplace: this.history.marketplace,
        id: this.history.id,
        historyComment: { comment: this.comment },
      })
      .subscribe((res) => {
        if (res) {
          this.update.emit(this.comment);
        }
        subscription.unsubscribe();
        this.bsModalRef.hide();
      });
  }
}
