import { NgTemplateOutlet } from '@angular/common';
import { Component, computed, ContentChild, EventEmitter, input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'ICheckbox',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgTemplateOutlet],
  templateUrl: './icheckbox.component.html',
})
export class ICheckboxComponent implements OnInit {
  inputId = input<string>();
  inputName = input<string>();
  label = input<string>();
  value = input<boolean>();
  control = input<FormControl<any>>(new FormControl<boolean>(false));
  disabled = input(false);
  tooltipValue = input<string>();

  @Output() valueChange = new EventEmitter<boolean>();

  @ContentChild('labelSlot', { static: false }) labelSlot: TemplateRef<any> | undefined;

  idValue = computed(() => {
    return this.inputId() ?? Math.random().toString(36).substring(2, 15);
  });

  checkBoxValue = computed(() => {
    if (this.value()) {
      this.control().setValue(this.value());
    }
    return this.control().value;
  });

  ngOnInit(): void {
    if (this.disabled()) {
      // TODO: update the control to be disabled
      this.control().disable();
    }

    this.control().valueChanges.subscribe((value) => {
      this.valueChange.emit(value as boolean);
    });
  }
}
