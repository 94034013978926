import moment, { Moment } from 'moment-timezone';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AccountType, Currency } from './api-client';
import { AuthService } from './auth.service';
import { OrganizationAccountGroups } from './models';
import { Utils } from './utils/utils';
import { StatsApiClientService } from './stats-api-client.service';
import { Injectable } from '@angular/core';

// TODO merge dateRange & date Aggregation into a single param
export enum DateAggregation {
  hourly = 'hourly',
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
}

export enum Comparison {
  None = 0,
  PreviousPeriod = 1,
  PreviousWeek = 2,
  PreviousMonth = 3,
  Previousyear = 4,
  Custom = 5,
}

type DateAggregationsDef = {
  [key in DateAggregation]: { title: string; abbreviation: string };
};

export const DateAggregations: DateAggregationsDef = {
  [DateAggregation.hourly]: {
    title: 'common.hourly',
    abbreviation: 'H',
  },
  [DateAggregation.daily]: {
    title: 'common.daily',
    abbreviation: 'D',
  },
  [DateAggregation.weekly]: {
    title: 'common.weekly',
    abbreviation: 'W',
  },
  [DateAggregation.monthly]: {
    title: 'common.monthly',
    abbreviation: 'M',
  },
};

export enum MetricsSelectorLocalStorageKey {
  strategy = 'Strategy',
  dashboard = 'Dashboard',
  overview = 'Overview',
  overviewDetails = 'OverviewDetails',
  traffic = 'Traffic',
  salesAndAdvertising = 'SalesAndAdvertising',
  profitAnalytics = 'ProfitAnalytics',
  hourlyStats = 'HourlyStats',
  hourlyCampaignStats = 'HourlyCampaignStats',
  superBoard = 'SuperBoard',
  keywordAsinStats = 'KeywordAsinStats',
  dspStats = 'DspStats',
}

type DateRange = RelativeDateRange | CustomDateRange;

type RelativeDateRange = {
  type: 'relative';
  days: number;
};

type CustomDateRange = {
  type: 'absolute';
  startDate: string; // format YYYY-MM-DD
  endDate: string; // format YYYY-MM-DD
};

const DateRangeLocalStorageKey = 'dateRange';
const StrategyListViewTypeStorageKey = 'strategyListViewType';
const OverviewChartTypeStorageKey = 'overviewChartType';

@Injectable({
  providedIn: 'root',
})
export class UserSelectionService {
  private readonly selectedCurrency = new BehaviorSubject<Currency>(Currency.EUR);
  private readonly periodComparison = new BehaviorSubject<
    | {
        type: Comparison;
        period: string[] | undefined;
      }
    | undefined
  >(undefined);
  private readonly disableDateRangeSelection = new BehaviorSubject<boolean>(false);
  private readonly selectedOrganizations = new BehaviorSubject<OrganizationAccountGroups[] | undefined>(undefined);
  private readonly selectedDateRangeSubject: BehaviorSubject<Moment[]>;

  /** Public observables **/
  public readonly selectedCurrency$ = this.selectedCurrency.asObservable();
  public readonly periodComparison$: Observable<{ type: Comparison; period: string[] | undefined } | undefined> =
    this.periodComparison.asObservable();
  public readonly enablePeriodComparison$: Observable<boolean>;
  public readonly disableDateRangeSelection$ = this.disableDateRangeSelection.asObservable();
  public readonly selectedDateRange$: Observable<Moment[]>;
  public readonly dateRange$: Observable<string[]>;
  // For now, only used for agency-board component
  public readonly selectedOrganizations$: Observable<OrganizationAccountGroups[] | undefined> =
    this.selectedOrganizations.asObservable();

  constructor(private authService: AuthService) {
    // we do not unsubscribe as this service is meant to be a singleton (constructed once, never destructed)
    this.authService.user$.subscribe((x) => x && this.setCurrency(x.defaultCurrency!));
    // init date range from local storage
    this.selectedDateRangeSubject = new BehaviorSubject(this.getInitialDateRange());
    this.selectedDateRange$ = this.selectedDateRangeSubject.asObservable();
    // disable period comparison when date inteval is greater than 92 days
    this.enablePeriodComparison$ = this.selectedDateRange$.pipe(
      map((dr) => {
        const diff = dr[1].diff(dr[0], 'days');
        return diff <= StatsApiClientService.maxComp;
      }),
      shareReplay(1),
    );
    this.dateRange$ = this.selectedDateRange$.pipe(
      map(([minDate, maxDate]) => {
        const selectedDateRangeStr = [Utils.formatMomentDate(minDate), Utils.formatMomentDate(maxDate)];
        return selectedDateRangeStr;
      }),
      shareReplay(1),
    );
  }

  public setSelectedOrganizations(organizations: OrganizationAccountGroups[]) {
    this.selectedOrganizations.next(organizations);
  }

  private getInitialDateRange(): Moment[] {
    const stored = localStorage.getItem(DateRangeLocalStorageKey);
    if (stored) {
      // check validity and remove item from local storage if not valid
      try {
        const savedDateRange: DateRange = JSON.parse(stored);
        if (savedDateRange.type == 'relative') {
          if (savedDateRange.days > 0) {
            return [moment().subtract(savedDateRange.days, 'days'), moment().subtract(1, 'days')];
          }
        } else if (savedDateRange.type == 'absolute') {
          if (savedDateRange.startDate && savedDateRange.endDate) {
            const startDate = moment(savedDateRange.startDate);
            const endDate = moment(savedDateRange.endDate);
            if (startDate < moment() && endDate < moment() && startDate <= endDate) {
              return [startDate, endDate];
            }
          }
        }
      } catch (ex) {
        // invalid JSON in local storage
      }
      localStorage.removeItem(DateRangeLocalStorageKey);
    }
    return [moment().subtract(30, 'days'), moment().subtract(1, 'days')];
  }

  public setCurrency(currency: Currency): void {
    this.selectedCurrency.next(currency);
  }

  public setAbsoluteDateRange(startDate: Moment, endDate: Moment): void {
    if (
      startDate.isSame(this.selectedDateRangeSubject.value[0], 'day') &&
      endDate.isSame(this.selectedDateRangeSubject.value[1], 'day')
    ) {
      return;
    }
    this.selectedDateRangeSubject.next([startDate, endDate]);
    const json = JSON.stringify({
      type: 'absolute',
      startDate: Utils.formatMomentDate(startDate),
      endDate: Utils.formatMomentDate(endDate),
    });
    localStorage.setItem(DateRangeLocalStorageKey, json);
  }

  public setRelativeDateRange(relativeDays: number): void {
    const startDate = moment().subtract(relativeDays, 'days');
    const endDate = moment().subtract(1, 'days');
    if (
      startDate.isSame(this.selectedDateRangeSubject.value[0], 'day') &&
      endDate.isSame(this.selectedDateRangeSubject.value[1], 'day')
    ) {
      return;
    }
    this.selectedDateRangeSubject.next([startDate, endDate]);
    const json = JSON.stringify({
      type: 'relative',
      days: relativeDays,
    });
    localStorage.setItem(DateRangeLocalStorageKey, json);
  }

  /**
   * @deprecated
   * @returns
   */
  public getDateRange(): Date[] {
    return this.selectedDateRangeSubject.value.map((d) => d.toDate());
  }

  public getDateRangeStr(): string[] {
    return this.selectedDateRangeSubject.value.map((d) => Utils.formatMomentDate(d));
  }

  public getPreviousDateRangeStr(): string[] | undefined {
    return this.periodComparison.value?.period;
  }

  public setMetricSelectorData(
    metrics: string[],
    storageName: MetricsSelectorLocalStorageKey | string,
    accountType: AccountType,
  ) {
    if (!storageName) return;
    localStorage.removeItem(`metricSelectorData${storageName}_${accountType}`);
    localStorage.setItem(`metricSelector${storageName}`, JSON.stringify(metrics));
  }

  public setChartMetricSelectorData(
    metrics: string[],
    storageName: MetricsSelectorLocalStorageKey | string,
    accountType: AccountType,
  ) {
    if (!storageName) return;
    localStorage.removeItem(`chartMetricSelectorData${storageName}_${accountType}`); // Remove item since VENDOR/SELLER filter deletion
    localStorage.setItem(`chartMetricSelector${storageName}`, JSON.stringify(metrics));
  }

  public getMetricSelectorData(
    storageName: MetricsSelectorLocalStorageKey | string,
    accountType: AccountType,
  ): string[] {
    localStorage.removeItem(`metricSelectorData${storageName}_${accountType}`);
    const metrics = localStorage.getItem(`metricSelector${storageName}`);
    if (metrics && metrics.length != 0) {
      return JSON.parse(metrics);
    }
    return [];
  }

  public getChartMetricSelectorData(
    storageName: MetricsSelectorLocalStorageKey | string,
    accountType: AccountType,
  ): string[] {
    localStorage.removeItem(`chartMetricSelectorData${storageName}_${accountType}`);
    const metrics = localStorage.getItem(`chartMetricSelector${storageName}`);
    if (metrics && metrics.length != 0) {
      return JSON.parse(metrics);
    }
    return [];
  }

  public setUserCustomMetrics(
    metrics: string[],
    storageName: MetricsSelectorLocalStorageKey | string,
    accountType: AccountType,
  ): void {
    localStorage.setItem(`customMetrics${storageName}_${accountType}`, JSON.stringify(metrics));
  }

  public getUserCustomMetrics(storageName: MetricsSelectorLocalStorageKey, accountType: AccountType): string[] {
    const metrics = localStorage.getItem(`customMetrics${storageName}_${accountType}`);
    if (metrics && metrics.length != 0) {
      return JSON.parse(metrics);
    }
    return [];
  }

  public setUserSelectedMetrics(
    metrics: string[],
    storageName: MetricsSelectorLocalStorageKey,
    accountType: AccountType,
  ): void {
    localStorage.setItem(`selectedMetrics${storageName}_${accountType}`, JSON.stringify(metrics));
  }

  public getUserSelectedMetrics(storageName: MetricsSelectorLocalStorageKey, accountType: AccountType): string[] {
    const metrics = localStorage.getItem(`selectedMetrics${storageName}_${accountType}`);
    if (metrics && metrics.length != 0) {
      return JSON.parse(metrics);
    }
    return [];
  }

  public getUserChartDisplayedPreference(storageName: MetricsSelectorLocalStorageKey): boolean {
    const display = localStorage.getItem(`chartDisplayed${storageName}`);
    return display === 'false' ? false : true;
  }

  public setUserChartDisplayedPreference(storageName: MetricsSelectorLocalStorageKey, chartDisplayed: boolean): void {
    localStorage.setItem(`chartDisplayed${storageName}`, chartDisplayed ? 'true' : 'false');
  }

  public getUserViewTypePreference(): string {
    localStorage.removeItem('viewTypePreference'); // remove old key
    return localStorage.getItem(StrategyListViewTypeStorageKey) ?? '';
  }

  public setUserViewTypePreference(pref: string): void {
    return localStorage.setItem(StrategyListViewTypeStorageKey, pref);
  }

  public getUserOverviewChartTypePreference(): 'lineChart' | 'donuts' {
    return localStorage.getItem(OverviewChartTypeStorageKey) === 'lineChart' ? 'lineChart' : 'donuts';
  }

  public setUserOverviewChartTypePreference(pref: 'lineChart' | 'donuts'): void {
    return localStorage.setItem(OverviewChartTypeStorageKey, pref);
  }

  public togglePeriodComparison(periodComparison: Moment[] | undefined, type: Comparison | undefined) {
    if (!periodComparison || !type) {
      this.periodComparison.next({ type: Comparison.None, period: undefined });
      return;
    }
    const start = periodComparison[0].format('YYYY-MM-DD');
    const end = periodComparison[1].format('YYYY-MM-DD');
    this.periodComparison.next({ type: type, period: [start, end] });
  }

  public disableDateRange(): void {
    this.disableDateRangeSelection.next(true);
  }

  public enabledDateRange(): void {
    this.disableDateRangeSelection.next(false);
  }
}
