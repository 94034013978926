import { Component, EventEmitter, Input, OnInit, Output, signal } from "@angular/core";
import { Marketplace, MatchType, SegmentConfigType, SegmentEx, Targeting } from "@front/m19-services";
import { Option } from "@front/m19-ui";
import { ICON_ADD } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SegmentService } from "libs/m19-services/src/lib/m19-services/segmentService";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";

@UntilDestroy()
@Component({
  selector: "app-add-to-segment-modal",
  templateUrl: "./add-to-segment-modal.component.html",
})
export class AddToSegmentModalComponent implements OnInit {
  @Input()
  segmentType: SegmentConfigType = SegmentConfigType.KeywordSegment;

  @Input()
  set targetingItemValue(value: string) {
    this.targetingItemValues = [value];
  }

  @Input()
  targetingItemValues: string[] = [];
  @Input()
  marketplace: Marketplace;

  creation: boolean;
  matchType = MatchType.exact;
  segments: SegmentEx[];
  readonly SegmentType = SegmentConfigType;
  readonly MatchType = MatchType;
  readonly ICON_ADD = ICON_ADD;

  @Output()
  segmentCreationRequested = new EventEmitter<Targeting[]>();

  filter: RegExp;

  segmentOptions: Option<SegmentEx | null>[] = [];
  selectedSegment = signal<Option<SegmentEx | null> | undefined>(undefined);

  constructor(
    private segmentService: SegmentService,
    private bsModalRef: BsModalRef,
    private toasterService: ToastrService,
  ) {}

  ngOnInit(): void {
    this.segmentService.segmentIndex$.pipe(untilDestroyed(this)).subscribe((segmentIndex) => {
      this.segments = Array.from(segmentIndex.values());

      this.segmentOptions = [
        {
          value: null,
          label: "Create new segment",
        },
        ...this.segments
          .filter((s) => s.segmentType == this.segmentType && s.name)
          .map((s) => ({
            value: s,
            label: s.name,
          })),
      ];
      this.selectedSegment.set(this.segmentOptions[0]);
    });
  }

  selectSegment(segment: Option<SegmentEx | null>) {
    this.selectedSegment.set(segment);
    this.creation = segment == undefined;
  }

  close(): void {
    this.bsModalRef.hide();
  }

  setMatchType(matchType: MatchType) {
    this.matchType = matchType;
  }

  save(): void {
    if (this.selectedSegment()?.value) {
      if (this.segmentType == SegmentConfigType.KeywordSegment) {
        this.segmentService
          .addKeywordsToSegment(
            this.selectedSegment()!.value!.segmentId,
            this.targetingItemValues.map((v) => ({
              targetingValue: v,
              matchType: this.matchType,
            })),
          )
          .pipe(untilDestroyed(this))
          .subscribe({
            next: () => {
              this.toasterService.success(
                `Keyword${this.targetingItemValues.length > 1 ? "s" : ""} ${this.targetingItemValues.slice(0, 3)}${
                  this.targetingItemValues.length > 3 ? "…" : ""
                } successfully added to segment ${this.selectedSegment()!.value!.name}`,
                "Keyword(s) added to segment",
              );
              this.bsModalRef.hide();
            },
            error: (error) => {
              this.toasterService.error(error, "Keyword segment update error");
            },
          });
      } else {
        this.segmentService
          .addAsinsToSegment(this.selectedSegment()!.value!.segmentId, this.targetingItemValues)
          .pipe(untilDestroyed(this))
          .subscribe({
            next: () => {
              this.toasterService.success(
                `ASIN${this.targetingItemValues.length > 1 ? "s" : ""} ${this.targetingItemValues.slice(0, 3)}${
                  this.targetingItemValues.length > 3 ? "…" : ""
                } successfully added to segment ${this.selectedSegment()!.value!.name}`,
                "ASIN(s) added to segment",
              );
              this.bsModalRef.hide();
            },
            error: (error) => {
              this.toasterService.error(error, "Product segment update error");
            },
          });
      }
    } else {
      // request segment creation request
      let segmentItems: Targeting[];
      if (this.segmentType == SegmentConfigType.KeywordSegment) {
        segmentItems = this.targetingItemValues.map((v) => ({ matchType: this.matchType, targetingValue: v }));
      } else {
        segmentItems = this.targetingItemValues.map((v) => ({ matchType: MatchType.asinSameAs, targetingValue: v }));
      }
      this.segmentCreationRequested.emit(segmentItems);
      this.bsModalRef.hide();
    }
  }
}
