import { Injectable } from "@angular/core";
import {
  AccountMarketplace,
  AlgoMode,
  CampaignType,
  CreateStrategyGroupRequest,
  ProductGroup,
  Strategy,
  StrategyAsin,
  StrategyStateEnum,
  StrategyType,
  StrategyUpdateParams,
  Utils,
} from "@front/m19-services";
import { CsvExportService, CsvField, fieldExtractor, simpleField } from "@m19-board/services/csv-export.service";

/**
 * This type:
 * - map a string token found in a CSV to an attribute of Strategy object
 * - implement the conversion logic (from string to the attribute type - and vice-versa)
 *
 * We should have parse(csvField.value(strategy)) == strategy[field]
 *
 * csvField.header is the csv header (in camel case)
 * title is a human readable header
 * placeholder is a human-readable example for the user
 */
type BulkCsvField<T, K extends keyof T & string, V extends T[K]> = {
  field: K;
  title: string;
  placeHolder?: string;
  parse: (input: string) => V;
  csvField: CsvField<T>;
  readonly: boolean;
};

function stringField<T, K extends keyof T & string>(field: K, readonly?: boolean): BulkCsvField<T, K, T[K]> {
  const title = Utils.camelCaseToHuman(Utils.toCamelCase(field));
  return {
    field,
    title,
    parse: (input) => input as T[K],
    csvField: simpleField<T>(field),
    readonly: readonly ?? false,
  };
}

function booleanField<T, K extends keyof T & string>(
  field: K,
  trueLabel: string,
  falseLabel: string,
  header?: string,
  readonly?: boolean,
): BulkCsvField<T, K, T[K]> {
  const csvHeader = header ?? Utils.toCamelCase(field);
  const title = Utils.camelCaseToHuman(csvHeader);
  return {
    field,
    title,
    parse: (value) => parseBoolean(trueLabel)(value) as T[K],
    csvField: fieldExtractor(csvHeader, (strat) => (strat[field] ? trueLabel : falseLabel)),
    readonly: readonly ?? false,
  };
}

function enumField<T, K extends keyof T & string, V>(
  field: K,
  enumType: V,
  header?: string,
  readonly?: boolean,
): BulkCsvField<T, K, T[K]> {
  const csvHeader = header ?? Utils.toCamelCase(field);
  const title = Utils.camelCaseToHuman(csvHeader);
  return {
    field,
    title,
    placeHolder: Object.keys(enumType).join("|"),
    parse: (input) => (enumType[input] ? input : undefined) as T[K],
    csvField: simpleField<T>(field, csvHeader),
    readonly: readonly ?? false,
  };
}

function numericField<T, K extends keyof T & string>(
  field: K,
  type: "float" | "int",
  header?: string,
  readonly?: boolean,
): BulkCsvField<T, K, T[K]> {
  const csvHeader = header ?? Utils.toCamelCase(field);
  const title = Utils.camelCaseToHuman(csvHeader);
  const parseFunction = (value: string) => {
    const parsed = type == "float" ? Number.parseFloat(value) : Number.parseInt(value);
    return (Number.isNaN(parsed) ? undefined : parsed) as T[K];
  };
  return {
    field,
    title,
    parse: parseFunction,
    csvField: fieldExtractor(csvHeader, (strat) => (strat[field] as number)?.toFixed(type == "float" ? 2 : 0) ?? "-"),
    readonly: readonly ?? false,
  };
}

function parseBoolean(trueLabel: string) {
  return (value: string) => value == trueLabel;
}

const StrategyAsinsField: BulkCsvField<Strategy, "asins", StrategyAsin[]> = {
  field: "asins",
  title: "ASINs",
  placeHolder: "ASIN1;ASIN2;ASIN3",
  parse: (input: string) =>
    input
      .split(";")
      .map((x) => ({ asin: x.trim() }))
      .filter((a) => Utils.isValidAsin(a.asin)),
  csvField: fieldExtractor("StrategyAsins", (strat) =>
    strat.asins.length == 0 ? "-" : strat.asins.map((a) => a.asin).join(";"),
  ),
  readonly: false,
};

const StrategyIdField = numericField<Strategy, keyof Strategy>("strategyId", "int");
const StrategyNameField = stringField<Strategy, keyof Strategy>("name");
const StrategyGroupField = numericField<Strategy, keyof Strategy>("strategyGroupId", "int", "StrategyGroupId", true);
const StrategyTypeField = enumField<Strategy, keyof Strategy, typeof StrategyType>(
  "strategyType",
  StrategyType,
  "Type",
  true,
);
const StateField = enumField<Strategy, keyof Strategy, typeof StrategyStateEnum>("state", StrategyStateEnum, "Status");
const AlgoModeField = enumField<Strategy, keyof Strategy, typeof AlgoMode>("algoMode", AlgoMode, "Algorithm");
const AcosTargetField = numericField<Strategy, keyof Strategy>("acosTarget", "float");
const SuggestedBidField = numericField<Strategy, keyof Strategy>("suggestedBid", "float");
const DailyBudgetField = numericField<Strategy, keyof Strategy>("dailyBudget", "float");
const MinDailySpentField = numericField<Strategy, keyof Strategy>("minDailySpend", "float", "MinDailySpent");
const MonthlyBudgetField = numericField<Strategy, keyof Strategy>("monthlyBudget", "float");
const NextMonthlyBudgetField = numericField<Strategy, keyof Strategy>("nextMonthlyBudget", "float");
const AutoAlgoExplorationField = booleanField<Strategy, keyof Strategy>(
  "disableOtherQueries",
  "OFF",
  "ON",
  "AutoAlgoExploration",
);
const AutoTargetCampaignField = booleanField<Strategy, keyof Strategy>(
  "disableAutoSegment",
  "OFF",
  "ON",
  "AutoTargetCampaign",
);
const ProductTargetingField = booleanField<Strategy, keyof Strategy>(
  "disableProductSegment",
  "OFF",
  "ON",
  "ProductTargeting",
);
const ProductGroupIdField = numericField<ProductGroup, "productGroupId">("productGroupId", "int", "ProductGroupId");
const ProductGroupName = stringField<ProductGroup, "productGroupName">("productGroupName");
const ProductGroupAsinsField: BulkCsvField<ProductGroup, "items", string[]> = {
  field: "items",
  title: "ASINs",
  placeHolder: "ASIN1;ASIN2;ASIN3",
  parse: (input: string) =>
    input
      .split(";")
      .map((x) => x.trim())
      .filter((a) => Utils.isValidAsin(a)),
  csvField: fieldExtractor("ASINs", (pg) => (pg.items.length == 0 ? "-" : pg.items.join(";"))),
  readonly: false,
};
const BoostStrategyName = stringField<Strategy & { activatePrimeDayBoost: string }, "name">("name", true);
const BoostActivationField = booleanField<
  Strategy & { activatePrimeDayBoost: string },
  keyof (Strategy & { activatePrimeDayBoost: string })
>("activatePrimeDayBoost", "Yes", "No", "PromoDaysBoost");
const BoostAcosOptimizer = numericField<Strategy & { activatePrimeDayBoost: string }, "primeDayBoost">(
  "primeDayBoost",
  "int",
  "ACOSOptimizer",
);

export type StrategyUploadResult = {
  created: Strategy[];
  updated: Strategy[];
  errors: string[];
};

export type ProductGroupUploadResult = {
  created: ProductGroup[];
  updated: ProductGroup[];
  errors: string[];
};

export type StrategyBulkOperations = {
  bulkData: string;
  creations: StrategyCreation[];
  updates: StrategyUpdate[];
  errors: BulkError[];
};

export type ProductGroupBulkOperations = {
  bulkData: string;
  creations: ProductGroupCreation[];
  updates: ProductGroupUpdate[];
  errors: BulkError[];
};

export type BulkError = {
  lineIndex: number;
  csvRow: string;
  errors: string[];
};

export function isStrategyBulkError(obj: any): obj is BulkError {
  return obj.errors !== undefined;
}

export type StrategyUpdate = {
  lineIndex: number;
  csvRow: string;
  strategyToUpdate: Strategy;
  updatedFields: StrategyUpdateParams;
};

export type ProductGroupUpdate = {
  lineIndex: number;
  csvRow: string;
  productGroupToUpdate: ProductGroup;
  updatedFields: Partial<ProductGroup> & {
    asinsToAdd: string[];
    asinsToDelete: string[];
    asins: string[];
  };
};

export type StrategyCreation = {
  lineIndex: number;
  csvRow: string;
  strategy: Strategy;
  strategyGroup?: Required<CreateStrategyGroupRequest>;
};

export type ProductGroupCreation = {
  lineIndex: number;
  csvRow: string;
  productGroup: ProductGroup;
};

@Injectable()
export class BulkImportService {
  public readonly SpStrategyBulkConfig = [
    StrategyIdField,
    StrategyNameField,
    StrategyGroupField,
    StrategyTypeField,
    StateField,
    AlgoModeField,
    AcosTargetField,
    SuggestedBidField,
    DailyBudgetField,
    MinDailySpentField,
    MonthlyBudgetField,
    NextMonthlyBudgetField,
    AutoAlgoExplorationField,
    AutoTargetCampaignField,
    ProductTargetingField,
    StrategyAsinsField,
  ];

  public readonly SdStrategyBulkConfig = [
    StrategyIdField,
    StrategyNameField,
    StateField,
    AlgoModeField,
    AcosTargetField,
    SuggestedBidField,
    DailyBudgetField,
    MinDailySpentField,
    MonthlyBudgetField,
    NextMonthlyBudgetField,
    AutoAlgoExplorationField,
    StrategyAsinsField,
  ];

  public readonly ProductGroupBulkConfig = [ProductGroupIdField, ProductGroupName, ProductGroupAsinsField];

  public readonly StrategyBoostBulkConfig = [
    StrategyIdField,
    BoostStrategyName,
    BoostActivationField,
    BoostAcosOptimizer,
  ];

  constructor(private csvExportService: CsvExportService) {}

  public exportStrategyCsv(accountMarketplace: AccountMarketplace, campaignType: CampaignType, strategies: Strategy[]) {
    const fields = this.getBulkConfig(campaignType).map((f) => f.csvField);

    this.csvExportService.exportCsv(
      {
        prefix: `${campaignType}_strategy_list`,
        marketplace: accountMarketplace.marketplace,
        accountGroupName: accountMarketplace.accountGroupName,
        fieldSeparator: "\t",
        quoteStrings: "",
      },
      strategies,
      fields,
    );
  }

  public exportProductGroupCsv(accountMarketplace: AccountMarketplace, productGroups: ProductGroup[]) {
    this.csvExportService.exportCsv(
      {
        prefix: `product_group_list`,
        marketplace: accountMarketplace.marketplace,
        accountGroupName: accountMarketplace.accountGroupName,
        fieldSeparator: "\t",
        quoteStrings: "",
      },
      productGroups,
      this.ProductGroupBulkConfig.map((f) => f.csvField),
    );
  }

  public exportPromoDaysBoostCsv(accountMarketplace: AccountMarketplace, strategies: Strategy[]) {
    this.csvExportService.exportCsv(
      {
        prefix: `promo_days`,
        marketplace: accountMarketplace.marketplace,
        accountGroupName: accountMarketplace.accountGroupName,
        fieldSeparator: "\t",
        quoteStrings: "",
      },
      strategies,
      this.StrategyBoostBulkConfig.map((f) => f.csvField),
    );
  }

  public getBulkConfig(campaignType: CampaignType) {
    if (campaignType == CampaignType.SP) {
      return this.SpStrategyBulkConfig;
    }
    if (campaignType == CampaignType.SD) {
      return this.SdStrategyBulkConfig;
    }
    throw `Cannot export CSV row for ${campaignType} strategies`;
  }

  /**
   * Generate placeholder text to place in the input text area
   * @param campaignType
   * @returns
   */
  public getTextAreaPlaceholderText(campaignType: CampaignType): string {
    const bulkConfig = this.getBulkConfig(campaignType);
    return (
      "// Enter strategies to create or update here. Fields should be separated by tabs.\n" +
      "// To create a new strategy, set StrategyId to 0.\n" +
      (campaignType == CampaignType.SP ? "// StrategyGroupId and Type fields cannot be updated.\n" : "") +
      bulkConfig.map((f) => f.placeHolder ?? f.csvField.header).join("\t") +
      "…\n" +
      bulkConfig.map((f) => f.placeHolder ?? f.csvField.header).join("\t") +
      "…\n…"
    );
  }

  public getProductGroupTextAreaPlaceholderText(): string {
    const bulkConfig = this.ProductGroupBulkConfig;
    return (
      "// Enter product groups to create or update here. Fields should be separated by tabs.\n" +
      "// To create a new product group, set ProductGroupId to 0.\n" +
      bulkConfig.map((f) => f.placeHolder ?? f.csvField.header).join("\t") +
      "…\n" +
      bulkConfig.map((f) => f.placeHolder ?? f.csvField.header).join("\t") +
      "…\n…"
    );
  }

  public getStrategyBoostTextAreaPlaceholderText(): string {
    const bulkConfig = this.StrategyBoostBulkConfig;
    return (
      "// Enter strategies where to (de)activate boost. Fields should be separated by tabs.\n" +
      "// Strategy name cannot be updated and will be ignored.\n" +
      "// PromoDaysBoost authorized values are Yes or No.\n" +
      "// ACOSOptimizer authorized values are -50, 0, 25, 50 or 100.\n" +
      bulkConfig.map((f) => f.placeHolder ?? f.csvField.header).join("\t") +
      "\n" +
      bulkConfig.map((f) => f.placeHolder ?? f.csvField.header).join("\t") +
      "\n…"
    );
  }

  public parseCsvRow(row: string[], campaignType: CampaignType): Partial<Strategy> {
    const config = this.getBulkConfig(campaignType);
    if (row.length < config.length) {
      return null;
    }
    const result = {};
    for (let i = 0; i < config.length; i++) {
      const field = config[i];
      result[field.field] = field.parse(row[i]);
    }
    return result;
  }

  public parseProductGroupCsvRow(row: string[]): Partial<ProductGroup> {
    const config = this.ProductGroupBulkConfig;
    if (row.length < config.length) {
      return null;
    }
    const result = {};
    for (let i = 0; i < config.length; i++) {
      const field = config[i];
      result[field.field] = field.parse(row[i]);
    }
    return result;
  }

  public parseStrategyBoostCsvRow(row: string[]): Partial<Strategy & { activatePrimeDayBoost: string }> {
    const config = this.StrategyBoostBulkConfig;
    if (row.length < config.length) {
      return null;
    }
    const result = {};
    for (let i = 0; i < config.length; i++) {
      const field = config[i];
      result[field.field] = field.parse(row[i]);
    }
    return result;
  }

  public getStrategyUpdateParams(change: Partial<Strategy>, originalStrategy: Strategy): StrategyUpdateParams {
    const config = this.getBulkConfig(originalStrategy.campaignType);
    const target = { ...originalStrategy, ...change };
    const updateParams: StrategyUpdateParams = {
      accountId: originalStrategy.accountId,
      marketplace: originalStrategy.marketplace,
      strategyId: originalStrategy.strategyId,
      asinsToAdd: [],
      asinsToDelete: [],
    };
    for (const field of config) {
      if (field.field == "asins") {
        continue;
      }
      if (field.readonly) {
        continue;
      }
      if (target[field.field] !== originalStrategy[field.field]) {
        updateParams[field.field] = target[field.field];
      }
    }
    for (const asin of target.asins) {
      if (!originalStrategy.asins.find((sa) => sa.asin === asin.asin) && !updateParams.asinsToAdd.includes(asin.asin)) {
        updateParams.asinsToAdd.push(asin.asin);
      }
    }
    for (const asin of originalStrategy.asins) {
      if (!target.asins.find((sa) => sa.asin === asin.asin) && !updateParams.asinsToDelete.includes(asin.asin)) {
        updateParams.asinsToDelete.push(asin.asin);
      }
    }
    return updateParams;
  }

  public getProductGroupUpdateParams(
    change: Partial<ProductGroup>,
    originalProductGroup: ProductGroup,
  ): Partial<ProductGroup> & {
    asinsToAdd: string[];
    asinsToDelete: string[];
    asins: string[];
  } {
    const config = this.ProductGroupBulkConfig;
    const target = { ...originalProductGroup, ...change };
    const updateParams: Partial<ProductGroup> & {
      asinsToAdd: string[];
      asinsToDelete: string[];
      asins: string[];
    } = { asinsToAdd: [], asinsToDelete: [], asins: [] };
    for (const field of config) {
      if (field.readonly) {
        continue;
      }
      if (field.field == "items") {
        continue;
      }
      if (target[field.field] !== originalProductGroup[field.field]) {
        updateParams[field.field as string] = target[field.field];
      }
    }
    for (const asin of target.items) {
      if (!originalProductGroup.items.find((a) => a === asin) && !updateParams.asinsToAdd.includes(asin)) {
        updateParams.asinsToAdd.push(asin);
      }
      updateParams.asins.push(asin);
    }
    for (const asin of originalProductGroup.items) {
      if (!target.items.find((a) => a === asin) && !updateParams.asinsToDelete.includes(asin)) {
        updateParams.asinsToDelete.push(asin);
      }
    }
    return updateParams;
  }
}
