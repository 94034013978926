import { BehaviorSubject, map, Observable, switchMap, tap, throwError } from 'rxjs';
import { Marketplace, MatchType, Segment, StrategyApi, Targeting, UpdateSegmentItemsActionEnum } from './api-client';
import { SegmentEx, StrategyEx } from './models';
import { StrategyCache } from './strategy.cache';
import { catchAjaxError, Utils } from './utils';
import { Constant } from './constant';
import { AccountSelectionService } from './accountSelection.service';
import { StrategyCacheReloaded } from './strategyCacheReloaded';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SegmentService {
  private segmentIndex: Map<number, SegmentEx> = new Map();
  private readonly segmentIndexSubject: BehaviorSubject<Map<number, SegmentEx>> = new BehaviorSubject(new Map());
  public readonly segmentIndex$: Observable<Map<number, SegmentEx>> = this.segmentIndexSubject.asObservable();

  constructor(
    private strategyCacheReloaded: StrategyCacheReloaded,
    private strategyCache: StrategyCache,
    private strategyApi: StrategyApi,
    accountSelectionService: AccountSelectionService,
  ) {
    accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(switchMap((x) => this.load(x.accountId, x.marketplace)))
      .subscribe();
  }

  load(accountId: string, marketplace: Marketplace): Observable<Map<number, SegmentEx>> {
    return this.strategyApi.listSegments({ accountId: accountId, marketplace: marketplace }).pipe(map(this.reload));
  }

  private reload = (segments: Segment[]) => {
    this.segmentIndex.clear();
    for (const segment of segments) this.segmentIndex.set(segment.segmentId!, new SegmentEx(segment));
    this.segmentIndexSubject.next(this.segmentIndex);
    this.strategyCacheReloaded.segmentIndexReloaded.next(this.segmentIndex);
    return this.segmentIndex;
  };

  private addSegment(segment: SegmentEx) {
    this.segmentIndex.set(segment.segmentId, segment);
    this.segmentIndexSubject.next(this.segmentIndex);
    this.strategyCache.updateAllSegments(this.segmentIndex);
  }

  public deleteSegment(segmentId: number) {
    this.segmentIndex.delete(segmentId);
    this.segmentIndexSubject.next(this.segmentIndex);
    this.strategyCache.updateAllSegments(this.segmentIndex);
    this.strategyCache.buildAccountConfig();
  }

  public getSegmentById(segmentId: number): SegmentEx | undefined {
    return this.segmentIndex.get(segmentId);
  }

  public getAllSegments(): SegmentEx[] {
    return Array.from(this.segmentIndex.values());
  }

  public createSegment(accountId: string, name: string, marketplace: Marketplace): Observable<SegmentEx> {
    name = name.trim();
    if (name == '') {
      return throwError('Segment Name cannot be empty');
    }
    if (
      this.getAllSegments().find(
        (x) =>
          x.accountId == accountId &&
          (x.marketplace == null || x.marketplace === marketplace) &&
          x.name.toLocaleUpperCase() == name.toLocaleUpperCase(),
      )
    ) {
      return throwError('Segment ' + name + ' already exists');
    }
    if (!Constant.nameRegexp.test(name)) {
      return throwError(
        'Invalid character used, Segment Name can only use characters allowed in Amazon campaign names',
      );
    }
    return this.strategyApi.createSegment({ accountId: accountId, marketplace: marketplace, name: name }).pipe(
      catchAjaxError('Error creating Segment ' + name + ': '),
      map((response) => {
        const segment = response.entity as Segment;
        const newSegment = new SegmentEx(segment);
        this.addSegment(newSegment);
        return newSegment;
      }),
    );
  }

  public deleteSegmentAsync(segmentId: number): Observable<void> {
    if (!this.getSegmentById(segmentId)) {
      return throwError(() => 'Unknown segment ' + segmentId);
    }
    const segment = this.getSegmentById(segmentId)!;
    return this.strategyApi
      .deleteSegment({
        accountId: segment.accountId,
        marketplace: segment.marketplace,
        segmentId: segment.segmentId,
      })
      .pipe(
        tap(() => {
          this.deleteSegment(segmentId);
        }),
        catchAjaxError(),
        map(() => void 0),
      );
  }

  public getAllowedSegmentsForStrategy(strategy: StrategyEx): SegmentEx[] {
    const forbiddenSegments: Record<any, any> = {};

    strategy.tactics.forEach((x) => (forbiddenSegments[x.segmentId]! = true));

    return this.getAllSegments().filter(
      (x) =>
        x.accountId == strategy.accountId &&
        (!x.marketplace || x.marketplace == strategy.marketplace) &&
        !forbiddenSegments[x.segmentId]!,
    );
  }

  public deleteItemsFromSegment(segmentId: number, items: Targeting[]): Observable<SegmentEx> {
    const segment = this.getSegmentById(segmentId);
    if (!segment) {
      return throwError(() => `Error updating Segment: invalid segmentId ${segmentId}`);
    }
    return this.deleteSegmentItemsFromDb(segment, items);
  }

  public updateSegmentName(segmentId: number, name: string): Observable<SegmentEx> {
    const updatedSegment = this.getClonedSegment(segmentId);
    if (!updatedSegment) {
      return throwError(() => 'Segment does not exist');
    }
    updatedSegment.name = name;
    return this.updateSegmentInDb(updatedSegment);
  }

  private getClonedSegment(segmentId: number): SegmentEx | undefined {
    const segment = this.getSegmentById(segmentId);
    if (!segment) {
      return undefined;
    }
    // working on a clone to only update the segment on success
    const clonedSegment = new SegmentEx(segment);
    clonedSegment.items = [...segment.items];
    return clonedSegment;
  }

  private addSegmentItemsInDb(segment: SegmentEx, segmentItems: Targeting[]): Observable<SegmentEx> {
    return this.strategyApi
      .updateSegmentItems({
        accountId: segment.accountId,
        marketplace: segment.marketplace,
        segmentId: segment.segmentId,
        action: UpdateSegmentItemsActionEnum.ADD,
        targeting: segmentItems,
      })
      .pipe(
        map(() => {
          segment.addItems(segmentItems);
          return segment;
        }),
        catchAjaxError('Error adding items to Segment ' + segment.name + ': '),
      );
  }

  private deleteSegmentItemsFromDb(segment: SegmentEx, segmentItems: Targeting[]): Observable<SegmentEx> {
    return this.strategyApi
      .updateSegmentItems({
        accountId: segment.accountId,
        marketplace: segment.marketplace,
        segmentId: segment.segmentId,
        action: UpdateSegmentItemsActionEnum.DELETE,
        targeting: segmentItems,
      })
      .pipe(
        map(() => {
          const dic: Record<MatchType, any> = {
            [MatchType.asinSameAs]: {},
            [MatchType.exact]: {},
            [MatchType.phrase]: {},
          };
          for (const item of segmentItems) {
            dic[item.matchType][item.targetingValue] = true;
          }

          // @ts-ignore
          segment.items = segment.items.filter((x) => !dic[x.matchType][x.targetingValue]); // keep items not in dic;
          return segment;
        }),
        catchAjaxError('Error removing items from Segment ' + segment.name + ': '),
      );
  }

  private updateSegmentInDb(segment: SegmentEx): Observable<SegmentEx> {
    segment.name = segment.name.trim();
    if (segment.name == '') {
      return throwError(() => new Error('Segment Name cannot be empty'));
    }
    if (!Constant.nameRegexp.test(segment.name)) {
      return throwError(
        () =>
          new Error('Invalid character used, Segment Name can only use characters allowed in Amazon campaign names'),
      );
    }
    if (
      this.getAllSegments().find(
        (x) =>
          x.accountId == segment.accountId &&
          (x.marketplace == null || x.marketplace === segment.marketplace) &&
          x.name.toLocaleUpperCase() == segment.name.toLocaleUpperCase(),
      )
    ) {
      return throwError(() => new Error('Segment ' + segment.name + ' already exists'));
    }
    return this.strategyApi
      .updateSegment({
        accountId: segment.accountId,
        marketplace: segment.marketplace,
        segmentId: segment.segmentId,
        name: segment.name,
      })
      .pipe(
        map((response) => {
          const segment = response.entity as Segment;
          const modifiedSegment = new SegmentEx(segment);
          this.addSegment(modifiedSegment);
          return modifiedSegment;
        }),

        catchAjaxError(`Error updating Segment ${segment.name}: `),
      );
  }

  public addAsinsToSegment(segmentId: number, asins: string[]): Observable<SegmentEx> {
    const segment = this.getSegmentById(segmentId);
    if (!segment) {
      return throwError(() => new Error(`Error updating Segment: invalid segmentId ${segmentId}`));
    }
    const initialSize = segment.items.filter((x) => x.matchType == 'asinSameAs').length;
    let count = 0;
    const items = [];
    for (let asin of asins) {
      if (initialSize + count < Constant.maxAsinBySegment) {
        asin = Utils.normalizeASIN(asin);
        if (asin == '') continue;
        if (!Utils.isValidAsin(asin)) {
          return throwError(() => new Error(asin + ': Invalid ASIN'));
        } else if (segment.items.find((x) => x.targetingValue == asin) != undefined) {
          return throwError(() => new Error(asin + ': Duplicate'));
        } else if (items.find((x) => x.targetingValue == asin) != undefined) {
          return throwError(() => new Error(asin + ': Duplicate'));
        } else {
          items.push({
            matchType: MatchType.asinSameAs,
            targetingValue: asin,
          });
          count++;
        }
      } else {
        return throwError(() => new Error('You have reached the maximum number of ' + Constant.maxAsinBySegment));
      }
    }
    return this.addSegmentItemsInDb(segment, items);
  }

  public addKeywordsToSegment(segmentId: number, keywordsToAdd: Targeting[]): Observable<SegmentEx> {
    const segment = this.getSegmentById(segmentId);
    if (!segment) {
      return throwError(() => new Error(`Error updating Segment: invalid segmentId ${segmentId}`));
    }
    const { addedKeywords, errors } = SegmentService.checkKeywords(
      keywordsToAdd,
      segment.items.filter((x) => x.matchType == 'phrase' || x.matchType == 'exact'),
    );
    if (errors.length > 0) {
      return throwError(() => errors.join('. '));
    }
    return this.addSegmentItemsInDb(segment, addedKeywords);
  }

  public static checkKeywords(
    keywords: Targeting[],
    existingKeywords: Targeting[],
    max = Constant.maxKeywordBySegment,
  ) {
    const initialSize = existingKeywords.length;
    let count = 0;
    const errors: string[] = [];
    const items = [...existingKeywords];
    const addedKeywords = [];
    for (const keyword of keywords) {
      if (initialSize + count < max) {
        const normalizedKeyword = Utils.normalizeKeyword(keyword.targetingValue);
        if (normalizedKeyword == '') continue;
        const reason = Utils.isValidKeyword(normalizedKeyword, keyword.matchType);
        if (reason != '') {
          errors.push(keyword.targetingValue + ': ' + reason);
        } else if (items.find((x) => x.targetingValue == normalizedKeyword) != undefined)
          errors.push(keyword.targetingValue + ': Duplicate');
        else {
          const toAdd = {
            matchType: keyword.matchType,
            targetingValue: normalizedKeyword,
          };
          items.push(toAdd);
          addedKeywords.push(toAdd);
          count++;
        }
      } else {
        errors.push(
          'You have reached the maximum number of ' +
            max +
            ' keywords, the last ' +
            (keywords.length - errors.length - count) +
            ' keyword(s) could not be added.',
        );
        break;
      }
    }
    return {
      keywords: items,
      addedKeywords: addedKeywords,
      errors: errors,
    };
  }
}
