<ng-container *transloco="let t">
  <div class="row flex items-center justify-between pl-3 pr-3">
    <p class="mb-0 text-center">
      @if (asinsNumber === 0) {
        <fa-icon
          [icon]="faWaring"
          size="1x"
          class="warning mr-1"
          matTooltip="{{ t('strategy-tactics-card-details.no_asin') }}"
        ></fa-icon>
      }
      {{ t("sd-strategy-creation.asin_selected", { count: asinsNumber }) }}
      <app-limit-warning
        [current]="asinsNumber"
        [limit]="MaxAsins"
        [message]="t('sp-strategy-group-card.too_many_asins_some_functionalities_are_deactivated')"
      ></app-limit-warning>
    </p>
  </div>
  <ul class="tactics">
    @if (strategyTactic) {
      <li class="tactic">
        <div class="tactic-body">
          <p class="tactic-name mb-0">{{ strategyTactic.segment.name }}</p>
          @if (strategyTactic.tacticType === "BLACKLIST") {
            <IBadge color="gray" label="{{ t('common.blacklist') }}" size="xs" />
          }
          @if (strategyTactic.tacticType !== "BLACKLIST" && _strategy.algoMode !== AlgoModeTypes.PRODUCT_LAUNCH) {
            <span class="intensity" (click)="$event.stopPropagation()">
              <input
                type="range"
                #intensity
                [value]="strategyTactic | tacticIntensityPipe"
                min="1"
                max="5"
                step="1"
                class="slider"
                disabled
              />
              <div class="labels">
                <span class="label label-L">
                  <span class="mt-1">L</span>
                </span>
                <span class="label"></span>
                <span class="label label-N">
                  <span class="mt-1">N</span>
                </span>
                <span class="label"></span>
                <span class="label label-H">
                  <span class="mt-1">H</span>
                </span>
              </div>
            </span>
          }
        </div>
      </li>
    }
    @if (_strategy.tactics.length > 1) {
      <li class="tactic">
        <div class="tactic-body">
          <p class="tactic-name smaller mb-0">
            {{ t("strategy-tactics-card-details.other_tactics", { count: strategy.tactics.length - 1 }) }}
          </p>
        </div>
      </li>
    }
  </ul>
</ng-container>
