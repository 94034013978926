<ng-container *transloco="let t">
  <h2>{{ t("dashboard360-layout.my_profile") }}</h2>
  <div class="mx-auto mb-3 flex h-20 w-20 items-center justify-center rounded-full bg-gray-200 text-3xl font-semibold">
    {{ getInitials() }}
  </div>
  <ICard class="mx-auto w-2/4">
    <ng-template #body>
      <form [formGroup]="form" class="mx-auto flex w-full flex-col gap-y-5" (ngSubmit)="saveUser()">
        <IInput [control]="form.controls.userName" [icon]="ICON_USER" />
        <IInput [control]="form.controls.email" [icon]="ICON_EMAIL" error="{{ t('profile-settings.invalid_email') }}" />
        <div class="flex w-full gap-3">
          <div class="flex-1">
            <IButton
              color="white"
              [label]="
                currencyService.getCurrencySymbol(form.controls.defaultCurrency.value) +
                ' ' +
                form.controls.defaultCurrency.value
              "
              [matMenuTriggerFor]="currencyMenu"
              [block]="true"
              class="w-full"
              [icon]="ICON_CHEVRON_DOWN"
              [trailing]="true"
              [spaceBetween]="true"
            />
            <mat-menu #currencyMenu="matMenu">
              @for (currency of currencyService.getCurrencies(); track currency) {
                <ul class="dd-menu w-48">
                  <li (click)="form.controls.defaultCurrency.setValue(Currency[currency])" class="dd-item">
                    {{ currencyService.printCurrency(Currency[currency]) }}
                  </li>
                </ul>
              }
            </mat-menu>
          </div>
          <div class="flex-1">
            <IButton
              color="white"
              [label]="locales[form.controls.locale.value]"
              [matMenuTriggerFor]="localeMenu"
              [icon]="ICON_CHEVRON_DOWN"
              [spaceBetween]="true"
              [trailing]="true"
              [block]="true"
            />
            <mat-menu #localeMenu="matMenu">
              @for (item of locales | keyvalue; track item) {
                <ul class="dd-menu w-48">
                  <li (click)="form.controls.locale.setValue(item.key)" class="dd-item">
                    {{ item.value }}
                  </li>
                </ul>
              }
            </mat-menu>
          </div>
        </div>
        <div class="text-center text-sm text-gray-500">
          {{ t("profile-settings.example_number") }}
          {{
            123456.78
              | currency
                : this.form.controls.defaultCurrency.value
                : "symbol-narrow"
                : "1.0-2"
                : this.form.controls.locale.value
          }}
          / {{ t("profile-settings.date") }} {{ dateExample(this.form.controls.locale.value) }}
        </div>
        <div class="mx-auto flex w-2/4 gap-x-3">
          <IButton label="{{ t('profile-settings.change_password') }}" color="white" (onClick)="changePassword()" />
          <IButton
            label="{{ t('common.save') }}"
            [disabled]="form.invalid"
            [block]="true"
            class="flex-1"
            type="submit"
            [loading]="isLoading"
          />
        </div>
      </form>
    </ng-template>
  </ICard>
</ng-container>
