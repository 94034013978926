<div
  class="relative"
  (mouseenter)="submenu = true"
  (mouseleave)="submenu = false"
  [attr.data-testid]="'card-' + strategyGroup.strategyGroupName"
  *transloco="let t"
>
  <div
    class="submenu btn-group hidden"
    [class.!flex]="submenu || submenuhover"
    (mouseenter)="submenuhover = true"
    (mouseleave)="submenuhover = false"
  >
    @if (strategyGroup.strategies.length === 1) {
      <button
        type="button"
        class="submenu-btn btn btn-secondary"
        [disabled]="readonly"
        (click)="changeStatus()"
        matTooltipPosition="above"
        matTooltip="{{
          strategyGroup.strategies[0].state === State.ENABLED
            ? t('sp-strategy-group-card.pause_strategy')
            : t('sp-strategy-group-card.activate_strategy')
        }}"
      >
        <fa-icon [icon]="strategyGroup.strategies[0].state === State.ENABLED ? faPause : faPlay"></fa-icon>
      </button>
    }
    <button
      data-testid="btn-delete"
      [disabled]="readonly"
      type="button"
      class="submenu-btn btn btn-secondary"
      (click)="deleteStrategyGroup()"
      matTooltipPosition="above"
      matTooltip="{{ t('sp-strategy-group-card.delete_strategy_group') }}"
    >
      <fa-icon [icon]="faTrash"></fa-icon>
    </button>
  </div>

  <div
    [routerLink]="
      strategyGroup.strategies?.length === 1
        ? strategyGroupUrlPrefix + strategyGroup.strategyGroupId + '/' + strategyGroup.productStrategies[0].strategyId
        : strategyGroupUrlPrefix + strategyGroup.strategyGroupId
    "
    queryParamsHandling="merge"
  >
    <div class="strategy-group card-perso left-child right-child border-shadow card mb-0 align-top">
      <div class="strategy-status card-perso-header top-child card-header flex items-center pb-0 pr-2">
        <div
          class="pb-0 pr-0 pt-0"
          matTooltipPosition="above"
          [matTooltip]="
            hasLiveStategies() ? t('subscription-card.status_active') : t('creative-display.status_inactive')
          "
        >
          @if (hasLiveStategies()) {
            <fa-icon [icon]="faPlayCircle" class="activated"></fa-icon>
          } @else {
            <fa-icon [icon]="faPauseCircle" class="desactivated"></fa-icon>
          }
        </div>
        <h5 class="card-name mb-0 ml-2 mr-2" [matTooltip]="strategyGroup.strategyGroupName" matTooltipPosition="below">
          {{ strategyGroup.strategyGroupName }}
        </h5>
        <div>
          @if (
            strategyGroup.strategies?.length === 1 && strategyGroup.strategies[0].algoMode === AlgoMode.ACOS_TARGET
          ) {
            <app-fbl-mode [strategy]="strategyGroup.strategies[0]" [size]="FblModeSize.small"></app-fbl-mode>
          }
        </div>
      </div>
      <div class="card-perso-body bottom-child card-body">
        @if (strategyGroup.strategies?.length === 1) {
          <app-algo-target-renderer
            [algoTarget]="strategyGroup.strategies[0]"
            [locale]="locale()"
            [currency]="currency()"
            [readonly]="readonly"
            [addLabel]="true"
            class="text-dark"
          ></app-algo-target-renderer>
          <app-strategy-tactics-card-details
            [strategy]="strategyGroup.strategies[0]"
          ></app-strategy-tactics-card-details>
        } @else {
          <div class="p-2">
            <p>
              {{ t("sd-strategy-creation.asin_selected", { count: strategyGroup.asins.length }) }}
              <app-limit-warning
                [current]="strategyGroup.asins.length"
                [limit]="MaxAsins"
                [message]="t('sp-strategy-group-card.too_many_asins_some_functionalities_are_deactivated')"
              ></app-limit-warning>
            </p>
          </div>
          <div class="p-2">
            @if (strategyGroup.brandStrategies.length > 0) {
              <p>
                {{ t("sp-strategy-group-card.brand_defense", { count: strategyGroup.brandStrategies.length }) }}
              </p>
            }
            @if (strategyGroup.keywordStrategies.length > 0) {
              <p>
                {{ t("sp-strategy-group-card.focus", { count: strategyGroup.keywordStrategies.length }) }}
              </p>
            }
            <p>
              {{ t("sp-strategy-group-card.main_strategy", { count: strategyGroup.productStrategies.length }) }}
            </p>
          </div>
        }
      </div>
    </div>
  </div>
</div>
