import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { faRocket } from "@fortawesome/free-solid-svg-icons";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  AccountMarketplaceService,
  AccountSelectionService,
  AccountState,
  OrganizationAccountGroupService,
} from "@front/m19-services";
import { BoardType, LayoutSelectorService } from "@m19-board/layout-selector.service";
import { keywordRankingAvailableFor } from "@m19-board/tracking/KeywordRankingAvailability";
import { bufferTime, combineLatest, filter, map } from "rxjs";

@UntilDestroy()
@Component({
  selector: "app-keyword-ranking-overlay",
  templateUrl: "./keyword-ranking-overlay.component.html",
  styleUrls: ["./overlay.component.scss"],
})
export class KeywordRankingOverlayComponent implements OnInit {
  readonly faRocket = faRocket;
  @Input()
  pageName: string;
  @Input()
  feature: string;

  notActivatedAccount = false;
  noAccountGroupSetup = false;
  boardType: BoardType;
  loading = true;
  notSupportedMarketplace = false;
  noBillingPlanSupport = false;
  readonly BoardType = BoardType;

  constructor(
    private accountMarketplaceService: AccountMarketplaceService,
    private layoutSelectorService: LayoutSelectorService,
    private accountSelectionService: AccountSelectionService,
    private organizationServices: OrganizationAccountGroupService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.boardType = this.layoutSelectorService.getBoardType();
    this.accountMarketplaceService.accountMarketplaces$
      .pipe(
        untilDestroyed(this),
        bufferTime(1000),
        filter((b) => b.length > 0),
        map((b) => b[b.length - 1]),
      )
      .subscribe((accountMarketplaces) => {
        this.noAccountGroupSetup =
          accountMarketplaces.length == 0 || accountMarketplaces.findIndex((am) => am.accountGroupId > 0) < 0;
        this.loading = false;
      });
    combineLatest([
      this.accountSelectionService.singleAccountMarketplaceSelection$,
      this.organizationServices.allOrganizationAccountGroups$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([am, allOrgs]) => {
        this.notActivatedAccount = am.state != AccountState.BIDDER_ON;
        this.notSupportedMarketplace = !keywordRankingAvailableFor(am.marketplace);
        const selectedOrg = allOrgs.find((x) => x.id == am.resourceOrganizationId);
        const plan = selectedOrg?.getBillingPlan()?.plan;
        if (!plan) {
          this.noBillingPlanSupport = true;
        } else {
          this.noBillingPlanSupport = false;
        }
      });
  }

  billingRegistration(): void {
    switch (this.layoutSelectorService.getBoardType()) {
      case BoardType.M19:
        this.router.navigate(["billing-registration"]);
        break;
      case BoardType.WHITELABEL_SELFSERVICE:
        this.router.navigate(["freemium"]);
        break;
      default:
      // not possible
    }
  }
}
