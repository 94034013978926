import { PdfApi } from './api-client';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  constructor(private pdfApi: PdfApi) {}

  uploadPdfData(
    pdfData: Blob,
    email: string,
    domain: string,
    subject: string,
    filename: string,
    message?: string,
  ): Subject<boolean> {
    const res: Subject<boolean> = new Subject();
    this.pdfApi
      .uploadPDF({
        email: email,
        domain: domain,
        subject: subject,
        asset: pdfData,
        message: message,
        filename: filename,
      })
      .subscribe(
        () => {
          res.next(true);
        },
        () => {
          res.next(false);
        },
      );
    return res;
  }
}
