import { formatNumber } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { faInfo, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  AccountMarketplace,
  AccountMarketplaceService,
  AccountSelectionService,
  AccountType,
  AllVendorInventory,
  AsinService,
  AuthService,
  CampaignType,
  Currency,
  currencyRate,
  currencyRateOrderStat,
  CurrencyStat,
  emptyOrderStat,
  FbaStorageFees,
  FbaStorageFeesApi,
  GlobalSettlementFees,
  Marketplace,
  MarketplaceCogsByAsin,
  marketplaceToCurrencyRate,
  Metric,
  Order,
  OrderApi,
  OrderStats,
  orderToOrderStats,
  StatsApi,
  sumOrderStat,
  UserSelectionService,
  Utils,
} from "@front/m19-services";
import { AdStatsEx } from "@front/m19-services";

import {
  COST_OF_GOODS,
  FBA_FEE,
  FBA_STORAGE_FEE,
  getOrderMetricOverviewDetails,
  ORDER_GLOBAL_ADV,
  ORDER_GLOBAL_FEE,
  ORDER_GLOBAL_MARGIN,
  ORDER_GLOBAL_PROMOTION,
  ORDER_GLOBAL_SALES,
  ORDER_GLOBAL_SHIPPING_GIFT_WRAP,
  ORDER_SALES,
  PROFIT_WITH_GLOBAL_FEES,
  REFUND_SALES,
  REIMBURSEMENT,
} from "@m19-board/models/MetricsDef";
import { ExportToCsv } from "export-to-csv";
import moment from "moment-timezone";
import { combineLatest, forkJoin, Observable } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";

export class Row {
  name: string;
  data: Map<string, string>;
  metricId: string;
  isGlobalMetric = true;
  isEmpty: boolean;
  tooltip: string;
}

export class MarketplaceOrders {
  constructor(
    public marketplace: Marketplace,
    public orders: Array<Order>,
  ) {}
}

export class MarketplaceAdCosts {
  constructor(
    public marketplace: Marketplace,
    public costs: Array<AdStatsEx>,
  ) {}
}

export class MarketplaceStorageFees {
  constructor(
    public marketplace: Marketplace,
    public storageFees: Array<FbaStorageFees>,
  ) {}
}

export class MarketplaceGlobalFees {
  constructor(
    public marketplace: Marketplace,
    public globalFees: Array<GlobalSettlementFees>,
  ) {}
}

@UntilDestroy()
@Component({
  selector: "app-profit-and-loss",
  templateUrl: "./profit-and-loss.component.html",
  styleUrls: ["./profit-and-loss.component.scss"],
})
export class ProfitAndLossComponent implements OnInit, OnDestroy {
  readonly CHART_METRICS: Metric<CurrencyStat>[] = [
    ORDER_GLOBAL_SALES,
    ORDER_GLOBAL_PROMOTION,
    ORDER_GLOBAL_SHIPPING_GIFT_WRAP,
    ORDER_GLOBAL_ADV,
    ORDER_GLOBAL_FEE,
    COST_OF_GOODS,
    PROFIT_WITH_GLOBAL_FEES,
    ORDER_GLOBAL_MARGIN,
  ];

  readonly DETAILED_METRICS = new Set<string>([ORDER_GLOBAL_SALES.id, ORDER_GLOBAL_FEE.id, ORDER_GLOBAL_ADV.id]);

  readonly faPlus = faPlus;
  readonly faMinus = faMinus;
  readonly info = faInfo;

  loading = false;
  currency: Currency = Currency.USD;
  locale: string;

  orderStatsByMonth: Map<string, OrderStats> = new Map();
  marketplacesByMonth: Map<string, Set<Marketplace>> = new Map();
  minGlobalFeesMonthByMarketplace: Map<Marketplace, string> = new Map();
  minMonthWithoutEstimation: string = undefined;
  marketplaces: Set<Marketplace> = new Set();
  marketplacesWithoutData: Array<Marketplace> = [];
  detailedMetricIds: Set<string> = new Set();
  firstCol = "metric";
  displayedColumns = [this.firstCol];
  vendorSelected = false;

  dataSource: MatTableDataSource<Row> = new MatTableDataSource<Row>([]);

  constructor(
    private accountSelectionService: AccountSelectionService,
    private userSelectionService: UserSelectionService,
    private authService: AuthService,
    private asinService: AsinService,
    private orderService: OrderApi,
    private statsService: StatsApi,
    private fbaStorageFeeService: FbaStorageFeesApi,
    private accountMarketplaceService: AccountMarketplaceService,
  ) {}

  ngOnInit(): void {
    this.userSelectionService.disableDateRange();
    this.accountSelectionService.updateSingleMarketplaceMode(false);
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => (this.locale = user.locale));

    const orders$: Observable<MarketplaceOrders[]> = this.accountSelectionService.accountMarketplacesSelection$.pipe(
      tap((_) => (this.loading = true)),

      switchMap((ams: AccountMarketplace[]) =>
        forkJoin(
          ams.map((am) =>
            this.orderService
              .getMonthlyOrders({
                accountId: am.accountId,
                marketplace: am.marketplace,
                minDate: Utils.formatDateForApiFromToday(-400),
                maxDate: Utils.formatDateForApiFromToday(0),
              })
              .pipe(map((o) => new MarketplaceOrders(am.marketplace, o))),
          ),
        ),
      ),
    );

    const cogs$: Observable<MarketplaceCogsByAsin[]> = this.accountSelectionService.accountMarketplacesSelection$.pipe(
      tap((_) => (this.loading = true)),
      switchMap((ams: AccountMarketplace[]) => this.asinService.getAllCostOfGoods(ams)),
    );

    const dailyOrdersUnit$: Observable<MarketplaceOrders[]> =
      this.accountSelectionService.accountMarketplacesSelection$.pipe(
        tap((_) => (this.loading = true)),
        switchMap((ams: AccountMarketplace[]) =>
          forkJoin(
            ams.map((am: AccountMarketplace) =>
              this.orderService
                .getDailyOrderQuantitiesPerAsin({
                  accountId: am.accountId,
                  marketplace: am.marketplace,
                  minDate: Utils.formatDateForApiFromToday(-400),
                  maxDate: Utils.formatDateForApiFromToday(0),
                })
                .pipe(map((o: Order[]) => new MarketplaceOrders(am.marketplace, o))),
            ),
          ),
        ),
      );

    const storageFee$: Observable<MarketplaceStorageFees[]> =
      this.accountSelectionService.accountMarketplacesSelection$.pipe(
        tap((_) => (this.loading = true)),
        switchMap((ams: AccountMarketplace[]) =>
          forkJoin(
            ams.map((am: AccountMarketplace) =>
              this.fbaStorageFeeService
                .getFbaStorageFees({
                  accountId: am.accountId,
                  marketplace: am.marketplace,
                  minDate: Utils.formatDateForApiFromToday(-400),
                  maxDate: Utils.formatDateForApiFromToday(0),
                })
                .pipe(map((s: FbaStorageFees[]) => new MarketplaceStorageFees(am.marketplace, s))),
            ),
          ),
        ),
      );

    const adCosts$: Observable<MarketplaceAdCosts[]> = this.accountSelectionService.accountMarketplacesSelection$.pipe(
      tap((_) => (this.loading = true)),
      switchMap((ams: AccountMarketplace[]) =>
        forkJoin<MarketplaceAdCosts[]>(
          ams.map((am: AccountMarketplace) =>
            this.statsService
              .getMonthlyCost({
                accountId: am.accountId,
                marketplace: am.marketplace,
                minDate: Utils.formatDateForApiFromToday(-400),
                maxDate: Utils.formatDateForApiFromToday(0),
              })
              .pipe(map((o: AllVendorInventory[]) => new MarketplaceAdCosts(am.marketplace, o))),
          ),
        ),
      ),
    );

    const globalFees$: Observable<MarketplaceGlobalFees[]> =
      this.accountSelectionService.accountMarketplacesSelection$.pipe(
        tap((_) => (this.loading = true)),
        switchMap((accountMarketplaces: AccountMarketplace[]) =>
          forkJoin<MarketplaceGlobalFees[]>(
            accountMarketplaces.map((am: AccountMarketplace) =>
              this.statsService
                .getGlobalSettlementFees({
                  accountId: am.accountId,
                  marketplace: am.marketplace,
                  minDate: Utils.formatDateForApiFromToday(-400),
                  maxDate: Utils.formatDateForApiFromToday(0),
                })
                .pipe(map((f: GlobalSettlementFees[]) => new MarketplaceGlobalFees(am.marketplace, f))),
            ),
          ),
        ),
      );

    this.userSelectionService.selectedCurrency$.pipe(untilDestroyed(this)).subscribe((currency) => {
      const previousCurrency: Currency = this.currency;
      this.currency = currency;

      if (previousCurrency != this.currency) {
        this.orderStatsByMonth.forEach((value, _) => currencyRateOrderStat(value, this.currency));
        this.computeDatasource();
      }
    });

    combineLatest([
      globalFees$,
      dailyOrdersUnit$,
      cogs$,
      orders$,
      adCosts$,
      storageFee$,
      this.accountSelectionService.accountMarketplacesSelection$.pipe(
        untilDestroyed(this),
        tap((_) => (this.loading = true)),
      ),
    ]).subscribe(([globalFees, dailyOrdersUnit, cogs, orders, adCosts, storage, selection]) => {
      const monthlyCogsByMarketplace = this.computeCogs(cogs, dailyOrdersUnit);

      this.orderStatsByMonth.clear();
      this.marketplacesByMonth.clear();
      this.marketplaces.clear();
      this.minGlobalFeesMonthByMarketplace.clear();
      const marketplacesWithoutDataSet: Set<Marketplace> = new Set();
      selection.forEach((am) => {
        this.marketplaces.add(am.marketplace);
        marketplacesWithoutDataSet.add(am.marketplace);
      });

      this.computeOrders(orders, marketplacesWithoutDataSet);
      this.processGlobalFees(globalFees);
      this.processAdCosts(adCosts);
      this.processStorageFees(storage);

      this.marketplacesWithoutData = Array.from(marketplacesWithoutDataSet);
      this.vendorSelected = this.containsVendor(marketplacesWithoutDataSet, selection);
      // add cogs
      this.marketplaces.forEach((m) => {
        if (monthlyCogsByMarketplace.has(m)) {
          monthlyCogsByMarketplace.get(m).forEach((cogs, month) => {
            const c = cogs * marketplaceToCurrencyRate(m, this.currency);
            if (!this.orderStatsByMonth.has(month)) {
              const empty = emptyOrderStat(undefined, month, this.currency);
              empty.marketplace = m;
              this.orderStatsByMonth.set(month, empty);
            }
            this.orderStatsByMonth.get(month).costOfGoods -= c;
            this.orderStatsByMonth.get(month).profit -= c;
          });
        }
      });

      this.computeDatasource();

      if (this.minGlobalFeesMonthByMarketplace.size > 0) {
        this.minMonthWithoutEstimation = Array.from(this.minGlobalFeesMonthByMarketplace.values()).reduce(
          (prev, current) => (prev > current ? prev : current),
        );
        if (this.displayedColumns.length > 1 && this.minMonthWithoutEstimation <= this.displayedColumns[1]) {
          this.minMonthWithoutEstimation = undefined;
        }
      } else {
        this.minMonthWithoutEstimation = undefined;
      }

      if (this.minMonthWithoutEstimation) {
        this.minMonthWithoutEstimation = moment
          .utc(
            new Date(
              parseInt(this.minMonthWithoutEstimation.slice(0, 4)),
              parseInt(this.minMonthWithoutEstimation.slice(5, 7)),
              1,
            ),
          )
          .format("MMMM yyyy");
      }
      this.loading = false;
    });

    // display fake data if no account marketplace available
    this.accountMarketplaceService.accountMarketplaces$.pipe(untilDestroyed(this)).subscribe((accountMarketplaces) => {
      const noAccountGroupSetup =
        accountMarketplaces.length == 0 || accountMarketplaces.findIndex((am) => am.accountGroupId > 0) < 0;
      if (noAccountGroupSetup) {
        //this.loading = false;
      }
    });
  }

  private computeOrders(orders: MarketplaceOrders[], marketplacesWithoutDataSet: Set<Marketplace>) {
    orders
      .filter((d) => {
        return this.marketplaces.has(d.marketplace);
      })
      .forEach((x) =>
        x.orders.forEach((o) => {
          marketplacesWithoutDataSet.delete(x.marketplace);
          const month = this.formatDateToMonth(o.day);
          let stat = orderToOrderStats(o, this.currency, 0);
          if (!this.marketplacesByMonth.has(month)) {
            this.marketplacesByMonth.set(month, new Set());
          }
          this.marketplacesByMonth.get(month).add(x.marketplace);

          if (this.orderStatsByMonth.has(month)) {
            stat = sumOrderStat(this.orderStatsByMonth.get(month), stat);
          }
          this.orderStatsByMonth.set(month, stat);
        }),
      );
  }

  private processGlobalFees(globalFees: MarketplaceGlobalFees[]) {
    globalFees
      .filter((d) => {
        return this.marketplaces.has(d.marketplace);
      })
      .forEach((x) =>
        x.globalFees.forEach((f) => {
          const month = this.formatDateToMonth(f.date);

          if (this.minGlobalFeesMonthByMarketplace.has(x.marketplace)) {
            const date = this.minGlobalFeesMonthByMarketplace.get(x.marketplace);
            if (month < date) this.minGlobalFeesMonthByMarketplace.set(x.marketplace, month);
          } else {
            this.minGlobalFeesMonthByMarketplace.set(x.marketplace, month);
          }

          const rate = marketplaceToCurrencyRate(x.marketplace, this.currency);
          const stat = this.orderStatsByMonth.get(month);
          if (stat) {
            const liquidations = f.liquidations * rate || 0;
            const longTermStorageFee = f.longTermStorageFee * rate || 0;
            const reimbursement = f.reimbursement * rate || 0;
            const reimbursementClawback = f.reimbursementClawback * rate || 0;
            const internationalFreight = f.internationalFreight * rate || 0;
            const other = f.other * rate || 0;

            stat.liquidations = stat.liquidations ? stat.liquidations + liquidations : liquidations;
            stat.fbaStorageFee = stat.fbaStorageFee ? stat.fbaStorageFee + longTermStorageFee : longTermStorageFee;
            stat.internationalFreight = stat.internationalFreight
              ? stat.internationalFreight + internationalFreight
              : internationalFreight;
            stat.otherFee = stat.otherFee ? stat.otherFee + other : other;
            stat.reimbursement = stat.reimbursement ? stat.reimbursement + reimbursement : reimbursement;
            stat.reimbursementClawback = stat.reimbursementClawback
              ? stat.reimbursementClawback + reimbursementClawback
              : reimbursementClawback;

            const globalFee = liquidations + internationalFreight + longTermStorageFee + other;

            stat.fee += globalFee;
            stat.globalSales += reimbursement + reimbursementClawback;

            stat.profit += globalFee + reimbursement + reimbursementClawback;
          }
        }),
      );
  }

  private processAdCosts(adCosts: MarketplaceAdCosts[]) {
    adCosts
      .filter((d) => {
        return this.marketplaces.has(d.marketplace);
      })
      .forEach((x) =>
        x.costs.forEach((c) => {
          const rate = currencyRate(c, this.currency);
          const month = this.formatDateToMonth(c.date);
          const stat = this.orderStatsByMonth.get(month);
          if (stat) {
            const cost = c.cost * rate || 0;
            stat.advertising -= cost;
            stat.profit -= cost;
            switch (c.campaignType) {
              case CampaignType.SB:
                stat.sbAdvertising -= cost || 0;
                break;
              case CampaignType.SP:
                stat.spAdvertising -= cost || 0;
                break;
              case CampaignType.SD:
              case CampaignType.SDR:
                stat.sdAdvertising -= cost || 0;
                break;
            }
          }
        }),
      );
  }

  private processStorageFees(storage: MarketplaceStorageFees[]): void {
    storage
      .filter((d) => {
        return this.marketplaces.has(d.marketplace);
      })
      .forEach((x) =>
        x.storageFees.forEach((s) => {
          const rate = marketplaceToCurrencyRate(x.marketplace, this.currency);
          const month = this.formatDateToMonth(s.monthOfCharge);
          const stat = this.orderStatsByMonth.get(month);
          if (stat) {
            const fee = -s.monthlyStorageFee * rate || 0;
            stat.fbaStorageFee = stat.fbaStorageFee ? stat.fbaStorageFee + fee : fee;
            stat.fee += fee;
            stat.profit += fee;
          }
        }),
      );
  }

  toggleMetricDetails(row: Row) {
    if (!this.DETAILED_METRICS.has(row.metricId)) {
      return;
    }
    if (this.detailedMetricIds.has(row.metricId)) {
      this.detailedMetricIds.delete(row.metricId);
    } else {
      this.detailedMetricIds.add(row.metricId);
    }
    this.computeDatasource();
  }

  private formatDateToMonth(date: string): string {
    return date.substring(0, 7);
  }

  containsVendor(marketplaces: Set<Marketplace>, accountMarkeplaceSelection: AccountMarketplace[]) {
    if (accountMarkeplaceSelection) {
      return (
        accountMarkeplaceSelection
          .filter((a) => marketplaces.has(a.marketplace))
          .map((x) => x.accountType)
          .filter((x) => x == AccountType.VENDOR).length > 0
      );
    }
    return false;
  }

  createRow(metric: Metric<CurrencyStat>, months: string[], isGlobal: boolean): Row {
    const r = new Row();
    r.metricId = metric.id;
    r.isGlobalMetric = isGlobal;
    r.name = metric.title;
    r.data = new Map<string, string>();
    r.isEmpty = true;

    if (r.metricId == ORDER_GLOBAL_SALES.id) {
      r.tooltip = "excluding taxes";
    }

    if (r.metricId == ORDER_SALES.id) {
      r.tooltip = "Sales are aggregated at the purchased date";
    }

    if (r.metricId == REIMBURSEMENT.id) {
      r.tooltip =
        "Includes all FBA Inventory Reimbursement aggregated at the posted date of the settlement except " +
        "the reversal reimbursements that are aggregated at the purchased date of the related order if any";
    }

    if (r.metricId == REFUND_SALES.id) {
      r.tooltip =
        "Includes Chargeback, A-to-Z guarante and Order refunds. The refunds are aggregated at the purchased date of the related order";
    }

    if (r.metricId == FBA_STORAGE_FEE.id) {
      r.tooltip = "Includes storage, long term storage and storage overage fees";
    }

    if (r.metricId == FBA_FEE.id) {
      r.tooltip = "FBA fulfillment fee";
    }

    months.forEach((d) => {
      const orderStat = this.orderStatsByMonth.get(d);
      if (metric.id == ORDER_GLOBAL_MARGIN.id) {
        r.data.set(d, metric.format(orderStat, this.locale, this.currency));
      } else {
        const val = metric.value(orderStat);
        if (val != 0) r.isEmpty = false;
        r.data.set(
          d,
          val < 0 ? "(" + formatNumber(val * -1, this.locale, "1.0-0") + ")" : formatNumber(val, this.locale, "1.0-0"),
        );
      }
    });
    return r;
  }

  private parseYearMonth(isoDate: string): Array<number> {
    return isoDate.split("-").map((x) => parseInt(x));
  }

  computeDatasource() {
    const data = new Array<Row>();
    // remove months without all marketplaces
    let allMonths = [];
    this.marketplacesByMonth.forEach((marketplaces, m) => {
      if (marketplaces.size == this.marketplaces.size - this.marketplacesWithoutData.length) allMonths.push(m);
    });

    allMonths = allMonths.sort((a, b) => (a > b ? -1 : 1));
    let consecutiveMonths = [];

    const nbMonth = allMonths.length;

    if (nbMonth > 0) {
      consecutiveMonths.push(allMonths[0]);
    }

    if (nbMonth > 1) {
      // detect consecutive months
      for (let _i = 1; _i < nbMonth; _i++) {
        const curr = new Date();
        const target = new Date();
        curr.setUTCFullYear(this.parseYearMonth(allMonths[_i])[0], this.parseYearMonth(allMonths[_i])[1], 1);
        curr.setHours(0, 0, 0, 0);
        target.setUTCFullYear(
          this.parseYearMonth(allMonths[_i - 1])[0],
          this.parseYearMonth(allMonths[_i - 1])[1] - 1,
          1,
        );
        target.setHours(0, 0, 0, 0);
        if (Utils.formatDateForApi(curr) == Utils.formatDateForApi(target)) {
          consecutiveMonths.push(allMonths[_i]);
        } else {
          break;
        }
      }
    }
    consecutiveMonths = consecutiveMonths.sort();

    const col = [this.firstCol];
    consecutiveMonths.forEach((d) => col.push(d));
    this.displayedColumns = col;

    this.CHART_METRICS.forEach((m) => {
      const r = this.createRow(m, consecutiveMonths, true);
      data.push(r);
      if (this.detailedMetricIds.has(r.metricId)) {
        getOrderMetricOverviewDetails(m).forEach((detail) => {
          const detailedRow = this.createRow(detail, consecutiveMonths, false);
          if (!detailedRow.isEmpty) data.push(detailedRow);
        });
      }
    });
    this.dataSource.data = data;
  }

  computeCogs(allCogs: MarketplaceCogsByAsin[], allOrders: MarketplaceOrders[]): Map<Marketplace, Map<string, number>> {
    const cogsByMarketplace: Map<Marketplace, Map<string, number>> = new Map();
    for (const marketplaceCogs of allCogs) {
      const marketplace = marketplaceCogs.marketplace;
      if (!cogsByMarketplace.has(marketplace)) {
        cogsByMarketplace.set(marketplace, new Map());
      }
      const cogsOfM = cogsByMarketplace.get(marketplace);

      let orders: Order[] = [];
      const cogsByAsin = marketplaceCogs.cogs;
      for (const marketplaceOrders of allOrders) {
        if (marketplaceOrders.marketplace == marketplace) {
          orders = marketplaceOrders.orders;
          break;
        }
      }

      for (const order of orders) {
        if (!cogsByAsin.has(order.asin)) continue;
        const month = this.formatDateToMonth(order.day);
        if (!cogsOfM.has(month)) {
          cogsOfM.set(month, 0);
        }
        let cog = 0;
        const sumQuantities = order.sumQuantities || 0;

        let dateCog: [string, number] = undefined;
        for (const curr of cogsByAsin.get(order.asin)) {
          // cogs are sorted by date
          if (curr[0] <= order.day) {
            dateCog = curr;
          } else {
            break;
          }
        }
        if (dateCog) {
          cog = dateCog[1] * sumQuantities;
          cogsOfM.set(month, cogsOfM.get(month) + cog);
        }
      }
    }
    return cogsByMarketplace;
  }

  downloadFile(): void {
    const allStats = [];
    for (const orderStat of this.dataSource.data) {
      const stat = {};
      orderStat.name;
      stat[""] = orderStat.name;
      for (const m of orderStat.data.keys()) {
        let data = orderStat.data.get(m);
        if (data.startsWith("(") && data.endsWith(")")) {
          data = "-" + data.slice(1, -1);
        }
        stat[m] = data;
      }
      stat["currency"] = this.currency;
      allStats.push(stat);
    }

    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: "profit_overview",
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(allStats);
  }

  ngOnDestroy() {
    this.userSelectionService.enabledDateRange();
    this.accountSelectionService.updateSingleMarketplaceMode(true);
  }
}
