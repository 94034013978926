import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import {
  AccountMarketplace,
  AccountSelectionService,
  AsinService,
  AuthService,
  Catalog,
  Marketplace,
  ProductGroup,
  ProductGroupEx,
  ProductGroupService,
  StrategyAsin,
} from "@front/m19-services";
import { ICON_CHEVRON_DOWN, ICON_EDIT_O } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { combineLatest } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { StrategyAsinSelectionMode } from "../../strategy-asins/asins-selection.component";

@UntilDestroy()
@Component({
  selector: "app-product-group-page",
  templateUrl: "./product-group-page.component.html",
  styleUrls: ["./product-group-page.component.scss"],
})
export class ProductGroupPageComponent implements OnInit {
  productGroup?: ProductGroupEx;
  productGroups: ProductGroupEx[];
  alreadySelectedProducts: StrategyAsin[];
  marketplace: Marketplace;
  accountId: string;
  isReadOnly = false;
  editMode = false;
  productGroupNameFilter = "";
  newProductGroupName = "";

  readonly ICON_EDIT = ICON_EDIT_O;
  readonly ICON_CHEVRON_DOWN = ICON_CHEVRON_DOWN;

  private catalog: string[];

  readonly selectionModes: { selectionMode: StrategyAsinSelectionMode; label: string }[] = [
    { selectionMode: StrategyAsinSelectionMode.FromCatalog, label: "Catalog" },
    { selectionMode: StrategyAsinSelectionMode.Bulk, label: "Bulk" },
  ];

  @ViewChild("search") search: ElementRef;
  productGroupNameModal: BsModalRef;

  productGroupPage = "/products/product-group";

  constructor(
    private route: ActivatedRoute,
    private productGroupService: ProductGroupService,
    private router: Router,
    private asinService: AsinService,
    private accountSelectionService: AccountSelectionService,
    private modalService: BsModalService,
    private toastrService: ToastrService,
    private authService: AuthService,
  ) {
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      if ((user?.uiVersion ?? 0) > 0) {
        this.productGroupPage = "/product-center/product-group";
      }
    });
  }

  ngOnInit() {
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(untilDestroyed(this))
      .subscribe((am: AccountMarketplace) => {
        this.marketplace = am.marketplace;
        this.accountId = am.accountId;
      });
    combineLatest<[Params, ProductGroupEx[]]>([
      this.route.params,
      this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(
        switchMap((am) => this.productGroupService.getProductGroups(am.accountId, am.marketplace)),
      ),
    ])
      .pipe(
        map(([params, productGroups]) => {
          const id = Number(params["id"]);
          return productGroups.find((pg) => pg.productGroupId == id);
        }),
      )
      .subscribe((productGroup: ProductGroupEx) => {
        if (productGroup == undefined) {
          this.productGroup = null;
          return;
        }
        this.productGroup = productGroup;
        this.alreadySelectedProducts = productGroup.items.map((a) => ({ asin: a }));
      });
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am: AccountMarketplace) => this.productGroupService.getProductGroups(am.accountId, am.marketplace)),
        untilDestroyed(this),
      )
      .subscribe((x) => (this.productGroups = x));
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am: AccountMarketplace) => this.asinService.getCatalog(am.accountId, am.marketplace)),
      )
      .subscribe((catalog: Catalog) => {
        this.catalog = [...catalog.childAsins.values()];
      });
    this.accountSelectionService.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((b) => (this.isReadOnly = b));
  }

  navigateToProductGroup(productGroup: ProductGroup) {
    this.router.navigate([this.productGroupPage, productGroup.productGroupId]);
  }

  openProductGroupNameModal(template: TemplateRef<any>) {
    if (this.isReadOnly) {
      return;
    }
    this.newProductGroupName = (this.productGroup as ProductGroupEx).productGroupName;
    this.productGroupNameModal = this.modalService.show(template, { class: "modal-primary modal-dialog-centered" });
  }

  changeName(newname: string) {
    this.productGroupService
      .updateProductGroupName(
        this.accountId,
        this.marketplace,
        (this.productGroup as ProductGroup).productGroupId,
        newname,
      )
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.toastrService.success("Product Group name updated");
          this.productGroupNameModal.hide();
        },
        error: (err) => {
          this.toastrService.error(err, "Error updating Product Group name");
        },
      });
  }

  addAsinsArrayToProductGroup(asins: StrategyAsin[]) {
    const target: string[] = asins.map((a) => a.asin);
    this.productGroupService
      .addAsinsToProductGroup(
        this.accountId,
        this.marketplace,
        (this.productGroup as ProductGroupEx).productGroupId,
        target,
        this.catalog,
      )
      .subscribe({
        next: () => {
          this.toastrService.success("ASINs added to Product Group");
        },
        error: (err) => {
          this.toastrService.error(err, "Error adding ASINs to Product Group");
        },
      });
  }

  deleteAsinArrayFromProductGroup(asins: StrategyAsin[]) {
    if (this.isReadOnly) {
      return;
    }
    const target: string[] = asins.map((a) => a.asin);
    this.productGroupService
      .deleteAsinsFromProductGroup(
        this.accountId,
        this.marketplace,
        (this.productGroup as ProductGroupEx).productGroupId,
        target,
      )
      .subscribe({
        next: () => {
          this.toastrService.success("ASINs removed from Product Group");
        },
        error: (err) => {
          this.toastrService.error(err, "Error removing ASINs from Product Group");
        },
      });
  }

  setFocus(): void {
    setTimeout(() => {
      this.search.nativeElement.value = "";
      this.search.nativeElement.focus();
    });
  }

  // Use for filter
  accessValue(s: ProductGroupEx) {
    return s.productGroupName;
  }

  getValueFromInputEvent(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }
}
