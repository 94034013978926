import { AgGridAngular } from "@ag-grid-community/angular";
import {
  ColDef,
  GridOptions,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from "@ag-grid-community/core";
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { ActivatedRoute, Router } from "@angular/router";
import { faPauseCircle, faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import {
  AccessLevel,
  AccountMarketplace,
  AccountSelectionService,
  AlgoMode,
  AsinService,
  AuthService,
  CampaignType,
  ConfigService,
  Currency,
  currencyRateToEuro,
  CurrencyService,
  EntityIdType,
  getBasicGridOptions,
  getStrategies,
  HistoryApi,
  Marketplaces,
  MatchType,
  SbStrategiesService,
  SegmentConfigType,
  SegmentEx,
  singleStrategyInStrategyGroup,
  StrategyAsin,
  StrategyEx,
  StrategyGroupEx,
  StrategyKeywords,
  StrategyStateEnum,
  StrategyTacticEx,
  StrategyTargetingType,
  StrategyType,
  StrategyTypeStr,
  TacticType,
  Targeting,
  UpdateStrategyTopOfSearchRankingsActionEnum,
  User,
  UserSelectionService,
  Utils,
} from "@front/m19-services";
import { IButtonComponent } from "@front/m19-ui";
import { TranslocoService } from "@jsverse/transloco";
import { ActivityFilter } from "@m19-board/activities/activity/activity.component";
import {
  ActionButton,
  ActionButtonsComponent,
} from "@m19-board/insights/overview/action-buttons/action-buttons.component";
import { AlgoTargetRendererComponent } from "@m19-board/insights/overview/algo-target-renderer/algo-target-renderer.component";
import { GlobalStrategyStats } from "@m19-board/insights/overview/overview-grid.component";
import { KeywordSegmentModalComponent } from "@m19-board/segments/keyword-segment-modal.component";
import { ProductSegmentModalComponent } from "@m19-board/segments/product-segment-modal.component";
import { AlgoStateCellComponent } from "@m19-board/shared/algo-state-cell/algo-state-cell.component";
import { ConfirmPopupComponent } from "@m19-board/shared/confirm-popup/confirm-popup.component";
import {
  SlideToggleParams,
  SlideToggleRendererComponent,
} from "@m19-board/shared/slide-toggle-renderer/slide-toggle-renderer.component";
import { StrategyInfoCellComponent } from "@m19-board/shared/strategy-info-cell/strategy-info-cell.component";
import { StrategyActivityModalComponent } from "@m19-board/strategies/strategies/strategy-activities/strategy-activities-modal.component";
import { StrategyStatsComponent } from "@m19-board/strategies/strategies/strategy-stats/stategy-stats.component";
import { StrategyAsinSelectionMode } from "@m19-board/strategies/strategy-asins/asins-selection.component";
import { SwitchAlgoModeComponent } from "@m19-board/strategies/strategy-page/switch-algo-mode-popup/switch-algo-mode.component";
import { TacticAddPopupComponent } from "@m19-board/strategies/strategy-page/tactic-add-popup.component";
import { keywordRankingAvailableFor } from "@m19-board/tracking/KeywordRankingAvailability";
import {
  ICON_CHART_LINE,
  ICON_CHEVRON_DOWN,
  ICON_COPY_O,
  ICON_EDIT_O,
  ICON_LIST,
  ICON_PAUSE,
  ICON_PLAY,
  ICON_SYNC,
  ICON_TRASH_O,
} from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Constant } from "libs/m19-services/src/lib/m19-services/constant";
import moment from "moment-timezone";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import {
  combineLatest,
  filter,
  forkJoin,
  Observable,
  of,
  ReplaySubject,
  Subscription,
  switchMap,
  take,
  tap,
} from "rxjs";
import { actionColumnProperties } from "../../grid-config/grid-columns";
import { InputModalComponent } from "../../shared/input-modal/input-modal.component";
import {
  StrategyGroupAddProductModalComponent,
  StrategyGroupProductsToAdd,
} from "./add-product/strategy-group-add-product-modal.component";
import { AdvancedSettingsModalComponent } from "./advanced-settings-modal/advanced-settings-modal.component";
import { BlacklistModalComponent, UpdateOperations } from "./blacklist/blacklist-modal.component";
import { StrategyGroupMoveProductModalComponent } from "./move-product/strategy-group-move-product-modal.component";
import { TargetingsModalComponent } from "./targetings-modal/targetings-modal.component";
@UntilDestroy()
@Component({
  templateUrl: "./sp-strategy-group-page.component.html",
})
export class SpStrategyGroupPageComponent implements OnInit {
  readonly ICON_SYNC = ICON_SYNC;

  readonly StrategyType = StrategyType;
  readonly CampaignType = CampaignType;
  readonly TacticType = TacticType;
  readonly AlgoMode = AlgoMode;
  readonly StrategyTypeStr = StrategyTypeStr;
  readonly faPlayCircle = faPlayCircle;
  readonly faPauseCircle = faPauseCircle;
  readonly maxKwTargetingByStrategy = Constant.maxKwTargetingByStrategy;
  readonly StrategyTargetingType = StrategyTargetingType;
  readonly ICON_EDIT_O = ICON_EDIT_O;
  readonly ICON_TRASH_O = ICON_TRASH_O;
  readonly ICON_CHEVRON_DOWN = ICON_CHEVRON_DOWN;
  readonly ICON_COPY = ICON_COPY_O;
  readonly ICON_PLAY = ICON_PLAY;
  readonly ICON_PAUSE = ICON_PAUSE;

  readonly editIconProperties = {
    color: "gray",
    variant: "ghost",
    square: true,
    icon: ICON_EDIT_O,
    iconOnHover: true,
    trailing: true,
  };

  strategyGroup$ = new ReplaySubject<StrategyGroupEx>(1);
  strategyGroupAsins: StrategyAsin[];
  strategyGroupAsinsStr: string[];
  loading = true;
  strategyGroup: StrategyGroupEx;
  isReadOnly = false;
  accountMarketplace: AccountMarketplace;
  promoBoostActivated = false;
  user: User;
  strategiesPerAsin: Map<string, StrategyEx[]> = new Map();
  productsToDelete: Set<string> = new Set();
  areAllProductsSelected = false;
  asinEligibility: Map<string, { status: boolean; reason: string }> = new Map();
  // for the activities section
  activityFilter: ActivityFilter[] = [];
  infoMessageLastUpdate = undefined;
  dateRange: string[];
  maxActivitiesDate: string;
  currencyCode: string;
  currencySymbol: string;

  focusedStrategy: StrategyEx; // Tactic modal
  focusedMinDailySpend: number;
  focusedDailyBudget: number;

  focusedAdvertisedProducts: string[]; // Also for targeting modal

  maxProducts = Constant.maxAsinTargetingByStrategy;
  productSelectionModes: { selectionMode: StrategyAsinSelectionMode; label: string }[];
  @ViewChild("advertisedProductsModal", { static: false }) advertisedProductsModal: TemplateRef<any>;
  @ViewChild("editProductsModal", { static: false }) editProductsModal: TemplateRef<any>;

  focusedTactics: StrategyTacticEx[]; // Tactic modal
  @ViewChild("tacticsModal", { static: false }) tacticsModal: TemplateRef<any>;

  @ViewChild("dailyBudgetModalRef", { static: false }) dailyBudgetModalRef: TemplateRef<any>;

  @ViewChild("advancedSettingsModal", { static: false }) advancedSettingsModal: TemplateRef<any>;

  modalRef: BsModalRef;
  modalRef2: BsModalRef; // in case we need a second modal.
  focusTacticType: TacticType;

  modalSearchInput = new FormControl<string>("");
  tacticSearchInput = "";

  productTargetingAllowAsinFromOtherCatalog = false;

  strategyPageUrl = "/strategies/sponsored-product";
  strategyGroupPageUrl = "/strategies/strategy-group/sponsored-product";

  // section expands
  readonly sectionVisibilityLsKey = "sp-strategy-group-page-section-visibility";
  readonly sectionVisibility = {
    products: true,
    blacklist: false,
    brandStrategies: true,
    keywordStrategies: true,
    productStrategies: true,
    stats: false,
    activities: false,
    advanced: false,
  };

  readonly StrategyColumnDefinitions: Record<string, ColDef<StrategyEx>> = {
    state: {
      field: "state",
      headerName: "State",
      maxWidth: 130,
      cellRendererSelector: (params) => {
        if (!params.value) return undefined;
        return {
          component: AlgoStateCellComponent,
          params: {
            strategy: params.data,
            isReadOnly: this.isReadOnly,
            onStateChange: () => {
              this.configurationService.updateStrategyState(
                this.accountMarketplace.accountId,
                this.accountMarketplace.marketplace,
                params.data.strategyId,
                params.value === StrategyStateEnum.ENABLED ? StrategyStateEnum.PAUSED : StrategyStateEnum.ENABLED,
              );
            },
          },
        };
      },
    },
    strategyName: {
      headerName: "Strategy name",
      field: "name",
      cellRenderer: IButtonComponent,
      cellRendererParams: (params) => ({
        tooltipValue: "Edit strategy name",
        ...this.editIconProperties,
        clickAction: () => {
          const strategyNameInput = new FormControl<string>(params.value);
          const ref = this.modalService.show(InputModalComponent, {
            initialState: {
              title: this.translocoService.translate("sp-strategy-group-page.change_strategy_name"),
              inputControl: strategyNameInput,
              maxLength: 80,
            },
            class: "modal-primary modal-dialog-centered",
          });

          ref.content.emitUpdate.subscribe(() => {
            const newName = strategyNameInput.value;
            const strategy: StrategyEx = params.data;
            if (newName.trim() !== "")
              this.configurationService.updateStrategyName(
                strategy.accountId,
                strategy.marketplace,
                strategy.strategyId,
                newName,
              );
          });
        },
      }),
    },
    algo: {
      headerName: "Algorithm",
      field: "algorithm",
      cellRenderer: IButtonComponent,
      cellRendererParams: (params) => ({
        ...this.editIconProperties,
        tooltipValue: "Change Algorithm",
        label: StrategyEx.getAlgoModeStrShort(params.value),
        clickAction: () => {
          if (this.isReadOnly) return;
          if (params.data.primeDayBoost) {
            // cannot change algo when prime day boost is active
            this.toastrService.warning(
              this.translocoService.translate("sp-strategy-group-page.cannot_change_algorithm_while_promo_day_boost"),
            );
            return;
          }
          const modalOptions: ModalOptions = {
            initialState: {
              strategy: params.data,
            },
            class: "modal-primary modal-lg modal-dialog-centered",
          };
          this.modalService.show(SwitchAlgoModeComponent, modalOptions);
        },
      }),
    },
    target: {
      headerName: "Target",
      colId: "target",
      cellRenderer: AlgoTargetRendererComponent,
      cellRendererParams: (params) => {
        return {
          locale: this.user.locale,
          currency: this.accountMarketplace?.marketplace
            ? Marketplaces[this.accountMarketplace.marketplace].currency
            : Currency.USD,
          readonly: this.isReadOnly,
        };
      },
    },
    products: {
      headerName: "Advertised Products",
      field: "asins",
      valueFormatter: (params: ValueFormatterParams<StrategyEx, StrategyAsin[]>) => "" + params.value.length,
      cellRenderer: IButtonComponent,
      cellRendererParams: (params) => ({
        ...this.editIconProperties,
        tooltipValue: "View advertised products",
        label: params.value.length,
        clickAction: () => {
          const products: string[] = params.data.asins.map((a) => a.asin);
          this.focusedStrategy = params.data;
          this.displayAdvertisedProducts(products);
        },
      }),
    },
    targetings: {
      headerName: "Targetings",
      field: "targetings",
      cellRenderer: IButtonComponent,
      cellRendererParams: (params: ICellRendererParams<StrategyEx, Targeting[]>) => {
        return {
          ...this.editIconProperties,
          tooltipValue: "View Targetings",
          label: this.formatTargetings(params.value),
          clickAction: () => {
            this.onTargetingsClick(params);
          },
        };
      },
    },
    tosro: {
      headerName: "TOSRO",
      colId: "TOSRO",
      headerTooltip: "Top of Search Rankings Optimizer",
      suppressSizeToFit: true,
      valueGetter: (params) => {
        return params.data.topOfSearchRankings;
      },
      cellRenderer: SlideToggleRendererComponent,
      cellRendererParams: (params: ICellRendererParams<StrategyEx, StrategyKeywords[], SlideToggleParams>) => {
        const supportedMarketplace = keywordRankingAvailableFor(params.data.marketplace);
        const hasOneExactTargeting = params.data.targetings.findIndex((t) => t.matchType == MatchType.exact) >= 0;
        const isDisabled = !supportedMarketplace || this.isReadOnly || !hasOneExactTargeting;

        return {
          isDisabled: isDisabled,
          isChecked: !isDisabled && params.value.length > 0,
          tooltip: this.getTosroTooltip(supportedMarketplace, hasOneExactTargeting),
          onChange: (event) => {
            this.switchOptimizerStatus(params.data, event);
          },
        };
      },
    },
    info: {
      headerName: "Info",
      cellRenderer: StrategyInfoCellComponent,
      cellRendererParams: (params) => ({
        strategy: params.data,
        currencyCode: this.currencyCode,
        locale: this.user.locale,
        promoBoostActivated: this.promoBoostActivated,
      }),
    },
  };

  private getTosroTooltip(supportedMarketplace: boolean, hasOneExactTargeting: boolean) {
    let res = "";
    if (this.isReadOnly) {
      return "You do not have the permission to activate TOSRO";
    } else if (!supportedMarketplace) {
      return "TOSRO not supported for this marketplace";
    } else {
      res = "Activated keywords are tracked and included in your hourly keyword tracker quota";
    }
    if (!hasOneExactTargeting) {
      res = "TOSRO can be activated only for strategy with at least one exact keyword targeting";
    }
    return res;
  }

  readonly ActionButtons: Record<string, (strategy: StrategyEx) => ActionButton> = {
    stats: (strategy) => ({
      icon: ICON_CHART_LINE,
      tooltip: "Display strategy stats",
      onClick: () => {
        this.openStrategyStats(strategy);
      },
    }),
    activities: (strategy) => ({
      icon: ICON_LIST,
      tooltip: "Display strategy activities",
      onClick: () => {
        this.openStrategyActivities(strategy);
      },
    }),
    delete: (strategy) => ({
      icon: ICON_TRASH_O,
      tooltip: `Remove ${StrategyTypeStr[strategy.strategyType]} strategy`,
      disabled: this.isReadOnly,
      onClick: () => {
        this.configurationService.deleteStrategy(strategy).subscribe({
          next: () => {
            this.toastrService.success("Strategy " + strategy.name + " successfully deleted", "Strategy deleted");
          },
          error: (err) => {
            this.toastrService.error(
              "Error deleting Strategy " + strategy.name + ": " + err,
              "Strategy deletion error",
            );
          },
        });
      },
    }),
    deleteProductStrategy: (strategy) => ({
      icon: ICON_TRASH_O,
      tooltip: `Remove ${StrategyTypeStr[strategy.strategyType]} strategy`,
      disabled: this.isReadOnly,
      onClick: () => {
        const impactedStrats = new Map<StrategyEx, string[]>();
        for (const strategyAsin of strategy.asins) {
          for (const strat of this.strategiesPerAsin.get(strategyAsin.asin)) {
            if (strat.strategyId != strategy.strategyId && strat.state == StrategyStateEnum.ENABLED) {
              if (impactedStrats.has(strat)) {
                impactedStrats.get(strat).push(strategyAsin.asin);
              } else {
                impactedStrats.set(strat, [strategyAsin.asin]);
              }
            }
          }
        }
        if (impactedStrats.size == 0) {
          this.configurationService.deleteStrategy(strategy).subscribe({
            next: () => {
              this.toastrService.success(
                "Main Strategy " + strategy.name + " successfully deleted",
                "Strategy deleted",
              );
            },
            error: (err) => {
              this.toastrService.error(
                "Error deleting Main Strategy " + strategy.name + ": " + err,
                "Strategy deletion error",
              );
            },
          });
        }
        //  confirmation modal
        const modalOpts: ModalOptions = {
          initialState: {
            title: "Main strategy deletion",
            message: `Products of this strategy are used in ${impactedStrats.size} brand defense or focus ${
              impactedStrats.size > 1 ? "strategies" : "strategy"
            } (${Array.from(impactedStrats.keys())
              .map((s) => `"${s.name}"`)
              .join(", ")}). They will stop running for these products.`,
          },
        };
        const modalRef = this.modalService.show(ConfirmPopupComponent, modalOpts);
        const sub = modalRef.content.confirm
          .pipe(
            switchMap(() =>
              forkJoin(
                Array.from(impactedStrats.entries()).map(([strat, asins]) =>
                  this.configurationService.deleteAsinsFromStrategy(strat, asins),
                ),
              ),
            ),
            // TODO: also stop strategies if no more ASINs
            switchMap(() => this.configurationService.deleteStrategy(strategy)),
          )
          .subscribe({
            next: () => {
              this.toastrService.success(
                "Main Strategy " + strategy.name + " successfully deleted",
                "Strategy deleted",
              );
              sub.unsubscribe();
            },
            error: (err) => {
              this.toastrService.error(
                "Error deleting Main Strategy " + strategy.name + ": " + err,
                "Strategy deletion error",
              );
              sub.unsubscribe();
            },
          });
        sub.add(
          modalRef.content.cancel.subscribe(() => {
            sub.unsubscribe();
          }),
        );
      },
    }),
    advanced: (strategy) => ({
      icon: "icon-[mdi--ellipsis-horizontal]",
      tooltip: "Advanced settings",
      subItems: [
        ...(strategy.algorithm !== AlgoMode.MONTHLY_BUDGET_TARGET
          ? [
              {
                title: this.translocoService.translate("sp-strategy-group-page.edit_average_daily_budget"),
                disabled: this.isReadOnly || !!strategy.primeDayBoost,
                onClick: () => {
                  this.focusedStrategy = strategy;
                  this.modalRef = this.modalService.show(this.dailyBudgetModalRef, {
                    class: "modal-primary modal-dialog-centered",
                  });
                },
              },
            ]
          : []),
        {
          title: "Open Advanced Settings",
          onClick: () => {
            this.focusedStrategy = strategy;

            this.modalRef = this.modalService.show(AdvancedSettingsModalComponent, {
              class: "modal-primary modal-dialog-centered",
              initialState: {
                accountMarketplace: this.accountMarketplace,
                strategy: strategy,
                isReadOnly: this.isReadOnly,
                locale: this.user.locale,
                currencyCode: this.currencyCode as Currency,
              },
            });

            // this.modalRef = this.modalService.show(this.advancedSettingsModal, {
            //   class: "modal-primary modal-dialog-centered",
            // });
          },
        },
      ],
    }),
    copyStrategyId: (strategy) => ({
      icon: ICON_COPY_O,
      tooltip: "Copy strategy ID",
      onClick: () => {
        navigator.clipboard.writeText(strategy.strategyId.toString());
        this.toastrService.success("Strategy ID copied to clipboard", "Strategy ID copied");
      },
    }),
  };

  // drag handler to manage strategy priority
  rowDragHandler = (strategyType: StrategyType) => (event) => {
    const strategy = event.node.data;
    const index = event.overIndex;
    if (event.overNode?.rowIndex == index) {
      return; // no move
    }
    let priority = 0;
    const strategies = getStrategies(this.strategyGroup, strategyType);
    if (index == 0) {
      priority = Math.floor((Number.MIN_SAFE_INTEGER + strategies[0].priority) / 2);
    } else if (index == strategies.length - 1) {
      priority = Math.floor((Number.MAX_SAFE_INTEGER + strategies[index].priority) / 2);
    } else {
      priority = Math.floor((strategies[index - 1].priority + strategies[index].priority) / 2);
    }
    this.configurationService
      .updateStrategyPriority(
        this.accountMarketplace.accountId,
        this.accountMarketplace.marketplace,
        strategy.strategyId,
        priority,
      )
      .pipe(untilDestroyed(this), take(1))
      .subscribe({
        next: () => {
          this.toastrService.success("Priority updated");
        },
        error: (err: string) => {
          this.toastrService.error(err, "Strategy priority update error");
        },
      });
  };

  // brand defense strategy table

  @ViewChild("brandStrategies", { static: false }) brandStrategiesGrid!: AgGridAngular;

  readonly brandStrategyGridOptions: GridOptions<StrategyEx> = {
    ...getBasicGridOptions("brandStrategies"),
    sideBar: false,
    defaultColDef: {
      flex: 1,
      resizable: true,
    },
    rowDragText: () => "Drag strategy up or down to modify its priority",
    onRowDragEnd: this.rowDragHandler(StrategyType.BRAND),
    columnDefs: [
      { ...this.StrategyColumnDefinitions.state, rowDrag: true },
      this.StrategyColumnDefinitions.strategyName,
      this.StrategyColumnDefinitions.algo,
      this.StrategyColumnDefinitions.target,
      this.StrategyColumnDefinitions.products,
      this.StrategyColumnDefinitions.targetings,
      this.StrategyColumnDefinitions.tosro,
      this.StrategyColumnDefinitions.info,
      // actions
      {
        ...actionColumnProperties<StrategyEx, string>(),
        cellRendererSelector: (params: ICellRendererParams<StrategyEx>) => {
          const actionButtons: ActionButton[] = [];
          actionButtons.push(this.ActionButtons.advanced(params.data));
          actionButtons.push(this.ActionButtons.stats(params.data));
          actionButtons.push(this.ActionButtons.activities(params.data));
          actionButtons.push(this.ActionButtons.delete(params.data));
          if (this.accountMarketplace?.accessLevel == AccessLevel.ADMIN) {
            actionButtons.push(this.ActionButtons.copyStrategyId(params.data));
          }
          return {
            component: ActionButtonsComponent,
            params: {
              actionButtons,
            },
          };
        },
      },
    ],
  };
  brandStrategyTableHeight: number;

  // main keyword strategy table
  @ViewChild("mainKeywordStrategies", { static: false }) mainKeywordStrategiesGrid!: AgGridAngular;

  readonly mainKeywordStrategyGridOptions: GridOptions<StrategyEx> = {
    ...getBasicGridOptions("brandStrategies"),
    sideBar: false,
    defaultColDef: {
      flex: 1,
      resizable: true,
    },
    rowDragText: () => "Drag strategy up or down to modify its priority",
    onRowDragEnd: this.rowDragHandler(StrategyType.KEYWORD),
    columnDefs: [
      { ...this.StrategyColumnDefinitions.state, rowDrag: true },
      this.StrategyColumnDefinitions.strategyName,
      this.StrategyColumnDefinitions.algo,
      this.StrategyColumnDefinitions.target,
      this.StrategyColumnDefinitions.products,
      this.StrategyColumnDefinitions.targetings,
      this.StrategyColumnDefinitions.tosro,
      this.StrategyColumnDefinitions.info,
      // actions
      {
        ...actionColumnProperties<StrategyEx, string>(),
        cellRendererSelector: (params: ICellRendererParams<StrategyEx>) => {
          const actionButtons: ActionButton[] = [];
          actionButtons.push(this.ActionButtons.advanced(params.data));
          // actionButtons.push(this.ActionButtons.edit(params.data));
          actionButtons.push(this.ActionButtons.stats(params.data));
          actionButtons.push(this.ActionButtons.activities(params.data));
          actionButtons.push(this.ActionButtons.delete(params.data));
          if (this.accountMarketplace?.accessLevel == AccessLevel.ADMIN) {
            actionButtons.push(this.ActionButtons.copyStrategyId(params.data));
          }
          return {
            component: ActionButtonsComponent,
            params: {
              actionButtons,
            },
          };
        },
      },
    ],
  };
  keywordStrategyTableHeight: number;

  // product strategy table
  @ViewChild("productStrategies", { static: false }) productStrategiesGrid!: AgGridAngular;

  readonly productStrategyGridOptions: GridOptions<StrategyEx> = {
    ...getBasicGridOptions("productStrategies"),
    sideBar: false,
    defaultColDef: {
      flex: 1,
      resizable: true,
    },
    columnDefs: [
      this.StrategyColumnDefinitions.state,
      this.StrategyColumnDefinitions.strategyName,
      this.StrategyColumnDefinitions.algo,
      this.StrategyColumnDefinitions.target,
      this.StrategyColumnDefinitions.products,
      {
        headerName: "Tactics",
        valueGetter: (params: ValueGetterParams<StrategyEx>) =>
          params.data.tactics.filter((t) => t.tacticType == TacticType.LEGACY),
        valueFormatter: (params: ValueFormatterParams<StrategyEx, Targeting[]>) =>
          params.value.length == 0 ? "No Tactic" : params.value.length.toString(),
        cellRenderer: IButtonComponent,
        cellRendererParams: (params: ICellRendererParams<StrategyEx>) => ({
          ...this.editIconProperties,
          tooltipValue: "View Tactics",

          label: params.value.length == 0 ? "No Tactic" : params.value.length.toString(),
          clickAction: () => {
            this.focusTacticType = TacticType.LEGACY;
            this.onTacticClick(params);
          },
        }),
      },
      {
        headerName: "Blacklist",
        valueGetter: (params: ValueGetterParams<StrategyEx>) =>
          params.data.tactics.filter((t) => t.tacticType == TacticType.BLACKLIST),
        valueFormatter: (params: ValueFormatterParams<StrategyEx, Targeting[]>) =>
          params.value.length == 0 ? "" : params.value.length.toString(),
        cellRenderer: IButtonComponent,
        cellRendererParams: (params) => ({
          ...this.editIconProperties,
          tooltipValue: "View Blacklist",
          label: params.value.length == 0 ? "0" : params.value.length.toString(),
          clickAction: () => {
            this.focusTacticType = TacticType.BLACKLIST;
            this.onTacticClick(params, true);
          },
        }),
      },
      this.StrategyColumnDefinitions.info,
      {
        ...actionColumnProperties<StrategyEx, string>(),
        cellRendererSelector: (params: ICellRendererParams<StrategyEx>) => {
          const actionButtons: ActionButton[] = [];
          actionButtons.push(this.ActionButtons.advanced(params.data));
          actionButtons.push(this.ActionButtons.stats(params.data));
          actionButtons.push(this.ActionButtons.activities(params.data));
          if (this.strategyGroup.productStrategies.length > 1) {
            actionButtons.push(this.ActionButtons.deleteProductStrategy(params.data));
          }
          if (this.accountMarketplace?.accessLevel == AccessLevel.ADMIN) {
            actionButtons.push(this.ActionButtons.copyStrategyId(params.data));
          }
          return {
            component: ActionButtonsComponent,
            params: {
              actionButtons,
            },
          };
        },
      },
    ],
  };
  productStrategyTableHeight: number;

  // strategy group stats inputs
  hiddenColumns = ["campaignType", "strategyGroup"];
  strategyGroupStatsFilter = (stats: GlobalStrategyStats) =>
    stats.strategy?.strategyGroupId == this.strategyGroup?.strategyGroupId;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private accountMarketplaceSelection: AccountSelectionService,
    private configurationService: ConfigService,
    private asinService: AsinService,
    private authService: AuthService,
    private modalService: BsModalService,
    private userSelectionService: UserSelectionService,
    private currencyService: CurrencyService,
    private historyService: HistoryApi,
    // TODO: this service is only used to trigger the strategy index refresh
    sbStrategiesService: SbStrategiesService,
    private translocoService: TranslocoService,
  ) {
    // call the service to force loading the sbStrategiesService
    sbStrategiesService.brandAssetsIndex$;
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      if ((user?.uiVersion ?? 0) > 0) {
        this.strategyPageUrl = "/advertising/sponsored-product";
        this.strategyGroupPageUrl = "/advertising/sponsored-product/strategy-group";
      }
    });
  }

  ngOnInit(): void {
    this.initSectionVisibility();

    this.accountMarketplaceSelection.promoBoostActivated$.pipe(untilDestroyed(this)).subscribe((b) => {
      this.promoBoostActivated = b;
    });

    this.accountMarketplaceSelection.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((b) => (this.isReadOnly = b));
    this.accountMarketplaceSelection.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am: AccountMarketplace) => this.asinService.getCatalog(am.accountId, am.marketplace)),
        untilDestroyed(this),
      )
      .subscribe((catalog) => {
        this.asinEligibility = catalog.getSPEligibility();
      });
    combineLatest<[AccountMarketplace, StrategyGroupEx]>([
      this.accountMarketplaceSelection.singleAccountMarketplaceSelection$.pipe(
        tap((am: AccountMarketplace) => {
          this.accountMarketplace = am;
          this.currencyCode = this.currencyService.getCurrencyCode(am.marketplace);
          this.currencySymbol = this.currencyService.getCurrencySymbolFromMarketplace(am.marketplace);
        }),
      ),
      this.strategyGroup$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([accountMarketplace, strategyGroup]) => {
        // compute asin per strategy
        this.strategiesPerAsin = new Map();
        for (const strat of strategyGroup.brandStrategies
          .concat(strategyGroup.keywordStrategies)
          .concat(strategyGroup.productStrategies)) {
          if (strat.state != StrategyStateEnum.ENABLED) continue;
          for (const asin of strat.asins) {
            if (this.strategiesPerAsin.has(asin.asin)) {
              this.strategiesPerAsin.get(asin.asin).push(strat);
            } else {
              this.strategiesPerAsin.set(asin.asin, [strat]);
            }
          }
        }

        // get product from catalog
        if (strategyGroup.asins.length == 0) {
          return of([]);
        }
      });

    this.route.paramMap
      .pipe(
        switchMap((params) => {
          const id = Number(params.get("id"));
          return this.configurationService.getStrategyGroupById(id);
        }),
        untilDestroyed(this),
      )
      .subscribe((strategyGroup: StrategyGroupEx) => {
        // redirect to strategy page if the strategy group cannot be found
        if (!strategyGroup) {
          this.router.navigate([this.strategyPageUrl], { queryParamsHandling: "merge" });
          return;
        }
        // if strategy group contains only one product strategy, route to the substrategy page directly
        if (singleStrategyInStrategyGroup(strategyGroup)) {
          this.router.navigate(
            [this.strategyGroupPageUrl, strategyGroup.strategyGroupId, strategyGroup.productStrategies[0].strategyId],
            { queryParamsHandling: "merge" },
          );
          return;
        }

        this.loading = false;
        this.strategyGroup = strategyGroup;
        this.strategyGroup$.next(strategyGroup);
      });
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.user = user;
    });
    this.strategyGroup$.pipe(untilDestroyed(this)).subscribe((sg) => {
      this.strategyGroupAsins = sg.asins.map((a) => ({ asin: a }));
      this.strategyGroupAsinsStr = sg.asins;
      this.brandStrategyTableHeight = (1 + sg.brandStrategies.length) * 50;
      this.keywordStrategyTableHeight = (1 + sg.keywordStrategies.length) * 50;
      this.productStrategyTableHeight = (1 + sg.productStrategies.length) * 50;
    });

    this.strategyGroup$
      .pipe(
        untilDestroyed(this),
        switchMap((sg) => {
          this.activityFilter = [
            { primaryType: EntityIdType.strategyGroupId, primaryId: sg.strategyGroupId },
            ...sg.brandStrategies
              .concat(sg.keywordStrategies)
              .concat(sg.productStrategies)
              .map((s) => ({ primaryType: EntityIdType.strategyId, primaryId: s.strategyId })),
          ];
          return this.historyService.getHistory({
            accountId: sg.accountId!,
            marketplace: sg.marketplace!,
            minDate: Utils.formatDateForApiFromToday(-2),
            maxDate: Utils.formatDateForApiFromToday(0),
            historyKey: this.activityFilter,
          });
        }),
      )
      .subscribe((history) => {
        if (history.length == 0) {
          this.infoMessageLastUpdate = undefined;
          return;
        }
        const lastUpdate = history
          .filter((a) => a.timestamp)
          .sort((a, b) => b.timestamp!.localeCompare(a.timestamp!))[0].timestamp;

        const nbHoursBeforeNextPush = Utils.estimatedNbHoursBeforeNextPush(
          Marketplaces[this.accountMarketplace.marketplace].platform,
          this.accountMarketplace.bidderRuns ? this.accountMarketplace.bidderRuns["SP"]?.bidderRequestTime : undefined,
          this.accountMarketplace.bidderRuns ? this.accountMarketplace.bidderRuns["SP"]?.lastBidderStart : undefined,
          this.accountMarketplace.bidderRuns ? this.accountMarketplace.bidderRuns["SP"]?.lastBidderEnd : undefined,
          lastUpdate!,
        );

        if (nbHoursBeforeNextPush && nbHoursBeforeNextPush > 0) {
          this.infoMessageLastUpdate = this.translocoService.translate("sp-strategy-group-page.time_for_update", {
            count: nbHoursBeforeNextPush,
          });
        } else {
          this.infoMessageLastUpdate = undefined;
        }
      });

    this.userSelectionService.dateRange$.pipe(untilDestroyed(this)).subscribe((r: string[]) => {
      this.dateRange = r;
      // if max date is yesterday, activity data will also include today activities
      if (this.dateRange[1] == moment().subtract(1, "days").format("YYYY-MM-DD")) {
        this.maxActivitiesDate = moment().format("YYYY-MM-DD");
      } else {
        this.maxActivitiesDate = this.dateRange[1];
      }
    });
  }

  private initSectionVisibility(): void {
    const visibility = localStorage.getItem(this.sectionVisibilityLsKey);
    try {
      const openedSections: Set<string> = new Set(JSON.parse(visibility) as string[]);
      if (openedSections.size == 0) return; // no config

      Object.keys(this.sectionVisibility).forEach((s) => (this.sectionVisibility[s] = openedSections.has(s)));
    } catch (e) {
      return;
    }
  }

  toggleSectionVisibility(section: keyof typeof this.sectionVisibility) {
    this.sectionVisibility[section] = !this.sectionVisibility[section];
    const openedSections = Object.keys(this.sectionVisibility).filter((k) => this.sectionVisibility[k]);
    localStorage.setItem(this.sectionVisibilityLsKey, JSON.stringify(openedSections));
  }

  getDailyBudgetLowerBound(): number {
    if (this.focusedStrategy.algoMode == AlgoMode.PRODUCT_LAUNCH) {
      if (this.focusedStrategy.suggestedBid) {
        return this.focusedStrategy.suggestedBid * 5;
      }
    }
    if (this.focusedStrategy.algoMode == AlgoMode.ACOS_TARGET) {
      if (this.focusedStrategy.minDailySpend) {
        return this.focusedStrategy.minDailySpend * 2;
      }
    }
    return Math.ceil(1 / currencyRateToEuro(this.currencyCode as Currency));
  }

  isInValidDailyBudget(): boolean {
    return (
      !this.focusedDailyBudget ||
      this.focusedDailyBudget < 1 ||
      this.focusedDailyBudget < this.getDailyBudgetLowerBound()
    );
  }

  changeDailyBudget(): void {
    if (this.isInValidDailyBudget()) return;
    this.configurationService.updateStrategyDailyBudget(
      this.focusedStrategy.accountId,
      this.focusedStrategy.marketplace,
      this.focusedStrategy.strategyId,
      Math.round(this.focusedDailyBudget),
    );
    this.modalRef.hide();
  }

  deleteDailyBudget(): void {
    this.configurationService.updateStrategyDailyBudget(
      this.focusedStrategy.accountId,
      this.focusedStrategy.marketplace,
      this.focusedStrategy.strategyId,
      null,
    );
    this.modalRef.hide();
  }

  displayAdvertisedProducts(products: string[]) {
    this.focusedAdvertisedProducts = products;
    this.modalRef = this.modalService.show(this.advertisedProductsModal, {
      class: "modal-primary modal-dialog-centered modal-xl",
    });
    this.modalRef.onHide.subscribe(() => {
      this.modalSearchInput.setValue("");
    });
  }

  addProductModal() {
    if (this.focusedStrategy.strategyType === StrategyType.PRODUCT) {
      this.productSelectionModes = [
        { selectionMode: StrategyAsinSelectionMode.FromCatalog, label: "From Catalog" },
        { selectionMode: StrategyAsinSelectionMode.Bulk, label: "From ASIN list" },
        { selectionMode: StrategyAsinSelectionMode.FromProductGroups, label: "From Product Groups" },
      ];
    } else {
      this.productSelectionModes = [
        { selectionMode: StrategyAsinSelectionMode.FromCustomAsinList, label: "From Custom list" },
        { selectionMode: StrategyAsinSelectionMode.Bulk, label: "From ASIN list" },
      ];
    }

    this.modalRef2 = this.modalService.show(this.editProductsModal, {
      class: "modal-primary modal-dialog-centered modal-xl",
    });
  }

  moveToOtherStrategy(asins: StrategyAsin[]) {
    const modalOpts: ModalOptions = {
      initialState: {
        strategyGroup: this.strategyGroup,
        source: this.focusedStrategy,
      },
      class: "modal-lg",
    };
    const modalRef = this.modalService.show(StrategyGroupMoveProductModalComponent, modalOpts);
    modalRef.content.target
      .pipe(
        switchMap((target) => {
          if (target.type == "NewProductStrategy") {
            // create a new strategy and delete the strategy from the current one
            return this.configurationService
              .createStrategyAsync({ ...target.productStrategyToCreate, asins: asins })
              .pipe(
                switchMap(() =>
                  this.configurationService.deleteAsinsFromStrategy(
                    this.focusedStrategy,
                    asins.map((a) => a.asin),
                  ),
                ),
              );
          }
          return this.configurationService.moveAsinsToStrategy(
            asins.map((a) => a.asin),
            this.focusedStrategy,
            target.productStrategy,
          );
        }),
      )
      .subscribe({
        next: () => {
          this.toastrService.success(`ASINs moved to another main strategy`);
          this.focusedStrategy.asins = this.focusedStrategy.asins.filter((a) => !asins.includes(a));
        },
        error: (error: string) => {
          this.toastrService.error(`Error moving ASINs: ${error}`, "Error moving ASINs");
        },
      });
  }

  addProductsToSubStrat(toAdd: StrategyAsin[], strategy: StrategyEx) {
    this.configurationService
      .addAsinsToStrategy(
        strategy,
        toAdd.map((a) => a.asin),
      )
      .subscribe({
        next: () => {
          this.toastrService.success("Products added to strategy", "Products added");
          this.focusedStrategy.asins = [...this.focusedStrategy.asins, ...toAdd];
          this.focusedAdvertisedProducts = this.focusedStrategy.asins.map((a) => a.asin);
        },
        error: (e: string) => {
          this.toastrService.error(e, "Error adding products to strategy");
        },
      });
  }

  removeProductsFromSubstrat(toRemove: StrategyAsin[], strategy: StrategyEx) {
    if (strategy.strategyType !== StrategyType.PRODUCT) {
      this.configurationService
        .deleteAsinsFromStrategy(
          strategy,
          toRemove.map((a) => a.asin),
        )
        .subscribe({
          next: () => {
            this.toastrService.success("Products removed from strategy", "Products removed");
            this.focusedStrategy.asins = this.focusedStrategy.asins.filter((a) => !toRemove.includes(a));
            this.focusedAdvertisedProducts = this.focusedStrategy.asins.map((a) => a.asin);
          },
          error: (e: string) => {
            this.toastrService.error(e, "Error removing products from strategy");
          },
        });
    } else {
      const toRemoveAsins = toRemove.map((a) => a.asin);
      const toRemoveSet = new Set<string>(toRemoveAsins);
      const impactedStrats = new Map<StrategyEx, string[]>();
      for (const otherStrat of this.strategyGroup.brandStrategies.concat(this.strategyGroup.keywordStrategies)) {
        const intersection = otherStrat.asins.map((x) => x.asin).filter((a) => toRemoveSet.has(a));
        if (intersection.length > 0) {
          impactedStrats.set(otherStrat, intersection);
        }
      }
      if (impactedStrats.size == 0) {
        this.configurationService.deleteAsinsFromStrategy(strategy, toRemoveAsins).subscribe({
          next: () => {
            this.toastrService.success(
              `ASIN${toRemove.length > 1 ? "s" : ""} removed from strategy`,
              "Strategy ASIN Updated",
            );
            this.focusedStrategy.asins = this.focusedStrategy.asins.filter((a) => !toRemove.includes(a));
          },
          error: (error: string) => {
            this.toastrService.error(
              `Error removing Strategy ASIN${toRemove.length > 1 ? "s" : ""}: ${error}`,
              "Strategy ASIN Update Error",
            );
          },
        });
        return;
      }

      const modalOpts: ModalOptions = {
        initialState: {
          title: "Removing ASINs from Main strategy",
          message: `These ASINs are used in ${impactedStrats.size} brand defense or focus ${
            impactedStrats.size > 1 ? "strategies" : "strategy"
          } (${Array.from(impactedStrats.keys())
            .map((s) => `"${s.name}"`)
            .join(", ")}). They will stop running for these products.`,
        },
        class: "modal-dialog-centered",
      };
      const modalRef = this.modalService.show(ConfirmPopupComponent, modalOpts);
      modalRef.content.confirm
        .pipe(
          switchMap(() =>
            forkJoin(
              Array.from(impactedStrats.entries()).map(([strat, asins]) =>
                this.configurationService.deleteAsinsFromStrategy(strat, asins),
              ),
            ),
          ),
          switchMap(() => this.configurationService.deleteAsinsFromStrategy(strategy, toRemoveAsins)),
          // TODO: also stop strategies if no more ASINs
        )
        .subscribe({
          next: () => {
            this.toastrService.success(
              `ASIN${toRemove.length > 1 ? "s" : ""} removed from strategy`,
              "Strategy ASIN Updated",
            );
            this.focusedStrategy.asins = this.focusedStrategy.asins.filter((a) => !toRemove.includes(a));
          },
          error: (error: string) => {
            this.toastrService.error(
              `Error removing Strategy ASIN${toRemove.length > 1 ? "s" : ""}: ${error}`,
              "Strategy ASIN Update Error",
            );
          },
        });
    }
  }

  onTargetingsClick(params: ICellRendererParams<StrategyEx, Targeting[]>) {
    const strategy = params.data;

    const targetingType =
      strategy.targetings.length > 0 && strategy.targetings[0].matchType === MatchType.asinSameAs
        ? StrategyTargetingType.PRODUCTS
        : StrategyTargetingType.KEYWORDS;

    this.modalRef = this.modalService.show(TargetingsModalComponent, {
      class: "modal-primary modal-dialog-centered",
      initialState: {
        strategyType: strategy.strategyType,
        targetings: strategy.targetings,
        isReadOnly: this.isReadOnly,
        strategy: strategy,
        strategyGroup: this.strategyGroup,
        targetingType: targetingType,
        accountMarketplace: this.accountMarketplace,
      },
    });

    this.modalRef.onHide.subscribe(() => {
      this.modalSearchInput.setValue("");
    });

    this.modalRef.content.onIsolationUpdated.subscribe(({ strategy, isolation }) => {
      this.updateStrategyAsinIsolation({ strategy, isolation });
    });
  }

  private updateStrategyAsinIsolation({ strategy, isolation }: { strategy: StrategyEx; isolation: boolean }) {
    if (strategy.strategyType === StrategyType.BRAND) {
      this.strategyGroup.brandStrategies.find((s) => s.strategyId === strategy.strategyId).asinIsolation = isolation;
      this.brandStrategiesGrid.api.redrawRows();
    } else {
      this.strategyGroup.keywordStrategies.find((s) => s.strategyId === strategy.strategyId).asinIsolation = isolation;
      this.mainKeywordStrategiesGrid.api.redrawRows();
    }
  }

  private formatTargetings(targetings: Targeting[]): string {
    if (targetings.length == 0) {
      return "No Targeting";
    }
    const { p, k } = targetings.reduce(
      ({ p, k }, t) => {
        if (t.matchType == MatchType.asinSameAs) {
          p++;
        } else {
          k++;
        }
        return { p, k };
      },
      { p: 0, k: 0 },
    );
    let res = "";
    if (p > 0) {
      res += `${p} Product${p > 1 ? "s" : ""}`;
    }
    if (k > 0) {
      res += p > 0 ? ", " : "";
      res += `${k} Keyword${k > 1 ? "s" : ""}`;
    }
    return res;
  }

  private onTacticClick(params: ICellRendererParams<StrategyEx>, onlyBlacklist = false) {
    this.focusedStrategy = params.data;
    this.focusedTactics = params.value;
    this.modalRef = this.modalService.show(this.tacticsModal, {
      class: "modal-primary modal-dialog-centered modal-lg",
    });
    this.modalRef.onHide.subscribe(() => {
      this.modalSearchInput.setValue("");
    });
  }

  createTactic(): void {
    const allowedSegmentTacticTypes: [SegmentConfigType, TacticType][] = [];
    if (this.focusedStrategy.campaignType != CampaignType.SD) {
      if (this.focusTacticType == TacticType.LEGACY)
        allowedSegmentTacticTypes.push([SegmentConfigType.KeywordSegment, TacticType.LEGACY]);
      else allowedSegmentTacticTypes.push([SegmentConfigType.KeywordSegment, TacticType.BLACKLIST]);
    }
    if (this.focusedStrategy.productTargetingEnabled) {
      if (this.focusTacticType == TacticType.LEGACY)
        allowedSegmentTacticTypes.push([SegmentConfigType.ProductSegment, TacticType.LEGACY]);
      else allowedSegmentTacticTypes.push([SegmentConfigType.ProductSegment, TacticType.BLACKLIST]);
    }

    const modalOptions: ModalOptions = {
      initialState: {
        strategy: this.focusedStrategy,
        allowedSegmentTacticTypes: allowedSegmentTacticTypes,
      },
      class: "modal-lg modal-dialog-centered",
    };
    const modalRef = this.modalService.show(TacticAddPopupComponent, modalOptions);
    const subscription = modalRef.content.segmentCreationRequested
      .pipe(untilDestroyed(this))
      .subscribe((segmentCreationRequest) => {
        const segmentCreationModalOption: ModalOptions = {
          initialState: {
            accountId: this.focusedStrategy.accountId,
            marketplace: this.focusedStrategy.marketplace,
          },
          class: "modal-xl modal-dialog-centered",
        };
        if (segmentCreationRequest.segmentType == SegmentConfigType.ProductSegment) {
          const segmentCreationRef = this.modalService.show(ProductSegmentModalComponent, segmentCreationModalOption);
          this.createTacticAfterSegmentCreation(
            subscription,
            segmentCreationRequest.tacticType,
            segmentCreationRef.content.segmentCreated,
            segmentCreationRef.content.segmentEditionCanceled,
          );
        } else {
          const segmentCreationRef = this.modalService.show(KeywordSegmentModalComponent, segmentCreationModalOption);
          this.createTacticAfterSegmentCreation(
            subscription,
            segmentCreationRequest.tacticType,
            segmentCreationRef.content.segmentCreated,
            segmentCreationRef.content.segmentEditionCanceled,
          );
        }
      });
    subscription.add(
      modalRef.content?.tacticCreated.pipe(untilDestroyed(this)).subscribe((tactic) => {
        this.focusedTactics = [...this.focusedTactics, tactic];
        subscription.unsubscribe();
      }),
    );
    subscription.add(
      modalRef.content.tacticCreationCancelled.pipe(untilDestroyed(this)).subscribe(() => subscription.unsubscribe()),
    );
  }

  private createTacticAfterSegmentCreation(
    subscription: Subscription,
    tacticType: TacticType,
    segmentCreated: Observable<SegmentEx>,
    segmentCreationCancelled: Observable<void>,
  ) {
    subscription.add(
      segmentCreated
        .pipe(
          untilDestroyed(this),
          switchMap((s) => {
            return this.configurationService.addTacticToStrategyAsync(
              this.focusedStrategy.accountId,
              this.focusedStrategy.marketplace,
              this.focusedStrategy.strategyId,
              s.segmentId,
              tacticType,
            );
          }),
        )
        .subscribe({
          next: (t: StrategyTacticEx) => {
            this.toastrService.success("Tactic sucessfully added to strategy", "Tactic created");
            this.focusedTactics = [...this.focusedTactics, t];
            subscription.unsubscribe();
          },
          error: (error) => {
            this.toastrService.error(error, "Tactic creation error");
            subscription.unsubscribe();
          },
        }),
    );
    subscription.add(segmentCreationCancelled.pipe(untilDestroyed(this)).subscribe(() => subscription.unsubscribe()));
  }

  changeName(newName: string) {
    this.configurationService
      .updateStrategyGroup({
        accountId: this.strategyGroup.accountId,
        marketplace: this.strategyGroup.marketplace,
        organizationId: this.accountMarketplace.resourceOrganizationId,
        strategyGroupId: this.strategyGroup.strategyGroupId,
        strategyGroupName: newName,
      })
      .subscribe({
        next: () => {
          this.toastrService.success("Strategy Group name updated");
        },
        error: (e: string) => {
          this.toastrService.error(e, "Strategy Group name update eror");
        },
      });
  }

  updateProductSelection(asins: StrategyAsin[]) {
    this.productsToDelete = new Set(asins.map((a) => a.asin));
    this.areAllProductsSelected = this.allProductsSelected();
  }

  deleteProducts() {
    if (this.productsToDelete.size == 0) {
      return;
    }
    const impactedStrats = new Map<StrategyEx, string[]>();
    for (const asin of this.productsToDelete.values()) {
      for (const strat of this.strategiesPerAsin.get(asin)) {
        if (impactedStrats.has(strat)) {
          impactedStrats.get(strat).push(asin);
        } else {
          impactedStrats.set(strat, [asin]);
        }
      }
    }
    //  confirmation modal
    const modalOpts: ModalOptions = {
      initialState: {
        title: "Product deletion",
        message: `Th${this.productsToDelete.size > 1 ? "ese" : "is"} product${
          this.productsToDelete.size > 1 ? "s are" : " is"
        } used in ${impactedStrats.size}  ${impactedStrats.size > 1 ? "strategies" : "strategy"} (${Array.from(
          impactedStrats.keys(),
        )
          .map((s) => `"${s.name}"`)
          .join(", ")}). ${impactedStrats.size > 1 ? "They" : "It"} will stop running for ${
          this.productsToDelete.size > 1 ? "these products" : "this product"
        }.`,
      },
    };
    const modalRef = this.modalService.show(ConfirmPopupComponent, modalOpts);
    const sub = modalRef.content.confirm
      .pipe(
        switchMap(() =>
          forkJoin(
            Array.from(impactedStrats.entries()).map(([strat, asins]) =>
              this.configurationService.deleteAsinsFromStrategy(strat, asins),
            ),
          ),
        ),
        // TODO: also stop strategies if no more ASINs
      )
      .subscribe(() => {
        this.productsToDelete.clear();
        this.toastrService.success("Product(s) removed from strategy group");
        if (this.focusedStrategy) {
          this.focusedStrategy.asins = this.focusedStrategy.asins.filter((a) => !this.productsToDelete.has(a.asin));
        }
        sub.unsubscribe();
      });
    sub.add(
      modalRef.content.cancel.subscribe(() => {
        sub.unsubscribe();
      }),
    );
  }

  tacticDeleted(segmentId: number) {
    this.focusedTactics = this.focusedTactics.filter((t) => t.segment.segmentId !== segmentId);
  }

  private allProductsSelected() {
    return this.productsToDelete.size == this.strategyGroup?.asins.length;
  }

  private openStrategyStats(strategy: StrategyEx) {
    const modalOpts: ModalOptions = {
      initialState: {
        strategy,
        isReadOnly: this.isReadOnly,
      },
      class: "modal-xl",
    };
    this.modalService.show(StrategyStatsComponent, modalOpts);
  }

  private openStrategyActivities(strategy: StrategyEx) {
    const modalOpts: ModalOptions = {
      initialState: {
        strategy,
      },
      class: "modal-xl",
    };
    this.modalService.show(StrategyActivityModalComponent, modalOpts);
  }

  openAddProductModal() {
    const modalOptions: ModalOptions = {
      initialState: {
        accountMarketplace: this.accountMarketplace,
        strategyGroup: this.strategyGroup,
      },
      class: "modal-xl modal-dialog-centered",
    };
    const ref = this.modalService.show(StrategyGroupAddProductModalComponent, modalOptions);
    ref.content.newAsins
      .pipe(
        untilDestroyed(this),
        filter((update) => update != "CANCEL"),
        take(1),
        switchMap((update: StrategyGroupProductsToAdd) => {
          // product strategy creation
          if (update.type == "NewProductStrategy") {
            const toCreate = update.productStrategyToCreate;
            const reached = this.configurationService.isStrategyLimitReached(CampaignType.SP);
            if (reached) {
              toCreate.state = StrategyStateEnum.PAUSED;
            }
            return this.configurationService.createStrategyAsync(toCreate);
          }
          // product strategy update
          return this.configurationService.addAsinsToStrategy(update.productStrategy, update.asins);
        }),
      )
      .subscribe({
        next: () => {
          this.toastrService.success("Products added");
        },
        error: (e: string) => {
          this.toastrService.error(e, "Error adding products");
        },
      });
  }

  openBlackListCreationModal(creation: boolean) {
    const modalOptions: ModalOptions = {
      initialState: {
        accountId: this.accountMarketplace.accountId,
        marketplace: this.accountMarketplace.marketplace,
        isReadOnly: this.isReadOnly,
        modalTitle: creation ? "Blacklist Creation" : "Blacklist Update",
        blacklist: creation ? [] : this.strategyGroup.blacklist,
      },
      class: "modal-xl",
    };
    const ref = this.modalService.show(BlacklistModalComponent, modalOptions);
    ref.content.updatedBlacklist
      .pipe(
        untilDestroyed(this),
        filter((update) => update != "CANCEL"),
        switchMap((update: UpdateOperations<Targeting>) =>
          this.configurationService.updateStrategyGroupBlacklist({
            strategyGroupId: this.strategyGroup.strategyGroupId,
            accountId: this.accountMarketplace.accountId,
            marketplace: this.accountMarketplace.marketplace,
            toAdd: update.toAdd,
            toDelete: update.toDelete,
          }),
        ),
        take(1), // complete after the update (and unsubscribe)
      )
      .subscribe({
        next: () => {
          this.toastrService.success("Blacklist updated");
        },
        error: (e: string) => {
          this.toastrService.error(e, "Strategy Group blacklist update error");
        },
      });
  }

  openSubStrategyCreationPage(strategyType: StrategyType) {
    this.router.navigate([`${this.strategyGroupPageUrl}/${this.strategyGroup.strategyGroupId}/create-strategy`], {
      queryParamsHandling: "merge",
      queryParams: {
        strategyType,
      },
    });
  }

  switchOptimizerStatus(strategy: StrategyEx, event: MatSlideToggleChange): void {
    const enable = event.checked;

    const keywords: string[] = enable
      ? strategy.targetings.filter((t) => t.matchType == MatchType.exact).map((t) => t.targetingValue)
      : strategy.topOfSearchRankings.map((t) => t.keyword);
    this.configurationService
      .updateTopOfSearchRankings(
        strategy,
        this.accountMarketplace.resourceOrganizationId,
        keywords,
        enable ? UpdateStrategyTopOfSearchRankingsActionEnum.ADD : UpdateStrategyTopOfSearchRankingsActionEnum.DELETE,
      )
      .subscribe({
        next: () => {
          if (enable) {
            this.toastrService.success(
              `Activated on Strategy ${strategy.name}`,
              "Top Of Search Rankings Optimizer Activated",
            );
          } else {
            this.toastrService.success(
              `Dectivated on Strategy ${strategy.name}`,
              "Top Of Search Rankings Optimizer Deactivated",
            );
          }
        },
        error: (error) => {
          event.source.toggle();
          this.toastrService.error(`Error: ${error}`, "Top Of Search Rankings Optimizer update error");
        },
      });
  }

  strategyAsinAccessValue(asin: StrategyAsin): string {
    return asin.asin;
  }

  tacticAccessValue(tactic: StrategyTacticEx): string {
    return tactic.segment.name;
  }

  getValueFromInputEvent(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }
}
