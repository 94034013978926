import { Component, ViewEncapsulation } from "@angular/core";
import {
  AbstractControl,
  AbstractControlOptions,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { AuthService, User } from "@front/m19-services";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { catchError, switchMap, throwError } from "rxjs";

@Component({
  templateUrl: "./change-password.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class ChangePasswordComponent {
  user: User;
  readonly faLock = faLock;

  readonly passwordForm = this.fb.group(
    {
      oldPwd: ["", [Validators.required]],
      newPw1: ["", [Validators.required, Validators.minLength(8)]],
      newPw2: ["", Validators.required],
    },
    { validators: ConfirmedValidator("newPw1", "newPw2") } as AbstractControlOptions,
  );

  get oldPwd(): AbstractControl {
    return this.passwordForm.get("oldPwd");
  }

  get newPw1(): AbstractControl {
    return this.passwordForm.get("newPw1");
  }

  get newPw2(): AbstractControl {
    return this.passwordForm.get("newPw2");
  }

  constructor(
    private fb: UntypedFormBuilder,
    private bsModalRef: BsModalRef,
    private authService: AuthService,
    private toasterService: ToastrService,
  ) {}

  updatePassword() {
    this.authService
      .fetchTokenPassword(this.user.email, this.oldPwd.value)
      .pipe(
        catchError(() => throwError(() => "Your password is invalid")),
        switchMap(() => this.authService.updatePasswordAsync({ ...this.user, newPassword: this.newPw1.value })),
      )
      .subscribe({
        next: () => {
          this.bsModalRef.hide();
          this.toasterService.success("Password successfully updated");
        },
        error: (error: string) => {
          this.toasterService.error("Error updating password: " + error, "Password update error");
        },
      });
  }

  close() {
    this.bsModalRef.hide();
  }
}

export function ConfirmedValidator(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup): void => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmedValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
