import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AccountMarketplace,
  AccountSelectionService,
  AuthService,
  CampaignType,
  ConfigService,
  Strategy,
  StrategyGroupEx,
  StrategyStateEnum,
  StrategyType,
  StrategyTypeStr,
} from "@front/m19-services";
import { ICON_CHEVRON_DOWN } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ToastrService } from "ngx-toastr";
import { switchMap } from "rxjs";

@UntilDestroy()
@Component({
  template: `@if (strategyGroup && strategyType && accountMarketplace) {
    <div class="mb-3 flex items-center justify-between px-2">
      <div class="flex items-center">
        <a
          class="btn btn-ghost-secondary btn-lg inline-flex items-center"
          type="button"
          routerLink="{{ strategyPageUrl }}"
          queryParamsHandling="merge"
          matTooltip="Back to Sponsored Products strategy list"
          matTooltipPosition="above"
        >
          Sponsored Products
        </a>
        <span [class]="ICON_CHEVRON_DOWN + ' mx-2 -rotate-90'"></span>
        <a
          class="btn btn-ghost-secondary btn-lg inline-flex items-center"
          type="button"
          routerLink=".."
          queryParamsHandling="merge"
        >
          {{ strategyGroup?.strategyGroupName }}
        </a>
        <span [class]="ICON_CHEVRON_DOWN + ' mx-2 -rotate-90'"></span>
        <a
          class="btn btn-ghost-secondary btn-lg inline-flex items-center"
          type="button"
          routerLink="."
          queryParamsHandling="merge"
        >
          New {{ strategyTypeStr | titlecase }} Strategy
        </a>
      </div>
      <div>
        <div>
          Account:&nbsp;<strong>{{ accountMarketplace.accountGroupName }}</strong>
        </div>
        <div>
          Marketplace:&nbsp;<strong>{{ accountMarketplace.marketplace }}</strong>
        </div>
      </div>
    </div>
    <app-sp-substrategy-creation
      [strategyGroup]="strategyGroup"
      [accountMarketplace]="accountMarketplace"
      [strategyType]="strategyType"
      (strategyToCreate)="createStrategy($event)"
    ></app-sp-substrategy-creation>
  }`,
})
export class SpSubStrategyCreationPageComponent implements OnInit {
  strategyGroup: StrategyGroupEx;
  strategyType: StrategyType;
  accountMarketplace: AccountMarketplace;
  strategyTypeStr: string;
  creationOnGoing = false;
  readonly ICON_CHEVRON_DOWN = ICON_CHEVRON_DOWN;
  strategyPageUrl = "/strategies/sponsored-product";
  strategyGroupPageUrl = "/strategies/strategy-group/sponsored-product";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private configurationService: ConfigService,
    private accountMarketplaceSelection: AccountSelectionService,
    private toastrService: ToastrService,
    private authService: AuthService,
  ) {
    this.authService.loggedUser$.subscribe((user) => {
      if ((user?.uiVersion ?? 0) > 0) {
        this.strategyPageUrl = "/advertising/sponsored-product";
        this.strategyGroupPageUrl = "/advertising/sponsored-product/strategy-group";
      }
    });
  }

  ngOnInit(): void {
    this.route.queryParamMap
      // no need for untildestroyed as it will complete when changing page
      .subscribe((params) => {
        this.strategyType = params.get("strategyType") as StrategyType;
        if (!StrategyTypeStr[this.strategyType]) {
          // invalid strategy type
          this.router.navigate([this.strategyPageUrl]);
          return;
        }
        this.strategyTypeStr = StrategyTypeStr[this.strategyType];
      });
    this.route.paramMap
      .pipe(
        switchMap((params) => {
          const id = Number(params.get("id"));
          return this.configurationService.getStrategyGroupById(id);
        }),
        untilDestroyed(this),
      )
      .subscribe((strategyGroup: StrategyGroupEx) => {
        // redirect to strategy page if the strategy group cannot be found
        if (!strategyGroup) {
          this.router.navigate([this.strategyPageUrl], { queryParamsHandling: "merge" });
          return;
        }
        this.strategyGroup = strategyGroup;
      });
    this.accountMarketplaceSelection.singleAccountMarketplaceSelection$.pipe(untilDestroyed(this)).subscribe((am) => {
      this.accountMarketplace = am;
    });
  }

  createStrategy(event: Strategy) {
    if (this.creationOnGoing) {
      return;
    }
    this.creationOnGoing = true;
    const reached = this.configurationService.isStrategyLimitReached(CampaignType.SP);
    if (reached) {
      event.state = StrategyStateEnum.PAUSED;
    }
    this.configurationService.createStrategyAsync(event).subscribe({
      next: () => {
        this.creationOnGoing = false;
        this.toastrService.success(this.strategyTypeStr + " strategy created");
        this.router.navigate([this.strategyGroupPageUrl + "/" + this.strategyGroup.strategyGroupId], {
          queryParamsHandling: "merge",
        });
      },
      error: (e: string) => {
        this.creationOnGoing = false;
        this.toastrService.error(e, this.strategyTypeStr + " strategy creation errir");
      },
    });
  }
}
