// tslint:disable
/**
 * M19 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined } from '../runtime';
import type { OperationOpts, HttpQuery } from '../runtime';
import type {
    Marketplace,
    Similarities,
} from '../models';

export interface GetSimilarRequest {
    marketplace: Marketplace;
    term?: Array<string>;
    asin?: Array<string>;
}

/**
 * no description
 */
export class KeywordApi extends BaseAPI {

    /**
     * Provide similar search terms and products to a given set of search terms and products
     * Similar search terms and products
     */
    getSimilar({ marketplace, term, asin }: GetSimilarRequest): Observable<Similarities>
    getSimilar({ marketplace, term, asin }: GetSimilarRequest, opts?: OperationOpts): Observable<AjaxResponse<Similarities>>
    getSimilar({ marketplace, term, asin }: GetSimilarRequest, opts?: OperationOpts): Observable<Similarities | AjaxResponse<Similarities>> {
        throwIfNullOrUndefined(marketplace, 'marketplace', 'getSimilar');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'marketplace': marketplace,
        };

        if (term != null) { query['term'] = term; }
        if (asin != null) { query['asin'] = asin; }

        return this.request<Similarities>({
            url: '/keyword/similar',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

}
