import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faArrowRight, faCheckSquare, faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  AccountSelectionService,
  AsinService,
  Catalog,
  Marketplace,
  ProductGroupEx,
  ProductGroupService,
  StrategyAsin,
  Utils,
} from "@front/m19-services";
import { ICON_CLOSE, ICON_SEARCH } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { combineLatest, Subject } from "rxjs";
import { switchMap } from "rxjs/operators";
import { ProductGroupDetailsPopupComponent } from "../product-group/product-group-detail-popup.component";
import { StrategyAsinsComponent } from "./strategy-asins.component";

export enum StrategyAsinSelectionMode {
  FromCatalog = 0,
  Bulk = 1,
  FromProductGroups = 2,
  FromCustomAsinList = 3,
}

@UntilDestroy()
@Component({
  selector: "app-asins-selection",
  templateUrl: "./asins-selection.component.html",
  styleUrls: ["./asins-selection.component.scss"],
})
export class AsinsSelectionComponent implements OnInit, OnChanges {
  @Input()
  set asins(a: StrategyAsin[]) {
    this.asins_ = a;
    this.updateAsins();
  }
  private asins_: StrategyAsin[];
  get asins() {
    return this.asins_;
  }

  @Input()
  accountId: string;

  @Input()
  marketplace: Marketplace;

  @Input()
  csvExport = true;

  @Input()
  csvExportFileName: string;

  @Input() asinEligibility: Map<string, { status: boolean; reason: string }> = new Map();

  @Input() notDisplayedAsins: string[] = [];

  @Input() disabled = false;

  @Input() onlyAddParent = false;

  @Input() allowAsinFromOtherCatalog = false;

  @Input() bulkPlaceholderText = `ASIN list (Max ${ProductGroupEx.MaxProductGroupItems} ASINs per strategy)`;

  @Input() bulkButtonLabel = "Bulk";

  @Input() maxProducts = ProductGroupEx.MaxProductGroupItems;

  @Input() selectionModes: { selectionMode: StrategyAsinSelectionMode; label: string }[] = [
    { selectionMode: StrategyAsinSelectionMode.FromCatalog, label: "Catalog" },
    { selectionMode: StrategyAsinSelectionMode.Bulk, label: "Bulk" },
    { selectionMode: StrategyAsinSelectionMode.FromProductGroups, label: "Product Group" },
  ];

  @Input() height: string;

  @Input() customAsinList: string[];

  @Output()
  onAdd = new EventEmitter<StrategyAsin[]>();

  @Output()
  onDelete = new EventEmitter<StrategyAsin[]>();

  @ViewChild("asinsSelection")
  asinsSelection: StrategyAsinsComponent;

  isReadOnly = false;
  _asins: StrategyAsin[];
  selectionMode: StrategyAsinSelectionMode;
  catalog: Catalog;
  productGroups: ProductGroupEx[];
  filteredProductGroups: ProductGroupEx[];
  catalogAsins: StrategyAsin[];
  customAsins: StrategyAsin[];
  selectedCatalogAsins: StrategyAsin[] = [];
  selectedProductGroups: ProductGroupEx[] = [];
  selectedProductGroupItems = 0;
  bulkAsins = "";
  errors = [];
  productGroupFilter = new RegExp("", "i");
  private productGroupItems = new Map<number, string[]>(); // eligible ASINs (e.g. same mktplace) indexed by product group

  pgSearchControl = new FormControl("");

  readonly SelectionModes = StrategyAsinSelectionMode;
  readonly faArrowRight = faArrowRight;
  readonly faSearch = faSearch;
  readonly faSquare = faSquare;
  readonly faCheckedSquare = faCheckSquare;
  readonly ICON_CLOSE = ICON_CLOSE;
  readonly ICON_SEARCH = ICON_SEARCH;

  private setupAsins$ = new Subject<void>();

  constructor(
    private asinService: AsinService,
    private productGroupService: ProductGroupService,
    private modalService: BsModalService,
    private accountSelectionService: AccountSelectionService,
  ) {}

  ngOnChanges(): void {
    this.updateAsins();
  }

  ngOnInit(): void {
    this.updateAsins();

    this.accountSelectionService.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((readOnly) => {
      this.isReadOnly = readOnly;
    });
    this.selectionMode = this.selectionModes[0].selectionMode;
    this.customAsins = (this.customAsinList ?? [])
      .filter((a) => !this.notDisplayedAsins.includes(a))
      .map((a) => ({
        asin: a,
      }));
    combineLatest<[Catalog, ProductGroupEx[]]>([
      this.setupAsins$.pipe(
        switchMap(() => this.asinService.getCatalog(this.accountId, this.marketplace)),
        untilDestroyed(this),
      ),
      this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(
        switchMap((am) => this.productGroupService.getProductGroups(am.accountId, am.marketplace)),
      ),
    ]).subscribe(([catalog, pg]: [Catalog, ProductGroupEx[]]) => {
      this.catalog = catalog;
      this.catalogAsins = [];
      for (const asin of catalog.childAsins)
        if (!this.notDisplayedAsins.includes(asin)) this.catalogAsins.push({ asin: asin });
      this.selectedCatalogAsins = [];
      this.selectedProductGroups = [];
      this.bulkAsins = "";
      this.productGroups = pg.filter((p) => p.items.length > 0);
      this.filteredProductGroups = [...this.productGroups];
      this.productGroupFilter = new RegExp("", "i");
      for (const productGroup of this.productGroups) {
        this.productGroupItems.set(
          productGroup.productGroupId,
          productGroup.items.filter((a) => catalog.contains(a)),
        );
      }
    });

    if (this.accountId && this.marketplace) this.setupAsins$.next(void 0);

    this.pgSearchControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.setProductGroupFilter(value);
    });
  }

  private updateAsins() {
    this._asins = this.asins;
    if (this.accountId && this.marketplace) this.setupAsins$.next(void 0);
    if (this.asinsSelection) {
      this.asinsSelection.asins = this.asins;
    }
  }

  changeSelectionMode(selectionMode: StrategyAsinSelectionMode) {
    this.selectionMode = selectionMode;
    this.selectedCatalogAsins = [];
    this.selectedProductGroups = [];
    this.bulkAsins = "";
  }

  selectCatalogAsins(selectedAsins: StrategyAsin[]) {
    this.selectedCatalogAsins = selectedAsins;
  }

  isAddButtonDisabled() {
    return this.disabled || !this.selectedCatalogAsins || this.selectedCatalogAsins.length === 0;
  }

  isBulkAddButtonDisabled() {
    return this.disabled || !this.bulkAsins || this.bulkAsins.length === 0;
  }

  isProductGroupAddButtonDisabled() {
    return this.disabled || !this.selectedProductGroups || this.selectedProductGroups.length === 0;
  }

  selectAllProductGroups() {
    for (const productGroup of this.filteredProductGroups) {
      if (
        this.getProductGroupItems(productGroup).length > 1 &&
        !this.productGroupAlreadySelected(productGroup) &&
        this.selectedProductGroups.findIndex((pg) => pg.productGroupId == productGroup.productGroupId) < 0
      ) {
        this.selectedProductGroups.push(productGroup);
      }
    }
    this.selectedProductGroupItems = this.selectedProductGroups.map((p) => p.items.length).reduce((a, b) => a + b, 0);
  }

  unselectAllProductGroups() {
    this.selectedProductGroups = [];
  }

  setProductGroupFilter(filter: string) {
    this.productGroupFilter = new RegExp(filter, "i");
    this.filteredProductGroups = this.productGroups.filter((pg) => this.productGroupFilter.test(pg.productGroupName));
  }

  isAllProductGroupSelected() {
    return (
      this.selectedProductGroups.length ===
      this.productGroups.filter((p) => !this.productGroupAlreadySelected(p)).length
    );
  }

  selectProductGroup(productGroup: ProductGroupEx) {
    const index = this.selectedProductGroups.findIndex((pg) => pg.productGroupId == productGroup.productGroupId);
    if (index < 0) {
      this.selectedProductGroups.push(productGroup);
    } else {
      this.selectedProductGroups.splice(index, 1);
    }
    this.selectedProductGroupItems = this.selectedProductGroups.map((p) => p.items.length).reduce((a, b) => a + b, 0);
  }

  isProductGroupSelected(productGroup: ProductGroupEx) {
    return this.selectedProductGroups.findIndex((pg) => pg.productGroupId == productGroup.productGroupId) >= 0;
  }

  productGroupAlreadySelected(productGroup: ProductGroupEx) {
    const asins = this._asins.map((a) => a.asin);
    return this.getProductGroupItems(productGroup).every(
      (a) => asins.includes(a) || this.notDisplayedAsins.includes(a),
    );
  }

  addStrategyAsin() {
    if (this.selectedCatalogAsins.length + this._asins.length > this.maxProducts) {
      this.errors = ["Max limit of products reached (" + this.maxProducts + " ASINs)"];
      return;
    }
    this.errors = [];
    this.onAdd.emit(this.selectedCatalogAsins);
  }

  addStrategyAsinByBulk() {
    const asins = this.bulkAsins.split(/[\s,;\n]+/).map((a) => a.trim().toUpperCase());
    this.errors = [];
    const toAdd: StrategyAsin[] = [];
    const asinsLength = this._asins.length;

    for (const asin of asins) {
      if (asin == "") continue;
      if (!Utils.isValidAsin(asin)) {
        this.errors.push(asin + ": invalid ASIN");
        continue;
      }
      if (this._asins.findIndex((a) => a.asin == asin) >= 0) {
        this.errors.push(asin + ": already selected");
        continue;
      }
      if (this.notDisplayedAsins.findIndex((a) => a == asin) >= 0) {
        this.errors.push(asin + ": cannot be selected");
        continue;
      }
      if (this.customAsinList && !this.customAsinList.some((a) => a == asin)) {
        this.errors.push(asin + ": not allowed");
        continue;
      }
      if (!this.allowAsinFromOtherCatalog && !this.catalog.contains(asin)) {
        this.errors.push(asin + ": unknown - not in catalog");
        continue;
      }
      if (!this.allowAsinFromOtherCatalog && !this.catalog.childAsins.has(asin)) {
        this.errors.push(asin + ": not a child ASIN");
        continue;
      }
      if (toAdd.findIndex((a) => a.asin == asin) >= 0) {
        this.errors.push(asin + ": duplicated - will be added once");
        continue;
      }
      if (toAdd.length + asinsLength >= this.maxProducts) {
        this.errors.push(asin + ": max limit of products reached (" + this.maxProducts + " ASINs)");
        continue;
      }
      toAdd.push({ asin: asin });
    }
    if (toAdd.length == 0) {
      this.errors.push("No ASIN to add");
      this.bulkAsins = "";
      return;
    }
    this.onAdd.emit(toAdd);
  }

  getProductGroupItems(productGroup: ProductGroupEx) {
    return this.productGroupItems.get(productGroup.productGroupId) ?? [];
  }

  addStrategyAsinByProductGroup() {
    const asins: StrategyAsin[] = [];
    for (const productGroup of this.selectedProductGroups) {
      for (const asin of this.getProductGroupItems(productGroup)) {
        if (
          asins.findIndex((a) => a.asin == asin) < 0 &&
          this._asins.findIndex((a) => a.asin == asin) < 0 &&
          this.notDisplayedAsins.findIndex((a) => a == asin) < 0
        ) {
          asins.push({ asin: asin });
        }
      }
    }
    if (asins.length > 0) {
      this.onAdd.emit(asins);
    }
  }

  removeAsins(asinsToRemove: StrategyAsin[]) {
    this.onDelete.emit(asinsToRemove);
  }

  openProductGroupInfo(productGroup: ProductGroupEx) {
    const modalOptions: ModalOptions = {
      initialState: {
        group: productGroup,
        marketplace: this.marketplace,
      },
    };
    this.modalService.show(ProductGroupDetailsPopupComponent, modalOptions);
  }
}
