import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { AsinService, Catalog, Marketplace, ProductEx } from "@front/m19-services";
import { Observable } from "rxjs";

@UntilDestroy()
@Component({
  selector: "app-cluster-asin-card",
  templateUrl: "./cluster-asin-card.component.html",
  styleUrls: ["./cluster-asin-card.component.scss"],
})
export class ClusterAsinCardComponent implements OnInit {
  @Input() small = false;
  @Input() isVideoCreative = false;
  @Input() groupNumberLimit = 10;
  @Input() groupLength: number; // number of created group so far
  @Input() catalog: Catalog;

  @Input() marketplace: Marketplace;
  @Input() asin: string;

  @Input() ineligible = false;
  @Input() inCluster = false;
  // index of available clusters
  @Input() availableClusters: number[] = [1, 3, 4];

  @Output() remove = new EventEmitter();
  @Output() add = new EventEmitter<number>();

  product: ProductEx;

  constructor(private asinService: AsinService) {}

  ngOnInit() {
    this.product = this.catalog?.products.find((p) => p.asin === this.asin);
  }

  addVideoAsin() {
    if (this.availableClusters.length > 0) {
      this.add.emit(0);
    }
  }

  canAddNewGroup() {
    return this.groupLength < this.groupNumberLimit;
  }
}
