import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { faSquare, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faArrowRight, faCheckSquare, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { MatchType, Targeting } from "@front/m19-services";
import { CsvExportService, simpleField } from "@m19-board/services/csv-export.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { IgnoredKeywordsModalComponent } from "@m19-board/shared/ignored-keywords-modal/ignored-keywords-modal.component";
import { Constant } from "libs/m19-services/src/lib/m19-services/constant";
import { SegmentService } from "libs/m19-services/src/lib/m19-services/segmentService";

type Selectable<T> = {
  selected: boolean;
  item: T;
};

export type UpdateOperations<T> = {
  toAdd: T[];
  toDelete: T[];
  newState: T[];
};

@Component({
  templateUrl: "./blacklist-modal.component.html",
  styleUrls: ["./blacklist-modal.component.scss"],
})
export class BlacklistModalComponent implements OnInit {
  @Input()
  modalTitle: string;
  @Input()
  blacklist: Targeting[] = [];
  @Input() isReadOnly = false;

  @Output()
  updatedBlacklist = new EventEmitter<UpdateOperations<Targeting> | "CANCEL">();

  bulkKeywords: string;
  datasourceKeyword = new MatTableDataSource<Selectable<Targeting>>();
  matchType = MatchType.phrase;
  importErrors: string[];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  readonly faArrowRight = faArrowRight;
  readonly faTrash = faTrashAlt;
  readonly faSearch = faSearch;
  readonly faSquare = faSquare;
  readonly faCheckedSquare = faCheckSquare;
  readonly faTimes = faTimes;
  readonly MatchType = MatchType;
  readonly maxKwTargetingByStrategy = Constant.maxKwTargetingByStrategy;

  constructor(
    public bsModalRef: BsModalRef,
    private csvExportService: CsvExportService,
    private modalService: BsModalService,
  ) {}

  ngOnInit(): void {
    this.datasourceKeyword.paginator = this.paginator;
    this.datasourceKeyword.sort = this.sort;
    this.datasourceKeyword.sortingDataAccessor = (item, property): string | number => {
      if (property == "matchType") return item.item.matchType;
      if (property == "keyword") return item.item.targetingValue;
    };
    this.datasourceKeyword.filterPredicate = (row, filter) => {
      if (filter) {
        const regexp = new RegExp(filter, "i");
        const result = regexp.test(row.item.targetingValue);
        return result;
      }
      return true;
    };
    if (this.blacklist.length > 0) {
      const { errors, keywords } = SegmentService.checkKeywords(
        this.blacklist,
        this.datasourceKeyword.data.map((s) => s.item),
        Constant.maxKwTargetingByStrategy,
      );
      this.datasourceKeyword.data = keywords.map((s) => ({ selected: false, item: s }));
      this.importErrors = errors;
    }
  }

  close() {
    this.updatedBlacklist.emit("CANCEL");
    this.bsModalRef.hide();
  }

  addKeyword() {
    if (this.keywordToAddInvalid()) {
      return;
    }
    const kw: Targeting[] = this.bulkKeywords.split("\n").map((k) => {
      return { targetingValue: k, matchType: this.matchType };
    });
    const { errors, keywords } = SegmentService.checkKeywords(
      kw,
      this.datasourceKeyword.data.map((s) => s.item),
      Constant.maxKwTargetingByStrategy,
    );
    this.datasourceKeyword.data = keywords.map((s) => ({ selected: false, item: s }));
    this.importErrors = errors;
    this.bulkKeywords = "";
  }

  deleteSelectedKeywords() {
    this.datasourceKeyword.data = this.datasourceKeyword.data.filter((s) => !s.selected);
  }

  deleteKeyword(keyword: Targeting) {
    this.datasourceKeyword.data = this.datasourceKeyword.data.filter(
      (k) => k.item.matchType != keyword.matchType || k.item.targetingValue != keyword.targetingValue,
    );
  }

  setFilter(filter: string) {
    this.datasourceKeyword.filter = filter.trim();
  }

  select(keyword: Selectable<Targeting>) {
    keyword.selected = !keyword.selected;
  }

  toggleSelectAll() {
    const selected = this.allSelected();
    this.datasourceKeyword.filteredData.forEach((d) => (d.selected = !selected));
  }

  nbSelectedKw() {
    return this.datasourceKeyword.filteredData.filter((s) => s.selected).length;
  }

  allSelected() {
    return this.datasourceKeyword.filteredData.every((s) => s.selected);
  }

  setMatchType(matchType: MatchType) {
    this.matchType = matchType;
  }

  keywordToAddInvalid() {
    return !this.bulkKeywords || this.bulkKeywords.trim() === "";
  }

  clearErrors() {
    this.importErrors = [];
  }

  save() {
    const kwToAdd = [];
    const kwToDelete = [];
    for (const kw of this.datasourceKeyword.data) {
      if (
        this.blacklist.findIndex(
          (s) => s.targetingValue == kw.item.targetingValue && s.matchType == kw.item.matchType,
        ) < 0
      ) {
        kwToAdd.push(kw.item);
      }
    }
    for (const kw of this.blacklist) {
      if (
        this.datasourceKeyword.data.findIndex(
          (s) => s.item.matchType == kw.matchType && s.item.targetingValue == kw.targetingValue,
        ) < 0
      ) {
        kwToDelete.push(kw);
      }
    }
    this.updatedBlacklist.emit({
      toAdd: kwToAdd,
      toDelete: kwToDelete,
      newState: this.datasourceKeyword.data.map((i) => i.item),
    });
    this.bsModalRef.hide();
  }

  isInvalid() {
    return false;
  }

  invalidTooltip() {
    if (this.isReadOnly) {
      return "You do not have rights to update blacklist";
    }
    return "";
  }

  downloadFile() {
    this.csvExportService.exportCsv(
      {
        prefix: "blacklist",
      },
      this.datasourceKeyword.data.map((s) => s.item),
      [simpleField("matchType"), simpleField("targetingValue", "keyword")],
    );
  }

  openIgnoredKeywordModal() {
    const errorObjs: { kw: string; reason: string }[] = [];
    for (const error of this.importErrors) {
      const splitted = error.split(":");
      errorObjs.push({ kw: splitted[0], reason: splitted[1] });
    }
    this.modalService.show(IgnoredKeywordsModalComponent, {
      initialState: {
        errors: errorObjs,
      },
      class: "modal-lg",
    });
  }

  getValueFromInputEvent(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }
}
