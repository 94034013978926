import { AgGridAngular } from "@ag-grid-community/angular";
import {
  ColDef,
  GridOptions,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from "@ag-grid-community/core";
import { Component, OnInit, signal, ViewChild, WritableSignal } from "@angular/core";
import {
  AccountMarketplace,
  AccountMarketplaceService,
  AccountSelectionService,
  AuthService,
  Currency,
  DataSet,
  DateAggregation,
  DspAdgroup,
  DspAdvertiser,
  DspCampaign,
  DspCampaignStateEnum,
  DspCreative,
  DspCreativeAdCreativeFormatPropertiesAdCreativeFormatTypeEnum,
  DspDeliveryStatus,
  DspService,
  DspStats,
  getBasicGridOptions,
  getFilteredLeafNodes,
  Metric,
  MetricsSelectorLocalStorageKey,
  PALETTE,
  SIDE_BAR_NO_PIVOT,
  StatsApiClientService,
  UserSelectionService,
} from "@front/m19-services";
import { TranslocoService } from "@jsverse/transloco";
import { actionColumnProperties } from "@m19-board/grid-config/grid-columns";
import { exportGridCsv, getCsvFileName, getMetricsColDef } from "@m19-board/grid-config/grid-config";
import {
  ActionButton,
  ActionButtonsComponent,
} from "@m19-board/insights/overview/action-buttons/action-buttons.component";
import {
  DSP_3P_FEES,
  DSP_ACOS,
  DSP_ADD_TO_CART,
  DSP_CLICKS,
  DSP_COST,
  DSP_FEE,
  DSP_IMPRESSIONS,
  DSP_NEW_TO_BRAND_PRODUCT_SALES,
  DSP_PAGE_VIEWS,
  DSP_PURCHASE_CLICKS,
  DSP_PURCHASES,
  DSP_ROAS,
  DSP_SALES,
  DSP_SUPPLY_COST,
  DSP_UNITS_SOLD,
} from "@m19-board/models/DspMetrics";
import { ChartData, ChartRendererComponent } from "@m19-board/shared/chart-renderer/chart-renderer.component";
import { FilterTag, FilterTagVal } from "@m19-board/shared/filter-tags/filter-tags.component";
import { ICON_CHART_LINE, ICON_CLOSE, ICON_LIST } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  map,
  Observable,
  of,
  ReplaySubject,
  shareReplay,
  Subject,
  switchMap,
  tap,
} from "rxjs";
import { DspStatsDetailsModalComponent } from "./dsp-stats-details-modal.component";

@Component({
  selector: "app-dsp-stats",
  template: ` <app-stats-overlay *transloco="let t">
    @if (loading()) {
      <div class="flex w-full items-center justify-center">
        <app-spinner [display]="true"></app-spinner>
      </div>
    } @else if (dspAdvertiser === undefined) {
      <div class="p-2">
        <div class="border-l-4 border-orange-500 bg-orange-100 p-4 text-orange-700">
          {{ t("dsp-stats.no_dsp_advertiser_linked_to_this_account") }}
        </div>
      </div>
    } @else {
      <div class="mb-3 flex justify-between">
        <app-filter-tags [filters]="filters" (filterChange)="applyFilter($event)" />
      </div>
      <div class="sticky -top-4 z-50">
        <app-entry-selector
          [data]="totalData"
          [previousPeriodData]="previousTotalData"
          [localStorageKey]="localstorageKey"
          (chartMetricsChanges)="selectMetrics($event)"
          [pageMetrics]="dspMetrics"
        ></app-entry-selector>
      </div>
      @if (globalChartDisplay()) {
        <ICard>
          <ng-template #header>
            <div class="flex items-center justify-end">
              <div class="ml-3 flex items-center gap-x-3">
                <app-date-aggreation-switch-button
                  class="flex"
                  [selected]="dateAggregation"
                  (dateAggSelected)="selectAggregation($event)"
                />
                <IButton
                  color="gray"
                  variant="ghost"
                  tooltipValue="{{ t('common.hide_chart') }}"
                  [icon]="ICON_CLOSE"
                  [square]="true"
                  (onClick)="toggleGlobalChartDisplay(false)"
                />
              </div>
            </div>
          </ng-template>
          <ng-template #body>
            @if (dataLoading()) {
              <div class="flex h-44 items-center justify-center">
                <app-spinner type="default" [display]="true" size="s" />
              </div>
            } @else {
              <canvas
                baseChart
                class="chart mb-3"
                [datasets]="globalDataset.chartDataSet"
                type="line"
                [labels]="globalDataset.labels"
                [options]="globalDataset.lineChartOptions"
              ></canvas>
            }
          </ng-template>
        </ICard>
      }
      <div class="mt-3">
        <ICard class="group">
          <ng-template #body>
            <div class="invisible mb-2 flex w-full items-center justify-end gap-1 group-hover:visible">
              <IButton
                [tooltipValue]="t('common.restore_default_columns')"
                [label]="t('common.restore_columns')"
                color="white"
                (onClick)="restoreCampaignDefaultColumns()"
              />
              <app-export-button [tooltipValue]="t('common.export_as_csv')" (export)="exportCampaignGridCsv()" />
              @if (!globalChartDisplay()) {
                <IButton
                  tooltipValue="{{ t('common.show_chart') }}"
                  [icon]="ICON_CHART"
                  [square]="true"
                  (onClick)="toggleGlobalChartDisplay(true)"
                  color="white"
                />
              }
            </div>
            <div class="ag-theme-quartz grid">
              <ag-grid-angular
                class="h-[40vh] w-full"
                #campaignStatsGrid
                [rowData]="campaignStatsGridData"
                [gridOptions]="campaignStatsGridOptions"
                [animateRows]="true"
              />
            </div>
          </ng-template>
        </ICard>
      </div>
      <div class="mt-3">
        <ICard class="group">
          <ng-template #body>
            <div class="invisible mb-2 flex w-full items-center justify-end gap-1 group-hover:visible">
              <IButton
                [tooltipValue]="t('common.restore_default_columns')"
                [label]="t('common.restore_columns')"
                color="white"
                (onClick)="restoreCreativeDefaultColumns()"
              />
              <app-export-button [tooltipValue]="t('common.export_as_csv')" (export)="exportCreativeGridCsv()" />
            </div>

            <div class="ag-theme-quartz grid">
              <ag-grid-angular
                class="h-[40vh] w-full"
                #creativeStatsGrid
                [rowData]="creativeStatsGridData"
                [gridOptions]="creativeStatsGridOptions"
                [animateRows]="true"
              />
            </div>
          </ng-template>
        </ICard>
      </div>
    }
  </app-stats-overlay>`,
})
@UntilDestroy()
export class DspStatsComponent implements OnInit {
  readonly dspMetrics: Metric<any>[] = DspMetrics; // Metrics to display on the selector
  readonly ICON_CLOSE = ICON_CLOSE;
  readonly ICON_CHART = ICON_CHART_LINE;
  readonly localstorageKey = MetricsSelectorLocalStorageKey.dspStats;

  accountMarketplace?: AccountMarketplace;
  dspAdvertiser: DspAdvertiser | undefined;
  dspAdvertiser$ = new Subject<DspAdvertiser | undefined>();
  locale?: string;
  currency?: Currency;
  dspCampaigns: DspCampaign[] = [];
  dspCreatives: DspCreative[] = [];
  dspAdgroups: Map<string, DspAdgroup> = new Map();
  filter$: Subject<{ dspOrderIds?: string[]; dspCreativeIds?: string[] }> = new BehaviorSubject({});

  readonly loading = signal(true);
  readonly dataLoading = signal(true);
  readonly globalChartDisplay: WritableSignal<boolean>;

  totalData: DspStats = emptyDspStats(); // Sum of stats
  previousTotalData: DspStats = emptyDspStats(); // Sum of stats for previous period
  globalDatasetData: DspStats[] = []; // Data for the chart
  globalPreviousDatasetData: DspStats[] = []; // Data for the chart for previous period
  globalDataset: DataSet<DspStats>; // Dataset for global chart
  dateAggregation = DateAggregation.daily; // Date aggregation for the chart
  selectedMetrics: Metric<any>[] = [DSP_SALES, DSP_COST]; // Metrics selected by the user
  private modalDataset = new DataSet<DspStats>(3, [DSP_SALES, DSP_COST], sumDspStats); // Dataset for campaign/creative chart

  // AG Grid - campaign stats
  @ViewChild("campaignStatsGrid") campaignStatsGrid!: AgGridAngular;
  campaignStatsGridData: DspCampaignStats[] = [];
  private previousCampaignStatsGridData = new Map<string, DspStats>();
  private campaignStats$ = new ReplaySubject<Map<string, ChartData<DspStats>>>(1);
  private previousCreativeStatsGridData = new Map<string, DspStats>();
  private creativeStats$ = new ReplaySubject<Map<string, ChartData<DspStats>>>(1);

  readonly campaignStatsGridOptions: GridOptions<DspCampaignStats> = {
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
    },
    ...getBasicGridOptions("dsp_campaign_stats", true),
    sideBar: SIDE_BAR_NO_PIVOT,
    columnDefs: [
      {
        headerValueGetter: () => this.translocoService.translate("dsp-stats.order"),
        field: "dspCampaignName",
        floatingFilter: true,
        filter: "agTextColumnFilter",
        pinned: "left",
      },
      {
        headerValueGetter: () => this.translocoService.translate("dsp-stats.state"),
        field: "state",
        pinned: "left",
        valueFormatter: (params: ValueFormatterParams<DspCampaignStats, DspCampaignStateEnum>) =>
          params.value ? this.translocoService.translate(DspCampaignState[params.value]) : "",
        floatingFilter: true,
        filter: "agSetColumnFilter",
        filterValueGetter: (params: ValueGetterParams<DspCampaignStats, any>) =>
          params.data?.state ? this.translocoService.translate(DspCampaignState[params.data.state]) : "",
      },
      {
        headerValueGetter: () => this.translocoService.translate("dsp-stats.deliveryStatus"),
        field: "deliveryStatus",
        pinned: "left",
        valueFormatter: (params: ValueFormatterParams<DspCampaignStats, DspDeliveryStatus>) =>
          params.value ? this.translocoService.translate(DspDeliveryStatusDesc[params.value]) : "",
        filterValueGetter: (params: ValueGetterParams<DspCampaignStats, any>) =>
          params.data?.deliveryStatus
            ? this.translocoService.translate(DspDeliveryStatusDesc[params.data.deliveryStatus])
            : "",
        floatingFilter: true,
        filter: "agSetColumnFilter",
      },
      ...(getMetricsColDef(this.dspMetrics) as ColDef<DspCampaignStats, any>[]).map((def) => ({
        ...def,
        cellRendererParams: (params: any) => {
          return {
            ...def.cellRendererParams(params),
            currency: this.currency,
            locale: this.locale,
            previousData: params.node.isRowPinned()
              ? undefined
              : this.previousCampaignStatsGridData.get(params.data.orderId),
          };
        },
      })),
      {
        ...actionColumnProperties<DspCampaignStats, string>(),
        cellRendererSelector: (params) => {
          const title = params.node.isRowPinned()
            ? this.translocoService.translate("dsp-stats.dsp_order_stats_graph_total")
            : ((this.translocoService.translate("dsp-stats.dsp_order_stats_graph") +
                " - " +
                params.data?.dspCampaignName) as string);
          const btns: ActionButton[] = [
            {
              icon: ICON_CHART_LINE,
              tooltip: params.node.isRowPinned()
                ? this.translocoService.translate("dsp-stats.display_dsp_order_stats_graph_total")
                : this.translocoService.translate("dsp-stats.display_dsp_order_stats_graph"),
              onClick: (params: ICellRendererParams) => {
                const orderIds = getFilteredLeafNodes(params.node, this.campaignStatsGrid.api).map(
                  (n) => n.data.orderId as string,
                );
                this.openChartModal(orderIds, title, this.campaignStats$);
              },
            },
            {
              icon: ICON_LIST,
              tooltip: this.translocoService.translate("dsp-stats.display_dsp_order_stats_details"),
              onClick: (params: ICellRendererParams) => {
                const nodes = getFilteredLeafNodes(params.node, this.campaignStatsGrid.api);
                const lineItemsData = nodes.flatMap((n) => n.data.lineItemStats as DspStats[]);
                const title =
                  nodes.length > 1
                    ? this.translocoService.translate("dsp-stats.line_item_details")
                    : this.translocoService.translate("dsp-stats.line_item_details_order", {
                        order: nodes[0].data.dspCampaignName ?? "unknown",
                      });
                this.openLineItemDetailsModal(title, lineItemsData);
              },
            },
          ];
          return {
            component: ActionButtonsComponent,
            params: {
              actionButtons: btns,
            },
          };
        },
      },
      {
        headerValueGetter: () => this.translocoService.translate("common.currency"),
        colId: "currency",
        field: "currency",
        suppressFiltersToolPanel: true,
        hide: true,
        valueGetter: () => this.currency,
      },
    ],
    getRowId: (row) => row.data.orderId ?? "",
    onModelUpdated: (params) => {
      let total = emptyDspStats();
      this.campaignStatsGrid?.api.forEachNodeAfterFilterAndSort((node) => {
        if (!node.group) total = sumDspStats(total, node.data);
      });
      params.api.setGridOption("pinnedBottomRowData", [total]);
    },
  };

  // AG Grid - campaign stats
  @ViewChild("creativeStatsGrid") creativeStatsGrid!: AgGridAngular;
  creativeStatsGridData: DspCreativeStats[] = [];
  readonly creativeStatsGridOptions: GridOptions<DspCreativeStats> = {
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
    },
    ...getBasicGridOptions("dsp_campaign_stats", true),
    sideBar: SIDE_BAR_NO_PIVOT,
    columnDefs: [
      {
        headerValueGetter: () => this.translocoService.translate("dsp-stats.creative"),
        field: "dspCreativeName",
        floatingFilter: true,
        filter: "agTextColumnFilter",
        pinned: "left",
      },
      {
        headerValueGetter: () => this.translocoService.translate("dsp-stats.creativeType"),
        field: "type",
        pinned: "left",
        floatingFilter: true,
        filter: "agSetColumnFilter",
      },
      ...(getMetricsColDef(this.dspMetrics) as ColDef<DspCreativeStats, any>[]).map((def) => ({
        ...def,
        cellRendererParams: (params: any) => ({
          ...def.cellRendererParams(params),
          currency: this.currency,
          locale: this.locale,
          previousData: params.node.isRowPinned()
            ? undefined
            : this.previousCreativeStatsGridData.get(params.data.creativeId),
        }),
      })),
      {
        ...actionColumnProperties<DspCreativeStats, string>(),
        cellRendererSelector: (params) => {
          const title = params.node.isRowPinned()
            ? this.translocoService.translate("dsp-stats.dsp_creative_stats_graph_total")
            : this.translocoService.translate("dsp-stats.dsp_creative_stats_graph") +
              " - " +
              params.data?.dspCreativeName;
          const btns: ActionButton[] = [
            {
              icon: ICON_CHART_LINE,
              tooltip: params.node.isRowPinned()
                ? this.translocoService.translate("dsp-stats.display_dsp_creative_stats_graph_total")
                : this.translocoService.translate("dsp-stats.display_dsp_creative_stats_graph"),
              onClick: (params: ICellRendererParams) => {
                const creativeIds = getFilteredLeafNodes(params.node, this.creativeStatsGrid.api).map(
                  (n) => n.data.creativeId as string,
                );
                this.openChartModal(creativeIds, title, this.creativeStats$);
              },
            },
            {
              icon: ICON_LIST,
              tooltip: this.translocoService.translate("dsp-stats.display_dsp_creative_stats_details"),
              onClick: (params: ICellRendererParams) => {
                const nodes = getFilteredLeafNodes(params.node, this.creativeStatsGrid.api);
                const lineItemsData = nodes.flatMap((n) => n.data.lineItemStats as DspStats[]);
                const title =
                  nodes.length > 1
                    ? this.translocoService.translate("dsp-stats.line_item_details")
                    : this.translocoService.translate("dsp-stats.line_item_details_creative", {
                        creative: nodes[0].data.dspCreativeName ?? "unknown",
                      });
                this.openLineItemDetailsModal(title, lineItemsData);
              },
            },
          ];
          return {
            component: ActionButtonsComponent,
            params: {
              actionButtons: btns,
            },
          };
        },
      },
      {
        headerValueGetter: () => this.translocoService.translate("common.currency"),
        colId: "currency",
        field: "currency",
        suppressFiltersToolPanel: true,
        hide: true,
        valueGetter: () => this.currency,
      },
    ],
    getRowId: (row) => row.data.creativeId ?? "",
    onModelUpdated: (params) => {
      let total = emptyDspStats();
      this.campaignStatsGrid?.api.forEachNodeAfterFilterAndSort((node) => {
        if (!node.group) total = sumDspStats(total, node.data);
      });
      params.api.setGridOption("pinnedBottomRowData", [total]);
    },
  };

  readonly filters: FilterTag<DspCampaign | DspCreative>[] = [
    {
      type: "DspCampaign",
      label: "DSP Orders",
      tooltip: "Filter stats on DSP orders",
      noValuePlaceholder: "No DSP order available",
      options: () => this.dspCampaigns?.map((c) => ({ label: c.name ?? "", value: c })) ?? [],
      defaultValues: () => [],
      color: PALETTE[0],
      unique: true,
      excludeOption: false,
    },
    {
      type: "DspCreative",
      label: "DSP Creatives",
      tooltip: "Filter stats on DSP cretatives",
      noValuePlaceholder: "No DSP creative available",
      options: () => this.dspCreatives?.map((c) => ({ label: c.name ?? "", value: c })) ?? [],
      defaultValues: () => [],
      color: PALETTE[1],
      unique: true,
      excludeOption: false,
    },
  ];

  constructor(
    private accountSelectionService: AccountSelectionService,
    private accountMarketplaceService: AccountMarketplaceService,
    private statsService: StatsApiClientService,
    private toastrService: ToastrService,
    private translocoService: TranslocoService,
    private userSelectionService: UserSelectionService,
    private authService: AuthService,
    private dspService: DspService,
    private modalService: BsModalService,
  ) {
    this.globalChartDisplay = signal(this.userSelectionService.getUserChartDisplayedPreference(this.localstorageKey));
    this.globalDataset = new DataSet<DspStats>(3, [DSP_SALES, DSP_COST], sumDspStats);
    this.globalDataset.metricsOnSameScale = [[DSP_SALES, DSP_COST]];
    this.modalDataset.metricsOnSameScale = [[DSP_SALES, DSP_COST]];
    this.translocoService.langChanges$.pipe(untilDestroyed(this)).subscribe(() => {
      // change filter tags label
      this.filters[0].label = this.translocoService.translate("dsp-stats.filter_dsp_orders_label");
      this.filters[0].tooltip = this.translocoService.translate("dsp-stats.filter_dsp_orders_tooltip");
      this.filters[0].noValuePlaceholder = this.translocoService.translate(
        "dsp-stats.filter_dsp_orders_noValuePlaceholder",
      );
      this.filters[1].label = this.translocoService.translate("dsp-stats.filter_dsp_creatives_label");
      this.filters[1].tooltip = this.translocoService.translate("dsp-stats.filter_dsp_creatives_tooltip");
      this.filters[1].noValuePlaceholder = this.translocoService.translate(
        "dsp-stats.filter_dsp_creatives_noValuePlaceholder",
      );
    });

    combineLatest([this.authService.loggedUser$, this.userSelectionService.selectedCurrency$])
      .pipe(untilDestroyed(this))
      .subscribe({
        next: ([user, currency]: [any, Currency]) => {
          this.locale = user.locale;
          this.currency = currency;

          this.globalDataset.locale = user.locale;
          this.globalDataset.currency = currency;
          this.modalDataset.locale = user.locale;
          this.modalDataset.currency = currency;
        },
      });
  }

  ngOnInit(): void {
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        tap((accountMarketplace) => {
          this.loading.set(true);
          this.accountMarketplace = accountMarketplace;
        }),
        switchMap((accountMarketplace) => {
          if (accountMarketplace.dspAdvertiserId) {
            return this.accountMarketplaceService.getDspAdvertiser(accountMarketplace.dspAdvertiserId).pipe(
              catchError((error) => {
                this.toastrService.error(this.translocoService.translate("dsp-stats.error_loading_dsp_advertiser"));
                return of(undefined);
              }),
            );
          }
          return of(undefined);
        }),
      )
      .subscribe((dspAdvertiser) => {
        this.dspAdvertiser$.next(dspAdvertiser);
        this.loading.set(false);
      });
    this.dspAdvertiser$.pipe(untilDestroyed(this)).subscribe((dspAdvertiser) => {
      this.dspAdvertiser = dspAdvertiser;
    });
    const dspCampaigns$ = this.dspAdvertiser$.pipe(
      switchMap((dspAdvertiser) => {
        if (dspAdvertiser) {
          return this.dspService.getDspCampaigns(dspAdvertiser.dspAdvertiserId);
        }
        return of([]);
      }),
      shareReplay(1),
    );
    const dspCreatives$ = this.dspAdvertiser$.pipe(
      switchMap((dspAdvertiser) => {
        if (dspAdvertiser) {
          return this.dspService.getDspCreatives(dspAdvertiser.dspAdvertiserId);
        }
        return of([]);
      }),
      shareReplay(1),
    );
    dspCampaigns$.pipe(untilDestroyed(this)).subscribe((campaigns) => {
      this.dspCampaigns = campaigns;
    });
    dspCreatives$.pipe(untilDestroyed(this)).subscribe((creatives) => {
      this.dspCreatives = creatives;
    });
    this.dspAdvertiser$
      .pipe(
        untilDestroyed(this),
        switchMap((dspAdvertiser) => {
          if (dspAdvertiser) {
            return this.dspService.getDspAdgroups(dspAdvertiser.dspAdvertiserId);
          }
          return of([]);
        }),
      )
      .subscribe({
        next: (adgroups) => {
          this.dspAdgroups.clear();
          for (const adgroup of adgroups) {
            if (!adgroup.adGroupId) {
              continue;
            }
            this.dspAdgroups.set(adgroup.adGroupId, adgroup);
          }
        },
        error: (error) => {
          this.toastrService.error(this.translocoService.translate("dsp-stats.error_loading_dsp_adgroups"));
        },
      });

    combineLatest([this.accountSelectionService.singleAccountMarketplaceSelection$, this.dspAdvertiser$])
      .pipe(
        tap(() => {
          this.dataLoading.set(true);
        }),
        untilDestroyed(this),
        switchMap(([accountMarketplace, dspAdvertiser]) => {
          if (dspAdvertiser) {
            return combineLatest([
              this.statsService.getDspLineItemsStats(
                dspAdvertiser.dspAdvertiserId,
                accountMarketplace.accountId,
                accountMarketplace.marketplace,
              ),
              this.statsService.getDspLineItemsStatsPreviousPeriod(
                dspAdvertiser.dspAdvertiserId,
                accountMarketplace.accountId,
                accountMarketplace.marketplace,
              ),
              dspCampaigns$,
              dspCreatives$,
              this.filter$,
            ]);
          }
          return of<
            [
              DspStats[],
              DspStats[],
              DspCampaign[],
              DspCreative[],
              { dspOrderIds?: string[]; dspCreativeIds?: string[] },
            ]
          >([[], [], [], [], {}]);
        }),
      )
      .subscribe(([stats, previousStats, dspCampaigns, dspCreatives, filter]) => {
        this.dataLoading.set(false);
        this.totalData = emptyDspStats();
        this.previousTotalData = emptyDspStats();
        this.globalDatasetData = [];
        this.globalPreviousDatasetData = [];
        // build campaign and creative stats
        const statsPerCampaign = new Map<string, DspCampaignStats>();
        const campaignStats = new Map<string, ChartData<DspStats>>();
        const creativeStats = new Map<string, ChartData<DspStats>>();
        const statsPerCreative = new Map<string, DspCreativeStats>();
        for (const stat of stats) {
          if (filter.dspOrderIds && stat.orderId && !filter.dspOrderIds.includes(stat.orderId)) {
            continue;
          }
          if (filter.dspCreativeIds && stat.creativeId && !filter.dspCreativeIds.includes(stat.creativeId)) {
            continue;
          }
          this.totalData = sumDspStats(this.totalData, stat);
          this.globalDatasetData.push(stat);

          if (stat.orderId) {
            const existing = statsPerCampaign.get(stat.orderId);
            if (existing) {
              statsPerCampaign.set(stat.orderId, { ...existing, ...sumDspStats(existing, stat) });
              statsPerCampaign.get(stat.orderId)!.lineItemStats.push(stat);
              const chartData = campaignStats.get(stat.orderId)!;
              chartData.data.push(stat);
              chartData.totalData = statsPerCampaign.get(stat.orderId)!;
            } else {
              const dspCampaign = dspCampaigns.find((c) => c.campaignId == stat.orderId);
              if (!dspCampaign) {
                statsPerCampaign.set(stat.orderId, { ...stat, lineItemStats: [{ ...stat }] });
              } else {
                statsPerCampaign.set(stat.orderId, {
                  ...stat,
                  dspCampaignName: dspCampaign.name,
                  state: dspCampaign.state,
                  deliveryStatus: dspCampaign.deliveryStatus,
                  lineItemStats: [{ ...stat }],
                });
              }
              campaignStats.set(stat.orderId, {
                data: [stat],
                totalData: { ...stat },
              });
            }
          }
          if (stat.creativeId) {
            const existing = statsPerCreative.get(stat.creativeId);
            if (existing) {
              statsPerCreative.set(stat.creativeId, { ...existing, ...sumDspStats(existing, stat) });
              statsPerCreative.get(stat.creativeId)!.lineItemStats.push(stat);
              const chartData = creativeStats.get(stat.creativeId)!;
              chartData.data.push(stat);
              chartData.totalData = statsPerCreative.get(stat.creativeId)!;
            } else {
              const dspCreative = dspCreatives.find((c) => c.adCreativeId == stat.creativeId);
              if (!dspCreative) {
                statsPerCreative.set(stat.creativeId, { ...stat, lineItemStats: [{ ...stat }] });
              } else {
                statsPerCreative.set(stat.creativeId, {
                  ...stat,
                  dspCreativeName: dspCreative.name,
                  type: dspCreative.adCreativeFormatProperties?.adCreativeFormatType,
                  lineItemStats: [{ ...stat }],
                });
              }
              creativeStats.set(stat.creativeId, {
                data: [stat],
                totalData: { ...stat },
              });
            }
          }
        }
        this.previousCampaignStatsGridData = new Map<string, DspStats>();
        this.previousCreativeStatsGridData = new Map<string, DspStats>();
        for (const stat of previousStats) {
          if (filter.dspOrderIds && stat.orderId && !filter.dspOrderIds.includes(stat.orderId)) {
            continue;
          }
          if (filter.dspCreativeIds && stat.creativeId && !filter.dspCreativeIds.includes(stat.creativeId)) {
            continue;
          }
          this.previousTotalData = sumDspStats(this.previousTotalData, stat);
          this.globalPreviousDatasetData.push(stat);

          if (stat.orderId) {
            const existing = this.previousCampaignStatsGridData.get(stat.orderId);
            if (existing) {
              this.previousCampaignStatsGridData.set(stat.orderId, sumDspStats(existing, stat));
            } else {
              this.previousCampaignStatsGridData.set(stat.orderId, { ...stat });
            }
            const chartData = campaignStats.get(stat.orderId)!;
            if (chartData && chartData.previousData) {
              chartData.previousData.push(stat);
              chartData.totalPreviousData = this.previousCampaignStatsGridData.get(stat.orderId)!;
            } else if (chartData) {
              chartData.previousData = [stat];
              chartData.totalPreviousData = { ...stat };
            }
          }
          if (stat.creativeId) {
            const existing = this.previousCreativeStatsGridData.get(stat.creativeId);
            if (existing) {
              this.previousCreativeStatsGridData.set(stat.creativeId, sumDspStats(existing, stat));
            } else {
              this.previousCreativeStatsGridData.set(stat.creativeId, { ...stat });
            }
            const chartData = creativeStats.get(stat.creativeId)!;
            if (chartData && chartData.previousData) {
              chartData.previousData.push(stat);
              chartData.totalPreviousData = this.previousCreativeStatsGridData.get(stat.creativeId)!;
            } else if (chartData) {
              chartData.previousData = [stat];
              chartData.totalPreviousData = { ...stat };
            }
          }
        }

        this.buildDataset();
        this.campaignStatsGridData = Array.from(statsPerCampaign.values());
        this.creativeStatsGridData = Array.from(statsPerCreative.values());
        this.campaignStats$.next(campaignStats);
        this.creativeStats$.next(creativeStats);
        this.campaignStatsGrid.api?.refreshCells({ force: true });
        this.creativeStatsGrid.api?.refreshCells({ force: true });
      });
  }

  buildDataset() {
    const dateRange: string[] = this.userSelectionService.getDateRangeStr();
    const previousDateRange = this.userSelectionService.getPreviousDateRangeStr();
    this.globalDataset.buildDataSet(
      this.globalDatasetData,
      this.selectedMetrics,
      this.dateAggregation,
      {
        minDate: dateRange[0],
        maxDate: dateRange[1],
      },
      previousDateRange
        ? {
            data: this.globalPreviousDatasetData,
            period: previousDateRange,
          }
        : undefined,
    );
  }

  selectMetrics(metrics: Metric<any>[]) {
    this.selectedMetrics = metrics;
    this.buildDataset();
  }

  selectAggregation(dateAggregation: DateAggregation) {
    this.dateAggregation = dateAggregation;
    this.buildDataset();
  }

  toggleGlobalChartDisplay(display: boolean) {
    this.globalChartDisplay.set(display);
    this.userSelectionService.setUserChartDisplayedPreference(this.localstorageKey, display);
  }

  restoreCampaignDefaultColumns() {
    this.campaignStatsGrid.api?.resetColumnState();
    this.campaignStatsGrid.api?.autoSizeAllColumns();
  }

  restoreCreativeDefaultColumns() {
    this.creativeStatsGrid.api?.resetColumnState();
    this.creativeStatsGrid.api?.autoSizeAllColumns();
  }

  exportCampaignGridCsv(): void {
    if (!this.accountMarketplace) {
      return;
    }
    const fileName = getCsvFileName(
      "dsp_campaign_stats_",
      this.accountMarketplace.accountGroupName,
      this.accountMarketplace.marketplace,
      this.userSelectionService.getDateRangeStr(),
    );
    const columnKeys: string[] = this.campaignStatsGrid?.api
      .getAllDisplayedColumns()
      .map((c) => c.getColId())
      .concat(["currency"]);
    // add translations
    this.setCsvColumnHeader(this.campaignStatsGrid, "dspCampaignName", "dsp-stats.order");
    this.setCsvColumnHeader(this.campaignStatsGrid, "state", "dsp-stats.state");
    this.setCsvColumnHeader(this.campaignStatsGrid, "deliveryStatus", "dsp-stats.deliveryStatus");
    this.setCsvColumnHeader(this.campaignStatsGrid, "currency", "common.currency");

    exportGridCsv(this.campaignStatsGrid?.api, { fileName, columnKeys, skipColumnGroupHeaders: true });
  }

  exportCreativeGridCsv(): void {
    if (!this.accountMarketplace) {
      return;
    }
    const fileName = getCsvFileName(
      "dsp_creative_stats_",
      this.accountMarketplace.accountGroupName,
      this.accountMarketplace.marketplace,
      this.userSelectionService.getDateRangeStr(),
    );
    const columnKeys: string[] = this.creativeStatsGrid?.api
      .getAllDisplayedColumns()
      .map((c) => c.getColId())
      .concat(["currency"]);
    // add translations
    this.setCsvColumnHeader(this.creativeStatsGrid, "dspCreativeName", "dsp-stats.creative");
    this.setCsvColumnHeader(this.creativeStatsGrid, "type", "dsp-stats.creativeType");
    this.setCsvColumnHeader(this.creativeStatsGrid, "currency", "common.currency");

    exportGridCsv(this.creativeStatsGrid?.api, { fileName, columnKeys, skipColumnGroupHeaders: true });
  }

  applyFilter(filters: FilterTagVal<DspCampaign | DspCreative>[]) {
    if (filters.length === 0) {
      this.filter$.next({});
      return;
    }
    const filter: { dspOrderIds?: string[]; dspCreativeIds?: string[] } = {};
    for (const filterVal of filters) {
      if (filterVal.values.length === 0) {
        continue;
      }
      if (filterVal.filter.type === "DspCampaign") {
        filter.dspOrderIds = filterVal.values
          .map((v) => (v.value as DspCampaign).campaignId)
          .filter((v): v is string => !!v);
      }
      if (filterVal.filter.type === "DspCreative") {
        filter.dspCreativeIds = filterVal.values
          .map((v) => (v.value as DspCreative).adCreativeId)
          .filter((v): v is string => !!v);
      }
    }
    this.filter$.next(filter);
  }

  private setCsvColumnHeader(grid: AgGridAngular, colId: string, translationKey: string): void {
    const columnDef = this.campaignStatsGrid.api?.getColumnDef(colId);
    if (columnDef) {
      columnDef.headerName = this.translocoService.translate(translationKey);
    }
  }

  private openChartModal(ids: string[], title: string, chartData$: Observable<Map<string, ChartData<DspStats>>>) {
    this.modalService.show(ChartRendererComponent<DspStats>, {
      initialState: {
        title,
        dataset: this.modalDataset,
        metrics: this.dspMetrics,
        chartData$: chartData$.pipe(
          map((d) => {
            let totalData = emptyDspStats();
            let totalPreviousData = emptyDspStats();
            const data: DspStats[] = [];
            const previousData: DspStats[] = [];
            for (const id of ids) {
              const node = d.get(id);
              if (node) {
                totalData = sumDspStats(totalData, node.totalData);
                if (node.totalPreviousData) {
                  totalPreviousData = sumDspStats(totalPreviousData, node.totalPreviousData);
                }
                data.push(...node.data);
                if (node.previousData) {
                  previousData.push(...node.previousData);
                }
              }
            }
            return { data, totalData, totalPreviousData, previousData };
          }),
        ),
        localStorageKey: MetricsSelectorLocalStorageKey.dspStats,
      },
      class: "modal-xxl modal-dialog-centered",
    });
  }

  private openLineItemDetailsModal(title: string, lineItemsData: DspStats[]) {
    this.modalService.show(DspStatsDetailsModalComponent, {
      initialState: {
        title,
        lineItemsData,
        lineItems: this.dspAdgroups,
        locale: this.locale,
        currency: this.currency,
      },
      class: "modal-xxl modal-dialog-centered",
    });
  }
}

type DspCampaignStats = DspStats & {
  dspCampaignName?: string;
  state?: DspCampaignStateEnum;
  deliveryStatus?: DspDeliveryStatus;
  lineItemStats: DspStats[];
};

type DspCreativeStats = DspStats & {
  dspCreativeName?: string;
  type?: DspCreativeAdCreativeFormatPropertiesAdCreativeFormatTypeEnum;
  lineItemStats: DspStats[];
};

const DspCampaignState: { [key in DspCampaignStateEnum]: string } = {
  [DspCampaignStateEnum.ACTIVE]: "dsp-stats.campaignstate_active",
  [DspCampaignStateEnum.INACTIVE]: "dsp-stats.campaignstate_inactive",
};

export const DspDeliveryStatusDesc: { [key in DspDeliveryStatus]: string } = {
  [DspDeliveryStatus.DELIVERING]: "dsp-stats.dspCampaignDeliveryStatus_DELIVERING",
  [DspDeliveryStatus.ENDED]: "dsp-stats.dspCampaignDeliveryStatus_ENDED",
  [DspDeliveryStatus.ADGROUPS_NOT_RUNNING]: "dsp-stats.dspCampaignDeliveryStatus_ADGROUPS_NOT_RUNNING",
  [DspDeliveryStatus.CREATIVES_NOT_RUNNING]: "dsp-stats.dspCampaignDeliveryStatus_CREATIVES_NOT_RUNNING",
  [DspDeliveryStatus.READY_TO_DELIVER]: "dsp-stats.dspCampaignDeliveryStatus_READY_TO_DELIVER",
  [DspDeliveryStatus.SUSPENDED]: "dsp-stats.dspCampaignDeliveryStatus_SUSPENDED",
  [DspDeliveryStatus.INACTIVE]: "dsp-stats.dspCampaignDeliveryStatus_INACTIVE",
  [DspDeliveryStatus.OTHER]: "dsp-stats.dspCampaignDeliveryStatus_OTHER",
};

export const DspMetrics = [
  DSP_SALES,
  DSP_UNITS_SOLD,
  DSP_COST,
  DSP_ACOS,
  DSP_IMPRESSIONS,
  DSP_CLICKS,
  DSP_FEE,
  DSP_PURCHASE_CLICKS,
  DSP_PURCHASES,
  DSP_ADD_TO_CART,
  DSP_PAGE_VIEWS,
  DSP_NEW_TO_BRAND_PRODUCT_SALES,
  DSP_3P_FEES,
  DSP_SUPPLY_COST,
  DSP_ROAS,
];

export function emptyDspStats(): DspStats {
  return {
    impressions: 0,
    clicks: 0,
    totalCost: 0,
    supplyCost: 0,
    totalFee: 0,
    unitsSold: 0,
    sales: 0,
    purchaseClicks: 0,
    purchases: 0,
    _3PFees: 0,
    addToCart: 0,
    pageViews: 0,
    newToBrandProductSales: 0,
  };
}

function sum(a?: number, b?: number): number {
  return a ? (b ? a + b : a) : (b as number);
}

export function sumDspStats(stats1: DspStats, stats2: DspStats): DspStats {
  const res = emptyDspStats();
  res.currency = stats1.currency;
  res.impressions = sum(stats1.impressions, stats2.impressions);
  res.clicks = sum(stats1.clicks, stats2.clicks);
  res.totalCost = sum(stats1.totalCost, stats2.totalCost);
  res.unitsSold = sum(stats1.unitsSold, stats2.unitsSold);
  res.sales = sum(stats1.sales, stats2.sales);
  res.totalFee = sum(stats1.totalFee, stats2.totalFee);
  res.purchaseClicks = sum(stats1.purchaseClicks, stats2.purchaseClicks);
  res.purchases = sum(stats1.purchases, stats2.purchases);
  res.addToCart = sum(stats1.addToCart, stats2.addToCart);
  res.pageViews = sum(stats1.pageViews, stats2.pageViews);
  res.newToBrandProductSales = sum(stats1.newToBrandProductSales, stats2.newToBrandProductSales);
  res._3PFees = sum(stats1._3PFees, stats2._3PFees);
  res.supplyCost = sum(stats1.supplyCost, stats2.supplyCost);

  return res;
}
