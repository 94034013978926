import { Clipboard } from "@angular/cdk/clipboard";
import { Component, computed, EventEmitter, HostBinding, inject, Input, OnInit, Output, signal } from "@angular/core";
import { faList, faThLarge } from "@fortawesome/free-solid-svg-icons";
import {
  AccountSelectionService,
  AsinService,
  Catalog,
  InventoryConfig,
  InventoryRules,
  InventoryService,
  InventoryStats,
  Marketplace,
  ProductEx,
  StrategyAsin,
  StrategyEx,
} from "@front/m19-services";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { toSignal } from "@angular/core/rxjs-interop";
import { FormControl } from "@angular/forms";
import { PageEvent } from "@angular/material/paginator";
import { Option } from "@front/m19-ui";
import { CsvExportService, CsvField, fieldExtractor, simpleField } from "@m19-board/services/csv-export.service";
import { SwitchButtonType } from "@m19-board/shared/switch-button/switch-button.component";
import { ICON_SEARCH } from "@m19-board/utils/iconsLabels";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, combineLatest, switchMap } from "rxjs";
import { ViewTypeEnum } from "../strategy-list/strategy-list.component";

interface ParentAsin extends StrategyAsin {
  childAsin: StrategyAsin[];
}

@UntilDestroy()
@Component({
  selector: "app-strategy-asins",
  templateUrl: "./strategy-asins.component.html",
  styleUrls: ["./strategy-asins.component.scss"],
})
export class StrategyAsinsComponent implements OnInit {
  public SwitchButtonType = SwitchButtonType;
  public ViewTypeEnum = ViewTypeEnum;
  readonly faTable = faThLarge;
  readonly faListAlt = faList;
  readonly ASIN_VIEW_TYPE_KEY = "strategy-asins-view-type";
  readonly ICON_SEARCH = ICON_SEARCH;

  @HostBinding("class") class = "inline-block w-full";
  @HostBinding("style") style = "max-height: inherit";

  asinService = inject(AsinService);

  @Input()
  set asins(asins: StrategyAsin[]) {
    this.$asins.set([...asins]);
    this.asins$.next(asins);
  }

  $asins = signal<StrategyAsin[]>([]);

  @Input() withDisplayMode = false;
  displayMode: ViewTypeEnum = ViewTypeEnum.LIST;

  @Input() addProductsBtn = false;

  @Input() onlyAddParent = false;

  @Input() isReadOnly = false;

  @Input()
  csvExport = true;

  @Input()
  csvExportFileName: string;

  @Input()
  accountId: string;

  @Input()
  marketplace: Marketplace;

  @Input()
  selectable = false;

  @Input()
  itemsShown = 40;

  @Input()
  deletable = true;

  @Input()
  movable = true;

  @Input() strategiesPerAsin: Map<string, StrategyEx[]>;

  @Input() displaySeedsCounter = false;

  private _alreadySelectedAsins: StrategyAsin[] = [];
  get alreadySelectedAsins() {
    return this._alreadySelectedAsins;
  }

  @Input() set alreadySelectedAsins(value: StrategyAsin[]) {
    this._alreadySelectedAsins = value ?? [];
    const alreadySelectedSet = new Set(this._alreadySelectedAsins.map((a) => a.asin));
    this.selected = this.selected.filter((a) => !alreadySelectedSet.has(a.asin));
    this.productSelection = {};
    for (const asin of this.selected) {
      this.productSelection[asin.asin] = true;
    }
  }

  @Input() withCustomFieldFilter = false;

  @Input() allowGroupByParentAsin = false;

  @Input() asinEligibility: Map<string, { status: boolean; reason: string }> = new Map();

  @Output()
  onDelete = new EventEmitter<StrategyAsin[]>();

  @Output()
  move = new EventEmitter<StrategyAsin[]>();

  @Output()
  selectedProducts = new EventEmitter<StrategyAsin[]>();
  @Output() addProducts = new EventEmitter<void>();

  asinOffers: { [key: string]: ProductEx };
  asins$ = new BehaviorSubject<StrategyAsin[]>([]);
  loading = true;
  selected: StrategyAsin[] = [];
  pausedAsins: { [asin: string]: boolean } = {}; // ASIN paused because of low inventory rules
  private filter = new RegExp("", "i");

  queryControl = new FormControl("");
  $groupByParentAsin = signal(false);
  $query = toSignal(this.queryControl.valueChanges);
  $pageEvent = signal<PageEvent>({
    pageIndex: 0,
    pageSize: this.itemsShown,
    length: 0,
  }); // Page change event

  customField1Name: string;
  customField2Name: string;

  $customFieldFilter = signal<{ value: string; field: string }[]>([]);

  // Filter asins based on search bar input
  $filteredAsins = computed(() => {
    this.filter = new RegExp(this.$query(), "i");
    if (!this.asinOffers) return this.$asins();
    return (this.$groupByParentAsin() ? this.$asinsGroupByParentAsin() : this.$asins()).filter(
      (asin) =>
        (asin.asin.search(this.filter) !== -1 ||
          (this.asinOffers[asin.asin]?.title && this.asinOffers[asin.asin]?.title.search(this.filter) != -1)) &&
        (this.$customFieldFilter().length === 0 ||
          this.$customFieldFilter().find(
            (f) => f.value === this.asinOffers[asin.asin]?.customField1 && f.field === this.customField1Name,
          ) ||
          this.$customFieldFilter().find(
            (f) => f.value === this.asinOffers[asin.asin]?.customField2 && f.field === this.customField2Name,
          )),
    );
  });

  $shownAsins = computed(() => {
    if (!this.$pageEvent()) return this.$filteredAsins().slice(0, this.itemsShown);
    const start = this.$pageEvent().pageIndex * this.$pageEvent().pageSize;
    const end = start + this.$pageEvent().pageSize;
    return this.$filteredAsins().slice(start, end);
  });

  // Group asins by parent asins
  $asinsGroupByParentAsin = computed(() => this.aggregateDataByParentAsin(this.$asins()));

  productSelection: { [key: string]: boolean } = {};

  private catalog: Catalog;

  inventoryConfig: InventoryConfig;
  customFieldsOptions: Option[] = [];
  selectedCustomFields = signal<Option[]>([]);

  constructor(
    private toasterService: ToastrService,
    private clipboard: Clipboard,
    private inventoryService: InventoryService,
    private csvExportService: CsvExportService,
    private accountSelectionService: AccountSelectionService,
  ) {}

  ngOnInit() {
    this.asinOffers = {};
    if (this.withDisplayMode) this.initDisplayBy();

    this.unselectAllproducts();

    if (!this.selectable) this.deletable = false;

    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am) =>
          combineLatest<[StrategyAsin[], Catalog]>([
            this.asins$,
            this.asinService.getCatalog(am.accountId, am.marketplace),
          ]),
        ),
      )
      .subscribe(([asins, catalog]) => {
        if (!asins) return;
        this.asinOffers = {};
        if (catalog) {
          this.catalog = catalog;
          for (const asin of asins) {
            this.asinOffers[asin.asin] = this.catalog.asinOffers.get(asin.asin);
          }
        }
        this.unselectAllproducts();
        this.loading = false;
      });

    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am) =>
          combineLatest<[InventoryConfig, Catalog]>([
            this.asinService.getInventoryConfig(am.accountId, am.marketplace),
            this.asinService.getCatalog(am.accountId, am.marketplace),
          ]),
        ),
      )
      .subscribe(([inventoryConfig, catalog]) => {
        this.inventoryConfig = inventoryConfig;

        this.customField1Name = this.inventoryConfig.customField1Name ?? "Custom Field 1";
        this.customField2Name = this.inventoryConfig.customField2Name ?? "Custom Field 2";

        const customFields: { value: string; field: string }[] = [];

        catalog.products.forEach((p) => {
          if (
            p.customField1 &&
            customFields.findIndex((f) => f.value === p.customField1 && f.field === this.customField1Name) === -1
          )
            customFields.push({ value: p.customField1, field: this.customField1Name });
          if (
            p.customField2 &&
            customFields.findIndex((f) => f.value === p.customField2 && f.field === this.customField2Name) === -1
          )
            customFields.push({ value: p.customField2, field: this.customField2Name });
        });
        this.customFieldsOptions = Array.from(customFields).map((v) => ({
          value: v,
          label: v.value,
        }));
      });

    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am) =>
          combineLatest<[StrategyAsin[], InventoryStats[], InventoryRules]>([
            this.asins$,
            this.inventoryService.getInventoryStats(am.accountId, am.marketplace),
            this.asinService.getInventoryRules(am.accountId, am.marketplace),
          ]),
        ),
      )
      .pipe(untilDestroyed(this))
      .subscribe(([asins, inventoryStats, inventoryRules]) => {
        const asinsSet = new Set(asins?.map((s) => s.asin));
        this.pausedAsins = {};
        if (inventoryRules) {
          for (const stat of inventoryStats) {
            if (asinsSet.has(stat.asin) && inventoryRules.execute(stat.asin, stat).shouldPauseAdvertising) {
              this.pausedAsins[stat.asin] = true;
            }
          }
        }
      });
  }

  setFilter(query: string): void {
    this.$pageEvent.set(undefined); // Reset page event to filter on all products
  }

  selectProduct(asin: string, value: boolean) {
    const strategyAsin: StrategyAsin = { asin: asin };
    if (this.selectable && !this.isAsinSelected(strategyAsin)) {
      this.productSelection[strategyAsin.asin] = value;
      this.selected = this.$groupByParentAsin()
        ? this.$asinsGroupByParentAsin()
            .filter((asin) => this.productSelection[asin.asin])
            .flatMap((a) => (a.childAsin && a.childAsin.length > 0 && !this.onlyAddParent ? a.childAsin : [a]))
        : this.asins$.value.filter((asin) => this.productSelection[asin.asin]);
      this.selectedProducts.emit(this.selected);
    }
  }

  selectAllProducts() {
    for (const asin of this.$filteredAsins()) {
      if (!this.isDisabled({ ...asin, childAsin: [] })) {
        this.productSelection[asin.asin] = true;
      }
    }
    this.selected =
      this.$groupByParentAsin() && !this.onlyAddParent
        ? this.$asinsGroupByParentAsin()
            .filter((asin) => this.productSelection[asin.asin])
            .flatMap((a) => (a.childAsin && a.childAsin.length > 0 && !this.onlyAddParent ? a.childAsin : [a]))
        : this.asins$.value.filter((asin) => this.productSelection[asin.asin]);

    this.selectedProducts.emit(this.selected);
  }

  unselectAllproducts() {
    this.selected = [];
    this.productSelection = {};
    this.selectedProducts.emit([]);
  }

  deleteAsins() {
    this.onDelete.emit(this.selected);
  }

  moveAsins(): void {
    this.move.emit(this.selected);
  }

  exportCsv() {
    const fields: CsvField<StrategyAsin>[] = [
      simpleField("asin"),
      fieldExtractor("Title", (data) => this.asinOffers[data.asin]?.title),
    ];
    if (this.asinEligibility.size > 0) {
      fields.push(fieldExtractor("IsEligible", (data) => (this.isAsinIneligible(data) ? "No" : "Yes")));
      fields.push(
        fieldExtractor("IneligibilityReason", (data) => (this.isAsinIneligible(data) ? this.reason(data) : "")),
      );
    }

    this.csvExportService.exportCsv({ prefix: this.csvExportFileName + "_asins" }, this.asins$.value, fields);
  }

  alreadySelected(asin: ParentAsin): boolean {
    if (this.$groupByParentAsin() && asin.childAsin.length > 0) {
      return asin.childAsin.every((a) => this.alreadySelectedAsins.findIndex((s) => s.asin == a.asin) > -1);
    }
    for (let i = 0; i < this.alreadySelectedAsins.length; i++) {
      if (asin.asin == this.alreadySelectedAsins[i].asin) {
        return true;
      }
    }
    return false;
  }

  isAsinSelected(asin: StrategyAsin) {
    return this.alreadySelectedAsins.find((a) => a.asin === asin.asin) !== undefined;
  }

  isAsinIneligible(asin: StrategyAsin) {
    return !(this.asinEligibility.get(asin.asin)?.status ?? false);
  }

  reason(asin: StrategyAsin) {
    if (!this.asinEligibility.has(asin.asin)) return "No longer in catalog";
    return this.asinEligibility.get(asin.asin)?.reason ?? "Ineligible";
  }

  isDisabled(asin: ParentAsin) {
    return (
      // (this.asinEligibility.size > 0 && this.isAsinIneligible(asin)) ||
      // this.pausedAsins[asin.asin]
      this.alreadySelected(asin)
    );
  }

  toggleGroupByParentAsin() {
    this.$groupByParentAsin.set(!this.$groupByParentAsin());
    this.unselectAllproducts();
    this.$customFieldFilter.set([]);
  }

  private aggregateDataByParentAsin(asins: StrategyAsin[]) {
    if (!this.catalog) {
      return [];
    }
    const result: Map<string, ParentAsin> = new Map();
    for (const asin of asins) {
      const parentAsin = this.catalog.getParentAsin(asin.asin);
      if (!parentAsin) {
        result.set(asin.asin, { asin: asin.asin, childAsin: [] });
        continue;
      }
      // aggregate asins
      const existinsgParentAsin = result.get(parentAsin);
      if (!existinsgParentAsin) {
        const toInsert = {
          asin: parentAsin,
          childAsin: [],
        };
        toInsert.childAsin.push(asin);
        result.set(parentAsin, toInsert);
        continue;
      }
      existinsgParentAsin.childAsin.push(asin);
    }
    return Array.from(result.values());
  }

  copyToClipboard(str: string) {
    this.clipboard.copy(str);
    this.toasterService.success("Copied to clipboard");
  }

  displayBy(viewType: ViewTypeEnum): void {
    this.displayMode = viewType;
    localStorage.setItem(this.ASIN_VIEW_TYPE_KEY, viewType.toString());
  }

  initDisplayBy(): void {
    const viewType: string = localStorage.getItem(this.ASIN_VIEW_TYPE_KEY);
    if (viewType) {
      this.displayMode = ViewTypeEnum[viewType];
    }
  }

  customFieldFilter(values: Option[]) {
    this.$customFieldFilter.set(values.map((v) => v.value));
    this.selectedCustomFields.set(values);
  }
}
