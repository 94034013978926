import { Component, Input } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CampaignType, Strategy, StrategyEx } from "@front/m19-services";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";

interface IFbl {
  name: string;
  src: string;
  title: string;
}

export enum FblModeSize {
  small = "sm",
  large = "lg",
}

@Component({
  selector: "app-fbl-mode",
  standalone: true,
  imports: [MatTooltipModule],
  templateUrl: "./fbl-mode.component.html",
  styleUrls: ["./fbl-mode.component.scss"],
})
export class FblModeComponent implements ICellRendererAngularComp {
  static readonly fblMode: Record<number, IFbl> = {
    1: { name: "ACOS", src: "assets/img/fbl_icons/target.svg", title: "ACOS target management" },
    2: { name: "BUDGET", src: "assets/img/fbl_icons/budget.svg", title: "Budget management" },
    3: { name: "Min daily spend", src: "assets/img/fbl_icons/rocket.svg", title: "Min daily spend management" },
    4: {
      name: "Not enough stats",
      src: "assets/img/fbl_icons/no_data.svg",
      title: "The algorithm has not enough stats to optimize your strategy",
    },
    8: {
      name: "Delivery issue",
      src: "assets/img/fbl_icons/delivery_warning.svg",
      title: "Strategy ASINs have warning flag and may have delivery issue",
    },
  };

  strategy_: StrategyEx;
  constraint: any;

  @Input() set strategy(strategy: Strategy) {
    this.strategy_ = strategy as StrategyEx;
    this.constraint = FblModeComponent.fblMode[strategy.constraint];
    if (this.constraint === FblModeComponent.fblMode[4]) {
      if (
        (strategy.campaignType === CampaignType.SP || strategy.campaignType === CampaignType.SD) &&
        strategy.asins.length === 0
      )
        this.constraint = undefined;
      if (strategy.campaignType === CampaignType.SB && (this.strategy_.sbCreatives?.length ?? 0) === 0)
        this.constraint = undefined;
    }
  }

  size_ = 0;

  @Input() set size(size: FblModeSize) {
    if (size == FblModeSize.small) this.size_ = 25;
    else this.size_ = 30;
  }

  agInit(params: ICellRendererParams & { strategy: StrategyEx; size: FblModeSize }): void {
    this.strategy = params.strategy;
    this.size = params.size;
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }
}
