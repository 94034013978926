import { Injectable } from "@angular/core";
import {
  AccountMarketplace,
  AccountSelectionService,
  AdsStatsWithPreviousPeriod,
  AdStatsEx,
  AsinService,
  CampaignType,
  CogsByAsin,
  Currency,
  DateAggregation,
  emptyOrderStat,
  FbaStorageFeeService,
  Marketplace,
  marketplaceToCurrencyRate,
  Order,
  OrderService,
  OrderStats,
  orderToOrderStats,
  StatsApiClientService,
  sumOrderStat,
  UserSelectionService,
  Utils,
} from "@front/m19-services";
import { combineLatest, map, Observable, shareReplay, switchMap, take, tap, zip } from "rxjs";
import { of } from "rxjs/internal/observable/of";

@Injectable({
  providedIn: "root",
})
export class ProfitService {
  private marketplace: Marketplace;
  private dateRanges: Date[]; // TODO: use moment instead of Date

  // public observables
  public orderStatsByAsin$: Observable<Map<string, OrderStats>>;
  public orderStatsByAsinDate$: Observable<Map<string, Map<string, OrderStats>>>;
  public orderStatsByAsinDateWithDailyAsinStats$: Observable<{
    orderStatsByAsinDate: Map<string, Map<string, OrderStats>>;
    previousOrderStatsByAsinDate: Map<string, Map<string, OrderStats>>;
    dailyAsinStats: AdsStatsWithPreviousPeriod;
  }>;
  public orderStatsByDate$: Observable<Map<string, OrderStats>>;
  public previousOrderStatsByAsin$: Observable<Map<string, OrderStats>>;
  public previousOrderStatsByAsinDate$: Observable<Map<string, Map<string, OrderStats>>>;

  public previousOrderStatsByDate$: Observable<Map<string, OrderStats>>;
  public last12weeksOrderStats$: Observable<OrderStats[]>;
  public last12weeksAsinOrderStats$: Observable<OrderStats[]>;

  constructor(
    accountSelection: AccountSelectionService,
    userSelectionService: UserSelectionService,
    statsApiClientService: StatsApiClientService,
    asinService: AsinService,
    orderService: OrderService,
    fbaStorageFeeService: FbaStorageFeeService,
  ) {
    const allStats$ = combineLatest([
      accountSelection.singleAccountMarketplaceSelection$,
      userSelectionService.selectedDateRange$,
      userSelectionService.selectedCurrency$,
      userSelectionService.periodComparison$,
    ]).pipe(
      tap(([am, dr, _]) => {
        this.marketplace = am.marketplace;
        this.dateRanges = dr.map((d) => d.toDate());
      }),
      switchMap(([am, dr, currency, periodComparison]) =>
        zip([
          statsApiClientService.getDailyAsinsStats(
            am.accountId,
            am.marketplace,
            am.useSourcingMetrics,
            dr,
            periodComparison?.period,
          ),
          asinService.getCostOfGoods(am.accountId, am.marketplace),
          orderService.globalDataByDates$,
          fbaStorageFeeService.globalDataByDates$,
          orderService.previousGlobalDataByDates$,
          fbaStorageFeeService.previousGlobalDataByDates$,
          of(currency),
        ]),
      ),
    );

    const orderStats$ = allStats$.pipe(
      map(
        ([
          dailyAsinStats,
          cogsByAsin,
          orders,
          fbaStorageFeesByDates,
          previousOrders,
          previousFbaStorageFeesByDates,
          currency,
        ]) => {
          return this.buildAllOrderStats(
            dailyAsinStats,
            cogsByAsin,
            orders,
            fbaStorageFeesByDates,
            currency,
            previousOrders,
            previousFbaStorageFeesByDates,
          );
        },
      ),
    );

    const orderStatsWithDailyAsinStats$ = allStats$.pipe(
      map(
        ([
          dailyAsinStats,
          cogsByAsin,
          orders,
          fbaStorageFeesByDates,
          previousOrders,
          previousFbaStorageFeesByDates,
          currency,
        ]) => {
          const orderStats = this.buildAllOrderStats(
            dailyAsinStats,
            cogsByAsin,
            orders,
            fbaStorageFeesByDates,
            currency,
            previousOrders,
            previousFbaStorageFeesByDates,
          );
          return {
            ...orderStats,
            dailyAsinStats,
          };
        },
      ),
    );

    this.orderStatsByAsin$ = orderStats$.pipe(
      map((x) => x.orderStats.orderStatsByAsin),
      shareReplay(1),
    );
    this.orderStatsByAsinDate$ = orderStats$.pipe(
      map((x) => x.orderStats.orderStatsByAsinDate),
      shareReplay(1),
    );
    this.orderStatsByAsinDateWithDailyAsinStats$ = orderStatsWithDailyAsinStats$.pipe(
      map((stats) => ({
        orderStatsByAsinDate: stats.orderStats.orderStatsByAsinDate,
        previousOrderStatsByAsinDate: stats.previousOrderStats.orderStatsByAsinDate,
        dailyAsinStats: stats.dailyAsinStats,
      })),
      shareReplay(1),
    );
    this.orderStatsByDate$ = orderStats$.pipe(
      map((x) => x.orderStats.orderStatsByDate),
      shareReplay(1),
    );
    this.previousOrderStatsByAsin$ = orderStats$.pipe(
      map((x) => x.previousOrderStats.orderStatsByAsin),
      shareReplay(1),
    );
    this.previousOrderStatsByAsinDate$ = orderStats$.pipe(
      map((x) => x.previousOrderStats.orderStatsByAsinDate),
      shareReplay(1),
    );

    this.previousOrderStatsByDate$ = orderStats$.pipe(
      map((x) => x.previousOrderStats.orderStatsByDate),
      shareReplay(1),
    );

    const lastWeekStats = accountSelection.singleAccountMarketplaceSelection$.pipe(
      switchMap((am: AccountMarketplace) =>
        combineLatest<[AdStatsEx[], CogsByAsin, Order[], Map<string, Map<string, number>>, Currency]>([
          statsApiClientService
            .getLast12WeeksAsinStats(am.accountId, am.marketplace, !!am.useSourcingMetrics)
            .pipe(take(1)),
          asinService.getCostOfGoods(am.accountId, am.marketplace),
          orderService.getOrders(
            am.accountId,
            am.marketplace,
            Utils.formatDateForApiFromToday(-84),
            Utils.formatDateForApiFromToday(-1),
          ),
          fbaStorageFeeService.getFees(
            am.accountId,
            am.marketplace,
            Utils.formatDateForApiFromToday(-84),
            Utils.formatDateForApiFromToday(-1),
          ),
          userSelectionService.selectedCurrency$,
        ]),
      ),
      map(([asinStats, cogsByAsin, orders, fbaStorageFeesByDates, currency]) => {
        const minDate = new Date(Utils.formatDateForApiFromToday(-84));
        const maxDate = new Date(Utils.formatDateForApiFromToday(-1));
        const orderStatsByAsin = new Map<string, OrderStats>();
        const orderStatsByDate = new Map<string, OrderStats>();
        const orderStatsByAsinDate = new Map<string, Map<string, OrderStats>>();
        const currencyRate = marketplaceToCurrencyRate(this.marketplace, currency);
        for (const order of orders) {
          let asinCog = 0;
          if (cogsByAsin.has(order.asin)) {
            for (const dateCog of cogsByAsin.get(order.asin)) {
              const date = dateCog[0];
              // cogs are sorted by date
              if (date > order.day) break;
              const cog = dateCog[1];
              asinCog = cog;
            }
          }
          asinCog *= currencyRate;
          const orderStat = orderToOrderStats(order, currency, asinCog);

          // Clone order stat to avoid modifying orderStat inserted in orderStatsByAsin map
          if (orderStatsByDate.has(order.day)) {
            orderStatsByDate.set(order.day, sumOrderStat(orderStatsByDate.get(order.day), { ...orderStat }));
          } else {
            orderStatsByDate.set(order.day, { ...orderStat });
          }
          if (!orderStatsByAsinDate.has(order.asin)) {
            orderStatsByAsinDate.set(order.asin, new Map());
          }
          if (orderStatsByAsinDate.get(order.asin).has(order.day)) {
            orderStatsByAsinDate
              .get(order.asin)
              .set(order.day, sumOrderStat(orderStatsByAsinDate.get(order.asin).get(order.day), { ...orderStat }));
          } else {
            orderStatsByAsinDate.get(order.asin).set(order.day, { ...orderStat });
          }
        }
        this.setFbaStorageFee(
          fbaStorageFeesByDates,
          orderStatsByAsin,
          orderStatsByDate,
          orderStatsByAsinDate,
          currency,
          currencyRate,
          [minDate, maxDate],
        );
        this.setAdvertisingStats(
          asinStats,
          orderStatsByAsin,
          orderStatsByDate,
          orderStatsByAsinDate,
          currency,
          currencyRate,
        );
        const statsByAsinDate: OrderStats[] = [];
        for (const [asin, data] of orderStatsByAsinDate) {
          for (const [date, stats] of data) {
            stats.asin = asin;
            statsByAsinDate.push(stats);
          }
        }
        return {
          statsByDate: Array.from(orderStatsByDate.values()),
          statsByAsinDate,
        };
      }),
      shareReplay(1),
    );
    this.last12weeksOrderStats$ = lastWeekStats.pipe(map((x) => x.statsByDate));
    this.last12weeksAsinOrderStats$ = lastWeekStats.pipe(map((x) => x.statsByAsinDate));
  }

  private buildAllOrderStats(
    dailyAsinStats: AdsStatsWithPreviousPeriod,
    cogsByAsin: CogsByAsin,
    orders: Order[],
    fbaStorageFeesByDates: Map<string, Map<string, number>>,
    currency: Currency,
    previousOrders: Order[],
    previousFbaStorageFeesByDates: Map<string, Map<string, number>>,
  ) {
    const orderStats = this.buildOrderStats(dailyAsinStats.data, cogsByAsin, orders, fbaStorageFeesByDates, currency);
    const previousOrderStats = this.buildOrderStats(
      dailyAsinStats.data,
      cogsByAsin,
      previousOrders,
      previousFbaStorageFeesByDates,
      currency,
    );

    return {
      orderStats,
      previousOrderStats,
    };
  }

  private buildOrderStats(
    asinAdStats: AdStatsEx[],
    cogsByAsin: CogsByAsin,
    orders: Order[],
    fbaStorageFeesByDates: Map<string, Map<string, number>>,
    currency: Currency,
  ) {
    const orderStatsByAsin = new Map<string, OrderStats>();
    const orderStatsByDate = new Map<string, OrderStats>();
    const orderStatsByAsinDate = new Map<string, Map<string, OrderStats>>();
    const currencyRate = marketplaceToCurrencyRate(this.marketplace, currency);
    for (const order of orders) {
      let asinCog = 0;
      if (cogsByAsin.has(order.asin)) {
        for (const dateCog of cogsByAsin.get(order.asin)) {
          const date = dateCog[0];
          // cogs are sorted by date
          if (date > order.day) break;
          const cog = dateCog[1];
          asinCog = cog;
        }
      }
      asinCog *= currencyRate;
      const orderStat = orderToOrderStats(order, currency, asinCog);

      if (orderStatsByAsin.has(order.asin)) {
        orderStatsByAsin.set(order.asin, sumOrderStat(orderStatsByAsin.get(order.asin), orderStat));
      } else {
        orderStatsByAsin.set(order.asin, orderStat);
      }

      // Clone order stat to avoid modifying orderStat inserted in orderStatsByAsin map
      if (orderStatsByDate.has(order.day)) {
        orderStatsByDate.set(order.day, sumOrderStat(orderStatsByDate.get(order.day), { ...orderStat }));
      } else {
        orderStatsByDate.set(order.day, { ...orderStat });
      }

      // Clone order stat to avoid modifying orderStat inserted in orderStatsByAsin map
      if (!orderStatsByAsinDate.has(order.asin)) {
        orderStatsByAsinDate.set(order.asin, new Map());
      }
      if (orderStatsByAsinDate.get(order.asin).has(order.day)) {
        orderStatsByAsinDate
          .get(order.asin)
          .set(order.day, sumOrderStat(orderStatsByAsinDate.get(order.asin).get(order.day), { ...orderStat }));
      } else {
        orderStatsByAsinDate.get(order.asin).set(order.day, { ...orderStat });
      }
    }
    this.setFbaStorageFee(
      fbaStorageFeesByDates,
      orderStatsByAsin,
      orderStatsByDate,
      orderStatsByAsinDate,
      currency,
      currencyRate,
      this.dateRanges,
    );
    this.setAdvertisingStats(
      asinAdStats,
      orderStatsByAsin,
      orderStatsByDate,
      orderStatsByAsinDate,
      currency,
      currencyRate,
    );
    return { orderStatsByAsin, orderStatsByDate, orderStatsByAsinDate };
  }

  private setFbaStorageFee(
    fbaStorageFee: Map<string, Map<string, number>>,
    orderStatsByAsin: Map<string, OrderStats>,
    orderStatsByDate: Map<string, OrderStats>,
    orderStatsByAsinDate: Map<string, Map<string, OrderStats>>,
    currency: Currency,
    currencyRate: number,
    dateRange: Date[],
  ): void {
    const selectedStartDate = dateRange[0];
    const selectedEndDate = dateRange[1];
    const currencyRate_ = currencyRate;

    for (let date = selectedStartDate; date <= selectedEndDate; date = Utils.incDate(date, DateAggregation.daily, 1)) {
      const currentDate = Utils.formatDateForApi(date);
      const asinStorageFee = fbaStorageFee.get(currentDate);
      if (!asinStorageFee) {
        continue;
      }
      asinStorageFee.forEach((value: number, key: string) => {
        const asin = key;
        const storageFee = value * currencyRate_ || 0;
        if (storageFee == 0) return;
        if (!orderStatsByAsin.has(asin)) orderStatsByAsin.set(asin, emptyOrderStat(asin, currentDate, currency));

        const newOrderStatByAsin = orderStatsByAsin.get(asin);
        newOrderStatByAsin.fee += storageFee;
        newOrderStatByAsin.profit += storageFee;
        newOrderStatByAsin.fbaStorageFee += storageFee;

        if (!orderStatsByDate.has(currentDate))
          orderStatsByDate.set(currentDate, emptyOrderStat(asin, currentDate, currency));

        const newOrderStatByDate = orderStatsByDate.get(currentDate);
        newOrderStatByDate.fee += storageFee;
        newOrderStatByDate.profit += storageFee;
        newOrderStatByDate.fbaStorageFee += storageFee;

        if (!orderStatsByAsinDate.has(asin)) {
          orderStatsByAsinDate.set(asin, new Map());
        }
        const orderStatByAsinDateInMap = orderStatsByAsinDate.get(asin).get(currentDate);
        if (orderStatByAsinDateInMap) {
          orderStatByAsinDateInMap.fee += storageFee;
          orderStatByAsinDateInMap.profit += storageFee;
          orderStatByAsinDateInMap.fbaStorageFee += storageFee;
        } else {
          const newOrderStat = emptyOrderStat(asin, currentDate, currency);
          newOrderStat.fee += storageFee;
          newOrderStat.profit += storageFee;
          newOrderStat.fbaStorageFee += storageFee;
          orderStatsByAsinDate.get(asin).set(currentDate, newOrderStat);
        }
      });
    }
  }

  private setAdvertisingStats(
    asinStats: AdStatsEx[],
    orderStatsByAsin: Map<string, OrderStats>,
    orderStatsByDate: Map<string, OrderStats>,
    orderStatsByAsinDate: Map<string, Map<string, OrderStats>>,
    currency: Currency,
    currencyRate: number,
  ): void {
    asinStats.forEach((adStat) => {
      const cost = adStat.currency == currency ? (adStat.cost ?? 0) : (adStat.cost ?? 0) * currencyRate;

      const newOrderStatByAsin =
        orderStatsByAsin.get(adStat.asin) ?? emptyOrderStat(adStat.asin, adStat.date, currency);
      const newOrderStatByDate =
        orderStatsByDate.get(adStat.date) ?? emptyOrderStat(adStat.asin, adStat.date, currency);

      switch (adStat.campaignType) {
        case CampaignType.SP:
          newOrderStatByAsin.spAdvertising -= cost;
          newOrderStatByDate.spAdvertising -= cost;
          break;
        case CampaignType.SD:
        case CampaignType.SDR:
          newOrderStatByAsin.sdAdvertising -= cost;
          newOrderStatByDate.sdAdvertising -= cost;
          break;
        default:
          newOrderStatByAsin.sbAdvertising -= cost;
          newOrderStatByDate.sbAdvertising -= cost;
      }

      newOrderStatByAsin.advertising -= cost;
      newOrderStatByAsin.profit -= cost;
      orderStatsByAsin.set(adStat.asin, newOrderStatByAsin);

      newOrderStatByDate.advertising -= cost;
      newOrderStatByDate.profit -= cost;
      orderStatsByDate.set(adStat.date, newOrderStatByDate);

      // Set profit for order stat by asin/date
      // We don't need the advertising campaign type, as we only want to calculate profit and total ad cost
      if (!orderStatsByAsinDate.has(adStat.asin)) orderStatsByAsinDate.set(adStat.asin, new Map());

      const orderStatByAsinDateInMap = orderStatsByAsinDate.get(adStat.asin).get(adStat.date);
      if (orderStatByAsinDateInMap) {
        orderStatByAsinDateInMap.advertising -= cost;
        orderStatByAsinDateInMap.profit -= cost;
      } else {
        const newOrderStat = emptyOrderStat(adStat.asin, adStat.date, currency);
        newOrderStat.advertising -= cost;
        newOrderStat.profit -= cost;
        orderStatsByAsinDate.get(adStat.asin).set(adStat.date, newOrderStat);
      }
    });
  }
}
