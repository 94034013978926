import { AllVendorInventory, DailyVendorAsinAllSales } from '../api-client';
import { AdStatsEx, VendorInventoryAdStats } from './AdStatsEx';

export function toInventoryAdStats(inventories: AllVendorInventory[], isSourcing: boolean): AdStatsEx[] {
  return inventories.map((i: AllVendorInventory) => {
    return {
      accountId: i.accountId,
      marketplace: i.marketplace,
      asin: i.asin,
      date: i.date,
      vendorInventoryAdStats: getVendorInventoryAdStats(i, isSourcing),
    };
  });
}

export function toVendorAsinSalesAdStats(dailyAsinSales: DailyVendorAsinAllSales[], isSourcing: boolean): AdStatsEx[] {
  return dailyAsinSales.map((s: DailyVendorAsinAllSales) => getVendorAsinSalesStats(s, isSourcing));
}

export function getVendorAsinSalesStats(s: DailyVendorAsinAllSales, isSourcing: boolean) {
  return {
    accountId: s.accountId,
    marketplace: s.marketplace,
    asin: s.asin,
    date: s.date,
    allSales: isSourcing ? s.sourcingSales : s.manufacturingSales,
    allOrderedUnits: isSourcing ? s.sourcingUnitsSold : s.manufacturingUnitsSold,
    vendorCustomerReturns: isSourcing ? s.sourcingCustomerReturns : s.manufacturingCustomerReturns,
    vendorShippedCogs: isSourcing ? s.sourcingShippedCogs : s.manufacturingShippedCogs,
  };
}

// Create an AdStatsEx object with the right vendor metrics (sourcing or manufacturing)
export function getVendorInventoryAdStats(inventory: AllVendorInventory, isSourcing: boolean): VendorInventoryAdStats {
  return {
    inventorySellableUnits: isSourcing ? inventory.sourcingSellableUnits : inventory.manufacturingSellableUnits,
    inventorySellableCost: isSourcing ? inventory.sourcingSellableCost : inventory.manufacturingSellableCost,
    inventoryUnsellableUnits: isSourcing ? inventory.sourcingUnsellableUnits : inventory.manufacturingUnsellableUnits,
    inventoryUnsellableCost: isSourcing ? inventory.sourcingUnsellableCost : inventory.manufacturingUnsellableCost,
    inventoryOpenPurchaseOrderUnits: isSourcing
      ? inventory.sourcingOpenPurchaseOrderUnits
      : inventory.manufacturingOpenPurchaseOrderUnits,
    inventoryAged90pSellableUnits: isSourcing
      ? inventory.sourcingAged90pSellableUnits
      : inventory.manufacturingAged90pSellableUnits,
    inventoryAged90pSellableCost: isSourcing
      ? inventory.sourcingAged90pSellableCost
      : inventory.manufacturingAged90pSellableCost,
    inventorySourceableProductOutOfStockRate: isSourcing ? 0 : inventory.sourceableProductOutOfStockRate,
    lastVendorInventoryDate: inventory.lastVendorInventoryDate,
  };
}

// sum number handling undefined
function sum(a: number, b: number) {
  return a ? (b ? a + b : a) : b ? b : undefined;
}

function divide(a: number, b: number) {
  return a ? (b && b !== 0 ? a / b : NaN) : b ? 0 : NaN;
}

export function mergeVendorTrafficAndInventoryDailyAsinStats(
  trafficStats: AdStatsEx[],
  inventoryStats: AdStatsEx[],
): AdStatsEx[] {
  const mergedData = new Map<string, AdStatsEx>();
  for (const trafficStat of trafficStats) {
    mergedData.set(trafficStat.asin! + trafficStat.date!, trafficStat);
  }
  for (const inventoryStat of inventoryStats) {
    const key = inventoryStat.asin! + inventoryStat.date!;
    const mergedStat = mergedData.get(key);
    if (mergedStat) {
      mergedStat.vendorInventoryAdStats = inventoryStat.vendorInventoryAdStats;
      // compute outOfStockGlanceViews = glance views * sourceableProductOutOfStockRate
      if (
        mergedStat.vendorInventoryAdStats!.inventorySourceableProductOutOfStockRate! > 0 &&
        mergedStat.browserPageViews! > 0
      ) {
        mergedStat.outOfStockGlanceViews =
          mergedStat.vendorInventoryAdStats!.inventorySourceableProductOutOfStockRate! * mergedStat!.browserPageViews!;
      }
    } else {
      mergedData.set(key, inventoryStat);
    }
  }
  return Array.from(mergedData.values());
}

export function sumVendorInventoryStats(a: VendorInventoryAdStats, o: VendorInventoryAdStats): VendorInventoryAdStats {
  const res: VendorInventoryAdStats = {};

  res.inventorySellableUnits = sum(a.inventorySellableUnits!, o.inventorySellableUnits!);
  res.inventorySellableCost = sum(a.inventorySellableCost!, o.inventorySellableCost!);
  res.inventoryUnsellableUnits = sum(a.inventoryUnsellableUnits!, o.inventoryUnsellableUnits!);
  res.inventoryUnsellableCost = sum(a.inventoryUnsellableCost!, o.inventoryUnsellableCost!);
  res.inventoryOpenPurchaseOrderUnits = sum(a.inventoryOpenPurchaseOrderUnits!, o.inventoryOpenPurchaseOrderUnits!);
  res.inventoryAged90pSellableUnits = sum(a.inventoryAged90pSellableUnits!, o.inventoryAged90pSellableUnits!);
  res.inventoryAged90pSellableCost = sum(a.inventoryAged90pSellableCost!, o.inventoryAged90pSellableCost!);
  res.lastVendorInventoryDate = a.lastVendorInventoryDate ?? o.lastVendorInventoryDate;
  // do not sum inventorySourceableProductOutOfStockRate
  return res;
}

export function divideVendorInventory(a: VendorInventoryAdStats, x: number): VendorInventoryAdStats {
  const res: VendorInventoryAdStats = {};
  res.inventorySellableUnits = divide(a.inventorySellableUnits!, x);
  res.inventorySellableCost = divide(a.inventorySellableCost!, x);
  res.inventoryUnsellableUnits = divide(a.inventoryUnsellableUnits!, x);
  res.inventoryUnsellableCost = divide(a.inventoryUnsellableCost!, x);
  res.inventoryOpenPurchaseOrderUnits = divide(a.inventoryOpenPurchaseOrderUnits!, x);
  res.inventoryAged90pSellableUnits = divide(a.inventoryAged90pSellableUnits!, x);
  res.inventoryAged90pSellableCost = divide(a.inventoryAged90pSellableCost!, x);
  return res;
}
