import { AdStats } from '../api-client';
import { CatalogBrandEx } from './CatalogBrandEx';

export interface AdStatsEx extends AdStats {
  // We will have all the VENDORS sourcing OR manufacturing fields here.
  vendorInventoryAdStats?: VendorInventoryAdStats;
  catalogBrand?: CatalogBrandEx;
  catalogBrandName?: string;
  outOfStockGlanceViews?: number;
}

// Inventory stats
export interface VendorInventoryAdStats {
  inventorySellableUnits?: number;
  inventorySellableCost?: number;
  inventoryUnsellableUnits?: number;
  inventoryUnsellableCost?: number;
  inventoryOpenPurchaseOrderUnits?: number;
  inventoryAged90pSellableUnits?: number;
  inventoryAged90pSellableCost?: number;
  inventorySourceableProductOutOfStockRate?: number;
  lastVendorInventoryDate?: string;
}
