import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import {
  Brand,
  BrandApi,
  BrandAsset,
  Marketplace,
  MediaType,
  PreModeration,
  Response,
  SbAsins,
  SbCreative,
  SbCreativeCluster,
  SbCreativeType,
  StrategyApi,
  UpdateSbCreativeRequest,
} from './api-client';
import { catchAjaxError, Utils } from './utils';
import { BrandNameMaxLength, HeadlineMaxLength, SbCreativeEx } from './models';
import { StrategyCache } from './strategy.cache';
import { StrategyCacheReloaded } from './strategyCacheReloaded';
import { AccountSelectionService } from './accountSelection.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SbStrategiesService {
  private brands: { [key: string]: Observable<Brand[]> } = {}; // Local Cache for Brands per account.marketplace

  private readonly brandAssetsIndex: BehaviorSubject<Map<string, BrandAsset[]>> = new BehaviorSubject<
    Map<string, BrandAsset[]>
  >(new Map());
  public readonly brandAssetsIndex$ = this.brandAssetsIndex.asObservable();
  public readonly brandAssets$: Observable<BrandAsset[]> = this.brandAssetsIndex$.pipe(
    map((x) => Array.from(x.values()).flat()),
  );

  private readonly sbCreativeIndex: Map<number, SbCreativeEx> = new Map();
  private readonly sbCreativeIndexSubject: BehaviorSubject<Map<number, SbCreativeEx>> = new BehaviorSubject(new Map());
  public readonly sbCreativeIndex$: Observable<Map<number, SbCreativeEx>> = this.sbCreativeIndexSubject.asObservable();

  constructor(
    private strategyCacheReloaded: StrategyCacheReloaded,
    private strategyCache: StrategyCache,
    private strategyApi: StrategyApi,
    private brandApi: BrandApi,
    accountSelectionService: AccountSelectionService,
  ) {
    accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(switchMap((x) => this.load(x.accountId, x.marketplace)))
      .subscribe();
  }

  load(accountId: string, marketplace: Marketplace): Observable<Map<number, SbCreativeEx>> {
    return combineLatest([
      this.brandApi.getBrandAsset({ accountId: accountId, marketplace: marketplace }),
      this.strategyApi.listSbCreative({ accountId: accountId, marketplace: marketplace }),
    ]).pipe(map(this.reload));
  }

  private reload = ([brandAssets, sbCreatives]: [BrandAsset[], SbCreative[]]) => {
    const brandAssetsIndex = new Map<string, BrandAsset[]>();
    for (const asset of brandAssets) {
      if (brandAssetsIndex.has(asset.assetId!)) {
        brandAssetsIndex.get(asset.assetId!)!.push(asset);
      } else {
        brandAssetsIndex.set(asset.assetId!, [asset]);
      }
    }
    this.brandAssetsIndex.next(brandAssetsIndex);

    const sbCreativeIndexByStrategyId: Map<number, SbCreativeEx[]> = new Map();
    this.sbCreativeIndex.clear();

    for (const sbCreative of sbCreatives) {
      const creative = new SbCreativeEx(sbCreative, this.brandAssetsIndex.getValue());
      if (!sbCreativeIndexByStrategyId.has(sbCreative.strategyId))
        sbCreativeIndexByStrategyId.set(sbCreative.strategyId, [creative]);
      else sbCreativeIndexByStrategyId.get(sbCreative.strategyId)!.push(creative);
      this.sbCreativeIndex.set(sbCreative.creativeId, creative);
    }
    this.sbCreativeIndexSubject.next(this.sbCreativeIndex);

    this.strategyCache.updateAllCreatives(sbCreativeIndexByStrategyId);

    this.strategyCacheReloaded.sbCreativeIndexReloaded.next(this.sbCreativeIndex);
    return this.sbCreativeIndex;
  };

  public getBrands(accountId: string, marketplace: Marketplace): Observable<Brand[]> {
    const key = accountId + '.' + marketplace;
    if (!this.brands[key]) {
      this.brands[key] = this.brandApi
        .getBrand({ accountId: accountId, marketplace: marketplace })
        .pipe(shareReplay(1));
    }
    return this.brands[key];
  }

  public hasBrands(accountId: string, marketplace: Marketplace): Observable<boolean> {
    return this.getBrands(accountId, marketplace).pipe(map((x) => x && x.length > 0));
  }

  public updateSbCreativeStatus(sbCreative: SbCreative): Observable<void> {
    return this.updateSbCreativeInDb(
      {
        accountId: sbCreative.accountId,
        marketplace: sbCreative.marketplace,
        creativeId: sbCreative.creativeId,
        state: sbCreative.state,
      },
      sbCreative.strategyId,
    );
  }

  public updateSbCreative(prevCreative: SbCreative, newCreative: SbCreative): Observable<void> {
    if (
      newCreative.logoAssetId == prevCreative.logoAssetId &&
      newCreative.videoAssetId == prevCreative.videoAssetId &&
      newCreative.customImageAssetId == prevCreative.customImageAssetId &&
      newCreative.customImageAssetId2 == prevCreative.customImageAssetId2 &&
      newCreative.customImageAssetId3 == prevCreative.customImageAssetId3 &&
      newCreative.customImageAssetId4 == prevCreative.customImageAssetId4 &&
      newCreative.customImageAssetId5 == prevCreative.customImageAssetId5 &&
      newCreative.headline == prevCreative.headline
    ) {
      return of(void 0);
    }

    return this.updateSbCreativeInDb(
      {
        accountId: prevCreative.accountId,
        marketplace: prevCreative.marketplace,
        creativeId: prevCreative.creativeId,
        logoAssetId: this.getUpdatedValue(prevCreative.logoAssetId, newCreative.logoAssetId),
        videoAssetId: this.getUpdatedValue(prevCreative.videoAssetId, newCreative.videoAssetId),
        customImageAssetId: this.getUpdatedValue(prevCreative.customImageAssetId, newCreative.customImageAssetId),
        customImageAssetId2: this.getUpdatedValue(
          prevCreative.customImageAssetId2,
          newCreative.customImageAssetId2,
          true,
        ),
        customImageAssetId3: this.getUpdatedValue(
          prevCreative.customImageAssetId3,
          newCreative.customImageAssetId3,
          true,
        ),
        customImageAssetId4: this.getUpdatedValue(
          prevCreative.customImageAssetId4,
          newCreative.customImageAssetId4,
          true,
        ),
        customImageAssetId5: this.getUpdatedValue(
          prevCreative.customImageAssetId5,
          newCreative.customImageAssetId5,
          true,
        ),
        headline: this.getUpdatedValue(prevCreative.headline, newCreative.headline),
      },
      prevCreative.strategyId,
    );
  }

  private getUpdatedValue<T>(prevValue: T, newValue: T, withExplicitNull: boolean = false): T | string | undefined {
    if (newValue === prevValue) return undefined;

    if (withExplicitNull && newValue === undefined) return 'null';

    return newValue;
  }

  public updateSbCreativeClusterAsync(sbCreative: SbCreative, cluster: SbAsins[]): Observable<SbCreativeCluster> {
    const prevCreatives = this.strategyCache.getCreatives(sbCreative.strategyId);
    if (!prevCreatives) {
      return throwError(() => 'Unknown strategyId ' + sbCreative.strategyId);
    }
    const index = prevCreatives.findIndex((x) => x.creativeId === sbCreative.creativeId);
    if (index === -1) {
      return throwError(() => 'Unknown creativeId ' + sbCreative.creativeId);
    }
    return this.strategyApi
      .updateSbCreativeCluster({
        accountId: sbCreative.accountId,
        marketplace: sbCreative.marketplace,
        creativeId: sbCreative.creativeId,
        sbCreativeCluster: {
          accountId: sbCreative.accountId,
          marketplace: sbCreative.marketplace,
          creativeId: sbCreative.creativeId,
          creativeAsins: cluster,
        },
      })
      .pipe(
        map((response: Response) => {
          const prevCreatives = this.strategyCache.getCreatives(sbCreative.strategyId);
          const index = prevCreatives.findIndex((x) => x.creativeId === sbCreative.creativeId);
          const newSbCreativeCluster = response.entity as SbCreativeCluster;
          prevCreatives[index].creativeAsins = newSbCreativeCluster.creativeAsins;
          this.strategyCache.updateStrategyCreatives(sbCreative.strategyId, prevCreatives);
          return newSbCreativeCluster;
        }),
        catchAjaxError('Error updating SB Creative: '),
      );
  }

  public createSbCreativeAsync(sbCreative: SbCreative): Observable<SbCreativeEx> {
    const strategy = this.strategyCache.get(sbCreative.strategyId);
    if (!strategy) {
      return throwError(() => 'Unknown strategyId ' + sbCreative.strategyId);
    }
    if (strategy.accountId != sbCreative.accountId) {
      return throwError(() => 'Trying to create a SB Creative for an invalid accountId ' + strategy.accountId);
    }
    if (
      sbCreative.creativeType == SbCreativeType.productCollection ||
      sbCreative.creativeType === SbCreativeType.storeSpotlight
    ) {
      if (!sbCreative.brandName) {
        return throwError(() => 'No brand name specified');
      }
      if (sbCreative.brandName.length > BrandNameMaxLength) {
        return throwError(() => 'Brand name should contain less than ' + BrandNameMaxLength + ' characters');
      }
      if (!sbCreative.headline) {
        return throwError(() => 'No headline specified');
      }
      if (sbCreative.headline.length > HeadlineMaxLength) {
        return throwError(() => 'Headline should contain less than ' + HeadlineMaxLength + ' characters');
      }
    }
    return this.strategyApi
      .createSbCreative({
        accountId: sbCreative.accountId,
        marketplace: strategy.marketplace,
        logoAssetId: sbCreative.logoAssetId,
        videoAssetId: sbCreative.videoAssetId,
        state: sbCreative.state,
        strategyId: sbCreative.strategyId,
        brandEntityId: sbCreative.brandEntityId ?? 'null',
        brandName: sbCreative.brandName ?? 'null',
        headline: sbCreative.headline ?? 'null',
        storePageId: sbCreative.storePageId ?? 'null',
        customImageAssetId: sbCreative.customImageAssetId ?? 'null',
        customImageAssetId2: sbCreative.customImageAssetId2 ?? 'null',
        customImageAssetId3: sbCreative.customImageAssetId3 ?? 'null',
        customImageAssetId4: sbCreative.customImageAssetId4 ?? 'null',
        customImageAssetId5: sbCreative.customImageAssetId5 ?? 'null',
        creativeType: sbCreative.creativeType,
      })
      .pipe(
        map((response) => {
          const creative = response.entity as SbCreative;
          const newCreative = new SbCreativeEx(creative, this.brandAssetsIndex.getValue());
          this.sbCreativeIndex.set(creative.creativeId, newCreative);
          this.sbCreativeIndexSubject.next(this.sbCreativeIndex);
          this.strategyCache.addStrategyCreative(sbCreative.strategyId, newCreative);
          return newCreative;
        }),
        catchAjaxError('Error creating SB Creative: '),
      );
  }

  public deleteSbCreative(sbCreative: SbCreative): Observable<void> {
    const strategy = this.strategyCache.get(sbCreative.strategyId);
    if (!strategy) {
      return throwError(() => 'Unknown strategyId ' + sbCreative.strategyId);
    }
    if (strategy.accountId != sbCreative.accountId) {
      return throwError(() => 'Trying to delete a SB Creative for an invalid accountId ' + strategy.accountId);
    }
    const sbCreatives = this.strategyCache.getCreatives(sbCreative.strategyId);
    if (!sbCreatives) {
      return throwError(() => 'Unknown strategyId ' + sbCreative.strategyId);
    }
    if (sbCreatives.findIndex((x) => x.creativeId === sbCreative.creativeId) === -1) {
      return throwError(() => 'Unknown creativeId ' + sbCreative.creativeId);
    }

    return this.strategyApi
      .deleteSbCreative({
        accountId: sbCreative.accountId,
        marketplace: strategy.marketplace,
        creativeId: sbCreative.creativeId,
      })
      .pipe(
        catchAjaxError(),
        map(() => void 0),
        tap(() => {
          const prevCreatives = this.strategyCache.getCreatives(sbCreative.strategyId)!;
          const index = prevCreatives.findIndex((x) => x.creativeId === sbCreative.creativeId);
          prevCreatives.splice(index, 1);
          this.strategyCache.updateStrategyCreatives(sbCreative.strategyId, prevCreatives);
        }),
      );
  }

  private updateSbCreativeInDb(updateSbCreativeRequest: UpdateSbCreativeRequest, strategyId: number): Observable<void> {
    const prevCreatives = this.strategyCache.getCreatives(strategyId);
    if (!prevCreatives) {
      return throwError(() => 'Unknown strategyId ' + strategyId);
    }
    const index = prevCreatives.findIndex((x) => x.creativeId === updateSbCreativeRequest.creativeId);
    if (index === -1) {
      return throwError(() => 'Unknown creativeId ' + updateSbCreativeRequest.creativeId);
    }
    const strategy = this.strategyCache.get(strategyId);
    if (!strategy) {
      return throwError(() => 'Unknown strategyId ' + strategyId);
    }
    return this.strategyApi.updateSbCreative(updateSbCreativeRequest).pipe(
      catchAjaxError(),
      map((response: Response) => {
        const prevCreatives = this.strategyCache.getCreatives(strategyId)!;
        const index = prevCreatives.findIndex((x) => x.creativeId === updateSbCreativeRequest.creativeId);
        prevCreatives[index] = new SbCreativeEx(response.entity as SbCreative, this.brandAssetsIndex.getValue());
        this.strategyCache.updateStrategyCreatives(strategyId, prevCreatives);
        return void 0;
      }),
    );
  }

  public uploadBrandAsset({
    accountId,
    marketplace,
    brandEntityId,
    file,
    mediaType,
  }: {
    accountId: string;
    marketplace: Marketplace;
    brandEntityId: string;
    file: File;
    mediaType: MediaType;
  }): Observable<BrandAsset | undefined> {
    if (!file) {
      return of(undefined);
    }
    return this.brandApi
      .uploadAsset({ accountId, marketplace, brandEntityId, mediaType, assetName: file.name, asset: file })
      .pipe(
        map((response: Response) => {
          if (response.code != 200) {
            throw new Error(response.message);
          }
          return response.entity;
        }),
        catchAjaxError('Brand Asset upload error: '),
        tap((brandAsset: BrandAsset | undefined) => {
          if (!brandAsset) return;

          const data = this.brandAssetsIndex.getValue();
          if (data.has(brandAsset.assetId!)) {
            data.get(brandAsset.assetId!)!.push(brandAsset);
          } else {
            data.set(brandAsset.assetId!, [brandAsset]);
          }
          this.brandAssetsIndex.next(data);
        }),
      );
  }

  public sbPreModeration(
    accountId: string,
    marketplace: Marketplace,
    headline: string,
    brandLogoURL: string,
    customImagesURLs: string[],
  ): Observable<PreModeration> {
    return this.strategyApi.sbPreModeration({
      accountId: accountId,
      marketplace: marketplace,
      preModerationRequest: { headline: headline, brandLogo: brandLogoURL, customImages: customImagesURLs },
    });
  }
}
