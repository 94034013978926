import { Component, OnInit, ViewChild } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Currency, CurrencyService, DailyAdSpendFee } from "@front/m19-services";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { formatCurrency } from "@angular/common";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { ExportToCsv } from "export-to-csv";

@Component({
  templateUrl: "./invoice-details-modal.component.html",
  styleUrls: ["invoice-details-modal.component.scss"],
})
export class InvoiceDetailsModalComponent implements OnInit {
  readonly faDownload = faDownload;

  locale: string;
  currency: Currency;
  displayedColumns = ["account", "marketplace", "adSpendFee"];
  dailyAdSpendFees: DailyAdSpendFee[];
  date: string;
  dataSource = new MatTableDataSource<DailyAdSpendFee>([]);
  dailyAdSpendFeeMap: Map<string, DailyAdSpendFee> = new Map<string, DailyAdSpendFee>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    public bsModalRef: BsModalRef,
    private currencyService: CurrencyService,
  ) {}

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dailyAdSpendFees?.forEach((x) => {
      if (!this.dailyAdSpendFeeMap.has(x.accountId + x.marketplace)) {
        this.dailyAdSpendFeeMap.set(x.accountId + x.marketplace, { ...x });
      } else {
        const existing = this.dailyAdSpendFeeMap.get(x.accountId + x.marketplace);
        existing.spendInLocalCurrency += x.spendInLocalCurrency;
        existing.adSpendFee += x.adSpendFee;
        existing.spend += x.spend;
      }
    });
    this.dataSource.data = Array.from(this.dailyAdSpendFeeMap.values());
  }

  formatAmount(amount: number): string {
    if (!amount) return "";
    return formatCurrency(
      amount,
      this.locale,
      this.currencyService.getCurrencySymbol(this.currency),
      this.currency,
      "1.0-2",
    );
  }

  close(): void {
    this.bsModalRef.hide();
  }

  downloadFile(): void {
    const options = {
      filename: "Invoice_details_" + this.date,
      fieldSeparator: ",",
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(
      this.dataSource.data.map((x) => {
        return {
          accountName: x.accountName,
          marketplace: x.marketplace,
          adSpendFee: x.adSpendFee.toFixed(2),
        };
      }),
    );
  }
}
