import { Component, Input, OnInit } from "@angular/core";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  InventoryStats,
  AsinService,
  Catalog,
  NotificationBidderIssueEx,
  NotificationEx,
  NotificationService,
  NotificationType,
  StrategyEx,
  Utils,
  InventoryService,
  InventoryRules,
  M19Status,
} from "@front/m19-services";
import { combineLatest, map } from "rxjs";

@UntilDestroy()
@Component({
  selector: "app-strategy-warning",
  templateUrl: "./strategy-warning.component.html",
  styleUrls: ["./strategy-warning.component.scss"],
})
export class StrategyWarningComponent implements OnInit {
  @Input()
  strategy: StrategyEx;

  readonly faWarning = faExclamationTriangle;

  faultyReason: string;
  atLeastOne = false;

  constructor(
    private asinService: AsinService,
    private inventoryService: InventoryService,
    private notifications: NotificationService,
  ) {}

  ngOnInit(): void {
    combineLatest<[InventoryRules, InventoryStats[], Catalog, NotificationEx[]]>([
      this.asinService.getInventoryRules(this.strategy.accountId, this.strategy.marketplace),
      this.inventoryService.getInventoryStats(this.strategy.accountId, this.strategy.marketplace),
      this.asinService.getCatalog(this.strategy.accountId, this.strategy.marketplace),
      this.notifications.getNotifications$.pipe(
        map((x: NotificationEx[]) =>
          x
            .filter((n) => n.type === NotificationType.BIDDER_ISSUE)
            .filter((n) => n.strategyId === this.strategy.strategyId),
        ),
      ),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([rules, stats, catalog, notifs]) => {
        if (!rules || !stats || !catalog) {
          return;
        }
        this.faultyReason = undefined;
        this.atLeastOne = false;

        // first check bidder issue
        const bidderIssue = notifs
          .filter((n) => n.type === NotificationType.BIDDER_ISSUE)
          .find((n) => n.strategyId === this.strategy.strategyId) as NotificationBidderIssueEx;
        if (bidderIssue && bidderIssue.warningType !== M19Status.IGNORED_REJECTED) {
          this.atLeastOne = true;
          this.faultyReason = bidderIssue.warningMessage;
          return;
        }

        // then creative issue
        for (const crea of this.strategy.sbCreatives ?? []) {
          const [status, _] = Utils.getSBFaultyReasons(
            crea,
            catalog,
            rules,
            stats,
            notifs.find((n) => n.subStrategyId === crea.creativeId) as NotificationBidderIssueEx,
          );
          if (status) {
            this.faultyReason = "Ads " + Utils.m19StatusToReadableStr(status);
            this.atLeastOne = true;
            break;
          }
        }
      });
  }
}
