import { Component, ElementRef, Input, OnInit, signal, ViewChild } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import {
  AccessLevel,
  AccountGroup,
  AccountMarketplace,
  AccountSelectionService,
  AccountState,
  BrandAnalyticsService,
  Marketplace,
  Marketplaces,
  mediaQuery,
  OrganizationAccountGroups,
  OrganizationAccountGroupService,
} from "@front/m19-services";
import { Option } from "@front/m19-ui";
import { ICON_CHEVRON_DOWN, ICON_SEARCH, ICON_WARNING_TRIANGLE } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest, Observable } from "rxjs";

@UntilDestroy()
@Component({
  selector: "app-account-selector",
  templateUrl: "./account-selector.component.html",
  styleUrls: ["./account-selector.component.scss"],
})
export class AccountSelectorComponent implements OnInit {
  @Input()
  scope: "dashboard" | "dashboard360" | undefined;

  readonly ICON_CHEVRON_DOWN = ICON_CHEVRON_DOWN;
  readonly ICON_SEARCH = ICON_SEARCH;
  readonly ICON_WARNING_TRIANGLE = ICON_WARNING_TRIANGLE;

  constructor(
    private accountGroupService: OrganizationAccountGroupService,
    private accountMarketplaceSelectionService: AccountSelectionService,
    private brandAnalyticsService: BrandAnalyticsService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  // Start of selectmenu refactoring
  accountOptions = signal<Option<AccountGroup>[]>([]);
  selectedAccount = signal<Option<AccountGroup> | undefined>(undefined);

  marketplaceOptions = signal<Option<Marketplace>[]>([]);
  selectedMarketplace = signal<Option<Marketplace> | undefined>(undefined);
  selectedMarketplaces = signal<Option<Marketplace>[]>([]);
  selectedMarketplacesMap = signal<Map<Marketplace, boolean>>(new Map());

  allOrganizations: OrganizationAccountGroups[] = [];

  @ViewChild("marketplacesDiv") marketplacesDiv!: ElementRef;

  isSingleMarketplaceMode = false;
  isWrapped = false;
  allSelected = false;
  currentMediaQuery: Observable<boolean>;
  isReadOnly = false;
  readonly faExclamationTriangle = faExclamationTriangle;
  readonly Marketplaces = Marketplaces;

  ngOnInit(): void {
    combineLatest<[OrganizationAccountGroups[] | undefined, Params]>([
      this.accountGroupService.allOrganizationAccountGroups$,
      this.route.queryParams,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([allOrganizations, params]) => {
        if (!allOrganizations) return;
        this.allOrganizations = allOrganizations.filter((o) => o.accountGroups && o.accountGroups.length > 0);

        this.accountOptions.set(
          this.allOrganizations.flatMap((o) => {
            return o.accountGroups.map((a) => {
              return {
                label: a.name,
                value: a,
                category: o.organizationName,
                bidderOn: this.hasAccountsWithBidderOn(a),
                lostAccess: this.hasLostAccess(a),
                accountId: a.resources[0]?.accountId,
              };
            });
          }),
        );

        const allGroups = this.allOrganizations.flatMap((o) => o.accountGroups);

        let notSelected = true;
        if (params["accountId"] && params["marketplace"] && params["orgId"]) {
          const accountGroup = allGroups.find((a) =>
            a
              .getAccountMarketplaces()
              .find(
                (am) =>
                  am.accountId === params["accountId"] &&
                  am.marketplace === params["marketplace"] &&
                  am.resourceOrganizationId == params["orgId"],
              ),
          );
          if (accountGroup) {
            notSelected = false;
            const am: AccountMarketplace | undefined = accountGroup
              .getAccountMarketplaces()
              .find(
                (am) =>
                  am.accountId === params["accountId"] &&
                  am.marketplace === params["marketplace"] &&
                  am.resourceOrganizationId == params["orgId"],
              );
            if (am) {
              this.selectGroupAccountMarketPlace(
                this.accountOptions().find((o) => o.value.id === accountGroup.id),
                am,
              );
              this.selectedMarketplace.set(this.marketplaceOptions().find((m) => m.label === am.marketplace));
              if (this.selectedMarketplace()) {
                this.selectedMarketplaces.set([this.selectedMarketplace()!]);
              }
            }
          }
        }

        if (notSelected && allGroups.length > 0) {
          const storedAccount = localStorage.getItem("currentAccountGroup") ?? allGroups[0];
          if (storedAccount) {
            const accountGroup = allGroups.find((a) => a.id == Number(storedAccount)) ?? allGroups[0];
            this.selectGroup(this.accountOptions().find((o) => o.value.id === accountGroup.id)!);
          } else {
            this.selectGroup(this.accountOptions()[0]);
          }
        }
      });
    this.accountMarketplaceSelectionService.singleMarketplaceMode$
      .pipe(untilDestroyed(this))
      .subscribe((x) => this.updateSingleMarketplaceMode(x));
    this.brandAnalyticsService.selectedMarketplace$.pipe(untilDestroyed(this)).subscribe((marketplace) => {
      if (this.selectedMarketplacesMap().has(marketplace) && !this.selectedMarketplacesMap().get(marketplace)) {
        this.toggleMarketplace(marketplace);
      }
    });

    this.accountMarketplaceSelectionService.readOnlyMode$
      .pipe(untilDestroyed(this))
      .subscribe((b) => (this.isReadOnly = b));
  }

  selectedAccountName(): string | undefined {
    return this.selectedAccount()?.value?.name;
  }

  selectGroupAccountMarketPlace(account: Option<AccountGroup> | undefined, am: AccountMarketplace) {
    if (!account) return;
    this.selectedAccount.set(account);
    localStorage.setItem("currentAccountGroup", String(account.value.id));
    this.accountMarketplaceSelectionService.updateAccountGroup(this.selectedAccount()!.value);

    const newMarketplaces = new Map<Marketplace, boolean>();
    for (const am of account.value.getAccountMarketplaces()) {
      newMarketplaces.set(am.marketplace, false);
    }

    newMarketplaces.set(am.marketplace, true);
    localStorage.setItem("currentMarketplace", am.marketplace);
    this.selectedMarketplacesMap.set(newMarketplaces);
    this.marketplaceOptions.set(
      Array.from(this.selectedMarketplacesMap().keys()).map((k) => ({
        label: k,
        value: k,
        name: Marketplaces[k].name,
        flag: Marketplaces[k].flag,
      })),
    );

    const width = 1200 + this.selectedMarketplacesMap().size * 50;
    mediaQuery(`(max-width: ${width}px)`).subscribe((matches) => {
      this.isWrapped = matches && this.selectedMarketplacesMap().size > 4;
    });

    this.sendUpdatedAccountMarketplaceSelection();
  }

  selectGroup(option: Option<AccountGroup>) {
    this.selectedAccount.set(option);
    localStorage.setItem("currentAccountGroup", String(option.value.id));

    if (this.selectedAccount()?.value) {
      this.accountMarketplaceSelectionService.updateAccountGroup(this.selectedAccount()!.value);
    }

    const newMarketplaces = new Map<Marketplace, boolean>();
    for (const am of this.selectedAccount()!.value.getAccountMarketplaces()) {
      newMarketplaces.set(am.marketplace, false);
    }
    for (const k of this.selectedMarketplacesMap().keys()) {
      if (newMarketplaces.has(k)) {
        newMarketplaces.set(k, newMarketplaces.get(k));
      }
    }
    if (newMarketplaces.size > 0 && Array.from(newMarketplaces.values()).every((x) => !x)) {
      const storedMarketplace = Marketplace[localStorage.getItem("currentMarketplace")];
      if (storedMarketplace && newMarketplaces.has(storedMarketplace)) newMarketplaces.set(storedMarketplace, true);
      else newMarketplaces.set(Array.from(newMarketplaces.keys())[0], true);
    }
    this.selectedMarketplacesMap.set(newMarketplaces);
    const width = 1200 + this.selectedMarketplacesMap().size * 50;
    mediaQuery(`(max-width: ${width}px)`).subscribe((matches) => {
      this.isWrapped = matches && this.selectedMarketplacesMap().size > 4;
    });
    this.sendUpdatedAccountMarketplaceSelection();
  }

  updateSingleMarketplaceMode(singleMode: boolean) {
    if (this.isSingleMarketplaceMode == singleMode) {
      return;
    }
    this.isSingleMarketplaceMode = singleMode;
    if (this.isSingleMarketplaceMode) {
      this.allSelected = false;
      if (Array.from(this.selectedMarketplacesMap().values()).filter((x) => x).length > 1) {
        let c = 0;
        for (const [marketplace, value] of this.selectedMarketplacesMap().entries()) {
          if (value) {
            if (c == 0) localStorage.setItem("currentMarketplace", marketplace);
            if (c > 0) this.selectedMarketplacesMap().set(marketplace, false);
            c++;
          }
        }
      }
      this.sendUpdatedAccountMarketplaceSelection();
    }
  }

  private sendUpdatedAccountMarketplaceSelection() {
    const selectedAccountMarketplaces = new Array<AccountMarketplace>();
    if (this.selectedAccount()?.value) {
      for (const am of this.selectedAccount()!.value.getAccountMarketplaces()) {
        if (this.selectedMarketplacesMap().get(am.marketplace)) {
          selectedAccountMarketplaces.push(am);
        }
      }
    }

    this.accountMarketplaceSelectionService.updateAccountMarketplace(selectedAccountMarketplaces);
    if (selectedAccountMarketplaces && selectedAccountMarketplaces.length > 0 && selectedAccountMarketplaces[0]) {
      this.brandAnalyticsService.selectMarketplace(selectedAccountMarketplaces[0].marketplace);
      // route to dashboard 360 or dashboard page if stats only mode
      if (
        this.scope == "dashboard" &&
        selectedAccountMarketplaces.some((a) => a.accessLevel === AccessLevel.STATS_ONLY)
      ) {
        this.router.navigate(["/dashboard360"], {
          queryParams: {
            accountId: selectedAccountMarketplaces[0].accountId,
            marketplace: selectedAccountMarketplaces[0].marketplace,
            orgId: selectedAccountMarketplaces[0].resourceOrganizationId,
          },
          queryParamsHandling: "merge",
        });
        return;
      }
      if (
        this.scope == "dashboard360" &&
        selectedAccountMarketplaces.every((a) => a.accessLevel !== AccessLevel.STATS_ONLY)
      ) {
        this.router.navigate(["/dashboard"], {
          queryParams: {
            accountId: selectedAccountMarketplaces[0].accountId,
            marketplace: selectedAccountMarketplaces[0].marketplace,
            orgId: selectedAccountMarketplaces[0].resourceOrganizationId,
          },
          queryParamsHandling: "merge",
        });
        return;
      }

      // update query parameters only if there is a single account marketplace selected
      if (selectedAccountMarketplaces.length == 1) {
        this.router.navigate([], {
          queryParams: {
            accountId: selectedAccountMarketplaces[0].accountId,
            marketplace: selectedAccountMarketplaces[0].marketplace,
            orgId: selectedAccountMarketplaces[0].resourceOrganizationId,
          },
          queryParamsHandling: "merge",
        });
      }
    }
  }

  selectMarketplaces(options: Option[]) {
    this.selectedMarketplaces.set(options);
    if (options.length == 0) return;
    this.selectedMarketplacesMap().forEach((_, key, map) => map.set(key, false));
    options.forEach((m) => this.selectedMarketplacesMap().set(Marketplace[m.label], true));
    this.sendUpdatedAccountMarketplaceSelection();
  }

  selectMarketplace(option: Option<Marketplace>) {
    for (const k of this.selectedMarketplacesMap().keys()) {
      if (k == option.label) {
        this.selectedMarketplacesMap().set(k, true);
      } else {
        this.selectedMarketplacesMap().set(k, false);
      }
    }
    this.sendUpdatedAccountMarketplaceSelection();
  }

  private singleClick = false;

  // On double-click, there is
  // 1. click event (first click)
  // 2. click event (second click)
  // 3. dblclick event (right after 2)
  toggleMarketplace(marketplace: Marketplace): void {
    this.singleClick = true;
    setTimeout(() => {
      // Do not handle second click in case of double click
      if (this.singleClick) {
        if (this.isSingleMarketplaceMode) {
          // just select the marketplace
          for (const k of this.selectedMarketplacesMap().keys()) {
            this.selectedMarketplacesMap().set(k, marketplace == k);
            if (marketplace == k) localStorage.setItem("currentMarketplace", marketplace);
          }
        } else {
          if (
            this.selectedMarketplacesMap().get(marketplace) &&
            Array.from(this.selectedMarketplacesMap().values()).filter((x) => x).length > 1
          ) {
            this.selectedMarketplacesMap().set(marketplace, false);
            this.allSelected = false;
          } else {
            this.selectedMarketplacesMap().set(marketplace, true);
          }
        }
        this.sendUpdatedAccountMarketplaceSelection();
      }
      this.singleClick = false;
    }, 10);
  }

  doubleClick(marketplace: Marketplace) {
    this.singleClick = false;
    if (this.isSingleMarketplaceMode) {
      return;
    }
    let nb = 0;
    for (const value of this.selectedMarketplacesMap().values()) if (value) if (nb++ > 0) break;
    if (nb == 1 && this.selectedMarketplacesMap().has(marketplace)) {
      // select all marketplaces
      for (const k of this.selectedMarketplacesMap().keys()) {
        this.selectedMarketplacesMap().set(k, true);
      }
    } else {
      // select one marketplace
      for (const k of this.selectedMarketplacesMap().keys()) {
        this.selectedMarketplacesMap().set(k, marketplace == k);
      }
    }
    this.sendUpdatedAccountMarketplaceSelection();
  }

  selectAll() {
    this.allSelected = true;
    this.selectedMarketplacesMap().forEach((_, key, map) => map.set(key, true));
    this.sendUpdatedAccountMarketplaceSelection();
  }

  private hasAccountsWithBidderOn(accountGroup: AccountGroup): boolean {
    return accountGroup.getAccountMarketplaces().filter((am) => am.state === AccountState.BIDDER_ON).length > 0;
  }

  private hasLostAccess(accountGroup: AccountGroup): boolean {
    for (const mktpl of accountGroup.getAccountMarketplaces()) {
      if (mktpl.isValidToken && !mktpl.hasAccessToAdvertising) {
        return true;
      }
    }
    return false;
  }

  listMktplLostAccess(accountGroup: Option<AccountGroup>): string {
    const marketPlacesLost: string[] = [];
    const accountMarketplaces = accountGroup.value.getAccountMarketplaces();
    for (const mktpl of accountMarketplaces) {
      if (mktpl.isValidToken && !mktpl.hasAccessToAdvertising) {
        marketPlacesLost.push(mktpl.marketplace);
      }
    }
    return marketPlacesLost.join(",");
  }
}
