import {
  AccessLevel,
  AccountMarketplace,
  AccountState,
  AccountType,
  Customer,
  Marketplace,
  Organization,
  OrganizationFirstPaymentValidatedEnum,
  Plan,
} from '../api-client';
import moment from 'moment-timezone';
import { AccountGroup } from './AccountGroup';

export function hasSubscription(organization: Organization): boolean {
  const now = moment.utc();
  const subscriptionEndDate = organization?.subscriptionEndDate ? moment.utc(organization?.subscriptionEndDate) : null;

  return (
    !!organization?.billingPlan &&
    organization?.subscriptionStartDate != null &&
    (subscriptionEndDate == null || subscriptionEndDate.isAfter(now))
  );
}

export function addBidderStatusToResource(
  organization: Organization,
  resource: AccountMarketplace,
): AccountMarketplace {
  if (hasSubscription(organization)) {
    const now = moment.utc();
    const activeState =
      organization.firstPaymentValidated == OrganizationFirstPaymentValidatedEnum.PAYMENT_ISSUE
        ? AccountState.PAYMENT_ISSUE
        : organization.firstPaymentValidated == OrganizationFirstPaymentValidatedEnum.NEW
          ? AccountState.NEW
          : AccountState.BIDDER_ON;

    const bidderStartDate = resource.bidderStartDate ? moment.utc(resource.bidderStartDate) : null;
    const bidderEndDate = resource.bidderEndDate ? moment.utc(resource.bidderEndDate) : null;
    // TODO bad design, it's not a good idea to compare DB date & browser date.
    // We could have time delay between DB & browser +
    // We are not robust to browser date modified by the client
    // add 1 minutes to be more robust to time delay
    if (
      bidderStartDate == null ||
      bidderStartDate.diff(now, 'minutes') > 1 ||
      (bidderEndDate && bidderEndDate.diff(now, 'minutes') < 0)
    ) {
      resource.state = AccountState.DOWNLOADER_ON;
    } else {
      resource.state = activeState;
    }
  } else {
    resource.state = AccountState.DOWNLOADER_ON;
  }

  return resource;
}

export function organizationCompare(a: OrganizationAccountGroups, b: OrganizationAccountGroups) {
  if (a.customerName == undefined && !a.parentOrganizationWhenOnwer) return 1;
  if (b.customerName == undefined && !b.parentOrganizationWhenOnwer) return -1;
  return a.organizationName.localeCompare(b.organizationName);
}

export class OrganizationAccountGroups {
  id: number;
  customerName: string;
  organizationName: string;
  isLegacy: boolean;
  accessLevel?: AccessLevel;
  customerId: string;
  customer: Customer | undefined;
  organization: Organization;
  accountGroups: AccountGroup[];
  // parentOrganization are only available for whitelabel board's admins
  // i.e. authorized users have null parentOrganization even if the organization has a parentOrganizationId
  parentOrganizationWhenOnwer: Organization | null;
  isParentPpcBoard: boolean;
  planTitle: string;
  isManualBillingPlan: boolean;
  datahubAccess: boolean;

  constructor(
    id: number,
    organization: Organization,
    parentOrganization: Organization | null,
    accountGroups: AccountGroup[],
  ) {
    this.id = id;
    this.organization = organization;
    this.isParentPpcBoard = organization!.isParentPpcBoard!;
    this.parentOrganizationWhenOnwer = parentOrganization;
    this.customerId = organization!.customerId!;
    this.customerName = organization!.customerName!;
    this.accessLevel = organization?.accessLevel;
    this.isLegacy =
      accountGroups.length > 0 &&
      accountGroups[0].resources.length > 0 &&
      (accountGroups[0].resources[0].isLegacy as boolean);
    this.accountGroups = accountGroups;
    this.accountGroups.forEach(
      (group) =>
        (group.resources = group.resources.map((resource) => addBidderStatusToResource(this.organization, resource))),
    );
    this.organizationName = this.customerName ?? (this.isLegacy ? 'Legacy Accounts' : 'Personal Free Plan');
    if (this.organization.parentOrganizationId) {
      const name = this.organization.board + ' selfservice: ' + this.organization.ownerUserName;
      this.organizationName = name.charAt(0).toUpperCase() + name.slice(1);
    }
    this.isManualBillingPlan = this.getIsManualBillingPlan();
    this.planTitle = this.getTitle();
    this.datahubAccess = organization.datahubAccess!;
  }

  private getTitle(): string {
    const plan = this?.organization?.billingPlan?.plan;
    if (!plan) {
      return 'No subscription';
    }

    if (this.getIsManualBillingPlan()) {
      return 'Paid plan';
    }
    if (plan == Plan.SELF_SERVICE && !this.parentOrganizationWhenOnwer) {
      return 'Paid plan';
    }
    if (plan != Plan.LEGACY) {
      return plan;
    }
    return this.organization.billingPlan!.frequency + ' billing plan';
  }

  private getIsManualBillingPlan(): boolean {
    const billingPlan = this?.organization?.billingPlan;
    if (
      !billingPlan ||
      billingPlan?.plan == Plan.MANUAL ||
      (billingPlan?.plan == Plan.LEGACY && !billingPlan?.legacyFee)
    ) {
      return true;
    }
    return false;
  }

  public setCustomer(customer: Customer) {
    if (customer && !this.customer) {
      this.customer = customer;
    }
  }

  public hasSubscription(): boolean {
    return hasSubscription(this.organization);
  }

  public hasASellerAccount() {
    return this.accountGroups.some((a) => a.getAccountMarketplaces().some((m) => m.accountType == AccountType.SELLER));
  }

  public allVendorAccounts() {
    return this.accountGroups
      .flatMap((a) => a.getAccountMarketplaces())
      .every((am) => am.accountType == AccountType.VENDOR);
  }

  public hasActiveSubscription() {
    return (
      this.hasSubscription() && this.organization?.firstPaymentValidated == OrganizationFirstPaymentValidatedEnum.PAID
    );
  }

  public getBillingPlan() {
    return this.organization?.billingPlan;
  }

  public getAccountState(am: AccountMarketplace): AccountState | undefined {
    return this.accountGroups
      .flatMap((x) => x.resources)
      .find((x) => x.accountId == am.accountId && x.marketplace == am.marketplace)?.state;
  }

  public canActivateBidder(am: AccountMarketplace) {
    if (am.state == AccountState.BIDDER_ON) return false;

    if (!am.activated || !am.hasAccessToAdvertising) return false;

    if (!this.hasActiveSubscription() || !this.getBillingPlan()) {
      return false;
    }

    const plan = this.getBillingPlan()!.plan;

    if (plan == Plan.LEGACY) {
      return false;
    }

    const accountsWithBidderOn = new Set<string>();
    this.accountGroups
      .flatMap((x) => x.resources)
      .filter((x) => x.state == AccountState.BIDDER_ON)
      .forEach((x) => accountsWithBidderOn.add(x.accountId));

    if (accountsWithBidderOn.size < (this.getBillingPlan()?.accountLimit ?? Infinity)) {
      return true;
    }

    if (!this.getBillingPlan()!.marketplaceLimitPerAccount) {
      return accountsWithBidderOn.has(am.accountId);
    }

    const marketplaceWithBidderOn = new Set<Marketplace>();
    this.accountGroups
      .flatMap((x) => x.resources)
      .filter((x) => x.accountId == am.accountId)
      .forEach((x) => marketplaceWithBidderOn.add(x.marketplace));

    if (this.getBillingPlan() && marketplaceWithBidderOn.size < this.getBillingPlan()!.marketplaceLimitPerAccount!) {
      return true;
    }
    return false;
  }
}
