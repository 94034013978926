import { EligibilityStatus } from '../api-client';
import { EligibilityUtils } from './EligibilityUtils';
import { ProductEx } from './ProductEx';

export class Catalog {
  public readonly parentAsins: Map<string, string[]> = new Map();
  public readonly asinsAndParentAsins: string[] = [];
  public readonly childAsins: Set<string> = new Set();
  private readonly sbEligibility: Map<string, { status: boolean; eligibility: EligibilityStatus; reason: string }> =
    new Map();
  private readonly spEligibility: Map<string, { status: boolean; eligibility: EligibilityStatus; reason: string }> =
    new Map();
  public readonly asinOffers: Map<string, ProductEx> = new Map();
  public readonly nbLandingpageEligibleAsins: number;

  constructor(public readonly products: ProductEx[]) {
    const landingPageEligibleAsin = new Set<string>();
    const asinsAndParentAsinsSet = new Set<string>();
    for (const product of products) {
      if (product.asin) {
        asinsAndParentAsinsSet.add(product.asin);
        if (product.parentAsin) {
          const childAsins = this.parentAsins.get(product.parentAsin) ?? [];
          if (!childAsins.includes(product.asin)) {
            childAsins.push(product.asin);
            this.parentAsins.set(product.parentAsin, childAsins);
          }
          asinsAndParentAsinsSet.add(product.parentAsin);
        } else if (!this.parentAsins.has(product.asin)) {
          this.parentAsins.set(product.asin, []);
        }

        this.setEligiblityWithReason(product.asin, product.sbEligibility!, product.sbReason!, this.sbEligibility);
        this.setEligiblityWithReason(product.asin, product.spEligibility!, product.spReason!, this.spEligibility);
        this.asinOffers.set(product.asin, product);

        // asin considered available if he is SB eligible (exactly not unknown) and SP eligible
        if (product.sbEligibility !== EligibilityStatus.INELIGIBLE) {
          landingPageEligibleAsin.add(product.asin);
        }
      }
    }
    this.asinsAndParentAsins = Array.from(asinsAndParentAsinsSet.values());
    for (const a of this.asinOffers.keys()) {
      if (!this.parentAsins.has(a) || this.parentAsins.get(a)!.length == 0) {
        this.childAsins.add(a);
      }
    }
    this.nbLandingpageEligibleAsins = landingPageEligibleAsin.size;
  }

  private setEligiblityWithReason(
    asin: string,
    eligiblity: EligibilityStatus,
    reason: string,
    eligibilityMap: Map<string, { status: boolean; eligibility: EligibilityStatus; reason: string }>,
  ): void {
    // if at least one sku is eligible, the asin is eligible => overide asin when prev sku was not eligible
    const prevEligibility = eligibilityMap.get(asin);
    if (!!prevEligibility && prevEligibility.eligibility === EligibilityStatus.ELIGIBLE) return;

    const newEligibility = eligiblity == null ? EligibilityStatus.UNKNOWN : eligiblity;

    if (EligibilityUtils.compare(newEligibility, prevEligibility?.eligibility) > 0) {
      eligibilityMap.set(asin, {
        status: EligibilityUtils.status(newEligibility),
        eligibility: newEligibility,
        reason:
          newEligibility === EligibilityStatus.ELIGIBLE
            ? ''
            : reason
              ? reason
              : newEligibility === EligibilityStatus.NOT_AUTHORIZED
                ? 'Not Authorized By Amazon API'
                : 'Ineligible',
      });
    }
  }

  public contains(asin: string): boolean {
    return this.asinOffers.has(asin);
  }

  public asins(): IterableIterator<string> {
    return this.asinOffers.keys();
  }

  public size(): number {
    return this.asinOffers.size;
  }

  public isParentAsin(asin: string) {
    return this.parentAsins.get(asin) && this.parentAsins.get(asin)!.length > 0;
  }

  public getSBEligibility(): Map<string, { status: boolean; reason: string }> {
    return this.sbEligibility;
  }

  public getSPEligibility(): Map<string, { status: boolean; reason: string }> {
    return this.spEligibility;
  }

  public getParentAsin(asin: string): string | undefined {
    return this.asinOffers.get(asin)?.parentAsin;
  }

  public getChildAsins(asin: string): string[] | undefined {
    return this.parentAsins.get(asin);
  }

  public brands(): Set<string> {
    const brands = new Set<string>();
    for (const offer of this.asinOffers.values()) if (offer.brand) brands.add(offer.brand);
    return brands;
  }

  public brandAsins(): Map<string, string[]> {
    const brandAsins = new Map<string, string[]>();
    for (const offer of this.asinOffers.values()) {
      if (offer.brand) {
        const asins = brandAsins.get(offer.brand) ?? [];
        asins.push(offer.asin!);
        brandAsins.set(offer.brand, asins);
      }
    }
    return brandAsins;
  }

  public getBrand(asin: string) {
    return this.asinOffers.get(asin)?.brand;
  }

  public getProductTitles(): Map<string, string> {
    const titles = new Map();
    for (const [key, value] of this.asinOffers) {
      titles.set(key, value?.title ?? '');
    }
    return titles;
  }
}
