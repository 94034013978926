import { CurrencyPipe } from "@angular/common";
import { Component, computed, inject, Input, OnInit, signal } from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  AccountMarketplace,
  AlgoMode,
  CampaignType,
  ConfigService,
  Currency,
  currencyRateToEuro,
  Organization,
  OrganizationService,
  SegmentConfigType,
  StrategyEx,
  StrategyType,
  TacticType,
} from "@front/m19-services";
import { IButtonComponent, IInputComponent } from "@front/m19-ui";
import { HourlyDataUnsupportedMarketplaces } from "@m19-board/overlay/hourly-stats-overlay.component";
import { IntInputComponent } from "@m19-board/shared/int-input/int-input.component";
import { SwitchInputComponent } from "@m19-board/shared/switch-input/switch-input.component";
import { DayPartingInputsComponent } from "@m19-board/strategies/day-parting-inputs/day-parting-inputs.component";
import { ICON_ADD, ICON_CLOSE, ICON_EDIT_O } from "@m19-board/utils/iconsLabels";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Observable, of } from "rxjs";
import { DisableAutoWarningComponent } from "../../../strategies/disable-auto/disable-auto-warning.component";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";
@Component({
  selector: "app-advanced-settings-modal",
  standalone: true,
  imports: [
    IButtonComponent,
    IInputComponent,
    DayPartingInputsComponent,
    SwitchInputComponent,
    IntInputComponent,
    CurrencyPipe,
    DisableAutoWarningComponent,
    TranslocoRootModule,
  ],
  templateUrl: "./advanced-settings-modal.component.html",
})
export class AdvancedSettingsModalComponent implements OnInit {
  readonly configurationService = inject(ConfigService);
  readonly orgService = inject(OrganizationService);
  readonly modalRef = inject(BsModalRef);

  readonly ICON_EDIT = ICON_EDIT_O;
  readonly ICON_ADD = ICON_ADD;
  readonly ICON_CLOSE = ICON_CLOSE;

  @Input() accountMarketplace: AccountMarketplace;
  @Input() strategy: StrategyEx;
  @Input() isReadOnly: boolean;
  @Input() locale: string;
  @Input() currencyCode: Currency;

  strategy$: Observable<StrategyEx>;

  // Strategy Label
  showStrategyLabelInput = false;
  newStrategyLabel = new FormControl("");

  // Day parting
  focusDayPartingAccess = false;

  // Min Daily Spend
  showMinDailySpend = false;
  minDailyBudgetLowerBound: number;
  maxDailySpend: number;
  maxDailyBudgetinEuros: number;

  autoAlgoExpEnabled = signal(false);
  productTargetingEnabled = signal(false);

  isProductTargetingToggleDisabled = computed(
    () =>
      this.isReadOnly ||
      (!this.autoAlgoExpEnabled() &&
        this.strategy.tactics.length > 0 &&
        this.strategy.tactics.every((t) => t.segment.segmentType === SegmentConfigType.ProductSegment)),
  );

  showAutoAlgoExp = computed(
    () =>
      this.strategy &&
      this.strategy.campaignType !== CampaignType.SD &&
      this.strategy.tactics.length > 0 &&
      (!this.autoAlgoExpEnabled() ||
        this.strategy.tactics.some((t) => t.segment && t.tacticType !== TacticType.BLACKLIST)),
  );

  isAutoAlgoToggleDisabled = computed(
    () =>
      this.isReadOnly ||
      (this.autoAlgoExpEnabled() &&
        !this.productTargetingEnabled() &&
        this.strategy.tactics.length > 0 &&
        this.strategy.tactics.every((t) => t.segment.segmentType === SegmentConfigType.ProductSegment)),
  );

  ngOnInit(): void {
    this.newStrategyLabel.setValue(this.strategy.strategyLabel ?? "");
    this.strategy$ = of(this.strategy);

    this.autoAlgoExpEnabled.set(this.strategy.autoAlgoExplorationEnabled);
    this.productTargetingEnabled.set(this.strategy.productTargetingEnabled);

    this.minDailyBudgetLowerBound = this.getMinDailyBudgetLowerBound();
    this.maxDailySpend = this.getMaxMinDailySpend();
    this.maxDailyBudgetinEuros = this.getUserMaxMinDailyBudgetInEuros();

    this.orgService.organizations$.subscribe((organizations) => {
      const hasAccessHourlyData: boolean =
        organizations.find((org: Organization) => org.organizationId === this.accountMarketplace.resourceOrganizationId)
          ?.billingPlan.hourlyDataSupport > 0;

      if (!HourlyDataUnsupportedMarketplaces.has(this.accountMarketplace.marketplace) && hasAccessHourlyData) {
        this.focusDayPartingAccess = true;
      }
    });
  }

  updateStrategyLabel() {
    if (this.isReadOnly) return;
    this.configurationService.updateStrategyLabel(
      this.accountMarketplace.accountId,
      this.accountMarketplace.marketplace,
      this.strategy.strategyId,
      this.newStrategyLabel.value ?? "",
    );
    this.strategy.strategyLabel = this.newStrategyLabel.value;
    this.showStrategyLabelInput = false;
  }

  // Auto targeting campaign
  toggleAutoTargetCampain(strategy: StrategyEx, enabled: boolean) {
    this.configurationService.updateStrategyTargetCampain(
      strategy.accountId,
      strategy.marketplace,
      strategy.strategyId,
      enabled,
    );
    this.strategy.autoTargetCampainEnabled = enabled;
    if (enabled && !this.autoAlgoExpEnabled()) this.autoAlgoExpEnabled.set(true);
  }

  // Product targeting
  toggleProductTargeting(strategy: StrategyEx, enabled: boolean) {
    this.configurationService.updateStrategyProductTargeting(
      strategy.accountId,
      strategy.marketplace,
      strategy.strategyId,
      enabled,
    );
    this.productTargetingEnabled.set(enabled);
  }

  // AI-powered targeting
  switchAutoAlgoExploration(strategy: StrategyEx, enabled: boolean) {
    this.configurationService.updateStrategyAutoAlgoExploration(
      strategy.accountId,
      strategy.marketplace,
      strategy.strategyId,
      enabled,
    );
    if (!enabled && this.strategy.autoTargetCampainEnabled) this.strategy.autoTargetCampainEnabled = false;
    this.autoAlgoExpEnabled.set(enabled);
  }

  private getMaxMinDailySpend() {
    const minDailySpendLimit = this.accountMarketplace?.minDailyBudgetLimit;

    return this.strategy.dailyBudget
      ? Math.floor(
          Math.min(
            minDailySpendLimit / currencyRateToEuro(this.currencyCode as Currency),
            0.5 * this.strategy.dailyBudget,
          ),
        )
      : Math.floor(minDailySpendLimit / currencyRateToEuro(this.currencyCode as Currency));
  }

  private getMinDailyBudgetLowerBound(): number {
    return Math.ceil(1 / currencyRateToEuro(this.currencyCode as Currency));
  }

  private getUserMaxMinDailyBudgetInEuros(): number {
    const minDailySpendLimit = this.accountMarketplace?.minDailyBudgetLimit;

    return Math.round(minDailySpendLimit / currencyRateToEuro(this.currencyCode as Currency));
  }

  updateMinDailySpend(value: number): void {
    this.configurationService.updateStrategyMinDailySpend(
      this.strategy.accountId,
      this.strategy.marketplace,
      this.strategy.strategyId,
      value,
    );
    this.strategy.minDailySpend = value;
    this.showMinDailySpend = false;
  }

  StrategyType = StrategyType;
  CampaignType = CampaignType;
  AlgoMode = AlgoMode;
}
